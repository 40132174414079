import axios from 'axios'
import Vue from 'vue';
import VueCryptojs from 'vue-cryptojs'

Vue.use(VueCryptojs);

let asciiToHex = (value) => Vue.CryptoJS.enc.Utf8.parse(value).toString(Vue.CryptoJS.enc.Hex);
const ApiService = {
    baseUrl: '',
    init(baseURL) {
        ApiService.baseUrl = baseURL;
        axios.defaults.baseURL = baseURL;
    },
    getBaseUrl() {
        return ApiService.baseUrl;
    },
    setHeader(token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token

    },
    computeHash(url, key) {
        return Vue.CryptoJS.SHA256(Vue.CryptoJS.enc.Hex.parse(key + asciiToHex(url.toLowerCase()))).toString(Vue.CryptoJS.enc.Hex).toUpperCase();
    },

    removeHeader() {
        axios.defaults.headers.common = {}
    },

    get(resource) {
        return axios.get(resource, {
            headers: {
                Hash: this.computeHash(resource, "124114982AA640178097CEBDDFA0E050")
            }
        })
    },

    post(resource, data) {
        return axios.post(resource, data, {
            headers: {
                Hash: this.computeHash(resource, "124114982AA640178097CEBDDFA0E050")
            }
        })
    },

    put(resource, data) {
        return axios.put(resource, data)
    },

    delete(resource) {
        return axios.delete(resource)
    },


    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
     **/
    customRequest(data) {
        return axios(data)

    }
};

export {
    ApiService
};