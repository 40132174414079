<template>
  <div class>
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNav2></HeaderNav2>
      <div class="container">
        <HeaderNav3></HeaderNav3>
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <p class="frequently-used">Add Beneficiary</p>
                </div>
                <div class="current_tab_title">
                  <font-awesome-icon icon="dot-circle" class="play-icon" />
                  <p class>You can add your Beneficiaries manually</p>
                </div>
                <div class="kindly_enter_details">
                  <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                  <p>Be sure to fill the information below accurately</p>
                </div>
                <form action>
                  <div class="form-table2">
                    <div class="label-title">
                      <label>Bank</label>
                      <div class="account_select">
                        <select name id v-model="beneficiaryBank" class="account_type">
                          <option value class="choose_account">- Select Bank -</option>
                          <option
                            v-for="item in INSTITUTIONS"
                            :key="item"
                            :value="item"
                            class="choose_account"
                          >{{item.label}}</option>
                        </select>
                        <font-awesome-icon icon="caret-down" class />
                      </div>
                    </div>
                    <div class="label-title2">
                      <label>Beneficiary Account Number</label>
                      <div class="account_select2">
                        <the-mask
                          mask="##########"
                          v-model="beneficiaryAccount"
                          class="input-amount"
                          placeholder="-"
                        />
                        <!--<input type="number" class="input-amount" placeholder="-"/>-->
                      </div>
                    </div>
                    <div class="label-title2">
                      <label>Beneficiary Name</label>
                      <div class="account_select2">
                        <input
                          class="input-amount"
                          disabled
                          style="background:whitesmoke"
                          v-model="beneficiaryName"
                        />
                      </div>
                    </div>
                  </div>
                </form>

                <div class="send-or-download2">
                  <div class="continue-button">
                    <a @click="$router.go(-1)">
                      <div class="continue-btn btn">
                        <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                      </div>
                    </a>
                  </div>
                  <router-link to="#">
                    <div class="continue-button">
                      <button class="continue-btn">
                        SAVE
                        <font-awesome-icon icon="angle-right" class="angle-icon" />
                      </button>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar :accountNumber="Account" :accountBalance="Balance"></SideBar>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderNav2 from "../../components/HeaderNav/HeaderNav2";
import HeaderNav3 from "../../components/HeaderNav/HeaderNav3";
import SideBar from "../../components/SideBar/SideBar";
import { mapGetters, mapState } from "vuex";
import { getInstitutionService } from "../../services/getInstitution.services.js";
import { transferService } from "../../services/transfer.services.js";
import Footer2 from "../../components/Footer/Footer2";
// import { getBeneficiaryService } from "../../services/beneficiary.service.js";

export default {
  name: "UpdateInfo.vue",
  components: {
    HeaderNav2,
    HeaderNav3,
    SideBar,
    Footer2
    // 'image-picker': ImagePicker
  },
  data() {
    return {
      date: null,
      image: "",
      progress: null,
      error: null,
      beneficiaryBank: null,
      beneficiary: "",
      beneficiaryName: "",
      beneficiaryAccount:"",
      Account: this.$store.getters.GET_USER.accounts[0].accountNumber
    };
  },
  computed: {
    ...mapGetters({ user: "GET_USER" }),

    ...mapState({
      AUTHENTICATION_TOKEN: state => state.User.AUTHENTICATION_TOKEN,
      INSTITUTIONS: state => state.Transfer.INSTITUTIONS,
      BENEFICIARY_ACCOUNT: state => state.Transfer.BENEFICIARY_ACCOUNT,
      BENEFICIARY_NAME: state => state.Transfer.BENEFICIARY_NAME,
      BENEFICIARY_BANK: state => state.Transfer.BENEFICIARY_BANK,
      SAVE_BENEFICIARY: state => state.Transfer.SAVE_BENEFICIARY
    }),
    Balance() {
      return this.user.accounts[
        this.user.accounts.findIndex(a => a.accountNumber == this.Account)
      ].balance;
    },
 
  },
  watch :{
      beneficiaryAccount: function(newval) {
        window.console.log(newval)
      if (newval.toString().length == 10) this.validateBeneficiaryAccount();
    },
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },
    async validateBeneficiaryAccount() {
      this.loading = true;
      const data = {
        Account: this.beneficiaryAccount,
        Code: this.beneficiaryBank.value,
        token: this.AUTHENTICATION_TOKEN
      };
      await transferService
        .validateBeneficiaryAccount(data)
        .then(res => {
          this.loading = false;
          this.beneficiaryName = res.status
          // this.$store.commit("SET_BENEFICIARY_NAME", res.status);
          window.console.log(res)
        }) 
        .catch(err => {
          this.loading = false;
          this.$toastr.error(err.status, "Validation Failed!", {
            timeOut: 5000
          });
          this.$store.commit("SET_BENEFICIARY_NAME", "");
        });
    },
    async getInstitution() {
      await getInstitutionService
        .getInstitution()
        .then(res => {
          this.$store.commit("SET_INSTITUTIONS", res);
        })
        .catch(err => {
          this.$toastr.error(err.status, "Could not fetch institutions", {
            timeOut: 5000
          });
        });
    },
  },
  created() {
    this.getInstitution()
  }
  // methods: {
  //     onFileChange(e) {
  //         var files = e.target.files || e.dataTransfer.files;
  //         if (!files.length)
  //             return;
  //         this.createImage(files[0]);
  //     },
  //     createImage(file) {
  //         var image = new Image();
  //         var reader = new FileReader();
  //         var vm = this;
  //
  //         reader.onload = (e) => {
  //             vm.image = e.target.result;
  //         };
  //         reader.readAsDataURL(file);
  //     },
  //     removeImage: function (e) {
  //         this.image = '';
  //     }
  // }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}
.quick-transfer {
  padding: 0 0.7rem;
}
.upload-picture {
  display: flex;
  padding: 1rem 0;
}
.upload_image {
  margin-right: 1.5rem;
  object-fit: contain;
  width: 200px;
}
.upload_image img {
  object-fit: contain;
  width: 200px;
  height: 200px;
}
.image-uploader {
  height: 230px !important;
}
.upload_image2 h5 {
  font-family: Montserrat-SemiBold;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0.22px;
  text-align: left;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
