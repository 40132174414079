<template>
  <div class="hope-landing">
    <div class="about-page-header">
      <HeaderNavA />

      <div class="about-title">
        <h1>
          Hope PSBank
          <br />
          <span>Products and Solutions</span>
        </h1>
        <div class="row">
          <div class="col-md-7 ml-auto mr-auto">
            <div class="text-center">
              <p>
                Your phone number is your means of payment for all our services
                and solutions.
              </p>
              <div class="d-flex justify-content-center">
                <PayAttitude2 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" id="pro">
      <div class="become_agent">
        <div class="row">
          <div class="col-md-4">
            <div class="agent_img">
              <img src="../../assets/images/hopebank-app2.png" />
            </div>
          </div>
          <div class="col-md-8">
            <div class="agent_how_to">
              <h1>
                Hope Products
                <span>and Services</span>
              </h1>
              <div class="product_solutions">
                <p>
                  We create products and services tailored to your needs as a valued customer and constantly innovate for
                  inclusive digital financial and payment lifestyle. Our services are distinct and unique in different
                  ways including:
                </p>
                <ul>
                  <li>
                    <font-awesome-icon icon="caret-right" class="caret-right" />Zero opening balance for all account
                    types.
                  </li>
                  <li>
                    <font-awesome-icon icon="caret-right" class="caret-right" />Zero documents and conditionality.
                  </li>
                  <li>
                    <font-awesome-icon icon="caret-right" class="caret-right" />Open and operate accounts by yourself
                    through <a href="tel:*569*9#">*569*9#</a> or Hope Digital App or <a
                      href="https://www.hopepsbank.com">www.hopepsbank.com</a>
                  </li>
                  <li>
                    <font-awesome-icon icon="caret-right" class="caret-right" />Your phone number is your means of
                    transaction at ATM, Merchant POS, Web, Agent Outlets and anywhere you see the PayAttitude sign.
                  </li>
                  <li>
                    <font-awesome-icon icon="caret-right" class="caret-right" />No cheque book, no ATM card. All digital!
                  </li>
                  <li>
                    <font-awesome-icon icon="caret-right" class="caret-right" />Your PIN is 6-8 digits and entered on your
                    own handset or device thereby offering you special protection.
                  </li>
                  <li>
                    <font-awesome-icon icon="caret-right" class="caret-right" />Use your phone as a Remote Payment
                    Control: You can securely send anyone to ATMs, our branches, agents or PoS to withdraw money or make
                    payment with just your phone number. We will locate you wherever you are so you can enter your PIN on
                    your phone or personal device to approve the transaction.
                  </li>
                  <li>
                    <font-awesome-icon icon="caret-right" class="caret-right" />Telephone POS: To receive payment from
                    anyone, type the person’s number on your phone and the person will approve on his or her phone.
                  </li>
                  <li>
                    <font-awesome-icon icon="caret-right" class="caret-right" />Personal Assistant: Ask your Hope accounts
                    to maintain your calendar, set reminders and make payments on your behalf.
                  </li>
                  <li>
                    <font-awesome-icon icon="caret-right" class="caret-right" />You can also enjoy some tailored services
                    and solutions without having an account with us. We have redefined the concept of open banking and the
                    boundaries of competition by offering our unique financial and payment services and solutions to
                    customers of other banks who are not required to open or maintain account with our bank. All
                    transactions on our platforms are gladly settled into accounts of our community of users and customers
                    with any bank of their choice.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agency-benefits grey_bg" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Hope Digital
                <br />
                <span>Solutions:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
              <h4>Hope Digital Solutions</h4>
              <p>
                Manage your accounts with ease through our various digital solutions:
              </p>
              <h5>Product Features</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Hope Digital Collection
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Sales &amp; Delivery Solution - Telephone
                  POS
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Hope Market Place
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agency-benefits white_bg2" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out"
      id="ineed">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Individual
                <br />
                <span>Needs:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
              <h4>Hope Wallet</h4>
              <PayAttitude />
              <p>
                Hope Wallet is an account that you can open and fund with ease for yourself, staff, family and friends for
                regular payments.
              </p>
              <h5>Product Features</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />No
                  BVN
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Zero opening and minimum balance
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Maximum Cumulative Balance - N300,000
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Direct debit instruction using our unique
                  Personal Assistant.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Funds transfer to all banks.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Bills payment
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Pay or withdraw with phone number at ATM,
                  Branches, Agent outlets, Merchant POS, Web and anywhere you see the PayAttitude sign.
                </li>
              </ul>
              <h5>Requirements</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Registered phone number.
                </li>
              </ul>
              <h5>Note: Wallet accounts have a minimum balance of 200 naira</h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div
      class="agency-benefits grey_bg"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Individual
                <br />
                <span>Needs:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
              <h4>Hope Hello</h4>
              <PayAttitude />
              <p>Hope Hello requires little or no documentation.</p>
              <h5>Product Features</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Zero opening and minimum balance
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Maximum single deposit-N50,000
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Maximum Cumulative Balance-N300,000
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Telephone POS
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Pay or withdraw with phone number at ATM, Branches, Agent
                  outlets, Merchant POS, Web and anywhere you see the
                  PayAttitude sign.
                </li>
              </ul>
              <h5>Requirements</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Valid BVN
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Registered phone number.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="agency-benefits white_bg2"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Individual
                <br />
                <span>Needs:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
              <h4>Hope Hello+</h4>
              <PayAttitude />
              <h5>Product Features</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Zero opening and minimum balance
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Maximum single deposit-N100,000
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Maximum Cumulative Balance-N500,000
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Competitive Interest Rate
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Telephone POS
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Pay or withdraw with phone number at ATM, Branches, Agent
                  outlets, Merchant POS, Web and anywhere you see the
                  PayAttitude sign.
                </li>
              </ul>
              <h5>Requirements</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Valid BVN
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Registered phone number.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Verifiable ID - BVN number can be used.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="agency-benefits grey_bg" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Individual
                <br />
                <span>Needs:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
              <h4>Hope Savings</h4>
              <PayAttitude />
              <p>
                The Hope Savings Account is an account which helps individuals save towards future financial security,
                thereby facilitating a savings culture for its target groups.
              </p>
              <h5>Product Features</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Zero opening balance.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Direct debit instruction using our unique
                  Personal Assistant.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Funds transfer to all banks.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Bills payment.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Pay or withdraw with phone number at ATM,
                  Branches, Agent outlets, Merchant POS, Web and anywhere you see the PayAttitude sign.
                </li>
              </ul>
              <h5>Requirements</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Valid BVN
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Registered phone number.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div
      class="agency-benefits white_bg2"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Individual
                <br />
                <span>Needs:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
              <h4>Hope Kiddies Savings</h4>
              <PayAttitude />
              <p>
                <b>Hope Kiddies Savings</b> is a savings account for children below the age of 18 years. This account is opened by parents/guardians in trust for their children. It allows parents/guardians save for their children’s future and teach them about savings from a tender age. Give your kids an early start by opening Hope Kiddies Savings account today.
              </p>
              <h5>Product Features</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Zero opening balance/minimum daily balance.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Competitive Interest Rate
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Direct debit instruction using our unique Personal Assistant.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Funds transfer to all banks.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Bills payment
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Pay or withdraw with phone number at ATM, Branches, Agent outlets, Merchant POS, Web and anywhere you see the PayAttitude sign.
                </li>
              </ul>
              <h5>Requirements</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Valid BVN of the Parent(s).
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Registered phone number.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div
      class="agency-benefits grey_bg"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Individual
                <br />
                <span>Needs:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
              <h4>Hope Kool Teen Wallet</h4>
              <PayAttitude />
              <p>
                Hope Kool Teen Wallet is a wallet account designed for children between the ages of 12 -17+. The account can be opened by the parent/guardian to be used by the child.
              </p>
              <h5>Product Features</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Wallet can be open without BVN
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />No
                  opening and minimum balance
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Maximum single deposit - N50,000
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Maximum Cumulative Balance - N300,000
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Pay or withdraw with phone number at ATM, Branches, Agent outlets, Merchant POS, Web and anywhere you see the PayAttitude sign.
                </li>
              </ul>
              <h5>Requirements</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Registered phone number of the child/ward
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Parents/Guardians required to have an account with HOPE PSBank.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="agency-benefits white_bg2" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Individual
                <br />
                <span>Needs:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
              <h4>Hope Salary - Savings</h4>
              <PayAttitude />
              <p>
                Hope Salary Account (Savings) is an account that offers comprehensive and flexible banking services to all
                salaried customers. It offers employees seamless and convenient access to their salaries.
              </p>
              <h5>Product Features</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Zero opening balance/minimum daily balance
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Direct debit instruction using our unique
                  Personal Assistant
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Funds transfer to all banks.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Bills payment
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Pay or withdraw with phone number at ATM,
                  Branches, Agent outlets, Merchant POS, Web and anywhere you see the PayAttitude sign.
                </li>
              </ul>
              <h5>Requirements</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Valid BVN.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Registered phone number.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agency-benefits grey_bg" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Individual
                <br />
                <span>Needs:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
              <h4>Hope Salary - Current</h4>
              <PayAttitude />
              <p>
                Hope Salary Account (Current) is an account that offers comprehensive and flexible banking services to all
                the salaried customers. It provides Hassle-free access to salaries and a multitude of convenient banking
                facilities.
              </p>
              <h5>Product Features</h5>

              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Zero opening balance/minimum daily balance
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Direct debit instruction using our unique
                  Personal Assistant
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Funds transfer to all banks.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Bills payment
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Pay or withdraw with phone number at ATM,
                  Branches, Agent outlets, Merchant POS, Web and anywhere you see the PayAttitude sign.
                </li>
              </ul>
              <h5>Requirements</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Valid BVN.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Registered phone number.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agency-benefits white_bg2" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Individual
                <br />
                <span>Needs:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
              <h4>Hope Joint</h4>
              <PayAttitude />
              <p>
                The Hope Joint is an account which helps individuals (maximum of 3) save towards future financial
                security, thereby facilitating a savings culture for its target groups.
              </p>
              <h5>Product Features</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Zero opening balance/minimum daily balance
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Competitive Interest rate.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Direct debit instruction using our unique
                  Personal Assistant
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Funds transfer to all banks.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Bills payment
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />All signatories receive notification for
                  digital PIN selection once account is opened.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />All signatories are prompted real-time to
                  approve transactions on the go. Account is debited once the Signatories approve in line with the mandate
                  on the account.
                </li>
              </ul>
              <h5>Requirements</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Valid BVN of all holders.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Registered phone number of all holders.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agency-benefits grey_bg" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Individual
                <br />
                <span>Needs:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
              <h4>Hope Current</h4>
              <PayAttitude />
              <p>
                Hope Current Individual Account is an account that is designed to allow for uninterrupted and wide range
                of business transactions with 3rd parties. It provides an easy way to manage your daily finances and carry
                out routine banking transactions seamlessly.
              </p>
              <h5>Product Features</h5>

              <ul>
                <!-- <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Minimum balance of N10,000
                </li> -->
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Direct debit instruction using our unique
                  Personal Assistant.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Funds transfer to all banks.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Bills payment.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Pay or withdraw with phone number at ATM,
                  Branches, Agent outlets, Merchant POS, Web and anywhere you see the PayAttitude sign.
                </li>
              </ul>
              <h5>Requirements</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Valid BVN.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Registered phone number.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line3"></div>
    <div class="agency-benefits white_bg2" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out"
      id="bizneed">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Business
                <br />
                <span>Needs:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
              <h4>Hope Business Account</h4>
              <PayAttitude />
              <p>
                Hope Business Accounts are targeted at all registered Micro, Small and Medium enterprises/business and
                other organisations.
              </p>

              <p>
                We offer our customers simple digital accounts to get them operating on the Go. We combine this ease of
                banking with bespoke solutions and services for their convenience.
              </p>
              <h5>Product Features</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Zero opening balance/minimum daily balance
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Direct debit instruction using our unique
                  Personal Assistant.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Funds transfer to all banks.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Bills payment Enables initiators or
                  assistants to be registered and help initiate transactions.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />All signatories receive notification for
                  digital PIN selection once account is opened.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />All signatories are prompted real-time to
                  approve transactions on the go.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Account is debited once the last signatory
                  approves based on mandate.
                </li>
              </ul>

              <div class="line3"></div>
              <h5>Registered Business</h5>
              <PayAttitude />
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Business Registration No (BN)
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />BVN of the Sole Proprietor(s)/Owner(s)
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />2
                  Suitable References.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Tax Identification Number.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Regulatory Licenses (where applicable).
                </li>
              </ul>

              <div class="line3"></div>
              <h5>Partnerships</h5>
              <PayAttitude />
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Registration Number
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />BVN of the Partners
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />2
                  Suitable References.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Tax Identification Number.
                </li>
              </ul>

              <div class="line3"></div>
              <h5>Registered Clubs, Societies, Associations/ Faith Based Organizations</h5>
              <PayAttitude />
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Registration Number
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />BVN of the Signatories and Trustees
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />2
                  Suitable References.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Tax Identification Number.
                </li>
              </ul>

              <div class="line3"></div>
              <h5>Unregistered Clubs, Associations</h5>
              <PayAttitude />
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />BVN of the Exco Members/Signatories
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />2
                  Suitable References.
                </li>
              </ul>

              <!-- <div class="line3"></div>
              <h5>Unregistered Business</h5>
              <PayAttitude />
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />2
                  Suitable References.
                </li>
              </ul> -->

              <div class="line3"></div>
              <h5>Limited Liability Companies</h5>
              <PayAttitude />
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Company's Registration Number (RC Number)
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />BVN for All Directors and
                  Shareholders/signatories
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />2
                  Suitable References.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Tax Identification Number.
                </li>
              </ul>
            </div>
            <div class="line3"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="agency-benefits grey_bg" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out"
      id="ineed">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Pay By Transfer
                <br />
                <span>Revolutionizing Payment Experiences:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
              <h4>Hope Wallet</h4>
              <PayAttitude />
              <p>
                Welcome to Pay By Transfer, where simplicity meets security in the world of payments. Our innovative
                service offers a seamless solution for settling payments by directly transferring funds to a merchant's
                account, eliminating the need for traditional debit card transactions. With instant transaction receipts
                and a streamlined process, Pay By Transfer is your gateway to a new era of hassle-free payments.
              </p>
              <h5>Why Choose Pay By Transfer?</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />
                  Experience the convenience of effortless payments without the need for debit cards or cash. Our secure
                  platform ensures the safety of your financial information while offering:
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Instant Transaction Receipts: Receive
                  real-time transaction receipts immediately after making a payment, providing you with a clear record of
                  your transactions.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Secure Transactions: Trust in our commitment
                  to keeping your data secure throughout the transfer process, enabling you to make payments with
                  confidence.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Simplified Merchant Interaction: Merchants
                  benefit from our straightforward system that allows for direct transfers to their accounts, minimizing
                  complexity and fees.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Transparent Process: Gain visibility into
                  the entire payment journey, from initiation to completion, ensuring a clear understanding of each
                  transaction.
                </li>

              </ul>
              <h5>How It Works:</h5>
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Choose Pay By Transfer: Opt for Pay By
                  Transfer at the merchant's checkout.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Initiate Transfer: Follow simple steps to
                  transfer funds directly from your bank account to the merchant's account.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Instant Receipt: Receive an instant
                  transaction receipt as confirmation of payment.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Merchant Notified: The merchant is promptly
                  notified of the successful payment, ensuring a smooth transaction.
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <back-to-top bottom="50px" right="50px">
      <button type="button" class="btn backtotop btn-to-top">
        <font-awesome-icon icon="chevron-up" class />
      </button>
    </back-to-top>

    <Footer />
  </div>
</template>

<script>
import HeaderNavA from "../../components/HeaderNav/HeaderNavA";
import PayAttitude2 from "../../components/Logo/PayAttitude2";
import PayAttitude from "../../components/Logo/PayAttitude";
import BackToTop from "vue-backtotop";
import Footer from "../../components/Footer/Footer";

export default {
  name: "Agency.vue",
  components: {
    HeaderNavA,
    PayAttitude2,
    PayAttitude,
    BackToTop,
    Footer,
  },

  data() {
    return {
      toggle: false,
      //   toggle2: false,
    };
  },
  methods: {},
};
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  /*color: #FECB32;*/
}

.slider {
  width: 100%;
  height: 544px !important;
  position: absolute;
  z-index: 0;
}

#__BVID__17__BV_tab_controls_ {
  background: #eff7f9 !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
  /* letter-spacing: 0.17px; */
}

.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}

.navbar-light .navbar-nav .nav-link a:hover {
  color: #fecb32;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
  /*border-bottom: 3px solid #FECB32;*/
}

.navbar-light .navbar-nav .nav-link.active {
  color: #fecb32 !important;
  /* border-bottom: 3px solid #FECB32; */
}

.navbar-light .navbar-nav .nav-link a.active {
  color: #fecb32 !important;
}

.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
  /* border-bottom: 3px solid #FECB32; */
}

.nav-link.active {
  color: #fecb32;
}

.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}

.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}

/*.plus_icon{*/
/*margin-right: 1rem;*/
/*font-size: 20px;*/
/*}*/
.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}

.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}

.yellow_bg a:hover {
  color: #000000 !important;
}

.yellow_bg>.navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}

.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}

.about-title h1 {
  font-family: Montserrat-Regular;
  font-size: 30px;
  color: #000000;
  letter-spacing: 0;
  padding-bottom: 0;
  padding-top: 5rem;
  text-align: center;
}

.about-title p {
  font-family: Montserrat-Regular;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  line-height: 1rem;
}

.slider-title3 {
  /*position: absolute;*/
  width: 100%;
  text-align: left;
  /*padding: 0 5%;*/
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 544px;
}

.slider-title3 h1 {
  font-family: Montserrat-Regular;
  font-size: 51px;
  color: #000000;
  letter-spacing: 0.7px;
  padding-bottom: 2rem;
}

.slider-title3 h1 span {
  font-family: Montserrat-SemiBold;
  color: #000000;
  letter-spacing: 0.7px;
  font-weight: 700;
}

.slider-info {
  display: flex;
  align-items: center;
}

.slider-info img {
  padding-right: 2rem;
}

.slider-info p {
  font-family: Montserrat-Regular;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
  margin-bottom: 0;
}

.slider-info p span {
  font-family: Montserrat-Medium;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}

@keyframes image_moving1_inner {
  0% {
    transform: translateY(0);
  }

  to {
    transform: translateY(-20%);
  }
}

.image_moving1_inner img {
  width: 100%;
  object-fit: contain;
}

@keyframes image_moving2_inner {
  0% {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

.image_moving2_inner img {
  width: 90%;
  object-fit: contain;
  transform: rotate(40deg);
}

.landing_page_right_section h2 {
  font-family: Montserrat-Medium;
  font-weight: 600;
  font-size: 29px;
  color: #000000;
  letter-spacing: 0.4px;
  padding-bottom: 1rem;
}

.landing_page_right_section p {
  font-family: Montserrat-Regular;
  font-size: 18px;
  color: #595959;
  letter-spacing: 0.28px;
  padding-bottom: 1rem;
}

.landing_page_right_section h6 {
  font-family: Montserrat-Medium;
  font-size: 18px;
  color: #636868;
  letter-spacing: 0.25px;
  font-weight: 600;
  padding-bottom: 1rem;
}

.open_account_btn {
  font-family: Montserrat-SemiBold;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.17px;
  background: #fecb32;
  border-radius: 5px;
  padding: 10px 2rem;
}

.product_solutions ul {
  padding-left: 0;
  list-style-type: none;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .about-title h1 {
    font-size: 22px;
    color: #000000;
    padding-bottom: 0;
    padding-top: 4rem;
  }
}
</style>
