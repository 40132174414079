import { ApiService } from "./api.service";

const CardService = {
    requestForCard: async (data) => {
        return new Promise(function (resolve, reject) {
            ApiService.post('/api/web/transaction/debit', data)
                .then((res) => {
                    if (res.data.success) return resolve(res.data);
                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

};



export {
    CardService
};
