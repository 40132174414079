import { ApiService } from "../api.service";

const CorporateAccountService = {
    //BVN VALIDATION for Single Account
    validateBvn: async ({ Account, DateOfBirth, Phone, Corporate, Code, Bvn, token }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/corporate/account/validate', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    Bvn,
                    DateOfBirth,
                    Phone,
                    Corporate,
                    Code
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/account/validate"

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    },

    //Create Single Account
    CreateAccount: async ({
        Id,
        token,
        Account,
        Corporate,
        Email,
        Nationality,
        FirstName,
        MiddleName,
        LastName,
        Gender,
        DateOfBirth,
        NkName,
        NkRelationship,
        NkPhone,
        NkAddress,
        ResState,
        ResTown,
        ResAddress,
        ResCountry,
        BizAddress,
        BizCountry,
        BizState,
        BizLGA,
        TIN,
        NIN,
        Occupation,
        MaidenName,
        ZipCode,
        CompanyIntro,
        CompanyId,
        OTP,
        Code,
        Product,
        Pin
    }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/corporate/account/initiate', localStorage.getItem("licence"))
                },
                data: {
                    Id,
                    Account,
                    Corporate,
                    Email,
                    Nationality,
                    FirstName,
                    MiddleName,
                    LastName,
                    Gender,
                    DateOfBirth,
                    NkName,
                    NkRelationship,
                    NkPhone,
                    NkAddress,
                    ResState,
                    ResTown,
                    ResAddress,
                    ResCountry,
                    BizAddress,
                    BizCountry,
                    BizState,
                    BizLGA,
                    TIN,
                    NIN,
                    Occupation,
                    MaidenName,
                    ZipCode,
                    CompanyIntro,
                    CompanyId,
                    OTP,
                    Code,
                    Product,
                    Pin,
                    Channel: "web"
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/account/initiate"

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    },

    //Validate Bulk account
    bulkAccountValidate: async ({ Account, Corporate, data, token }) => {
        const formData = new FormData();
        formData.set("Account", Account);
        formData.set("Corporate", Corporate);
        formData.set("data", data);

        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/corporate/accounts/validate', localStorage.getItem("licence"))
                },
                data: formData,
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/accounts/validate"

            })
                .then((res) => {
                    if (res.data.success) {
                        return resolve(res.data);
                    }
                    else {
                        reject(res.data)
                    }
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });
    },
    //Validate Bulk wallet
    bulkWalletValidate: async ({ Account, Corporate, data, token }) => {
        const formData = new FormData();
        formData.set("Account", Account);
        formData.set("Corporate", Corporate);
        formData.set("data", data);

        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/corporate/wallets/validate', localStorage.getItem("licence"))
                },
                data: formData,
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/wallets/validate"

            })
                .then((res) => {
                    if (res.data.success) {
                        return resolve(res.data);
                    }
                    else {
                        reject(res.data)
                    }
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });
    },

    //Initiate bulk wallet
    bulkWalletInitiate: async ({ Account, Corporate, Id, Pin, token }) => {

        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/corporate/wallets/initiate', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    Id,
                    Corporate,
                    Pin
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/wallets/initiate"

            })
                .then((res) => {

                    if (res.data.success) {
                        return resolve(res.data);
                    }
                    else {
                        reject(res.data)
                    }
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });
    },

    bulkAccountInitiate: async ({ Account, Corporate, Id, Pin, token }) => {

        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/corporate/accounts/initiate', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    Id,
                    Corporate,
                    Pin
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/accounts/initiate"

            })
                .then((res) => {

                    if (res.data.success) {
                        return resolve(res.data);
                    }
                    else {
                        reject(res.data)
                    }
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    },

};



export {
    CorporateAccountService
};
