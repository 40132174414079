<template>
  <div class="hope-landing">
    <div class="about-page-header">
      <HeaderNavA />

      <div class="about-title">
        <h1>
          Hope PSBank
          <br />
          <span>How to Transact</span>
        </h1>
        <div class="row">
          <div class="col-md-7 ml-auto mr-auto">
            <div class="text-center">
              <p>
                Your phone number is your means of payment for all our services
                and solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="container"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      id="pro"
    >
      <div class="become_agent">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Pull 
                <span>(Request for Money):</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_how_to">
              <h1>Person 2 Person</h1>
              <div class="product_solutions">
                <ul>
                  <li>
                    <font-awesome-icon icon="caret-right" class="caret-right" />Login to Hope PSBank Digital App or Dial <a href="tel:*569*9#">*569*9#</a> on your Phone for USSD
                  </li>
                  <li>
                    <font-awesome-icon icon="caret-right" class="caret-right" />For Digital App, select request money; For *569*9# select PayAttitude.
                  </li>
                  <li>
                    <font-awesome-icon icon="caret-right" class="caret-right" />Select your Account that is to receive the money being requested.
                  </li>
                  <li>
                    <font-awesome-icon icon="caret-right" class="caret-right" />For <a href="tel:*569*9#">*569*9#</a>, select receive money.
                  </li>
                  <li>
                    <font-awesome-icon icon="caret-right" class="caret-right" />Enter Amount.
                  </li>
                  <li>
                    <font-awesome-icon icon="caret-right" class="caret-right" />Enter phone number of the person who is to pay you and the person gets notified to authorize. For our App, you enjoy the unique feature of selecting the phone number from your phone contact list.
                  </li>
                  <li>
                    <font-awesome-icon icon="caret-right" class="caret-right" />The person enters his/her PIN on his/her phone, the person's account is debited and your account is credited.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="agency-benefits grey_bg"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Push 
                <span>(Send Money):</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
                <div class="product_solutions">
                    <ul>
                        <li>
                            <font-awesome-icon icon="caret-right" class="caret-right" />Login to Hope PSBank Digital App or Dial <a href="tel:*569*9#">*569*9#</a> on your Phone for USSD
                        </li>
                        <li>
                            <font-awesome-icon icon="caret-right" class="caret-right" />For Digital App, select send money; For *569*9# select PayAttitude.
                        </li>
                        <li>
                            <font-awesome-icon icon="caret-right" class="caret-right" />Select your Account to be debited.
                        </li>
                        <li>
                            <font-awesome-icon icon="caret-right" class="caret-right" />For <a href="tel:*569*9#">*569*9#</a>, select send money to phone.
                        </li>
                        <li>
                            <font-awesome-icon icon="caret-right" class="caret-right" />Enter Amount to be sent.
                        </li>
                        <li>
                            <font-awesome-icon icon="caret-right" class="caret-right" />Enter phone number to be paid For our App, you enjoy the unique feature of selecting the phone number from your phone contact list.
                        </li>
                        <li>
                            <font-awesome-icon icon="caret-right" class="caret-right" />Enter your PIN to authorize payment, your account will be debited and the account of the person will be credited.
                        </li>
                    </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="agency-benefits white_bg2"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      id="ineed"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Cash on <span>ATM</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Select cardless/PayAttitude on any ATM. This can be safely done by yourself or anyone else that you ask to withdraw at the ATM. Only you can approve with your PIN on your phone or personal device.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Input phone number
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Input Amount to be withdrawn
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />We locate your phone anywhere you are, you enter your PIN on your phone to authorise. We don't ask you to enter PIN or Code on the ATM. We have raised the bar. Your Phone is your payment remote control.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />ATM dispenses cash
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="agency-benefits grey_bg"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Cash Withdrawal at 
                <span>Agent Outlet:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Agent asks for your phone number and amount to be withdrawn, s/he inputs the same on his Agent App or Phone for cash withdrawal. This can be safely done by yourself or anyone else that you ask to withdraw at the agent's location. Only you can approve with your PIN on your phone or personal device.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />We locate your phone anywhere you are, you enter your PIN on your phone to authorise. We don't ask you to enter PIN or Code on the agent's device. We have raised the bar. Your Phone is your payment remote control.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Your account is debited and the Agent's account is credited.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />The Agent releases cash to you or any person that you send to withdraw at the agent location. This can be safely done by yourself or anyone else that you ask to withdraw at the agent location. Only you can approve with your PIN on your phone or personal device.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="agency-benefits white_bg2"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Cash Deposit at 
                <span>Agent Outlet:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Give your phone number and the cash to be deposited to Agent
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Agent confirms cash
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Agent select Deposit or Send Money and inputs your phone number in the App or *569*9#
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />The agent's account is debited, your account is credited and you receive an instant SMS alert.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="agency-benefits grey_bg"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Pay at 
                <span>Merchant POS:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
                <div class="product_solutions">
                    <ul>
                        <li>
                        <font-awesome-icon icon="caret-right" class="caret-right" />At checkout, give your phone to the cashier and this is inputted unto merchant POS or Phone using <a href="tel:*569*9#">*569*9#</a>. This can be safely done by yourself or anyone else that you ask to buy at merchant locations. Only you can approve with your PIN on your phone or personal device.
                        </li>
                        <li>
                        <font-awesome-icon icon="caret-right" class="caret-right" />We locate your phone anywhere you are, you enter your PIN on your phone to authorise. We don't ask you to enter PIN or Code on the merchant or cashier's device. We have raised the bar. Your Phone is your payment remote control.
                        </li>
                        <li>
                        <font-awesome-icon icon="caret-right" class="caret-right" />Your account is debited and the POS prints “approved slip”
                        </li>
                    </ul>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="agency-benefits white_bg2"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="agent_benefit">
              <h1>
                Pay on 
                <span>the Web:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-9">
            <div class="agent_benefit2 features_wrap">
              <ul>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />At checkout, select PayAttitude and enter your phone number. This can be safely done by yourself or anyone else that you ask to buy on the web. Only you can approve with your PIN on your phone or personal device. We don't ask you to enter your name, expiry date, etc. on the web.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />We locate your phone anywhere you are, you enter your PIN on your phone to authorise. We don't ask you to enter PIN or Code on the web. We have raised the bar. Your Phone is your payment remote control.
                </li>
                <li>
                  <font-awesome-icon icon="caret-right" class="caret-right" />Your account is debited and the transaction is approved on the web.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <back-to-top bottom="50px" right="50px">
      <button type="button" class="btn backtotop btn-to-top">
        <font-awesome-icon icon="chevron-up" class />
      </button>
    </back-to-top>

    <Footer />
  </div>
</template>

<script>
import HeaderNavA from "../../components/HeaderNav/HeaderNavA";
// import PayAttitude from "../../components/Logo/PayAttitude";
import BackToTop from "vue-backtotop";
import Footer from "../../components/Footer/Footer";

export default {
  name: "Agency.vue",
  components: {
    HeaderNavA,
    // PayAttitude,
    BackToTop,
    Footer,
  },

  data() {
    return {
      toggle: false,
      //   toggle2: false,
    };
  },
  methods: {},
};
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  /*color: #FECB32;*/
}
.slider {
  width: 100%;
  height: 544px !important;
  position: absolute;
  z-index: 0;
}

#__BVID__17__BV_tab_controls_ {
  background: #eff7f9 !important;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
  /* letter-spacing: 0.17px; */
}
.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}
.navbar-light .navbar-nav .nav-link a:hover {
  color: #fecb32;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
  /*border-bottom: 3px solid #FECB32;*/
}
.navbar-light .navbar-nav .nav-link.active {
  color: #fecb32 !important;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link a.active {
  color: #fecb32 !important;
}
.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
  /* border-bottom: 3px solid #FECB32; */
}
.nav-link.active {
  color: #fecb32;
}
.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}
.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}

/*.plus_icon{*/
/*margin-right: 1rem;*/
/*font-size: 20px;*/
/*}*/
.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg a:hover {
  color: #000000 !important;
}
.yellow_bg > .navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}
.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}
.about-title h1 {
  font-family: Montserrat-Regular;
  font-size: 30px;
  color: #000000;
  letter-spacing: 0;
  padding-bottom: 0;
  padding-top: 5rem;
  text-align: center;
}
.about-title p {
  font-family: Montserrat-Regular;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  line-height: 1rem;
}
.slider-title3 {
  /*position: absolute;*/
  width: 100%;
  text-align: left;
  /*padding: 0 5%;*/
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 544px;
}
.slider-title3 h1 {
  font-family: Montserrat-Regular;
  font-size: 51px;
  color: #000000;
  letter-spacing: 0.7px;
  padding-bottom: 2rem;
}
.slider-title3 h1 span {
  font-family: Montserrat-SemiBold;
  color: #000000;
  letter-spacing: 0.7px;
  font-weight: 700;
}
.slider-info {
  display: flex;
  align-items: center;
}
.slider-info img {
  padding-right: 2rem;
}
.slider-info p {
  font-family: Montserrat-Regular;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
  margin-bottom: 0;
}
.slider-info p span {
  font-family: Montserrat-Medium;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}
@keyframes image_moving1_inner {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(-20%);
  }
}
.image_moving1_inner img {
  width: 100%;
  object-fit: contain;
}

@keyframes image_moving2_inner {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
.image_moving2_inner img {
  width: 90%;
  object-fit: contain;
  transform: rotate(40deg);
}

.landing_page_right_section h2 {
  font-family: Montserrat-Medium;
  font-weight: 600;
  font-size: 29px;
  color: #000000;
  letter-spacing: 0.4px;
  padding-bottom: 1rem;
}
.landing_page_right_section p {
  font-family: Montserrat-Regular;
  font-size: 18px;
  color: #595959;
  letter-spacing: 0.28px;
  padding-bottom: 1rem;
}
.landing_page_right_section h6 {
  font-family: Montserrat-Medium;
  font-size: 18px;
  color: #636868;
  letter-spacing: 0.25px;
  font-weight: 600;
  padding-bottom: 1rem;
}
.open_account_btn {
  font-family: Montserrat-SemiBold;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.17px;
  background: #fecb32;
  border-radius: 5px;
  padding: 10px 2rem;
}
.product_solutions ul {
  padding-left: 0;
  list-style-type: none;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .about-title h1 {
    font-size: 22px;
    color: #000000;
    padding-bottom: 0;
    padding-top: 4rem;
  }
}
</style>
