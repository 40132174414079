<template>
  <div class="poster-bg-">
    <div class="poster"></div>
    <div class="dashboard-page">
      <Loader v-if="loading" :show-full="true" loading-text="Processing" />

      <div class>
        <HeaderNavBiz2 />
        <div class="register_now">
          <div class="container">
            <div class="text-left">
              <h6 class="welcome_username" v-if="user.corporateAccounts[0]">
                Hi, <span v-if="user.fullName">{{ user.firstName }}</span>
              </h6>
              <h6 v-else>Hi, User</h6>
            </div>
            <div class="hope_account_opening">
              <h1 class>Hope Salary Current Opening</h1>
              <p>Our account opening process is digital.</p>
            </div>
            <div class="main_dashboard">
              <div class="row">
                <div class="col-md-12">
                  <div class="steps_to_acc_open">
                    <h2>Hope Salary Current Account</h2>
                  </div>
                  <div class="main_dashboard_inner">
                    <div class="kindly_enter_details mb-3">
                      <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                      <p>
                        Open an account instantly by filling the form below.
                      </p>
                    </div>
                    <form @submit.prevent="BvnValidation">
                      <div class="form-table">
                        <div>
                          <!-- Select account type/category start -->
                          <div class="category">
                            <!-- <div class="steps_to_acc_open">
                              <h2>Select Category</h2>
                            </div>-->

                            <!-- <div class="label-title2">
                              <label>Account Type</label>
                              <div class="account_select2">
                                <div class="input-amount">Hope Salary Savings</div>
                              </div>
                            </div>-->

                            <div class="label-title">
                              <label>Account</label>
                              <div class="account_select">
                                <select v-model="Account" required id class="account_type">
                                  <option value>Select Account Category</option>
                                  <option v-for="item in user.corporateAccounts" :key="item + 'a'"
                                    :value="item.accountNumber">
                                    {{ item.accountName }} -
                                    {{ item.accountNumber }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                          </div>

                          <!-- Input fields for BVN ACCOUNTS  start-->
                          <div class="bvn-accounts">
                            <div class="input_fields">
                              <div class="label-title2">
                                <label>BVN <span class="red-star">*</span></label>
                                <div class="account_select2">
                                  <the-mask mask="###########" required type="tel" class="input-amount" v-model="Bvn"
                                    placeholder="BVN" />
                                </div>
                              </div>

                              <div class="label-title2">
                                <label>Phone Number
                                  <span class="red-star">*</span></label>
                                <div class="account_select2">
                                  <the-mask mask="###########" type="tel" required class="input-amount" v-model="Phone"
                                    placeholder=" " />
                                </div>
                              </div>

                              <div class="label-title2">
                                <label>Date of Birth
                                  <span class="red-star">*</span></label>
                                <DatePicker v-model="DateOfBirth" :disabled-date="disabledVer" valueType="format"
                                  format="DD-MM-YYYY" placeholder="DD-MM-YYYY"></DatePicker>
                              </div>

                              <div class="label-title2" v-if="Code.code == '103'">
                                <label>Date of Incorporation
                                  <span class="red-star">*</span></label>
                                <DatePicker v-model="DateOfInc" valueType="format" format="DD-MM-YYYY"
                                  placeholder="DD-MM-YYYY"></DatePicker>
                              </div>
                              <!-- submit -->
                              <div class="send-or-download2">
                                <div class="continue-button">
                                  <a @click="$router.go(-1)">
                                    <div class="continue-btn">
                                      <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                                    </div>
                                  </a>
                                </div>
                                <div class="continue-button">
                                  <b-button type="submit" :disabled="isDisabled1" class="continue-btn">
                                    Submit
                                    <font-awesome-icon icon="angle-right" class="angle-icon" />
                                  </b-button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Input fields for BVN ACCOUNTS  end-->
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </div>
  </div>
</template>

<script>
import HeaderNavBiz2 from "../../components/HeaderNav/HeaderNavBiz2";
import { TheMask } from "vue-the-mask";
import Loader from "../../components/Loader/Loader.vue";
import { CorporateAccountService } from "../../services/CorporateAccount/corporateAccountCreation.services.js";
import { mapState, mapGetters } from "vuex";
import Footer2 from "../../components/Footer/Footer2";
// import terms from "../../components/Terms/terms";

export default {
  name: "SalarySavings",
  components: {
    HeaderNavBiz2,
    TheMask,
    Loader: Loader,
    Footer2,
    // terms
  },
  data() {
    return {
      image: "",
      isDisabled: true,
      loading: false,
      Code: "104",
      Account: "",
      Phone: "",
      Bvn: "",
      DateOfBirth: "",
    };
  },
  computed: {
    NewAccountType() {
      return this.$store.getters.ACCOUNT;
    },
    ...mapGetters({ user: "GET_USER" }),
    ...mapState({
      token: (state) => state.User.AUTHENTICATION_TOKEN,
    }),
    Corporate() {
      let account = this.user.corporateAccounts;
      return account[account.findIndex((a) => a.accountNumber == this.Account)]
        .customerId;
    },
  },
  watch: {
    DateOfBirth(newval) {
      this.checkDateValidity(newval);
      if (newval && this.terms) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
      if (newval && this.Bvn && this.Phone) {
        this.isDisabled1 = false;
      } else {
        this.isDisabled1 = true;
      }
    },
  },
  methods: {
    disabledVer(date) {
      let tdate = new Date();
      let year = tdate.getFullYear();
      let month = tdate.getMonth();
      let day = tdate.getDate();
      const today = new Date(year - 18, month, day);
      today.setHours(0, 0, 0, 0);

      return date > today
    },
    //Check Date Validity
    checkDateValidity(date) {
      let tdate = new Date();
      let year = tdate.getFullYear();
      let month = tdate.getMonth();
      let day = tdate.getDate();
      let newYear = new Date(year - 18, month, day);
      let dob = new Date(date);

      if (dob > newYear) {
        this.DateOfBirth = "";
        return this.$toastr.error("Must be 18 years or older", "Error");
      }

      // alert("okay to proceed")
    },
    //For Bvn
    BvnValidation() {
      this.loading = true;
      CorporateAccountService.validateBvn({
        token: this.token,
        Code: this.Code,
        Bvn: this.Bvn,
        Phone: this.Phone,
        DateOfBirth: this.DateOfBirth,
        Corporate: this.Corporate,
      })
        .then((res) => {
          this.$store.commit("SET_ACCOUNT_ID", res.id);
          this.$store.commit("SET_ACCOUNT_NUMBER", this.Account);
          this.$store.commit("SET_BVN_DETAILS", res.data);
          this.$router.push("/salarysavings/complete");
        })
        .catch((error) => {
          this.$toastr.error(error.status, "Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    next_page() {
      this.$store.commit("SET_ACCOUNT_OTP", this.otp);
      this.$store.commit("SET_ACCOUNT_TYPE", this.Code);
      this.$router.push("/Verify_");
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}

.navbar {
  padding: 0;
}

.quick-transfer {
  padding: 0 2.2rem;
}

.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}

.modal-header {
  padding: 1rem 1rem 0 !important;
}

.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}

.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}

.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
}

.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
}

.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}

.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}

.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}

.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}

.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #ffffff !important;
}

.yellow_bg>.navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}

.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}

.main_dashboard {
  width: 75%;
  margin: 0 auto;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .main_dashboard {
    width: 100% !important;
  }
}
</style>
