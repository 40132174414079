import {
    ApiService
} from "./api.service";

const vasAirtime_Data = {
    vasCategory: async () => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                    method: 'GET',
                    url: ApiService.getBaseUrl() + "/api/web/catalog/vas/category/1",
                    headers: {
                        "Content-Type": "application/json",
                        "Hash": ApiService.computeHash('/api/web/catalog/vas/category/1', localStorage.getItem('licence'))
                    },
                    redirect: 'follow'
                })
                .then((res) => {
                    let data = res.data
                     resolve(data);
                     window.console.log("this is action category",data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });
       

    },

};



export {
    vasAirtime_Data
};
