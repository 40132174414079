<template>
  <div class="poster-bg-">
    <div class="poster"></div>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />

    <div class="dashboard-page registration-page">
      <div class>
        <HeaderNavIB />
        <div class="register_now">
          <div class="container">
            <div class="main_dashboard">
              <div class="row">
                <div class="col-md-12">
                  <div class="main_dashboard_inner">
                    <div class="transfer_money2 text-center pt-3">
                      <p class="frequently-used text-center">SETUP ACCOUNT PIN</p>
                    </div>
                    <!-- <div class="text-left">
                      <h6 class="welcome_username">Hi, {{user.accounts[0].accountName}}</h6>
                    </div>-->
                    <div class="kindly_enter_details mb-3">
                      <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                      <p>
                        To set up pin for your Corporate Account, we require your
                        Account Number
                      </p>
                    </div>
                    <form @submit.prevent="SetPin">
                      <div class="form-table">
                        <div class="label-title2">
                          <label>Account Number *</label>
                          <div class="account_select2">
                            <the-mask
                              mask="##########"
                              type="tel"
                              class="input-amount"
                              required
                              placeholder
                              v-model="Account"
                            />
                          </div>
                        </div>

                        <div class="label-title2">
                          <label>Enter Pin *</label>
                          <div class="account_select2">
                            <input
                              mask="########"
                              type="password"
                              class="input-amount"
                              maxlength="8"
                              minlength="6"
                              required
                              placeholder="6 to 8 digits"
                              v-model="pin1"
                            />
                          </div>
                        </div>

                        <div class="label-title2">
                          <label>Confirm Pin *</label>
                          <div class="account_select2">
                            <input
                              mask="########"
                              type="password"
                              class="input-amount"
                              placeholder="6 to 8 digits"
                              maxlength="8"
                              minlength="6"
                              required
                              v-model="pin"
                            />
                          </div>
                        </div>
                        <div
                          class="h6 mt-3 mb-3 mx-4 text-danger"
                          v-if="isPinMatch"
                        >Pin does not match</div>

                        <div class="send-or-download2">
                          <div class="continue-button">
                            <a @click="$router.go(-1)">
                              <div class="continue-cib-btn ">
                                <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                              </div>
                            </a>
                          </div>
                          <div class="continue-button">
                            <b-button
                              type="submit"
                              :disabled="isDisabled_"
                              class="continue-cib-btn"
                            >
                              Submit
                              <font-awesome-icon icon="angle-right" class="angle-icon" />
                            </b-button>

                            <!--<router-link to="Verify"><button class="continue-cib-btn">Verify<font-awesome-icon icon="angle-right" class="angle-icon"/></button></router-link>-->
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer3 />

      <b-modal
        id="modal-center3"
        centered
        v-model="otp_modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <h1 class="pin-info text-center">
              Enter the OTP that was
              <br />sent to your phone/email
            </h1>
            <div class="dialpad">
              <div class="form-group">
                <the-mask
                  type="password"
                  class="form-control"
                  v-model="otp"
                  mask="######"
                  id="txtBox"
                  placeholder="******"
                />
              </div>
              <Keyboard
                @pressed="otp = $event"
                :pinLength="otp"
                @completed="save_otp"
                :selfValue="otp"
              ></Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import HeaderNavIB from "../../../../components/HeaderNav/HeaderNavIB";
import { TheMask } from "vue-the-mask";
import { CorporateAccountService } from "../../../../services/CorporateAccount/corporateAccountAuth.services.js";
import Loader from "../../../../components/Loader/Loader.vue";
import Keyboard from "../../../../components/Keyboard/Keyboard.vue";
import Footer3 from "../../../../components/Footer/Footer3";
// import { mapGetters } from "vuex";

export default {
  name: "Signup.vue",
  components: {
    HeaderNavIB,
    TheMask,
    Loader,
    Keyboard,
    Footer3,
  },
  data() {
    return {
      date: null,
      isPinMatch: false,
      isDisabled_: true,
      image: "",
      pin: "",
      pin1: "",
      Account: "",
      Phone: "",
      loading: false,
      otp_modal: false,
      otp: "",
    };
  },

  watch: {
    pin(newval) {
      if (newval.length >= 6) {
        if (newval == this.pin1) {
          this.isDisabled_ = false;
          this.isPinMatch = false;
          return;
        }
        this.isDisabled_ = true;
        this.isPinMatch = true;
      }
    },
  },

  methods: {
    SetPin() {
      this.loading = true;
      let data = {
        Account: this.Account,
        Pin: this.pin,
      };
      CorporateAccountService.setPin(data)
        .then((res) => {
          this.$toastr.success(res.status, { timeOut: 5000 });
          this.$router.push("/dashboard_");
          //this.otp_modal = true;
        })
        .catch((error) => {
          this.$toastr.error(error.status, "Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}
.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.quick-transfer {
  padding: 0 2.2rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
  /* letter-spacing: 0.17px; */
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}
.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}
.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}
/*.plus_icon{*/
/*margin-right: 1rem;*/
/*font-size: 20px;*/
/*}*/
.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #ffffff !important;
}
.yellow_bg > .navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}
.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}
.registration-page {
  height: 100vh !important;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.main_dashboard {
  width: 70%;
  margin: 0 auto;
}
/* .poster {
  background-color: rgb(0, 94, 194, 0.1) !important;
} */

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .poster-bg {
    height: 100vh;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .poster-bg {
    height: 100vh;
  }
  .main_dashboard {
    width: 100% !important;
  }
}
</style>
