/* eslint-disable no-useless-escape */
<template>
  <div class="poster-bg">
    <div class="poster"></div>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />
    <div class="dashboard-page registration-page">
      <div>
        <HeaderNavIB />
        <div class="register_now">
          <div class="container">
            <div class="main_dashboard">
              <div class="row">
                <div class="col-md-12">
                  <div class="main_dashboard_inner">
                    <div class="transfer_money2 text-center">
                      <p class="frequently-used text-center">
                        Setup PIN for New Account
                      </p>
                    </div>
                    <div class="current_tab_title mb-3">
                      <font-awesome-icon icon="play" class="play-icon" />
                      <p class>
                        Create PIN to secure account for your Digital
                        Banking
                      </p>
                    </div>
                    <div class="kindly_enter_details mb-3">
                      <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                      <p>
                        <b>
                          Please note that your <strong>PIN is required</strong>
                          to complete any transaction
                        </b>
                      </p>
                    </div>
                    <ValidationObserver v-slot="{ passes }">
                      <form @submit.prevent="passes(triggerOtp)" action>
                        <div class="form-table2">
                          <div class="line3"></div>
                          <div class="label-title2">
                            <label>Account Number *</label>
                              <div class="account_select2">
                                <input
                                  type="tel"
                                  class="input-amount"
                                  placeholder="----------"
                                  disabled
                                />
                              </div>
                          </div>
                          <div class="label-title2">
                            <label>Phone Number *</label>
                              <div class="account_select2">
                                <input
                                  type="tel"
                                  class="input-amount"
                                  placeholder="-----------"
                                  disabled
                                />
                              </div>
                          </div>
                          <div class="line3"></div>
                          <div class="label-title2">
                            <label>Create New PIN *</label>
                            <ValidationProvider name="pin" rules="required" v-slot="{ errors }">
                              <div class="account_select2">
                                <the-mask
                                  mask="########"
                                  type="password"
                                  v-model="pin"
                                  ref="pin"
                                  class="input-amount"
                                  placeholder="******"
                                />
                              </div>
                              <span style="font-size:13px; color:red" class>
                                <span v-if="errors[0]">
                                  <font-awesome-icon
                                    icon="ban"
                                    size="1x"
                                    class="ban-icon text-danger"
                                  />
                                </span>
                                {{ errors[0] }}
                              </span>
                            </ValidationProvider>
                          </div>
                          <div class="label-title2">
                            <label>Confirm New PIN *</label>
                            <ValidationProvider
                              name="pins"
                              rules="required|confirmed:pin"
                              v-slot="{ errors }"
                            >
                              <div class="account_select2">
                                <the-mask
                                  mask="########"
                                  type="password"
                                  v-model="pin1"
                                  data-vv-name="pin"
                                  class="input-amount"
                                  placeholder="******"
                                />
                              </div>
                              <span style="font-size:13px; color:red" class>
                                <span v-if="errors[0]">
                                  <font-awesome-icon
                                    icon="ban"
                                    size="1x"
                                    class="ban-icon text-danger"
                                  />
                                </span>
                                {{ errors[0] }}
                              </span>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="send-or-download2">
                          <div class="continue-button">
                            <a @click="$router.go(-1)">
                              <div class="continue-btn ">
                                <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                              </div>
                            </a>
                          </div>
                          <div class="continue-button">
                            <b-button class="continue-btn" type="submit">
                              Submit
                              <font-awesome-icon icon="angle-right" class="angle-icon" />
                            </b-button>
                            <!--<router-link to="Complete"><button class="continue-btn">Submit <font-awesome-icon icon="angle-right" class="angle-icon"/></button></router-link>-->
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
      <b-modal
        id="modal-center3"
        centered
        v-model="otp_modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <h1 class="pin-info text-center">
              Enter the OTP that was
              <br />sent to your phone/email
            </h1>
            <div class="dialpad">
              <div class="form-group">
                <the-mask
                  type="password"
                  class="form-control"
                  v-model="otp"
                  mask="######"
                  id="txtBox"
                  placeholder="******"
                />
              </div>
              <Keyboard
                @pressed="otp = $event"
                :pinLength="otp"
                @completed="Complete"
                :selfValue="otp"
              ></Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>
      <b-modal
        id="modal-center"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
        v-model="showModal"
      >
        <form ref="form" @submit.stop.prevent>
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <img
              src="../../../assets/images/icons/check.svg"
              alt="Hope Bank"
              class="modal_congrats_img"
            />
            <h1 class="pin-info text-center">Congratulations</h1>
            <div class="line4"></div>
            <!-- <p class="modal_congrats" v-if="Authorized">
              You have successfully registered for Internet Banking. Click OK to
              Create Makers & Checkers
            </p>-->
            <p class="modal_congrats">
              You have successfully registered for Internet Banking. Click OK to
              Login
            </p>
            <!-- <router-link to="makers" v-if="Authorized">
              <button class="ok-btn">OK</button>
            </router-link>-->
            <router-link to="LoginBusiness">
              <button class="ok-btn">OK</button>
            </router-link>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import HeaderNavIB from "../../../components/HeaderNav/HeaderNavIB";
import Loader from "../../../components/Loader/Loader.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { CorporateAccountService } from "../../../services/CorporateAccount/corporateAccountAuth.services.js";
import { mapState } from "vuex";
import Keyboard from "../../../components/Keyboard/Keyboard.vue";
import Footer2 from "../../../components/Footer/Footer2";

export default {
  components: {
    HeaderNavIB,
    Loader,
    ValidationObserver,
    ValidationProvider,
    Keyboard,
    Footer2
  },
  data() {
    return {
      showTooltip: true,
      //Authorized : true,
      image: "",
      showModal: false,
      loading: false,
      accountNumber: "",
      password: "",
      pin: "",
      pin1: "",
      otp: "",
      otp_modal: false
    };
  },
  computed: {
    ...mapState({
      VALIDATION_CREDENTIALS: state => state.User.VALIDATION_CREDENTIALS,
      CORPORATE: state => state.CreateAccount.CORPORATE_ACCOUNT
    })
  },
  watch: {},
  methods: {
    Complete() {
      this.loading = true;
      this.otp_modal = false;
      const data = {
        CustomerId: this.VALIDATION_CREDENTIALS.customerId,
        CorporateId: this.VALIDATION_CREDENTIALS.corporateId,
        Code: this.otp,
        Account: this.CORPORATE.ACCOUNT,
        PhoneX: this.CORPORATE.PHONE,
        Password: this.password,
        Pin: this.pin,
      };
      CorporateAccountService.complete(data)
        .then(() => {
          this.showModal = true;
        })
        .catch(err => {
          this.$toastr.error(err.status, "Registration Failed!", {
            timeOut: 5000
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    triggerOtp() {
      this.otp_modal = true;
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.quick-transfer {
  padding: 0 2.2rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
  /* letter-spacing: 0.17px; */
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}
.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}
.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}

/*.plus_icon{*/
/*margin-right: 1rem;*/
/*font-size: 20px;*/
/*}*/
.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #ffffff !important;
}
.yellow_bg > .navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}
.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}

.input-amount {
  text-align: left;
}

.my-tooltip-class {
  color: #fecb32 !important;
}
.main_dashboard {
  width: 75%;
  margin: 0 auto;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .main_dashboard {
    width: 100% !important;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
