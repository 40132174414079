import { ApiService } from "./api.service";

const OccupationService = {
    occupationService: async () => {
        return new Promise(function (resolve, reject) {
            ApiService.get('/api/web/catalog')
                .then((res) => {

                    resolve(res.data.professions)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

     //Validate BVN when logged in
     occupationServiceWhenLoggedIn: async () => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'POST',
                url: ApiService.getBaseUrl() + "/api/web/catalog",
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/catalog', localStorage.getItem('licence'))
                },
            })
                .then((res) => {

                    resolve(res.data.professions)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    }

};



export {
    OccupationService
};
