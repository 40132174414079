var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"transaction-receipt-page"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-7 ml-auto mr-auto"},[_c('div',{ref:"html"},[_c('div',{staticClass:"hope-logo mt-5 text-center"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../../assets/images/logo/hope-bank-payment-service-logo.svg"),"alt":"Hope Bank Logo","width":"110px"}})])],1),_vm._m(0),_c('div',{staticClass:"line5"}),_c('div',{staticClass:"receipt-info"},[_c('table',[_c('tr',[_c('td',[_vm._v("Account Name:")]),_c('td',[_vm._v(_vm._s(_vm.USER.accounts[0].accountName))])]),_c('tr',[_c('td',[_vm._v("Account Number:")]),(_vm.TRANSACTION_DETAILS.data.from)?_c('td',[_vm._v(" "+_vm._s(_vm.hashAccount(_vm.TRANSACTION_DETAILS.data.from))+" ")]):_vm._e(),(_vm.TRANSACTION_DETAILS.data.account)?_c('td',[_vm._v(" "+_vm._s(_vm.TRANSACTION_DETAILS.data.account)+" ")]):_vm._e()]),(_vm.TRANSACTION_DETAILS.data['beneficiary Name'])?_c('tr',[_c('td',[_vm._v("Beneficiary Name:")]),(_vm.TRANSACTION_DETAILS.data['beneficiary Name'])?_c('td',[_vm._v(" "+_vm._s(_vm.TRANSACTION_DETAILS.data["beneficiary Name"])+" ")]):_vm._e()]):_vm._e(),_c('tr',[(
                    _vm.TRANSACTION_DETAILS.data.beneficiary ||
                    _vm.TRANSACTION_DETAILS.data.to
                  )?_c('td',[_vm._v(" Beneficiary Details: ")]):_vm._e(),(_vm.TRANSACTION_DETAILS.data.to)?_c('td',[_vm._v(" "+_vm._s(_vm.USER.accounts[0].accountName)+" - "+_vm._s(_vm.TRANSACTION_DETAILS.data.to)+" - Hope Bank ")]):_vm._e(),(
                    _vm.TRANSACTION_DETAILS.data.beneficiary &&
                    !_vm.TRANSACTION_DETAILS.data['destination Bank']
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.TRANSACTION_DETAILS.data.beneficiary)+" - Hope Bank ")]):_vm._e(),(
                    _vm.TRANSACTION_DETAILS.data.beneficiary &&
                    _vm.TRANSACTION_DETAILS.data['destination Bank']
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.TRANSACTION_DETAILS.data.beneficiary)+" - "+_vm._s(_vm.TRANSACTION_DETAILS.data["destination Bank"])+" ")]):_vm._e()]),_c('tr',[_c('td',[_vm._v("Transaction Amount:")]),_c('td',[_vm._v(_vm._s(_vm.TRANSACTION_DETAILS.data.amount))])]),_c('tr',[_c('td',[_vm._v("Transaction Type:")]),_c('td',[_vm._v(_vm._s(_vm.TRANSACTION_DETAILS.data["transaction Type"]))])]),_c('tr',[_c('td',[_vm._v("Transaction Note:")]),_c('td',[_vm._v(_vm._s(_vm.TRANSACTION_DETAILS.data.narration))])]),_c('tr',[_c('td',[_vm._v("Transaction Ref:")]),_c('td',[_vm._v(_vm._s(_vm.TRANSACTION_DETAILS.data["reference Number"]))])]),_c('tr',[_c('td',[_vm._v("Transaction Date:")]),_c('td',[_vm._v(_vm._s(_vm.TRANSACTION_DETAILS.date))])])])])]),_c('div',{staticClass:"send-or-download"},[_c('button',{staticClass:"download-btn2"},[_c('font-awesome-icon',{staticClass:"download-icon",attrs:{"icon":"envelope"}}),_vm._v(" Send to Email ")],1),_c('button',{staticClass:"download-btn2",on:{"click":_vm.exportToPDF}},[_c('font-awesome-icon',{staticClass:"download-icon",attrs:{"icon":"download"}}),_vm._v(" Download ")],1)]),(_vm.post.path == '/Dashboard')?_c('div',{staticClass:"send-or-download"},[_c('router-link',{attrs:{"to":"Dashboard"}},[_c('button',{staticClass:"download-btn2"},[_vm._v("Go Back")])])],1):_vm._e(),(_vm.post.path == '/History')?_c('div',{staticClass:"send-or-download"},[_c('router-link',{attrs:{"to":"History"}},[_c('button',{staticClass:"download-btn2"},[_vm._v("Go Back")])])],1):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d text-center"},[_c('h1',[_vm._v("Transaction Receipt")]),_c('h2',[_vm._v("Successful Transaction")])])
}]

export { render, staticRenderFns }