import {getters} from './getters';
import {mutations} from './mutations';
import {actions} from './actions';

const state = {
   TRANSFER_ACCOUNT:"",
   TRANSFER_ACCOUNT_FROM:"",
   TRANSFER_ACCOUNT_TO:"",
   BENEFICIARY_ACCOUNT:"",
   BENEFICIARY_NAME:"",
   BENEFICIARY_BANK:"",
   TRANSFER_AMOUNT:"",
   SAVE_BENEFICIARY:"",
   TRANSFER_NOTE:"",
   INSTITUTIONS:[],
   SCHEDULE_NAME:"",
   FREQUENCY:"",
   START:"",
   END:"",
   CURRENT_SCHEDULE:"",
};

export default {state, getters, mutations, actions}