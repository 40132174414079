import Vue from 'vue'
import Vuex from 'vuex'
import Users from './modules/user/users'
import Transfers from './modules/Transfer/transfers'
import CardService from './modules/CardService/cardService'
import AccountTypes from './modules/AccountType/AccountTypes'
import TransHistorys from './modules/TransHistory/TransHistory'
import CreateAccounts from './modules/CreateAccount/createAccount'
import BillPayments from './modules/BillPayment/billpayment'
import NIN from './modules/NIN/nin'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
    key: '**-jive-data-00-**',
    storage: localStorage,
    reducer: state => ({
        User: state.User,//only saves the user module
        CreateAccount: state.CreateAccount,
        TransHistory: state.TransHistory,
        BillPayment: state.BillPayment,
        CardService: state.CardService,
        NIN: state.NIN,
        AccountType: state.AccountType,
    })
});

export default new Vuex.Store({
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {

    },
    modules: {
        User: Users,
        Transfer: Transfers,
        AccountType: AccountTypes,
        TransHistory: TransHistorys,
        CreateAccount: CreateAccounts,
        BillPayment: BillPayments,
        CardService: CardService,
        NIN: NIN,

    },
    plugins: [vuexPersist.plugin]
});