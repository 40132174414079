<template>
  <div class="contact-page">
    <HeaderNavA />
    <div></div>
    <div class="container">
      <div class="contact-title">
        <h1>Locate Us</h1>
        <!-- <p>Looking for our office addresses? Find them here.</p> -->
      </div>
      <div class="row">
        <!--<div class="col-md-1"></div>-->
        <div class="col-md-12">
          <div class="contact-section">
            <div class="account_descriptions">
              <div class="search_title mt-3">
                <h4>Locations</h4>
                <div class="row">
                  <div
                    class="col-md-3 mb-3"
                    v-for="item in BranchLocations"
                    :key="item.address"
                  >
                    <div class="vision-mission">
                      <h5 class="about-title-sm text-left">{{ item.name }}:</h5>
                      <p>
                        {{ item.address }}
                      </p>
                    </div>
                  </div>
                  <!-- <div class="col-md-3 mb-3">
                    <div class="vision-mission">
                      <h5 class="about-title-sm text-left">Kano:</h5>
                      <p>
                        No. 1, Bompai Road, Visa House, Kano City, Kano State
                      </p>
                    </div>
                  </div> -->
                  <!-- <div class="col-md-3 mb-3">
                    <div class="vision-mission">
                      <h5 class="about-title-sm text-left">Port - Harcourt:</h5>
                      <p>
                        No. 2, Worlu Street, Off Olu Obasanjo, D Line
                        Port-Harcourt, Rivers State
                      </p>
                    </div>
                  </div> -->
                  <!-- <div class="col-md-3 mb-3">
                    <div class="vision-mission">
                      <h5 class="about-title-sm text-left">Ibadan:</h5>
                      <p>No. 2, Fajuyi Road, Adamasingba Ibadan, Oyo State</p>
                    </div>
                  </div> -->
                  <!-- <div class="col-md-3 mb-3">
                    <div class="vision-mission">
                      <h5 class="about-title-sm text-left">Yola:</h5>
                      <p>
                        No. 3, Gimba Road, By Fire Service Round About Yola,
                        Adamawa State
                      </p>
                    </div>
                  </div> -->
                  <!-- <div class="col-md-3 mb-3">
                    <div class="vision-mission">
                      <h5 class="about-title-sm text-left">Onitsha:</h5>
                      <p>No. 5, Awka Road, Onitsha, Anambra State</p>
                    </div>
                  </div> -->
                  <!-- <div class="col-md-3 mb-3">
                    <div class="vision-mission">
                      <h5 class="about-title-sm text-left">Ogba:</h5>
                      <p>No. 23, Ijaiye Street, Ogba, Lagos State</p>
                    </div>
                  </div> -->
                </div>
              </div>
              <!-- <google-map /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<div></div>-->
    <!--<div></div>-->
    <!-- <div class="copyright">
            <p>© 2019 All rights reserved | Hope Bank</p>
    </div>-->

    <Footer />
  </div>
</template>

<script>
import HeaderNavA from "../../components/HeaderNav/HeaderNavA";
// import GoogleMap from "../../components/Map/GoogleMap";
import Footer from "../../components/Footer/Footer";
import { AgentLocatorService } from "../../services/agentLocator.services";

export default {
  name: "Contact.vue",
  components: {
    HeaderNavA,
    // GoogleMap,
    Footer,
  },

  data: function () {
    return {
      active: true,
      BranchLocations: [],
    };
  },
  methods: {
    getBranchLocations() {
      AgentLocatorService.BranchLocator()
        .then((res) => {
          this.BranchLocations = res;
          window.console.log("this is response branch Locator", res);
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getBranchLocations();
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #ffffff;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #ffffff;
}
#__BVID__17__BV_tab_controls_ {
  background: #eff7f9 !important;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
  /* letter-spacing: 0.17px; */
}
.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
  /*border-bottom: 3px solid #FECB32;*/
}
.navbar-light .navbar-nav .nav-link.active {
  color: #fecb32 !important;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link a.active {
  color: #fecb32 !important;
}
.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
  /*border-bottom: 3px solid #FECB32;*/
}
.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}
.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff !important;
  border-radius: 5px 0 0 5px;
}
.black_bg {
  background: #000000;
  border-radius: 5px;
  height: 52px;
}
.nav_header_inner {
  padding: 0 1rem;
  background: #000;
  border-radius: 5px 0 0 5px;
}
.arrow_right {
  margin-right: 1rem;
}
.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg a:hover {
  color: #000000 !important;
}
.yellow_bg > .navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}
.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}
.logout-btn a {
  display: flex;
  align-items: center;
}

.left_info_nav {
  background-image: url("../../assets/images/max_bg3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 40%;
  padding: 2rem 3rem;
  text-align: left;
}
.info-list {
  font-family: Avenir-Medium;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: left;
  padding-top: 2rem;
}
.info-list li {
  padding-bottom: 1rem;
}
.account_descriptions {
  width: 100%;
}
.search_title {
  padding: 0.5rem 1rem;
}
.search_title h4 {
  font-family: Montserrat-SemiBold;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
}
.about-title-sm {
  font-family: Montserrat-SemiBold;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  margin-bottom: 0.5rem;
}
.vision-mission p {
  font-family: Montserrat-Regular;
  font-style: normal;
  font-size: 14px;
  color: #000000;
  margin-bottom: 0.5rem;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .contact-page {
    height: 100%;
  }
  .footer-social {
    display: block;
  }
}
</style>