<template>
  <div class @click="showList = false">
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNav2></HeaderNav2>
      <div class="container">
        <HeaderNav3></HeaderNav3>
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <img src="../../assets/images/icons/transfer-sm.svg" alt="Hope Bank" />
                  <p class="frequently-used">Transfer Money</p>
                </div>
                <div>
                  <b-tabs no-body>
                    <b-tab :active="one" @click="ownTab" id="OwnTransfer">
                      <template v-slot:title>
                        To
                        <br />
                        <strong>Own Account</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Transfer Funds to Own Account</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Kindly enter Transfer Details</p>
                        </div>
                        <form action>
                          <div class="form-table">
                            <div class="label-title">
                              <label>Transfer From</label>
                              <div class="account_select">
                                <select v-model="transferAccountFrom" name id class="account_type">
                                  <option v-for="item in user.accounts" :key="item + 'a'" :value="item.accountNumber">
                                    {{ item.accountName }} -
                                    {{ item.accountNumber }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="beneficiary_details">
                              <p>Beneficiary Details</p>
                            </div>
                            <div class="label-title">
                              <label>Transfer To</label>
                              <div class="account_select">
                                <select v-model="transferAccountTo" name id class="account_type">
                                  <option v-for="item in filteredUserAccount" :key="item + 'a'"
                                    :value="item.accountNumber">
                                    {{ item.accountName }} -
                                    {{ item.accountNumber }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select2">
                                <currency-input v-model="transferAmount" class="input-amount" currency="NGN" precision="2"
                                  required />
                                <!-- <vue-numeric
                                  class="input-amount"
                                  currency="₦"
                                  separator=","
                                  precision="2"
                                  v-model="transferAmount"
                                  required
                                ></vue-numeric> -->
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Note</label>
                              <div class="account_select2">
                                <input type="text" v-model="transferNote" class="input-amount"
                                  placeholder="Say something about the transaction" />
                              </div>
                            </div>
                            <div class="send-or-download2">
                              <div class="continue-button">
                                <a @click="$router.go(-1)">
                                  <div class="continue-btn">
                                    <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                                  </div>
                                </a>
                              </div>
                              <router-link to="Confirmation?p=1">
                                <div class="continue-button">
                                  <button class="continue-btn">
                                    CONTINUE
                                    <font-awesome-icon icon="angle-right" class="angle-icon" />
                                  </button>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </b-tab>

                    <b-tab :active="two" id="HopeTransfer" @click="hopeTab">
                      <template v-slot:title class="left">
                        To
                        <br />
                        <strong>Hope PS Bank</strong>
                        <!--<b-spinner type="grow" small></b-spinner> Tab <strong>2</strong>-->
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Transfer Funds to Hope Bank Account</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Kindly enter Transfer Details</p>
                        </div>
                        <form action>
                          <div class="form-table">
                            <div class="label-title">
                              <label>Transfer From</label>
                              <div class="account_select">
                                <select v-model="transferAccount" name id class="account_type">
                                  <option v-for="item in user.accounts" :key="item + 'a'" :value="item.accountNumber">
                                    {{ item.accountName }} -
                                    {{ item.accountNumber }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="beneficiary_details">
                              <p>Beneficiary Details</p>
                            </div>
                            <div class="label-title">
                              <label>Saved Beneficiaries</label>
                              <div class="account_select_new">
                                <!-- <select name v-model="beneficiary" id class="account_type">
                                  <option value class="choose_account">
                                    — Select Beneficiary —
                                  </option>
                                  <option :value="item" class="choose_account"
                                    v-for="(item, index) in Beneficiaries.filter((x) => x.code == '800' || !x.bankName)"
                                    :key="index">
                                    {{ item.name }}-{{ item.beneficiary }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class /> -->
                                <!-- <b-dropdown id="dropdown-1" text="Dropdown Button" class="m-md-2">
                                  <b-dropdown-item>First Action</b-dropdown-item>
                                  <b-dropdown-item>Second Action</b-dropdown-item>
                                  <b-dropdown-item>Third Action</b-dropdown-item>
                                  <b-dropdown-divider></b-dropdown-divider>
                                  <b-dropdown-item active>Active action</b-dropdown-item>
                                  <b-dropdown-item disabled>Disabled action</b-dropdown-item>
                                </b-dropdown> -->
                                <!--  <multiselect v-model="beneficiary"
                                  :options="Beneficiaries.filter((x) => x.code == '800' || !x.bankName)"
                                  :custom-label="nameWithLang" placeholder="- Select Beneficiary -" label="name"
                                  track-by="name">

                                </multiselect> -->
                                <div style="position: relative">
                                  <div @click="changeState"
                                    style="padding: 10px;cursor: pointer; display:flex; align-items:center;justify-content: space-between">
                                    {{ beneficiary.name || 'Select A Beneficiary' }}
                                    <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                      fill="#000000">
                                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                      <g id="SVGRepo_iconCarrier">
                                        <rect x="0" fill="none" width="24" height="24"></rect>
                                        <g>
                                          <path d="M7 10l5 5 5-5"></path>
                                        </g>
                                      </g>
                                    </svg>
                                  </div>
                                  <div v-if='showList'
                                    style="position: absolute;top:100%;right:-1%;width: 102%;height:300px;overflow:hidden;overflow-y:scroll;background-color: white;border: 2px solid #fecb32">

                                    <div v-if='Beneficiaries.filter((x) => x.code == "800" || !x.bankName).length === 0'
                                      style='padding: 10px; '>No Beneficiaries</div>
                                    <div
                                      style="display: flex; align-items: center;border-bottom: 1px solid #fecb32a2;padding: 10px; cursor:pointer;"
                                      :style="{ backgroundColor: item.id === beneficiary.id ? '#ffe8a3a2' : 'white' }"
                                      v-for="item in Beneficiaries.filter((x) => x.code == '800' || !x.bankName)"
                                      :key="item.id" @click="beneficiary = item, showList = false">
                                      <div
                                        style="font-size:12px;margin-right:5px;background-color: #fecb32a2; color: black; border-radius: 10000px; width: 34px; height: 34px; text-align: center;display: flex; align-items: center;justify-content: center;">
                                        {{ item.name.split(" ")[0].charAt().toUpperCase() }}
                                        {{ item.name.split(" ")[1].charAt().toUpperCase() }}
                                      </div>
                                      <div>
                                        <div style='font-size: 14px'>
                                          {{ item.name }}
                                        </div>
                                        <div style='font-size: 10px'>
                                          <span>
                                            HOPE
                                          </span>
                                          ~
                                          <span>
                                            {{ item.beneficiary }}
                                          </span>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Beneficiary Account/Phone No</label>
                              <div class="account_select2">
                                <input maxlength="11" v-model="beneficiaryAccount" class="input-amount" placeholder
                                  required @blur="validateBeneficiary" />
                                <!--<input type="number" class="input-amount" placeholder="-"/>-->
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Beneficiary Name</label>
                              <div class="account_select2">
                                <input class="input-amount" disabled style="background: white; height: 35px"
                                  v-model="beneficiaryName" />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select2">
                                <currency-input v-model="transferAmount" class="input-amount" currency="NGN" precision="2"
                                  required />

                                <!-- <vue-numeric
                                  class="input-amount"
                                  currency="₦"
                                  separator=","
                                  precision="2"
                                  @input="handleInput"
                                  v-model="transferAmount"
                                  required
                                ></vue-numeric> -->
                              </div>
                            </div>
                            <div class="label-title2" style="justify-content: space-between">
                              <label class="label">Save Beneficiary?</label>
                              <b-form-checkbox size="lg" v-model="saveBeneficiary" name="check-button"
                                switch></b-form-checkbox>
                            </div>
                            <div class="label-title2">
                              <label>Note</label>
                              <div class="account_select2">
                                <input type="text" class="input-amount" v-model="transferNote"
                                  placeholder="Say something about the transaction" />
                              </div>
                            </div>

                            <div class="send-or-download2">
                              <div class="continue-button">
                                <a @click="$router.go(-1)">
                                  <div class="continue-btn">
                                    <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                                  </div>
                                </a>
                              </div>
                              <router-link to="Confirmation?p=2">
                                <div class="continue-button">
                                  <button class="continue-btn">
                                    CONTINUE
                                    <font-awesome-icon icon="angle-right" class="angle-icon" />
                                  </button>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </b-tab>
                    <b-tab :active="three" id="OtherBank" @click="hopeTab">
                      <template v-slot:title>
                        To
                        <br />
                        <strong>Other Bank</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Transfer Funds to Other Account</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Kindly enter Transfer Details</p>
                        </div>
                        <form action>
                          <div class="form-table2">
                            <div class="label-title">
                              <label>Transfer From</label>
                              <div class="account_select">
                                <select v-model="transferAccount" name id class="account_type">
                                  <option v-for="item in user.accounts" :key="item + 'a'" :value="item.accountNumber">
                                    {{ item.accountName }} -
                                    {{ item.accountNumber }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="beneficiary_details">
                              <p>Beneficiary Details</p>
                            </div>
                            <!--   <div class="saved_types">
                              <div @click="hopeSaved = true" :class="{active: hopeSaved}">Hope Beneficiaries</div>
                              <div @click="hopeSaved = false" :class="{active: !hopeSaved}">Other Beneficiaries</div>
                            </div> -->


                            <!--       <div v-if="hopeSaved" class="label-title">
                              <label>Saved Beneficiaries</label>
                              <div class="account_select">
                                <select
                                  name
                                  v-model="beneficiary"
                                  id
                                  class="account_type"
                                >
                                  <option value class="choose_account">
                                    — Select Beneficiary —
                                  </option>
                                  <option
                                    :value="item"
                                    class="choose_account"
                                    v-for="(item, index) in Beneficiaries.filter((x) => x.code == '800')"
                                    :key="index"
                                  >
                                    {{ item.name }}-{{ item.beneficiary }}-{{
                                      item.bankName
                                    }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div> -->

                            <div class="label-title">
                              <label>Saved Beneficiaries</label>
                              <div class="account_select_new">
                                <!-- <select name v-model="beneficiary" id class="account_type">
                                  <option value class="choose_account">
                                    — Select Beneficiary —
                                  </option>
                                  <option :value="item" class="choose_account"
                                    v-for="(item, index) in Beneficiaries.filter((x) => x.bankName)" :key="index">
                                    {{ item.name }}-{{ item.beneficiary }}-{{
                                      item.bankName
                                    }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class /> -->

                                <!--    <multiselect v-model="beneficiary" :options="Beneficiaries.filter((x) => x.bankName)"
                                  :custom-label="nameWithLang" placeholder="- Select Beneficiary -" label="name"
                                  track-by="name">

                                </multiselect> -->

                                <div style="position: relative">
                                  <div @click="changeState"
                                    style="padding: 10px;cursor: pointer; display:flex; align-items:center;justify-content: space-between">
                                    {{ beneficiary.name || 'Select A Beneficiary' }}
                                    <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                      fill="#000000">
                                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                      <g id="SVGRepo_iconCarrier">
                                        <rect x="0" fill="none" width="24" height="24"></rect>
                                        <g>
                                          <path d="M7 10l5 5 5-5"></path>
                                        </g>
                                      </g>
                                    </svg>
                                  </div>
                                  <div v-if='showList'
                                    style="position: absolute;top:100%;right:-1%;width: 102%;height:300px;overflow:hidden;overflow-y:scroll;background-color: white;border: 2px solid #fecb32">
                                    <div v-if='Beneficiaries.filter((x) => x.bankName).length === 0'
                                      style='padding: 10px; '>No Beneficiaries</div>
                                    <div
                                      style="display: flex; align-items: center;border-bottom: 1px solid #fecb32a2;padding: 10px; cursor:pointer;"
                                      :style="{ backgroundColor: item.id === beneficiary.id ? '#ffe8a3a2' : 'white' }"
                                      v-for="item in Beneficiaries.filter((x) => x.bankName)" :key="item.id"
                                      @click="beneficiary = item, showList = false">
                                      <div
                                        style="font-size:12px;margin-right:5px;background-color: #fecb32a2; color: black; border-radius: 10000px; width: 34px; height: 34px; text-align: center;display: flex; align-items: center;justify-content: center;">
                                        {{ item.name.split(" ")[0].charAt().toUpperCase() }}
                                        {{ item.name.split(" ")[1].charAt().toUpperCase() }}
                                      </div>
                                      <div>
                                        <div style='font-size: 14px'>
                                          {{ item.name }}
                                        </div>
                                        <div style='font-size: 10px'>
                                          <span>
                                            {{ item.bankName }}
                                          </span>
                                          ~
                                          <span>
                                            {{ item.beneficiary }}
                                          </span>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>


                              </div>
                            </div>



                            <div class="label-title" v-if="!beneficiary">
                              <label>Bank</label>
                              <div class="account_select">
                                <select name id v-model="beneficiaryBank" class="account_type">
                                  <option value class="choose_account">
                                    - Select Bank -
                                  </option>
                                  <option v-for="item in INSTITUTIONS" :key="item" :value="item" class="choose_account">
                                    {{ item.label }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Number</label>
                              <div class="account_select2">
                                <!-- <the-mask
                                  mask="###########"
                                  v-model="beneficiaryAccount"
                                  class="input-amount"
                                  placeholder="-"
                                  required
                                  @blur="validateBeneficiary"
                                /> -->
                                <input maxlength="11" v-model="beneficiaryAccount" class="input-amount" placeholder
                                  required @blur="validateBeneficiary" />
                                <!--<input type="number" class="input-amount" placeholder="-"/>-->
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Beneficiary Name</label>
                              <div class="account_select2">
                                <input class="input-amount" disabled style="background: white; height: 35px"
                                  v-model="beneficiaryName" />
                              </div>
                            </div>
                            <div class="label-title2" v-if="beneficiary">
                              <label>Beneficiary Bank</label>
                              <div class="account_select2">
                                <input maxlength="11" v-model="beneficiaryBank.bankName" class="input-amount" placeholder
                                  required />
                                <!--<input type="number" class="input-amount" placeholder="-"/>-->
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select2">
                                <currency-input v-model="transferAmount" class="input-amount" currency="NGN" precision="2"
                                  required />
                                <!-- <vue-numeric
                                  class="input-amount"
                                  currency="₦"
                                  separator=","
                                  precision="2"
                                  v-model="transferAmount"
                                  required
                                ></vue-numeric> -->
                              </div>
                            </div>
                            <div class="label-title2" style="justify-content: space-between">
                              <label class="label">Save Beneficiary?</label>
                              <b-form-checkbox size="lg" v-model="saveBeneficiary" name="check-button"
                                switch></b-form-checkbox>
                            </div>
                            <div class="label-title2">
                              <label>Note</label>
                              <div class="account_select2">
                                <input type="text" v-model="transferNote" class="input-amount"
                                  placeholder="Say something about the transaction" />
                              </div>
                            </div>
                          </div>

                          <div class="send-or-download2">
                            <div class="continue-button">
                              <a @click="$router.go(-1)">
                                <div class="continue-btn">
                                  <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                                </div>
                              </a>
                            </div>
                            <router-link to="Confirmation?p=3">
                              <div class="continue-button">
                                <button class="continue-btn">
                                  CONTINUE
                                  <font-awesome-icon icon="angle-right" class="angle-icon" />
                                </button>
                              </div>
                            </router-link>
                          </div>
                        </form>
                      </div>
                    </b-tab>
                    <!-- <b-tab :active="four" id="InternationalTransfer">
                      <template v-slot:title>
                          International<br><strong>Fund Transfer</strong>
                      </template>
                      <div class="tabs-contents">
                          <div class="current_tab_title">
                              <font-awesome-icon icon="play" class="play-icon"/>
                              <p class="">Transfer Funds to Foreign Bank Account</p>
                          </div>
                          <div class="kindly_enter_details">
                              <font-awesome-icon icon="exclamation-circle" class="exclamation-icon"/>
                              <p>Kindly enter Transfer Details</p>
                          </div>
                          <form action="">
                              <div class="form-table2">
                                  <div class="label-title">
                                      <label >Transfer From</label>
                                      <div class="account_select">
                                          <select name="" id="" class="account_type">
                                              <option value="" class="choose_account">CURRENT ACCOUNT - 01234567890</option>
                                              <option value="" class="choose_account">SAVINGS ACCOUNT - 01234567890</option>
                                          </select>
                                          <font-awesome-icon icon="caret-down" class=""/>
                                      </div>
                                  </div>
                                  <div class="label-title">
                                      <label >My Beneficiaries</label>
                                      <div class="account_select">
                                          <select name="" id="" class="account_type">
                                              <option value="" class="choose_account">— Beneficiary’s Account —</option>
                                              <option value="" class="choose_account">Chukwudi Okoro  - 01234567890</option>
                                          </select>
                                          <font-awesome-icon icon="caret-down" class=""/>
                                      </div>
                                  </div>
                                  <div class="label-title">
                                      <label >Bank</label>
                                      <div class="account_select">
                                          <select name="" id="" class="account_type">
                                              <option value="" class="choose_account">- Select Bank -</option>
                                              <option value="" class="choose_account">Access Bank</option>
                                              <option value="" class="choose_account">First Bank</option>
                                          </select>
                                          <font-awesome-icon icon="caret-down" class=""/>
                                      </div>
                                  </div>
                                  <div class="label-title2">
                                      <label >Bank ID</label>
                                      <div class="account_select2">
                                          <the-mask mask="##########" class="input-amount" placeholder="-"/>
                                      </div>
                                  </div>
                                  <div class="label-title2">
                                      <label >Amount</label>
                                      <div class="account_select2">
                                          <vue-numeric class="input-amount" currency="$" separator=","
                                          precision = "2" v-model="price"></vue-numeric>
                                      </div>
                                  </div>
                                  <div class="label-title2">
                                      <label >Note</label>
                                      <div class="account_select2">
                                          <input type="text" class="input-amount" placeholder="Say something"/>
                                      </div>
                                  </div>

                              </div>
                              <router-link to="Confirmation?p=4"><div class="continue-button">
                                  <button class="continue-btn">CONTINUE <font-awesome-icon icon="angle-right" class="angle-icon"/></button>
                              </div></router-link>
                          </form>
                      </div>
                    </b-tab>-->
                    <!-- Schedule Transfer to Hope Account -->
                    <b-tab :active="five" @click="$router.push('ManageSchedule')">
                      <template v-slot:title>
                        Schedule
                        <br />
                        <strong>Transaction</strong>
                      </template>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar v-if="transferAccountFrom" :accountNumber="transferAccountFrom" :accountBalance="Balance">
              </SideBar>
              <SideBar v-if="transferAccount" :accountNumber="transferAccount" :accountBalance="Balance"></SideBar>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
    </div>
  </div>
</template>

<script>
// import { TheMask } from "vue-the-mask";
// import VueNumeric from "vue-numeric";
import HeaderNav2 from "../../components/HeaderNav/HeaderNav2";
import HeaderNav3 from "../../components/HeaderNav/HeaderNav3";
import SideBar from "../../components/SideBar/SideBar";
import { mapGetters, mapState } from "vuex";
import Loader from "../../components/Loader/Loader.vue";
import { transferService } from "../../services/transfer.services.js";
import { getInstitutionService } from "../../services/getInstitution.services.js";
import { getBeneficiaryService } from "../../services/beneficiary.service.js";
import Footer2 from "../../components/Footer/Footer2";
import functions from "../../utils/functions";
import { CurrencyInput } from "vue-currency-input";
//import vSelect from 'vue-select'
//import Multiselect from 'vue-multiselect'
export default {
  name: "Transfer.vue",
  components: {
    // TheMask,
    // VueNumeric,
    //Multiselect,
    HeaderNav2,
    HeaderNav3,
    SideBar,
    Loader,
    Footer2,
    CurrencyInput,
  },
  data() {
    return {
      oneoff: true,
      start_date: null,
      end_date: null,
      loading: false,
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      showList: false,
      Beneficiaries: [],
      beneficiary: "",
      Type: "",
      hopeSaved: true
    };
  },
  computed: {
    ...mapGetters({
      user: "GET_USER",
      filteredUserAccount: "GET_FILTERED_USER",
    }),

    ...mapState({
      AUTHENTICATION_TOKEN: (state) => state.User.AUTHENTICATION_TOKEN,
      TRANSFER_ACCOUNT: (state) => state.Transfer.TRANSFER_ACCOUNT,
      TRANSFER_ACCOUNT_FROM: (state) => state.Transfer.TRANSFER_ACCOUNT_FROM,
      TRANSFER_ACCOUNT_TO: (state) => state.Transfer.TRANSFER_ACCOUNT_TO,
      BENEFICIARY_ACCOUNT: (state) => state.Transfer.BENEFICIARY_ACCOUNT,
      BENEFICIARY_NAME: (state) => state.Transfer.BENEFICIARY_NAME,
      BENEFICIARY_BANK: (state) => state.Transfer.BENEFICIARY_BANK,
      TRANSFER_AMOUNT: (state) => state.Transfer.TRANSFER_AMOUNT,
      SAVE_BENEFICIARY: (state) => state.Transfer.SAVE_BENEFICIARY,
      TRANSFER_NOTE: (state) => state.Transfer.TRANSFER_NOTE,
      INSTITUTIONS: (state) => state.Transfer.INSTITUTIONS,
    }),

    Balance() {
      if (this.transferAccountFrom)
        return this.user.accounts[
          this.user.accounts.findIndex(
            (a) => a.accountNumber == this.transferAccountFrom
          )
        ].balance;
      return this.user.accounts[
        this.user.accounts.findIndex(
          (a) => a.accountNumber == this.transferAccount
        )
      ].balance;
    },

    transferAccount: {
      get() {
        return this.TRANSFER_ACCOUNT;
      },
      set(value) {
        this.$store.commit("SET_TRANSFER_ACCOUNT", value);
      },
    },

    transferAccountFrom: {
      get() {
        return this.TRANSFER_ACCOUNT_FROM;
      },
      set(value) {
        this.$store.commit("SET_TRANSFER_ACCOUNT_FROM", value);
      },
    },

    transferAccountTo: {
      get() {
        return this.TRANSFER_ACCOUNT_TO;
      },
      set(value) {
        this.$store.commit("SET_TRANSFER_ACCOUNT_TO", value);
      },
    },

    beneficiaryAccount: {
      get() {
        return this.BENEFICIARY_ACCOUNT;
      },
      set(value) {
        this.$store.commit("SET_BENEFICIARY_ACCOUNT", value);
      },
    },
    beneficiaryName: {
      get() {
        return this.BENEFICIARY_NAME;
      },
      set(value) {
        this.$store.commit("SET_BENEFICIARY_NAME", value);
      },
    },
    beneficiaryBank: {
      get() {
        return this.BENEFICIARY_BANK;
      },
      set(value) {
        this.$store.commit("SET_BENEFICIARY_BANK", value);
      },
    },
    transferAmount: {
      get() {
        return this.TRANSFER_AMOUNT;
      },
      set(value) {
        this.$store.commit("SET_TRANSFER_AMOUNT", value);
      },
    },
    saveBeneficiary: {
      get() {
        return this.SAVE_BENEFICIARY;
      },
      set(value) {
        this.$store.commit("SET_SAVE_BENEFICIARY", value);
      },
    },
    transferNote: {
      get() {
        return this.TRANSFER_NOTE;
      },
      set(value) {
        this.$store.commit("SET_TRANSFER_NOTE", value);
      },
    },
    transferScheduleName: {
      get() {
        return this.SCHEDULE_NAME;
      },
      set(value) {
        this.$store.commit("SET_SCHEDULE_NAME", value);
      },
    },
    transferFrequency: {
      get() {
        return this.FREQUENCY;
      },
      set(value) {
        this.$store.commit("SET_FREQUENCY", value);
      },
    },
    transferStart: {
      get() {
        return this.START;
      },
      set(value) {
        this.$store.commit("SET_START", value);
      },
    },
    transferEnd: {
      get() {
        return this.END;
      },
      set(value) {
        this.$store.commit("SET_END", value);
      },
    },

    routeQuery() {
      return this.$route.query.p;
    },
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },
    nameWithLang({ name }) {
      return `
      ${name.split(" ")[0]?.charAt().toUpperCase()}
                    ${name.split(" ")[1]?.charAt().toUpperCase()}
`
    },
    changeState(e) {
      e.stopPropagation()
      this.showList = !this.showList
    },
    ownTab() {
      this.showList = false
      this.beneficiary = ''
      this.transferAccount = "";
      this.$store.dispatch("CLEAR_INPUTS");
      this.$store.commit(
        "SET_TRANSFER_ACCOUNT_FROM",
        this.user.accounts[0].accountNumber
      );
    },
    /* yellow */
    hopeTab() {
      this.showList = false
      this.beneficiary = ''
      this.transferAccountFrom = "";
      this.$store.dispatch("CLEAR_INPUTS");
      this.$store.commit(
        "SET_TRANSFER_ACCOUNT",
        this.user.accounts[0].accountNumber
      );
    },

    async validateBeneficiaryAccount() {
      this.loading = true;
      const data = {
        Account: this.BENEFICIARY_ACCOUNT,
        Code: this.BENEFICIARY_BANK.value,
        token: this.AUTHENTICATION_TOKEN,
      };
      await transferService
        .validateBeneficiaryAccount(data)
        .then((res) => {
          this.loading = false;
          this.$store.commit("SET_BENEFICIARY_NAME", res.status);
        })
        .catch((err) => {
          this.loading = false;
          this.$toastr.error(err.status, "Validation Failed!", {
            timeOut: 5000,
          });
          this.$store.commit("SET_BENEFICIARY_NAME", "");
        });
    },
    async getInstitution() {
      await getInstitutionService
        .getInstitution()
        .then((res) => {
          this.$store.commit("SET_INSTITUTIONS", res);
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Could not fetch institutions", {
            timeOut: 5000,
          });
        });
    },
    AmountToFixed(x) {
      return functions.AmountToFixed(x);
    },
    //get beneficiaries tied to an account
    getBeneficiary() {
      this.loading = true;
      getBeneficiaryService
        .getBeneficiary()
        .then((res) => {
          this.Beneficiaries = res;
          window.console.log("==============", res);
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Could not fetch", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateBeneficiary() {
      //window.alert("yes");
      if (this.beneficiary) return;
      if (
        this.beneficiaryAccount.toString().length == 11 ||
        this.beneficiaryAccount.toString().length == 10
      ) {
        setTimeout(function () {
          this.hovered = true;
        }, 1000);
        this.validateBeneficiaryAccount();
      }
    },
  },
  watch: {
    // beneficiaryAccount(newval) {
    //   if (this.beneficiary) return;
    //   if (newval.toString().length == 11) {
    //     this.validateBeneficiaryAccount();
    //   }
    //   if (newval.toString().length == 10) {
    //     this.validateBeneficiaryAccount();
    //   }
    // },
    transferAccountFrom(newval) {
      let userAccounts = this.$store.getters.GET_USER.accounts;
      let filtered = userAccounts.filter((a) => a.accountNumber != newval);
      this.$store.commit("SET_FILTERED_USER", filtered);
    },
    beneficiary(newval) {
      this.beneficiaryAccount = newval.beneficiary;
      this.beneficiaryName = newval.name;
      this.beneficiaryBank = newval;
    },
  },
  mounted() {
    this.getInstitution();
    if (this.routeQuery == 1) {
      this.transferAccount = "";
      this.one = true;

      return;
    }
    if (this.routeQuery == 2) {
      this.transferAccountFrom = "";
      this.two = true;

      return;
    }
    if (this.routeQuery == 3) {
      this.transferAccountFrom = "";
      this.three = true;

      return;
    }
    if (this.routeQuery == 4) return (this.four = true);
    if (this.routeQuery == 5) return (this.five = true);
  },
  created() {
    this.getBeneficiary();
    if (this.transferAccountFrom) return;
    this.$store.commit(
      "SET_TRANSFER_ACCOUNT_FROM",
      this.user.accounts[0].accountNumber
    );
  },
};
</script>

<style scoped>
body {
  background: #000000;
}

a {
  text-decoration: none;
  color: #000000;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}

.account_select_new {
  border: 1px solid #fecb32;
  background: white;
  font-size: 15px;
  position: relative;
  z-index: 200;
}

.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}

.quick-transfer {
  padding: 0 0.7rem;
}

.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}

.saved_types {
  display: grid;
  text-align: center;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 10px;

}

.selectB {
  widows: 100%;
}

.saved_types div {
  padding: 6px;
  border: 1px solid;
  cursor: pointer;
}

.saved_types div.active {
  background: #4a4a4a;
  color: white
}

@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
