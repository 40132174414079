import { ApiService } from "../api.service";

const CorporateAccountService = {
    //Single Vas Payment
    paymentService: async ({ Account, ProductId, Beneficiary, Amount, Pin, token }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/corporate/transaction/vas/process', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    ProductId,
                    Beneficiary,
                    Amount,
                    Pin,

                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/transaction/vas/process"

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    },

    //Single Transfer Hope
    transferHope: async ({ Account, BeneficiaryName, Beneficiary, Narration, Amount, Save, Pin, token }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/corporate/transaction/transfer/process', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    BeneficiaryName,
                    Beneficiary,
                    Narration,
                    Amount,
                    Save,
                    Type: "TRM4",
                    Pin,

                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/transaction/transfer/process"

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });
    },

    //Single Transfer Others
    transferOther: async ({ Account, BeneficiaryName, BeneficiaryBank, Beneficiary, Narration, Amount, Save, Pin, token }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/corporate/transaction/transfer/process', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    BeneficiaryName,
                    Beneficiary,
                    BeneficiaryBank,
                    Narration,
                    Amount,
                    Save,
                    Type: "TRM1",
                    Pin,

                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/transaction/transfer/process"

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    },

    //Bulk transfer validate
    bulkTransferValidate: async ({ Account, Corporate, data, token }) => {

        const formData = new FormData();
        formData.set("Account", Account);
        formData.set("Corporate", Corporate);
        formData.set("data", data);

        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/corporate/bulk/transfers/validate', localStorage.getItem("licence"))
                },
                data: formData,
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/bulk/transfers/validate"

            })
                .then((res) => {
                    if (res.data.success) {
                        return resolve(res.data);
                    }
                    else {
                        reject(res.data)
                    }
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    },

    //Bulk transfer initiate
    bulkTransferInitiate: async ({ Account, Corporate, Id, Pin, token }) => {

        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/corporate/bulk/transfers/initiate', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    Id,
                    Corporate,
                    Pin
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/bulk/transfers/initiate"

            })
                .then((res) => {

                    if (res.data.success) {
                        return resolve(res.data);
                    }
                    else {
                        reject(res.data)
                    }
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    },


    //Bulk Vas validate
    bulkVasValidate: async ({ Account, Corporate, data, token }) => {

        const formData = new FormData();
        formData.set("Account", Account);
        formData.set("Corporate", Corporate);
        formData.set("data", data);

        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/corporate/bulk/vas/validate', localStorage.getItem("licence"))
                },
                data: formData,
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/bulk/vas/validate"

            })
                .then((res) => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });
    },

    //Bulk Vas initiate
    bulkVasInitiate: async ({ Account, Corporate, Id, Pin, token }) => {

        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/corporate/bulk/vas/initiate', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    Id,
                    Corporate,
                    Pin
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/bulk/vas/initiate"

            })
                .then((res) => {

                    if (res.data.success) {
                        return resolve(res.data);
                    }
                    else {
                        reject(res.data)
                    }
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    },

    //Get all Pending Authorizations Checker/Signatory
    pendingAuthorizations: async ({ token }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/corporate/pending', localStorage.getItem("licence"))
                },
                data: {},
                method: "GET",
                url: ApiService.getBaseUrl() + "/api/web/corporate/pending"

            })
                .then((res) => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    },

    //Authorize Transfer Checker/Signatory
    authorizeTransaction: async ({ RequestId, Corporate, Id, Pin, token }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/corporate/approve', localStorage.getItem("licence"))
                },
                data: {
                    RequestId,
                    Corporate,
                    Id,
                    Pin,
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/approve"

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });
    },

    //Decline Transfer Checker/Signatory
    declineTransaction: async ({ RequestId, Corporate, Id, token, reason }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/corporate/decline', localStorage.getItem("licence"))
                },
                data: {
                    RequestId,
                    Corporate,
                    Id,
                    reason,
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/decline"

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    },

    //Transaction History
    transHistory: async () => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'POST',
                url: ApiService.getBaseUrl() + "/api/web/corporate/transaction/history",
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/corporate/transaction/history', localStorage.getItem('licence'))
                },
                redirect: 'follow'
            })
                .then((res) => {
                    let data = res.data
                    resolve(data);
                })
                .catch(err => {
                    reject(err.response.data)
                })


        });

    },

    //Transaction Search History

    SearchtransHistory: async (dataObj) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'POST',
                url: ApiService.getBaseUrl() + "/api/web/corporate/transaction/history",
                data: {
                    start: dataObj.start,
                    end: dataObj.end,
                    account: dataObj.account
                },
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/corporate/transaction/history', localStorage.getItem('licence'))
                },
                redirect: 'follow'
            })
                .then((res) => {
                    let data = res.data
                    resolve(data);
                })
                .catch(err => {
                    reject(err.response.data)
                })


        });

    },

};



export {
    CorporateAccountService
};
