<template>
  <div class>
    <div class="poster"></div>
    <div class="dashboard-page">
      <Loader v-if="loading" :show-full="true" loading-text="Processing" />

      <HeaderNavBiz />
      <div class="container">
        <BusinessNavBar />
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <p class="frequently-used">Bulk Account Creation</p>
                </div>
                <div class="current_tab_title mb-3">
                  <font-awesome-icon icon="play" class="play-icon" />
                  <p class>Bulk Account</p>
                </div>
                <div class="kindly_enter_details mb-3">
                  <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                  <p>Select details to complete this process</p>
                </div>

                <form @submit.prevent="validateAccount">
                  <div class="form-table">
                    <div class="label-title">
                      <label>Select Account</label>
                      <div class="account_select">
                        <select v-model="Account" name id class="account_type">
                          <!-- <option value="">--Select--</option> -->
                          <option
                            v-for="(account, key) in user.corporateAccounts"
                            :key="key"
                            :value="account.accountNumber"
                            class="choose_account"
                          >{{ account.accountNumber }}</option>
                        </select>
                        <font-awesome-icon icon="caret-down" class />
                      </div>
                    </div>
                    <div class="label-title2">
                      <label>Click to Upload File (.xls, .xlsx)</label>
                      <div class="account_select5">
                        <vuebase64
                          accept=".xls, .xlsx"
                          @size-exceeded="onSizeExceeded"
                          @file="data = $event"
                        />
                      </div>

                      <div class="kindly_enter_details2 text-right">
                        Click
                        <!-- <a
                          class="sample-download"
                          href="../../../../assets/sample/sample_bulk_acct_opening.xls"
                          download
                        > -->
                        <a
                          class="sample-download"
                          href="https://hopepsbank.com/xls/sample_bulk_acct_opening.xls"
                          download
                        >
                          <span style="color:blue; font-weight: 800;" class="ml-1 mr-1">here</span>
                        </a> to download sample template
                      </div>
                    </div>
                    <div class="send-or-download2">
                      <div class="continue-button">
                        <a @click="$router.go(-1)">
                          <div class="continue-cib-btn ">
                            <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                          </div>
                        </a>
                      </div>
                      <div class="continue-button">
                        <button type="submit" class="continue-cib-btn">
                          NEXT
                          <font-awesome-icon icon="angle-right" class="angle-icon" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                 <!-- <b-button v-b-modal.modal-center-validated>Launch centered modal</b-button> -->
              </div>
            </div>
            <div class="col-md-4">
              <SideBar :accountNumber="Account" :accountBalance="Balance" :accountName="Name"></SideBar>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>
      <b-modal
        id="modal-center"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
        v-model="confirm_transaction"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <h1 class="pin-info text-center">
              Insert PIN
            </h1>
            <div class="dialpad">
              <div class="form-group">
                <the-mask
                  type="password"
                  class="form-control"
                  v-model="pin"
                  mask="########"
                  id="txtBox"
                  placeholder="******"
                />
              </div>
              <Keyboard
                @pressed="pin = $event"
                :pinLength="pin"
                @completed="initiateAccount"
                :selfValue="pin"
              ></Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>
      <!-- Bulk Validation Page starts here -->
      <BulkValidate :onClick="downloadFile"  :onSubmit="submitValidatedFile"/>
      <!-- Bulk Validation Page ends here -->
    </div>
  </div>
</template>

<script>
import axios from "axios"
import HeaderNavBiz from "../../../../components/HeaderNav/HeaderNavBiz";
import BusinessNavBar from "../../../../components/HeaderNav/BusinessNavBar";
import SideBar from "../../../../components/SideBar/SideBar_";
import { mapGetters, mapState } from "vuex";
import { CorporateAccountService } from "../../../../services/CorporateAccount/corporateAccountCreation.services";
// import VueNumeric from 'vue-numeric';
import Loader from "../../../../components/Loader/Loader.vue";
import Keyboard from "../../../../components/Keyboard/Keyboard.vue";
import Footer3 from "../../../../components/Footer/Footer3";
import vuebase64 from "../../../../components/vue-base64/vuebase64.vue";
import BulkValidate from "../../../../components/BulkValidate/BulkValidate";

export default {
  name: "BulkAccount",
  components: {
    HeaderNavBiz,
    BusinessNavBar,
    SideBar,
    vuebase64,
    // VueNumeric,
    Loader,
    Keyboard,
    Footer3,
    BulkValidate,
  },
  data() {
    return {
      // active: false,
      Account: this.$store.getters.GET_USER.corporateAccounts[0].accountNumber,
      Amount: "",
      Beneficiary: "",
      pin: "",
      data: "",
      loading: false,
      confirm_transaction: false,
      id: "",
      modalState:false,
      location:''
    };
  },

  computed: {
    ...mapGetters({ user: "GET_USER" }),

    ...mapState({
      token: (state) => state.User.AUTHENTICATION_TOKEN,
    }),
    Balance() {
      return this.user.corporateAccounts[
        this.user.corporateAccounts.findIndex(
          (a) => a.accountNumber == this.Account
        )
      ].balance;
    },
    Name() {
      return this.user.corporateAccounts[
        this.user.corporateAccounts.findIndex(
          (a) => a.accountNumber == this.Account
        )
      ].accountName;
    },
    // Dynamic Corporate id
    Corporate() {
      let account = this.user.corporateAccounts;
      return account[account.findIndex((a) => a.accountNumber == this.Account)]
        .customerId;
    },
  },

  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },
    onSizeExceeded() {
      this.$toastr.error(`Maximum file size exceeded`, "Error");
    },
    //Validate bulk account creation
    validateAccount() {
      this.loading = true;
      const data = {
        Account: this.Account,
        Corporate: this.Corporate,
        data: this.data,
        token: this.token,
      };
      CorporateAccountService.bulkAccountValidate(data)
        .then((res) => {
          window.console.log(res)
          // this.confirm_transaction = true;
           this.$bvModal.show("modal-center-validated")
          this.id = res.id;
          this.location = res.location
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    downloadFile(){

    this.loading = true;
      const config = {
         headers: { Authorization: `Bearer ${this.token}` },  
          responseType: 'blob',
      };

      axios.get(this.location, config).then((result)=>{
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.$toastr.success("success", "Successful", {
            timeOut: 5000,
          });
      }).catch(err=>{
        this.$toastr.error(err.status, "Failed!", {
            timeOut: 5000,
          });
      }).finally(()=>{
        this.loading =false;
      })

    },

    submitValidatedFile(){
 this.confirm_transaction = true;
 this.$bvModal.hide("modal-center-validated")
    },

    //initiate bulk account creation
    initiateAccount() {
      this.loading = true;
      this.confirm_transaction = false;
      const data = {
        Account: this.Account,
        Corporate: this.Corporate,
        Id: this.id,
        Pin: this.pin,
        token: this.token,
      };
      CorporateAccountService.bulkAccountInitiate(data)
        .then((res) => {
          this.$toastr.success(res.status, "Successful", {
            timeOut: 5000,
          });
          this.$router.push("/Dashboard_");
         
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    //this.getVasCategory();
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000 !important;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.navbar {
  padding: 0;
}
.quick-transfer {
  padding: 0 0.7rem;
}
.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}
.footer-info {
  padding-top: 2.5rem;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
/* .poster{
  background-color: rgb(0, 94, 194, 0.1) !important;
}
.current_tab_title{
  background-image: linear-gradient(
    180deg,
    #00c4ff 0%,
    #0091ff 100%
  ) !important;
} */
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
