<template>
  <div class>
    <div class="poster"></div>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />

    <div class="dashboard-page">
      <HeaderNav2></HeaderNav2>
      <div class="container">
        <HeaderNav3></HeaderNav3>
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <p class="frequently-used">Bank Statement</p>
                </div>
                <div class="current_tab_title mb-3">
                  <font-awesome-icon icon="play" class="play-icon" />
                  <p class>See all your Transaction History</p>
                </div>
                <div class="kindly_enter_details mb-3">
                  <font-awesome-icon
                    icon="exclamation-circle"
                    class="exclamation-icon"
                  />
                  <p>
                    Select the Account Number and Date you want to fetch details
                    from
                  </p>
                </div>
                <form @submit.prevent="completeForm" action>
                  <div class="form-table">
                    <div class="label-title">
                      <label>Fetch From</label>
                      <div class="account_select">
                        <select v-model="account" class="account_type">
                          <option value>Select Account</option>
                          <option
                            v-for="acct in accounts"
                            :key="acct.accountNumber"
                            :value="acct.accountNumber"
                            class="choose_account"
                          >
                            {{ acct.accountType }} - {{ acct.accountNumber }}
                          </option>
                        </select>
                        <font-awesome-icon icon="caret-down" class />
                      </div>
                    </div>
                    <!-- <div class="label-title">
                      <label>Document Type</label>
                      <div class="account_select">
                        <select v-model="type" class="account_type">
                          <option value>Select Document Type</option>
                          <option>excel</option>
                          <option>pdf</option>
                        </select>
                        <font-awesome-icon icon="caret-down" class />
                      </div>
                    </div> -->
                    <div class="label-title2">
                      <label>Date From</label>
                      <DatePicker v-model="start_date" valueType="format" format="MM-DD-YYYY" placeholder="MM-DD-YYYY"></DatePicker>
                    </div>
                    <div class="label-title2">
                      <label>Date To *</label>
                      <DatePicker v-model="end_date" valueType="format" format="MM-DD-YYYY" placeholder="MM-DD-YYYY"></DatePicker>
                    </div>
                    <div class="label-title2">
                      <label>Email Address *</label>
                      <div class="account_select2">
                        <input
                          type="email"
                          class="input-amount"
                          v-model="email"
                          placeholder="Enter Email"
                          required
                        />
                      </div>
                    </div>

                    <div class="send-or-download2">
                      <div class="continue-button">
                        <a @click="$router.go(-1)">
                          <div class="continue-btn">
                            <font-awesome-icon
                              icon="angle-left"
                              class="download-icon"
                            />BACK
                          </div>
                        </a>
                      </div>
                      <div class="continue-button">
                        <!--<b-button v-b-modal.modal-center class="continue-btn">Verify Your BVN <font-awesome-icon icon="angle-right" class="angle-icon"/></b-button>-->

                        <button type="submit" class="continue-btn">
                          Send
                          <font-awesome-icon
                            icon="paper-plane"
                            class="angle-icon"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar
                :accountNumber="user.accounts[0].accountNumber"
                :accountBalance="user.accounts[0].balance"
              ></SideBar>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
      <b-modal
        id="modal-center-pin"
        centered
        title="PIN"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
        v-model="confirm_transaction"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <h1 class="pin-info text-center">
              Enter PIN to complete this transaction
            </h1>
            <div class="dialpad">
              <div class="form-group">
                <the-mask
                  type="password"
                  class="form-control"
                  v-model="pin"
                  mask="########"
                  maxlength="8"
                  minlength="6"
                  id="txtBox"
                  placeholder="******"
                />
              </div>
              <Keyboard
                @pressed="pin = $event"
                :pinLength="pin"
                @completed="verifyNIN"
                :selfValue="pin"
              ></Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import HeaderNav2 from "../../components/HeaderNav/HeaderNav2";
import HeaderNav3 from "../../components/HeaderNav/HeaderNav3";
import SideBar from "../../components/SideBar/SideBar";
import Loader from "../../components/Loader/Loader.vue";
import { mapGetters } from "vuex";
import Footer2 from "../../components/Footer/Footer2";
import { TransactionHistoryService } from "../../services/TransactionHistory.services.js";
import Keyboard from "../../components/Keyboard/Keyboard.vue";

export default {
  name: "StatementGenerate.vue",
  components: {
    TheMask,
    HeaderNav2,
    HeaderNav3,
    SideBar,
    Footer2,
    Keyboard,
    Loader,
  },

  computed: {
    ...mapGetters({ user: "GET_USER" }),
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },
    userAccouts() {
      this.accounts = this.user.accounts;
    },
    async completeForm() {
      this.loading = true;
      const data = {
        email: this.email,
        start: this.start_date,
        end: this.end_date,
        account: this.account,
        // type: this.type,
      };

      await TransactionHistoryService.bankStatement(data)
        .then((res) => {
          if (res.success === false) {
            return this.$toastr.error(res.status, "Failed", {
              timeOut: 5000,
            });
          }

          this.$toastr.success(
            "Bank Statement sent to " + data.email,
            "Success",
            {
              timeOut: 5000,
            }
          );
        })
        .catch((err) => {
          window.console.log(err);
          this.$toastr.error(err.status, "Failed", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
          this.start_date = null;
          this.end_date = null;
          this.email = "";
          this.account = "";
          this.type = "";
        });
    },
  },
  data() {
    return {
      loading: false,
      start_date: null,
      end_date: null,
      email: "",
      account: "",
      accounts: [],
    };
  },
  mounted() {
    this.userAccouts();
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.quick-transfer {
  padding: 0 2.2rem;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000 !important;
  display: flex;
  align-items: center;
  margin: 0 1rem;
  font-family: Montserrat-Bold;
  font-size: 12px;
  letter-spacing: 0.17px;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
