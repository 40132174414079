import {getters} from './getters';
import {mutations} from './mutations';
import {actions} from './actions';

const state = {
    USER: '',
    BVN:"",
    FILTERED_USER:'',
    AUTHENTICATION_TOKEN: '',
    LOGGED_IN_BUSINESS:false,
    VALIDATION_CREDENTIALS:{},
    SECURITY_QUESTIONS:{},
    VERIFICATION_CODE : "",
    CUSTOMER_ID : "",
    ACCOUNT_NUMBER:""
};

export default {state, getters, mutations, actions}