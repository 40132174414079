import {
    TransactionHistoryService
} from "../../../services/TransactionHistory.services";

const actions = {

    LOAD_TRANSHISTORY: async (context) => {
        return await TransactionHistoryService.transHistory().then((res) => {
            window.console.log("response", res);
            context.commit('SET_TRANSACTION_DATA', res);
            window.console.log(res)
            return true;
        }).catch((err) => {
            window.console.log(err);
            return false;
        });
    }
};

export {
    actions
};