<template>
  <div class="hope-login-page">
    <div class="poster"></div>
    <Loader v-if="loading" :show-full="true" loading-text="Loading..." />
    <Loader v-if="loading1" :show-full="true" loading-text="Please wait..." />

    <div class="upper-nav">
      <div class="hope-logo">
        <a href="/">
          <img
            src="../../assets/images/logo/hope_bank_logo1.svg"
            alt="Hope Bank Logo"
            class="hope_logo"
          />
        </a>
      </div>
      <div class="back-logout" @click="$router.go(-1)">
        <!-- <button class="go-back-btn" @click="$router.go(-1)"> -->
        <div class="go-back-btn">
          <span>
            <font-awesome-icon icon="arrow-left" class="angle-left" />
          </span>
          Back
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
          <div class="login-section">
            <div class="left-side">
              <!-- <router-link to="/">
                    <img
                        src="../../assets/images/logo/hope_bank_logo1.svg"
                        alt="Hope Bank Logo"
                        class="hope_logo2"
                    />
                    </router-link> -->
              <p class="login-info">Verify Identity</p>
              <form class="form-login" @submit.prevent="pinModal">
                <div class="user-login">
                  <div class="text-left nin-text-label">
                    <label for="">Account to debit</label>
                  </div>
                  <div class="username">
                    <select v-model="account" class="username-input">
                      <option>Account to debit</option>
                      <option
                        v-for="item in user.accounts"
                        :key="item + 'a'"
                        :value="item.accountNumber"
                      >
                        {{ item.accountNumber }}
                      </option>
                    </select>
                    <font-awesome-icon icon="chevron-down" class />
                  </div>
                  <!-- <div class="text-left nin-text-label">
                            <label for="">Phone Number</label>
                        </div>
                        <div class="username">
                            <input
                                type="text"
                                class="username-input"
                                placeholder="080xxxxxxxxx"
                                v-model="phone"
                                disabled
                            />
                            <font-awesome-icon icon="phone" class="login_icons" />
                        </div> -->
                  <div class="text-left nin-text-label">
                    <label for=""
                      >Enter NIN for the identity to be verified</label
                    >
                  </div>
                  <div class="username">
                    <input
                      v-model="NIN"
                      type="tel"
                      class="username-input"
                      placeholder="NIN"
                      required
                    />
                    <font-awesome-icon icon="user-circle" class="login_icons" />
                  </div>
                </div>
                <div class="login-button">
                  <button class="login-btn" type="submit">OK</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"-->
    <b-modal
      id="modal-center-service-charge"
      centered
      v-model="amountChargeModal"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      hide-footer
      ref="myModal"
    >
      <form ref="form" @submit.stop.prevent="hideModal">
        <b-form-group label-for="name-input" class="pin-info">
          <!-- <h1 class="pin-info text-center">Congratulations</h1>
            <div class="line4"></div> -->
          <p class="modal_congrats">
            Verify Identity enables you view photograph, full names, address,
            gender, marital status, employment status, educational level, etc of
            the person.
          </p>
          <p class="modal_congrats">
            This service will cost you
            <strong>N5,000</strong> To continue, select OK.
          </p>
          <button class="ok-btn">OK</button>
        </b-form-group>
      </form>
    </b-modal>
    <b-modal
      id="modal-center-pin"
      centered
      title="PIN"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      hide-footer
      v-model="confirm_transaction"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="nameState"
          label-for="name-input"
          invalid-feedback="PIN is required"
          class="pin-info"
        >
          <h1 class="pin-info text-center">
            Enter PIN to authorize this transaction
          </h1>
          <div class="dialpad">
            <div class="form-group">
              <the-mask
                type="password"
                class="form-control"
                v-model="pin"
                mask="########"
                maxlength="8"
                id="txtBox"
                placeholder="******"
              />
            </div>
            <Keyboard
              @pressed="pin = $event"
              :pinLength="pin"
              @completed="verifyNIN"
              :selfValue="pin"
            ></Keyboard>
          </div>
        </b-form-group>
      </form>
    </b-modal>
    <b-modal
      id="modal-center-data"
      centered
      v-model="success_modal"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      hide-footer
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group label-for="name-input" class="pin-info">
          <h1 class="pin-info text-center">Identity Data</h1>
          <div class="line4"></div>
          <p class="modal_congrats">Your NIN is {{ NIN }}</p>
          <div style="profile-image">
            <img :src="`data:image/png;base64,${image}`" />
          </div>
          <div class="line4"></div>
          <table>
            <tr>
              <td>Title:</td>
              <td>{{ title }}</td>
            </tr>
            <tr>
              <td>First Name:</td>
              <td>{{ firstname }}</td>
            </tr>
            <tr>
              <td>Middle Name:</td>
              <td>{{ middlename }}</td>
            </tr>
            <tr>
              <td>Last Name:</td>
              <td>{{ lastname }}</td>
            </tr>
            <tr>
              <td>Email:</td>
              <td>{{ email }}</td>
            </tr>
            <tr>
              <td>Birth Date:</td>
              <td>{{ birthDate }}</td>
            </tr>
            <tr>
              <td>Gender:</td>
              <td>{{ gender }}</td>
            </tr>
            <tr>
              <td>Marital Status:</td>
              <td>{{ maritalStatus }}</td>
            </tr>
            <tr>
              <td>Telephone:</td>
              <td>{{ telephoneno }}</td>
            </tr>
            <tr>
              <td>Residential Address:</td>
              <td>{{ residentialAddress }}</td>
            </tr>
            <tr>
              <td>Employment Status:</td>
              <td>{{ emplymentstatus }}</td>
            </tr>
            <tr>
              <td>Profession:</td>
              <td>{{ profession }}</td>
            </tr>
            <tr>
              <td>Religion:</td>
              <td>{{ religion }}</td>
            </tr>
          </table>
          <div class="space"></div>

          <router-link to="/">
            <button class="ok-btn">OK</button>
          </router-link>
        </b-form-group>
      </form>
    </b-modal>
    <b-modal
      id="modal-center-otp"
      centered
      v-model="otp2_modal"
      hide-footer
      hide-header-close
    >
      <form ref="form" @submit.prevent>
        <b-form-group
          label-for="name-input"
          invalid-feedback="OTP is required"
          class="pin-info"
        >
          <h1 class="pin-info text-center">Enter OTP sent to Phone Number</h1>
          <div class="dialpad">
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                v-model="otp"
                mask="######"
                maxlength="6"
                minlength="6"
                id="txtBox"
                placeholder="otp"
              />
            </div>
            <Keyboard
              @pressed="otp = $event"
              :pinLength="otp"
              :selfValue="otp"
              @completed="verifyNINOtp"
            ></Keyboard>
          </div>
        </b-form-group>
      </form>
    </b-modal>
    <Footer2 />
    <!-- <Footer/> -->
  </div>
</template>

<script>
import Loader from "../../components/Loader/Loader.vue";
// import { TheMask } from "vue-the-mask";
import { mapGetters, mapState } from "vuex";
import { getInstitutionService } from "../../services/getInstitution.services.js";
import Keyboard from "../../components/Keyboard/Keyboard.vue";
import Footer2 from "../../components/Footer/Footer2";
import { NINValidation } from "../../services/ninValidation.service";

export default {
  components: {
    Loader,
    // TheMask,
    Keyboard,
    Footer2,
  },

  data() {
    return {
      // phone: "",
      title: "",
      firstname: "",
      lastname: "",
      middlename: "",
      maritalStatus: "",
      email: "",
      birthDate: "",
      gender: "",
      telephoneno: "",
      residentialAddress: "",
      emplymentstatus: "",
      educationalLevel: "",
      profession: "",
      religion: "",
      image: "",
      pin: "",
      account: "",
      NIN: "",
      amount: "",
      otp: "",
      loading: false,
      loading1: false,
      errors: [],
      showPasswordModal: false,
      amountChargeModal: false,
      confirm_transaction: false,
      success_modal: false,
      otp2_modal: false,
      reference: "",
    };
  },

  computed: {
    ...mapGetters({
      user: "GET_USER",
      filteredUserAccount: "GET_FILTERED_USER",
    }),

    ...mapState({
      AUTHENTICATION_TOKEN: (state) => state.User.AUTHENTICATION_TOKEN,
      TRANSFER_ACCOUNT: (state) => state.Transfer.TRANSFER_ACCOUNT,
      TRANSFER_ACCOUNT_FROM: (state) => state.Transfer.TRANSFER_ACCOUNT_FROM,
      TRANSFER_ACCOUNT_TO: (state) => state.Transfer.TRANSFER_ACCOUNT_TO,
      INSTITUTIONS: (state) => state.Transfer.INSTITUTIONS,
    }),

    phone: {
      get() {
        return this.user.phone;
      },
      set(value) {
        this.user.phone = value;
      },
    },
  },
  methods: {
    verifyNIN() {
      this.loading = true;
      this.confirm_transaction = false;
      let data = {
        token: this.AUTHENTICATION_TOKEN,
        pin: this.pin,
        NIN: this.NIN,
        account: this.account,
      };
      window.console.log(data);
      NINValidation.verifyNIN(data)
        .then((res) => {
          window.console.log(res);
          // this.nin = res.nin
          // this.showNIN = true
          this.reference = res.reference;
          this.otp2_modal = true;
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    verifyNINOtp() {
      this.loading = true;
      const data = {
        NIN: this.NIN,
        otp: this.otp,
        reference: this.reference,
        token: this.AUTHENTICATION_TOKEN,
      };
      NINValidation.verifyNINOtp(data)
        .then((res) => {
          window.console.log(res);
          this.lastname = res.data["last Name"];
          this.firstname = res.data["first Name"];
          this.middlename = res.data["middlename"];
          this.maritalStatus = res.data["marital Status"];
          this.birthDate = res.data["birth Date"];
          this.title = res.data["title"];
          this.email = res.data["email"];
          this.telephoneno = res.data["telephoneno"];
          this.gender = res.data["gender"];
          this.emplymentstatus = res.data["emplymentstatus"];
          this.residentialAddress = res.data["residential Address"];
          this.image = res.data["image"];
          this.profession = res.data["profession"];
          this.religion = res.data["religion"];
          this.otp2_modal = false;

          this.success_modal = true;
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    pinModal() {
      this.confirm_transaction = true;
    },
    async getInstitution() {
      await getInstitutionService
        .getInstitution()
        .then((res) => {
          this.$store.commit("SET_INSTITUTIONS", res);
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Could not fetch institutions", {
            timeOut: 5000,
          });
        });
    },
    myModal() {
      this.amountChargeModal = true;
    },
    hideModal() {
      // this.$refs["myModal"].hide();
      this.amountChargeModal = false;
    },
  },
  mounted() {
    this.myModal();
    this.getInstitution();
  },
  created() {
    this.getBeneficiary();
    if (this.transferAccountFrom) return;
    this.$store.commit(
      "SET_TRANSFER_ACCOUNT_FROM",
      this.user.accounts[0].accountNumber
    );
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}
.go-back-btn:hover {
  /* color: #000000;
        background: #ffffff; */
}
.logout-btn2:hover {
  /* color: #ffffff;
        background: #000000; */
}
.modal-body {
  padding: 1rem !important;
}
.btns-biz-individual {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}
.btn-for-individual {
  font-family: Montserrat-SemiBold !important;
  font-size: 13px !important;
  color: #000000 !important;
  background: #fecb32 !important;
  border: 1px solid #fecb32 !important;
  border-radius: 5px;
  padding: 10px 1.5rem;
  letter-spacing: 0.17px;
  margin: 5px;
}
.btn-for-individual:hover {
  color: #000000 !important;
  background: #ffffff !important;
  border: 1px solid #fecb32 !important;
}
.profile-image img {
  border-radius: 50% !important;
  width: 93px !important;
  height: 93px !important;
  border: 6px solid rgba(50, 138, 254, 0.48) !important;
}
.btn-for-biz {
  font-family: Montserrat-SemiBold !important;
  font-size: 13px !important;
  color: #000000 !important;
  background: #fecb32 !important;
  border: 1px solid #fecb32 !important;
  border-radius: 5px;
  padding: 10px 1.5rem;
  letter-spacing: 0.17px;
  margin: 5px;
}
.btn-for-biz:hover {
  color: #000000 !important;
  background: #ffffff !important;
  border: 1px solid #fecb32 !important;
}
.left-side {
  width: 100%;
}
.space {
  /* border-bottom: 2px solid #e9e9e9; */
  margin: 1rem 0 0;
}
.username-input {
  position: relative;
  z-index: 10;
}
.username svg {
  margin-left: -2.5rem;
  position: relative;
  z-index: 0;
}
</style>