<template>
  <div>
    <div class="vue-base64-file-upload">
      <img v-show="previewImage && !disablePreview" :src="previewImage" class="img-fluid" />
      <div :style="wrapperStyles">
        <input type="file" @change="onChange"  :class="imageClass"  :accept="accept" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "vuebase64",
  props: {
    imageClass: {
      type: String,
      default: "input-amount"
    },
    inputClass: {
      type: String,
      default: ""
    },
    accept: {
      type: String,
      default: "image/*"
    },
    // required :{
    //   type:Boolean,
    //   default:true
    // },
    maxSize: {
      type: Number,
      default: 20 // megabytes
    },
    disablePreview: {
      type: Boolean,
      default: false
    },
    fileName: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Click here to upload image"
    },
    defaultPreview: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      file: null,
      preview: null,
      visiblePreview: false
    };
  },

  computed: {
    //You can  bind styles like so
    wrapperStyles() {
      return {
        position: "relative",
        width: "100%"
      };
    },
    previewImage() {
      return this.preview || this.defaultPreview;
    }
  },

  methods: {
    onChange(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }

      let file = files[0];
      let size = file.size && file.size / Math.pow(1000, 2);

      // check file max size
      if (size > this.maxSize) {
        this.$emit("size-exceeded", size);
        this.$toastr.e("Max file size exceeded")
        return;
      }

      // update file
      this.file = file;
      this.$emit("file", file);

      let reader = new FileReader();

      reader.onload = e => {
        let dataURI = e.target.result;

        if (dataURI) {
          this.$emit("load", dataURI);
          this.preview = dataURI;
        }
      };

      // read blob url from file data
      reader.readAsDataURL(file);
    }
  }
};
</script>

<style>
.vue-base64-file-upload {
  margin: 10px 5px;
}

.img-fluid {
  height: 100px !important;
  margin: 0px 8px !important;
}
</style>