<template>
  <div class>
    <div class="poster"></div>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />
    <div class="dashboard-page">
      <HeaderNavBiz />
      <div class="container">
        <BusinessNavBar />
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <img
                    src="../../../../assets/images/icons/transfer-sm.svg"
                    alt="Hope Bank"
                  />
                  <p class="frequently-used">Airtime/Data</p>
                </div>
                <div class="data-or-airtime-cib cib-color-change">
                  <b-tabs no-body>
                    <b-tab active @click="airtimeTab">
                      <template v-slot:title>
                        <strong>Buy Airtime</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Buy Airtime</p>
                        </div>
                        <div class="kindly_enter_details mb-3">
                          <font-awesome-icon
                            icon="exclamation-circle"
                            class="exclamation-icon"
                          />
                          <p>
                            Input details, select account and follow
                            instructions
                          </p>
                        </div>
                        <form @submit.prevent="checkForm" v-if="Category">
                          <div class="form-table">
                            <div v-if="errors.length">
                              <b class="text-danger"
                                >Please correct the following error(s):</b
                              >
                              <ul>
                                <span
                                  class="text-danger"
                                  v-for="(error, key) in errors"
                                  :key="key"
                                  >{{ error }}</span
                                >
                              </ul>
                            </div>
                            <div class="label-title2">
                              <label>Account</label>
                              <div class="account_select">
                                <select
                                  v-model="Account"
                                  name="Account"
                                  id="Account"
                                  class="account_type"
                                >
                                  <option
                                    v-for="(
                                      account, key
                                    ) in user.corporateAccounts"
                                    :value="account.accountNumber"
                                    :key="key"
                                    class="choose_account"
                                  >
                                    {{ account.accountNumber }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div
                              class="label-title"
                              v-if="Category.length != 0"
                            >
                              <label>Select Network</label>
                              <div class="account_select">
                                <select
                                  v-model="ProductId"
                                  name="ProductId"
                                  id="ProductId"
                                  class="account_type"
                                >
                                  <option value class="choose_account">
                                    -- Select Network --
                                  </option>

                                  <option
                                    v-for="(
                                      categories, key
                                    ) in Category.merchants"
                                    :value="categories.products[0].id"
                                    :key="key"
                                    class="choose_account"
                                  >
                                    {{ categories.name }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>

                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select2">
                                <currency-input
                                  v-model="Amount"
                                  class="input-amount"
                                  currency="NGN"
                                  precision="2"
                                  required
                                />
                                <!-- <vue-numeric
                                  class="input-amount"
                                  currency="₦"
                                  separator=","
                                  precision="2"
                                  v-model="Amount"
                                ></vue-numeric> -->
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Phone Number</label>
                              <div class="account_select2">
                                <the-mask
                                  v-model="Beneficiary"
                                  name="Beneficiary"
                                  id="Beneficiary"
                                  mask="###########"
                                  class="input-amount"
                                  placeholder="-"
                                />
                                <!--<input type="number" class="input-amount" placeholder="-"/>-->
                              </div>
                            </div>

                            <div class="send-or-download2">
                              <div class="continue-button">
                                <a @click="$router.go(-1)">
                                  <div class="continue-cib-btn">
                                    <font-awesome-icon
                                      icon="angle-left"
                                      class="download-icon"
                                    />BACK
                                  </div>
                                </a>
                              </div>
                              <div class="continue-button">
                                <b-button
                                  :disabled="
                                    Beneficiary.toString().length != 11
                                  "
                                  type="submit"
                                  class="continue-btn"
                                >
                                  SUBMIT
                                  <font-awesome-icon
                                    icon="angle-right"
                                    class="angle-icon"
                                  />
                                </b-button>
                                <!--<button v-b-modal.modal-center class="continue-cib-btn">CONTINUE <font-awesome-icon icon="angle-right" class="angle-icon"/></button>-->
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </b-tab>
                    <b-tab @click="dataTab">
                      <template v-slot:title>
                        <strong>Buy Data</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Buy Data</p>
                        </div>
                        <div class="kindly_enter_details mb-3">
                          <font-awesome-icon
                            icon="exclamation-circle"
                            class="exclamation-icon"
                          />
                          <p>
                            Input details, select account and follow
                            instructions
                          </p>
                        </div>
                        <form @submit.prevent="checkDataForm">
                          <div class="form-table">
                            <div v-if="errors.length">
                              <b class="text-danger"
                                >Please correct the following error(s):</b
                              >
                              <ul>
                                <div
                                  class="text-danger"
                                  v-for="(error, key) in errors"
                                  :key="key"
                                >
                                  {{ error }}
                                </div>
                              </ul>
                            </div>
                            <div class="label-title2">
                              <label>Account</label>
                              <div class="account_select">
                                <select
                                  v-model="Account1"
                                  name="Account"
                                  id="Account"
                                  class="account_type"
                                >
                                  <option
                                    v-for="(
                                      account, key
                                    ) in user.corporateAccounts"
                                    :value="account.accountNumber"
                                    :key="key"
                                    class="choose_account"
                                  >
                                    {{ account.accountNumber }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>

                            <div class="label-title">
                              <label>Select Network</label>
                              <div class="account_select">
                                <select
                                  v-model="MerchantId1"
                                  name="ProductId1"
                                  id="ProductId1"
                                  class="account_type"
                                  v-if="Category.merchants"
                                >
                                  <option value class="choose_account">
                                    -- Select Network --
                                  </option>

                                  <option
                                    v-for="(
                                      categories, key
                                    ) in Category.merchants"
                                    :value="categories.name"
                                    :key="key"
                                    class="choose_account"
                                  >
                                    {{ categories.name }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="label-title">
                              <label>Data Bundle</label>
                              <div
                                class="account_select"
                                v-if="Category.merchants"
                              >
                                <select
                                  v-model="ProductId1"
                                  name="data"
                                  id="data"
                                  class="account_type"
                                >
                                  <option value class="choose_account">
                                    -- Select Bundle --
                                  </option>
                                  <option
                                    :value="bundle.id"
                                    v-for="(bundle, key) in Bundle"
                                    :key="key"
                                    class="choose_account"
                                  >
                                    {{ bundle.name }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>

                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select2">
                                <!-- <vue-numeric
                                  class="input-amount"
                                  disabled
                                  currency="₦"
                                  precision="2"
                                  separator=","
                                  v-model="Amount1"
                                ></vue-numeric> -->
                                <currency-input
                                  v-model="Amount"
                                  class="input-amount"
                                  currency="NGN"
                                  precision="2"
                                  required
                                />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Phone Number</label>
                              <div class="account_select2">
                                <the-mask
                                  v-model="Beneficiary1"
                                  name="Beneficiary"
                                  id="Beneficiary"
                                  mask="###########"
                                  class="input-amount"
                                  placeholder="-"
                                />
                                <!--<input type="number" class="input-amount" placeholder="-"/>-->
                              </div>
                            </div>

                            <div class="send-or-download2">
                              <div class="continue-button">
                                <a @click="$router.go(-1)">
                                  <div class="continue-cib-btn">
                                    <font-awesome-icon
                                      icon="angle-left"
                                      class="download-icon"
                                    />BACK
                                  </div>
                                </a>
                              </div>
                              <div class="continue-button">
                                <b-button
                                  :disabled="
                                    Beneficiary1.toString().length != 11
                                  "
                                  type="submit"
                                  class="continue-btn"
                                >
                                  SUBMIT
                                  <font-awesome-icon
                                    icon="angle-right"
                                    class="angle-icon"
                                  />
                                </b-button>
                                <!--<button v-b-modal.modal-center  class="continue-cib-btn">CONTINUE <font-awesome-icon icon="angle-right" class="angle-icon"/></button>-->
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar
                v-if="Account"
                :accountNumber="Account"
                :accountBalance="Balance"
                :accountName="Name"
              ></SideBar>
              <SideBar
                v-if="Account1"
                :accountNumber="Account1"
                :accountBalance="Balance"
                :accountName="Name"
              ></SideBar>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>
      <b-modal
        id="modal-center3"
        centered
        title="Confirm Transactions"
        v-model="otp_modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <h1 class="pin-info text-center">
              Insert PIN to complete
              <br />transaction
            </h1>
            <div class="dialpad">
              <div class="form-group">
                <the-mask
                  type="password"
                  class="form-control"
                  v-model="pin"
                  mask="########"
                  id="txtBox"
                  placeholder="******"
                />
              </div>
              <Keyboard
                @pressed="pin = $event"
                v-if="Amount && Beneficiary"
                :pinLength="pin"
                @completed="save_otp"
                :selfValue="pin"
              ></Keyboard>
              <Keyboard
                @pressed="pin = $event"
                v-if="Amount1 && Beneficiary1"
                :pinLength="pin"
                @completed="save_otp_data"
                :selfValue="pin"
              ></Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>
      <b-modal
        id="modal-center2"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
        v-model="transaction_successful"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <img
              src="../../../../assets/images/icons/check.svg"
              alt="Hope Bank"
              class="modal_congrats_img"
            />
            <h1 class="pin-info text-center">
              Your transaction was successful
            </h1>
            <div class="line4"></div>
            <p class="modal_congrats">
              Some transaction takes a few minutes to reflect
            </p>
            <!--<router-link to="Complete"><button class="ok-btn">OK</button></router-link>-->
            <div class="send-or-download2">
              <router-link to="Overview">
                <button class="download-btn2">
                  <font-awesome-icon
                    icon="angle-left"
                    class="download-icon"
                  />HOME
                </button>
              </router-link>
              <router-link to="transfermoney">
                <button class="download-btn2">
                  SCHEDULE PAYMENT
                  <font-awesome-icon
                    icon="angle-right"
                    class="download-icon2"
                  />
                </button>
              </router-link>
            </div>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import HeaderNavBiz from "../../../../components/HeaderNav/HeaderNavBiz";
import BusinessNavBar from "../../../../components/HeaderNav/BusinessNavBar";
// import VueNumeric from "vue-numeric";
import SideBar from "../../../../components/SideBar/SideBar_";
import { vasAirtime_Data } from "../../../../services/vasAirtime&Data.services.js";
import { VasPaymentService } from "../../../../services/VasPayment.services.js";
import { CorporateAccountService } from "../../../../services/CorporateAccount/corporateAccountTransaction.services.js";
import Loader from "../../../../components/Loader/Loader.vue";
import { mapGetters, mapState } from "vuex";
import Keyboard from "../../../../components/Keyboard/Keyboard.vue";
import Footer3 from "../../../../components/Footer/Footer3";
import functions from "../../../../utils/functions";

export default {
  name: "AirtimeData.vue",
  components: {
    TheMask,
    HeaderNavBiz,
    BusinessNavBar,
    SideBar,
    Loader,
    // VueNumeric,
    Keyboard,
    Footer3,
  },
  data() {
    return {
      Account: this.$store.getters.GET_USER.corporateAccounts[0].accountNumber,
      ProductId: "",
      Amount: "",
      Beneficiary: "",
      Account1: "",
      ProductId1: "",
      MerchantId1: "",
      Bundle: [],
      Amount1: "",
      Beneficiary1: "",
      pin: "",
      loading: false,
      errors: [],
      Category: {},
      otp_modal: false,
      transaction_successful: false,
    };
  },
  computed: {
    ...mapGetters({ user: "GET_USER" }),

    ...mapState({
      AUTHENTICATION_TOKEN: (state) => state.User.AUTHENTICATION_TOKEN,
    }),
    Balance() {
      if (this.Account)
        return this.user.corporateAccounts[
          this.user.corporateAccounts.findIndex(
            (a) => a.accountNumber == this.Account
          )
        ].balance;
      return this.user.corporateAccounts[
        this.user.corporateAccounts.findIndex(
          (a) => a.accountNumber == this.Account1
        )
      ].balance;
    },
    Name() {
      if (this.Account)
        return this.user.corporateAccounts[
          this.user.corporateAccounts.findIndex(
            (a) => a.accountNumber == this.Account
          )
        ].accountName;
      return this.user.corporateAccounts[
        this.user.corporateAccounts.findIndex(
          (a) => a.accountNumber == this.Account1
        )
      ].accountName;
    },
  },
  watch: {
    MerchantId1(newval) {
      let merchant = this.Category.merchants.filter((o) => o.name == newval);
      this.Bundle = merchant[0].products;
    },
    ProductId1(newval) {
      let product = this.Bundle.filter((o) => o.id == newval);
      this.Amount1 = product[0].price;
    },
  },

  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },
    checkForm: function (e) {
      e.preventDefault();
      this.errors = [];
      if (this.ProductId === "") {
        this.errors.push("Select Network");
      } else if (this.Amount === "") {
        this.errors.push("Amount  is required");
      } else if (this.Beneficiary === "") {
        this.errors.push("Beneficiary is required");
      } else {
        this.validateBuyAirtimeVasPayment();
      }
    },
    checkDataForm: function (e) {
      e.preventDefault();
      this.errors = [];
      if (this.ProductId1 === "") {
        this.errors.push("Select Network");
      } else if (this.Amount1 === "") {
        this.errors.push("Amount  is required");
      } else if (this.Beneficiary1 === "") {
        this.errors.push("Beneficiary is required");
      } else {
        this.ValidateBuyDataVasPayment();
      }
    },
    airtimeTab() {
      this.Account1 = "";
      this.Account = this.user.corporateAccounts[0].accountNumber;
    },
    dataTab() {
      this.Account = "";
      this.Account1 = this.user.corporateAccounts[0].accountNumber;
    },
    async ValidateBuyDataVasPayment() {
      this.Beneficiary = "";
      this.Amount = "";
      this.loading = true;
      const data = {
        Beneficiary: this.Beneficiary1,
        ProductId: this.ProductId1,
        Amount: this.Amount1,
        token: this.AUTHENTICATION_TOKEN,
      };
      await VasPaymentService.validateVaspaymentService(data)
        .then(() => {
          // window.console.log(res);
          this.otp_modal = true;
        })
        .catch((error) => {
          this.$toastr.error(error.status, "Validation Failed!", {
            timeOut: 5000,
          });
        });
      this.loading = false;
    },
    async validateBuyAirtimeVasPayment() {
      this.Beneficiary1 = "";
      this.Amount1 = "";
      this.loading = true;
      const data = {
        Beneficiary: this.Beneficiary,
        ProductId: this.ProductId,
        Amount: this.AmountToFixed(this.Amount),
        token: this.AUTHENTICATION_TOKEN,
      };
      await VasPaymentService.validateVaspaymentService(data)
        .then((res) => {
          window.console.log(res);
          this.otp_modal = true;
        })
        .catch((error) => {
          this.$toastr.error(error.status, { timeOut: 5000 });
        });
      this.loading = false;
    },
    async save_otp() {
      this.loading = true;
      const data = {
        Account: this.Account,
        Beneficiary: this.Beneficiary,
        ProductId: this.ProductId,
        Amount: this.AmountToFixed(this.Amount),
        Pin: this.pin,
        token: this.AUTHENTICATION_TOKEN,
      };
      await CorporateAccountService.paymentService(data)
        .then((res) => {
          // window.console.log(res);
          this.$toastr.success(res.status, "Payment Successful", {
            timeOut: 5000,
          });
          this.Beneficiary = "";
          this.Amount = "";
          this.ProductId = "";
        })
        .catch((error) => {
          this.$toastr.error(error.status, "Payment Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
          this.pin = "";
          this.otp_modal = false;
        });
    },

    async save_otp_data() {
      this.loading = true;
      const data = {
        Account: this.Account1,
        Beneficiary: this.Beneficiary1,
        ProductId: this.ProductId1,
        Amount: this.AmountToFixed(this.Amount1),
        Pin: this.pin,
        token: this.AUTHENTICATION_TOKEN,
      };
      await CorporateAccountService.paymentService(data)
        .then((res) => {
          // window.console.log(res);
          this.$toastr.success(res.status, "Payment Successful", {
            timeOut: 5000,
          });
          this.Beneficiary1 = "";
          this.Amount1 = "";
          this.ProductId1 = "";
        })
        .catch((error) => {
          this.$toastr.error(error.status, "Payment Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
          this.pin = "";
          this.otp_modal = false;
        });
    },

    async getVasCategory() {
      this.loading = true;
      await vasAirtime_Data
        .vasCategory()
        .then((res) => {
          this.Category = res;
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Could not fetch vas Category", {
            timeOut: 5000,
          });
        })
        .finally(() => (this.loading = false));
    },

    AmountToFixed(x) {
      return functions.AmountToFixed(x);
    },
  },

  created() {
    this.getVasCategory();
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000 !important;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000 !important;
  display: flex;
  align-items: center;
  margin: 0 1rem;
  font-family: Montserrat-Bold;
  font-size: 12px;
  letter-spacing: 0.17px;
}
.quick-transfer {
  padding: 0 0.7rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: center;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #fecb32;
  border: 2px solid #fecb32;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
/* .poster {
  background-color: rgb(0, 94, 194, 0.1) !important;
}
.current_tab_title.current_tab_title {
  background-image: linear-gradient(
    180deg,
    #00c4ff 0%,
    #0091ff 100%
  ) !important;
} */
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
