<template>
  <div class>
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNavBiz />

      <div class="container">
        <BusinessNavBar />

        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <img src="../../../../assets/images/icons/bill.svg" alt="Hope Bank" />
                  <p class="frequently-used">Transfer Limit</p>
                </div>
                <div class="current_tab_title mb-3">
                  <font-awesome-icon icon="play" class="play-icon" />
                  <p class>You can always change your transfer, it’s a way you monitor your money</p>
                </div>
                <div class="kindly_enter_details mb-3">
                  <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                  <p>Increase or decrease your transfer limit</p>
                </div>
                <form action>
                  <div class="form-table">
                    <div class="label-title">
                      <label>Select Account</label>
                      <div class="account_select">
                        <select name id class="account_type">
                          <option value class="choose_account">CURRENT ACCOUNT - 01234567890</option>
                          <option value class="choose_account">SAVINGS ACCOUNT - 01234567890</option>
                        </select>
                        <font-awesome-icon icon="caret-down" class />
                      </div>
                    </div>
                    <div class="label-title">
                      <label>Transfer Limit</label>
                      <div class="account_select2">
                        <vue-numeric
                          class="input-amount"
                          currency="₦"
                          separator=","
                          v-model="price"
                        ></vue-numeric>
                      </div>
                    </div>

                    <div class="send-or-download2">
                      <div class="continue-button">
                        <a @click="$router.go(-1)">
                          <div class="continue-cib-btn">
                            <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                          </div>
                        </a>
                      </div>
                      <div class="continue-button">
                        <b-button v-b-modal.modal-center class="continue-cib-btn">
                          SUBMIT
                          <font-awesome-icon icon="angle-right" class="angle-icon" />
                        </b-button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar
                :accountNumber="user.accounts[0].accountNumber"
                :accountBalance="user.accounts[0].balance"
              ></SideBar>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>
      <b-modal
        id="modal-center"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <!--<b-form-input-->
            <!--id="name-input"-->
            <!--v-model="password"-->
            <!--:state="nameState"-->
            <!--required-->
            <!--&gt;</b-form-input>-->
            <h1 class="pin-info text-center">
              Insert PIN to finish
              <br />transaction
            </h1>
            <div class="dialpad">
            <div class="form-group">
              <the-mask
                type="password"
                class="form-control"
                v-model="pin"
                mask="######"
                id="txtBox"
                placeholder="******"
              />
            </div>
            <Keyboard
              @pressed="pin = $event"
              :pinLength="pin"
              @completed="TransferLimit"
              :selfValue="pin"
            ></Keyboard>
          </div>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import VueNumeric from "vue-numeric";
import HeaderNavBiz from "../../../../components/HeaderNav/HeaderNavBiz";
import BusinessNavBar from "../../../../components/HeaderNav/BusinessNavBar";
import SideBar from "../../../../components/SideBar/SideBar_";
import { mapGetters } from "vuex";
import Keyboard from "../../../../components/Keyboard/Keyboard.vue";
import Footer3 from "../../../../components/Footer/Footer3";

export default {
  name: "TransferLimit.vue",
  components: {
    TheMask,
    VueNumeric,
    HeaderNavBiz,
    BusinessNavBar,
    SideBar,
    Keyboard,
    Footer3,
  },
  computed: {
    ...mapGetters({ user: "GET_USER" }),
  },
  data() {
    return {
      pin: "",
    };
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}
.quick-transfer {
  padding: 0 0.7rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.account_select3 {
  background: #ffffff;
  border: 1px solid #fecb32;
  padding: 0 1rem;
  width: 200px;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
/* .poster {
  background-color: rgb(0, 94, 194, 0.1) !important;
} */
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
