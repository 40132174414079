import {transferService} from "../../../services/transfer.services";


const actions = {
    //Transfer to Hope account
   async TRANSFER_HOPE ({state}, {Pin, token}) {
        let data = {
            Account: state.TRANSFER_ACCOUNT,
            Beneficiary: state.BENEFICIARY_ACCOUNT,
            BeneficiaryName: state.BENEFICIARY_NAME,
            Narration: state.TRANSFER_NOTE,
            Amount: state.TRANSFER_AMOUNT,
            Save : state.SAVE_BENEFICIARY,
            Pin,
            token,
        }
       return await transferService.transferHope(data)
        .then (res => {
           return Promise.resolve(res);
           // window.console.log('success', res)
        })
        .catch (err => {
           return Promise.reject(err);
           // window.console.log('failed', err)
        })
    },

    //Transfer to Other account
    async TRANSFER_OTHER ({state}, {Pin, token}) {
        let data = {
            Account: state.TRANSFER_ACCOUNT,
            Beneficiary: state.BENEFICIARY_ACCOUNT,
            BeneficiaryName: state.BENEFICIARY_NAME,
            BeneficiaryBank: state.BENEFICIARY_BANK,
            Narration: state.TRANSFER_NOTE,
            Amount: state.TRANSFER_AMOUNT,
            Save : state.SAVE_BENEFICIARY,
            Pin,
            token,
        }
       return await transferService.transferOther(data)
        .then (res => {
           return Promise.resolve(res);
           // window.console.log('success', res)
        })
        .catch (err => {
           return Promise.reject(err);
           // window.console.log('failed', err)
        })
    },

    //Transfer to Own account
    async TRANSFER_OWN ({state}, {Pin, token}) {
        let data = {
            Account: state.TRANSFER_ACCOUNT_FROM,
            Beneficiary: state.TRANSFER_ACCOUNT_TO,
            Narration: state.TRANSFER_NOTE,
            Amount: state.TRANSFER_AMOUNT,
            Save : state.SAVE_BENEFICIARY,
            Pin,
            token,
        }
       return await transferService.transferOwn(data)
       .then (res => {
           
           return Promise.resolve(res);
        })
        .catch (err => {
           return Promise.reject(err);
           // window.console.log('failed', err)
        })
    },
    
    //Schedule Transfer to Hope account
    async SCHEDULE_TRANSFER_HOPE({state}, {Pin,token}) {
        let data = {
            Account: state.TRANSFER_ACCOUNT,
            Beneficiary: state.BENEFICIARY_ACCOUNT,
            BeneficiaryName: state.BENEFICIARY_NAME,
            Narration: state.TRANSFER_NOTE,
            Amount: state.TRANSFER_AMOUNT,
            ScheduleName: state.SCHEDULE_NAME,
            Frequency: state.FREQUENCY,
            Start: state.START,
            End: state.END,
            Save: state.SAVE_BENEFICIARY,
            Pin,
            token,
        }
        return await transferService.scheduleService_Hope(data)
            .then(res => {
                return Promise.resolve(res);
                // window.console.log('success', res)
            })
            .catch(err => {
                return Promise.reject(err);
                // window.console.log('failed', err)
            })
    },

     //Schedule Transfer to Own account
     async SCHEDULE_TRANSFER_OWN({state}, {Pin,token,BeneficiaryName}) {
        let data = {
            Account: state.TRANSFER_ACCOUNT_FROM,
            Beneficiary: state.TRANSFER_ACCOUNT_TO,
            BeneficiaryName,
            Narration: state.TRANSFER_NOTE,
            Amount: state.TRANSFER_AMOUNT,
            ScheduleName: state.SCHEDULE_NAME,
            Frequency: state.FREQUENCY,
            Start: state.START,
            End: state.END,
            Save: state.SAVE_BENEFICIARY,
            Pin,
            token,
        }
        return await transferService.scheduleService_Own(data)
            .then(res => {
                return Promise.resolve(res);
                // window.console.log('success', res)
            })
            .catch(err => {
                return Promise.reject(err);
                // window.console.log('failed', err)
            })
    },

    
    //Transfer to Other account
    async SCHEDULE_TRANSFER_OTHER({state}, {Pin,token}) {
        let data = {
            Account: state.TRANSFER_ACCOUNT,
            Beneficiary: state.BENEFICIARY_ACCOUNT,
            BeneficiaryName: state.BENEFICIARY_NAME,
            BeneficiaryBank: state.BENEFICIARY_BANK,
            Narration: state.TRANSFER_NOTE,
            Amount: state.TRANSFER_AMOUNT,
            ScheduleName: state.SCHEDULE_NAME,
            Frequency: state.FREQUENCY,
            Start: state.START,
            End: state.END,
            Save: state.SAVE_BENEFICIARY,
            Pin,
            token,
        }
        return await transferService.scheduleService_Other(data)
            .then(res => {
                return Promise.resolve(res);
                // window.console.log('success', res)
            })
            .catch(err => {
                return Promise.reject(err);
                // window.console.log('failed', err)
            })
    },


    //Clear all input fields in state after succesful transaction
    CLEAR_INPUTS ({commit}) {
        commit ('SET_TRANSFER_ACCOUNT', "")
        commit ('SET_TRANSFER_ACCOUNT_FROM', "")
        commit ('SET_TRANSFER_ACCOUNT_TO', "")
        commit ('SET_BENEFICIARY_ACCOUNT', "")
        commit ('SET_BENEFICIARY_NAME', "")
        commit ('SET_BENEFICIARY_BANK', "")
        commit ('SET_TRANSFER_AMOUNT', "")
        commit ('SET_TRANSFER_TYPE', "")
        commit ('SET_SAVE_BENEFICIARY', "")
        commit ('SET_TRANSFER_NOTE', "")
        commit ('SET_SCHEDULE_NAME', "")
        commit ('SET_FREQUENCY', "")
        commit ('SET_START', "")
        commit ('SET_END', "")
    }
}

export {
    actions
};