<template>
  <div class="hope-login-page">
    <div class="poster"></div>
    <Loader v-if="loading" :show-full="true" loading-text="Logging in..." />
    <Loader v-if="loading1" :show-full="true" loading-text="Please wait..." />

    <div class="upper-nav">
      <div class="hope-logo">
        <a href="/">
          <img
            src="../../assets/images/logo/hope_bank_logo1.svg"
            alt="Hope Bank Logo"
            class="hope_logo"
          />
        </a>
      </div>
      <div class="back-logout">
        <router-link to="/">
          <button class="go-back-btn">
            <!--<button class="go-back-btn" @click="$router.go(-1)">-->
            <!-- <span>
              <font-awesome-icon icon="arrow-left" class="angle-left" />
            </span>-->
            Back Home
          </button>
        </router-link>
        <router-link to="Login">
          <button class="logout-btn2">Individual</button>
        </router-link>
      </div>
    </div>
    <div class="container">
      <div class="login_section">
        <div class="login-section">
          <div class="left-side">
            <router-link to="/">
              <img
                src="../../assets/images/logo/hope_bank_logo1.svg"
                alt="Hope Bank Logo"
                class="hope_logo2"
              />
            </router-link>
            <p class="login-info">
              Login to your
              <br />Business Account
            </p>
            <form @submit.prevent="login" class="form-login">
              <div class="user-login">
                <div class="username">
                  <input
                    type="text"
                    class="username-input"
                    placeholder="Phone Number"
                    v-model="Phone"
                    required
                  />
                  <font-awesome-icon icon="phone" class="login_icons" />
                </div>
                <div class="username">
                  <input
                    type="password"
                    class="username-input"
                    placeholder="Password"
                    v-model="Password"
                    required
                  />
                  <font-awesome-icon icon="lock" class="login_icons" />
                </div>
                <!-- <label class="container-checkbox remember_me text-left">Remember Me
                                    <input type="checkbox" checked="checked">
                                    <span class="checkmark"></span>
                </label>-->
                <div class="forgot-password">
                  <span style="cursor:pointer" @click="showPasswordModal=true">Forgot Password?</span>
                </div>
              </div>
              <div class="have-an-account">
                <p>Are you new here?</p>
                <router-link to="signupIb" class="register_now_btn">Register</router-link>
              </div>
              <div class="login-button">
                <button class="login-btn" type="submit">LOGIN</button>
              </div>
            </form>
          </div>

          <div class="right-side">
            <Slider animation="fade" v-model="sliderValue" :duration="5000" :speed="1000">
              <SliderItem
                v-for="(i, index) in list"
                :key="index"
                @click="changeIndex(1);"
                :style="i"
              >
                <!--<p style="line-height: 280px; font-size: 5rem; text-align: center;">-->
                <!--Page{{ index + 1 }}-->
                <!--</p>-->
                <div>
                  <img src="../../assets/images/man-smile.jpg" alt="Hope Bank" />
                </div>
              </SliderItem>
              <SliderItem
                v-for="(i, index) in list"
                :key="index"
                @click="changeIndex(1);"
                :style="i"
              >
                <div>
                  <img src="../../assets/images/user1.jpg" alt="Hope Bank" />
                </div>
              </SliderItem>
              <SliderItem
                v-for="(i, index) in list"
                :key="index"
                @click="changeIndex(1);"
                :style="i"
              >
                <div>
                  <img src="../../assets/images/user2.jpg" alt="Hope Bank" />
                </div>
              </SliderItem>
              <SliderItem
                v-for="(i, index) in list"
                :key="index"
                @click="changeIndex(1);"
                :style="i"
              >
                <div>
                  <img src="../../assets/images/user3.jpg" alt="Hope Bank" />
                </div>
              </SliderItem>
              <SliderItem
                v-for="(i, index) in list"
                :key="index"
                @click="changeIndex(1);"
                :style="i"
              >
                <div>
                  <img src="../../assets/images/user4.jpg" alt="Hope Bank" />
                </div>
              </SliderItem>
            </Slider>
            <!--<img src="../../assets/images/man-smile.jpg" alt="Hope Bank">-->
            <div class="register-info">
              <!--<img src="../../assets/images/max_bg2.jpg" alt="Hope Bank">-->
              <h1>
                Become Elegant and
                <br />Hopeful for the future of
                <br />Banking.
              </h1>
              <div class="get-benefits">
                <p>
                  Get benefits for
                  <br />the Future.
                </p>
                <router-link to="signupIb">
                  <div class="register-button">
                    <button class="register-btn">Register</button>
                    <div class="angle-right2">
                      <font-awesome-icon icon="angle-right" class="angleright" />
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-center"
      centered
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      hide-footer
      v-model="showPasswordModal"
    >
      <ValidationObserver v-slot="{ passes }">
        <form ref="form" @submit.prevent="passes(resetPassword)">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <h6 class="text-center">Enter your Account Number to Reset your password</h6>
            <div class="label-title2">
              <label>Account Number*</label>
              <ValidationProvider name="password" rules="required" v-slot="{ errors }">
                <div class="account_select3">
                  <the-mask
                    mask="##########"
                    v-model="Account"
                    ref="password"
                    type="password"
                    class="input-amount"
                    placeholder="**********"
                  />
                </div>
                <span style="font-size:13px; color:red" class>
                  <span v-if="errors[0]">
                    <font-awesome-icon icon="ban" size="1x" class="ban-icon text-danger" />
                  </span>
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="continue-button float-right">
              <b-button
                :disabled="Account.toString().length != 10"
                type="submit"
                class="continue-btn"
              >
                SUBMIT
                <font-awesome-icon icon="angle-right" class="angle-icon" />
              </b-button>
              <!--<button class="continue-btn">CONTINUE <font-awesome-icon icon="angle-right" class="angle-icon"/></button>-->
            </div>
          </b-form-group>
        </form>
      </ValidationObserver>
    </b-modal>
    <div class="copyright">
      <p>© 2020 All rights reserved | Hope Bank</p>
    </div>
    <!-- <Footer/> -->
  </div>
</template>

<script>
import { Slider, SliderItem } from "vue-easy-slider";
import { mapActions } from "vuex";
import router from "../../router.js";
import Loader from "../../components/Loader/Loader.vue";
import { TheMask } from "vue-the-mask";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { securityService } from "../../services/security.services.js";
// import Footer from "../../components/Footer/Footer"

export default {
  name: "Login.vue",
  components: {
    Slider,
    SliderItem,
    Loader,
    ValidationObserver,
    ValidationProvider,
    TheMask
    // Footer
  },

  data() {
    return {
      list: [],
      sliderValue: 2,
      Phone: "",
      Password: "",
      Account: "",
      loading: false,
      loading1: false,
      errors: [],
      showPasswordModal: false
    };
  },

  computed: {
    routeQuery() {
      return this.$route.query.path;
    }
  },
  methods: {
    changeIndex(index) {
      this.sliderValue = index;
    },

    mask() {
      let word = this.Password;
      if (word.length <= 0) {
        return word;
      } else {
        var masked =
          word.substring(0, word.length - 0).replace(/\d/g, "*") +
          word.substring(word.length - 0, word.length);
        return masked;
      }
    },

    //get Security questions
    async resetPassword() {
      this.loading1 = true;
      await securityService
        .resetPassword(this.Account)
        .then(res => {
          window.console.log(res);
          this.loading1 = false;
          this.$store.commit("SET_SECURITY_QUESTIONS", res.questions);
          this.$store.commit("SET_USER_DATA", this.Account);
          this.$router.push("/ResetPassword");
        })
        .catch(error => {
          this.loading1 = false;
          this.$toastr.error(error.status, "Reset Pin Failed!", {
            timeOut: 5000
          });
        });
    },

    ...mapActions({ loginUser: "LOGIN" }),
    async login() {
      this.loading = true;
      await this.loginUser({
        Phone: this.Phone,
        Password: this.Password
      })
        .then(() => {
          //window.console.log(res);
          if (this.routeQuery == "register") {
            router.push("/Dashboard_?path=register");
            window.location.reload();
            return;
          }

          if (this.routeQuery == "sq") {
            router.push("/makers");
            window.location.reload();
            return;
          }
          router.push("/Dashboard_");
          window.location.reload();
        })
        .catch(error => {
          this.$toastr.error(error.status, "Login Failed!", { timeOut: 5000 });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    setTimeout(
      () =>
        (this.list = [
          {
            // backgroundColor: "#3f51b5",
            // backgroundImage: "../../assets/images/man-smile.jpg",
            width: "100%"
            // height: "100%"
          }
        ]),
      500
    );
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}
.go-back-btn:hover {
  /* color: #000000;
        background: #ffffff; */
}
.logout-btn2:hover {
  /* color: #ffffff;
        background: #000000; */
}
</style>