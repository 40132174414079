<template>
  <div class>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNav2></HeaderNav2>
      <div class="container">
        <HeaderNav3></HeaderNav3>
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <p class="frequently-used">Reset PIN</p>
                </div>
                <div class="current_tab_title mb-3">
                  <font-awesome-icon icon="play" class="play-icon" />
                  <p class>Forgot your PIN, do a quick reset</p>
                </div>
                <div class="kindly_enter_details mb-3">
                  <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                  <p>To reset your password, kindly provide information as follows.</p>
                </div>
                <form action @submit.prevent="answerSecurityQuestions">
                  <div class="form-table">
                    <div class="label-title">
                      <label>Security Question 1*</label>
                      <div class="account_select">
                        <select name id class="account_type">
                          <option value class="choose_account">- Select -</option>
                          <option
                            value
                            v-if="SECURITY_QUESTIONS[this.obJectKeys[0]]"
                            class="choose_account"
                          >{{SECURITY_QUESTIONS[this.obJectKeys[0]]}}</option>
                        </select>
                        <font-awesome-icon icon="caret-down" class />
                      </div>
                    </div>
                    <div class="label-title2">
                      <label>Security Answer 1*</label>
                      <div class="account_select2">
                        <input type="text" v-model="answer1" class="input-amount" placeholder=" " />
                      </div>
                    </div>
                    <div class="label-title">
                      <label>Security Question 2*</label>
                      <div class="account_select">
                        <select name id class="account_type">
                          <option value class="choose_account">- Select -</option>
                          <option
                            value
                            v-if="SECURITY_QUESTIONS[this.obJectKeys[1]]"
                            class="choose_account"
                          >{{SECURITY_QUESTIONS[this.obJectKeys[1]]}}</option>
                        </select>
                        <font-awesome-icon icon="caret-down" class />
                      </div>
                    </div>
                    <div class="label-title2">
                      <label>Security Answer 2*</label>
                      <div class="account_select2">
                        <input type="text" v-model="answer2" class="input-amount" placeholder=" " />
                      </div>
                    </div>
                    <div class="label-title">
                      <label>Security Question 3*</label>
                      <div class="account_select">
                        <select name id class="account_type">
                          <option value class="choose_account">- Select -</option>
                          <option
                            value
                            v-if="SECURITY_QUESTIONS[this.obJectKeys[2]]"
                            class="choose_account"
                          >{{SECURITY_QUESTIONS[this.obJectKeys[2]]}}</option>
                        </select>
                        <font-awesome-icon icon="caret-down" class />
                      </div>
                    </div>
                    <div class="label-title2">
                      <label>Security Answer 3*</label>
                      <div class="account_select2">
                        <input type="text" v-model="answer3" class="input-amount" placeholder=" " />
                      </div>
                    </div>

                    <div class="send-or-download2">
                      <div class="continue-button">
                        <a @click="$router.go(-1)">
                          <div class="continue-btn">
                            <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                          </div>
                        </a>
                      </div>
                      <div class="continue-button">
                        <b-button type="submit" class="continue-btn">
                          SUBMIT
                          <font-awesome-icon icon="angle-right" class="angle-icon" />
                        </b-button>
                        <!--<button class="continue-btn">CONTINUE <font-awesome-icon icon="angle-right" class="angle-icon"/></button>-->
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar
                :accountNumber="user.accounts[0].accountNumber"
                :accountBalance="user.accounts[0].balance"
              ></SideBar>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>

      <b-modal
        id="modal-center"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
        v-model="showPinModal"
      >
        <ValidationObserver v-slot="{ passes }">
          <form ref="form" @submit.prevent="passes(updatePin)">
            <b-form-group
              :state="nameState"
              label-for="name-input"
              invalid-feedback="PIN is required"
              class="pin-info"
            >
              <h1 class="pin-info text-center">
                Insert PIN to finish
                <br />transaction
              </h1>
              <div class="label-title2">
                <label>New PIN</label>
                <ValidationProvider name="pin" rules="required" v-slot="{ errors }">
                  <div class="account_select2">
                    <the-mask
                      mask="######"
                      v-model="pin1"
                      ref="pin"
                      type="password"
                      class="input-amount"
                      placeholder="******"
                    />
                  </div>
                  <span style="font-size:13px; color:red" class>
                    <span v-if="errors[0]">
                      <font-awesome-icon icon="ban" size="1x" class="ban-icon text-danger" />
                    </span>
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="label-title2">
                <label>Confirm PIN</label>
                <ValidationProvider name="pins" rules="required|confirmed:pin" v-slot="{ errors }">
                  <div class="account_select2">
                    <the-mask
                      mask="######"
                      v-model="pin"
                      type="password"
                      data-vv-name="pin"
                      class="input-amount"
                      placeholder="******"
                    />
                  </div>
                  <span style="font-size:13px; color:red" class>
                    <span v-if="errors[0]">
                      <font-awesome-icon icon="ban" size="1x" class="ban-icon text-danger" />
                    </span>
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="label-title2">
                <label>Token/OTP</label>
                <ValidationProvider name="otp" rules="required" v-slot="{ errors }">
                  <div class="account_select2">
                    <the-mask
                      mask="######"
                      v-model="otp"
                      type="password"
                      class="input-amount"
                      placeholder="000000"
                    />
                  </div>
                  <span style="font-size:13px; color:red" class>
                    <span v-if="errors[0]">
                      <font-awesome-icon icon="ban" size="1x" class="ban-icon text-danger" />
                    </span>
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div @click="resendOtp" class="resend-otp">Resend OTP</div>
              <div class="continue-button float-right">
                <b-button type="submit" class="continue-btn">
                  SUBMIT
                  <font-awesome-icon icon="angle-right" class="angle-icon" />
                </b-button>
                <!--<button class="continue-btn">CONTINUE <font-awesome-icon icon="angle-right" class="angle-icon"/></button>-->
              </div>
            </b-form-group>
          </form>
        </ValidationObserver>
      </b-modal>

      <b-modal
        id="modal-center2"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <h1 class="pin-info text-center">
              Insert PIN to finish
              <br />transaction
            </h1>
            <div class="dialpad">
            <div class="form-group">
              <the-mask
                type="password"
                class="form-control"
                v-model="pin"
                mask="######"
                id="txtBox"
                placeholder="******"
              />
            </div>
            <Keyboard
              @pressed="pin = $event"
              :pinLength="pin"
              @completed="resetPin"
              :selfValue="pin"
            ></Keyboard>
          </div>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import HeaderNav2 from "../../components/HeaderNav/HeaderNav2";
import HeaderNav3 from "../../components/HeaderNav/HeaderNav3";
import SideBar from "../../components/SideBar/SideBar";
import { securityService } from "../../services/security.services.js";
import { mapGetters, mapState } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Loader from "../../components/Loader/Loader.vue";
import Footer2 from "../../components/Footer/Footer2";

export default {
  name: "ResetPin.vue",
  components: {
    TheMask,
    HeaderNav2,
    HeaderNav3,
    SideBar,
    Loader,
    ValidationObserver,
    ValidationProvider,
    Footer2
  },
  data() {
    return {
      answer1: "",
      answer2: "",
      answer3: "",
      showPinModal: false,
      loading: false,
      otp: "",
      pin1: "",
      pin: ""
    };
  },
  computed: {
    ...mapGetters({ user: "GET_USER" }),

    ...mapState({
      SECURITY_QUESTIONS: state => state.User.SECURITY_QUESTIONS
    }),
    Account() {
      return this.user.accounts[0].accountNumber;
    },
    obJectKeys() {
      return Object.keys(this.SECURITY_QUESTIONS);
    }
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },
    resendOtp() {
      this.showPinModal = false;
    },
    //get Security questions
    async resetPin() {
      await securityService
        .resetPin(this.Account)
        .then(res => {
          window.console.log(res);
          this.$store.commit("SET_SECURITY_QUESTIONS", res.questions);
        })
        .catch(error => {
          this.$toastr.error(error.status, "Reset Pin Failed!", {
            timeOut: 5000
          });
        });
    },
    //answer security questions
    async answerSecurityQuestions() {
      this.loading = true;
      await securityService
        .answerSecurityQuestions({
          answer1: this.answer1,
          answer2: this.answer2,
          answer3: this.answer3,
          Account: this.Account,
          keys: this.obJectKeys
        })
        .then(res => {
          this.loading = false;
          this.showPinModal = true;
          this.$toastr.success(res.status || "Answer Successful", {
            timeOut: 5000
          });
        })
        .catch(error => {
          this.loading = false;
          this.$toastr.error(error.status, "Answer Failed", { timeOut: 5000 });
        });
    },
    //Reset pin to new pin
    async updatePin() {
      this.loading = true;
      await securityService
        .updatePin({
          Account: this.Account,
          Code: this.otp,
          Pin: this.pin
        })
        .then(res => {
          this.loading = false;
          this.$router.push("dashboard");
          this.$toastr.success(res.status || "Updated Successfully", {
            timeOut: 5000
          });
        })
        .catch(error => {
          this.loading = false;
          this.$toastr.error(error.status, "Update Failed", { timeOut: 5000 });
        });
    }
  },
  mounted() {
    this.resetPin();
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}
.quick-transfer {
  padding: 0 2.2rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
