import {AccountTypeService} from "../../../services/accountType.services";

const actions = {

    LOAD_ACCOUNT_TYPE: async (context) => {
        return await AccountTypeService.accountTypeService().then((res) => {
            context.commit('SET_ACCOUNT_TYPE_DATA', res);
            return true;
        }).catch((err) => {
            window.console.log(err);
            return false;
        });
    }
};

export {
    actions
};