<template>
  <div class>
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNavBiz />
      <div class="container">
        <BusinessNavBar />
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <p class="frequently-used">Menu</p>
                <!-- <div class="row ">
                                <div class="col-md-3">
                                    <router-link to="TransferMoney"><div class="column_1">
                                        <img src="../../assets/images/icons/transfer.svg" alt="Hope Bank">
                                        <p>Transfer Money</p>
                                    </div></router-link>
                                </div>
                                <div class="col-md-3">
                                    <router-link to="BillPayment"><div class="column_1">
                                        <img src="../../assets/images/icons/bill-payment.svg" alt="Hope Bank">
                                        <p>Bill Payment</p>
                                    </div></router-link>
                                </div>
                                <div class="col-md-3">
                                    <router-link to="UpdateInfo"><div class="column_1">
                                        <img src="../../assets/images/icons/self-service.svg" alt="Hope Bank">
                                        <p>Self Service</p>
                                    </div></router-link>
                                </div>
                                <div class="col-md-3">
                                    <router-link to="airtimedata"><div class="column_1">
                                        <img src="../../assets/images/icons/buy-airtime.svg" alt="Hope Bank">
                                        <p>Buy Airtime/Data</p>
                                    </div></router-link>
                                </div>
                            </div>
                <div class="line"></div>-->
                <div class="row">
                  <div class="col-md-3">
                    <router-link to="transfer">
                      <div class="column_1_cib">
                        <img src="../../../../assets/images/icons/transfer.svg" alt="Hope Bank" />
                        <p>Transfer Money</p>
                      </div>
                    </router-link>
                  </div>

                  <div class="col-md-3">
                    <router-link to="airtimedata_">
                      <div class="column_1_cib">
                        <img src="../../../../assets/images/icons/buy-airtime.svg" alt="Hope Bank" />
                        <p>Buy Airtime/Data</p>
                      </div>
                    </router-link>
                  </div>
                  <div class="col-md-3">
                    <router-link to="billpayment_">
                      <div class="column_1_cib">
                        <img src="../../../../assets/images/icons/bill-payment.svg" alt="Hope Bank" />
                        <p>Bill Payment</p>
                      </div>
                    </router-link>
                  </div>
                  <!-- <div class="col-md-4">
                                    <router-link to="Card"><div class="column_1">
                                        <img src="../../assets/images/icons/card-management.svg" alt="Hope Bank">
                                        <p>Card Management</p>
                                    </div></router-link>
                  </div>-->
                  <div class="col-md-3">
                    <router-link to>
                      <div class="column_1_cib">
                        <img src="../../../../assets/images/icons/self-service.svg" alt="Hope Bank" />
                        <p>Self Service</p>
                      </div>
                    </router-link>
                  </div>

                  <!-- <div class="col-md-4">
                                    <router-link to="Cheque"><div class="column_1">
                                        <img src="../../assets/images/icons/cheque.svg" alt="Hope Bank">
                                        <p>Cheque</p>
                                    </div></router-link>
                  </div>-->
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar
                :accountNumber="user.accounts[0].accountNumber"
                :accountBalance="user.accounts[0].balance"
                :accountName="Name"
              ></SideBar>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderNavBiz from "../../../../components/HeaderNav/HeaderNavBiz";
import BusinessNavBar from "../../../../components/HeaderNav/BusinessNavBar";
import SideBar from "../../../../components/SideBar/SideBar_";
import { mapGetters } from "vuex";
import Footer3 from "../../../../components/Footer/Footer3";

export default {
  name: "Dashboard.vue",
  components: {
    HeaderNavBiz,
    BusinessNavBar,
    SideBar,
    Footer3,
  },
  data() {
    return {
      Account: this.$store.getters.GET_USER.accounts[0].accountNumber,
      Name: this.$store.getters.GET_USER.accounts[0].accountName,
    };
  },
  computed: {
    ...mapGetters({ user: "GET_USER" }),
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000 !important;
  display: flex;
  align-items: center;
  margin: 0 1rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
  letter-spacing: 0.17px;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
/* .poster {
  background-color: rgb(0, 94, 194, 0.1) !important;
} */
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>

