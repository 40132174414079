<template>
  <div class>
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNav2></HeaderNav2>
      <div class="container">
        <HeaderNav3></HeaderNav3>
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <p class="frequently-used">Bank Statement</p>
                </div>
                <div class="current_tab_title mb-3">
                  <font-awesome-icon icon="play" class="play-icon" />
                  <p class>See all your Transaction History</p>
                </div>
                <div class="kindly_enter_details mb-3">
                  <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                  <p>Select the Account Number and Date you want to fetch details from</p>
                </div>
                <form action>
                  <div class="form-table">
                    <div class="label-title">
                      <label>Fetch From</label>
                      <div class="account_select2">
                        <div class="input-amount">CURRENT ACCOUNT - 01234567890</div>
                      </div>
                    </div>
                    <div class="label-title2">
                      <label>Date From</label>
                      <DatePicker v-model="start_date" valueType="format" format="DD-MM-YYYY" placeholder="DD-MM-YYYY"></DatePicker>
                    </div>
                    <div class="label-title2">
                      <label>Date To *</label>
                      <DatePicker v-model="end_date" valueType="format" format="DD-MM-YYYY" placeholder="DD-MM-YYYY"></DatePicker>
                    </div>
                    <div class="label-title2">
                      <label>Email Address *</label>
                      <div class="account_select2">
                        <input type="email" class="input-amount" placeholder="Enter Email" />
                      </div>
                    </div>

                    <div class="send-or-download2">
                      <div class="continue-button">
                        <a @click="$router.go(-1)">
                          <div class="continue-btn ">
                            <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                          </div>
                        </a>
                      </div>
                      <div class="continue-button">
                        <!--<b-button v-b-modal.modal-center class="continue-btn">Verify Your BVN <font-awesome-icon icon="angle-right" class="angle-icon"/></b-button>-->
                        <router-link to="History">
                          <button class="continue-btn">
                            Send
                            <font-awesome-icon icon="paper-plane" class="angle-icon" />
                          </button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar
                :accountNumber="user.accounts[0].accountNumber"
                :accountBalance="user.accounts[0].balance"
              ></SideBar>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
      <b-modal
        id="modal-center"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <h1 class="pin-info text-center">
              Insert PIN to finish
              <br />transaction
            </h1>
            <the-mask mask="####" type="password" class="input-pin" placeholder="****" required />
            <div class="pin_input">
              <div class="pin_input2">
                <div class="pin-input">1</div>
                <div class="pin-input">2</div>
                <div class="pin-input">3</div>
                <div class="pin-input">4</div>
              </div>
              <div class="pin_input2">
                <div class="pin-input">5</div>
                <div class="pin-input">6</div>
                <div class="pin-input">7</div>
                <div class="pin-input">8</div>
              </div>
              <div class="pin_input2">
                <div class="pin-input">9</div>
                <div class="pin-input">0</div>
                <div class="pin-input">
                  <font-awesome-icon icon="backspace" class />
                </div>
                <button class="pin-input">OK</button>
              </div>
            </div>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import HeaderNav2 from "../../components/HeaderNav/HeaderNav2";
import HeaderNav3 from "../../components/HeaderNav/HeaderNav3";
import SideBar from "../../components/SideBar/SideBar";
import { mapGetters } from "vuex";
import Footer2 from "../../components/Footer/Footer2";

export default {
  name: "StatementGen2.vue",
  components: {
    TheMask,
    HeaderNav2,
    HeaderNav3,
    SideBar,
    Footer2
  },
  computed: {
    ...mapGetters({ user: "GET_USER" })
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    }
  },
  data() {
    return {
      price: "",
      start_date: null,
      end_date: null
    };
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.quick-transfer {
  padding: 0 2.2rem;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000 !important;
  display: flex;
  align-items: center;
  margin: 0 1rem;
  font-family: Montserrat-Bold;
  font-size: 12px;
  letter-spacing: 0.17px;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
