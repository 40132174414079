<template>
  <div class="nav_header">
    <b-navbar toggleable="lg" type="light" variant="white">
      <b-navbar-brand href="/">
        <img
          src="../../assets/images/logo/hope_bank_logo1.svg"
          alt="Hope PS Bank Logo"
          class="logo-small"
        />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item>
            <router-link to="dashboard_">Dashboard</router-link>
          </b-nav-item>
          <b-navbar-nav>
            <b-nav-item>
              <router-link to="pending">Pending</router-link>
            </b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav>
            <div class="dropdown">
              <button
                type="button"
                class="nav_menu_drop dropdown-toggle"
                data-toggle="dropdown"
              >
                Accounts
              </button>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="business_"
                  >Open Additional Accounts</router-link
                >
                <router-link class="dropdown-item" to="HopeWalletFor3rdParty"
                  >Open Hope Wallet for 3rd Party</router-link
                >
                <router-link class="dropdown-item" to="bulkwallets"
                  >Open Bulk Wallets for 3rd Party</router-link
                >
                <router-link class="dropdown-item" to="salarysavings"
                  >Open A Salary Account</router-link
                >
                <router-link class="dropdown-item" to="createbulkaccounts"
                  >Open Bulk Salary Accounts</router-link
                >
              </div>
            </div>
          </b-navbar-nav>
          <!--<b-nav-item-dropdown text="Accounts" right class="nav_dashboard" >-->
          <!--<b-dropdown-item ><router-link to="History">Account History</router-link></b-dropdown-item>-->
          <!--<b-dropdown-item ><router-link to="StatementGenerate">Statement Generate</router-link></b-dropdown-item>-->
          <!--<b-dropdown-item ><router-link to="Cheque">Cheque</router-link></b-dropdown-item>-->
          <!--</b-nav-item-dropdown>-->
          <b-navbar-nav>
            <div class="dropdown">
              <router-link to="transfer">
                <button
                  type="button"
                  class="nav_menu_drop dropdown-toggle"
                  data-toggle="dropdown"
                >
                  Transfer
                </button>
              </router-link>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="transfer"
                  >Single Transfer</router-link
                >
                <router-link class="dropdown-item" to="transferBulk"
                  >Bulk Transfer</router-link
                >
              </div>
            </div>
          </b-navbar-nav>

          <b-navbar-nav>
            <div class="dropdown">
              <router-link to="airtimedata_">
                <button
                  type="button"
                  class="nav_menu_drop dropdown-toggle"
                  data-toggle="dropdown"
                >
                  Payments
                </button>
              </router-link>
              <div class="dropdown-menu">
                <router-link
                  class="dropdown-item"
                  to="#"
                  style="position: relative"
                >
                  <div ref="dropdownToggle" style="position: relative">
                    <router-link to="#" style="position: relative">
                      <button
                        type="button"
                        class="nav_menu_drop dropdown-toggle ml-n2"
                        data-toggle="dropdown"
                        @click="toggleDropdown"
                      >
                        Single Payment
                      </button>
                    </router-link>
                    <div
                      class="dropdown-menu"
                      style="position: relative"
                      @mouseleave="toggleDropdown"
                    >
                      <router-link class="dropdown-item" to="airtimedata_"
                        >Airtime &amp; Data</router-link
                      >
                      <router-link class="dropdown-item" to="billpayment_"
                        >Bills Payment</router-link
                      >
                    </div>
                  </div>
                </router-link>
                <router-link
                  class="dropdown-item"
                  style="position: relative"
                  to="bulkpayment"
                  >Bulk Payment</router-link
                >
              </div>
            </div>
          </b-navbar-nav>
          <!-- <b-navbar-nav>
            <div class="dropdown">
              <button
                type="button"
                class="nav_menu_drop dropdown-toggle"
                data-toggle="dropdown"
              >Payments</button>
              <div class="dropdown-menu">
                <b-nav-item class="dropdown-item">
                  <div class="dropdown">
                    <button
                      type="button"
                      class="nav_menu_drop dropdown-toggle"
                      data-toggle="dropdown"
                    >Single Payments</button>
                    <div class="dropdown-menu">
                      <router-link class="dropdown-item" to="airtimedata_">Airtime &amp; Data</router-link>
                      <router-link class="dropdown-item" to="billpayment_">Bills Payment</router-link>
                    </div>
                  </div>
                </b-nav-item>
                <b-nav-item>
                  <router-link to="bulkpayment" class="pl-2 pt-5">Bulk Payment</router-link>
                </b-nav-item>
              </div>
            </div>
          </b-navbar-nav>-->

          <b-navbar-nav>
            <div class="dropdown">
              <button
                type="button"
                class="nav_menu_drop dropdown-toggle"
                data-toggle="dropdown"
              >
                Services
              </button>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="makers"
                  >Create Maker</router-link
                >
                <router-link class="dropdown-item" to="checkers"
                  >Create Checker</router-link
                >
                <router-link class="dropdown-item" to="history_"
                  >Account History</router-link
                >
                <router-link class="dropdown-item" to="statementgenerate_"
                  >Generate Statement</router-link
                >
              </div>
            </div>
          </b-navbar-nav>
          <b-navbar-nav>
            <div class="dropdown">
              <button
                type="button"
                class="nav_menu_drop dropdown-toggle"
                data-toggle="dropdown"
              >
                Settings
              </button>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="updateinfo_"
                  >Update Info</router-link
                >
                <router-link class="dropdown-item" to="setpin_"
                  >Set PIN</router-link
                >
                <router-link class="dropdown-item" to="changepin_"
                  >Change PIN</router-link
                >
                <router-link class="dropdown-item" to="resetpin_"
                  >Reset PIN</router-link
                >
                <router-link class="dropdown-item" to="changepassword_"
                  >Change Password</router-link
                >
                <!-- <router-link class="dropdown-item" to="UpdateSecurityQuestion">Update Security Question</router-link> -->
              </div>
            </div>
          </b-navbar-nav>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item class="quick-transfer">
            <router-link to="menu_">
              <font-awesome-icon icon="bars" class="bars" />MENU
            </router-link>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "HeaderNav3.vue",

  methods: {
    toggleDropdown() {
      this.$refs.dropdownToggle.classList.toggle("dropdown");
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #4a4a4a;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.nav-link {
  font-family: Montserrat-Medium;
  font-size: 12px;
  color: #4a4a4a;
  /* font-weight: 400; */
  letter-spacing: 0.17px;
}
.navbar-nav {
  padding-left: 2.5rem !important;
}
.about-page-header .dropup,
.dropright,
.dropdown,
.dropleft {
  margin-top: 0 !important;
}
.quick-transfer {
  padding: 0 0.7rem;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .navbar-nav {
    padding-left: 0 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-nav {
    padding-left: 0 !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .navbar-nav {
    padding-left: 2rem !important;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1350px) {
  .navbar-nav {
    padding-left: 2rem !important;
  }
}
</style>
