import { ApiService } from "../api.service";

const CorporateAccountService = {
    //Validate Corporate Account
    validationCorporate: async ({ code, rcNo, BizName, BizAddress, BizReg, BizEmail, BizCountry, BizState, BizLga, BizCity, ZipCode, TIN, ShareCap, BizObj, BizPhone, CompSecName, CompSecPhone, CompSecAddress, TurnOver, SCUML }) => {
        return new Promise(function (resolve, reject) {
            ApiService.post('/api/web/corporate/setup/validate', {
                code,
                rcNo,
                BizName,
                BizAddress,
                BizReg,
                BizEmail,
                BizCountry,
                BizState,
                BizLga,
                BizCity,
                ZipCode,
                TIN,
                ShareCap,
                BizObj,
                BizPhone,
                CompSecName,
                CompSecPhone,
                CompSecAddress,
                TurnOver,
                SCUML
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },
    //Validate Joint Account
    validateJoinAccount: async ({ code, BizPhone, ReferalCode, BizName }) => {

        return new Promise((resolve, reject) => {

            ApiService.customRequest({
                method: 'POST',
                url: ApiService.getBaseUrl() + "/api/web/corporate/setup/validate",
                data: {
                    code,
                    BizPhone,
                    BizName,
                    ReferalCode
                },
                headers: {
                    Hash: ApiService.computeHash("/api/web/corporate/setup/validate", "124114982AA640178097CEBDDFA0E050"),
                    "Authorization": null
                },
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {

                    reject(err.response.data)
                })

        })

    },
    //Validate Directors
    validationDirector: async ({ company, Bvn, Phone, DateOfBirth }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'POST',
                url: ApiService.getBaseUrl() + "/api/web/corporate/setup/validate/director",
                data: {
                    company,
                    Bvn,
                    Phone,
                    DateOfBirth,
                },
                headers: {
                    Hash: ApiService.computeHash("/api/web/corporate/setup/validate/director", "124114982AA640178097CEBDDFA0E050"),
                    "Authorization": null
                },
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {

                    reject(err.response.data)
                })
        });
    },


    //Validate Signatories
    validationSignatories: async ({ company, Bvn, Phone, DateOfBirth, email, class_ }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'POST',
                url: ApiService.getBaseUrl() + "/api/web/corporate/setup/validate/signatory",
                data: {
                    company,
                    Bvn,
                    Phone,
                    DateOfBirth,
                    email,
                    class: class_
                },
                headers: {
                    Hash: ApiService.computeHash("/api/web/corporate/setup/validate/signatory", "124114982AA640178097CEBDDFA0E050"),
                    "Authorization": null
                },
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {

                    reject(err.response.data)
                })

        });
    },


    //Validation Complete
    validationComplete: async ({ company, ceo, creators, compSec, directors, signatories, mandate, referee, idData, boardRes, licence, utility, scuml, debitAuth }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'POST',
                url: ApiService.getBaseUrl() + "/api/web/corporate/setup/validate/complete",
                data: {
                    company,
                    ceo,
                    creators,
                    compSec,
                    directors,
                    signatories,
                    mandate,
                    referee,
                    idData,
                    boardRes,
                    licence,
                    utility,
                    scuml,
                    debitAuth
                },
                headers: {
                    Hash: ApiService.computeHash("/api/web/corporate/setup/validate/complete", "124114982AA640178097CEBDDFA0E050"),
                    "Authorization": null
                },
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {

                    reject(err.response.data)
                })

        });
    },

    //Complete Process
    completeProcess: async ({ company, id, otp1, otp2 }) => {
        return new Promise(function (resolve, reject) {

            ApiService.customRequest({
                method: 'POST',
                url: ApiService.getBaseUrl() + "/api/web/corporate/setup/complete",
                data: {
                    company,
                    id,
                    otp1,
                    otp2,
                },
                headers: {
                    Hash: ApiService.computeHash("/api/web/corporate/setup/complete", "124114982AA640178097CEBDDFA0E050"),
                    "Authorization": null
                },
            }).then((res) => {
                if (res.data.success) return resolve(res.data);
                reject(res.data)
            }).catch(err => {
                reject(err.response.data)
            })

        });
    },


    //Validate Corporate Account when logged in
    validationCorporateWhenLoggedIn: async ({
        code,
        existing,
        BizName,
        corporate,
        account

    }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'POST',
                url: ApiService.getBaseUrl() + "/api/web/corporate/setup/validate",
                data: {
                    code,
                    existing,
                    BizName,
                    corporate,
                    account


                },
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/corporate/setup/validate', localStorage.getItem('licence'))
                },
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    }

};



export {
    CorporateAccountService
};
