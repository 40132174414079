<template>
  <div class="poster-bg-">
    <div class="poster"></div>
    <div class="dashboard-page">
      <Loader v-if="loading" :show-full="true" loading-text="Processing" />

      <div class>
        <HeaderNavRegister />
        <div class="register_now">
          <div class="container">
            <div class="hope_account_opening">
              <h1 class>Hope PSBank</h1>
              <div class="row">
                  <div class="col-md-7 ml-auto mr-auto">
                      <p>Registration for National Identification Number (NIN) or Bank Verification Number (BVN) or Account Opening</p>
                  </div>
              </div>
            </div>
            <div class="main_dashboard">
              <div class="row">
                <div class="col-md-12">
                  <div class="steps_to_acc_open">
                    <h2>Confirm your information</h2>
                  </div>
                  <div class="main_dashboard_inner">
                    <div class="kindly_enter_details mb-3">
                      <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                      <p>Please confirm your information for correctness and click confirm form</p>
                    </div>
                    <form @submit.prevent="ninValidation">
                      <div class="form-table">
                        <!-- do you have nin -->
                        <div v-if="index === 0">
                          <div class="non_nigerian_form">
                            <div class="label-title">
                            <label>
                                Do you have NIN?
                                <span class="red-star">*</span>
                            </label>
                            <div class="account_select">
                                <select v-model="haveNIN" class="account_type" :disabled="isDisabled1">
                                    <option class="choose_account">Select</option>
                                    <option value="False" class="choose_account">No</option>
                                    <option value="True" class="choose_account">Yes</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                            </div>
                            </div>
                            <div class="label-title2">
                            <label>
                                If Yes, Enter NIN
                            </label>
                            <div class="account_select2">
                                <input
                                v-model="NIN"
                                :disabled="haveNIN === 'False'"
                                type="text"
                                class="input-amount"
                                placeholder=" "
                                maxlength="13"
                                />
                            </div>
                            </div>
                        </div>
                        <!-- do you have nin -->
                        <!-- do you have BVN -->
                        <div class="non_nigerian_form">
                            <div class="label-title">
                            <label>
                                Do you have BVN?
                                <span class="red-star">*</span>
                            </label>
                            <div class="account_select">
                                <select v-model="haveBVN" class="account_type" :disabled="isDisabled1">
                                <option class="choose_account">Select</option>
                                <option value="False" class="choose_account">No</option>
                                <option value="True" class="choose_account">Yes</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                            </div>
                            </div>
                            <div class="label-title2">
                            <label>
                                If Yes, Enter BVN
                            </label>
                            <div class="account_select2">
                                <input
                                v-model="BVN"
                                :disabled="haveBVN === 'False'"
                                type="text"
                                class="input-amount"
                                placeholder=" "
                                maxlength="11"
                                />
                            </div>
                            </div>
                        </div>
                        <!-- do you have BVN -->
                        <!-- do you have a Hope Account starts-->
                        <div class="non_nigerian_form">
                            <div class="label-title">
                            <label>
                                Do you have a Hope Account?
                                <span class="red-star">*</span>
                            </label>
                            <div class="account_select">
                                <select v-model="haveHope" class="account_type" :disabled="isDisabled1">
                                <option class="choose_account">Select</option>
                                <option value="False" class="choose_account">No</option>
                                <option value="True" class="choose_account">Yes</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                            </div>
                            </div>
                            <div class="label-title2">
                            <label>
                                If Yes, Enter your Phone Number
                            </label>
                            <div class="account_select2">
                                <input
                                v-model="HopeAcct"
                                :disabled="haveHope === 'False'"
                                type="text"
                                class="input-amount"
                                placeholder=" "
                                maxlength="11"
                                />
                            </div>
                            </div>
                            <!-- continue button starts -->
                            <!-- <div class="send-or-download2" >
                                    <div class="continue-button">
                                        <button
                                             @click.prevent="isDisabled1 = false"
                                            class="continue-btn"
                                            >
                                            Edit
                                            <font-awesome-icon icon="edit" class="angle-icon" />
                                        </button>
                                    </div>
                                    <div class="continue-button">
                                        <button
                                            class="continue-btn"
                                            @click="nextForm"
                                            >
                                            Verified
                                            <font-awesome-icon icon="check-double" class="angle-icon" />
                                        </button>
                                    </div>
                                </div> -->
                            <!-- continue button ends -->
                        </div>
                        <!-- do you have a Hope Account ends -->
                        <div >
                            <!-- Your Names -->
                            <!-- <div v-if="index === 1"> -->
                              <div class="non_nigerian_form">
                              <h3>Your Names</h3>
                              <div class="label-title">
                                <label>
                                  Title (Mr./Mrs./Master/Miss/Ms.) <span class="red-star">*</span> 
                                </label>
                                <div class="account_select">
                                  <select class="account_type" v-model="title" :disabled="isDisabled1">
                                    <option class="choose_account">Select</option>
                                    <option value="Mr" class="choose_account">Mr.</option>
                                    <option value="Mrs" class="choose_account">Mrs.</option>
                                    <option value="Master" class="choose_account">Master</option>
                                    <option value="Miss" class="choose_account">Miss</option>
                                    <option value="Ms" class="choose_account">Ms.</option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>
                                  Last Name
                                  <span class="red-star">*</span>
                                </label>
                                <div class="account_select2">
                                  <input
                                    v-model="lastname"
                                    type="text"
                                    class="input-amount"
                                    placeholder=" "
                                    required
                                    :disabled="isDisabled1"
                                  />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>
                                  First Name
                                  <span class="red-star">*</span>
                                </label>
                                <div class="account_select2">
                                  <input
                                    v-model="firstname"
                                    type="text"
                                    class="input-amount"
                                    placeholder=" "
                                    required
                                    :disabled="isDisabled1"
                                  />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>Middle Names</label>
                                <div class="account_select2">
                                  <input
                                    v-model="middlename"
                                    type="text"
                                    class="input-amount"
                                    placeholder=" "
                                    :disabled="isDisabled1"
                                  />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>Other Names</label>
                                <div class="account_select2">
                                  <input
                                    v-model="othernames"
                                    type="text"
                                    class="input-amount"
                                    placeholder=" "
                                    :disabled="isDisabled1"
                                  />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>Maiden Name</label>
                                <div class="account_select2">
                                  <input
                                    v-model="maidenname"
                                    type="text"
                                    class="input-amount"
                                    placeholder=" "
                                    :disabled="isDisabled1"
                                  />
                                </div>
                              </div>
                              <!-- continue button starts -->
                                <!-- <div class="send-or-download2" >
                                    <div class="continue-button">
                                        <button
                                            @click.prevent="isDisabled1 = false"
                                            class="continue-btn"
                                            >
                                            Edit
                                            <font-awesome-icon icon="edit" class="angle-icon" />
                                        </button>
                                    </div>
                                    <div class="continue-button">
                                        <button
                                             
                                            class="continue-btn"
                                            @click="nextForm"
                                            >
                                            Verified
                                            <font-awesome-icon icon="check-double" class="angle-icon" />
                                        </button>
                                    </div>
                                </div> -->
                                <!-- continue button ends -->
                            </div>
                            <!-- </div> -->
                            <!-- Your Names -->

                            <!-- Your Address -->
                            <!-- <div v-if="index === 2"> -->
                              <div class="non_nigerian_form">
                              <h3>Your Address</h3>
                              <div class="label-title">
                                <label>
                                  Are you homeless?
                                  <span class="red-star">*</span>
                                </label>
                                <div class="account_select">
                                  <select class="account_type" v-model="isHomeless" :disabled="isDisabled1">
                                    <option class="choose_account">Yes or No</option>
                                    <option value="True" class="choose_account">Yes</option>
                                    <option value="False" class="choose_account">No, where do you live?</option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>
                              <div class="yes-no-res">
                                <div class="label-title2">
                                        <label>Country of Residence <span class="red-star">*</span></label>
                                        <div class="account_select">
                                        <select v-model="resdcountry" class="account_type" :disabled="isDisabled1">
                                           
                                            <option
                                            v-for="(items, key) in countries"
                                            :key="key"
                                            :value="items.value"
                                            class="choose_account"
                                            >{{ items.label }}</option
                                            >
                                        </select>
                                        <font-awesome-icon icon="caret-down" class />
                                        </div>
                                    </div>
                                    <div class="label-title2">
                                        <label>State of Residence <span class="red-star">*</span></label>
                                      
                                        <div class="account_select">
                                          {{resdstate.label}}
                                        <select v-model="resdstate" class="account_type" :disabled="isDisabled1" >
                                            <option :value="resdstate.value">{{resdstate.label}}</option>
                                            <option
                                            v-for="(items, key) in states"
                                            :key="key"
                                            :value="items.value"
                                            class="choose_account"
                                            >{{ items.label }}</option
                                            >
                                        </select>
                                        <font-awesome-icon icon="caret-down" class />
                                        </div>
                                    </div>
                                    <div class="label-title2">
                                        <label>LGA of Residence <span class="red-star">*</span></label>
                                        <div class="account_select">
                                        
                                        <select class="account_type" v-model="resdlga" :disabled="isDisabled1" >
                                            
                                            <option
                                            :value="lga.value"
                                            v-for="(lga, index) in resdstate.lgas"
                                            :key="index"
                                            >{{ lga.label }}</option
                                            >
                                        </select>
                                        <font-awesome-icon icon="caret-down" class />
                                        </div>
                                    </div>
                                    <div class="label-title2">
                                        <label>Town/City of Residence <span class="red-star">*</span></label>
                                        <div class="account_select2">
                                        <input
                                            type="text"
                                            v-model="resdtown"
                                            class="input-amount"
                                            placeholder=" "
                                            :disabled="isDisabled1"
                                        />
                                        </div>
                                    </div>
                                    <div class="label-title2">
                                        <label>Address of Residence <span class="red-star">*</span></label>
                                        <div class="account_select2">
                                        <input
                                            type="text"
                                            v-model="resdaddress"
                                            class="input-amount"
                                            placeholder=" "
                                            :disabled="isDisabled1"
                                        />
                                        </div>
                                    </div>
                                </div>
                                <!-- continue button starts -->
                                <!-- <div class="send-or-download2" >
                                    <div class="continue-button">
                                        <button
                                             @click.prevent="isDisabled1 = false"
                                            class="continue-btn"
                                            >
                                            Edit
                                            <font-awesome-icon icon="edit" class="angle-icon" />
                                        </button>
                                    </div>
                                    <div class="continue-button">
                                        <button
                                            
                                            class="continue-btn"
                                             @click="nextForm"
                                            >
                                            Verified
                                            <font-awesome-icon icon="check-double" class="angle-icon" />
                                        </button>
                                    </div>
                                </div> -->
                                <!-- continue button ends -->
                            </div>
                            <!-- </div> -->
                            <!-- Your Address -->

                            <!-- When and where you born? -->
                            <!-- <div v-if="index === 3"> -->
                              <div class="non_nigerian_form">
                              <h3>When and where you born?</h3>
                              <div class="label-title2">
                                <label>
                                  Date of Birth 
                                  <span class="red-star">*</span>
                                </label>
                                <DatePicker :disabled="isDisabled1" v-model="dob" :disabled-date="disabledVer" valueType="format" format="DD-MM-YYYY" placeholder="DD-MM-YYYY"></DatePicker>
                                
                              </div>
                              <div class="label-title2">
                                <label>
                                  Date of Birth Verification
                                  <span class="red-star">*</span>
                                </label>
                                <DatePicker :disabled="isDisabled1" v-model="dobverf" :disabled-date="disabledVer" valueType="format" format="DD-MM-YYYY" placeholder="DD-MM-YYYY"></DatePicker>
                              </div>
                              <div class="label-title2">
                                    <label>Place of Birth - Country <span class="red-star">*</span></label>
                                    <div class="account_select">
                                    <select v-model="pobcountry" class="account_type" :disabled="isDisabled1">
                                        <option value>--Select Country--</option>
                                        <option
                                        v-for="(items, key) in countries"
                                        :key="key"
                                        :value="items.value"
                                        class="choose_account"
                                        >{{ items.label }}</option
                                        >
                                    </select>
                                    <font-awesome-icon icon="caret-down" class />
                                    </div>
                                </div>
                                <div class="label-title2">
                                    <label>Place of Birth - State <span class="red-star">*</span></label>
                                    <div class="account_select">
                                       {{pobstate.label}}
                                    <select v-model="pobstate" class="account_type" :disabled="isDisabled1">
                                        <option value>--Select State--</option>
                                        <option
                                        v-for="(items, key) in states"
                                        :key="key"
                                        :value="items.value"
                                        class="choose_account"
                                        >{{ items.label }}</option
                                        >
                                    </select>
                                    <font-awesome-icon icon="caret-down" class />
                                    </div>
                                </div>
                                <div class="label-title2">
                                    <label>Place of Birth - LGA <span class="red-star">*</span></label>
                                    <div class="account_select">
                                    <select class="account_type" v-model="poblga" :disabled="isDisabled1">
                                        <option class="choose_account" value
                                        >--Select Lga--</option
                                        >
                                        <option
                                        :value="lga.value"
                                        v-for="(lga, index) in pobstate.lgas"
                                        :key="index"
                                        >{{ lga.label }}</option
                                        >
                                    </select>
                                    <font-awesome-icon icon="caret-down" class />
                                    </div>
                                </div>
                                <!-- continue button starts -->
                                <!-- <div class="send-or-download2" >
                                    <div class="continue-button">
                                        <button
                                             @click.prevent="isDisabled1 = false"
                                            class="continue-btn"
                                            >
                                            Edit
                                            <font-awesome-icon icon="edit" class="angle-icon" />
                                        </button>
                                    </div>
                                    <div class="continue-button">
                                        <button
                                            
                                            class="continue-btn"
                                             @click="nextForm"
                                            >
                                            Verified
                                            <font-awesome-icon icon="check-double" class="angle-icon" />
                                        </button>
                                    </div>
                                </div> -->
                                <!-- continue button ends -->
                            </div>
                            <!-- </div> -->
                            <!-- When and where you born? -->

                            <!-- Where are you from? -->
                            <!-- <div v-if="index === 4"> -->
                              <div class="non_nigerian_form">
                              <h3>Where are you from?</h3>
                              <div class="label-title2">
                                    <label>Place of Origin - Country <span class="red-star">*</span></label>
                                    <div class="account_select">
                                    <select v-model="poocountry" class="account_type" :disabled="isDisabled1">
                                        <option value>--Select Country--</option>
                                        <option
                                        v-for="(items, key) in countries"
                                        :key="key"
                                        :value="items.value"
                                        class="choose_account"
                                        >{{ items.label }}</option
                                        >
                                    </select>
                                    <font-awesome-icon icon="caret-down" class />
                                    </div>
                                </div>
                                <div class="label-title2">
                                    <label>Place of Origin - State <span class="red-star">*</span></label>
                                    <div class="account_select">
                                      {{poostate.label}}
                                    <select v-model="poostate" class="account_type" :disabled="isDisabled1">
                                        <option value>--Select State--</option>
                                        <option
                                        v-for="(items, key) in states"
                                        :key="key"
                                        :value="items.value"
                                        class="choose_account"
                                        >{{ items.label }}</option
                                        >
                                    </select>
                                    <font-awesome-icon icon="caret-down" class />
                                    </div>
                                </div>
                                <div class="label-title2">
                                    <label>Place of Origin - LGA <span class="red-star">*</span></label>
                                    <div class="account_select">
                                    <select class="account_type" v-model="poolga" :disabled="isDisabled1">
                                        <option class="choose_account" value
                                        >--Select Lga--</option
                                        >
                                        <option
                                        :value="lga.value"
                                        v-for="(lga, index) in poostate.lgas"
                                        :key="index"
                                        >{{ lga.label }}</option
                                        >
                                    </select>
                                    <font-awesome-icon icon="caret-down" class />
                                    </div>
                                </div>
                                <div class="label-title2">
                                    <label>Place of Origin - Town <span class="red-star">*</span></label>
                                    <div class="account_select2">
                                    <input
                                        type="text"
                                        v-model="pootown"
                                        class="input-amount"
                                        placeholder=" "
                                        :disabled="isDisabled1"
                                    />
                                    </div>
                                </div>
                                <!-- continue button starts -->
                                <!-- <div class="send-or-download2" >
                                    <div class="continue-button">
                                        <button
                                            @click.prevent="isDisabled1 = false"
                                            class="continue-btn"
                                            >
                                            Edit
                                            <font-awesome-icon icon="edit" class="angle-icon" />
                                        </button>
                                    </div>
                                    <div class="continue-button">
                                        <button
                                            
                                             @click="nextForm"
                                            class="continue-btn"
                                            >
                                            Verified
                                            <font-awesome-icon icon="check-double" class="angle-icon" />
                                        </button>
                                    </div>
                                </div> -->
                                <!-- continue button ends -->
                            </div>
                            <!-- </div> -->
                            <!-- Where are you from? -->

                            <!-- Where is your Father from? -->
                            <!-- <div v-if="index === 5"> -->
                              <div class="non_nigerian_form">
                              <h3>Where is your Father from?</h3>
                              <div class="label-title2">
                                    <label>Place of Origin - Country <span class="red-star">*</span></label>
                                    <div class="account_select">
                                    <select v-model="fpoocountry" class="account_type" :disabled="isDisabled1">
                                      <option
                                        v-for="(items, key) in countries"
                                        :key="key"
                                        :value="items.value"
                                        class="choose_account"
                                        >{{ items.label }}</option
                                        >
                                    </select>
                                    <font-awesome-icon icon="caret-down" class />
                                    </div>
                                </div>
                                <div class="label-title2">
                                    <label>Place of Origin - State <span class="red-star">*</span></label>
                                    <div class="account_select">
                                      {{fpoostate.label}}
                                    <select v-model="fpoostate" class="account_type" :disabled="isDisabled1">
                                        <option value>--Select State--</option>
                                        <option
                                        v-for="(items, key) in states"
                                        :key="key"
                                        :value="items.value"
                                        class="choose_account"
                                        >{{ items.label }}</option
                                        >
                                    </select>
                                    <font-awesome-icon icon="caret-down" class />
                                    </div>
                                </div>
                                <div class="label-title2">
                                    <label>Place of Origin - LGA <span class="red-star">*</span></label>
                                    <div class="account_select">
                                    <select class="account_type" v-model="fpoolga" :disabled="isDisabled1">
                                        <option class="choose_account" value
                                        >--Select Lga--</option
                                        >
                                        <option
                                        :value="lga.value"
                                        v-for="(lga, index) in fpoostate.lgas"
                                        :key="index"
                                        >{{ lga.label }}</option
                                        >
                                    </select>
                                    <font-awesome-icon icon="caret-down" class />
                                    </div>
                                </div>
                                <div class="label-title2">
                                    <label>Place of Origin - Town <span class="red-star">*</span></label>
                                    <div class="account_select2">
                                    <input
                                        type="text"
                                        v-model="fpootown"
                                        class="input-amount"
                                        placeholder=" "
                                        :disabled="isDisabled1"
                                    />
                                    </div>
                                </div>
                                <!-- continue button starts -->
                                <!-- <div class="send-or-download2" >
                                    <div class="continue-button">
                                        <button
                                            @click.prevent="isDisabled1 = false"
                                            class="continue-btn"
                                            >
                                            Edit
                                            <font-awesome-icon icon="edit" class="angle-icon" />
                                        </button>
                                    </div>
                                    <div class="continue-button">
                                        <button
                                            
                                            class="continue-btn"
                                             @click="nextForm"
                                            >
                                            Verified
                                            <font-awesome-icon icon="check-double" class="angle-icon" />
                                        </button>
                                    </div>
                                </div> -->
                                <!-- continue button ends -->
                            </div>
                            <!-- </div> -->
                            <!-- Where is your Father from? -->

                            <!-- Where is your Mother from? -->
                            <!-- <div v-if="index ===6"> -->
                              <div class="non_nigerian_form">
                              <h3>Where is your Mother from?</h3>
                              <div class="label-title2">
                                    <label>Place of Origin - Country <span class="red-star">*</span></label>
                                    <div class="account_select">
                                    <select v-model="mpoocountry" class="account_type" :disabled="isDisabled1">
                                        <option value>--Select Country--</option>
                                        <option
                                        v-for="(items, key) in countries"
                                        :key="key"
                                        :value="items.value"
                                        class="choose_account"
                                        >{{ items.label }}</option
                                        >
                                    </select>
                                    <font-awesome-icon icon="caret-down" class />
                                    </div>
                                </div>
                                <div class="label-title2">
                                    <label>Place of Origin - State <span class="red-star">*</span></label>
                                    <div class="account_select">
                                      {{mpoostate.label}}
                                    <select v-model="mpoostate" class="account_type" :disabled="isDisabled1">
                                        <option value>--Select State--</option>
                                        <option
                                        v-for="(items, key) in states"
                                        :key="key"
                                        :value="items.value"
                                        class="choose_account"
                                        >{{ items.label }}</option
                                        >
                                    </select>
                                    <font-awesome-icon icon="caret-down" class />
                                    </div>
                                </div>
                                <div class="label-title2">
                                    <label>Place of Origin - LGA <span class="red-star">*</span></label>
                                    <div class="account_select">
                                    <select class="account_type" v-model="mpoolga" :disabled="isDisabled1">
                                        <option class="choose_account" value
                                        >--Select Lga--</option
                                        >
                                        <option
                                        :value="lga.value"
                                        v-for="(lga, index) in mpoostate.lgas"
                                        :key="index"
                                        >{{ lga.label }}</option
                                        >
                                    </select>
                                    <font-awesome-icon icon="caret-down" class />
                                    </div>
                                </div>
                                <div class="label-title2">
                                    <label>Place of Origin - Town <span class="red-star">*</span></label>
                                    <div class="account_select2">
                                    <input
                                        type="text"
                                        v-model="mpootown"
                                        class="input-amount"
                                        placeholder=" "
                                        :disabled="isDisabled1"
                                    />
                                    </div>
                                </div>
                                <!-- continue button starts -->
                                <!-- <div class="send-or-download2" >
                                    <div class="continue-button">
                                        <button
                                            @click.prevent="isDisabled1 = false"
                                            class="continue-btn"
                                            >
                                            Edit
                                            <font-awesome-icon icon="edit" class="angle-icon" />
                                        </button>
                                    </div>
                                    <div class="continue-button">
                                        <button
                                            
                                            class="continue-btn"
                                             @click="nextForm"
                                            >
                                            Verified
                                            <font-awesome-icon icon="check-double" class="angle-icon" />
                                        </button>
                                    </div>
                                </div> -->
                                <!-- continue button ends -->
                            </div>
                            <!-- </div> -->
                            <!-- Where is your Mother from? -->

                            <!-- Your Physical Features -->
                            <!-- <div v-if="index === 7"> -->
                              <div class="non_nigerian_form">
                              <h3>Your Physical Features</h3>
                              <div class="label-title">
                                <label>
                                  Gender
                                  <span class="red-star">*</span>
                                </label>
                                <div class="account_select">
                                  <select v-model="gender" class="account_type" :disabled="isDisabled1">
                                    <option class="choose_account">Select Gender</option>
                                    <option value="Male" class="choose_account">Male</option>
                                    <option value="Female" class="choose_account">Female</option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>
                              <div class="label-title">
                                <label>
                                  Tribal Mark
                                  
                                </label>
                                <div class="account_select">
                                  <select  class="account_type" v-model="tribalmarks" :disabled="isDisabled1">
                                    <option class="choose_account">Select</option>
                                    <option value="True" class="choose_account">Yes</option>
                                    <option value="none" class="choose_account">No</option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>Hair Color </label>
                                <div class="account_select2">
                                    <input
                                    v-model="haircolor"
                                    type="text"
                                    class="input-amount"
                                    placeholder=" "
                                    :disabled="isDisabled1"
                                    />
                                </div>
                              </div>
                              <div class="label-title">
                                <label>
                                  Hunch Back
                                </label>
                                <div class="account_select">
                                  <select  class="account_type" v-model="hunchback" :disabled="isDisabled1">
                                    <option class="choose_account">Select</option>
                                    <option value="True" class="choose_account">Yes</option>
                                    <option value="False" class="choose_account">No</option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>Height <span class="red-star">*</span></label>
                                <div class="account_select2">
                                    <input
                                    v-model="height"
                                    type="number"
                                    class="input-amount"
                                    placeholder=" "
                                    :disabled="isDisabled1"
                                    />
                                </div>
                              </div>
                              <!-- <div class="label-title2">
                                <label>Visible Scars </label>
                                <div class="account_select2">
                                    <input
                                    v-model="visiblescars"
                                    type="text"
                                    class="input-amount"
                                    placeholder=" "
                                    :disabled="isDisabled8"
                                    />
                                </div>
                              </div> -->
                              <div class="label-title">
                                <label>
                                  Visible Scars
                                </label>
                                <div class="account_select">
                                  <select  class="account_type" v-model="visiblescars" :disabled="isDisabled1">
                                    <option class="choose_account">Select</option>
                                    <option value="True" class="choose_account">Yes</option>
                                    <option value="False" class="choose_account">No</option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>Others </label>
                                <div class="account_select2">
                                    <input
                                    v-model="others"
                                    type="text"
                                    class="input-amount"
                                    placeholder=" "
                                    :disabled="isDisabled1"
                                    />
                                </div>
                              </div>
                              <div class="label-title">
                                <label>
                                  Residence Status <span class="red-star">*</span>
                                </label>
                                <div class="account_select">
                                  <select  class="account_type" v-model="resdstatus" :disabled="isDisabled1">
                                    <option class="choose_account">Select Residence Status</option>
                                   <option value="BIRTH" class="choose_account">Birth</option>
                                    <option value="NATURALIZATION" class="choose_account">Naturalization</option>
                                    <option value="REGISTRATION" class="choose_account">Registration</option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>
                              <div class="label-title2">
                                    <label>Nationality <span class="red-star">*</span></label>
                                    <div class="account_select">
                                      
                                    <select class="account_type" v-model="nationality" :disabled="isDisabled1" >
                                        
                                        <option
                                        v-for="(items, key) in countries"
                                        :key="key"
                                        :value="items.value"
                                        class="choose_account"
                                        >{{ items.label }}</option
                                        >
                                    </select>
                                    <font-awesome-icon icon="caret-down" class />
                                    </div>
                                </div>
                                <!-- continue button starts -->
                                <!-- <div class="send-or-download2" >
                                    <div class="continue-button">
                                        <button
                                            @click.prevent="isDisabled1 = false"
                                            class="continue-btn"
                                            >
                                            Edit
                                            <font-awesome-icon icon="edit" class="angle-icon" />
                                        </button>
                                    </div>
                                    <div class="continue-button">
                                        <button
                                            
                                            class="continue-btn"
                                             @click="nextForm"
                                            >
                                            Verified
                                            <font-awesome-icon icon="check-double" class="angle-icon" />
                                        </button>
                                    </div>
                                </div> -->
                                <!-- continue button ends -->
                            </div>
                            <!-- </div> -->
                            <!-- Your Physical Features -->

                            <!-- Your Other Details -->
                            <!-- <div v-if="index===8"> -->
                              <div class="non_nigerian_form">
                              <h3>Your Other Details</h3>
                              <div class="label-title">
                                <label>
                                  Marital Status <span class="red-star">*</span>
                                </label>
                                <div class="account_select">
                                  <select  class="account_type" v-model="maritalstatus" :disabled="isDisabled1">
                                    <option class="choose_account">Select Marital Status</option>
                                    <option value="Divorsed" class="choose_account">Divorsed</option>
                                    <option value="Married" class="choose_account">Married</option>
                                    <option value="Separated" class="choose_account">Separated</option>
                                    <option value="Single" class="choose_account">Single</option>
                                    <option value="Widowed" class="choose_account">Widowed</option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>
                              <div class="label-title2">
                                    <label>Main Native Language Spoken </label>
                                    <div class="account_select2">
                                        <input
                                        v-model="langspoken"
                                        type="text"
                                        class="input-amount"
                                        placeholder=" "
                                        :disabled="isDisabled1"
                                        />
                                    </div>
                                </div>
                                <div class="label-title2">
                                    <label>Language you read &amp; write <span class="red-star">*</span></label>
                                    <div class="account_select2">
                                        <input
                                        v-model="langreadwrite"
                                        type="text"
                                        class="input-amount"
                                        placeholder=" "
                                        :disabled="isDisabled1"
                                        />
                                    </div>
                                </div>
                                <div class="label-title2">
                                    <label>Other Language Spoken </label>
                                    <div class="account_select2">
                                        <input
                                        v-model="langothers"
                                        type="text"
                                        class="input-amount"
                                        placeholder=" "
                                        :disabled="isDisabled1"
                                        />
                                    </div>
                                </div>
                                <div class="label-title">
                                <label>
                                  Educational Level 
                                </label>
                                <div class="account_select">
                                  <select  class="account_type" v-model="educlevel" :disabled="isDisabled1">
                                    <option class="choose_account">Select Educational Level</option>
                                    <option value="" class="choose_account">None</option>
                                     <option value="Certification" class="choose_account">Certification</option>
                                    <option value="Primary" class="choose_account">Primary</option>
                                    <option value="Secondary" class="choose_account">Secondary</option>
                                    <option value="Tertiary" class="choose_account">Tertiary</option>
                                    <option value="PostGraduate" class="choose_account">Post Graduate</option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>
                              <div class="label-title">
                                <label>
                                  Religion 
                                </label>
                                <div class="account_select">
                                  <select  class="account_type" v-model="religion" :disabled="isDisabled1">
                                    <option class="choose_account">Select Religion</option>
                                    <option value="Christianity" class="choose_account">Christianity</option>
                                    <option value="Islam" class="choose_account">Islam</option>
                                    <option value="Traditional" class="choose_account">Traditional</option>
                                    <option value="Others" class="choose_account">Others</option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>
                              <div class="label-title2">
                                    <label>Occupation/Profession </label>
                                    <div class="account_select2">
                                        <input
                                        v-model="occupation"
                                        type="text"
                                        class="input-amount"
                                        placeholder=" "
                                        :disabled="isDisabled1"
                                        />
                                    </div>
                                </div>
                                <div class="label-title2">
                                    <label>
                                    Telephone 
                                    <span class="red-star">*</span>
                                    </label>
                                    <div class="account_select2">
                                    <the-mask
                                        mask="###########"
                                        type="tel"
                                        class="input-amount"
                                        v-model="phone"
                                        placeholder=" "
                                        required
                                        :disabled="isDisabled1"
                                    />
                                    </div>
                                </div>
                                <div class="label-title">
                                <label>
                                  Employment Status 
                                </label>
                                <div class="account_select">
                                  <select  class="account_type" v-model="emplstatus" :disabled="isDisabled1">
                                    <option class="choose_account">Select Employment Status</option>
                                     <option value="Employed" class="choose_account">Employed</option>
                                    <option value="Unemployed" class="choose_account">Unemployed</option>
                                    <option value="Pensional" class="choose_account">Pensional</option>
                                    <option value="SelfEmployed" class="choose_account">Self Employed</option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>
                              <div class="label-title">
                                <label>
                                  Home Delivery of the card 
                                </label>
                                <div class="account_select">
                                  <select  class="account_type" v-model="carddelivery" :disabled="isDisabled1">
                                    <option class="choose_account">Select</option>
                                    <option value="Yes" class="choose_account">Yes (courier fees will apply)</option>
                                    <option value="No" class="choose_account">No (“NO” indicates collection at point of registration)</option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>
                                  Email Address
                                </label>
                                <div class="account_select2">
                                  <input
                                    v-model="emailaddress"
                                    type="email"
                                    class="input-amount"
                                    placeholder=" "
                                    :disabled="isDisabled1"
                                  />
                                </div>
                              </div>
                              <!-- continue button starts -->
                                <!-- <div class="send-or-download2" >
                                    <div class="continue-button">
                                        <button
                                            @click.prevent="isDisabled1 = false"
                                            class="continue-btn"
                                            >
                                            Edit
                                            <font-awesome-icon icon="edit" class="angle-icon" />
                                        </button>
                                    </div>
                                    <div class="continue-button">
                                        <button
                                            
                                            class="continue-btn"
                                             @click="nextForm"
                                            >
                                            Verified
                                            <font-awesome-icon icon="check-double" class="angle-icon" />
                                        </button>
                                    </div>
                                </div> -->
                                <!-- continue button ends -->
                            </div>
                            <!-- </div> -->
                            <!-- Your Other Details -->

                            <!-- Guardian Details -->
                            <!-- <div v-if="index === 9"> -->
                              <div class="non_nigerian_form">
                              <h3>Guardian Details</h3>
                                <div class="label-title2">
                                    <label>Surname </label>
                                    <div class="account_select2">
                                        <input
                                        v-model="guardsname"
                                        type="text"
                                        class="input-amount"
                                        placeholder=" "
                                        :disabled="isDisabled1"
                                        />
                                    </div>
                                </div>

                                <div class="label-title2">
                                    <label>First Name </label>
                                    <div class="account_select2">
                                        <input
                                        v-model="guardfname"
                                        type="text"
                                        class="input-amount"
                                        placeholder=" "
                                        :disabled="isDisabled1"
                                        />
                                    </div>
                                </div>
                                <div class="label-title2">
                                    <label>Middle Name </label>
                                    <div class="account_select2">
                                        <input
                                        v-model="guardmname"
                                        type="text"
                                        class="input-amount"
                                        placeholder=" "
                                        :disabled="isDisabled1"
                                        />
                                    </div>
                                </div>
                                <div class="label-title2">
                                    <label>Email Address </label>
                                    <div class="account_select2">
                                        <input
                                        v-model="guardemail"
                                        type="email"
                                        class="input-amount"
                                        :disabled="isDisabled1"
                                        />
                                    </div>
                                </div>

                                <div class="label-title2">
                                    <label>Phone </label>
                                    <div class="account_select2">
                                        <input
                                        v-model="guardphone"
                                        type="tel"
                                        class="input-amount"
                                        placeholder=" "
                                        maxlength="11"
                                        :disabled="isDisabled1"
                                        />
                                    </div>
                                </div>

                                <div class="label-title2">
                                    <label>National Identification Number </label>
                                    <div class="account_select2">
                                        <input
                                        v-model="guardnin"
                                        type="tel"
                                        class="input-amount"
                                        placeholder=" "
                                        maxlength="13"
                                        :disabled="isDisabled1"
                                        />
                                    </div>
                                </div>
                                <!-- continue button starts -->
                                <!-- <div class="send-or-download2" >
                                    <div class="continue-button">
                                        <button
                                            @click.prevent="isDisabled1 = false"
                                            class="continue-btn"
                                            >
                                            Edit
                                            <font-awesome-icon icon="edit" class="angle-icon" />
                                        </button>
                                    </div>
                                    <div class="continue-button">
                                        <button
                                            
                                            class="continue-btn"
                                             @click="nextForm"
                                            >
                                            Verified
                                            <font-awesome-icon icon="check-double" class="angle-icon" />
                                        </button>
                                    </div>
                                </div> -->
                                <!-- continue button ends -->
                            </div>
                            <!-- </div> -->
                            <!-- Guardian Details -->

                            <!-- Next of kin  details -->
                            <!-- <div v-if="index === 10"> -->
                              <div class="non_nigerian_form">
                                <h3>Next of KIN Details</h3>
                                <div class="label-title2">
                                    <label>Surname of Next of KIN</label>
                                    <div class="account_select2">
                                        <input
                                        v-model="nkinsname"
                                        type="text"
                                        class="input-amount"
                                        placeholder=" "
                                        :disabled="isDisabled1"
                                        />
                                    </div>
                                </div>

                                <div class="label-title2">
                                    <label>First Name of Next of KIN</label>
                                    <div class="account_select2">
                                        <input
                                        v-model="nkinfname"
                                        type="text"
                                        class="input-amount"
                                        placeholder=" "
                                        :disabled="isDisabled1"
                                        />
                                    </div>
                                </div>
                                <div class="label-title2">
                                    <label>Middle Name of Next of KIN</label>
                                    <div class="account_select2">
                                        <input
                                        v-model="nkinmname"
                                        type="text"
                                        class="input-amount"
                                        placeholder=" "
                                        :disabled="isDisabled1"
                                        />
                                    </div>
                                </div>
                                <div class="label-title2">
                                    <label>Relationship of Next of KIN</label>
                                    <div class="account_select2">
                                        <input
                                        type="text"
                                        class="input-amount"
                                        v-model="nkinrelation"
                                        placeholder=""
                                        :disabled="isDisabled1"
                                        />
                                    </div>
                                </div>
                                <!-- continue button starts -->
                                <!-- <div class="send-or-download2" >
                                    <div class="continue-button">
                                        <button
                                            @click.prevent="isDisabled1 = false"
                                            class="continue-btn"
                                            >
                                            Edit
                                            <font-awesome-icon icon="edit" class="angle-icon" />
                                        </button>
                                    </div>
                                    <div class="continue-button">
                                        <button
                                            
                                            class="continue-btn"
                                             @click="nextForm"
                                            >
                                            Verified
                                            <font-awesome-icon icon="check-double" class="angle-icon" />
                                        </button>
                                    </div>
                                </div> -->
                                <!-- continue button ends -->
                            </div>
                            <!-- </div> -->

                            <!-- Address of your next of KIN -->
                            <!-- <div v-if="index === 11"> -->
                              <div class="non_nigerian_form">
                              <h3>Address of your next of KIN</h3>
                              <div class="yes-no-res">
                                <div class="label-title2">
                                        <label>Country of Residence <span class="red-star">*</span></label>
                                        <div class="account_select">
                                        <select class="account_type" v-model="nkincountry" :disabled="isDisabled1">
                                            <option value>--Select Country--</option>
                                            <option
                                            v-for="(items, key) in countries"
                                            :key="key"
                                            :value="items.value"
                                            class="choose_account"
                                            >{{ items.label }}</option
                                            >
                                        </select>
                                        <font-awesome-icon icon="caret-down" class />
                                        </div>
                                    </div>
                                    <div class="label-title2">
                                        <label>State of Residence <span class="red-star">*</span></label>
                                        <div class="account_select">
                                          {{nkinstate.label}}
                                        <select class="account_type" v-model="nkinstate" :disabled="isDisabled1">
                                            <option value>--Select State--</option>
                                            <option
                                            v-for="(items, key) in states"
                                            :key="key"
                                            :value="items.value"
                                            class="choose_account"
                                            >{{ items.label }}</option
                                            >
                                        </select>
                                        <font-awesome-icon icon="caret-down" class />
                                        </div>
                                    </div>
                                    <div class="label-title2">
                                        <label>LGA of Residence <span class="red-star">*</span></label>
                                        <div class="account_select">
                                        <select class="account_type" v-model="nkinlga" :disabled="isDisabled1">
                                            <option class="choose_account" value
                                            >--Select Lga--</option
                                            >
                                            <option
                                            :value="lga.value"
                                            v-for="(lga, index) in nkinstate.lgas"
                                            :key="index"
                                            >{{ lga.label }}</option
                                            >
                                        </select>
                                        <font-awesome-icon icon="caret-down" class />
                                        </div>
                                    </div>
                                    <div class="label-title2">
                                        <label>Town/City of Residence <span class="red-star">*</span></label>
                                        <div class="account_select2">
                                        <input
                                            v-model="nkintown"
                                            type="text"
                                            class="input-amount"
                                            placeholder=" "
                                            :disabled="isDisabled1"
                                        />
                                        </div>
                                    </div>
                                    <div class="label-title2">
                                        <label>Street of Residence <span class="red-star">*</span></label>
                                        <div class="account_select2">
                                        <input
                                            type="text"
                                            v-model="nkinstreet"
                                            class="input-amount"
                                            placeholder=" "
                                            :disabled="isDisabled1"
                                        />
                                        </div>
                                    </div>
                                    <div class="label-title2">
                                        <label>Next of KIN NIN </label>
                                        <div class="account_select2">
                                            <input
                                            v-model="nkinnin"
                                            type="tel"
                                            class="input-amount"
                                            placeholder=" "
                                            maxlength="13"
                                            :disabled="isDisabled1"
                                            />
                                        </div>
                                    </div>
                                    <div class="label-title2">
                                        <label>Email Address</label>
                                        <div class="account_select2">
                                            <input
                                            v-model="nkinemail"
                                            type="email"
                                            class="input-amount"
                                            placeholder=" "
                                            :disabled="isDisabled1"
                                            />
                                        </div>
                                    </div>
                                    <div class="label-title2">
                                        <label>Phone Number</label>
                                        <div class="account_select2">
                                            <input
                                            type="tel"
                                            class="input-amount"
                                            v-model="nkinphone"
                                            placeholder=" "
                                            maxlength="11"
                                            :disabled="isDisabled1"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <!-- continue button starts -->
                                <div class="send-or-download3" >
                                    <div class="continue-button">
                                        <button
                                            @click.prevent="isDisabled1 = false"
                                            class="continue-btn"
                                            >
                                            Edit
                                            <font-awesome-icon icon="edit" class="angle-icon" />
                                        </button>
                                    </div>
                                    <!-- <div class="continue-button">
                                        <button @click="nextForm"
                                            class="continue-btn"
                                            >
                                            Verified
                                            <font-awesome-icon icon="check-double" class="angle-icon" />
                                        </button>
                                    </div> -->
                                </div>
                                <!-- continue button ends -->
                            </div>
                            <!-- </div> -->
                            <!-- Address of your next of KIN -->
                        </div>

                        </div>
                        <div class="">
                          <p class="modal_congrats">By submitting this form, I consent to the use of the above information to enrol me for NIN, BVN and open accounts for me in Hope PSBank.</p>
                        </div>
                        
                      </div>
                      <!-- submit button shows -->
                      <div class="send-or-download2" >
                        <div class="continue-button">
                          <div @click="backForm" class="continue-btn">
                              <font-awesome-icon icon="angle-left" class="download-icon" />Back
                            </div>
                        </div>
                        <div class="continue-button" >
                            <b-button
                                type="submit"
                                class="continue-btn"
                                >
                                Submit
                                <font-awesome-icon icon="angle-right" class="angle-icon" />
                            </b-button>
                        </div>
                      </div>
                      <!-- submit dummy ends -->
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
      <b-modal
        id="modal-center"
        centered
        v-model="success_modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <img
              src="../../assets/images/icons/check.svg"
              alt="Hope Bank"
              class="modal_congrats_img"
            />
            <h1 class="pin-info text-center">Congratulations</h1>
            <div class="line4"></div>
            <p class="modal_congrats">
              Thank you for starting your registration process. To complete your registration, please visit our nearest centre or outlet for your data capturing. <b><router-link to="enrolmentcenter">Click here ...</router-link></b> to view our authorised centres or outlet.
            </p>
            
            <router-link to="/">
              <button class="ok-btn">Close</button>
            </router-link>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import HeaderNavRegister from "../../components/HeaderNav/HeaderNavRegister";
import { TheMask } from "vue-the-mask";
import Loader from "../../components/Loader/Loader.vue";
import { getCountryService } from "../../services/getCountries.services.js";
import { getStateService } from "../../services/getState.services.js";
import { mapGetters } from "vuex";
import { NINValidation } from "../../services/ninValidation.service.js";
// import { mapActions } from "vuex";
import Footer2 from "../../components/Footer/Footer2";
// import NaijaStates from 'naija-state-local-government';

export default {
  components: {
    HeaderNavRegister,
    TheMask,
    Loader: Loader,
    Footer2,
    // NaijaStates,
  },
  data() {
    return {
      index:0,
        haveNIN: "",
        haveBVN: "",
        haveHope: "",
        isDisabled: true,
        isDisabled_: true,
        isDisabled1: true,
        // isDisabled2: true,
        // isDisabled3: true,
        // isDisabled4: true,
        // isDisabled5: true,
        // isDisabled6: true,
        // isDisabled7: true,
        // isDisabled8: true,
        // isDisabled9: true,
        // isDisabled10: true,
        // isDisabled11: true,
        // isDisabled12: true,
        loading: false,
        Code: "",
        NIN: "" ,
        BVN: "",
        HopeAcct: "",
        title: "",
        lastname: "",
        firstname: "",
        middlename: "",
        othernames: "",
        maidenname: "",
        phone: "",
        isHomeless: "",
        nameState: null,
        resdtown: "",
        resdstate: "",
        resdcountry: "",
        resdlga: "",
        resdaddress: "",
        dob: "",
        dobverf: "",
        pobcountry: "",
        pobstate: "",
        poblga: "",
        poocountry: "",
        poostate: "",
        poolga: "",
        pootown: "",
        fpoocountry: "",
        fpoostate: "",
        fpoolga: "",
        fpootown: "",
        mpoocountry: "",
        mpoostate: "",
        mpoolga: "",
        mpootown: "",
        gender: "",
        tribalmarks: "",
        haircolor: "",
        hunchback: "",
        visiblescars: "",
        others: "",
        height: "",
        resdstatus: "",
        nationality: "",
        maritalstatus: "",
        langspoken: "",
        langreadwrite: "",
        langothers: "",
        educlevel: "",
        religion: "",
        occupation: "",
        emplstatus: "",
        carddelivery: "",
        emailaddress: "",
        nkinsname: "",
        nkinfname: "",
        nkinmname: "",    
        nkinrelation: "",
        nkincountry: "",
        nkinstate: "",
        nkinlga: "",
        nkintown: "",
        nkinstreet: "",
        nkinnin: "",
        nkinemail: "",
        nkinphone: "",
        countries:"",
        states:"",
        guardsname: "",
        guardfname: "",
        guardmname: "",    
        guardemail: "",
        guardphone: "",    
        guardnin: "",
        responseData: {},
    };
  },

  computed:{
   ...mapGetters({ NIN_DETAILS: "GET_NIN_DETAILS" })
  },
  methods: {
    disabledVer(date) {
      let tdate = new Date();
      let year = tdate.getFullYear();
      let month = tdate.getMonth();
      let day = tdate.getDate();
      const today = new Date(year - 18, month, day);
      today.setHours(0, 0, 0, 0);

      return date > today
    },
  
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid ? "valid" : "invalid";
      return valid;
    },
    handleOk(bvModalEvt) {
      window.console.log(bvModalEvt);
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs.modal.hide();
      });
    },
    // next_page() {
    //   this.$router.push("/");
    // },
  nextForm(){
      this.index++
    },
    backForm(){
      this.index--
    },
  generateNinDetails (){
    window.console.log(this.NIN_DETAILS)
    this.NIN = this.NIN_DETAILS.NIN
    this.BVN = this.NIN_DETAILS.BVN
    this.HopeAcct = this.NIN_DETAILS.HopeAcct
    this.haveNIN = this.NIN_DETAILS.haveNIN
    this.haveBVN = this.NIN_DETAILS.haveBVN
    this.haveHope = this.NIN_DETAILS.haveHope
    this.title = this.NIN_DETAILS.title   
    this.lastname = this.NIN_DETAILS.lastname   
    this.firstname = this.NIN_DETAILS.firstname
    this.middlename = this.NIN_DETAILS.middlename   
    this.othernames = this.NIN_DETAILS.othernames   
    this.maidenname = this.NIN_DETAILS.maidenname   
    this.isHomeless = this.NIN_DETAILS.isHomeless
    this.resdcountry = this.NIN_DETAILS.resdcountry
    this.resdcountry = this.NIN_DETAILS.resdcountry
    this.resdstate = this.NIN_DETAILS.resdstate
    this.resdlga = this.NIN_DETAILS.resdlga
    this.resdtown = this.NIN_DETAILS.resdtown
    this.resdaddress = this.NIN_DETAILS.resdaddress
    this.dob = this.NIN_DETAILS.dob
    this.dobverf = this.NIN_DETAILS.dobverf
    this.pobcountry = this.NIN_DETAILS.pobcountry
    this.pobstate = this.NIN_DETAILS.pobstate
    this.poblga = this.NIN_DETAILS.poblga
    this.poocountry = this.NIN_DETAILS.poocountry
    this.poostate = this.NIN_DETAILS.poostate
    this.poolga = this.NIN_DETAILS.poolga
    this.pootown = this.NIN_DETAILS.pootown
    this.fpootown = this.NIN_DETAILS.fpootown
    this.fpoocountry = this.NIN_DETAILS.fpoocountry
    this.fpoostate = this.NIN_DETAILS.fpoostate
    this.fpoolga = this.NIN_DETAILS.fpoolga
    this.mpoocountry = this.NIN_DETAILS.mpoocountry
    this.mpoostate = this.NIN_DETAILS.mpoostate
    this.mpoolga = this.NIN_DETAILS.mpoolga
    this.mpootown = this.NIN_DETAILS.mpootown
    this.gender = this.NIN_DETAILS.gender
    this.tribalmarks = this.NIN_DETAILS.tribalmarks
    this.haircolor = this.NIN_DETAILS.haircolor
    this.hunchback = this.NIN_DETAILS.hunchback
    this.height = this.NIN_DETAILS.height
    this.visiblescars = this.NIN_DETAILS.visiblescars
    this.resdstatus = this.NIN_DETAILS.resdstatus
    this.others = this.NIN_DETAILS.others
    this.nationality = this.NIN_DETAILS.nationality
    this.maritalstatus = this.NIN_DETAILS.maritalstatus
    this.langspoken = this.NIN_DETAILS.langspoken
    this.langreadwrite = this.NIN_DETAILS.langreadwrite
    this.langothers = this.NIN_DETAILS.langothers
    this.educlevel = this.NIN_DETAILS.educlevel
    this.religion = this.NIN_DETAILS.religion
    this.occupation = this.NIN_DETAILS.occupation
    this.phone = this.NIN_DETAILS.phone
    this.emplstatus = this.NIN_DETAILS.emplstatus
    this.carddelivery = this.NIN_DETAILS.carddelivery
    this.emailaddress = this.NIN_DETAILS.emailaddress
    this.nkinsname = this.NIN_DETAILS.nkinsname
    this.nkinfname = this.NIN_DETAILS.nkinfname
    this.nkinmname = this.NIN_DETAILS.nkinmname
    this.nkinrelation = this.NIN_DETAILS.nkinrelation
    this.nkincountry = this.NIN_DETAILS.nkincountry
    this.nkinstate = this.NIN_DETAILS.nkinstate
    this.nkinlga = this.NIN_DETAILS.nkinlga
    this.nkintown = this.NIN_DETAILS.nkintown
    this.nkinstreet = this.NIN_DETAILS.nkinstreet
    this.nkinnin = this.NIN_DETAILS.nkinnin
    this.nkinphone = this.NIN_DETAILS.nkinphone
    this.nkinemail = this.NIN_DETAILS.nkinemail   
    this.guardsname = this.guardsname
    this.guardfname = this.guardfname
    this.guardmname = this.guardmname    
    this.guardemail = this.guardemail
    this.guardphone = this.guardphone   
    this.guardnin = this.guardnin
  },
 async ninValidation(){
    this.loading = true
    const payload = {
      NIN : this.NIN,
    BVN : this.BVN,
    HopeAcct : this.HopeAcct,
    haveNIN : this.haveNIN,
    haveBVN : this.haveBVN,
    haveHope : this.haveHope,
    title : this.title,   
    lastname : this.lastname,   
    firstname : this.firstname,
    middlename : this.middlename,   
    othernames : this.othernames,   
    maidenname : this.maidenname,   
    isHomeless : this.isHomeless,
    resdcountry : this.resdcountry,
    resdstate : this.resdstate.value,
    resdlga : this.resdlga,
    resdtown : this.resdtown,
    resdaddress : this.resdaddress,
    dob : this.dob,
    dobverf : this.dobverf,
    pobcountry : this.pobcountry,
    pobstate : this.pobstate.value,
    poblga : this.poblga,
    poocountry : this.poocountry,
    poostate : this.poostate.value,
    poolga : this.poolga,
    pootown : this.pootown,
    fpootown : this.fpootown,
    fpoocountry : this.fpoocountry,
    fpoostate : this.fpoostate.value,
    fpoolga : this.fpoolga,
    mpoocountry : this.mpoocountry,
    mpoostate : this.mpoostate.value,
    mpoolga : this.mpoolga,
    mpootown : this.mpootown,
    gender : this.gender,
    tribalmarks : this.tribalmarks,
    haircolor : this.haircolor,
    hunchback : this.hunchback,
    height : this.height,
    visiblescars : this.visiblescars,
    resdstatus : this.resdstatus,
    others : this.others,
    nationality : this.nationality,
    maritalstatus : this.maritalstatus,
    langspoken : this.langspoken,
    langreadwrite : this.langreadwrite,
    langothers : this.langothers,
    educlevel : this.educlevel,
    religion : this.religion,
    occupation : this.occupation,
    phone : this.phone,
    emplstatus : this.emplstatus,
    carddelivery : this.carddelivery,
    emailaddress : this.emailaddress,
    nkinsname : this.nkinsname,
    nkinfname : this.nkinfname,
    nkinmname : this.nkinmname,
    nkinrelation : this.nkinrelation,
    nkincountry : this.nkincountry,
    nkinstate : this.nkinstate.value,
    nkinlga : this.nkinlga,
    nkintown : this.nkintown,
    nkinstreet : this.nkinstreet,
    nkinnin : this.nkinnin,
    nkinphone : this.nkinphone,
    nkinemail : this.nkinemail, 
    guardsname: this.guardsname,
    guardfname: this.guardfname,
    guardmname: this.guardmname,    
    guardemail: this.guardemail,
    guardphone: this.guardphone,    
    guardnin: this.guardnin,
    }
    await NINValidation.registerationService(payload).then((res) =>{
      window.console.log(res)
    this.$toastr.success("success", "Successful", {
                timeOut: 5000,
              });
               this.$bvModal.show("modal-center")
    }).catch((err) => this.$toastr.error(err.status, "Failed"))
        .finally(() => {
          this.loading = false;
        });
  },

    // get Countries 
    getCountries() {
      getCountryService
        .getCountry()
        .then((res) => {
          this.loading = false;
          this.countries = res;
        })
        .catch((err) => window.console.log(err));
    },

    //get States
    async GetStates() {
      await getStateService
        .getState()
        .then((res) => {
          this.states = res;
          //window.console.log("=====", res);
        })
        .catch((err) => window.console.log(err));
    },

    //Check Date Validity

    checkDateValidity(date) {
      if (this.Code.code != 207) {
        let tdate = new Date();
        let year = tdate.getFullYear();
        let month = tdate.getMonth();
        let day = tdate.getDate();
        let newYear = new Date(year - 18, month, day);
        let dob = new Date(date);

        if (dob > newYear) {
          this.DateOfBirth = "";
          return this.$toastr.error("Must be 18 years or older", "Error");
        }
      } else {
        let tdate = new Date();
        let year = tdate.getFullYear();
        let month = tdate.getMonth();
        let day = tdate.getDate();
        let newYear = new Date(year - 18, month, day);
        let newYear_ = new Date(year - 8, month, day);
        let dob = new Date(date);

        if (dob < newYear || dob > newYear_) {
          this.DateOfBirth = "";
          return this.$toastr.error(
            "Must be between 7 and 17 years old",
            "Error"
          );
        }
      }
    },

  },
  created() {
    this.GetStates();
    this.getCountries();
    this.generateNinDetails()
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.quick-transfer {
  padding: 0 2.2rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
}
.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
}
.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}
.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}
.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}

.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #ffffff !important;
}
.yellow_bg > .navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}
.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}
.main_dashboard {
  width: 75%;
  margin: 0 auto;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .main_dashboard {
    width: 100% !important;
  }
}
</style>
