import {ApiService} from "./api.service"

const NINValidation = {
    registerationService: async ({
        haveNIN,
        haveBVN,
        haveHope,
        Code,
        NIN,
        BVN,
        HopeAcct,
        title,
        lastname,
        firstname,
        middlename,
        othernames,
        maidenname,
        phone,
        isHomeless,
        nameState,
        resdtown,
        resdstate,
        resdcountry,
        resdlga,
        resdaddress,
        dob,
        dobverf,
        pobcountry,
        pobstate,
        poblga,
        poocountry,
        poostate,
        poolga,
        pootown,
        fpoocountry,
        fpoostate,
        fpoolga,
        fpootown,
        mpoocountry,
        mpoostate,
        mpoolga,
        mpootown,
        gender,
        tribalmarks,
        haircolor,
        hunchback,
        visiblescars,
        others,
        height,
        resdstatus,
        nationality,
        maritalstatus,
        langspoken,
        langreadwrite,
        langothers,
        educlevel,
        religion,
        occupation,
        emplstatus,
        carddelivery,
        emailaddress,
        nkinsname,
        nkinfname,
        nkinmname,    
        nkinrelation,
        nkincountry,
        nkinstate,
        nkinlga,
        nkintown,
        nkinstreet,
        nkinnin,
        nkinemail,
        nkinphone
    }) =>{
        return new Promise((resolve, reject) => {
            ApiService.post('/api/web/services/identity/register',{
                haveNIN,
        haveBVN,
        haveHope,
        Code,
        NIN,
        BVN,
        HopeAcct,
        title,
        lastname,
        firstname,
        middlename,
        othernames,
        maidenname,
        phone,
        isHomeless,
        nameState,
        resdtown,
        resdstate,
        resdcountry,
        resdlga,
        resdaddress,
        dob,
        dobverf,
        pobcountry,
        pobstate,
        poblga,
        poocountry,
        poostate,
        poolga,
        pootown,
        fpoocountry,
        fpoostate,
        fpoolga,
        fpootown,
        mpoocountry,
        mpoostate,
        mpoolga,
        mpootown,
        gender,
        tribalmarks,
        haircolor,
        hunchback,
        visiblescars,
        others,
        height,
        resdstatus,
        nationality,
        maritalstatus,
        langspoken,
        langreadwrite,
        langothers,
        educlevel,
        religion,
        occupation,
        emplstatus,
        carddelivery,
        emailaddress,
        nkinsname,
        nkinfname,
        nkinmname,    
        nkinrelation,
        nkincountry,
        nkinstate,
        nkinlga,
        nkintown,
        nkinstreet,
        nkinnin,
        nkinemail,
        nkinphone
            }).then((res)=>{
                if (res.data.success) return resolve(res.data);

                 reject(res.data)
            })
            .catch(err => {
                reject(err.response.data)
            })
        })
        
    },
    //confirmNINService
    confirmNINService: async ({
        pin,
        account,
        token
    }) => {
        return new Promise((resolve, reject) => {
             ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/services/identity/confirm/nin', localStorage.getItem("licence"))
                },
                data: {
                 
                    pin,
                    account,
                  

                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/services/identity/confirm/nin"

            }).then((res) => {
                window.console.log(res)
                if(res.data.success) return resolve(res.data)

                reject(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
        
    },
    verifyNIN: async ({
        pin,
        account,
        token,
        NIN
    }) => {
        return new Promise((resolve, reject) => {
             ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/services/identity/verify/nin', localStorage.getItem("licence"))
                },
                data: {
                 
                    pin,
                    account,
                    NIN
                  

                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/services/identity/verify/nin"

            }).then((res) => {
                window.console.log(res)
                if(res.data.success) return resolve(res.data)

                reject(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
        
    },
    verifyNINOtp: async ({
        NIN,
        otp,
        token,
        reference
    }) => {
        return new Promise((resolve, reject) => {
             ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/services/identity/verify/nin/otp', localStorage.getItem("licence"))
                },
                data: {
                 
                    otp,
                    reference,
                    NIN
                  

                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/services/identity/verify/nin/otp"

            }).then((res) => {
                window.console.log(res)
                if(res.data.success) return resolve(res.data)

                reject(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
        
    },
    ninEnrollmentCenter: async() =>{
        const licence =  localStorage.getItem('licence') ? localStorage.getItem('licence') : '124114982AA640178097CEBDDFA0E050'
        return new Promise((resolve, reject) => {
            ApiService.customRequest({
                method: 'GET',
                url: ApiService.getBaseUrl() + "/api/web/catalog/identity/outlets",
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/catalog/identity/outlets',licence)
                },
            })
                .then((res) => {
                    let data = res.data;
                resolve(data);
                })
                .catch(err => {
                    reject(err.response.data)
                })
        })
        
    },
}
export {
    NINValidation
};
