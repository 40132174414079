import {ApiService} from "./api.service";

const getInstitutionService = {
    getInstitution : () => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method : 'GET',
                url : ApiService.getBaseUrl() + "/api/web/catalog/institutions",
                headers: {
                    "Content-Type": "application/json",
                    "Hash" : ApiService.computeHash('/api/web/catalog/institutions', localStorage.getItem('licence'))
                },
                redirect : 'follow'
            })
            .then((res) => {
                if(res.data[0]) return resolve(res.data);
                    
                reject(res.data)
            })
            .catch (err => {
                reject (err.response.data)
            })
        });
    },

    getInstitutionWhenLoggedOut : () => {
        return new Promise(function (resolve, reject) {
            ApiService.get("/api/web/catalog/institutions")
            .then((res) => {
                if(res.data[0]) return resolve(res.data);
                    
                reject(res.data)
            })
            .catch (err => {
                reject (err.response.data)
            })
        });
    }
}

export {
    getInstitutionService
}