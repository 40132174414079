<template>
  <div class="poster-bg-">
    <div class="poster"></div>
    <div class="dashboard-page">
      <Loader v-if="loading" :show-full="true" loading-text="Processing" />

      <div class>
        <HeaderNavRegister />
        <div class="register_now">
          <div class="container">
            <div class="hope_account_opening">
              <h1 class>Hope Account Opening</h1>
              <p>Our account opening process is digital.</p>
            </div>
            <div class="main_dashboard">
              <div class="row">
                <div class="col-md-12">
                  <div class="steps_to_acc_open">
                    <h2>Create Checker</h2>
                  </div>
                  <div class="main_dashboard_inner">
                    <div class="kindly_enter_details mb-3">
                      <font-awesome-icon
                        icon="exclamation-circle"
                        class="exclamation-icon"
                      />
                      <p>
                        Open an account instantly by filling the form below.
                      </p>
                    </div>
                    <form @submit.prevent="CreateChecker">
                      <div class="form-table">
                        <div>
                          <!-- Select account type/category start -->
                          <div class="category">
                            <div class="label-title2">
                              <label>Account</label>
                              <div class="account_select">
                                <select
                                  v-model="account"
                                  name
                                  id
                                  class="account_type"
                                >
                                  <option value>--Select Account--</option>
                                  <option
                                    v-for="(account,
                                    key) in user.corporateAccounts"
                                    :key="key"
                                    :value="account"
                                    class="choose_account"
                                    >{{ account.accountName }}
                                    {{ account.accountType }}
                                    {{ account.accountNumber }}</option
                                  >
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>

                            <div class="label-title" v-show="index_ == 0">
                              <label>Enter Number of Checkers</label>
                              <div class="account_select">
                                <select
                                  v-model="CheckersNo"
                                  class="account_type"
                                >
                                  <option value>--Select--</option>
                                  <option
                                    :value="index + 1"
                                    v-for="(one, index) in 5"
                                    :key="index"
                                    class="choose_account"
                                    >{{ index + 1 }}</option
                                  >
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                          </div>

                          <!-- Input fields for BVN ACCOUNTS  start-->
                          <div v-if="CheckersNo">
                            <div class="bvn-accounts">
                              <div
                                class="input_fields"
                                v-for="(checkers, index) in CheckersNo"
                                :key="index"
                              >
                                <div
                                  class="non_nigerian_form"
                                  v-if="index == index_"
                                >
                                  <h3>Checker's {{ index + 1 }} Details</h3>
                                  <div class="label-title2">
                                    <label
                                      >BVN
                                      <span class="red-star">*</span></label
                                    >
                                    <div class="account_select2">
                                      <input
                                        minlength="11"
                                        maxlength="11"
                                        type="tel"
                                        class="input-amount"
                                        v-model="bvn"
                                        placeholder="BVN"
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div class="label-title2">
                                    <label
                                      >Phone Number
                                      <span class="red-star">*</span></label
                                    >
                                    <div class="account_select2">
                                      <input
                                        minlength="11"
                                        maxlength="11"
                                        type="tel"
                                        class="input-amount"
                                        v-model="phone"
                                        placeholder=" "
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div class="label-title2">
                                    <label
                                      >Date of Birth 
                                      <span class="red-star">*</span></label
                                    >
                                    <DatePicker v-model="dateOfBirth" valueType="format" format="MM-DD-YYYY" placeholder="MM-DD-YYYY"></DatePicker>
                                  </div>

                                  <div class="label-title2">
                                    <label
                                      >Email
                                      <span class="red-star">*</span></label
                                    >
                                    <div class="account_select2">
                                      <input
                                        type="email"
                                        class="input-amount"
                                        v-model="email"
                                        placeholder=" "
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div class="send-or-download2">
                                    <div class="continue-button">
                                      <a @click="$router.go(-1)">
                                        <div class="continue-btn ">
                                          <font-awesome-icon
                                            icon="angle-left"
                                            class="download-icon"
                                          />BACK
                                        </div>
                                      </a>
                                    </div>
                                    <div class="continue-button">
                                      <b-button
                                        type="submit"
                                        class="continue-btn"
                                      >
                                        Submit
                                        <font-awesome-icon
                                          icon="angle-right"
                                          class="angle-icon"
                                        />
                                      </b-button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Input fields for BVN ACCOUNTS  end-->
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </div>
  </div>
</template>

<script>
import HeaderNavRegister from "../../../components/HeaderNav/HeaderNavRegister";
import Loader from "../../../components/Loader/Loader.vue";
import { CorporateAccountService } from "../../../services/CorporateAccount/corporateAccountAuth.services.js";
import { mapGetters } from "vuex";
import Footer2 from "../../../components/Footer/Footer2";

export default {
  name: "Checkers",
  components: {
    HeaderNavRegister,
    Loader: Loader,
    Footer2,
  },
  data() {
    return {
      image: "",
      bvn: "",
      phone: "",
      dateOfBirth: "",
      loading: false,
      account: "",
      email: "",
      index_: 0,
      Code: "",
      CheckersNo: "",
      success_modal: false,
      responseData: {},
    };
  },
  computed: {
    NewAccountType() {
      return this.$store.getters.ACCOUNT;
    },
    ...mapGetters({ user: "GET_USER" }),
  },
  methods: {
    Next() {
      if (this.index_ >= this.CheckersNo - 1)
        return this.$router.push("/dashboard_");
      this.bvn = "";
      this.account = "";
      this.email = "";
      this.phone = "";
      this.dateOfBirth = "";
      this.index_++;
    },
    CreateChecker() {
      this.loading = true;
      CorporateAccountService.createChecker({
        Bvn: this.bvn,
        Phone: this.phone,
        DateOfBirth: this.dateOfBirth,
        account: this.account.accountNumber,
        email: this.email,
        corporate: this.account.customerId,
      })
        .then(() => {
          this.$toastr.success("Checker created Successfully", "Successful", {
            timeOut: 5000,
          });
          this.Next();
        })
        .catch((error) => {
          this.$toastr.error(error.status, "Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.quick-transfer {
  padding: 0 2.2rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
}
.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
}
.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}
.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}
.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}

.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #ffffff !important;
}
.yellow_bg > .navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}
.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}

.main_dashboard {
  padding: 1rem;
}
</style>
