<template>
  <div class="poster-bg-">
    <div class="poster"></div>
    <div class="dashboard-page">
      <Loader v-if="loading" :show-full="true" loading-text="Processing" />

      <div class>
        <HeaderNavRegister />
        <div class="register_now">
          <div class="container">
            <div class="hope_account_opening">
              <h1 class>Hope Account Opening</h1>
              <p>Our account opening process is digital.</p>
            </div>
            <div class="main_dashboard">
              <div class="row">
                <div class="col-md-12">
                  <div class="steps_to_acc_open">
                    <h2>Business Account Opening</h2>
                  </div>
                  <div class="main_dashboard_inner">
                    <div class="kindly_enter_details mb-3">
                      <font-awesome-icon
                        icon="exclamation-circle"
                        class="exclamation-icon"
                      />
                      <p>Continue account process by filling the form below.</p>
                    </div>
                    <div class="steps_to_acc_open">
                      <h2>
                        Enter Details -
                        <span class="h5">{{ ACCOUNT_TYPE.name }}</span>
                      </h2>
                    </div>

                    <form @submit.prevent="ValidateSignatories">
                      <div class="form-table">
                        <div>
                          <!-- Select number of signatories -->

                          <div class="category" v-show="isSelected">
                            <div class="label-title">
                              <label>Enter Number of Signatories</label>
                              <div class="account_select">
                                <select
                                  v-model="SignatoryNumber"
                                  required
                                  class="account_type"
                                >
                                  <option value>
                                    Select Number of Signatories
                                  </option>
                                  <option
                                    v-for="(one, index) in 29"
                                    :key="one"
                                    :value="index"
                                    class="choose_account"
                                  >
                                    {{ index + 1 }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                          </div>

                          <!-- Registered Business/Enterprise -->
                          <!-- <div class="category" v-show="isSelected">
                                <div class="label-title">
                                  <label>Enter Number of Signatories</label>
                                  <div class="account_select">
                                    <select
                                      v-model="SignatoryNumber"
                                      required
                                      class="account_type"
                                    >
                                      <option value
                                        >Select Number of Signatories</option
                                      >
                                      <option
                                        v-for="(one, index) in 3"
                                        :key="one"
                                        :value="index"
                                        class="choose_account"
                                        >{{ index + 1 }}</option
                                      >
                                    </select>
                                    <font-awesome-icon icon="caret-down" class />
                                  </div>
                                </div>
                              </div> -->
                          <!-- Registered Business/Enterprise -->

                          <!-- Input fields for BVN ACCOUNTS  start-->

                          <div class="bvn-accounts mt-3" v-if="Signatories">
                            <div class="row">
                              <div class="col-md-7">
                                <div
                                  class="non_nigerian_form"
                                  v-for="(director, index) in Signatories"
                                  :key="director"
                                >
                                  <div v-if="index == index_">
                                    <h3>
                                      <span>
                                        Enter Details of Signatory
                                        {{ index + 1 }}
                                      </span>
                                    </h3>
                                    <div class="label-title2">
                                      <label
                                        >Signatory's BVN
                                        <span class="red-star">*</span></label
                                      >
                                      <div class="account_select2">
                                        <the-mask
                                          mask="###########"
                                          type="tel"
                                          class="input-amount"
                                          v-model="Bvn"
                                          placeholder="BVN"
                                          required
                                        />
                                      </div>
                                    </div>

                                    <div class="label-title2">
                                      <label
                                        >Phone Number
                                        <span class="red-star">*</span></label
                                      >
                                      <div class="account_select2">
                                        <the-mask
                                          mask="###########"
                                          type="tel"
                                          class="input-amount"
                                          v-model="Phone_"
                                          placeholder=" "
                                          required
                                        />
                                      </div>
                                    </div>

                                    <div class="label-title2">
                                      <label
                                        >Email
                                        <span class="red-star">*</span></label
                                      >
                                      <div class="account_select2">
                                        <input
                                          type="email"
                                          v-model="email"
                                          class="input-amount"
                                          placeholder=" "
                                          required
                                        />
                                      </div>
                                    </div>

                                    <div class="label-title2">
                                      <label
                                        >Date of Birth
                                        <span class="red-star">*</span></label
                                      >
                                      <DatePicker v-model="DateOfBirth_" valueType="format" format="MM-DD-YYYY" placeholder="MM-DD-YYYY"></DatePicker>
                                    </div>

                                    <div
                                      class="label-title2"
                                      v-if="ACCOUNT_TYPE.code !== '111'"
                                    >
                                      <label
                                        >He/She can create
                                        Maker/Checker<b-button
                                          v-b-modal.modal-center-info
                                          class="tips-info"
                                          >?</b-button
                                        ></label
                                      >
                                      <div class="account_select">
                                        <select
                                          class="account_type"
                                          v-model="creators"
                                          required
                                        >
                                          <option value>--Select--</option>
                                          <option value="true">Yes</option>
                                          <option value="false">No</option>
                                        </select>
                                        <font-awesome-icon
                                          icon="caret-down"
                                          class
                                        />
                                      </div>
                                    </div>

                                    <div class="label-title2">
                                      <label>Signatory's Class</label>
                                      <div class="account_select">
                                        <select
                                          class="account_type"
                                          v-model="class_"
                                          required
                                        >
                                          <option value>
                                            Select Signatory Class
                                          </option>
                                          <option value="A">A</option>
                                          <option value="B">B</option>
                                          <option value="C">C</option>
                                          <option value="D">D</option>
                                          <option value="E">E</option>
                                          <option value="F">F</option>
                                        </select>
                                        <font-awesome-icon
                                          icon="caret-down"
                                          class
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- personal details -->
                                <div
                                  class="non_nigerian_form mt-5"
                                  v-if="BVN_DETAILS.data"
                                >
                                  <h3>Personal Details</h3>

                                  <div
                                    class="fetched_signatories mt-3 mb-3 mx-3"
                                  >
                                    <div class="form-table2">
                                      <div class="upload_profile_photo">
                                        <div class="profile_photo">
                                          <img
                                            src="../../../assets/images/upload.svg"
                                            alt="Hope Bank"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="label-title2">
                                    <label
                                      >First Name
                                      <span class="red-star">*</span></label
                                    >
                                    <div class="account_select2">
                                      <input
                                        v-model="FirstName"
                                        type="text"
                                        :disabled="isDisabled"
                                        class="input-amount"
                                        placeholder=" "
                                      />
                                    </div>
                                  </div>
                                  <div class="label-title2">
                                    <label>Middle Name</label>
                                    <div class="account_select2">
                                      <input
                                        v-model="MiddleName"
                                        type="text"
                                        :disabled="isDisabled"
                                        class="input-amount"
                                        placeholder=" "
                                      />
                                    </div>
                                  </div>
                                  <div class="label-title2">
                                    <label
                                      >Last Name
                                      <span class="red-star">*</span></label
                                    >
                                    <div class="account_select2">
                                      <input
                                        v-model="LastName"
                                        type="text"
                                        :disabled="isDisabled"
                                        class="input-amount"
                                        placeholder=" "
                                      />
                                    </div>
                                  </div>

                                  <div class="label-title2">
                                    <label>Phone Number</label>
                                    <div class="account_select2">
                                      <input
                                        type="text"
                                        class="input-amount"
                                        v-model="Phone"
                                        :disabled="isDisabled"
                                        placeholder=" "
                                      />
                                    </div>
                                  </div>
                                  <div class="label-title2">
                                    <label
                                      >Date of Birth
                                      <span class="red-star">*</span></label
                                    >
                                    <div class="account_select">
                                      <input
                                        type="text"
                                        placeholder="-DD-MM-YY-"
                                        v-model="DateOfBirth"
                                        :disabled="isDisabled"
                                        required
                                        class="input-amount"
                                      />
                                      <!-- <datetime
                                            placeholder="-DD-MM-YY-"
                                            v-model="DateOfBirth"
                                            :disabled="isDisabled"
                                            class="input-amount"
                                          ></datetime>
                                          <font-awesome-icon icon="calendar-check" /> -->
                                    </div>
                                  </div>

                                  <!-- <div class="label-title2">
                                          <label>Address</label>
                                          <div class="account_select2">
                                            <input
                                              type="text"
                                              class="input-amount"
                                              v-model="NkAddress"
                                              :disabled="isDisabled"
                                              placeholder=" "
                                            />
                                          </div>
                                      </div>-->

                                  <div class="label-title">
                                    <label>Gender</label>
                                    <div class="account_select">
                                      <select
                                        v-model="Gender"
                                        :disabled="isDisabled"
                                        class="account_type"
                                      >
                                        <option
                                          value="Male"
                                          class="choose_account"
                                        >
                                          Male
                                        </option>
                                        <option
                                          value="Female"
                                          class="choose_account"
                                        >
                                          Female
                                        </option>
                                      </select>
                                      <font-awesome-icon
                                        icon="caret-down"
                                        class
                                      />
                                    </div>
                                  </div>

                                  <!-- <div class="label-title2">
                                          <label>State of Origin</label>
                                          <div class="account_select2">
                                            <input
                                              type="text"
                                              class="input-amount"
                                              :disabled="isDisabled"
                                              placeholder=" "
                                            />
                                          </div>
                                        </div>
                                        <div class="label-title2">
                                          <label>LGA of Origin</label>
                                          <div class="account_select2">
                                            <input
                                              type="text"
                                              class="input-amount"
                                              :disabled="isDisabled"
                                              placeholder=" "
                                            />
                                          </div>
                                      </div>-->
                                </div>
                                <div class="d-flex justify-content-end mt-3">
                                  <button
                                    type="submit"
                                    v-if="isSubmit"
                                    class="continue-btn"
                                  >
                                    Add Signatory
                                    <font-awesome-icon
                                      icon="angle-right"
                                      class="angle-icon"
                                    />
                                  </button>
                                </div>
                              </div>
                              <div class="col-md-5">
                                <div class="recent-transactions">
                                  <div class="steps_to_acc_open">
                                    <h2>Added Signatories</h2>
                                  </div>
                                  <table>
                                    <tr>
                                      <th>Name</th>
                                      <th>BVN</th>
                                      <th>Phone No.</th>
                                      <th>Category</th>
                                      <th></th>
                                    </tr>

                                    <tr
                                      v-for="(signator, index) in SIGNATORIES"
                                      :key="index"
                                    >
                                      <td>{{ signator.fullName }}</td>
                                      <td>
                                        {{ signator.bvn }}
                                      </td>
                                      <td>{{ signator.phoneNumber }}</td>
                                      <td>{{ signator.signatoryClass }}</td>
                                      <td>
                                        <font-awesome-icon icon="trash" />
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- submit -->
                        <div class="send-or-download2">
                          <div class="continue-button">
                            <a @click="$router.go(-1)">
                              <div class="continue-btn">
                                <font-awesome-icon
                                  icon="angle-left"
                                  class="download-icon"
                                />Back
                              </div>
                            </a>
                          </div>

                          <div class="continue-button">
                            <b-button @click="Next" class="continue-btn">
                              Continue
                              <font-awesome-icon
                                icon="angle-right"
                                class="angle-icon"
                              />
                            </b-button>
                            <!-- <div
                                  @click="Next"
                                  v-if="isNext"
                                  class="continue-btn "
                                >
                                  Next
                                  <font-awesome-icon
                                    icon="angle-right"
                                    class="angle-icon"
                                  />
                                </div> -->

                            <!-- <router-link to="Complete"><button class="continue-btn">Create Account <font-awesome-icon icon="angle-right" class="angle-icon"/></button></router-link> -->
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-modal
          id="modal-center-info"
          centered
          hide-footer
          hide-header-close
          ref="myModal"
        >
          <form ref="form">
            <b-form-group class="pin-info">
              <div class="mandates_info">
                <p><b>MAKER:</b> Is a user who helps initiate transactions.</p>
                <p>
                  <b>CHECKER:</b> Is a user who check/confirm transactions
                  initiated by the maker for final approval by the signatory.
                </p>
              </div>

              <div @click="hideModal" class="mt-3">
                <div class="continue-btn text-center">OK</div>
              </div>
            </b-form-group>
          </form>
        </b-modal>
      </div>
      <Footer2 />
    </div>
  </div>
</template>

<script>
import HeaderNavRegister from "../../../components/HeaderNav/HeaderNavRegister";
import { TheMask } from "vue-the-mask";
import Loader from "../../../components/Loader/Loader.vue";
import { CorporateAccountService } from "../../../services/CorporateAccount/corporateAccount.services.js";
import { mapState } from "vuex";
import Footer2 from "../../../components/Footer/Footer2";

export default {
  name: "Signatories",
  components: {
    HeaderNavRegister,
    TheMask,
    Loader: Loader,
    Footer2,
  },
  data() {
    return {
      image: "",
      isSelected: true,
      loading: false,
      Code: "",
      creators: "",
      Bvn: "",
      Phone_: "",
      email: "",
      class_: "",
      DateOfBirth_: "",
      category: "",
      SignatoryNumber: "",
      index_: 0,
      Phone: "",
      isSubmit: true,
      isNext: false,
      DateOfBirth: "",
    };
  },
  computed: {
    ...mapState({
      ACCOUNT_TYPE: (state) => state.CreateAccount.ACCOUNT_TYPE,
      BVN_DETAILS: (state) => state.CreateAccount.BVN_DETAILS,
      CORPORATE: (state) => state.CreateAccount.CORPORATE_ACCOUNT,
      SIGNATORIES: (state) => state.CreateAccount.CORPORATE_ACCOUNT.SIGNATORIES,
    }),
    Signatories() {
      if (this.SignatoryNumber === "") return 0;
      return this.SignatoryNumber + 2;
    },
    FirstName: {
      get() {
        return this.BVN_DETAILS.data.firstName;
      },
      set(value) {
        this.BVN_DETAILS.data.firstName = value;
      },
    },
    LastName: {
      get() {
        return this.BVN_DETAILS.data.lastName;
      },
      set(value) {
        this.BVN_DETAILS.data.lastName = value;
      },
    },
    MiddleName: {
      get() {
        return this.BVN_DETAILS.data.middleName;
      },
      set(value) {
        this.BVN_DETAILS.data.middleName = value;
      },
    },
    phone: {
      get() {
        return this.BVN_DETAILS.data.phone;
      },
      set(value) {
        this.BVN_DETAILS.data.phone = value;
      },
    },
    dateOfBirth: {
      get() {
        return this.BVN_DETAILS.data.dateOfBirth;
      },
      set(value) {
        this.BVN_DETAILS.data.dateOfBirth = value;
      },
    },
    Gender: {
      get() {
        return this.BVN_DETAILS.data.gender;
      },
      set(value) {
        this.BVN_DETAILS.data.gender = value;
      },
    },
  },
  methods: {
    //Validate Signatories
    async ValidateSignatories() {
      this.loading = true;
      await CorporateAccountService.validationSignatories({
        company: this.CORPORATE.COMPANY_ID,
        Bvn: this.Bvn,
        Phone: this.Phone_,
        DateOfBirth: this.DateOfBirth_,
        class_: this.class_,
        email: this.email,
      })
        .then((res) => {
          this.isSubmit = false;
          this.isNext = true;
          this.Phone = res.data.phoneNumber;
          this.DateOfBirth = res.data.dateOfBirth;
          this.$store.commit("SET_BVN_DETAILS", res);
          this.$store.commit("SET_CORPORATE_SIGNATORIES_ID", res.id);
          this.$store.commit("SET_CORPORATE_SIGNATORIES", res.data);
          if (this.creators == "true")
            return this.$store.commit(
              "SET_CORPORATE_SIGNATORIES_CREATORS",
              res.id
            );
        })
        .catch((err) => this.$toastr.error(err.status, "Failed"))
        .finally(() => {
          this.loading = false;
        });
    },

    // change() {
    //   this.details = {
    //     one: "one"
    //   };
    //   this.isSubmit = false;
    //   this.isNext = true;
    //   this.$store.commit("SET_CORPORATE_SIGNATORIES", this.index_);
    //   if (this.creators == "yes") return this.$store.commit("SET_CORPORATE_SIGNATORIES_CREATORS", this.index_);

    // },

    Next() {
      if (this.index_ === this.SignatoryNumber)
        return this.$router.push("/mandate");
      this.index_++;
      this.isSubmit = true;
      this.isNext = false;
      this.Bvn = "";
      this.Phone_ = "";
      this.DateOfBirth_ = "";
      this.email = "";
      this.class_ = "";
      this.$store.commit("SET_BVN_DETAILS", {});
      this.isSelected = false;
      this.creators = "";
    },

    hideModal() {
      // setTimeout(()=>{
      //   this.$refs["myModal"]()
      // },1000);
      // setTimeout(function () { this.fetchHole() }.bind(this.$refs["myModal"]), 1000)
      this.$refs["myModal"].hide();
    },
    myModal() {
      this.$refs["myModal"].show();
      setTimeout(() => {}, 1000);
    },
  },
  created() {
    this.$store.commit("SET_BVN_DETAILS", {});
  },
  beforeDestroy() {
    this.$store.commit("SET_CORPORATE_SIGNATORIES_NO", this.Signatories);
  },
  mounted() {
    // this.myModal()
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.quick-transfer {
  padding: 0 2.2rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
}
.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
}
.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}
.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}
.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}

.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #ffffff !important;
}
.yellow_bg > .navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}
.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}

.main_dashboard {
  padding: 1rem;
}
</style>
