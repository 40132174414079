<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="logo-footer">
            <router-link to="/">
              <img src="../../assets/images/logo/hope-bank-payment-service-logo.svg" class="logo-footer" />
            </router-link>

            <div>

              <div class="social" style="font-size: 14px;margin-top: 20px;">
                Licensed by the Central Bank of Nigeria
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="footer-social">
            <div class="footer-social2">
              <div class="social">
                <a href="https://www.twitter.com/hopepsbank" target="_blank">
                  <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }" class="fb" />
                  <div>@hopepsbank</div>
                </a>
              </div>
              <div class="social">
                <a href="https://www.facebook.com/Hopepsbank-100293875242053" target="_blank">
                  <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook-square' }" class="fb" />
                  <div>@hopepsbank</div>
                </a>
              </div>
              <div class="social">
                <a href="https://www.instagram.com/hopepsbank" target="_blank">
                  <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }" class="fb" />
                  <div>@hopepsbank</div>
                </a>
              </div>
              <div class="social">
                <a href="tel:+2349017856291">
                  <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'whatsapp' }" class="fb" />
                  <div>09017856291</div>
                </a>
              </div>
              <div class="social">
                <a href="tel:01-2272871">
                  <font-awesome-icon icon="phone-square-alt" class="fb" />
                  <div>01-2272871</div>
                </a>
                <!-- <a href="tel:+01012345670" style="margin-left: 2rem;">+01 0123 45670</a> -->
              </div>
              <div class="social" style="word-break: break-all;">
                <a href="mailto:customercare@hopepsbank.com">
                  <font-awesome-icon icon="envelope" class="fb" />
                  <div class style="word-break: break-all;">customercare@hopepsbank.com</div>
                </a>
              </div>
              <div class="social2">
                <font-awesome-icon icon="map-marker-alt" class="fb" />
                <div class="text-left">
                  <a>
                    Hope Payment Service Bank Ltd,
                    <br />Commerce House, <br />1, Idowu Taylor Street,
                    <br />Victoria Island, Lagos.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class>
            <ul class="quick-link">
              <li>
                <router-link to="/">HOME</router-link>
              </li>
              <li>
                <router-link to="digitalbanking">DIGITAL BANKING</router-link>
              </li>
              <li>
                <router-link to="Start">OPEN ACCOUNT</router-link>
              </li>
              <li>
                <router-link to="about">ABOUT US</router-link>
              </li>
              <li>
                <router-link to="Contact">CONTACT US</router-link>
              </li>
              <li>
                <router-link to="Agency">AGENCY</router-link>
              </li>
              <li>
                <router-link to="nin">NIN or BVN REGISTRATION</router-link>
              </li>
              <li>
                <router-link to="howtotransact">HOW TO TRANSACT</router-link>
              </li>
              <li>
                <router-link to="PrivacyPolicy">PRIVACY POLICY</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3">
          <div class="footer-note">
            <h5>HOPE PAYMENT SERVICE BANK</h5>
            <p>
              To bring financial and payment services closer to all by providing
              tailored financial services and solutions for them across
              different channels, irrespective of their economic or social
              status and location.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="fixed-bottom">
      <div class="marquee">
        <div>
          <span>THIS WEBSITE IS FOR TESTING PURPOSE ONLY.</span>
          <span>THIS WEBSITE IS FOR TESTING PURPOSE ONLY.</span>
        </div>
      </div>
    </div> -->
  </footer>
</template>

<script>
export default {
  name: "Footer.vue",
};
</script>

<style scoped>
.marquee {
  height: 35px;
  width: 100%;
  background: #fecb32;
  overflow: hidden;
  position: relative;
  padding: 0.5rem;
}

.marquee div {
  display: block;
  width: 200%;
  height: 30px;

  position: absolute;
  overflow: hidden;

  animation: marquee 5s linear infinite;
}

.marquee span {
  font-family: Montserrat-Medium;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  float: left;
  width: 50%;
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}
</style>
