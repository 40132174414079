import { ApiService } from "./api.service";
import store from '../store/store'


const userService = {
    //Login user 
/*     login: ({ Phone, Password }) => {
        return new Promise(function (resolve, reject) {
            ApiService.post('/api/web/auth/signin', {
                Phone: Phone,
                Password: Password
            })
                .then((res) => {
                    if (res.data.success) {
                        let token = res.data.token;
                        localStorage.setItem("licence", res.data.license)
                        store.commit('SET_BVN', res.data.bvn)
                        // window.console.log(res.data)
                        return resolve(token);

                    }

                    reject(res.data)
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });

    }, */
    login: ({ Phone, Password }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'POST',
                url: ApiService.getBaseUrl() + '/api/web/auth/signin',
                data: {
                    Phone: Phone,
                    Password: Password
                },
                headers: {
                    Hash: ApiService.computeHash('/api/web/auth/signin', "124114982AA640178097CEBDDFA0E050")
                }
            })
                .then((res) => {
                    if (res.data.success) {
                        let token = res.data.token;
                        localStorage.setItem("licence", res.data.license)
                        store.commit('SET_BVN', res.data.bvn)
                        // window.console.log(res.data)
                        return resolve(token);

                    }

                    reject(res.data)
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });

    },

 

    //Logout user
    logout: () => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: "GET",
                url: ApiService.getBaseUrl() + "api/web/auth/signout",
                headers: {
                    'Hash': ApiService.computeHash('/api/web/auth/signout', localStorage.getItem("licence"))
                },
            })
                .then((res) => {
                    if (res.data.success) {
                        localStorage.setItem("**-jive-data-00-**", "");
                        localStorage.clear();
                        resolve(res.data);
                    }
                    else {
                        reject(res.data)
                    }
                })
        });
    },

    //internet Banking registration start
    verifyAccount: ({ Account }) => {
        return new Promise(function (resolve, reject) {
            ApiService.post(`/api/web/auth/signup/validate/${Account}`)
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)

                })
        });

    },

    //internet Bnaking Registration complete
    completeRegistration: ({ CustomerId, Code, Account, Password, Pin, question1, question2, question3, answer1, answer2, answer3 }) => {

        const Questions = {}
        Questions[question1] = answer1;
        Questions[question2] = answer2;
        Questions[question3] = answer3;

        return new Promise((resolve, reject) => {
            ApiService.post('/api/web/auth/signup/complete', {
                CustomerId,
                Code,
                Account,
                Password,
                Pin,
                Questions
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data)

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        })
    },

    //Get Current User Details
    authorize: async (token) => {
        return await ApiService.customRequest({
            headers: {
                'Authorization': 'Bearer ' + token,
                'Hash': ApiService.computeHash('/api/web/auth/me', localStorage.getItem("licence"))
            },
            method: "POST",
            url: ApiService.getBaseUrl() + "/api/web/auth/me"
        }).then((res) => {
            return Promise.resolve(res.data);
        }).catch((error) => {
            return Promise.reject(error.response.data);
        });
    },


    //set default account
    defaultAccount: async ({ Token, Account }) => {
        return new Promise((resolve, reject) => {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + Token,
                    'Hash': ApiService.computeHash(`/api/web/self/default/${Account}`, localStorage.getItem("licence"))
                },
                data: {


                },
                method: "POST",
                url: ApiService.getBaseUrl() + `/api/web/self/default/${Account}`

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        })
    },
    defaultCooperateAccount: async ({ Token, Account }) => {
        return new Promise((resolve, reject) => {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + Token,
                    'Hash': ApiService.computeHash(`/api/web/self/default/${Account}`, localStorage.getItem("licence"))
                },
                data: {


                },
                method: "POST",
                url: ApiService.getBaseUrl() + `/api/web/self/default/${Account}`

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        })
    }

};

export {
    userService
};