/* eslint-disable no-useless-escape */
<template>
  <div class="poster-bg">
    <div class="poster"></div>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />
    <div class="dashboard-page registration-page">
      <div>
        <HeaderNavIB />
        <div class="register_now">
          <div class="container">
            <div class="main_dashboard">
              <div class="row">
                <div class="col-md-12">
                  <div class="main_dashboard_inner">
                    <div class="transfer_money2 text-center">
                      <p class="frequently-used text-center">Internet Banking Registration</p>
                    </div>
                    <div class="current_tab_title mb-3">
                      <font-awesome-icon icon="play" class="play-icon" />
                      <p class>
                        Create password to secure account for your Internet
                        Banking
                      </p>
                    </div>
                    <div class="kindly_enter_details mb-3">
                      <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                      <p>
                        <b>
                          Please note that Answers to Security Questions are <strong>NOT</strong>
                          Case Sensitive
                        </b>
                      </p>
                    </div>
                    <ValidationObserver v-slot="{ passes }">
                      <form @submit.prevent="passes(completeRegistration)" action>
                        <div class="form-table2">
                          <div class="label-title">
                            <label>Choose Security Question *</label>
                            <ValidationProvider
                              name="question1"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <div class="account_select">
                                <select name id v-model="question1" class="account_type">
                                  <option value class="choose_account">- Select -</option>
                                  <option
                                    :value="index"
                                    v-for="(item,
                                    index) in VALIDATION_CREDENTIALS.questions"
                                    :key="index"
                                    class="choose_account"
                                  >{{ item }}</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                              <span style="font-size:13px; color:red" class>
                                <span v-if="errors[0]">
                                  <font-awesome-icon
                                    icon="ban"
                                    size="1x"
                                    class="ban-icon text-danger"
                                  />
                                </span>
                                {{ errors[0] }}
                              </span>
                            </ValidationProvider>
                          </div>
                          <div class="label-title2">
                            <label>Answer to Secret Question *</label>
                            <ValidationProvider name="answer1" rules="required" v-slot="{ errors }">
                              <div class="account_select2">
                                <input
                                  type="text"
                                  v-model="answer1"
                                  class="input-amount"
                                  placeholder=" "
                                />
                              </div>
                              <span style="font-size:13px; color:red" class>
                                <span v-if="errors[0]">
                                  <font-awesome-icon
                                    icon="ban"
                                    size="1x"
                                    class="ban-icon text-danger"
                                  />
                                </span>
                                {{ errors[0] }}
                              </span>
                            </ValidationProvider>
                          </div>
                          <div class="line3"></div>
                          <div class="label-title">
                            <label>Choose Security Question *</label>
                            <ValidationProvider
                              name="question2"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <div class="account_select">
                                <select name id v-model="question2" class="account_type">
                                  <option value class="choose_account">- Select -</option>
                                  <option
                                    :value="index"
                                    v-for="(item,
                                    index) in Questions2"
                                    :key="index"
                                    class="choose_account"
                                  >{{ item }}</option>/option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                              <span style="font-size:13px; color:red" class>
                                <span v-if="errors[0]">
                                  <font-awesome-icon
                                    icon="ban"
                                    size="1x"
                                    class="ban-icon text-danger"
                                  />
                                </span>
                                {{ errors[0] }}
                              </span>
                            </ValidationProvider>
                          </div>
                          <div class="label-title2">
                            <label>Answer to Secret Question *</label>
                            <ValidationProvider name="answer2" rules="required" v-slot="{ errors }">
                              <div class="account_select2">
                                <input
                                  type="text"
                                  v-model="answer2"
                                  class="input-amount"
                                  placeholder=" "
                                />
                              </div>
                              <span style="font-size:13px; color:red" class>
                                <span v-if="errors[0]">
                                  <font-awesome-icon
                                    icon="ban"
                                    size="1x"
                                    class="ban-icon text-danger"
                                  />
                                </span>
                                {{ errors[0] }}
                              </span>
                            </ValidationProvider>
                          </div>
                          <div class="line3"></div>
                          <div class="label-title">
                            <label>Choose Security Question *</label>
                            <ValidationProvider
                              name="question3"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <div class="account_select">
                                <select name id v-model="question3" class="account_type">
                                  <option value class="choose_account">- Select -</option>
                                  <option
                                    :value="index"
                                    v-for="(item,
                                    index) in Questions3"
                                    :key="index"
                                    class="choose_account"
                                  >{{ item }}</option>/option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                              <span style="font-size:13px; color:red" class>
                                <span v-if="errors[0]">
                                  <font-awesome-icon
                                    icon="ban"
                                    size="1x"
                                    class="ban-icon text-danger"
                                  />
                                </span>
                                {{ errors[0] }}
                              </span>
                            </ValidationProvider>
                          </div>
                          <div class="label-title2">
                            <label>Answer to Secret Question *</label>
                            <ValidationProvider name="answer3" rules="required" v-slot="{ errors }">
                              <div class="account_select2">
                                <input
                                  type="text"
                                  v-model="answer3"
                                  class="input-amount"
                                  placeholder=" "
                                />
                              </div>
                              <span style="font-size:13px; color:red" class>
                                <span v-if="errors[0]">
                                  <font-awesome-icon
                                    icon="ban"
                                    size="1x"
                                    class="ban-icon text-danger"
                                  />
                                </span>
                                {{ errors[0] }}
                              </span>
                            </ValidationProvider>
                          </div>

                          <!-- <div class="label-title2">
                            <label>Account Number *</label>
                            <ValidationProvider
                              name="accountNumber"
                              rules="required|numeric|min:10|max:10"
                              v-slot="{ errors }"
                            >
                              <div class="account_select2">
                                <input
                                  type="text"
                                  v-model="accountNumber"
                                  class="input-amount"
                                  placeholder=" "
                                />
                              </div>
                              <span style="font-size:13px; color:red" class>
                                <span v-if="errors[0]">
                                  <font-awesome-icon
                                    icon="ban"
                                    size="1x"
                                    class="ban-icon text-danger"
                                  />
                                </span>
                                {{ errors[0] }}
                              </span>
                            </ValidationProvider>
                          </div>-->
                          <div class="line3"></div>
                          <div class="label-title2">
                            <label>Create New Password *</label>
                            <ValidationProvider
                              name="password"
                              rules="required|min:8"
                              v-slot="{ errors }"
                            >
                              <div class="account_select2" id="tooltip-variant">
                                <input
                                  type="password"
                                  v-model="password"
                                  ref="password"
                                  class="input-amount"
                                  placeholder=" "
                                />
                              </div>
                              <b-tooltip
                                target="tooltip-variant"
                                variant="info"
                                custom-class="my-tooltip-class"
                                placement="left"
                              >
                                Password must be atleast 8 characters long and
                                must contain an uppercase, lowercase, a number
                                and a symbol (e.g %, @, $, #).
                              </b-tooltip>
                              <span style="font-size:13px; color:red" class>
                                <span v-if="errors[0]">
                                  <font-awesome-icon
                                    icon="ban"
                                    size="1x"
                                    class="ban-icon text-danger"
                                  />
                                </span>
                                {{ errors[0] }}
                              </span>
                            </ValidationProvider>
                          </div>
                          <div class="label-title2">
                            <label>Confirm New Password *</label>
                            <ValidationProvider
                              name="passwords"
                              rules="required|confirmed:password"
                              v-slot="{ errors }"
                            >
                              <div class="account_select2">
                                <input
                                  type="password"
                                  data-vv-as="password"
                                  v-model="password1"
                                  class="input-amount"
                                  placeholder=" "
                                />
                              </div>
                              <span style="font-size:13px; color:red" class>
                                <span v-if="errors[0]">
                                  <font-awesome-icon
                                    icon="ban"
                                    size="1x"
                                    class="ban-icon text-danger"
                                  />
                                </span>
                                {{ errors[0] }}
                              </span>
                            </ValidationProvider>
                          </div>
                          <div class="line3"></div>
                          <!-- <div class="label-title2">
                            <label>Create New PIN *</label>
                            <ValidationProvider name="pin" rules="required" v-slot="{ errors }">
                              <div class="account_select2">
                                <the-mask
                                  mask="######"
                                  type="password"
                                  v-model="pin"
                                  ref="pin"
                                  class="input-amount"
                                  placeholder="******"
                                />
                              </div>
                              <span style="font-size:13px; color:red" class>
                                <span v-if="errors[0]">
                                  <font-awesome-icon
                                    icon="ban"
                                    size="1x"
                                    class="ban-icon text-danger"
                                  />
                                </span>
                                {{ errors[0] }}
                              </span>
                            </ValidationProvider>
                          </div>-->
                          <div class="label-title2">
                            <label>Enter PIN *</label>
                            <ValidationProvider name="pins" rules="required" v-slot="{ errors }">
                              <div class="account_select2">
                                <the-mask
                                  mask="########"
                                  type="password"
                                  v-model="pin1"
                                  data-vv-name="pin"
                                  class="input-amount"
                                  placeholder="******"
                                />
                              </div>
                              <span style="font-size:13px; color:red" class>
                                <span v-if="errors[0]">
                                  <font-awesome-icon
                                    icon="ban"
                                    size="1x"
                                    class="ban-icon text-danger"
                                  />
                                </span>
                                {{ errors[0] }}
                              </span>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="send-or-download2">
                          <div class="continue-button">
                            <a @click="$router.go(-1)">
                              <div class="continue-btn btn">
                                <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                              </div>
                            </a>
                          </div>
                          <div class="continue-button">
                            <b-button class="continue-btn" type="submit">
                              Submit
                              <font-awesome-icon icon="angle-right" class="angle-icon" />
                            </b-button>
                            <!--<router-link to="Complete"><button class="continue-btn">Submit <font-awesome-icon icon="angle-right" class="angle-icon"/></button></router-link>-->
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
      <b-modal
        id="modal-center"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
        v-model="showModal"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <img
              src="../../assets/images/icons/check.svg"
              alt="Hope Bank"
              class="modal_congrats_img"
            />
            <h1 class="pin-info text-center">Congratulations</h1>
            <div class="line4"></div>
            <p class="modal_congrats">
              You have successfully registered for Digital Banking. Click OK to
              Login
            </p>
            <router-link to="Login">
              <button class="ok-btn">OK</button>
            </router-link>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import HeaderNavIB from "../../components/HeaderNav/HeaderNavIB";
import Loader from "../../components/Loader/Loader.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { userService } from "../../services/user.service.js";
import { mapState } from "vuex";
import Footer2 from "../../components/Footer/Footer2";

export default {
  name: "SecurityQuestion.vue",
  components: {
    HeaderNavIB,
    Loader,
    ValidationObserver,
    ValidationProvider,
    Footer2
  },
  data() {
    return {
      showTooltip: true,
      image: "",
      showModal: false,
      loading: false,
      Questions2: {},
      Questions3: {},
      question1: "",
      question2: "",
      question3: "",
      answer1: "",
      answer2: "",
      answer3: "",
      accountNumber: "",
      password: "",
      password1: "",
      pin: "",
      pin1: ""
    };
  },
  computed: {
    ...mapState({
      VALIDATION_CREDENTIALS: state => state.User.VALIDATION_CREDENTIALS,
      VERIFICATION_CODE: state => state.User.VERIFICATION_CODE,
      ACCOUNT_NUMBER: state => state.User.ACCOUNT_NUMBER
    })
  },
  watch: {
    question1(newval) {
      let obj = this.VALIDATION_CREDENTIALS.questions;
      let newObj = {};
      Object.keys(obj).reduce((result, key) => {
        if (key !== newval) {
          newObj[key] = obj[key];
        }
      }, {});
      //window.console.log(newObj);
      this.Questions2 = newObj;
    },
    question2(newval) {
      let obj = this.Questions2;
      let newObj = {};
      Object.keys(obj).reduce((result, key) => {
        if (key !== newval) {
          newObj[key] = obj[key];
        }
      }, {});
      //window.console.log(newObj);
      this.Questions3 = newObj;
    }
  },
  methods: {
    async completeRegistration() {
      this.loading = true;
      const data = {
        CustomerId: this.VALIDATION_CREDENTIALS.customerId,
        Code: this.VERIFICATION_CODE,
        Account: this.ACCOUNT_NUMBER,
        Password: this.password,
        Pin: this.pin1,
        question1: this.question1,
        question2: this.question2,
        question3: this.question3,
        answer1: this.answer1,
        answer2: this.answer2,
        answer3: this.answer3
      };
      await userService
        .completeRegistration(data)
        .then(res => {
          this.loading = false;
          this.showModal = true;
          window.console.log(res);
        })
        .catch(err => {
          this.$toastr.error(err.status, "Registration Failed!", {
            timeOut: 5000
          });
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.quick-transfer {
  padding: 0 2.2rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
  /* letter-spacing: 0.17px; */
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}
.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}
.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}

/*.plus_icon{*/
/*margin-right: 1rem;*/
/*font-size: 20px;*/
/*}*/
.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #ffffff !important;
}
.yellow_bg > .navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}
.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}

.input-amount {
  text-align: left;
}

.my-tooltip-class {
  color: #fecb32 !important;
}
.main_dashboard {
  width: 75%;
  margin: 0 auto;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .main_dashboard {
    width: 100% !important;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
