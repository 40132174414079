<template>
  <div class>
    <div class="poster"></div>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />

    <div class="dashboard-page overview_main_page">
      <HeaderNavBizDashboard />
      <div class="container">
        <BusinessNavBar />
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-12">
              <div class="main_dashboard_inner3">
                <div class="overview_greetings">
                  <div class="overview_greeting">
                    <font-awesome-icon icon="chart-bar" />
                    <div class="frequently-used">Business Dashboard</div>
                  </div>
                  <div class="greetings">
                    Hi,
                    <!-- <span v-if="user.accounts[0]">{{user.accounts[0].accountName}}</span> -->
                    <span v-if="user.fullName">{{ user.firstName }}</span>
                  </div>
                </div>
                <div class="line"></div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="overview_border2">
                      <div class="overview_header">
                        <font-awesome-icon icon="briefcase" />
                        <div class="profile_title">Business Profile</div>
                      </div>
                      <div class="p-3">
                        <div class="profile-image">
                          <img
                            src="../../../../assets/images/avatar-icon.svg"
                            alt="Profile Picture"
                          />
                        </div>
                        <div class="profile-info">
                          <h4 v-if="user.accounts[0]">
                            {{ user.accounts[0].accountName }}
                          </h4>
                          <p>Account Type: <b>Business Account</b></p>
                          <!-- <p>Account Number: -</p> -->
                          <!-- <p v-for="(account, index) in user.corporateAccounts" :key="index">
                            <b>{{account.accountType}} - {{account.accountNumber}}</b>
                          </p>-->
                          <div>
                            <b-button v-b-toggle.collapse-3 class="terms_button"
                              >View All Accounts</b-button
                            >
                            <b-collapse id="collapse-3">
                              <!-- <p v-for="(account, index) in user.corporateAccounts" :key="index" class="mb-1">
                                <b>{{account.accountName}}</b><br>
                                <b>{{account.accountType}} - {{account.accountNumber}}</b>
                              </p> -->
                              <div
                                v-for="account in user.corporateAccounts"
                                :key="account.accountNumber"
                                class="radio-item mb-1"
                              >
                                <input
                                  type="radio"
                                  v-model="defaultAccount"
                                  :id="account.accountNumber"
                                  :value="account.accountNumber"
                                />
                                <label :for="account.accountNumber"
                                  ><b>{{ account.accountName }}</b
                                  ><br />
                                  <b
                                    >{{ account.accountType }} -
                                    {{ account.accountNumber }}</b
                                  ></label
                                >
                              </div>
                              <!-- <div class="radio-item">
                                  <input type="radio" id="ritemb" name="ritem" value="ropt2">
                                  <label for="ritemb">Option 2</label>
                              </div> -->
                              <!-- <button class="continue-btn mt-2 mb-2" @click.prevent="setDefaultAccount">Set as default Account</button> -->
                            </b-collapse>
                          </div>

                          <p>
                            BVN -
                            <b>{{ BVN }}</b>
                          </p>
                          <p>
                            Phone Number -
                            <b>{{ user.phone }}</b>
                          </p>
                        </div>
                      </div>
                      <div class="last_login2">
                        <div class="last_login">
                          <h4>Last Login</h4>
                        </div>
                        <div class="login-history2">
                          <div>
                            <div class="web">Web</div>
                            <div class="date">{{ user.lastLogin }}</div>
                          </div>
                          <div>
                            <div class="successful">Successful</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3"></div>
                  </div>

                  <div class="col-md-8">
                    <div class="overview_border">
                      <div class="overview_header">
                        <font-awesome-icon icon="money-bill" />
                        <div class="profile_title">Accounts Details</div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <div class="dashboard-acc-bal">
                            <carousel-3d
                              :autoplay="true"
                              :autoplay-timeout="5000"
                              :controls-visible="true"
                              :space="440"
                            >
                              <slide
                                :index="index"
                                v-for="(
                                  account, index
                                ) in user.corporateAccounts"
                                :key="index"
                              >
                                <div class="account-balance1">
                                  <p>
                                    <b>{{ account.accountName }}</b
                                    ><br />
                                    <b
                                      >{{ account.accountType }} -
                                      {{ account.accountNumber }}</b
                                    >
                                  </p>
                                  <p>Account Balance:</p>
                                  <h3 v-if="account.balance != undefined">
                                    <span>&#8358;</span>
                                    {{ formatAmount(account.balance) }}
                                  </h3>
                                  <h3 v-else>Not Authorized</h3>
                                  <div
                                    v-if="account.ledgerBalance"
                                    class="book_balance"
                                  >
                                    Book Balance:
                                    {{ formatAmount(account.ledgerBalance) }}
                                  </div>
                                </div>
                                <img
                                  src="../../../../assets/images/hope-bg.jpg"
                                />
                              </slide>
                            </carousel-3d>
                            <controls
                              v-if="controlsVisible"
                              :next-html="controlsNextHtml"
                              :prev-html="controlsPrevHtml"
                              :width="controlsWidth"
                              :height="controlsHeight"
                            ></controls>
                            <!-- <div class="swipe-left-right">
                              <p>Swipe Image Left &amp; Right to view other accounts</p>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="account_details1-">
                            <div class="account_details1">
                              <div class="view_statement">
                                <router-link to="statementgenerate_">
                                  <button class="view_statement-btn">
                                    Generate Statement
                                  </button>
                                </router-link>
                              </div>
                              <div class="view_statement">
                                <router-link to="/BillPayment_">
                                  <button class="view_statement-btn">
                                    Make Payment
                                  </button>
                                </router-link>
                              </div>
                              <div class="view_statement">
                                <router-link to="transfer">
                                  <button class="view_statement-btn">
                                    Make Transfer
                                  </button>
                                </router-link>
                              </div>
                              <div class="view_statement">
                                <router-link to="History_">
                                  <button class="view_statement-btn">
                                    Transaction History
                                  </button>
                                </router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3"></div>

                    <div class="overview_border">
                      <div class="overview_header">
                        <font-awesome-icon icon="clock" />
                        <div class="profile_title">Transaction History</div>
                      </div>

                      <div class="login-history">
                        <div
                          class="login-history2"
                          v-if="transHistory[0]"
                          @click="transactionDetails(transHistory[0])"
                          style="cursor: pointer"
                        >
                          <div>
                            <div class="web">
                              &#8358; {{ formatAmount(transHistory[0].amount) }}
                            </div>
                          </div>
                          <div>
                            <div class="transfer">
                              {{ transHistory[0].transactionType }}
                            </div>
                            <div class="time">{{ transHistory[0].date }}</div>
                          </div>
                        </div>
                        <div
                          class="login-history2"
                          v-if="transHistory[1]"
                          @click="transactionDetails(transHistory[1])"
                          style="cursor: pointer"
                        >
                          <div>
                            <div class="web">
                              &#8358; {{ formatAmount(transHistory[1].amount) }}
                            </div>
                          </div>
                          <div>
                            <div class="successful">
                              {{ transHistory[1].transactionType }}
                            </div>
                            <div class="time">{{ transHistory[1].date }}</div>
                          </div>
                        </div>
                        <div
                          class="login-history2"
                          v-if="transHistory[2]"
                          @click="transactionDetails(transHistory[2])"
                          style="cursor: pointer"
                        >
                          <div>
                            <div class="web">
                              &#8358; {{ formatAmount(transHistory[2].amount) }}
                            </div>
                          </div>
                          <div>
                            <div class="transfer">
                              {{ transHistory[2].transactionType }}
                            </div>
                            <div class="time">{{ transHistory[2].date }}</div>
                          </div>
                        </div>
                        <div
                          class="login-history2"
                          v-if="transHistory[3]"
                          @click="transactionDetails(transHistory[3])"
                          style="cursor: pointer"
                        >
                          <div>
                            <div class="web">
                              &#8358; {{ formatAmount(transHistory[3].amount) }}
                            </div>
                          </div>
                          <div>
                            <div class="successful">
                              {{ transHistory[3].transactionType }}
                            </div>
                            <div class="time">{{ transHistory[3].date }}</div>
                          </div>
                        </div>
                        <div
                          class="login-history2"
                          v-if="transHistory[4]"
                          @click="transactionDetails(transHistory[4])"
                          style="cursor: pointer"
                        >
                          <div>
                            <div class="web">
                              &#8358; {{ formatAmount(transHistory[4].amount) }}
                            </div>
                          </div>
                          <div>
                            <div class="transfer">
                              {{ transHistory[4].transactionType }}
                            </div>
                            <div class="time">{{ transHistory[4].date }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>
      <b-modal id="modal-scrollable" scrollable title="Scrollable Content">
        <p class="my-4" v-for="i in 20" :key="i">
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import HeaderNavBizDashboard from "../../../../components/HeaderNav/HeaderNavBizDashboard";
import BusinessNavBar from "../../../../components/HeaderNav/BusinessNavBar";
import { mapGetters, mapState } from "vuex";
import { CorporateAccountService } from "../../../../services/CorporateAccount/corporateAccountTransaction.services.js";
import Loader from "../../../../components/Loader/Loader.vue";
import { userService } from "../../../../services/user.service";
// import {getInstitutionService} from '../../services/getInstitution.services.js';
import { Carousel3d, Slide } from "vue-carousel-3d";
import Controls from "../../../../components/Controls/Controls";
import Footer3 from "../../../../components/Footer/Footer3";

export default {
  components: {
    HeaderNavBizDashboard,
    BusinessNavBar,
    Loader,
    Carousel3d,
    Slide,
    Controls,
    Footer3,
  },
  data() {
    return {
      loading: false,
      defaultAccount: "",
      // transHistory: [],
      transHistories: [],
    };
  },

  computed: {
    ...mapGetters({ user: "GET_USER" }),
    ...mapState({
      BVN: (state) => state.User.BVN,
      AUTHENTICATION_TOKEN: (state) => state.User.AUTHENTICATION_TOKEN,
    }),
    transHistory: function () {
      if (this.defaultAccount === "") {
        return this.transHistories;
      }
      let history = this.transHistories.filter(
        (trans) => trans.account == this.defaultAccount
      );
      return history;
    },
    routeQuery() {
      return this.$route.query.path;
    },
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },
    transactionDetails(n) {
      // let transactions = this.transHistories[n];
      // window.console.log("this is the transaction issues", n);
      this.$store.commit("SET_TRANSACTION_DATA", n);
      this.$router.push("Receipt_");
    },
    async getTransactionHistory() {
      this.loading = true;
      await CorporateAccountService.transHistory()
        .then((res) => {
          window.console.log(res);
          this.loading = false;
          this.transHistories = res;
          // window.console.log("this is the trans history",res)
        })
        .catch((err) => {
          window.console.log(err);
          this.loading = false;
          this.$toastr.error(err.status, "Error!", { timeOut: 5000 });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    formatAmount(value) {
      let val = (value / 1).toFixed(2).replace(".", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    async setDefaultAccount() {
      this.loading = true;
      await userService
        .defaultCooperateAccount({
          Token: this.AUTHENTICATION_TOKEN,
          Account: this.defaultAccount,
        })
        .then((res) => {
          window.console.log(res);
          this.$toastr.success(res.status || "Default Account set Successful", {
            timeOut: 5000,
          });
          this.confirm_transaction_own = false;

          // this.$store.commit("SET_BILL_DATA",{})
        })
        .catch((error) => {
          this.$toastr.error(error.status, " Failed!", { timeOut: 5000 });
          // this.$store.commit("SET_BILL_DATA",{})
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // calculateTotal(){

    //     let amount = this.user.accounts[0].balance + this.user.accounts[1].balance + this.user.accounts[2].balance
    //     window.console.log("+++++++++",this.formatAmount(amount))
    //     let TotalAmount = amount
    //     return this.formatAmount(TotalAmount)

    // },

    props: {
      controlsVisible: {
        type: Boolean,
        default: true,
      },
      controlsPrevHtml: {
        type: String,
        default: "&lsaquo;",
      },
      controlsNextHtml: {
        type: String,
        default: "&rsaquo;",
      },
      controlsWidth: {
        type: [String, Number],
        default: 50,
      },
      controlsHeight: {
        type: [String, Number],
        default: 50,
      },
    },
  },
  mounted() {
    if (this.routeQuery == "business") return this.$router.push("/business_");
    // if (this.routeQuery == "register") return this.$router.push("/register_");
    this.getTransactionHistory();
    this.$store.commit("SET_LOGGED_IN_BUSINESS", true);
    // this.getInstitution();
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000 !important;
  display: flex;
  align-items: center;
  margin: 0 1rem;
  font-family: Montserrat-Bold;
  font-size: 12px;
  letter-spacing: 0.17px;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
.savings_currents {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: 0 2px 2px 0 rgba(0,0,0,0.10); */
}
.savings-current {
  font-family: Montserrat-Medium;
  font-size: 15px;
  color: #4a4a4a;
  letter-spacing: 0.18px;
  margin-bottom: 0;
}
.savings-current-amount {
  font-family: Montserrat-Medium;
  font-size: 18px;
  font-weight: 700;
  color: #4a4a4a;
  letter-spacing: 0.18px;
  margin-bottom: 0;
}
.terms_button {
  border: 1px solid #000 !important;
  color: #000 !important;
}
/* .poster {
  background-color: rgb(0, 94, 194, 0.1) !important;
}
.overview_header {
  background: #005ec2;
  background: #0091ff;
  background-image: linear-gradient(180deg, #00c4ff 0%, #0091ff 100%);
  color: #ffffff;
} */
/* .overview_border {
  border: 1px solid #0091ff;
}
.overview_border2 {
  border: 1px solid #0091ff;
} */
/* .profile_title {
  color: #ffffff;
} */
/* .view_statement-btn {
  background-image: linear-gradient(180deg, #00c4ff 0%, #0091ff 100%);
  border: 1px solid #0091ff !important;
  color: #ffffff !important;
} */
/* .quick-transfer a {
  color: #ffffff !important;
}
.account-balance1 p,
.account-balance1 h3 {
  color: #ffffff;
}
.book_balance {
  color: #ffffff;
} */
/* .profile-image img {
  border: 6px solid rgb(0, 94, 194, 0.5) !important;
} */
</style>

<style lang="scss">
.dropdown {
  &__header {
    cursor: pointer;
    /*line-height: 50px;*/
    /*padding-left: 10px;*/
    /*padding-right: 50px;*/
    /*position: relative;*/
    /*text-overflow: ellipsis;*/
    i.fa {
      position: absolute;
      /*right: 10px;*/
      top: 50%;
      width: 50px;

      transition: opacity 0.3s;
      &.fa-angle-up {
        opacity: 0;
      }
    }
    &.is-active {
      i.fa {
        &.fa-angle-up {
          opacity: 1;
        }
        &.fa-angle-down {
          opacity: 0;
        }
      }
      + .dropdown__content {
        height: auto;
        opacity: 1;
        visibility: visible;
        position: absolute;
        z-index: 5;
        background: #ffffff;
        width: 230px;
      }
    }
  }
  &__content {
    height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 15px 10px;
    transition: opacity 0.3s;
    visibility: hidden;
  }
}
</style>