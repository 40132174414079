const functions = {
    /**
     * @param x the number to be converted
     * @return returns the number converted to two decimal places.
     */
    AmountToFixed: (x) => {
        return Number.parseFloat(x).toFixed(2);
    },

    // Returns true if all chars are vowel

    checkVowel: (str) => {
        if (!str) {
            return;
        }
        const formattedStr = str.toLowerCase();
        let count = 0;

        for (let i = 0; i < formattedStr.length; i++) {
            window.console.log(formattedStr.charAt(i));
            if (
                formattedStr.charAt(i) === 'a' ||
                formattedStr.charAt(i) === 'e' ||
                formattedStr.charAt(i) === 'i' ||
                formattedStr.charAt(i) === 'o' ||
                formattedStr.charAt(i) === 'u'
            ) {
                count = count + 1;
            }
            // else {
            //     count = count;
            // }
        }

        if (count === str.length) {
            return true;
        }
        else {
            return false;
        }
    },


    // Returns true if all chars are consonants
    checkConsonants: (str) => {
        if (!str) {
            return;
        }
        const formattedStr = str.toLowerCase();
        let count = 0;

        for (let i = 0; i < formattedStr.length; i++) {
            window.console.log(formattedStr.charAt(i));
            if (
                formattedStr.charAt(i) === 'a' ||
                formattedStr.charAt(i) === 'e' ||
                formattedStr.charAt(i) === 'i' ||
                formattedStr.charAt(i) === 'o' ||
                formattedStr.charAt(i) === 'u'
            ) {
                count = count + 1;
            }
            // else {
            //     count = count;
            // }
        }

        if (count === 0) {
            return true;
        } else {
            return false;
        }
    }


    // console.log(checkConsonants('ccoc'))


}

export default functions