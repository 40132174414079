import { ApiService } from "./api.service";


const TransactionHistoryService = {

    transHistory: async () => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'POST',
                url: ApiService.getBaseUrl() + "/api/web/transaction/history",
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/transaction/history', localStorage.getItem('licence'))
                },
                redirect: 'follow'
            })
                .then((res) => {
                    let data = res.data
                    resolve(data);
                })
                .catch(err => {
                    reject(err.response.data)
                })


        });

    },
    SearchtransHistory: async (dataObj) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'POST',
                url: ApiService.getBaseUrl() + "/api/web/transaction/history",
                data: {
                    start: dataObj.start,
                    end: dataObj.end,
                    account: dataObj.account
                },
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/transaction/history', localStorage.getItem('licence'))
                },
                redirect: 'follow'
            })
                .then((res) => {
                    let data = res.data
                    resolve(data);
                })
                .catch(err => {
                    reject(err.response.data)
                })


        });

    },
    bankStatement: async (dataObj) => {
        return new Promise((resolve, reject) => {
            ApiService.customRequest({
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/transaction/statement",
                data: {
                    email: dataObj.email,
                    start: dataObj.start,
                    end: dataObj.end,
                    account: dataObj.account,
                    // type: dataObj.type,
                },
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/transaction/statement', localStorage.getItem('licence'))
                },
            }).then((res) => {
                let data = res.data
                resolve(data)
            }).catch(err => {
                reject(err.response.data)
            })
        })

    }

};



export {
    TransactionHistoryService
};
