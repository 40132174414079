import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";


import "../src/utils/fontawesome";
import "../src/utils/bootstrap";
import "../src/utils/sweetalert2";
import "../src/utils/scrollto";
// import "../src/utils/lga";

import { ApiService } from "./services/api.service";
import "vue-toastr-2/dist/vue-toastr-2.min.css";
import VModal from "vue-js-modal";
import VueToastr2 from "vue-toastr-2";
//new additions
import "./vee-validate";
import VueTheMask from "vue-the-mask";
import Vue2Filters from "vue2-filters";
import DatePicker from 'vue2-datepicker';
import './styles/index.css'

window.toastr = require("toastr");

Vue.use(VueTheMask);
Vue.component('DatePicker', DatePicker)

// import { Tabs, Tab } from "vue-tabs-component";

// Vue.component("tabs", Tabs);
// Vue.component("tab", Tab);

import { VueFlux, FluxPagination, Transitions } from "vue-flux";

import EasySlider from "vue-easy-slider";

Vue.use(EasySlider);

import Datetime from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";
import VueNumeric from "vue-numeric";



//import {TheMask} from 'vue-the-mask'

Vue.use(Datetime);

import { BadgerAccordion, BadgerAccordionItem } from "vue-badger-accordion";

import vueCountryRegionSelect from "vue-country-region-select";
Vue.use(vueCountryRegionSelect);

import { mixin as clickaway } from "vue-clickaway";

import BackToTop from "vue-backtotop";

Vue.use(BackToTop);

//ApiService.init("https://test.ibank.hopepsbank.com/");
//ApiService.init("https://ibank.hopepsbank.com");
ApiService.init("https://ibank.hopepsbank.com/");


if (store.dispatch("isLoggedIn"))
    ApiService.setHeader(store.getters.GET_AUTH_TOKEN);
else store.dispatch("UNSET_USER").then(() => { });

(Vue.config.productionTip = false),
    Vue.use(VModal, { dialog: true, dynamic: true, injectModalsContainer: true });
Vue.use(VueToastr2);
Vue.use(Vue2Filters);

import vSelect from "vue-select";

Vue.component("v-select", vSelect);

// import ImagePicker from 'vue-image-picker'

import VueImageChooser from "vue-image-chooser";

Vue.use(VueImageChooser);

import * as VueGoogleMaps from "vue2-google-maps";

import Carousel3d from "vue-carousel-3d";
Vue.use(Carousel3d);

// const pluginOptions = {
//   /* see config reference */
//   globalOptions: { currency: 'NGN' }
// }
import VueCurrencyInput from 'vue-currency-input'
Vue.use(VueCurrencyInput,)


import VuePageTransition from "vue-page-transition";

Vue.use(VuePageTransition);

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

import Multiselect from 'vue-multiselect'
// register globally
Vue.component('multiselect', Multiselect)

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyBUIH1yezrEvHBG-e_f2XGt-LyNruW28HA",
        libraries: "places", // necessary for places input
        region: "NG",
        language: "eng",
    },
});

new Vue({
    created() {
        AOS.init();
    },
    router,
    // Tabs,
    // Tab,
    VueFlux,
    VueNumeric,
    FluxPagination,
    VueCurrencyInput,
    Transitions,
    BadgerAccordion,
    BadgerAccordionItem,
    clickaway,
    store,
    // 'image-picker': ImagePicker,
    render: (h) => h(App),
}).$mount("#app");

// module.exports = {
//     // other config
//     productionSourceMap: false
// };