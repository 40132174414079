<template>
  <div class="poster-bg">
    <div class="poster"></div>
    <div class="dashboard-page registration-page">
      <Loader v-if="loading" :show-full="true" loading-text="Processing" />

      <div class>
        <HeaderNavBiz2 />
        <div class="register_now">
          <div class="container">
            <div class="text-left">
              <h6 class="welcome_username">
                Hi, {{ user.accounts[0].accountName }}
              </h6>
            </div>
            <div class="main_dashboard">
              <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                  <div class="main_dashboard_inner">
                    <div class="transfer_money2 text-center">
                      <p class="frequently-used text-center">Open Account</p>
                    </div>

                    <div class="current_tab_title mb-3">
                      <font-awesome-icon icon="play" class="play-icon" />
                      <p class>
                        All the information below are mandatory to create your
                        account
                      </p>
                    </div>

                    <div class="steps_to_acc_open">
                      <h2>
                        Enter Details -
                        <span class="h5">Hope Salary Current</span>
                      </h2>
                    </div>

                    <form @submit.prevent="CreateAccount">
                      <div class="form-table2">
                        <div class="upload_profile_photo">
                          <div class="profile_photo">
                            <img
                              src="../../assets/images/upload.svg"
                              alt="Hope Bank"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="label-title2">
                        <label
                          >First Name <span class="red-star">*</span></label
                        >
                        <div class="account_select2">
                          <input
                            v-model="FirstName"
                            type="text"
                            class="input-amount"
                            placeholder=" "
                          />
                        </div>
                      </div>
                      <div class="label-title2">
                        <label>Other Names</label>
                        <div class="account_select2">
                          <input
                            v-model="MiddleName"
                            type="text"
                            class="input-amount"
                            placeholder=" "
                          />
                        </div>
                      </div>
                      <div class="label-title2">
                        <label>Last Name <span class="red-star">*</span></label>
                        <div class="account_select2">
                          <input
                            v-model="LastName"
                            type="text"
                            class="input-amount"
                            placeholder=" "
                          />
                        </div>
                      </div>
                      <div class="label-title2">
                        <label
                          >Phone Number <span class="red-star">*</span></label
                        >
                        <div class="account_select2">
                          <input
                            v-model="Phone"
                            type="tel"
                            class="input-amount"
                            placeholder=" "
                          />
                        </div>
                      </div>
                      <div class="label-title2">
                        <label
                          >Email Address <span class="red-star">*</span></label
                        >
                        <div class="account_select2">
                          <input
                            v-model="Email"
                            type="email"
                            class="input-amount"
                            placeholder=" "
                          />
                        </div>
                      </div>
                      <div class="label-title2">
                        <label
                          >Date of Birth  
                          <span class="red-star">*</span></label
                        >
                        <DatePicker v-model="DateOfBirth" :disabled-date="disabledVer" valueType="format" format="DD-MM-YYYY" placeholder="DD-MM-YYYY"></DatePicker>
                      </div>

                      <div class="label-title">
                        <label>Gender</label>
                        <div class="account_select">
                          <select v-model="Gender" class="account_type">
                            <option value="Male" class="choose_account"
                              >Male</option
                            >
                            <option value="Female" class="choose_account"
                              >Female</option
                            >
                          </select>
                          <font-awesome-icon icon="caret-down" class />
                        </div>
                      </div>
                      <!-- Next of kin  details -->
                      <div class="non_nigerian_form">
                        <h3>Next of Kin Details</h3>
                        <div class="label-title2">
                          <label>Name</label>
                          <div class="account_select2">
                            <input
                              type="text"
                              class="input-amount"
                              v-model="NkName"
                              placeholder=" "
                            />
                          </div>
                        </div>

                        <div class="label-title2">
                          <label>Phone</label>
                          <div class="account_select2">
                            <input
                              type="tel"
                              class="input-amount"
                              v-model="NkPhone"
                              placeholder=" "
                              maxlength="11"
                            />
                          </div>
                        </div>

                        <div class="label-title2">
                          <label>Relationship</label>
                          <div class="account_select2">
                            <input
                              type="text"
                              class="input-amount"
                              v-model="NkRelationship"
                              placeholder=" "
                            />
                          </div>
                        </div>

                        <div class="label-title2">
                          <label>Address</label>
                          <div class="account_select2">
                            <input
                              type="text"
                              class="input-amount"
                              v-model="NkAddress"
                              placeholder=" "
                            />
                          </div>
                        </div>
                      </div>
                      <!-- Non Nigerian details -->
                      <div class="non_nigerian_form">
                        <h3>Further Details</h3>
                        <div class="label-title2">
                          <label>Tax Identity Number</label>
                          <div class="account_select2">
                            <input
                              type="text"
                              v-model="TIN"
                              class="input-amount"
                              placeholder=" "
                            />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>National Identity Number</label>
                          <div class="account_select2">
                            <input
                              type="text"
                              v-model="NIN"
                              class="input-amount"
                              placeholder=" "
                            />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Occupation</label>
                          <div class="account_select">
                            <select v-model="Occupation" class="account_type">
                              <option
                                :value="profession.value"
                                v-for="(profession, index) in professions"
                                :key="index"
                                class="choose_account"
                                >{{ profession.label }}</option
                              >
                            </select>
                            <font-awesome-icon icon="caret-down" class />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Mother's Maiden Name</label>
                          <div class="account_select2">
                            <input
                              type="text"
                              v-model="MaidenName"
                              class="input-amount"
                              placeholder=" "
                            />
                          </div>
                        </div>

                        <div class="label-title2">
                          <label>Nationality</label>
                          <div class="account_select">
                            <select v-model="Nationality" class="account_type">
                              <option
                                v-for="(items, key) in countries"
                                :key="key"
                                :value="items.value"
                                class="choose_account"
                                >{{ items.label }}</option
                              >
                            </select>
                            <font-awesome-icon icon="caret-down" class />
                          </div>
                        </div>

                        <div class="non_nigerian_form">
                          <h3>Address - Residential</h3>
                          <div class="label-title2">
                            <label>Street Address</label>
                            <div class="account_select2">
                              <input
                                type="text"
                                v-model="ResAddress"
                                class="input-amount"
                                placeholder=" "
                              />
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>Town/City</label>
                            <div class="account_select2">
                              <input
                                type="text"
                                v-model="ResTown"
                                class="input-amount"
                                placeholder=" "
                              />
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>ZIP/Postal Code</label>
                            <div class="account_select2">
                              <input
                                type="tel"
                                v-model="ZipCode"
                                class="input-amount"
                                placeholder=" "
                              />
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>Residence Country</label>
                            <div class="account_select">
                              <select v-model="ResCountry" class="account_type">
                                <option
                                  v-for="(items, key) in countries"
                                  :key="key"
                                  :value="items.value"
                                  class="choose_account"
                                  >{{ items.label }}</option
                                >
                              </select>
                              <font-awesome-icon icon="caret-down" class />
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>State <span class="red-star">*</span></label>
                            <div class="account_select">
                              <select v-model="ResState" class="account_type">
                                <option>Select State</option>
                                <option
                                  v-for="(items, key) in states"
                                  :key="key"
                                  :value="items.value"
                                  class="choose_account"
                                  >{{ items.label }}</option
                                >
                              </select>
                              <font-awesome-icon icon="caret-down" class />
                            </div>
                          </div>
                        </div>

                        <div class="non_nigerian_form">
                          <h3>Business/Office/School Address</h3>
                          <div class="label-title2">
                            <label>Address</label>
                            <div class="account_select2">
                              <input
                                type="text"
                                v-model="BizAddress"
                                class="input-amount"
                                placeholder=" "
                              />
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>Country</label>
                            <div class="account_select">
                              <select v-model="BizCountry" class="account_type">
                                <option
                                  v-for="(items, key) in countries"
                                  :key="key"
                                  :value="items.value"
                                  class="choose_account"
                                  >{{ items.label }}</option
                                >
                              </select>
                              <font-awesome-icon icon="caret-down" class />
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>State <span class="red-star">*</span></label>
                            <div class="account_select">
                              <select v-model="BizState" class="account_type">
                                <option>Select State</option>
                                <option
                                  v-for="(items, key) in states"
                                  :key="key"
                                  :value="items.value"
                                  class="choose_account"
                                  >{{ items.label }}</option
                                >
                              </select>
                              <font-awesome-icon icon="caret-down" class />
                            </div>
                          </div>

                          <div class="label-title2">
                            <label>LGA</label>
                            <div class="account_select2">
                              <input
                                type="text"
                                v-model="BizLGA"
                                class="input-amount"
                                placeholder=" "
                              />
                            </div>
                          </div>
                        </div>
                        <!-- Referral -->
                        <div class="non_nigerian_form">
                          <div class="label-title2">
                            <div class="">
                            <label>
                              Referral Code or Phone No. <span>[Optional]</span>
                            </label>
                            <p>Who introduced you to Hope?</p>
                            </div>
                            <div class="account_select2">
                              <input
                                type="text"
                                maxlength="11"
                                class="input-amount"
                                placeholder=" "
                              />
                            </div>
                          </div>
                        </div>
                          <!-- Referral -->
                      </div>

                      <!-- <div class="non_nigerian_form">
                        <h3>Non-Nigerians</h3>
                        <div class="label-title2">
                          <label>Resident Permit No. 1</label>
                          <div class="account_select2">
                            <input type="text" class="input-amount" placeholder=" " />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Resident Permit Date</label>
                          <div class="account_select2">
                            <input type="text" class="input-amount" placeholder=" " />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Resident Expiry</label>
                          <div class="account_select2">
                            <input type="text" class="input-amount" placeholder=" " />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Employers Name/Business</label>
                          <div class="account_select2">
                            <input type="text" class="input-amount" placeholder=" " />
                          </div>
                        </div>
                      </div>-->

                      <!-- <div class="non_nigerian_form">
                        <h3>Uploads</h3>
                        <div class="label-title2">
                          <label
                            >Letter of Intro from Company
                            <span class="red-star">*</span></label
                          >
                          <div class="account_select2">
                            <vuebase64
                              @size-exceeded="onSizeExceeded"
                              @load="CompanyIntro = $event"
                            />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label
                            >Company ID Card
                            <span class="red-star">*</span></label
                          >
                          <div class="account_select2">
                            <vuebase64
                              @size-exceeded="onSizeExceeded"
                              @load="CompanyId = $event"
                            />
                          </div>
                        </div>
                      </div> -->

                      <div class="non_nigerian_form">
                        <div class="term_condition_check">
                          <input
                            type="checkbox"
                            id="terms"
                            v-model="terms"
                            name="terms"
                            value="Terms"
                          />
                          <label for="terms">
                            I accept the
                            <b-button
                              v-b-modal.modal-scrollable-terms
                              class="terms_button"
                              >Terms and Conditions</b-button
                            >
                          </label>
                        </div>
                        <terms />

                        <div class="kindly_enter_details mb-3">
                          <font-awesome-icon
                            icon="exclamation-circle"
                            class="exclamation-icon"
                          />
                          <p>Account Number will be sent to your email.</p>
                        </div>
                      </div>
                      <div class="send-or-download2">
                        <div class="continue-button">
                          <a @click="$router.go(-1)">
                            <div class="continue-btn">
                              <font-awesome-icon
                                icon="angle-left"
                                class="download-icon"
                              />BACK
                            </div>
                          </a>
                        </div>
                        <div class="continue-button">
                          <b-button
                            type="submit"
                            :disabled="isDisabled"
                            class="continue-btn"
                          >
                            Submit
                            <font-awesome-icon
                              icon="angle-right"
                              class="angle-icon"
                            />
                          </b-button>
                          <!-- <router-link to="Complete"><button class="continue-btn">Create Account <font-awesome-icon icon="angle-right" class="angle-icon"/></button></router-link> -->
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </div>
  </div>
</template>

<script>
import HeaderNavBiz2 from "../../components/HeaderNav/HeaderNavBiz2";
// import {TheMask} from 'vue-the-mask';
import { getCountryService } from "../../services/getCountries.services.js";
import { getStateService } from "../../services/getState.services.js";
import { getTitlesService } from "../../services/getTitles.services.js";
import { CorporateAccountService } from "../../services/CorporateAccount/corporateAccountCreation.services.js";
import { OccupationService } from "../../services/getOccupations.js";
import Loader from "../../components/Loader/Loader.vue";
import { mapState, mapGetters } from "vuex";
import terms from "../../components/Terms/terms";
// import vuebase64 from "../../components/vue-base64/vuebase64.vue";
import Footer2 from "../../components/Footer/Footer2";

export default {
  name: "Verify_",
  components: {
    HeaderNavBiz2,
    Loader,
    terms,
    // vuebase64,
    Footer2,
    // TheMask,
  },
  data() {
    return {
      image: "",
      isPinMatch: false,
      //pinMessage:false,
      pin_keyboard1: true,
      pin_keyboard: false,
      professions: [],
      pin_modal: false,
      terms: false,
      isDisabled: true,
      country: null,
      region: null,
      titles: [],
      countries: [],
      states: [],
      Nationality: "",
      Email: "",
      loading: false,
      pin: "",
      pin1: "",
      NkName: "",
      NkRelationship: "",
      NkPhone: "",
      NkAddress: "",
      ResState: "",
      ResTown: "",
      ResCountry: "",
      BizAddress: "",
      BizCountry: "",
      BizState: "",
      BizLGA: "",
      TIN: "",
      NIN: "",
      Occupation: "",
      MaidenName: "",
      ResAddress: "",
      ZipCode: "",
      CompanyIntro: "",
      CompanyId: "",
      Code: "104",
      success_modal: false,
      responseData: {},
    };
  },

  watch: {
    pin(newval) {
      if (newval.length >= 6) {
        this.pinMessage = false;
        if (newval != this.pin1) {
          this.isPinMatch = true;
          // this.isDisabled = true;
          return;
        }
        this.isPinMatch = false;
        //this.isDisabled = false;
        return;
      }
      //this.isDisabled = true;
    },
    terms(newval) {
      if (newval) return (this.isDisabled = false);
      this.isDisabled = true;
    },
    DateOfBirth(newval) {
      this.checkDateValidity(newval);
    },
  },

  computed: {
    ...mapGetters({ user: "GET_USER" }),
    ...mapState({
      token: (state) => state.User.AUTHENTICATION_TOKEN,
      ACCOUNT_ID: (state) => state.CreateAccount.ACCOUNT_ID,
      ACCOUNT_NUMBER: (state) => state.CreateAccount.ACCOUNT_NUMBER,
      ACCOUNT_PIN: (state) => state.CreateAccount.ACCOUNT_PIN,
      BVN_DETAILS: (state) => state.CreateAccount.BVN_DETAILS,
    }),

    Corporate() {
      let account = this.user.corporateAccounts;
      return account[
        account.findIndex((a) => a.accountNumber == this.ACCOUNT_NUMBER)
      ].customerId;
    },

    FirstName: {
      get() {
        return this.BVN_DETAILS.firstName;
      },
      set(value) {
        this.BVN_DETAILS.firstName = value;
      },
    },
    LastName: {
      get() {
        return this.BVN_DETAILS.lastName;
      },
      set(value) {
        this.BVN_DETAILS.lastName = value;
      },
    },
    MiddleName: {
      get() {
        return this.BVN_DETAILS.middleName;
      },
      set(value) {
        this.BVN_DETAILS.middleName = value;
      },
    },
    Phone: {
      get() {
        return this.BVN_DETAILS.phone;
      },
      set(value) {
        this.BVN_DETAILS.phone = value;
      },
    },
    DateOfBirth: {
      get() {
        return this.BVN_DETAILS.DateOfBirth;
      },
      set(value) {
        this.BVN_DETAILS.DateOfBirth = value;
      },
    },
    Gender: {
      get() {
        return this.BVN_DETAILS.gender;
      },
      set(value) {
        this.BVN_DETAILS.gender = value;
      },
    },
  },

  methods: {
    disabledVer(date) {
      let tdate = new Date();
      let year = tdate.getFullYear();
      let month = tdate.getMonth();
      let day = tdate.getDate();
      const today = new Date(year - 18, month, day);
      today.setHours(0, 0, 0, 0);

      return date > today
    },
    //Check Date Validity
    checkDateValidity(date) {
      let tdate = new Date();
      let year = tdate.getFullYear();
      let month = tdate.getMonth();
      let day = tdate.getDate();
      let newYear = new Date(year - 18, month, day);
      let dob = new Date(date);

      if (dob > newYear) {
        this.DateOfBirth = "";
        return this.$toastr.error("Must be 18 years or older", "Error");
      }

      // alert("okay to proceed")
    },
    CreateAccount() {
      this.loading = true;
      let data = {
        Id: this.ACCOUNT_ID,
        token: this.token,
        Corporate: this.Corporate,
        Account: this.ACCOUNT_NUMBER,
        OTP: this.ACCOUNT_OTP,
        Pin: this.pin,
        Code: this.Code,
        Product: this.Code,
        Nationality: this.Nationality,
        Email: this.Email,
        DateOfBirth: this.DateOfBirth,
        FirstName: this.FirstName,
        LastName: this.LastName,
        MiddleName: this.MiddleName,
        Gender: this.Gender,
        NkName: this.NkName,
        NkRelationship: this.NkRelationship,
        NkPhone: this.NkPhone,
        NkAddress: this.NkAddress,
        ResState: this.ResState,
        ResTown: this.ResTown,
        ResAddress: this.ResAddress,
        ResCountry: this.ResCountry,
        BizAddress: this.BizAddress,
        BizCountry: this.BizCountry,
        BizState: this.BizState,
        BizLGA: this.BizLGA,
        TIN: this.TIN,
        NIN: this.NIN,
        Occupation: this.Occupation,
        MaidenName: this.MaidenName,
        ZipCode: this.ZipCode,
        CompanyIntro: this.CompanyIntro,
        CompanyId: this.CompanyId,
      };

      CorporateAccountService.CreateAccount(data)
        .then((res) => {
          this.$toastr.success(res.status, "Account Successfully created", {
            timeOut: 5000,
          });
          this.$router.push("/Dashboard_");
        })
        .catch((error) => {
          this.$toastr.error(error.status, "Account Creation Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSizeExceeded(size) {
      alert(`Image ${size}Mb size exceeds limits of 1Mb!`);
    },
    async getTitle() {
      await getTitlesService
        .getTitle()
        .then((res) => {
          this.loading = false;
          this.titles = res;
        })
        .catch((err) => window.console.log(err));
    },

    async getCountries() {
      await getCountryService
        .getCountryWhenLoggedIn()
        .then((res) => {
          this.countries = res;
        })
        .catch((err) => window.console.log(err));
    },

    async getState() {
      await getStateService
        .getStateWhenLoggedIn()
        .then((res) => {
          this.states = res;
        })
        .catch((err) => window.console.log(err));
    },

    getOccupations() {
      OccupationService.occupationServiceWhenLoggedIn()
        .then((res) => {
          this.loading = false;
          this.professions = res;
        })
        .catch((err) => window.console.log(err));
    },
  },
  mounted() {
    //this.getTitle();
    this.getCountries();
    this.getState();
    this.getOccupations();
  },
};
</script>

<style scoped>
.img-fluid {
  height: 100px !important;
  width: 100px !important;
}
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.quick-transfer {
  padding: 0 2.2rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
}
.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
}
.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}
.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}
.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}

.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #ffffff !important;
}
.yellow_bg > .navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}
.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}
.poster-bg {
  height: auto !important;
  background: none !important;
}
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
