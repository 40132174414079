import { render, staticRenderFns } from "./receiptvas.vue?vue&type=template&id=b91e57c4&scoped=true&"
import script from "./receiptvas.vue?vue&type=script&lang=js&"
export * from "./receiptvas.vue?vue&type=script&lang=js&"
import style0 from "./receiptvas.vue?vue&type=style&index=0&id=b91e57c4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b91e57c4",
  null
  
)

export default component.exports