<template>
  <div class="transaction-receipt-page">
    <div class="container">
      <div class="row">
        <div class="col-md-7 ml-auto mr-auto">
          <div ref="html">
            <div class="hope-logo mt-5 text-center">
              <router-link to="/">
                <img
                  src="../../../../assets/images/logo/hope-bank-payment-service-logo.svg"
                  alt="Hope Bank Logo"
                  width="110px"
                />
              </router-link>
            </div>
            <div class="d text-center">
              <h1>Transaction Receipt</h1>
              <h2>Successful Transaction</h2>
            </div>
            <div class="line5"></div>
            <div class="receipt-info">
              <table>
                <tr>
                  <td>Account Name:</td>
                  <td>{{ transferedAccountName() }}</td>
                </tr>
                <tr>
                  <td>Account Number:</td>
                  <td v-if="TRANSACTION_DETAILS.from">
                    {{ hashAccount(TRANSACTION_DETAILS.from) }}
                  </td>
                  <td v-if="TRANSACTION_DETAILS.account">
                    {{ TRANSACTION_DETAILS.account }}
                  </td>
                </tr>
                <tr>
                  <td>Description:</td>
                  <td v-if="TRANSACTION_DETAILS.description">
                    {{ hashAccount(TRANSACTION_DETAILS.description) }}
                  </td>
                  <!-- <td v-if="TRANSACTION_DETAILS.description">
                    {{ TRANSACTION_DETAILS.description }}
                  </td> -->
                </tr>
                <tr v-if="TRANSACTION_DETAILS['beneficiary Name']">
                  <td>Beneficiary Name:</td>
                  <td v-if="TRANSACTION_DETAILS['beneficiary Name']">
                    {{ TRANSACTION_DETAILS["beneficiary Name"] }}
                  </td>
                </tr>
                <tr>
                  <td
                    v-if="
                      TRANSACTION_DETAILS.beneficiary || TRANSACTION_DETAILS.to
                    "
                  >
                    Beneficiary Details:
                  </td>
                  <td v-if="TRANSACTION_DETAILS.to">
                    {{ USER.accounts[0].accountName }} -
                    {{ TRANSACTION_DETAILS.account }} - Hope Bank
                  </td>
                  <td
                    v-if="
                      TRANSACTION_DETAILS.beneficiary &&
                      !TRANSACTION_DETAILS['destination Bank']
                    "
                  >
                    {{ TRANSACTION_DETAILS.beneficiary }} - Hope Bank
                  </td>
                  <td
                    v-if="
                      TRANSACTION_DETAILS.beneficiary &&
                      TRANSACTION_DETAILS['destination Bank']
                    "
                  >
                    {{ TRANSACTION_DETAILS.beneficiary }} -
                    {{ TRANSACTION_DETAILS["destination Bank"] }}
                  </td>
                </tr>
                <tr>
                  <td>Transaction Amount:</td>
                  <td>
                    &#8358; {{ formatAmount(TRANSACTION_DETAILS.amount) }}
                  </td>
                </tr>
                <tr>
                  <td>Transaction Type:</td>
                  <td>{{ TRANSACTION_DETAILS["transactionType"] }}</td>
                </tr>
                <tr>
                  <td>Transaction Ref:</td>
                  <td>
                    {{ TRANSACTION_DETAILS.data["reference Number"] }}
                  </td>
                </tr>
                <tr>
                  <td>Beneficiary Account:</td>
                  <td>
                    {{ TRANSACTION_DETAILS.data["beneficiary"] }}
                  </td>
                </tr>
                <tr>
                  <td>Beneficiary Name:</td>
                  <td>
                    {{ TRANSACTION_DETAILS.data["beneficiary Name"] }}
                  </td>
                </tr>
                <tr>
                  <td>Transaction Date:</td>
                  <td>{{ TRANSACTION_DETAILS.date }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="send-or-download">
            <button class="download-btn2">
              <font-awesome-icon icon="envelope" class="download-icon" />Send to
              Email
            </button>
            <button class="download-btn2" @click="exportToPDF">
              <font-awesome-icon
                icon="download"
                class="download-icon"
              />Download
            </button>
          </div>
          <div class="send-or-download" v-if="post.name == 'Dashboard_'">
            <router-link to="Dashboard_"
              ><button class="download-btn2">Go Back</button>
            </router-link>
          </div>
          <div class="send-or-download" v-if="post.name == 'history_'">
            <router-link to="history_"
              ><button class="download-btn2">Go Back</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import html2pdf from "html2pdf.js";

export default {
  name: "Receipt.vue",
  computed: {
    ...mapGetters({
      TRANSACTION_DETAILS: "GET_TRANSACTION",
      USER: "GET_USER",
    }),
  },
  data() {
    return {
      post: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      window.console.log("callback:", vm); // VueComponent ...
      vm.post = from;
    });
  },

  methods: {
    hashAccount(string) {
      let slicedFront = string.slice(0, 3);
      let slicedBack = string.slice(7);
      return `${slicedFront}****${slicedBack}`;
    },
    formatAmount(value) {
      let val = (value / 1).toFixed(2).replace(".", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    transferedAccountName() {
      const account = this.USER.corporateAccounts.filter((acct) => {
        return acct.accountNumber === this.TRANSACTION_DETAILS.account;
      });
      return account[0].accountName;
    },

    //download html as pdf
    exportToPDF() {
      html2pdf(this.$refs.html, {
        margin: 0,
        compress: true,
        filename: "receipt.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 392, letterRendering: true },
        jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
      });
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}
.transaction-receipt-page {
  width: 100%;
  height: 100vh;
  background: #f1f1f1;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 1rem 0;
}
.transaction-receipt-page h1 {
  font-family: Montserrat-Regular;
  font-size: 24px;
  color: #000000;
  letter-spacing: 0.44px;
  padding: 0.5rem 0;
}
.transaction-receipt-page h2 {
  font-family: Montserrat-Bold;
  font-size: 24px;
  color: #16a085;
  letter-spacing: 0.44px;
}
td:nth-child(odd) {
  text-align: right;
  font-family: Montserrat-Medium;
  font-size: 15px;
  color: #000000;
  letter-spacing: 0.3px;
  width: 50%;
}
td:nth-child(even) {
  text-align: left;
  font-family: Montserrat-Regular;
  font-size: 15px;
  color: #4a4a4a;
  letter-spacing: 0.3px;
}
tr:nth-child(odd) {
  background-color: transparent;
}
tr:nth-child(even) {
  background-color: transparent;
}
.send-or-download {
  display: flex;
  justify-content: center;
}
.send-or-download button {
  margin: 0.5rem;
}
.download-btn2 {
  border: 1px solid #fecb32 !important;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  /* .transaction-receipt-page {
  } */
  .transaction-receipt-page img {
    width: 20%;
  }
  .transaction-receipt-page h1 {
    font-family: Montserrat-Regular;
    font-size: 17px;
    color: #000000;
    letter-spacing: 0.44px;
    padding: 0.5rem 0;
    margin-bottom: 0;
  }
  .transaction-receipt-page h2 {
    font-family: Montserrat-Bold;
    font-size: 17px;
    color: #16a085;
    letter-spacing: 0.44px;
    margin-bottom: 0;
  }
  td:nth-child(odd) {
    text-align: right;
    font-family: Montserrat-Medium;
    font-size: 12px;
    color: #000000;
    letter-spacing: 0.3px;
    line-height: 20px;
    width: 50%;
  }
  td:nth-child(even) {
    text-align: left;
    font-family: Montserrat-Regular;
    font-size: 12px;
    color: #4a4a4a;
    letter-spacing: 0.3px;
    line-height: 20px;
  }
}
</style>
