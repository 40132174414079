import {
    ApiService
} from "./api.service";

const getCountryService = {
    getCountry: async () => {
        return new Promise(function (resolve, reject) {
            ApiService.get('/api/web/catalog/countries').then((res) => {
                let data = res.data;
                resolve(data);
            }).catch((error) => {
                reject(error.response.data);
            });
        });
        
    },

      //Get Countries  when logged in
      getCountryWhenLoggedIn: async () => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'GET',
                url: ApiService.getBaseUrl() + "/api/web/catalog/countries",
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/catalog/countries', localStorage.getItem('licence'))
                },
            })
                .then((res) => {
                    let data = res.data;
                resolve(data);
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    }

}



export {
    getCountryService
};
