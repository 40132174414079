<template>
  <div id="app">
    <vue-page-transition name="fade-in-up">
      <router-view />
    </vue-page-transition>
  </div>
</template>

<script>
import { userService } from "./services/user.service.js";
//let timeout;

export default {
  async mounted() {
    if (!this.$store.getters.GET_AUTH_TOKEN) return;
    else {
      //if user is logged in, call this func to track user inactivity
      // this.activateActivityTracker()

      // Verify the User token is still valid
      let loginValid = await this.$store.dispatch(
        "AUTHORISE_USER",
        this.$store.getters.GET_AUTH_TOKEN
      );

      // If login is not valid
      if (!loginValid) {
        this.logout();
      }
    }
  },

  methods: {
    async logout() {
      this.loading = true;
      await userService
        .logout()
        .then((res) => {
          this.$store.commit("SET_AUTH_TOKEN", "");
          this.$toastr.success(res.status, { timeOut: 3000 });
          this.$router.push("/Login");
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Registration Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // resetTimeout() {
    //   clearTimeout(timeout)
    //   timeout = setTimeout(() => {
    //     this.logout();
    //     this.$toastr.info("Session timed out", {timeOut: 5000});
    //     }, 300000)
    // },
    // activateActivityTracker () {
    //   window.addEventListener("click", () => this.resetTimeout());
    //   window.addEventListener("scroll", () => this.resetTimeout());
    //   window.addEventListener("mousemove", () => this.resetTimeout())
    // }
  },
};
</script>

<style>
@import "assets/css/hope.css";
@import "assets/css/responsive.css";
@import "assets/css/animate.css";
@import "../node_modules/vue-slim-tabs/themes/default.css";
@import "assets/css/vue-multiselect.min.css";
/* @import url(https://unpkg.com/vue-multiselect@2.1.0/dist/vue-multiselect.min.css); */

#app {
  font-family: "Montserrat-Regular", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  /* text-align: center; */
  /* color: #2c3e50; */
  /*margin-top: 60px;*/
}
</style>
