import {ApiService} from "./api.service";

const getBeneficiaryService = {
    getBeneficiary: async () => {
        return new Promise(function (resolve, reject) {
              ApiService.customRequest({
                      method: 'GET',
                      url: ApiService.getBaseUrl() + "/api/web/self/favourite",
                      headers: {
                          "Content-Type": "application/json",
                          "Hash": ApiService.computeHash('/api/web/self/favourite', localStorage.getItem('licence'))
                      },
                      redirect: 'follow'
                  })
                  .then((res) => {
                      let data = res.data
                      resolve(data);
                      window.console.log("this is action category", data)
                  })
                  .catch(err => {
                      reject(err.response.data)
                  })
        });

    },
    DeleteBeneficiaryById: async(id)=>{
        return new Promise(function(resolve, reject){
            ApiService.customRequest({
                    method: 'POST',
                    url: ApiService.getBaseUrl() + `/api/web/self/favourite/delete/${id}`,
                    headers: {
                        "Content-Type": "application/json",
                        "Hash": ApiService.computeHash(`/api/web/self/favourite/delete/${id}`, localStorage.getItem('licence'))
                    },
                    redirect: 'follow'
                })
                .then((res) => {
                    resolve(res.data);
                   // window.console.log("this is action category", data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
            
        })
    }

};



export {
    getBeneficiaryService
};
