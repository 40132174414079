<template>
  <div class="hope-login-page">
    <div class="poster"></div>
    <Loader v-if="loading" :show-full="true" loading-text="Loading..." />
    <Loader v-if="loading1" :show-full="true" loading-text="Please wait..." />

    <div class="upper-nav">
      <div class="hope-logo">
        <a href="/">
          <img
            src="../../assets/images/logo/hope_bank_logo1.svg"
            alt="Hope Bank Logo"
            class="hope_logo"
          />
        </a>
      </div>
      <div class="back-logout" @click="$router.go(-1)">
          <!-- <button class="go-back-btn" @click="$router.go(-1)"> -->
          <div class="go-back-btn">
            <span>
              <font-awesome-icon icon="arrow-left" class="angle-left" />
            </span>
            Back
          </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
          <div class="col-md-7 ml-auto mr-auto">
            <div class="enrollment-section">
              <p class="login-info">
                Enrollment Centers
              </p>
              <table>
                <tr>
                  <th>Address</th>
                  <th>State</th>
                  <th>LGA</th>
                  <th>Name</th>
                </tr>
                <tr v-for="item in outlets" :key="item.address">
                  <td>{{item.address}}</td>
                  <td>{{item.stateName}}</td>
                  <td>{{item.localGovtName}}</td>
                  <td>{{item.name}}</td>
                </tr>
              </table>

              
              <!-- <ul>
                <li>Address: <span >Victoria Island</span></li>
                <li>State: <span>Victoria Island</span></li>
                <li>LGA: <span>Victoria Island</span></li>
                <li>Name: <span>Victoria Island</span></li>
              </ul> -->
            </div>
        </div>
      </div>
 
    </div>
  
    
    <Footer2 />
    <!-- <Footer/> -->
  </div>
</template>

<script>
import Loader from "../../components/Loader/Loader.vue";
import Footer2 from "../../components/Footer/Footer2";
import { mapState } from "vuex";
import {NINValidation} from "../../services/ninValidation.service"
export default {
  components: {
    Loader,
    Footer2,
  },

  data() {
    return {
      address: "",
      state: "",
      stateName: "",
      localGovt: "",
      localGovtName: "",
      name: "",
      loading: false,
      loading1: false,
      errors: [],
      showPasswordModal: false,
      outlets: []
    };
  },

  computed: {
    ...mapState({
      AUTHENTICATION_TOKEN: (state) => state.User.AUTHENTICATION_TOKEN,
      TRANSFER_ACCOUNT: (state) => state.Transfer.TRANSFER_ACCOUNT,
      TRANSFER_ACCOUNT_FROM: (state) => state.Transfer.TRANSFER_ACCOUNT_FROM,
      TRANSFER_ACCOUNT_TO: (state) => state.Transfer.TRANSFER_ACCOUNT_TO,
      INSTITUTIONS: (state) => state.Transfer.INSTITUTIONS,
    }),
  },
  methods: {
    getNINOutlets(){
      NINValidation.ninEnrollmentCenter().then(res => {
        window.console.log(res)
        this.outlets = res
      }).catch((err) => {
          this.$toastr.error(err.status, "Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.getNINOutlets()
  },
  created() {
    
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}
.go-back-btn:hover {
  /* color: #000000;
        background: #ffffff; */
}
.logout-btn2:hover {
  /* color: #ffffff;
        background: #000000; */
}
.modal-body {
  padding: 1rem !important;
}
.btns-biz-individual {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}
.btn-for-individual {
  font-family: Montserrat-SemiBold !important;
  font-size: 13px !important;
  color: #000000 !important;
  background: #fecb32 !important;
  border: 1px solid #fecb32 !important;
  border-radius: 5px;
  padding: 10px 1.5rem;
  letter-spacing: 0.17px;
  margin: 5px;
}
.btn-for-individual:hover {
  color: #000000 !important;
  background: #ffffff !important;
  border: 1px solid #fecb32 !important;
}
.btn-for-biz {
  font-family: Montserrat-SemiBold !important;
  font-size: 13px !important;
  color: #000000 !important;
  background: #fecb32 !important;
  border: 1px solid #fecb32 !important;
  border-radius: 5px;
  padding: 10px 1.5rem;
  letter-spacing: 0.17px;
  margin: 5px;
}
.btn-for-biz:hover {
  color: #000000 !important;
  background: #ffffff !important;
  border: 1px solid #fecb32 !important;
}
.enrollment-section {
    width: 100%;
    background-color: #ffffff;
    -webkit-box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.22);
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.22);
    padding: 1.5rem;
}
.enrollment-section ul li {
  font-family: Montserrat-Regular;
  font-size: 14px;
  color: #000000;
  text-align: left;
  line-height: 1.7rem;
}
</style>