import { render, staticRenderFns } from "./StatementGen2.vue?vue&type=template&id=5737d140&scoped=true&"
import script from "./StatementGen2.vue?vue&type=script&lang=js&"
export * from "./StatementGen2.vue?vue&type=script&lang=js&"
import style0 from "./StatementGen2.vue?vue&type=style&index=0&id=5737d140&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5737d140",
  null
  
)

export default component.exports