<template>
  <div class="poster-bg">
    <div class="poster"></div>
    <div class="dashboard-page registration-page">
      <Loader v-if="loading" :show-full="true" loading-text="Processing" />

      <div class>
        <HeaderNavRegistered />
        <div class="register_now">
          <div class="container">
            <div class="text-left">
              <h6 class="welcome_username">
                Hi, {{ user.accounts[0].accountName }}
              </h6>
            </div>
            <div class="main_dashboard">
              <div class="row">
                <div class="col-md-12">
                  <div class="main_dashboard_inner">
                    <div class="transfer_money2 text-center">
                      <p class="frequently-used text-center">Open Account</p>
                    </div>

                    <div class="current_tab_title mb-3">
                      <font-awesome-icon icon="play" class="play-icon" />
                      <p class>
                        All the information below are mandatory to create your
                        account
                      </p>
                    </div>

                    <div class="steps_to_acc_open">
                      <h2>
                        Enter Details -
                        <span class="h5">{{ ACCOUNT_CATEGORY.name }}</span>
                      </h2>
                    </div>

                    <!-- <div class="mtn-plans">
                      <img src="../../assets/images/user-picture.jpg" alt="Hope Bank">
                      <div class="text-left">
                          <div class="plan-name1">Grace, Oyebola Ahmed</div>
                          <div class="plan-name2">BVN</div>
                          <div class="plan-name3">0123456789</div>
                      </div>
                    </div>-->

                    <form @submit.prevent="checkForm">
                      <div class="form-table2">
                        <!-- <div class="label-title" v-if="ACCOUNT_TYPE != 'Hope Hello Account'">
                          <label >Marital Status <span class="red-star">*</span></label>
                          <div class="account_select">
                              <select  name="" id="" class="account_type">
                                  <option value="" class="choose_account">- Select -</option>
                                  <option value="" class="choose_account">Single</option>
                                  <option value="" class="choose_account">Married</option>
                                  <option value="" class="choose_account">Divorced</option>
                              </select>
                              <font-awesome-icon icon="caret-down" class=""/>
                          </div>
                        </div>-->

                        <!-- <div class="label-title">
                            <label >Title <span class="red-star">*</span></label>
                            <div class="account_select">
                                <select  name="" id="" class="account_type">
                                    <option v-for="(items, key) in titles" :key="key" :value="items.value" class="choose_account">{{items.label}}
                                    </option>
                                    
                                </select>
                                <font-awesome-icon icon="caret-down" class=""/>
                            </div>
                        </div>-->
                        <div class="upload_profile_photo">
                          <div class="profile_photo">
                            <img src="../../assets/images/upload.svg" alt="Hope Bank" />
                          </div>
                          <!-- <label class="btn ml-n1 btn-info continue-btn">
                            Browse
                            <input
                              type="file"
                              class="mt-3 mt-md-0 mb-2 mb-md-1 small-screen-id"
                              style="display:none"
                              @change="onFileChange($event); validate($event)"
                              name="idCard"
                              id="id"
                            />
                          </label>-->

                          <!-- <button class="continue-btn">Browse</button> -->
                        </div>
                      </div>
                      <div class="label-title2">
                        <label>First Name <span class="red-star">*</span></label>
                        <div class="account_select2">
                          <input v-model="FirstName" type="text" class="input-amount" placeholder=" " required />
                        </div>
                      </div>
                      <div class="label-title2">
                        <label>Other Names</label>
                        <div class="account_select2">
                          <input v-model="MiddleName" type="text" class="input-amount" placeholder=" " />
                        </div>
                      </div>
                      <div class="label-title2">
                        <label>Last Name <span class="red-star">*</span></label>
                        <div class="account_select2">
                          <input v-model="LastName" type="text" class="input-amount" placeholder=" " required />
                        </div>
                      </div>
                      <div v-if="this.ACCOUNT_CATEGORY.code != '204' &&
                        this.ACCOUNT_CATEGORY.code != '201'
                        ">
                        <div class="label-title2">
                          <label>Phone Number <span class="red-star">*</span></label>
                          <div class="account_select2">
                            <input v-model="Phone" type="tel" class="input-amount" placeholder=" " required />
                          </div>
                        </div>
                      </div>
                      <div class="label-title2">
                        <label>Email Address <span class="red-star">*</span></label>
                        <div class="account_select2">
                          <input v-model="Email" type="email" class="input-amount" placeholder=" " />
                        </div>
                      </div>
                      <div class="label-title2">
                        <label>Date of Birth <span class="red-star">*</span></label>
                        <DatePicker v-model="DateOfBirth" :disabled-date="disabledVer" valueType="format"
                          format="DD-MM-YYYY" placeholder="DD-MM-YYYY"></DatePicker>
                      </div>

                      <div class="label-title">
                        <label>Gender</label>
                        <div class="account_select">
                          <select v-model="Gender" class="account_type">
                            <option value="Male" class="choose_account">
                              Male
                            </option>
                            <option value="Female" class="choose_account">
                              Female
                            </option>
                          </select>
                          <font-awesome-icon icon="caret-down" class />
                        </div>
                      </div>
                      <div class="label-title2">
                        <label>NIN </label>
                        <div class="account_select2">
                          <input v-model="nin" type="tel" class="input-amount" placeholder=" " />
                        </div>
                      </div>
                      <div class="label-title2">
                        <label>Turn Over </label>
                        <div class="account_select2">
                          <input v-model="TurnOver" type="tel" class="input-amount" placeholder=" " />
                        </div>
                      </div>

                      <!-- These inputs will not display for Kiddies Savings (204) -->
                      <div v-if="this.ACCOUNT_CATEGORY.code != '204' &&
                        this.ACCOUNT_CATEGORY.code != '201'
                        ">
                        <!-- Additional (Optional) -->
                        <div class="non_nigerian_form">
                          <h3>Additional [Optional]</h3>
                          <div class="label-title2">
                            <label>Phone Number</label>
                            <div class="account_select2">
                              <input type="tel" class="input-amount" v-model="Phone2" placeholder=" " />
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>Address</label>
                            <div class="account_select2">
                              <input type="text" class="input-amount" v-model="ResAddress2" placeholder=" " />
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>Turn Over</label>
                            <div class="account_select2">
                              <input type="text" v-model="TurnOver" class="input-amount" placeholder=" " />
                            </div>
                          </div>
                        </div>

                        <!-- Next of kin  details -->
                        <div class="non_nigerian_form">
                          <h3>Next of Kin Details [Optional]</h3>
                          <div class="label-title2">
                            <label>Name</label>
                            <div class="account_select2">
                              <input type="text" class="input-amount" v-model="NkName" placeholder=" " />
                            </div>
                          </div>

                          <div class="label-title2">
                            <label>Phone </label>
                            <div class="account_select2">
                              <input type="tel" class="input-amount" v-model="NkPhone" placeholder=" " maxlength="11" />
                            </div>
                          </div>

                          <div class="label-title2">
                            <label>Relationship </label>
                            <div class="account_select2">
                              <input type="text" class="input-amount" v-model="NkRelationship" placeholder=" " />
                            </div>
                          </div>

                          <div class="label-title2">
                            <label>Address </label>
                            <div class="account_select2">
                              <input type="text" class="input-amount" v-model="NkAddress" placeholder=" " />
                            </div>
                          </div>
                        </div>
                        <!-- Non Nigerian details -->
                        <div class="non_nigerian_form">
                          <h3>Further Details</h3>
                          <div class="label-title2">
                            <label>Tax Identity Number</label>
                            <div class="account_select2">
                              <input type="text" v-model="TIN" class="input-amount" placeholder=" " />
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>National Identity Number</label>
                            <div class="account_select2">
                              <input type="text" v-model="NIN" class="input-amount" placeholder=" " />
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>Occupation <span class="red-star">*</span></label>
                            <div class="account_select">
                              <select v-model="Occupation" class="account_type">
                                <option :value="profession.value" v-for="(profession, index) in professions" :key="index"
                                  class="choose_account">
                                  {{ profession.label }}
                                </option>
                              </select>
                              <font-awesome-icon icon="caret-down" class />
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>Mother's Maiden Name
                              <span class="red-star">*</span></label>
                            <div class="account_select2">
                              <input type="text" v-model="MaidenName" class="input-amount" placeholder=" " />
                            </div>
                          </div>

                          <div class="label-title2">
                            <label>Nationality
                              <span class="red-star">*</span>
                            </label>
                            <div class="account_select">
                              <select v-model="Nationality" class="account_type">
                                <option v-for="(items, key) in countries" :key="key" :value="items.value"
                                  class="choose_account">
                                  {{ items.label }}
                                </option>
                              </select>
                              <font-awesome-icon icon="caret-down" class />
                            </div>
                          </div>

                          <div class="non_nigerian_form">
                            <h3>Address - Residential</h3>
                            <div class="label-title2">
                              <label>Street Address
                                <span class="red-star">*</span></label>
                              <div class="account_select2">
                                <input type="text" v-model="resAddress" class="input-amount" placeholder=" " />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Town/City
                                <span class="red-star">*</span></label>
                              <div class="account_select2">
                                <input type="text" v-model="ResTown" class="input-amount" placeholder=" " />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>ZIP/Postal Code</label>
                              <div class="account_select2">
                                <input type="tel" v-model="ZipCode" class="input-amount" placeholder=" " />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Residence Country
                                <span class="red-star">*</span></label>
                              <div class="account_select">
                                <select v-model="ResCountry" class="account_type">
                                  <option v-for="(items, key) in countries" :key="key" :value="items.value"
                                    class="choose_account">
                                    {{ items.label }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>State <span class="red-star">*</span></label>
                              <div class="account_select">
                                <select v-model="ResState" class="account_type">
                                  <option>Select State</option>
                                  <option v-for="(items, key) in states" :key="key" :value="items.value"
                                    class="choose_account">
                                    {{ items.label }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                          </div>

                          <div class="non_nigerian_form">
                            <h3>Business/Office/School Address</h3>
                            <div class="label-title2">
                              <label>Address </label>
                              <div class="account_select2">
                                <input type="text" v-model="BizAddress" class="input-amount" placeholder=" " />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Country</label>
                              <div class="account_select">
                                <select v-model="BizCountry" class="account_type">
                                  <option v-for="(items, key) in countries" :key="key" :value="items.value"
                                    class="choose_account">
                                    {{ items.label }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>State </label>
                              <div class="account_select">
                                <select v-model="BizState" class="account_type">
                                  <option>Select State</option>
                                  <option v-for="(items, key) in states" :key="key" :value="items" class="choose_account">
                                    {{ items.label }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>LGA</label>
                              <div class="account_select">
                                <select class="account_type" v-model="BizLGA">
                                  <option class="choose_account" value>
                                    --Select Lga--
                                  </option>
                                  <option :value="lga.value" v-for="(lga, index) in BizState.lgas" :key="index">
                                    {{ lga.label }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="non_nigerian_form" v-if="this.ACCOUNT_CATEGORY.code == '1020'">
                        <h3>Non-Nigerians</h3>
                        <div class="label-title2">
                          <label>Resident Permit No. 1</label>
                          <div class="account_select2">
                            <input type="text" class="input-amount" placeholder=" " />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Resident Permit Date</label>
                          <div class="account_select2">
                            <input type="text" class="input-amount" placeholder=" " />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Resident Expiry</label>
                          <div class="account_select2">
                            <input type="text" class="input-amount" placeholder=" " />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Employers Name/Business</label>
                          <div class="account_select2">
                            <input type="text" class="input-amount" placeholder=" " />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Fatca Identification</label>
                          <div class="account_select2">
                            <input type="text" class="input-amount" placeholder=" " />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>USA Citizen or Resident</label>
                          <div class="account_select2">
                            <input type="text" class="input-amount" placeholder=" " />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Passport/Alien Number</label>
                          <div class="account_select2">
                            <input type="text" class="input-amount" placeholder=" " />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Passport Issue Date</label>
                          <div class="account_select2">
                            <input type="text" class="input-amount" placeholder=" " />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Passport Expiry Date</label>
                          <div class="account_select2">
                            <input type="text" class="input-amount" placeholder=" " />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Last 4 Digit of Social Security Number</label>
                          <div class="account_select2">
                            <input type="text" class="input-amount" placeholder=" " />
                          </div>
                        </div>
                      </div>

                      <!-- ID Card -->
                      <div class="non_nigerian_form" v-if="this.ACCOUNT_CATEGORY.code == '102' ||
                        this.ACCOUNT_CATEGORY.code == '206' ||
                        this.ACCOUNT_CATEGORY.code == '205'
                        ">
                        <h3>Other Information</h3>
                        <div class="label-title">
                          <label>ID TYPE</label>
                          <div class="account_select">
                            <select v-model="IdType" class="account_type">
                              <option>Select ID Type</option>
                              <option class="choose_account" value="NIN">
                                National Identity Number (NIN)
                              </option>
                              <option class="choose_account" value="BVN">
                                Bank Verification Number (BVN)
                              </option>
                              <!-- <option class="choose_account"
                                >Divers Licence</option
                              >
                              <option class="choose_account"
                                >Int'l Passport</option
                              >
                              <option class="choose_account"
                                >National ID Card</option
                              >
                              <option class="choose_account"
                                >Voters Card</option
                              >
                              <option class="choose_account">Others</option> -->
                            </select>
                            <font-awesome-icon icon="caret-down" class />
                          </div>
                          <!-- <div class="account_select2">
                            <input
                              type="text"
                              v-model="IdType"
                              class="input-amount"
                              placeholder=" "
                            />
                          </div>-->
                        </div>
                        <div class="label-title2">
                          <label>ID NO</label>
                          <div class="account_select2">
                            <input type="text" v-model="IdNo" class="input-amount" placeholder=" " />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Turn Over</label>
                          <div class="account_select2">
                            <input type="text" v-model="TurnOver" class="input-amount" placeholder=" " />
                          </div>
                        </div>
                        <!-- <div class="label-title2">
                          <label>ID Issue Date</label>
                          <div class="account_select2">
                            <datetime
                              placeholder="-DD-MM-YY-"
                              v-model="IdIssueDate"
                              class="input-amount"
                            ></datetime>
                          </div>
                        </div> -->
                        <!-- <div class="label-title2">
                          <label>ID Expiry Date</label>
                          <div class="account_select2">
                            <datetime
                              placeholder="-DD-MM-YY-"
                              v-model="IdExpiryDate"
                              class="input-amount"
                            ></datetime>
                          </div>
                        </div> -->
                        <!-- <div class="label-title2">
                          <label>
                            ID Data -
                            <span
                              class="ml-1"
                              style="color:red; font-size:13px"
                            >&nbsp; (max - 1mb)</span>
                          </label>
                          <div class="account_select2">
                            <vuebase64
                              @size-exceeded="onSizeExceeded"
                              @load="IdData = $event"
                              :maxSize="1"
                            />
                          </div>
                        </div> -->
                      </div>

                      <!-- Utility Bill -->
                      <!-- <div class="non_nigerian_form" v-if="
                          this.ACCOUNT_TYPE.code == '102'
                        ">
                        <div class="label-title2">
                          <label>
                            Utility Bill -
                            <span class="ml-1" style="color:red; font-size:13px"
                              >&nbsp; (max - 1mb)</span
                            >
                          </label>
                          <div class="account_select5">
                            <vuebase64
                              @size-exceeded="onSizeExceeded"
                              @load="UtilityBill = $event"
                              :maxSize="1"
                            />
                          </div>
                        </div>
                      </div> -->

                      <!-- Referee -->
                      <div class="non_nigerian_form" v-if="this.ACCOUNT_CATEGORY.code == '1111'">
                        <div v-for="(ref, index) in 2" :key="index">
                          <h3>Referee {{ index + 1 }} Details</h3>
                          <div class="label-title2">
                            <label>Referee {{ index + 1 }} Name
                              <span class="red-star">*</span></label>
                            <div class="account_select2">
                              <input type="text" required v-model="RefName[index]" class="input-amount" placeholder=" " />
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>Referee {{ index + 1 }} Email
                              <span class="red-star">*</span></label>
                            <div class="account_select2">
                              <input type="email" required v-model="RefEmail[index]" class="input-amount"
                                placeholder=" " />
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>Referee {{ index + 1 }} Phone
                              <span class="red-star">*</span></label>
                            <div class="account_select2">
                              <input v-model="RefPhone[index]" required type="text" class="input-amount"
                                placeholder=" " />
                            </div>
                          </div>
                          <!-- Who introduced you to Hope? (Optional) -->
                          <div class="non_nigerian_form">
                            <div class="label-title2">
                              <div class="">
                                <label>
                                  Referral Code or Phone No.
                                  <span> [Optional]</span>
                                </label>
                                <p>Who introduced you to Hope?</p>
                              </div>
                              <div class="account_select2">
                                <input type="text" maxlength="11" v-model="Referrer" class="input-amount"
                                  placeholder=" " />
                              </div>
                            </div>
                          </div>
                          <!-- Who introduced you to Hope? (Optional) -->
                        </div>
                      </div>
                      <!-- Who introduced you to Hope? (Optional) -->
                      <div class="non_nigerian_form">
                        <div class="label-title2">
                          <div class="">
                            <label>
                              Referral Code or Phone No.
                              <span class="optional_">[Optional]</span>
                            </label>
                            <p>Who introduced you to Hope?</p>
                          </div>
                          <div class="account_select2">
                            <input type="text" maxlength="11" v-model="Referrer" class="input-amount" placeholder=" " />
                          </div>
                        </div>
                      </div>

                      <!-- Uploads -->
                      <!-- <div
                        class="non_nigerian_form"
                        v-if="
                          this.ACCOUNT_TYPE.code == '104' ||
                            this.ACCOUNT_TYPE.code == '
                            '
                        "
                      >
                        <h3>
                          Uploads -
                          <span style="color:red; font-size:13px"
                            >(Only jpgs, pngs allowed. max file size - 1
                            mb)</span
                          >
                        </h3>
                        <div class="label-title2">
                          <label
                            >Letter of Intro from Company
                            <span class="red-star">*</span></label
                          >
                          <div class="account_select5">
                            <vuebase64
                              @size-exceeded="onSizeExceeded"
                              @load="CompanyIntro = $event"
                            />
                          </div>
                        </div>

                        <div class="label-title2">
                          <label
                            >Company ID Card
                            <span class="red-star">*</span></label
                          >
                          <div class="account_select5">
                            <vuebase64
                              @size-exceeded="onSizeExceeded"
                              @load="CompanyId = $event"
                              accept="image/*"
                              :maxSize="1"
                            />
                          </div>
                        </div>
                      </div> -->

                      <div class="non_nigerian_form">
                        <div>
                          <br />
                        </div>
                        <!--   <div class="term_condition_check">
                          <input
                            type="checkbox"
                            id="debit"
                            v-model="debitCard"
                            name="debit"
                            value="Debit"
                          />
                          <label for="debit" class="terms-label-text">&nbsp;request for debit card </label>
                        </div> -->
                        <div class="term_condition_check">
                          <input type="checkbox" id="terms" v-model="terms" name="terms" value="Terms" />
                          <label for="terms">
                            I accept the
                            <b-button v-b-modal.modal-scrollable-terms class="terms_button">Terms and
                              Conditions</b-button>
                          </label>
                        </div>
                        <terms />

                        <div class="kindly_enter_details mb-3">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Account Number will be sent to your email.</p>
                        </div>
                      </div>
                      <div class="send-or-download2">
                        <div class="continue-button">
                          <a @click="$router.go(-1)">
                            <div class="continue-btn">
                              <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                            </div>
                          </a>
                        </div>
                        <div class="continue-button">
                          <b-button type="submit" :disabled="isDisabled" class="continue-btn">
                            Submit
                            <font-awesome-icon icon="angle-right" class="angle-icon" />
                          </b-button>
                          <!-- <router-link to="Complete"><button class="continue-btn">Create Account <font-awesome-icon icon="angle-right" class="angle-icon"/></button></router-link> -->
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />

      <b-modal id="modal-center3" centered v-model="otp_modal" @show="resetModal" @hidden="resetModal" @ok="handleOk"
        hide-footer>
        <form ref="form" @submit.prevent="handleSubmit">
          <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <h1 class="pin-info text-center">Enter OTP sent to your Phone</h1>
            <div class="dialpad">
              <div class="form-group">
                <input type="password" class="form-control" v-model="otp" mask="######" maxlength="6" minlength="6"
                  id="txtBox" placeholder="otp" />
              </div>
              <Keyboard @pressed="otp = $event" :pinLength="otp" @completed="showPinModal" :selfValue="otp"></Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>

      <b-modal id="modal-center4" centered v-model="pin_modal" @show="resetModal" @hidden="resetModal" @ok="handleOk"
        hide-footer>
        <form ref="form" @submit.stop.prevent="CreateAccount">
          <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <h1 class="pin-info text-center">
              Create PIN (6-8 digits for {{ ACCOUNT_TYPE.name }})
            </h1>
            <div class="dialpad">
              <div class="form-group">
                <input @mousedown="showPinKeyboard1" type="password" class="form-control" v-model="pin1" mask="######"
                  maxlength="8" minlength="6" id="txtBox" placeholder="Enter PIN (6-8 Digits)" required />
              </div>
              <div class="form-group">
                <input @mousedown="showPinKeyboard" type="password" class="form-control" v-model="pin" mask="######"
                  maxlength="8" minlength="6" id="txtBox" placeholder="Confirm PIN (6-8 Digits)" required />
              </div>
              <div class="h6 mt-n3 mb-3 text-danger" v-if="isPinMatch">
                Pin does not match
              </div>
              <button class="ok-btn" :disabled="isDisabled_" type="submit">
                Submit
              </button>
            </div>
          </b-form-group>
        </form>
      </b-modal>

      <b-modal id="modal-center-kiddies" centered @show="resetModal" @hidden="resetModal" @ok="handleOk" hide-footer
        v-model="kiddlesCreateModal">
        <form ref="form" @submit.stop.prevent="CreateAccount">
          <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <h1 class="pin-info text-center">
              You are about to open <b>{{ ACCOUNT_CATEGORY.name }}</b> with a
              sum of:
            </h1>
            <div class="dialpad">
              <div class="label-title2">
                <!-- <div>
                  <small class="pl-3">
                    You are about to create a 
                    <b>{{ Code.name }}</b>
                  </small>
                </div> -->
                <label>Enter Amount</label>
                <div class="account_select2">
                  <vue-numeric class="input-amount" currency="₦" separator="," precision="2" v-model="Amount"
                    required></vue-numeric>
                </div>
              </div>

              <div class="label-title">
                <label>Account to debit</label>
                <div class="account_select">
                  <select required v-model="transferAccount" name id class="account_type">
                    <option v-for="item in user.accounts" :key="item + 'a'" :value="item.accountNumber">
                      {{ item.accountName }} -
                      {{ item.accountNumber }}
                    </option>
                  </select>
                  <font-awesome-icon icon="caret-down" class />
                </div>
              </div>
              <div class="label-title2">
                <label>Enter PIN</label>
                <div class="account_select2">
                  <input @mousedown="showPinKeyboard1" type="password" class="input-amount" v-model="pin" mask="########"
                    maxlength="8" minlength="6" placeholder required />
                </div>
              </div>

              <button class="ok-btn" type="submit">Submit</button>
            </div>
          </b-form-group>
        </form>
      </b-modal>

      <b-modal id="modal-center-kiddiesComplete" centered v-model="success_modal2" @show="resetModal" @hidden="resetModal"
        @ok="handleOk" hide-footer>
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <img src="../../assets/images/icons/check.svg" alt="Hope Bank" class="modal_congrats_img" />
            <h1 class="pin-info text-center">Congratulations</h1>
            <div class="line4"></div>
            <p class="modal_congrats">
              {{ ACCOUNT_CATEGORY.name }} has been created with Account Number:
              <span>{{ responseData.account }}</span>
            </p>
            <router-link to="Login">
              <button class="ok-btn">OK</button>
            </router-link>
          </b-form-group>
        </form>
      </b-modal>

      <b-modal id="modal-center" centered v-model="success_modal" @show="resetModal" @hidden="resetModal" @ok="handleOk"
        hide-footer>
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <img src="../../assets/images/icons/check.svg" alt="Hope Bank" class="modal_congrats_img" />
            <h1 class="pin-info text-center">Congratulations</h1>
            <div class="line4"></div>
            <p class="modal_congrats">
              Your {{ ACCOUNT_CATEGORY.name }} Number is
              <span>{{ responseData.account }}</span>
            </p>
            <p class="modal_congrats2">
              To operate your account, download Hope Digital App or Visit
              <a href="https://www.hopepsbank.com" target="_blank">www.hopepsbank.com</a>
              or Dial <a href="tel:*569*9#">*569*9#</a>.
            </p>
            <p class="modal_congrats2">
              To withdraw money or make payments, simply enter your phone number
              on ATM, PoS, Web or Agent's device.<br />
              You will be prompted to enter your PIN on your phone to approve.
              No Cheque book or Payment Card is necessary.
            </p>
            <p class="modal_congrats2">It is all digital with Hope PSBank.</p>
            <router-link to="Login">
              <button class="ok-btn">OK</button>
            </router-link>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import HeaderNavRegistered from "../../components/HeaderNav/HeaderNavRegistered";
// import {TheMask} from 'vue-the-mask';
import { getCountryService } from "../../services/getCountries.services.js";
import { getStateService } from "../../services/getState.services.js";
import { getTitlesService } from "../../services/getTitles.services.js";
import { CreateAccountService } from "../../services/createAccount.service.js";
import { OccupationService } from "../../services/getOccupations.js";
import Loader from "../../components/Loader/Loader.vue";
import { mapState, mapGetters } from "vuex";
import Keyboard from "../../components/Keyboard/Keyboard.vue";
import terms from "../../components/Terms/terms";
import Footer2 from "../../components/Footer/Footer2";
import VueNumeric from "vue-numeric";
import Axios from "axios";

//import vuebase64 from "../../components/vue-base64/vuebase64.vue";

export default {
  name: "Verify_",
  components: {
    HeaderNavRegistered,
    Loader,
    Keyboard,
    terms,
    Footer2,
    VueNumeric,
    //vuebase64,
    // TheMask,
  },
  data() {
    return {
      image: "",
      isPinMatch: false,
      //pinMessage:false,
      pin_keyboard1: true,
      pin_keyboard: false,
      professions: [],
      pin_modal: false,
      kiddlesCreateModal: false,
      terms: false,
      isDisabled: true,
      transferAccount: "",
      country: null,
      region: null,
      titles: [],
      countries: [],
      states: [],
      Nationality: "",
      Amount: "",
      Address: "",
      email: "",
      loading: false,
      pin: "",
      pin1: "",
      NkName: "",
      NkRelationship: "",
      NkPhone: "",
      NkAddress: "",
      ResState: "",
      ResTown: "",
      ResCountry: "",
      BizAddress: "",
      BizCountry: "",
      BizState: "",
      BizLGA: "",
      TIN: "",
      NIN: "",
      Occupation: "",
      MaidenName: "",
      resAddress: "",
      resAddress2: "",
      ZipCode: "",
      IdType: "",
      IdNo: "",
      IdIssueDate: "",
      IdExpiryDate: "",
      IdData: "",
      RefName: [],
      RefEmail: [],
      RefPhone: [],
      UtilityBill: "",
      CompanyIntro: "",
      debitCard: false,
      CompanyId: "",
      success_modal: false,
      responseData: {},
      Referrer: "",
    };
  },

  watch: {
    pin(newval) {
      if (newval.length >= 6) {
        this.pinMessage = false;
        if (newval != this.pin1) {
          this.isPinMatch = true;
          // this.isDisabled = true;
          return;
        }
        this.isPinMatch = false;
        //this.isDisabled = false;
        return;
      }
      //this.isDisabled = true;
    },
    terms(newval) {
      if (newval) return (this.isDisabled = false);
      this.isDisabled = true;
    },
    DateOfBirth(newval) {
      this.checkDateValidity(newval);
    },
  },

  computed: {
    ...mapGetters({ user: "GET_USER" }),
    ...mapState({
      ACCOUNT_OTP: (state) => state.CreateAccount.ACCOUNT_OTP,
      ACCOUNT_TYPE: (state) => state.CreateAccount.ACCOUNT_TYPE,
      ACCOUNT_ID: (state) => state.CreateAccount.ACCOUNT_ID,
      ACCOUNT_PIN: (state) => state.CreateAccount.ACCOUNT_PIN,
      BVN_DETAILS: (state) => state.CreateAccount.BVN_DETAILS,
      REGISTRATION_DETAILS: (state) => state.CreateAccount.REGISTRATION_DETAILS,
      ACCOUNT_CATEGORY: (state) => state.AccountType.ACCOUNT_CATEGORY,
    }),
    // Display Hi, users name
    fullName() {
      let name = this.user.accounts[0].accountName;
      name = name.split(" ");
      if (name.length == 2) {
        name.splice(1, 0, "");
      }
      return name;
    },

    FirstName: {
      get() {
        return this.BVN_DETAILS.data.FirstName;
      },
      set(value) {
        this.BVN_DETAILS.data.FirstName = value;
      },
    },
    LastName: {
      get() {
        return this.BVN_DETAILS.data.LastName;
      },
      set(value) {
        this.BVN_DETAILS.data.LastName = value;
      },
    },
    MiddleName: {
      get() {
        return this.BVN_DETAILS.data.MiddleName;
      },
      set(value) {
        this.BVN_DETAILS.data.MiddleName = value;
      },
    },
    Phone: {
      get() {
        return this.REGISTRATION_DETAILS.phoneNumber || this.phone;
      },
      set(value) {
        if (this.REGISTRATION_DETAILS.phoneNumber)
          return (this.REGISTRATION_DETAILS.phoneNumber = value);
        return (this.phone = value);
      },
    },
    DateOfBirth: {
      get() {
        return this.REGISTRATION_DETAILS.DateOfBirth || this.dob;
      },
      set(value) {
        if (this.REGISTRATION_DETAILS.DateOfBirth)
          return (this.REGISTRATION_DETAILS.DateOfBirth = value);
        return (this.dob = value);
      },
    },
    Gender: {
      get() {
        return this.BVN_DETAILS.data.Gender;
      },
      set(value) {
        this.BVN_DETAILS.data.Gender = value;
      },
    },
    address: {
      get() {
        return this.BVN_DETAILS.data.Address || this.Address;
      },
      set(value) {
        if (this.BVN_DETAILS.data.Address)
          return (this.BVN_DETAILS.data.Address = value);
        return (this.Address = value);
      },
    },
    Email: {
      get() {
        return this.BVN_DETAILS.data.Email || this.email;
      },
      set(value) {
        if (this.BVN_DETAILS.data.Email)
          return (this.BVN_DETAILS.data.Email = value);
        return (this.email = value);
      },
    },
  },

  methods: {
    onSizeExceeded() {
      this.$toastr.error(`Maximum file size exceeded`, "Error");
    },
    sendEmail(x) {
      Axios.post(`https://hopemail.tm-dev.xyz/service/send-mail/`, {
        name: `${this.FirstName} ${this.LastName}`,
        phone_number: this.Phone,
        account_no: x,
        email_from: this.email
      })
        .then((res) => {
          window.console.log(res)
        })
        .catch((err) => {
          window.console.log(err)
        })
    },
    checkForm: function () {
      if (this.ACCOUNT_CATEGORY.code == "204") {
        this.kiddlesCreateModal = true;
      } else {
        this.pin_modal = true;
      }
    },
    //Check Date Validity
    disabledVer(date) {
      let tdate = new Date();
      let year = tdate.getFullYear();
      let month = tdate.getMonth();
      let day = tdate.getDate();
      const today = new Date(year - 18, month, day);
      today.setHours(0, 0, 0, 0);

      return date > today
    },
    checkDateValidity(date) {
      let tdate = new Date();
      let year = tdate.getFullYear();
      let month = tdate.getMonth();
      let day = tdate.getDate();
      let newYear = new Date(year - 18, month, day);
      let dob = new Date(date);

      if (dob > newYear) {
        this.DateOfBirth = "";
        return this.$toastr.error("Must be 18 years or older", "Error");
      }

      // alert("okay to proceed")
    },
    async CreateAccount() {
      this.pin_modal = false;
      this.loading = true;
      this.kiddlesCreateModal = false;

      // window.console.log("this is accounttypee", this.ACCOUNT_TYPE);
      await CreateAccountService.createServiceWhenLoggedIn({
        Id: this.ACCOUNT_ID,
        OTP: this.ACCOUNT_OTP,
        Pin: this.pin,
        Code: this.$route.params.data,
        Product: this.$route.params.data,
        Address: this.Address,
        Amount: this.Amount,
        Nationality: this.Nationality,
        Email: this.email,
        DateOfBirth: this.DateOfBirth,
        FirstName: this.FirstName,
        LastName: this.LastName,
        MiddleName: this.MiddleName,
        Gender: this.Gender,
        Account: this.transferAccount,
        NkName: this.NkName,
        NkRelationship: this.NkRelationship,
        NkPhone: this.NkPhone,
        NkAddress: this.NkAddress,
        ResState: this.ResState,
        ResTown: this.ResTown,
        ResAddress: this.resAddress,
        ResCountry: this.ResCountry,
        BizAddress: this.BizAddress,
        BizCountry: this.BizCountry,
        BizState: this.BizState.value,
        BizLGA: this.BizLGA,
        TIN: this.TIN,
        NIN: this.NIN,
        Occupation: this.Occupation,
        MaidenName: this.MaidenName,
        ZipCode: this.ZipCode,
        IdType: this.IdType,
        IdNo: this.IdNo,
        IdIssueDate: this.IdIssueDate,
        IdExpiryDate: this.IdExpiryDate,
        IdData: this.IdData,
        UtilityBill: this.UtilityBill,
        Ref1Name: this.RefName[0],
        Ref1Email: this.RefEmail[0],
        Ref1Phone: this.RefPhone[0],
        Ref2Name: this.RefName[1],
        Ref2Email: this.RefEmail[1],
        Ref2Phone: this.RefPhone[1],
        CompanyIntro: this.CompanyIntro,
        CompanyId: this.CompanyId,
        Referrer: this.Referrer,
        TurnOver: this.TurnOver,
      })
        .then((res) => {
          window.console.log(res);
          this.responseData = res;
          this.success_modal = true;
          if (this.responseData) {
            if (this.debitCard) {
              this.sendEmail(this.responseData.account)
              window.console.log(this.responseData.account)
            }
          } else {
            return
          }
          //this.$toastr.success(res.status, "Account Successfully created", {timeOut: 5000});
        })
        .catch((error) => {
          this.$toastr.error(error.status, "Account Creation Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getTitle() {
      await getTitlesService
        .getTitle()
        .then((res) => {
          this.loading = false;
          this.titles = res;
        })
        .catch((err) => window.console.log(err));
    },

    async getCountries() {
      await getCountryService
        .getCountryWhenLoggedIn()
        .then((res) => {
          this.countries = res;
        })
        .catch((err) => window.console.log(err));
    },

    async getState() {
      await getStateService
        .getStateWhenLoggedIn()
        .then((res) => {
          this.states = res;
        })
        .catch((err) => window.console.log(err));
    },

    getOccupations() {
      OccupationService.occupationServiceWhenLoggedIn()
        .then((res) => {
          this.loading = false;
          this.professions = res;
        })
        .catch((err) => window.console.log(err));
    },

    //Company  intro image
    onFileChange1(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      let reader = new FileReader();
      reader.onload = (e) => {
        this.CompanyIntro = e.target.result;
        //window.console.log(reader.result);
      };
      reader.readAsDataURL(files[0]);
    },

    //Company Id image
    onFileChange2(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      let reader = new FileReader();
      reader.onload = (e) => {
        this.CompanyId = e.target.result;
      };
      reader.readAsDataURL(files[0]);
    },

    showPinKeyboard() {
      this.pin_keyboard1 = false;
      this.pin_keyboard = true;
    },
    showPinKeyboard1() {
      this.pin_keyboard1 = true;
      this.pin_keyboard = false;
    },
  },
  mounted() {
    //this.getTitle();
    this.getCountries();
    this.getState();
    this.getOccupations();
  },
};
</script>

<style scoped>
.img-fluid {
  height: 100px !important;
  width: 100px !important;
}

a {
  text-decoration: none;
  color: #000000;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}

.navbar {
  padding: 0;
}

.quick-transfer {
  padding: 0 2.2rem;
}

.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}

.modal-header {
  padding: 1rem 1rem 0 !important;
}

.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}

.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}

.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
}

.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
}

.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}

.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}

.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}

.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}

.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #ffffff !important;
}

.yellow_bg>.navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}

.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}

.poster-bg {
  height: auto !important;
  background: none !important;
}

.main_dashboard {
  width: 75%;
  margin: 0 auto;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .main_dashboard {
    width: 100% !important;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
