<template>
  <div class>
    <div class="poster"></div>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />

    <div class="dashboard-page overview_main_page">
      <HeaderNavDashboard></HeaderNavDashboard>
      <div class="container">
        <HeaderNav3></HeaderNav3>
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-12">
              <div class="main_dashboard_inner3">
                <div class="overview_greetings">
                  <div class="overview_greeting">
                    <font-awesome-icon icon="chart-bar" />
                    <div class="frequently-used">Account Dashboard</div>
                  </div>
                  <div class="greetings">
                    Hi, {{ user.accounts[0].accountName }}
                  </div>
                </div>
                <br />
                <div style="padding: 20px; border: 1px solid #000; background: #fecb32; border-radius:20px;">
                  <marquee>
                    <b>Dear Esteemed Customer,
                      Please dial *346*3*NIN*0349510# to get your vNIN.
                      Then dial *569*9# to add your vNIN or BVN to your account
                      in compliance with CBN requirements for KYC. Thank you</b>
                  </marquee>
                </div>
                <br />


                <div class="line"></div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="overview_border2">
                      <div class="overview_header">
                        <font-awesome-icon icon="user" />
                        <div class="profile_title">My Profile</div>
                      </div>
                      <div class="p-3">
                        <div class="profile-image">
                          <img src="../../assets/images/avatar-icon.svg" alt="Profile Picture" />
                        </div>
                        <div class="profile-info">
                          <h4>{{ user.accounts[0].accountName }}</h4>
                          <p>Account Type: <b>Personal Account</b></p>
                          <!-- <p>Account Number</p> -->
                          <!-- <p v-for="(account, index) in user.accounts" :key="index">
                            <b>{{account.accountType}} - {{account.accountNumber}}</b>
                          </p>-->
                          <div>
                            <b-button v-b-toggle.collapse-3 class="terms_button">View All Accounts</b-button>
                            <b-collapse id="collapse-3">
                              <!-- <p v-for="(account, index) in user.accounts" :key="index" class="mb-1">
                                <b>{{account.accountType}} - {{account.accountNumber}}</b>
                              </p> -->
                              <div v-for="account in user.accounts" :key="account.Number" class="radio-item mb-1">
                                <input type="radio" :id="account.accountNumber" v-model="defaultAccount"
                                  :value="account.accountNumber" />
                                <label :for="account.accountNumber"><b>{{ account.accountType }} -
                                    {{ account.accountNumber }}</b></label>
                              </div>
                              <!-- <div class="radio-item">
                                  <input type="radio" id="ritemb" name="ritem" value="ropt2">
                                  <label for="ritemb">Option 2</label>
                              </div> -->
                              <!-- {{defaultAccount}} -->
                              <button class="continue-btn mt-2 mb-2" @click.prevent="setDefaultAccount">
                                Set as default Account
                              </button>
                            </b-collapse>
                          </div>

                          <p>
                            BVN -
                            <b>{{ BVN }}</b>
                          </p>
                          <p>
                            Phone Number -
                            <b>{{ user.phone }}</b>
                          </p>
                        </div>
                      </div>
                      <div class="last_login2">
                        <div class="last_login">
                          <h4>Last Login</h4>
                        </div>
                        <div class="login-history2">
                          <div>
                            <div class="web">Web</div>
                            <div class="date">{{ user.lastLogin }}</div>
                          </div>
                          <div>
                            <div class="successful">Successful</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3"></div>
                  </div>

                  <div class="col-md-8">
                    <div class="overview_border">
                      <div class="overview_header">
                        <font-awesome-icon icon="money-bill" />
                        <div class="profile_title">Accounts Details</div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <div class="dashboard-acc-bal">
                            <!-- <div class="carousel-home-resources"> -->
                            <carousel-3d :autoplay="true" :controls-visible="carouselControls" :autoplay-timeout="5000"
                              :space="440">
                              <slide :index="index" v-for="(account, index) in user.accounts" :key="index">
                                <div class="account-balance1">
                                  <p>
                                    <b>{{ user.accounts[index].accountType }}
                                      -
                                      {{
      user.accounts[index].accountNumber
    }}</b>
                                  </p>
                                  <p>Account Balance:</p>
                                  <h3>
                                    <span>&#8358;</span>
                                    {{
        formatAmount(user.accounts[index].balance)
      }}
                                  </h3>

                                  <div class="book_balance">
                                    Book Balance:
                                    {{
        formatAmount(
          user.accounts[index].ledgerBalance
        )
      }}
                                  </div>
                                  <!--  <button @click="getAccountId(user.accounts[index].accountNumber), show_card = true"
                                    class="req_btn">
                                    Card Request
                                  </button> -->
                                </div>

                                <img src="../../assets/images/hope-bg.jpg" />
                              </slide>
                            </carousel-3d>
                            <controls v-if="controlsVisible" :next-html="controlsNextHtml" :prev-html="controlsPrevHtml"
                              :width="controlsWidth" :height="controlsHeight"></controls>
                            <!-- <div class="form-input">
                              <button
                                class="button"
                                @click="carouselControls = !carouselControls"
                              >
                                Show/hide Navigation
                              </button>
                            </div> -->

                            <!-- <div class="swipe-left-right">
                              <font-awesome-icon icon="chevron-circle-left" />
                              <font-awesome-icon icon="chevron-circle-right" />
                              <p>
                                Swipe Image Left &amp; Right to view other
                                accounts
                              </p>
                            </div> -->
                            <!-- </div> -->
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="account_details1-">
                            <div class="account_details1">
                              <div class="view_statement">
                                <router-link to="StatementGenerate">
                                  <button class="view_statement-btn">
                                    Generate Statement
                                  </button>
                                </router-link>
                              </div>
                              <div class="view_statement">
                                <router-link to="/BillPayment">
                                  <button class="view_statement-btn">
                                    Make Payment
                                  </button>
                                </router-link>
                              </div>
                              <div class="view_statement">
                                <router-link to="TransferMoney">
                                  <button class="view_statement-btn">
                                    Make Transfer
                                  </button>
                                </router-link>
                              </div>
                              <div class="view_statement">
                                <router-link to="History">
                                  <button class="view_statement-btn">
                                    Transaction History
                                  </button>
                                </router-link>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3"></div>

                    <div class="overview_border">
                      <div class="overview_header">
                        <font-awesome-icon icon="clock" />
                        <div class="profile_title">Transaction History</div>
                      </div>

                      <div class="login-history">
                        <div v-for="transaction in transHistory.slice(0, 20)" :key="transaction">

                          <div class="login-history2" v-if="transaction" @click="transactionDetails(transaction)"
                            style="cursor: pointer">
                            <div>
                              <div class="web">
                                {{ transaction.data.amount }}
                              </div>
                            </div>
                            <div>
                              <div class="transfer">
                                {{ transaction.data["transaction Type"] }}
                              </div>
                              <div class="time">
                                {{ transaction.data.date }}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
      <b-modal id="modal-scrollable" scrollable title="Scrollable Content">
        <p class="my-4" v-for="i in 20" :key="i">
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
      </b-modal>
    </div>
    <div class="modal_card" v-if="show_card">
      <div class="modal_card_inner">

        Are you sure you want to request for a debit card for this account?

        <div>The Fee to process this will be <b>₦ 2,150</b></div>

        <div class="receipt_cta">
          <button @click="confirm_card_payment = true, show_card = false">
            Yes
          </button>
          <button @click="show_card = false">
            No
          </button>
          <!-- Account,
                Narration = "Request for debit card",
                Type = "UUUT",
                Amount = 0.00,
                Fee = 0.00,
                Pin, -->
        </div>
      </div>
    </div>
    <!-- Confirmation Modal for Transfer to Hope, OtherAccount and Schedule transfer to hope account -->
    <b-modal id="modal-center" centered title="Confirm Transactions" v-model="confirm_card_payment" @show="resetModal"
      @hidden="resetModal" @ok="handleOk" hide-footer>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
          <p class="confirm-amount">
            You are about to Pay
            <span>
              <span>&#8358;</span>
              2,150
            </span>
            from <span>{{ accountId }}</span> for your debit card

          </p>
          <h1 class="pin-info text-center">Enter PIN</h1>
          <div class="dialpad">
            <div class="form-group">
              <the-mask type="password" class="form-control" v-model="pin" mask="########" id="txtBox"
                placeholder="******" />
            </div>
            <Keyboard @pressed="pin = $event" :pinLength="pin" @completed="requestCard" :selfValue="pin"></Keyboard>
          </div>
        </b-form-group>
      </form>
    </b-modal>
    <b-modal id="modal-center2" centered v-model="transaction_successful" @show="resetModal" @hidden="resetModal"
      @ok="handleOk" hide-footer>
      <!-- <form ref="form" @submit.stop.prevent="handleSubmit"> -->
      <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
        <img src="../../assets/images/icons/check.svg" alt="Hope Bank" class="modal_congrats_img" />
        <h1 class="pin-info text-center">Your transaction was successful</h1>
        <div class="line4"></div>
        <p class="modal_congrats">
          Some transactions take a few minutes to reflect
        </p>
        <!--<router-link to="Complete"><button class="ok-btn">OK</button></router-link>-->
        <!--  <div class="send-or-download2">
            <div>
              <button
                class="download-btn2"
                @click.prevent="ViewReceipt(ResponseData.data)"
              >
                View Receipt
                <font-awesome-icon icon="angle-right" class="download-icon2" />
              </button>
            </div>
            <div>
              <button class="download-btn2" @click.prevent="AddBeneficiary">
                Save Beneficiary
                <font-awesome-icon icon="angle-right" class="download-icon2" />
              </button>
            </div>
          </div> -->
      </b-form-group>
      <!-- </form> -->
    </b-modal>
    <TransactionReceipt v-if="showModal_receipt" @closeModal="showModal_receipt = false" />

  </div>
</template>

<script>
import HeaderNavDashboard from "../../components/HeaderNav/HeaderNavDashboard";
import HeaderNav3 from "../../components/HeaderNav/HeaderNav3";

import { mapActions, mapGetters, mapState } from "vuex";
import { TransactionHistoryService } from "../../services/TransactionHistory.services.js";
import { userService } from "../../services/user.service";
import Loader from "../../components/Loader/Loader.vue";
// import {getInstitutionService} from '../../services/getInstitution.services.js';
import { Carousel3d, Slide } from "vue-carousel-3d";
import Controls from "../../components/Controls/Controls";
import Footer2 from "../../components/Footer/Footer2";
import TransactionReceipt from "../../components/Receipt/TransactionReceipt.vue";
import Axios from "axios";
import Keyboard from "../../components/Keyboard/Keyboard.vue";
//import { CardService } from "../../services/card.service";

export default {
  name: "Overview.vue",

  components: {
    HeaderNavDashboard,
    HeaderNav3,
    Loader,
    Carousel3d,
    Slide,
    Controls,
    Footer2,
    TransactionReceipt,
    Keyboard
  },
  data() {
    return {
      loading: false,
      defaultAccount: "",
      transHistories: [],
      carouselControls: true,
      showModal_receipt: false,
      accountId: '',
      show_card: false,
      submittedNames: [],
      confirm_card_payment: false,
      transaction_successful: false,
      pin: ''

    };
  },

  computed: {
    ...mapGetters({ user: "GET_USER" }),
    ...mapState({
      BVN: (state) => state.User.BVN,
      AUTHENTICATION_TOKEN: (state) => state.User.AUTHENTICATION_TOKEN,
    }),

    transHistory: function () {
      if (this.defaultAccount === "") {
        return this.transHistories;
      }
      let history = this.transHistories.filter(
        (trans) => trans.account == this.defaultAccount
      );
      return history;
    },

    routeQuery() {
      return this.$route.query.path;
    },
  },
  mounted() {
    if (this.routeQuery == "register") return this.$router.push("/register_");
    this.getTransactionHistory();
    // this.getInstitution();
  },
  methods: {
    ...mapActions({ requestCardNow: "REQUEST_CARD" }),
    async requestCard() {
      this.loading = true;
      await this.requestCardNow({
        Account: this.accountId,
        Narration: 'Request for debit card',
        Type: 'UUUT',
        Amount: 2150.00,
        Fee: 0.00,
        Pin: this.pin,
      })
        .then(() => {
          this.transaction_successful = true
          this.loading = false;
        })
        .catch((error) => {
          this.$toastr.error(error.status, "Request Failed!", { timeOut: 5000 });
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid ? "valid" : "invalid";
      return valid;
    },
    resetModal() {
      this.password = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs.modal.hide();
      });
    },
    sendEmail() {
      Axios.post(`https://hopemail.tm-dev.xyz/service/send-mail/`, {
        name: this.user.fullName,
        phone_number: this.user.phone,
        account_no: this.accountId,
        email_from: this.user.email,
      })
        .then((res) => {
          window.console.log(res)
          this.$toastr.success("Debit Card Request Sent")
          this.show_card = false

        })
        .catch((err) => {
          window.console.log(err)
          this.$toastr.error("Debit Card Request Not Sent")

        })
    },
    getAccountId(index) {
      this.accountId = index
      window.console.log(this.accountId)
    },
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },
    transactionDetails(n) {
      this.$store.commit("SET_TRANSACTION_DATA", n);
      this.showModal_receipt = true
      //this.$router.push("Receipt");
    },
    async getTransactionHistory() {
      this.loading = true;
      await TransactionHistoryService.transHistory()
        .then((res) => {
          this.loading = false;
          this.transHistories = res;
          // window.console.log("this is the trans history",res)
        })
        .catch((err) => {
          window.console.log(err);
          this.loading = false;
          this.$toastr.error(err.status, "Error!", { timeOut: 5000 });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async setDefaultAccount() {
      this.loading = true;
      await userService
        .defaultAccount({
          Token: this.AUTHENTICATION_TOKEN,
          Account: this.defaultAccount,
        })
        .then((res) => {
          window.console.log(res);
          this.$toastr.success(res.status || "Default Account set Successful", {
            timeOut: 5000,
          });
          this.confirm_transaction_own = false;

          // this.$store.commit("SET_BILL_DATA",{})
        })
        .catch((error) => {
          this.$toastr.error(error.status, " Failed!", { timeOut: 5000 });
          // this.$store.commit("SET_BILL_DATA",{})
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // calculateTotal(){

    //     let amount = this.user.accounts[0].balance + this.user.accounts[1].balance + this.user.accounts[2].balance
    //     window.console.log("+++++++++",this.formatAmount(amount))
    //     let TotalAmount = amount
    //     return this.formatAmount(TotalAmount)

    // },
    formatAmount(value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    props: {
      controlsVisible: {
        type: Boolean,
        default: true,
      },
      controlsPrevHtml: {
        type: String,
        default: "&lsaquo;",
      },
      controlsNextHtml: {
        type: String,
        default: "&rsaquo;",
      },
      controlsWidth: {
        type: [String, Number],
        default: 50,
      },
      controlsHeight: {
        type: [String, Number],
        default: 50,
      },
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

/* .carousel-home-resources .left-1 {
  transform: translateX(-390px) translateZ(-300px) rotateY(-30deg) !important;
}
.carousel-home-resources .right-1 {
  transform: translateX(390px) translateZ(-300px) rotateY(30deg) !important;
} */

.navbar {
  padding: 0;
}

.req_btn {
  border: 0px solid none;
  margin-top: 10px;
  border-radius: 5px;
  background: white
}

.modal_card {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.476);
  z-index: 10000;
  display: grid;
  place-items: center;
}

.modal_card_inner {
  padding: 40px;
  background: white;
  border-radius: 20px
}

.receipt_cta {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.receipt_cta button {
  padding: 10px;
  width: 100%;
  color: #FFF;
  font-weight: 700;
  border-radius: 10px;
  background: #E6AA02;
}

.receipt_cta button:first-child {
  margin-right: 20px;
  background: none;
  color: #E6AA02;
  border: 1px solid #E6AA02;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000 !important;
  display: flex;
  align-items: center;
  margin: 0 1rem;
  font-family: Montserrat-Bold;
  font-size: 12px;
  letter-spacing: 0.17px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
}

.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}

.savings_currents {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: 0 2px 2px 0 rgba(0,0,0,0.10); */
}

.savings-current {
  font-family: Montserrat-Medium;
  font-size: 15px;
  color: #4a4a4a;
  letter-spacing: 0.18px;
  margin-bottom: 0;
}

.savings-current-amount {
  font-family: Montserrat-Medium;
  font-size: 18px;
  font-weight: 700;
  color: #4a4a4a;
  letter-spacing: 0.18px;
  margin-bottom: 0;
}

.terms_button {
  border: 1px solid #000 !important;
  color: #000 !important;
}

.login-history {
  height: 250px;
  overflow: hidden;
  overflow-y: scroll
}
</style>

<style lang="scss">
.dropdown {
  &__header {
    cursor: pointer;

    /*line-height: 50px;*/
    /*padding-left: 10px;*/
    /*padding-right: 50px;*/
    /*position: relative;*/
    /*text-overflow: ellipsis;*/
    i.fa {
      position: absolute;
      /*right: 10px;*/
      top: 50%;
      width: 50px;

      transition: opacity 0.3s;

      &.fa-angle-up {
        opacity: 0;
      }
    }

    &.is-active {
      i.fa {
        &.fa-angle-up {
          opacity: 1;
        }

        &.fa-angle-down {
          opacity: 0;
        }
      }

      +.dropdown__content {
        height: auto;
        opacity: 1;
        visibility: visible;
        position: absolute;
        z-index: 5;
        background: #ffffff;
        width: 230px;
      }
    }
  }

  &__content {
    height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 15px 10px;
    transition: opacity 0.3s;
    visibility: hidden;
  }
}
</style>