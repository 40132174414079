<template>
  <div class="about-page">
    <div class="about-page-header">
      <HeaderNavA />

      <div class="about-title">
        <h1>Terms and Conditions</h1>
      </div>
    </div>
    <div class="container">
      <div class="terms-condition-texts">
        <div id="terms-and-conditions">
          <strong>Terms and Conditions</strong>
          <p>
            <b>1. PURPOSE</b>
          </p>
          <p>
              The purpose of these general terms and conditions (”these Terms”) is to regulate the modalities for accessing and operating the internet, on line, and/ or mobile services (HOPE PSBANK Services) detailed below and under article 5 provided by HOPE PAYMENT SERVICE BANK LIMITED (”HOPE PSBANK”?) , and indicate the means by which HOPE PSBANK Customers, may access and use HOPE PSBANK Services.
              <br />Through HOPE PSBANK Services the Customer may access some information and carry out some transactions as indicated herein, from his HOPE PSBANK Private Data Storage Space defined under article 3.3.
          </p>
          <p>HOPE PSBANK Services include the following services, as detailed under article 5:</p>
          <ul>
              <li>Digital inquiries on any account(s) held by the Customer at HOPE PSBANK, HOPE PSBANK account and associated service(s).</li>
              <li>Digital management of any account(s) held by the Customer at HOPE PSBANK and associated service(s);</li>
              <li>HOPE PSBANK accounts</li>
              <li>Other services.</li>
          </ul>
          <p>HOPE PSBANK Services is governed by these Terms, especially with respect to security and, if need be, to directives provided in the customer’s HOPE PSBANK Private Data Storage Space or directly issued to the Customer as and when required.</p>
          <p>HOPE PSBANK reserves the right to reject or not to act on any request for a product, service or HOPE PSBANK Services transaction, without having to give any reason for such refusal and/or provide any justification thereof.</p>
          <p>
              <b>2. CONTRACTUAL DOCUMENTS</b>
          </p>
          <p>HOPE PSBANK Services are governed by these Terms, which are known to the Customer as hereby confirmed by him/her, and which he/she accepts without any reservation. The contract terms and conditions applicable to the customer’s account(s) & HOPE PSBANK Mobile digital accounts shall remain in force. Where there is conflict between different applicable contract documents, the terms and conditions governing the account shall prevail.</p>
          <p>
              <b>3. CONDITIONS FOR SUBSCRIPTION TO HOPE PSBANK SERVICES</b>
          </p>
          <p>3.1 Conditions for subscription to HOPE PSBANK Services</p>
          <p>HOPE PSBANK Services are accessible by any natural or corporate persons, holding one or several deposit, savings or current accounts in HOPE PSBANK’s books (’the Customer’). HOPE PSBANK Services are not open to joint accounts or accessible to Customers who are not personal holders of their accounts, even if they hold any power of attorney or any type of delegated authority on the account(s).</p>
          <p>3.2 Rules of subscription to HOPE PSBANK Services and acceptance of these general terms and conditions.</p>
          <p>Subscription to HOPE PSBANK Services is done upon the opening of an account at HOPE PSBANK or creating of a HOPE PSBANK mobile digital account, except in the event of refusal by the Customer.</p>
          <p>The Customer may also apply for his subscription to HOPE PSBANK Services by filling out a form which he may collect at any HOPE PSBANK branch office, download from the HOPE PSBANK web site or activate the HOPE PSBANK Mobile App; the completed form if obtained from the branch office shall be returned to the Customer’s HOPE PSBANK branch office.</p>
          <p>These Terms shall be accepted by the Customer through his signing of a HOPE PSBANK account opening form, or the form especially designed for subscription to HOPE PSBANK Services and the use or filling of the details on any of the Digital Platforms shall be deemed as an acceptance of these Terms by the Customer.</p>
          <p>HOPE PSBANK reserves the right to reject or not to act on any subscription request to HOPE PSBANK Services, without having to give any reason and/or justification thereof.</p>
          <p>3.3 Procedure for subscription to HOPE PSBANK Services and creation of the Customer’s HOPE PSBANK Private Data Storage Space.</p>
          <p>The Customer fills out his/her information on the HOPE PSBANK Platform or fills it out on the form to be returned to the Customer’s HOPE PSBANK branch office, whereby he/she will provide HOPE PSBANK with some personal information, especially the following:</p>
          <ul>
              <li>Deposit account number,</li>
              <li>E-mail address,</li>
              <li>Mobile phone number.</li>
          </ul>
          <p>As HOPE PSBANK accepts the Customer’s subscription request, HOPE PSBANK creates the Customer’s HOPE PSBANK Private Data Storage Space (the « HOPE PSBANK Private Data Storage Space ») and communicates to the Customer his/her username together with the Customer’s alphanumeric password, whereby the Customer may connect to his HOPE PSBANK Private Data Storage Space. The username and the password are communicated to the Customer.</p>
          <p>
              The Customer’s password makes up the Customer’s Personal Security Package.
              Communication of exact and complete information is absolutely necessary for provision of the HOPE PSBANK Services.
          </p>
          <p>
              <b>3.4 Updating of Customer’s data</b>
          </p>
          <p>
              The Customer undertakes to inform HOPE PSBANK of any changes in his/her personal data throughout his/her subscription to the HOPE PSBANK Platform. The Customer’s contact information may be updated by the Customer from his HOPE PSBANK Private Data Storage Space. Other personal information which cannot be modified from the Customers HOPE PSBANK Private Data Storage Space (especially marital status data) and any modification in this respect must be made at the Customers HOPE PSBANK branch office.
              The Customer may change his/her pin on Mobile via USSD provided that the Customer remembers his/her old pin, if not the Customer will have to perform a pin reset.
          </p>
          <p>
              <b>4. REQUIREMENTS FOR ACCESS TO HOPE PSBANK SERVICES</b>
          </p>
          <p>4.1 Means of access to HOPE PSBANK Services</p>
          <p>Access to HOPE PSBANK Services requires a terminal connected to the Web. However access to the HOPE PSBANK Mobile app may be accessed without an internet connection. The purchase or lease of a terminal, its installation and maintenance , as well as internet access and user fees (especially contracting with a service provider), are to be borne by the Customer, and shall not be charged to HOPE PSBANK in any event whatsoever. The Customer is liable to attend to the compatibility of the hardware and software which will be used for access to HOPE PSBANK Services.</p>
          <p>Access to HOPE PSBANK Mobile App requires data to be downloaded, the purchase or lease of data as well as data access are to be borne by the customer, and shall not be charged to HOPE PSBANK in any event whatsoever. The Customer is liable to attend to the compatibility of the hardware and software which will be used for access to HOPE PSBANK Platform.</p>
          <p>
              Access to HOPE PSBANK Services requires an Internet browser or mobile operating system which is compliant with market standard security norms. The choice and installation of the Web browser or operating system shall be the responsibility of the Customer, and not HOPE PSBANK in any circumstance.
              <br />
          </p>
          <p>The technical and operating specifications of the HOPE PSBANK Services may be subjected to further developments. In this case the Customer shall be personally responsible for the upgrading of his hardware and software.</p>
          <p>The Customer undertakes to inform HOPE PSBANK if he detects a website or app which attempts to counterfeit any of HOPE PSBANK Services.</p>
          <p>4.2 Access to HOPE PSBANK Services.</p>
          <p>HOPE PSBANK Services can only be accessed by connection through the HOPE PSBANK Website. Customer cannot connect from any other address. HOPE PSBANK Mobile App can only be accessed on the IOS store and Google Play store.</p>
          <p>HOPE PSBANK Services are made secure by end-to-end encryption of data transmission. The Customer must ensure that when connecting, the mention of « https » and a padlock are displayed in his browser tool bar.</p>
          <p>In the event of changes in the HOPE PSBANK Web site address, HOPE PSBANK shall inform the Customer as appropriate.</p>
          <p>HOPE PSBANK will devote its best efforts to ensure that HOPE PSBANK Services operate in normal and satisfactory conditions of security, by putting in place appropriate technical and organizational means. However HOPE PSBANK cannot, in view of maintenance requirements and incidents which may arise, guarantee an uninterrupted functioning of the HOPE PSBANK Services.</p>
          <p>4.3 Connection to the Customer’s HOPE PSBANK Private Data Storage Space, and Disconnection.</p>
          <p>Access to the Customer’s HOPE PSBANK Private Data Storage Space is strictly reserved for the Customer. The Customer guarantees the confidentiality of his username and or phone number and Personal Security Package, and forbids their use by anybody else. The Customer must ensure that the keyboarding and checking processes of his username and or phone number and Personal Security Package are carried out in perfect conditions of confidentiality and security.</p>
          <p>HOPE PSBANK reserves the right to restrict access to the Customer’s HOPE PSBANK Private Data Storage Space for some operations (i.e. access to accounts data) when security risks are detected.</p>
          <p>From the Customer’s HOPE PSBANK Private Data Storage Space, the Customer may carry out operations under the HOPE PSBANK Services in accordance with the options selected by the Customer.</p>
          <p>To exit from the HOPE PSBANK Services, the Customer must click on the log/sign out key on the mobile app button or web page which closes access to the HOPE PSBANK Services. The Customer undertakes not to exit from the HOPE PSBANK Services in any other way; especially, the Customer should not be satisfied with closing or reducing the windows.</p>
          <p>The HOPE PSBANK Services are disconnected automatically after an idle period of 5 minutes.</p>
          <p>4.4 Theft, loss, forgetting or diversion of the username and/or the Personal Security Package of the Customer.</p>
          <p>The Customer must immediately inform HOPE PSBANK by phone, e-mail, fax, and report to a HOPE PSBANK branch office, in case of theft, loss, forgetting or diversion of his username or Personal Security Package. HOPE PSBANK shall deny access to the Customer’s HOPE PSBANK Private Data Storage Space and shall indicate by e-mail or phone number to the Customer what procedure to follow to renew his Personal Security Package.</p>
          <p>
              <b>5. OPERATING RULES OF THE HOPE PSBANK SERVICES</b>
          </p>
          <p>5.1 General Rules</p>
          <p>Orders and requests for services passed on a working day by the Customer from the Customer’s HOPE PSBANK Private Data Storage Space are deemed to be received by HOPE PSBANK on the same day. However any order or request for services passed on a non-working day or after the HOPE PSBANK closing hours shall be deemed to have been received by HOPE PSBANK on the following working day.</p>
          <p>Orders and requests for services are executed by HOPE PSBANK in the terms and time provided for in the applicable agreements, completed by these Terms and the directives indicated to the Customer by HOPE PSBANK. HOPE PSBANK also reserves the right not to act upon any order or request for services, especially in case of insufficient funds, for security reasons or when there is a suspicion that the requested operation might not be authorized or might be fraudulent.</p>
          <p>5.2 Specific rules applicable to enquiry and information services.</p>
          <p>The Customer may carry out the following operations through the HOPE PSBANK Services, in the conditions specified below:</p>
          <ul>
              <li>Display the details of his deposit accounts(s) if need be, and especially use limits, balances, real time movements on the account(s) except for operations in process;</li>
              <li>
              Display the statements of accounts:
              <br />The Customer may display on his HOPE PSBANK Private Data Storage Space the statements of accounts as they may be available in hard copy in HOPE PSBANK branch offices.
              </li>
              <li>
              Download statements on a specific period:
              <br />The Customer may download his statements of accounts for a specific period chosen by him under the available formats as indicated on his HOPE PSBANK Private Data Storage Space. To this end, the Customer must comply with the directives displayed on his HOPE PSBANK Private Data Storage Space.
              <br />Information displayed on the Customer’s HOPE PSBANK Private Data Storage Space may be temporary. Accessible information on Customer’s HOPE PSBANK Private Data Storage Space is provided for information. Only hard copy statements available in HOPE PSBANK branch offices are final.
              </li>
          </ul>
        </div>
        
      </div>

      <back-to-top bottom="50px" right="50px">
        <button type="button" class="btn backtotop btn-to-top">
          <font-awesome-icon icon="chevron-up" class />
        </button>
      </back-to-top>
    </div>

    <Footer />
  </div>
</template>

<script>
import HeaderNavA from "../../components/HeaderNav/HeaderNavA";
import BackToTop from "vue-backtotop";
import Footer from "../../components/Footer/Footer";
// import terms from "../../components/Terms/terms";
export default {
  name: "About.vue",
  components: {
    HeaderNavA,
    BackToTop,
    Footer
    // terms
  }
};
</script>