<template>
  <div class>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNavBiz />

      <div class="container">
        <BusinessNavBar />

        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <img src="../../../../assets/images/icons/transfer-sm.svg" alt="Hope Bank" />
                  <p class="frequently-used">Confirm Transaction</p>
                </div>
                <div>
                  <b-tabs no-body v-model="tabIndex" class="generictab" ref="wizard">
                    <!-- Schedule Transaction to own account -->
                    <b-tab :active="one" :title-link-class="disabledTab">
                      <template v-slot:title>
                        To
                        <br />
                        <strong>Own Account</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Schedule Funds Transfer to Own Account</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Please confirm your Transaction Schedule Details</p>
                        </div>
                        <form action>
                          <div class="form-table2">
                            <div class="label-title2">
                              <label>Debit From</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{TRANSFER_ACCOUNT_FROM}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Bank</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">Hope Bank</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Number</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{TRANSFER_ACCOUNT_TO}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Names</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{user.accounts[0].accountName}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  <span>&#8358;</span>
                                  {{TRANSFER_AMOUNT}}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction Note</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{TRANSFER_NOTE}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction Schedule Name</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{SCHEDULE_NAME}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction Frequency</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{FREQUENCY}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction Start Date</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{START}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction End Date</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{END}}</div>
                              </div>
                            </div>
                          </div>
                          <div class="send-or-download2">
                            <div class="continue-button">
                              <button
                                class="continue-cib-btn"
                                @click="$router.push('ManageSchedule?p=1')"
                              >
                                BACK
                                <font-awesome-icon icon="angle-left" class="angle-icon" />
                              </button>
                            </div>
                            <div class="continue-button">
                              <b-button
                                @click="confirm_transaction_own = true"
                                class="continue-cib-btn"
                              >
                                SUBMIT
                                <font-awesome-icon icon="angle-right" class="angle-icon" />
                              </b-button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </b-tab>

                    <!-- Schedule Transaction to Hope Bank  -->
                    <b-tab :title-link-class="disabledTab" title="tab5" :active="two">
                      <template v-slot:title>
                        To
                        <br />
                        <strong>Hope Account</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Schedule Funds Transfer to Hope Account</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Please confirm your Transaction Schedule Details</p>
                        </div>
                        <form action>
                          <div class="form-table2">
                            <div class="label-title2">
                              <label>Debit From</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{TRANSFER_ACCOUNT}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Bank</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">Hope Bank</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Number</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{BENEFICIARY_ACCOUNT}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Names</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{BENEFICIARY_NAME}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  <span>&#8358;</span>
                                  {{TRANSFER_AMOUNT}}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction Note</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{TRANSFER_NOTE}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction Schedule Name</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{SCHEDULE_NAME}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction Frequency</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{FREQUENCY}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction Start Date</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{START}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction End Date</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{END}}</div>
                              </div>
                            </div>
                          </div>
                          <div class="send-or-download2">
                            <div class="continue-button">
                              <button
                                class="continue-cib-btn"
                                @click="$router.push('ManageSchedule?p=2')"
                              >
                                BACK
                                <font-awesome-icon icon="angle-left" class="angle-icon" />
                              </button>
                            </div>
                            <div class="continue-button">
                              <b-button v-b-modal.modal-center class="continue-cib-btn">
                                SUBMIT
                                <font-awesome-icon icon="angle-right" class="angle-icon" />
                              </b-button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </b-tab>

                    <!-- Schedule Transaction to other Bank -->
                    <b-tab :title-link-class="disabledTab" title="tab3" :active="three">
                      <template v-slot:title>
                        To
                        <br />
                        <strong>Other Bank</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Schedule Funds Transfer to Own Account</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Please confirm your Transaction Schedule Details</p>
                        </div>
                        <form action>
                          <div class="form-table2">
                            <div class="label-title2">
                              <label>Debit From</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{TRANSFER_ACCOUNT}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Bank</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{BENEFICIARY_BANK.label}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Number</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{BENEFICIARY_ACCOUNT}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Names</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{BENEFICIARY_NAME}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  <span>&#8358;</span>
                                  {{TRANSFER_AMOUNT}}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction Note</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{TRANSFER_NOTE}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction Schedule Name</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{SCHEDULE_NAME}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction Frequency</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{FREQUENCY}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction Start Date</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{START}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction End Date</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{END}}</div>
                              </div>
                            </div>
                          </div>
                          <div class="send-or-download2">
                            <div class="continue-button">
                              <!--<b-button v-b-modal.modal-center class="continue-cib-btn">BACK <font-awesome-icon icon="angle-right" class="angle-icon"/></b-button>-->
                              <button
                                class="continue-cib-btn"
                                @click="$router.push('ManageSchedule?p=3')"
                              >
                                BACK
                                <font-awesome-icon icon="angle-left" class="angle-icon" />
                              </button>
                            </div>
                            <div class="continue-button">
                              <b-button v-b-modal.modal-center class="continue-cib-btn">
                                SUBMIT
                                <font-awesome-icon icon="angle-right" class="angle-icon" />
                              </b-button>
                              <!--<button class="continue-cib-btn">CONTINUE <font-awesome-icon icon="angle-right" class="angle-icon"/></button>-->
                            </div>
                          </div>
                        </form>
                      </div>
                    </b-tab>

                    <b-tab :active="four" :title-link-class="disabledTab">
                      <template v-slot:title>
                        Schedule
                        <br />
                        <strong>Transaction</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Schedule Details</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>View Schedule Details</p>
                        </div>
                        <!-- <form action=""> -->
                        <div class="form-table2">
                          <div class="label-title2">
                            <label>Account</label>
                            <div class="account_select3">
                              <div class="confirm-account-info">{{CURRENT_SCHEDULE.account}}</div>
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>Beneficiary</label>
                            <div class="account_select3">
                              <div class="confirm-account-info">{{CURRENT_SCHEDULE.beneficiary}}</div>
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>Schedule Name</label>
                            <div class="account_select3">
                              <div class="confirm-account-info">{{CURRENT_SCHEDULE.name}}</div>
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>Amount</label>
                            <div class="account_select3">
                              <div class="confirm-account-info">
                                <span>&#8358;</span>
                                {{CURRENT_SCHEDULE.amount}}
                              </div>
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>Frequency</label>
                            <div class="account_select3">
                              <div class="confirm-account-info">{{CURRENT_SCHEDULE.frequency}}</div>
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>Next Run Date</label>
                            <div class="account_select3">
                              <div class="confirm-account-info">{{CURRENT_SCHEDULE.nextRunDate}}</div>
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>End Date</label>
                            <div class="account_select3">
                              <div class="confirm-account-info">{{CURRENT_SCHEDULE.endDate}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="send-or-download2">
                          <div class="continue-button">
                            <a @click="$router.go(-1)">
                              <b-button class="continue-cib-btn">
                                <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                              </b-button>
                            </a>
                          </div>
                          <div class="continue-button">
                            <!--<b-button v-b-modal.modal-center class="continue-cib-btn">BACK <font-awesome-icon icon="angle-right" class="angle-icon"/></b-button>-->
                            <button
                              class="continue-cib-btn"
                              @click="$router.push('ManageSchedule?p=4')"
                            >
                              SUBMIT
                              <font-awesome-icon icon="angle-left" class="angle-icon" />
                            </button>
                          </div>
                        </div>
                        <!-- </form> -->
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar
                v-if="TRANSFER_ACCOUNT_FROM"
                :accountNumber="TRANSFER_ACCOUNT_FROM"
                :accountBalance="Balance"
              ></SideBar>
              <SideBar
                v-if="TRANSFER_ACCOUNT"
                :accountNumber="TRANSFER_ACCOUNT"
                :accountBalance="Balance"
              ></SideBar>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>

      <!-- Confirmation Modal for Schedule transfer to hope and account -->
      <b-modal
        id="modal-center"
        centered
        title="Confirm Transactions"
        v-model="confirm_transaction"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <p class="confirm-amount">
              You are about to transfer
              <span>
                <span>&#8358;</span>
                {{TRANSFER_AMOUNT}}
              </span>
              from {{TRANSFER_ACCOUNT}} to
              <span>{{BENEFICIARY_NAME}}</span>
              - {{BENEFICIARY_ACCOUNT}}
            </p>
            <h1 class="pin-info text-center">Enter PIN</h1>
            <div class="dialpad">
              <div class="form-group">
                <the-mask
                  type="password"
                  class="form-control"
                  v-model="pin"
                  mask="######"
                  id="txtBox"
                  placeholder="******"
                />
              </div>
              <Keyboard
                @pressed="pin = $event"
                v-if="routeQuery == 1"
                :pinLength="pin"
                @completed="scheduletransferOwn"
                :selfValue="pin"
              ></Keyboard>
              <Keyboard
                @pressed="pin = $event"
                v-if="routeQuery == 2"
                :pinLength="pin"
                @completed="scheduletransferHope"
                :selfValue="pin"
              ></Keyboard>
              <Keyboard
                @pressed="pin = $event"
                v-if="routeQuery == 3"
                :pinLength="pin"
                @completed="scheduletransferOther"
                :selfValue="pin"
              ></Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>

      <!-- Confirmation modal for transfer to Own account -->
      <b-modal
        id="modal-center3"
        centered
        title="Confirm Transactions"
        v-model="confirm_transaction_own"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <p class="confirm-amount">
              You are about to transfer
              <span>
                <span>&#8358;</span>
                {{TRANSFER_AMOUNT}}
              </span>
              from {{TRANSFER_ACCOUNT_FROM}} to
              <span>{{user.accounts[0].accountName}}</span>
              - {{TRANSFER_ACCOUNT_TO}}
            </p>
            <h1 class="pin-info text-center">Enter PIN</h1>
            <div class="dialpad">
              <div class="form-group">
                <the-mask
                  type="password"
                  class="form-control"
                  v-model="pin"
                  mask="######"
                  maxlength="6"
                  id="txtBox"
                  placeholder="******"
                />
              </div>
              <Keyboard
                @pressed="pin = $event"
                :pinLength="pin"
                @completed="scheduletransferOwn"
                :selfValue="pin"
              ></Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>
      <!-- Transaction successful modal -->
      <b-modal
        id="modal-center2"
        centered
        v-model="transaction_successful"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
      >
        <!-- <form ref="form" @submit.stop.prevent="handleSubmit"> -->
        <b-form-group
          :state="nameState"
          label-for="name-input"
          invalid-feedback="PIN is required"
          class="pin-info"
        >
          <img
            src="../../../../assets/images/icons/check.svg"
            alt="Hope Bank"
            class="modal_congrats_img"
          />
          <h1 class="pin-info text-center">Your transaction was successful</h1>
          <div class="line4"></div>
          <p class="modal_congrats">Some transaction takes a few minutes to reflect</p>
          <!--<router-link to="Complete"><button class="ok-btn">OK</button></router-link>-->
          <div class="send-or-download2">
            <router-link to="Dashboard">
              <button class="download-btn2">
                <font-awesome-icon icon="angle-left" class="download-icon" />HOME
              </button>
            </router-link>
            <button class="download-btn2" @click="$router.push('ManageSchedule')">
              SCHEDULE PAYMENT
              <font-awesome-icon icon="angle-right" class="download-icon2" />
            </button>
          </div>
        </b-form-group>
        <!-- </form> -->
      </b-modal>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import HeaderNavBiz from "../../../../components/HeaderNav/HeaderNavBiz";
import BusinessNavBar from "../../../../components/HeaderNav/BusinessNavBar";
import SideBar from "../../../../components/SideBar/SideBar_";
import { mapGetters, mapState } from "vuex";
import Loader from "../../../../components/Loader/Loader.vue";
import Keyboard from "../../../../components/Keyboard/Keyboard.vue";
import Footer3 from "../../../../components/Footer/Footer3";

export default {
  name: "Confirmation.vue",
  components: {
    TheMask,
    HeaderNavBiz,
    BusinessNavBar,
    SideBar,
    Loader,
    Keyboard,
    Footer3,
  },
  data() {
    return {
      tabIndex: 0,
      password: "",
      nameState: null,
      submittedNames: [],
      one: false,
      two: false,
      three: false,
      four: false,
      pin: "",
      loading: false,
      transaction_successful: false,
      confirm_transaction: false,
      confirm_transaction_own: false,
      Account: null,
      Beneficiary: null,
      BeneficiaryName: null,
      Narration: null,
      Amount: null,
      // Type:null,
      ScheduleName: null,
      Frequency: null,
      Start: null,
      End: null,
    };
  },
  computed: {
    ...mapGetters({ user: "GET_USER" }),

    ...mapState({
      AUTHENTICATION_TOKEN: (state) => state.User.AUTHENTICATION_TOKEN,
      TRANSFER_ACCOUNT: (state) => state.Transfer.TRANSFER_ACCOUNT,
      TRANSFER_ACCOUNT_FROM: (state) => state.Transfer.TRANSFER_ACCOUNT_FROM,
      TRANSFER_ACCOUNT_TO: (state) => state.Transfer.TRANSFER_ACCOUNT_TO,
      BENEFICIARY_ACCOUNT: (state) => state.Transfer.BENEFICIARY_ACCOUNT,
      BENEFICIARY_NAME: (state) => state.Transfer.BENEFICIARY_NAME,
      BENEFICIARY_BANK: (state) => state.Transfer.BENEFICIARY_BANK,
      TRANSFER_AMOUNT: (state) => state.Transfer.TRANSFER_AMOUNT,
      //    TRANSFER_TYPE : state => state.Transfer.TRANSFER_TYPE,
      TRANSFER_NOTE: (state) => state.Transfer.TRANSFER_NOTE,
      SCHEDULE_NAME: (state) => state.Transfer.SCHEDULE_NAME,
      FREQUENCY: (state) => state.Transfer.FREQUENCY,
      START: (state) => state.Transfer.START,
      END: (state) => state.Transfer.END,
      CURRENT_SCHEDULE: (state) => state.Transfer.CURRENT_SCHEDULE,
    }),

    routeQuery() {
      return this.$route.query.p;
    },

    disabledTab() {
      return ["disabled"];
    },

    Balance() {
      if (this.TRANSFER_ACCOUNT_FROM)
        return this.user.accounts[
          this.user.accounts.findIndex(
            (a) => a.accountNumber == this.TRANSFER_ACCOUNT_FROM
          )
        ].balance;
      return this.user.accounts[
        this.user.accounts.findIndex(
          (a) => a.accountNumber == this.TRANSFER_ACCOUNT
        )
      ].balance;
    },
  },

  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid ? "valid" : "invalid";
      return valid;
    },
    resetModal() {
      this.password = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs.modal.hide();
      });
    },
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },

    //Schedule transaction to Hope account
    scheduletransferHope() {
      // alert('hope')
      this.loading = true;
      this.$store
        .dispatch("SCHEDULE_TRANSFER_HOPE", {
          Pin: this.pin,
          token: this.AUTHENTICATION_TOKEN,
        })
        .then((res) => {
          this.transaction_successful = true;
          this.$store.dispatch("CLEAR_INPUTS");
          this.$toastr.success(res.status, { timeOut: 5000 });
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Transaction Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
          this.pin = "";
          this.confirm_transaction = false;
          this.$router.push("ManageSchedule?p=4");
        });
    },

    //Schedule transaction to Own account
    scheduletransferOwn() {
      //alert('own')
      this.loading = true;
      this.$store
        .dispatch("SCHEDULE_TRANSFER_OWN", {
          Pin: this.pin,
          token: this.AUTHENTICATION_TOKEN,
          BeneficiaryName: this.user.accounts[0].accountName,
        })
        .then((res) => {
          this.transaction_successful = true;
          this.$store.dispatch("CLEAR_INPUTS");
          this.$toastr.success(res.status, { timeOut: 5000 });
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Transaction Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
          this.pin = "";
          this.confirm_transaction_own = false;
          this.$router.push("ManageSchedule?p=4");
        });
    },

    //Schedule transaction to Other account
    scheduletransferOther() {
      this.loading = true;
      this.$store
        .dispatch("SCHEDULE_TRANSFER_OTHER", {
          Pin: this.pin,
          token: this.AUTHENTICATION_TOKEN,
        })
        .then((res) => {
          this.transaction_successful = true;
          this.$store.dispatch("CLEAR_INPUTS");
          this.$toastr.success(res.status, { timeOut: 5000 });
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Transaction Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
          this.pin = "";
          this.confirm_transaction = false;
          this.$router.push("ManageSchedule?p=4");
        });
    },
  },
  mounted() {
    if (this.routeQuery == 1) return (this.one = true);
    if (this.routeQuery == 2) return (this.two = true);
    if (this.routeQuery == 3) return (this.three = true);
    if (this.routeQuery == 4) return (this.four = true);
  },
};
</script>

<style scoped>
/*body{*/
/*width: 100%;*/
/*height: 100vh;*/
/*background-image: url("../../assets/images/hope-bg3.jpg");*/
/*background-size: cover;*/
/*background-repeat: no-repeat;*/
/*}*/
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}
.quick-transfer {
  padding: 0 0.7rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: center;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #fecb32;
  border: 2px solid #fecb32;
}
.tabs-contents th {
  text-align: left;
  font-family: Montserrat-Medium;
  font-size: 12px;
  color: #000000;
  letter-spacing: 0.17px;
  background: #fecb32;
}
.confirm-amount {
  font-family: Montserrat-Medium;
  font-size: 15px;
  color: #4a4a4a;
  letter-spacing: 0;
  border-bottom: 1px solid #d0d0d0;
}
.confirm-amount span {
  font-family: Montserrat-Bold;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}

.disabledTab {
  pointer-events: none !important;
  background: red !important;
}
/* .poster {
  background-color: rgb(0, 94, 194, 0.1) !important;
} */

@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
