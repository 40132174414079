<template>
  <div class>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNavBiz />

      <div class="container">
        <BusinessNavBar />

        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <img src="../../../../assets/images/icons/transfer-sm.svg" alt="Hope Bank" />
                  <p v-if="TRANSACTION_DETAILS.action == 'CRA1'" class="frequently-used">
                    Confirm Account Creation
                  </p>
                  <p v-else class="frequently-used">Confirm Transaction</p>
                </div>
                <div>
                  <!-- <b-tabs no-body v-model="tabIndex" class="generictab" ref="wizard"> -->

                  <!-- <b-tab :active="two" title="tab2" :title-link-class="disabledTab"> -->
                  <!-- <template v-slot:title class="left">
                        To
                        <br />
                        <strong>Hope Bank</strong>
                  </template>-->
                  <div class="tabs-contents">
                    <div class="current_tab_title">
                      <font-awesome-icon icon="play" class="play-icon" />
                      <p class v-if="TRANSACTION_DETAILS.action == 'CRA1'">
                        Confirm Account Creation
                      </p>
                      <p class v-else>Confirm Funds Transfer</p>
                    </div>
                    <!-- <p class="frequently-used pt-2">Business Name</p> -->
                    <div class="kindly_enter_details">
                      <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                      <p>
                        Please confirm your Transactions Details before your
                        Approve
                      </p>
                    </div>
                    <form action @submit.prevent>
                      <div class="form-table2">
                        <div class="label-title2" v-if="(TRANSACTION_DETAILS.isSingle &&
                          TRANSACTION_DETAILS.action !== 'CRA1') ||
                          TRANSACTION_DETAILS.isBulk
                          ">
                          <div v-if="TRANSACTION_DETAILS.action != 'CRA1'" class="label-title2">
                            <label>Debit From</label>
                            <div class="account_select3">
                              <div class="confirm-account-info">
                                {{ TRANSACTION_DETAILS.account }}
                              </div>
                            </div>
                          </div>
                          <!-- Show  account creation Link for signatory -->
                          <div v-if="TRANSACTION_DETAILS.action == 'CRA1' &&
                            TRANSACTION_DETAILS.location
                            " class="">
                            <!-- <label>Link</label>
                            <div class="account_select3">
                              <a
                                :href="TRANSACTION_DETAILS.location"
                                target="_blank"
                                class="confirm-account-info"
                              >{{ TRANSACTION_DETAILS.location }}</a>
                            </div> -->
                            <a href="https://ibank.hopepsbank.com/corporate/docs/pending/">
                              <button class="continue-btn mt-2">
                                Download Validated File
                              </button>
                            </a>
                          </div>

                          <!-- Show  account creation Link for Checker -->
                          <div v-if="TRANSACTION_DETAILS.action == 'CRA1' &&
                            TRANSACTION_DETAILS.path
                            " class="">
                            <!-- <label>Link</label>
                            <div class="account_select3">
                              <a
                                :href="TRANSACTION_DETAILS.path"
                                target="_blank"
                                class="confirm-account-info"
                              >{{ TRANSACTION_DETAILS.path }}</a>
                            </div> -->

                            <a href="https://ibank.hopepsbank.com/corporate/docs/pending/">
                              <button class="continue-btn mt-2">
                                Download Validated File
                              </button>
                            </a>
                          </div>

                          <!-- <div class="kindly_enter_details text-right">
                            Click
                            <a
                              class="sample-download"
                              href="../../../../assets/sample/sample_bulk_transfer_transactions.xls"
                              download
                            >
                              <span style="color:blue; font-weight: 800;" class="ml-1 mr-1">here</span>
                            </a> to download uploaded file
                          </div> -->
                        </div>

                        <!-- Show transfer LInk For Bulk Transfer -->
                        <div class="label-title2" v-if="TRANSACTION_DETAILS.isBulk &&
                          TRANSACTION_DETAILS.action == 'TRM7'
                          ">
                          <!-- Link - transfer for Checker -->
                          <div class="" v-if="TRANSACTION_DETAILS.path">
                            <!-- <label>Link</label>
                            <div class="account_select3">
                              <a
                                :href="TRANSACTION_DETAILS.path"
                                target="_blank"
                                class="confirm-account-info"
                              >{{ TRANSACTION_DETAILS.path }}</a>
                            </div> -->
                            <a href="https://ibank.hopepsbank.com/corporate/docs/pending/">
                              <button class="continue-btn mt-2">
                                Download Validated File
                              </button>
                            </a>
                          </div>

                          <!-- Link  - transfer  for signatory -->
                          <div class="" v-else>
                            <!-- <label>Link</label>
                            <div class="account_select3">
                              <a
                                :href="TRANSACTION_DETAILS.location"
                                target="_blank"
                                class="confirm-account-info"
                              >{{ TRANSACTION_DETAILS.location }}</a>
                            </div> -->
                            <!-- <a download
                              :href="'https://ibank.hopepsbank.com/corporate/docs/pending/' + TRANSACTION_DETAILS.data.name + '.xlsx'"
                              target="_blank"> -->
                            <button @click="downloadFile" class="continue-btn mt-2">
                              Download Validated File
                            </button>
                            <!--                             </a>
 -->
                          </div>
                        </div>

                        <!-- Single Account Transfer -->
                        <div v-if="TRANSACTION_DETAILS.isSingle &&
                          TRANSACTION_DETAILS.action !== 'CRA1'
                          ">
                          <div class="label-title2">
                            <label>Bank</label>
                            <div class="account_select3">
                              <div class="confirm-account-info" v-if="TRANSACTION_DETAILS.data">
                                {{ TRANSACTION_DETAILS.data.beneficiaryBank }}
                              </div>
                              <div class="confirm-account-info" v-else>Nil</div>
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>Account Number</label>
                            <div class="account_select3">
                              <div class="confirm-account-info" v-if="TRANSACTION_DETAILS.data">
                                {{ TRANSACTION_DETAILS.data.beneficiary }}
                              </div>
                            </div>
                          </div>
                          <div class="label-title2">
                            <label>Account Names</label>
                            <div class="account_select3">
                              <div class="confirm-account-info" v-if="TRANSACTION_DETAILS.data">
                                {{ TRANSACTION_DETAILS.data.beneficiaryName }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- Single and Bulk Transfer -->
                        <div class="label-title2" v-if="TRANSACTION_DETAILS.isSingle &&
                          !TRANSACTION_DETAILS.action == 'CRA1'
                          ">
                          <div v-if="TRANSACTION_DETAILS.action != 'CRA1'">
                            <label>Amount</label>
                            <div class="account_select3">
                              <div class="confirm-account-info">
                                <!-- <span>&#8358;</span> -->
                                {{ TRANSACTION_DETAILS.amount }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Description</label>
                          <div class="account_select3">
                            <div class="confirm-account-info" v-if="TRANSACTION_DETAILS.data">
                              {{ TRANSACTION_DETAILS.data.narration }}
                            </div>
                            <div class="confirm-account-info" v-if="TRANSACTION_DETAILS.isBulk ||
                              TRANSACTION_DETAILS.action == 'CRA1'
                              ">
                              {{ TRANSACTION_DETAILS.description }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="send-or-download2">
                        <div class="continue-button">
                          <!--<b-button v-b-modal.modal-center class="continue-cib-btn">BACK <font-awesome-icon icon="angle-right" class="angle-icon"/></b-button>-->
                          <div class="continue-cib-btn" @click.prevent="goBack">
                            Back
                          </div>
                        </div>
                        <div class="d-flex">
                          <div class="continue-button">
                            <b-button @click="
                              isDecline = true;
                            isApprove = false;
                            reason_modal = true;
                            " class="continue-cib-btn mr-2">Decline</b-button>
                            <!--<button class="continue-cib-btn">CONTINUE <font-awesome-icon icon="angle-right" class="angle-icon"/></button>-->
                          </div>
                          <div class="continue-button">
                            <b-button @click="
                              isDecline = false;
                            isApprove = true;
                            pin_modal = true;
                            " class="continue-cib-btn">Approve</b-button>
                            <!--<button class="continue-cib-btn">CONTINUE <font-awesome-icon icon="angle-right" class="angle-icon"/></button>-->
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- </b-tab> -->
                  <!-- <b-tab :title-link-class="disabledTab" title="tab3" :active="three">
                      <template v-slot:title>
                        To
                        <br />
                        <strong>Other Bank</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Transfer Funds to Other Account</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Please confirm your Transactions Details</p>
                        </div>
                        <form action>
                          <div class="form-table2">
                            <div class="label-title2">
                              <label>Debit From</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{ TRANSFER_ACCOUNT }}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Bank</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{ BENEFICIARY_BANK.label }}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Number</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{ BENEFICIARY_ACCOUNT }}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Names</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{ BENEFICIARY_NAME }}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  <span>&#8358;</span>
                                  {{ TRANSFER_AMOUNT }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Description</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{ TRANSFER_NOTE }}</div>
                              </div>
                            </div>
                          </div>
                          <div class="send-or-download2">
                            <div class="continue-button">
                              
                              <button
                                class="continue-cib-btn"
                                @click="$router.push('transfer?p=3')"
                              >BACK</button>
                            </div>
                            <div class="d-flex">
                              <div class="continue-button">
                                <b-button
                                  v-b-modal.modal-center
                                  class="continue-cib-btn"
                                  @click="transferHope"
                                >APPROVE</b-button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                  </b-tab>-->

                  <!-- </b-tabs> -->
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar :accountNumber="TRANSACTION_DETAILS.account" :accountBalance="Balance" :accountName="Name">
              </SideBar>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>

      <!-- Confirmation Modal -->
      <b-modal id="modal-center3" centered title="Confirm Transactions" v-model="pin_modal" @ok="handleOk" hide-footer>
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <!-- <div @click="transferOwn" class="btn btn-warning">Confirm</div> -->
            <h1 class="pin-info text-center">Enter PIN</h1>
            <div class="dialpad">
              <div class="form-group">
                <the-mask type="password" class="form-control" v-model="pin" mask="########" minlength="6" maxlength="8"
                  placeholder="******" />
              </div>
              <Keyboard v-if="isApprove" @pressed="pin = $event" :pinLength="pin" @completed="Approve" :selfValue="pin">
              </Keyboard>
              <Keyboard v-if="isDecline" @pressed="pin = $event" :pinLength="pin" @completed="Decline" :selfValue="pin">
              </Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>

      <!-- Reason modal -->
      <b-modal id="modal-center3" centered v-model="reason_modal" @ok="handleOk" hide-footer>
        <form ref="form" @submit.prevent="Decline">
          <b-form-group :state="nameState" label-for="name-input" class="pin-info">
            <h1 class="pin-info text-center">Enter your Reason</h1>
            <div class="dialpad">
              <div class="form-group">
                <textarea type="text" class="form-control" v-model="reason" maxlength="200" id="txtBox" placeholder
                  required></textarea>
              </div>
              <input type="submit" class="btn continue-cib-btn" />
            </div>
          </b-form-group>
        </form>
      </b-modal>
      <!-- Transaction successful modal -->
      <b-modal id="modal-center2" centered v-model="transaction_successful" @show="resetModal" @hidden="resetModal"
        @ok="handleOk" hide-footer>
        <!-- <form ref="form" @submit.stop.prevent="handleSubmit"> -->
        <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
          <img src="../../../../assets/images/icons/check.svg" alt="Hope Bank" class="modal_congrats_img" />
          <h1 class="pin-info text-center">Your transaction was successful</h1>
          <div class="line4"></div>
          <p class="modal_congrats">
            Some transaction takes a few minutes to reflect
          </p>
          <!--<router-link to="Complete"><button class="ok-btn">OK</button></router-link>-->
          <div class="send-or-download2">
            <router-link to="dashboard_">
              <button class="download-btn2">
                <font-awesome-icon icon="angle-left" class="download-icon" />HOME
              </button>
            </router-link>
            <router-link to="manageschedule_">
              <button class="download-btn2">
                SCHEDULE PAYMENT
                <font-awesome-icon icon="angle-right" class="download-icon2" />
              </button>
            </router-link>
          </div>
        </b-form-group>
        <!-- </form> -->
      </b-modal>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import HeaderNavBiz from "../../../../components/HeaderNav/HeaderNavBiz";
import BusinessNavBar from "../../../../components/HeaderNav/BusinessNavBar";
import SideBar from "../../../../components/SideBar/SideBar_";
import { mapGetters, mapState } from "vuex";
import Loader from "../../../../components/Loader/Loader.vue";
import Keyboard from "../../../../components/Keyboard/Keyboard.vue";
//import { transferService } from "../../../../services/transfer.services.js";
import { CorporateAccountService } from "../../../../services/CorporateAccount/corporateAccountTransaction.services.js";
import Footer3 from "../../../../components/Footer/Footer3";
import axios from "axios";

export default {
  name: "Confirmation.vue",
  components: {
    TheMask,
    HeaderNavBiz,
    BusinessNavBar,
    SideBar,
    Loader,
    Keyboard,
    Footer3,
  },
  data() {
    return {
      pin: "",
      reason: "",
      reason_modal: false,
      isApprove: false,
      isDecline: false,
      nameState: null,
      pin_modal: false,
    };
  },

  computed: {
    ...mapGetters({
      TRANSACTION_DETAILS: "GET_TRANSACTION",
      user: "GET_USER",
      token: "GET_AUTH_TOKEN",
    }),
    ...mapState({
      token: (state) => state.User.AUTHENTICATION_TOKEN,
    }),
    routeQuery() {
      return this.$route.query.p;
    },
    Balance() {
      return this.user.corporateAccounts[
        this.user.corporateAccounts.findIndex(
          (a) => a.accountNumber == this.TRANSACTION_DETAILS.account
        )
      ].balance;
    },
    Name() {
      return this.user.corporateAccounts[
        this.user.corporateAccounts.findIndex(
          (a) => a.accountNumber == this.TRANSACTION_DETAILS.account
        )
      ].accountName;
    },
  },

  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid ? "valid" : "invalid";
      return valid;
    },
    goBack() {
      this.$router.go(-1);
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs.modal.hide();
      });
    },

    //download excel pending document
    downloadFile() {
      this.loading = true;
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
        responseType: "blob",
      };

      axios
        .get('https://ibank.hopepsbank.com/corporate/docs/pending/' + this.TRANSACTION_DETAILS.data.name + '.xlsx', config)
        .then((result) => {
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.xls"); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.$toastr.success("success", "Successful", {
            timeOut: 5000,
          });
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //Approve Transaction Checker/Signatory
    Approve() {
      this.loading = true;
      let data = {
        Pin: this.pin,
        RequestId: this.TRANSACTION_DETAILS.requestId,
        Corporate: this.TRANSACTION_DETAILS.corporate,
        Id: this.TRANSACTION_DETAILS.id,
        token: this.token,
      };
      CorporateAccountService.authorizeTransaction(data)
        .then((res) => {
          //window.console.log(res);
          this.$toastr.success(res.status, "Successful", {
            timeOut: 5000,
          });

          //this.transaction_successful = true;
          this.confirm_transaction = false;
          this.$store.dispatch(
            "AUTHORISE_USER",
            this.$store.getters.GET_AUTH_TOKEN
          );
          this.$router.push("/Dashboard_");
        })
        .catch((err) => {
          this.confirm_transaction = false;
          this.$toastr.error(err.status, "Transaction Failed!", {
            timeOut: 5000,
          });
          this.$router.push("/pending");
        })
        .finally(() => {
          this.loading = false;
          this.pin = "";
          this.confirm_transaction = false;
        });
    },

    //Decline Transaction Checker/Signatory
    Decline() {
      this.reason_modal = false;
      this.loading = true;
      let data = {
        Pin: this.pin,
        reason: this.reason,
        RequestId: this.TRANSACTION_DETAILS.requestId,
        Corporate: this.TRANSACTION_DETAILS.corporate,
        Id: this.TRANSACTION_DETAILS.id,
        token: this.token,
      };
      CorporateAccountService.declineTransaction(data)
        .then((res) => {
          //window.console.log(res);
          this.$toastr.success(res.status, "Successful", {
            timeOut: 5000,
          });

          //this.transaction_successful = true;
          this.confirm_transaction = false;
          this.$store.dispatch(
            "AUTHORISE_USER",
            this.$store.getters.GET_AUTH_TOKEN
          );
          this.$router.push("/Dashboard_");
        })
        .catch((err) => {
          this.reason_modal = false;
          this.$toastr.error(err.status, "Failed!", {
            timeOut: 5000,
          });
          this.$router.push("/pending");
        })
        .finally(() => {
          this.loading = false;
          this.pin = "";
          this.confirm_transaction = false;
        });
    },
  },
  mounted() { },
};
</script>

<style scoped>
/*body{*/
/*width: 100%;*/
/*height: 100vh;*/
/*background-image: url("../../assets/images/hope-bg3.jpg");*/
/*background-size: cover;*/
/*background-repeat: no-repeat;*/
/*}*/
a {
  text-decoration: none;
  color: #000000;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}

.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}

.quick-transfer {
  padding: 0 0.7rem;
}

.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}

.modal-header {
  padding: 1rem 1rem 0 !important;
}

.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}

.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: center;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}

.btn-secondary:hover {
  color: #000000;
  background: #fecb32;
  border: 2px solid #fecb32;
}

.tabs-contents th {
  text-align: left;
  font-family: Montserrat-Medium;
  font-size: 12px;
  color: #000000;
  letter-spacing: 0.17px;
  background: #fecb32;
}

.confirm-amount {
  font-family: Montserrat-Medium;
  font-size: 15px;
  color: #4a4a4a;
  letter-spacing: 0;
  border-bottom: 1px solid #d0d0d0;
}

.confirm-amount span {
  font-family: Montserrat-Bold;
}

.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}

.disabledTab {
  pointer-events: none !important;
  background: red !important;
}

.nav-tabs {
  border: none !important;
  -ms-flex-pack: distribute;
  justify-content: flex-start !important;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
  margin-right: 1rem !important;
}

/* .poster {
  background-color: rgb(0, 94, 194, 0.1) !important;
} */

@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
