<template>
  <div class>
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNav2></HeaderNav2>
      <div class="container">
        <HeaderNav3></HeaderNav3>
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <p class="frequently-used">Cheque Management</p>
                </div>
                <div class="data-or-airtime">
                  <b-tabs no-body>
                    <b-tab active>
                      <template v-slot:title>
                        <strong>Request Cheque</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p
                            class
                          >You need cheques to do business, to issue transactions without giving out cash</p>
                        </div>
                        <div class="kindly_enter_details mb-3">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Request for cheque book(s) by leaves and indicate how you want it delivered</p>
                        </div>
                        <form action>
                          <div class="form-table">
                            <div class="label-title2">
                              <label>Account</label>
                              <div class="account_select">
                                <select name id class="account_type">
                                  <option value class="choose_account">-- Select --</option>
                                  <option value class="choose_account">SAVINGS ACCOUNT - 01234567890</option>
                                  <option value class="choose_account">CURRENT ACCOUNT - 01234567890</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="label-title">
                              <label>Number of Leaves</label>
                              <div class="account_select">
                                <select name id class="account_type">
                                  <option value class="choose_account">-- Select --</option>
                                  <option value class="choose_account">30</option>
                                  <option value class="choose_account">50</option>
                                  <option value class="choose_account border-bottom">60</option>
                                  <option value class="choose_account">70</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="label-title">
                              <label>Number of Books</label>
                              <div class="account_select">
                                <select name id class="account_type">
                                  <option value class="choose_account">-- Select --</option>
                                  <option value class="choose_account">1</option>
                                  <option value class="choose_account">2</option>
                                  <option value class="choose_account border-bottom">3</option>
                                  <option value class="choose_account">4</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="label-title">
                              <label>Delivery</label>
                              <div class="account_select">
                                <select name id class="account_type">
                                  <option value class="choose_account">-- Select --</option>
                                  <option value class="choose_account">Pickup Here</option>
                                  <option value class="choose_account">Delivered To Address</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Enter BVN</label>
                              <div class="account_select2">
                                <the-mask mask="############" class="input-amount" placeholder="-" />
                                <!--<input type="number" class="input-amount" placeholder="-"/>-->
                              </div>
                            </div>

                            <div class="send-or-download2">
                              <div class="continue-button">
                                <a @click="$router.go(-1)">
                                  <div class="continue-btn btn">
                                    <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                                  </div>
                                </a>
                              </div>
                              <div class="continue-button">
                                <b-button v-b-modal.modal-center class="continue-btn">
                                  SUBMIT
                                  <font-awesome-icon icon="angle-right" class="angle-icon" />
                                </b-button>
                                <!--<button class="continue-btn">CONTINUE <font-awesome-icon icon="angle-right" class="angle-icon"/></button>-->
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <strong>Stop Cheque</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p
                            class
                          >To avoid wrong transactions, you can stop a cheque from being cashed</p>
                        </div>
                        <div class="kindly_enter_details mb-3">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Input cheque number, select account, your reason and stop cheque from being transacted</p>
                        </div>
                        <form action>
                          <div class="form-table">
                            <div class="label-title2">
                              <label>Account</label>
                              <div class="account_select">
                                <select name id class="account_type">
                                  <option value class="choose_account">-- Select --</option>
                                  <option value class="choose_account">SAVINGS ACCOUNT - 01234567890</option>
                                  <option value class="choose_account">CURRENT ACCOUNT - 01234567890</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Cheque Number</label>
                              <div class="account_select2">
                                <the-mask mask="###########" class="input-amount" placeholder="-" />
                                <!--<input type="number" class="input-amount" placeholder="-"/>-->
                              </div>
                            </div>
                            <div class="label-title">
                              <label>Reason</label>
                              <div class="account_select">
                                <select name id class="account_type">
                                  <option value class="choose_account">-- Select --</option>
                                  <option value class="choose_account">Duplicate</option>
                                  <option value class="choose_account">Expired</option>
                                  <option value class="choose_account border-bottom">Others</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>

                            <div class="continue-button">
                              <b-button v-b-modal.modal-center class="continue-btn">
                                SUBMIT
                                <font-awesome-icon icon="angle-right" class="angle-icon" />
                              </b-button>
                              <!--<button class="continue-btn">CONTINUE <font-awesome-icon icon="angle-right" class="angle-icon"/></button>-->
                            </div>
                          </div>
                        </form>
                      </div>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <strong>Confirm Cheque</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Give the bank the authority to allow your cheque to be cashed</p>
                        </div>
                        <div class="kindly_enter_details mb-3">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Input cheque number, select account and follow instructions</p>
                        </div>
                        <form action>
                          <div class="form-table">
                            <div class="label-title2">
                              <label>Account</label>
                              <div class="account_select">
                                <select name id class="account_type">
                                  <option value class="choose_account">-- Select --</option>
                                  <option value class="choose_account">SAVINGS ACCOUNT - 01234567890</option>
                                  <option value class="choose_account">CURRENT ACCOUNT - 01234567890</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>

                            <div class="label-title2">
                              <label>Cheque Number</label>
                              <div class="account_select2">
                                <the-mask mask="###########" class="input-amount" />
                                <!--<input type="number" class="input-amount" placeholder="-"/>-->
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select2">
                                <vue-numeric
                                  class="input-amount"
                                  currency="N"
                                  separator=","
                                  v-model="price"
                                ></vue-numeric>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Beneficiary Name</label>
                              <div class="account_select2">
                                <input type="text" class="input-amount" />
                              </div>
                            </div>

                            <div class="continue-button">
                              <b-button v-b-modal.modal-center class="continue-btn">
                                SUBMIT
                                <font-awesome-icon icon="angle-right" class="angle-icon" />
                              </b-button>
                              <!--<button class="continue-btn">CONTINUE <font-awesome-icon icon="angle-right" class="angle-icon"/></button>-->
                            </div>
                          </div>
                        </form>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar></SideBar>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
      <b-modal
        id="modal-center"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <h1 class="pin-info text-center">
              Insert PIN to finish
              <br />transaction
            </h1>
            <the-mask mask="####" type="password" class="input-pin" placeholder="****" required />
            <div class="pin_input">
              <div class="pin_input2">
                <div class="pin-input">1</div>
                <div class="pin-input">2</div>
                <div class="pin-input">3</div>
                <div class="pin-input">4</div>
              </div>
              <div class="pin_input2">
                <div class="pin-input">5</div>
                <div class="pin-input">6</div>
                <div class="pin-input">7</div>
                <div class="pin-input">8</div>
              </div>
              <div class="pin_input2">
                <div class="pin-input">9</div>
                <div class="pin-input">0</div>
                <div class="pin-input">
                  <font-awesome-icon icon="backspace" class />
                </div>
                <button class="pin-input">OK</button>
              </div>
            </div>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import VueNumeric from "vue-numeric";
import HeaderNav2 from "../../components/HeaderNav/HeaderNav2";
import HeaderNav3 from "../../components/HeaderNav/HeaderNav3";
import SideBar from "../../components/SideBar/SideBar";
import Footer2 from "../../components/Footer/Footer2";

export default {
  name: "Cheque.vue",
  components: {
    TheMask,
    VueNumeric,
    HeaderNav2,
    HeaderNav3,
    SideBar,
    Footer2
  },
  data() {
    return {
      price: ""
    };
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000 !important;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000 !important;
  display: flex;
  align-items: center;
  margin: 0 1rem;
  font-family: Montserrat-Bold;
  font-size: 12px;
  letter-spacing: 0.17px;
}

.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
