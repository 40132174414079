<template>
  <div>
    <div @click="closeModal()" class="receipt_backdrop"></div>
    <div v-if="dataType == 'bill'">
      <div class="receipt_page">
        <div ref="html" class="receipt_page_download">

          <div class="receipt_body">
            <div class="receipt_body_heading">
              <div>Transaction Receipt</div>
              <img src="../../assets/images/banner.svg" alt="Hope Bank Logo" width="100%" />
            </div>

            <div class="receipt_tick">
              <img src="../../assets/images/icons/transaction_tick.svg" alt="">
              <h5><b>Successful Transaction</b></h5>
              <p>{{ DateRes }}</p>
            </div>

            <div class="receipt_body_details">
              <div class="receipt_body_detail">
                <span>Account to debit</span>
                <span><b>{{ account }}</b></span>
              </div>

              <div class="receipt_body_detail">
                <span>Category</span>
                <span><b>{{ Category }}</b></span>
              </div>

              <div class="receipt_body_detail">
                <span>Biller/Merchant</span>
                <span><b>{{ Merchant }}</b></span>
              </div>

              <div class="receipt_body_detail">
                <span>Product</span>
                <span><b>{{ Product }}</b></span>
              </div>

              <div class="receipt_body_detail">
                <span>Beneficiary Details</span>
                <span><b>{{ beneficiary }}</b></span>
              </div>

              <div v-if="Token" class="receipt_body_detail">
                <span>Token</span>
                <span><b>{{ Token }}</b></span>
              </div>

              <div class="receipt_body_detail">
                <span>Amount</span>
                <span><b>₦ {{ Amount }}</b></span>
              </div>

              <div class="receipt_body_detail">
                <span>Transaction Date</span>
                <span><b>{{ DateRes }}</b></span>
              </div>
            </div>
          </div>
        </div>
        <div class="receipt_cta">
          <button><font-awesome-icon icon="envelope" class="download-icon" /> Send to Email</button>
          <button @click="exportToPDF"><font-awesome-icon icon="download" class="download-icon" />Download</button>
        </div>
      </div>

    </div>

    <div v-if="dataType == 'airtime'" class="transaction-receipt-page">
      <div class="receipt_page">
        <div ref="html" class="receipt_page_download">

          <div class="receipt_body">
            <div class="receipt_body_heading">
              <div>Transaction Receipt</div>
              <img src="../../assets/images/banner.svg" alt="Hope Bank Logo" width="100%" />
            </div>

            <div class="receipt_tick">
              <img src="../../assets/images/icons/transaction_tick.svg" alt="">
              <h5><b>Successful Transaction</b></h5>
              <p>{{ DateRes2 }}</p>
            </div>

            <div class="receipt_body_details">
              <div class="receipt_body_detail">
                <span>Account to debit</span>
                <span><b>{{ account2 }}</b></span>
              </div>

              <div class="receipt_body_detail">
                <span>Biller/Merchant</span>
                <span><b>{{ Merchant2 }}</b></span>
              </div>

              <div class="receipt_body_detail">
                <span>Beneficiary Details</span>
                <span><b>{{ beneficiary2 }}</b></span>
              </div>

              <div class="receipt_body_detail">
                <span>Amount</span>
                <span><b>₦ {{ Amount2 }}</b></span>
              </div>


              <div class="receipt_body_detail">
                <span>Transaction Date</span>
                <span><b>{{ DateRes2 }}</b></span>
              </div>
            </div>
          </div>
        </div>
        <div class="receipt_cta">
          <button><font-awesome-icon icon="envelope" class="download-icon" /> Send to Email</button>
          <button @click="exportToPDF"><font-awesome-icon icon="download" class="download-icon" />Download</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import html2pdf from "html2pdf.js";
import moment from "moment";


export default {
  name: "Receipt.vue",
  props: {
    dataType: String
  },
  computed: {
    ...mapGetters({
      TRANSACTION_DETAILS: "GET_TRANSACTION",
      USER: "GET_USER",
      billData: "GET_BILL_DATA",
      airtimeData: "GET_AIRTIME_DATA",
    }),

    Category: {
      get() {
        return this.billData.form.Category || this.airtimeData.form.Account;
      },
      set(value) {
        if (this.billData.form.Category) {
          return (this.billData.form.Category = value);
        }
        if (this.airtimeData.form.Category) {
          return (this.airtimeData.form.Category = value);
        }
      },
    },
    account: {
      get() {
        return this.billData.form.Account || this.airtimeData.form.Account;
      },
      set(value) {
        if (this.dataType == "bill") {
          return (this.billData.form.Account = value);
        }
        if (this.dataType == "airtime") {
          return (this.airtimeData.form.Account = value);
        }
      },
    },
    Merchant: {
      get() {
        return this.billData.form.Merchant;
      },
      set(value) {
        if (this.dataType == "bill") {
          return (this.billData.form.Merchant = value);
        }
        if (this.dataType == "airtime") {
          return (this.airtimeData.form.Merchant = value);
        }
      },
    },

    Product: {
      get() {
        if (this.dataType == "bill") {
          return this.billData.form.Product;
        }
        if (this.airtimeData.form.Product) {
          return this.airtimeData.form.Product
        }
        return ""
      },
      set(value) {
        if (this.dataType == "bill") {
          return (this.billData.form.Product = value);
        }
        if (this.dataType == "airtime") {
          return this.airtimeData.form.Product = value
        }
      },
    },
    ProductId: {
      get() {
        return this.billData.form.ProductId || this.airtimeData.form.ProductId;
      },
      set(value) {
        if (this.dataType == "bill") {
          return (this.billData.form.ProductId = value);
        }
        if (this.dataType == "airtime") {
          return (this.airtimeData.form.ProductId = value);
        }
      },
    },
    beneficiary: {
      get() {
        return this.billData.form.Beneficiary || this.airtimeData.form.Beneficiary;
      },
      set(value) {
        if (this.dataType == "bill") {
          return (this.billData.form.Beneficiary = value);
        }
        if (this.dataType == "airtime") {
          return (this.airtimeData.form.Beneficiary = value);
        }
      },
    },
    Token: {
      get() {
        return this.billData.response.token;
      },
      set(value) {
        if (this.dataType == "bill") {
          if (this.billData.response.token) {
            return (this.billData.response.token = value);
          } else {
            return (this.billData.response['token 0'] = value);
          }
        }
        if (this.dataType == "airtime") {
          return (this.airtimeData.response.token = value);
        }
      },
    },
    Amount: {
      get() {
        return this.billData.form.Amount || this.airtimeData.form.Account;
      },
      set(value) {
        if (this.dataType == "bill") {
          return (this.billData.form.Amount = value);
        }
        if (this.dataType == "airtime") {
          return (this.airtimeData.form.Amount = value);
        }
      },
    },
    DateRes: {
      get() {
        return moment(this.billData.form.date).format("D MMM YYYY, h:mm a") || moment(this.airtimeData.form.date).format("D MMM YYYY, h:mm a");
      },
      set(value) {
        if (this.billData.form.date) {
          return (this.billData.form.date = value);
        }
        if (this.airtimeData.form.date) {
          return (this.airtimeData.form.date = value);
        }
      },
    },
    Total: {
      get() {
        return this.billData.response["total Due"] || this.airtimeData.response["total Due"];
      },
      set(value) {
        if (this.billData.response["total Due"]) {
          return (this.billData.response["total Due"] = value);
        }
        return "";
      },
    },
    Fee: {
      get() {
        return this.billData.response["convenience Fee"] || this.airtimeData.response["convenience Fee"];
      },
      set(value) {
        if (this.billData.response["convenience Fee"]) {
          return (this.billData.response["convenience Fee"] = value);
        }
        return "";
      },
    },

    account2: {
      get() {
        return this.airtimeData.form.Account;
      },
      set(value) {
        if (this.airtimeData.form.Account) {
          return (this.airtimeData.form.Account = value);
        }
        return ""
      },
    },
    Merchant2: {
      get() {
        return this.airtimeData.form.Merchant;
      },
      set(value) {
        if (this.airtimeData.form.Merchant) {
          return (this.airtimeData.form.Merchant = value);
        }
        return ""
      },
    },

    Product2: {
      get() {
        if (this.airtimeData.form.Product) {
          return this.airtimeData.form.Product
        }
        return ""
      },
      set(value) {
        if (this.airtimeData.form.Product) {
          return this.airtimeData.form.Product = value
        }
      },
    },
    ProductId2: {
      get() {
        return this.airtimeData.form.ProductId;
      },
      set(value) {
        if (this.airtimeData.form.ProductId) {
          return (this.airtimeData.form.ProductId = value);
        }
      },
    },
    beneficiary2: {
      get() {
        return this.airtimeData.form.Beneficiary;
      },
      set(value) {
        if (this.airtimeData.form.Beneficiary) {
          return (this.airtimeData.form.Beneficiary = value);
        }
        return ""
      },
    },
    Amount2: {
      get() {
        return this.airtimeData.form.Amount;
      },
      set(value) {
        if (this.airtimeData.form.Amount) {
          return (this.airtimeData.form.Amount = value);
        }
        return ""
      },
    },
    DateRes2: {
      get() {
        return moment(this.airtimeData.form.date).format("D MMM YYYY, h:mm a");
      },
      set(value) {
        if (this.airtimeData.form.date) {
          return (this.airtimeData.form.date = value);
        }
        return ""
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    hashAccount(string) {
      let slicedFront = string.slice(0, 3);
      let slicedBack = string.slice(7);
      return `${slicedFront}****${slicedBack}`;
    },

    //download html as pdf
    exportToPDF() {
      html2pdf(this.$refs.html, {
        margin: 0,
        compress: true,
        filename: "receipt.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 392, letterRendering: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
  },
};
</script>

<style scoped>
.approved {
  color: rgb(0, 203, 0)
}

p {
  margin: 0
}

.receipt_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.381);
  z-index: 2000;
  width: 100%;
  backdrop-filter: blur(10px);
  height: 100%;
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.receipt_page {
  margin: 40px 0;
  background: white;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 500px;
  width: 90%;
  border-radius: 10px;
  z-index: 2002;
}

.receipt_page_download {
  padding: 30px;
  padding-bottom: 20px;
}

.receipt_logo img {
  border-radius: 20px;
  width: 100px;
}

.receipt_body_heading {
  margin: 30px 0;
  margin-top: 0px;
  text-align: center;
  position: relative;
  background: #E6AA02;
  padding: 12px;
  border-radius: 10px;
  color: white
}

.receipt_body_heading div {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px
}

.receipt_body_heading img {
  position: absolute;
  width: 70px;
  top: 50%;
  right: 10px;
  transform: translateY(-50%)
}

.receipt_body_heading p:first-child {
  margin-bottom: 10px;
}

.receipt_tick {
  text-align: center
}

.receipt_tick img {
  width: 100px;
  margin-bottom: 20px
}

.receipt_body_details {
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #0053C5;
  border-bottom: 1px solid #0053C5;
}

.receipt_body_detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  font-size: 14px;
}

.receipt_body_detail div:first-child {
  color: #E6AA02
}

.receipt_body_detail:last-child {
  margin-bottom: 0
}

.receipt_body_detail div {
  font-weight: 700;

}

.receipt_body_detail span:first-child {
  font-weight: 100
}

.receipt_cta {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 40px;
  padding-top: 0px
}

.receipt_cta button {
  padding: 10px;
  width: 100%;
  color: #FFF;
  font-weight: 700;
  border-radius: 10px;
  background: #E6AA02;
}

.receipt_cta button:first-child {
  margin-right: 20px;
  background: none;
  color: #E6AA02;
  border: 1px solid #E6AA02;
}

.receipt_cta svg {
  margin-right: 10px
}

@media screen and (max-width: 474px) {
  .receipt_page_download {
    padding: 14px
  }

  .receipt_body_heading div {
    font-size: 14px
  }

  .receipt_cta {
    padding: 20px;
    padding-top: 0px
  }

  .receipt_body_detail {
    font-size: 11px
  }

  .receipt_cta button {
    font-size: 14px padding
  }

  .receipt_cta button svg {
    width: 20px;
  }
}
</style>