<template>
  <div class="poster-bg">
    <div class="poster"></div>
    <Loader v-if="loading" :show-full="true" loading-text="Processing" />
    <div class="dashboard-page registration-page">
      <div class>
        <HeaderNavRegister />
        <div class="register_now">
          <div class="container">
            <div class="main_dashboard">
              <div class="row">
                <div class="col-md-12">
                  <div class="main_dashboard_inner">
                    <div class="transfer_money2 text-center">
                      <p class="frequently-used text-center">
                        Business Account Creation
                      </p>
                    </div>

                    <div class="current_tab_title mb-3">
                      <font-awesome-icon icon="play" class="play-icon" />
                      <p class>
                        Continue your registration by filling and uploading the
                        following requirements.
                      </p>
                    </div>
                    <div class="steps_to_acc_open">
                      <h2>
                        Enter Details -
                        <span class="h5">{{ ACCOUNT_TYPE.name }}</span>
                      </h2>
                    </div>

                    <form action @submit.prevent="validationComplete">
                      <div class="steps_to_acc_open">
                        <h2>Signatories' Mandate</h2>
                      </div>
                      <div>
                        <!-- <b-button v-b-toggle.collapse-3 class="m-1 continue-btn"
                          >Set Mandate</b-button> -->
                        <h5 class="about-title-sm">Set Mandate</h5>
                        <ul class="mandates_info">
                          <li>You may set as many mandates as you wish</li>
                          <li>
                            Each mandate must have a separate limit/amount
                          </li>
                          <li>One can sign alone (Sole signatory).</li>
                          <li>Two or more can sign (Joint signatories).</li>
                          <li>
                            <font-awesome-icon icon="exclamation" size="1x" class="text-danger" />Please Note that a limit
                            Amount can only be set
                            once.
                          </li>
                        </ul>

                        <!-- new mandate format start -->
                        <div class="row">
                          <div class="col-md-7">
                            <div class="fdf">
                              <div class="label-title2">
                                <label>Signatory Category</label>
                                <div class="account_select">
                                  <Multiselect v-model="dataArray" :options="options" class="account_type" label="label"
                                    track-by="value" multiple></Multiselect>
                                </div>
                              </div>
                              <ul class="mandates_info">
                                <li>
                                  For sole signatory (one can sign), select
                                  applicable category and only one category at a
                                  time.
                                </li>
                                <li>
                                  For joint signatories (two or more can sign)
                                  select applicable categories
                                </li>
                                <li>
                                  Remember that you can repeat this for as many
                                  mandates as you wish.
                                </li>
                              </ul>

                              <div class="label-title2">
                                <label>Category Type</label>
                                <div class="account_select">
                                  <select class="account_type" required v-model="categoryType">
                                    <option value>
                                      Select Category Type (Unlimited or Amount)
                                    </option>
                                    <option value="Unlimited">Unlimited</option>
                                    <option value="Amount">Amount</option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>Amount</label>
                                <div class="account_select2">
                                  <vue-numeric :disabled="categoryType === 'Unlimited'" v-model="limitAmount"
                                    class="input-amount" currency="₦" separator=","></vue-numeric>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex justify-content-between">
                              <div class="send-or-download2 mt-2">
                                <button class="continue-btn" @click.prevent="ResetMandate">
                                  <font-awesome-icon icon="delete" class="download-icon" />Reset
                                </button>
                              </div>
                              <div class="send-or-download2 mt-2">
                                <button class="continue-btn" @click.prevent="addMandate">
                                  <font-awesome-icon icon="plus" class="download-icon" />Add Mandate
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-5">
                            <div class="recent-transactions">
                              <div class="steps_to_acc_open">
                                <h2>Added Signatory(s)</h2>
                              </div>
                              <table v-if="MANDATE.length > 0">
                                <tr>
                                  <th>Name</th>
                                  <th>Category Type</th>
                                  <th>Amount</th>
                                  <th>Combination</th>
                                  <th></th>
                                </tr>

                                <tr v-for="(mandate, index) in MANDATE" :key="index">
                                  <td>Mandate 1</td>
                                  <td>{{ mandate.categoryType }}</td>
                                  <td>{{ formatAmount(mandate.amount) }}</td>
                                  <td>
                                    <span v-for="(
                                        comb, index
                                      ) in mandate.combination" :key="index">
                                      {{ comb.label }} ,
                                    </span>
                                  </td>
                                  <td><font-awesome-icon icon="trash" /></td>
                                </tr>
                              </table>

                              <table v-if="MANDATE.length === 0">
                                0
                                <tr>
                                  <th>Name</th>
                                  <th>Category Type</th>
                                  <th>Amount</th>
                                  <th>Combination</th>
                                  <th></th>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <!-- new mandate format ends -->

                        <!-- old mandate format start -->
                        <!-- <b-card>
                            <div class="form-table2">
                              <div class>
                                <h5 class="about-title-sm">Limited Mandate/Amount</h5>
                                
                                <div class>
                                  <div class="limited_amount">
                                    <vue-numeric
                                      class="input-amount border"
                                      :disabled="isLimitAmount"
                                      v-model="limitAmount"
                                      currency="₦"
                                      separator=","
                                    ></vue-numeric>
                                  </div>
                                  <div class="limited_amount mb-2">
                                    <div class="set_mandate_checkbox">
                                      <input
                                        type="checkbox"
                                        id="unlimitedAmount"
                                        name="unlimitedAmount"
                                        v-model="unlimitedAmount"
                                        :disabled="isUnlimitedAmount"
                                      />
                                      <label for="unlimitedAmount"
                                        >Unlimited Mandate/Amount</label
                                      >
                                    </div>
                                    <ul class="mandates_info">
                                  <li>(Please check box). This section must be completed.</li>
                                </ul>
                                  </div>
                                </div>
                                <h5 class="about-title-sm">Set Signatory Categories</h5>
                                <table class="signatories_mandate_input">
                                  <tr>
                                    <th style="font-size: 14px;">
                                      Single & Combined Mandate
                                    </th>
                                    <th class></th>
                                  </tr>
                                  <tr>
                                    <td>
                                      <ul class="mandates_info">
                                        <li>For sole signatory (one can sign), select applicable category and only one category at a time.</li>
                                        <li>For joint signatories (two or more can sign) select applicable categories</li>
                                        <li>Remember that you can repeat this for as many mandates as you wish.</li>
                                      </ul>
                                    </td>
                                  </tr>
                                  <tr
                                    class="table-row"
                                    v-for="category in Categories"
                                    :key="category"
                                  >
                                    <td>
                                      <div class="set_mandate_checkbox">
                                        <input
                                          type="checkbox"
                                          :id="category"
                                          :value="category"
                                          v-model="selected"
                                        />
                                        <label :for="category">
                                          {{ category }}
                                        </label>
                                      </div>
                                    </td>
                                    <td></td>
                                  </tr>
                                </table>

                                <div class="d-flex justify-content-between">
                                  <div class="send-or-download2 mt-2">
                                    <button
                                      class="continue-btn"
                                      @click.prevent="addMandate"
                                      :disabled="!selected[0]"
                                    >
                                      <font-awesome-icon
                                        icon="plus"
                                        class="download-icon"
                                      />Set Mandate
                                    </button>
                                  </div>
                                  <div class="send-or-download2 mt-2">
                                    <button
                                      class="continue-btn"
                                      @click.prevent="ResetMandate"
                                    >
                                      <font-awesome-icon
                                        icon="delete"
                                        class="download-icon"
                                      />Reset
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-card> -->
                        <!-- old mandate format ends -->
                      </div>
                      <div v-if="ACCOUNT_TYPE.code !== '111'">
                        <div class="mt-4" v-for="(referee, index) in 2" :key="index">
                          <div class="steps_to_acc_open">
                            <h2>Details of Referee &nbsp; {{ index + 1 }}</h2>
                          </div>
                          <div class="form-table2">
                            <div class="label-title2">
                              <label>Company Name
                                <span class="red-star">*</span></label>
                              <div class="account_select2">
                                <input type="text" v-model="CompName[index]" class="input-amount" id />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Authorized Signatory's First Name
                                <span class="red-star">*</span></label>
                              <div class="account_select2">
                                <input type="text" v-model="firstname[index]" class="input-amount" id />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Authorized Signatory's Last Name
                                <span class="red-star">*</span></label>
                              <div class="account_select2">
                                <input type="text" v-model="lastname[index]" class="input-amount" id />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Authorized Signatory's Email
                                <span class="red-star">*</span></label>
                              <div class="account_select2">
                                <input v-model="email[index]" type="email" class="input-amount" placeholder=" " />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Authorized Signatory's Phone No.
                                <span class="red-star">*</span></label>
                              <div class="account_select2">
                                <the-mask mask="###########" type="tel" class="input-amount" v-model="phone[index]"
                                  placeholder=" " />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="steps_to_acc_open">
                        <h2>
                          Uploads -
                          <span style="color:red; font-size:13px"
                            >(Only jpgs, pngs and pdfs allowed. max file size -
                            250 kb)</span
                          >
                        </h2>
                      </div> -->
                      <div class="form-table2">
                        <!-- <div
                          class="label-title2"
                          v-for="(director, index) of directors.length"
                          :key="director"
                        >
                          <label>
                            ID of Director {{ index + 1 }}
                            <span v-if="index == 0">(CE0)</span>
                            <span class="red-star">*</span>
                          </label>
                          <div class="account_select5">
                            <vuebase64
                              @size-exceeded="onSizeExceeded"
                              @load="directorIds[index] = $event"
                              accept="image/*, .pdf"
                              :maxSize="0.25"
                            />
                          </div>
                        </div>
                        <div class="line3"></div>
                        <div
                          class="label-title2"
                          v-for="(signatory, index) of signatoryNo"
                          :key="signatory"
                        >
                          <label
                            >ID of Signatory {{ index + 1
                            }}<span class="red-star">*</span></label
                          >
                          <div class="account_select5">
                            <vuebase64
                              accept="image/*, .pdf"
                              :maxSize="0.25"
                              @size-exceeded="onSizeExceeded"
                              @load="signatoryIds[index] = $event"
                            />
                          </div>
                        </div>
                        <div class="line3"></div>
                        <div class="label-title2">
                          <label
                            >Board Resolution (BR)
                            <span class="red-star">*</span></label
                          >
                          <div class="account_select5">
                            <vuebase64
                              @size-exceeded="onSizeExceeded"
                              accept="image/*, .pdf"
                              :maxSize="0.25"
                              @load="boardRes = $event"
                            />
                          </div>
                        </div>
                        <div class="line3"></div>
                        <div class="label-title2">
                          <label>
                            Licence
                            <span>
                              (for Schools, Hospital,
                              <br />BDC, Microfinance etc)
                            </span>
                            <span class="red-star">*</span>
                          </label>
                          <div class="account_select5">
                            <vuebase64
                              @size-exceeded="onSizeExceeded"
                              accept="image/*, .pdf"
                              :maxSize="0.25"
                              @load="licence = $event"
                            />
                          </div>
                        </div>
                        <div class="line3"></div>
                        <div class="label-title2">
                          <label
                            >Utility Bill <span class="red-star">*</span></label
                          >
                          <div class="account_select5">
                            <vuebase64
                              @size-exceeded="onSizeExceeded"
                              accept="image/*, .pdf"
                              :maxSize="0.25"
                              @load="utility = $event"
                            />
                          </div>
                        </div>
                        <div class="line3"></div>
                        <div class="label-title2">
                          <label>SCUML <span class="red-star">*</span></label>
                          <div class="account_select5">
                            <vuebase64
                              @size-exceeded="onSizeExceeded"
                              accept="image/*, .pdf"
                              :maxSize="0.25"
                              @load="scuml = $event"
                            />
                          </div>
                        </div> -->

                        <!-- <div class="term_condition_check">
                          <input
                            type="checkbox"
                            id="auth"
                            v-model="debitAuth"
                            name="auth"
                            value="auth"
                          />
                          <label for="auth">
                            Authority to debit Account for search report.
                          </label>
                        </div> -->

                        <div class="term_condition_check">
                          <terms />
                          <input type="checkbox" id="terms" v-model="terms" name="terms" value="no" />
                          <label for="terms">
                            I have read, understood and accept the
                            <b-button v-b-modal.modal-scrollable-terms class="terms_button">Terms and
                              Conditions</b-button>
                          </label>
                        </div>
                        <!-- <terms /> -->
                        <div class="kindly_enter_details mb-3">
                          <p>
                            I/We hereby authorize you to debit my/our account with the sum of
                            <b>
                              <span class="authorized_amount_input">₦<span>10,000</span></span>
                            </b>
                            <!-- <vue-numeric
                              class="authorized_amount_input"
                              v-model="amount"
                              currency="₦"
                              separator=","
                            ></vue-numeric> -->
                            being the legal cost for the search on my/our Corporate Account. Please note that debit
                            transactions will be restricted on the account until a successful
                            completion of the search.
                          </p>
                        </div>
                      </div>
                      <div class="send-or-download2">
                        <div class="continue-button">
                          <a @click="$router.go(-1)">
                            <div class="continue-btn">
                              <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                            </div>
                          </a>
                        </div>
                        <div class="continue-button">
                          <b-button type="submit" :disabled="!terms" class="continue-btn">
                            SUBMIT
                            <font-awesome-icon icon="angle-right" class="angle-icon" />
                          </b-button>
                          <!-- <router-link to="Login"><button class="continue-btn">Upload Documents <font-awesome-icon icon="angle-right" class="angle-icon"/></button></router-link> -->
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />

      <b-modal id="modal-center-ceo-otp" centered v-model="otp1_modal" hide-footer hide-header-close>
        <form ref="form" @submit.prevent hide-header-close="true">
          <b-form-group label-for="name-input" invalid-feedback="OTP is required" class="pin-info">
            <h1 class="pin-info text-center">
              Enter OTP sent to 1st {{ directorLabel() }} Phone Number
            </h1>
            <div class="dialpad">
              <div class="form-group">
                <input type="password" class="form-control" v-model="otp1" mask="######" maxlength="6" minlength="6"
                  id="txtBox" placeholder="OTP" />
              </div>
              <Keyboard @pressed="otp1 = $event" :pinLength="otp1" @completed="
                otp1_modal = false;
              ACCOUNT_TYPE.code == '106'
                ? completeProcess()
                : (otp2_modal = true);
              " :selfValue="otp1"></Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>

      <b-modal id="modal-center-secOrDir-otp" centered v-model="otp2_modal" hide-footer hide-header-close>
        <form ref="form" @submit.prevent>
          <b-form-group label-for="name-input" invalid-feedback="OTP is required" class="pin-info">
            <h1 class="pin-info text-center">
              Enter OTP sent to 2nd {{ directorLabel() }} or Secretary's Phone
              Number
            </h1>
            <div class="dialpad">
              <div class="form-group">
                <input type="password" class="form-control" v-model="otp2" mask="######" maxlength="6" minlength="6"
                  id="txtBox" placeholder="otp" />
              </div>
              <Keyboard @pressed="otp2 = $event" :pinLength="otp2" @completed="
                otp2_modal = false;
              completeProcess();
              " :selfValue="otp2"></Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>
      <b-modal id="modal-center" centered hide-footer v-model="success_modal" hide-header-close>
        <form ref="form">
          <b-form-group label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <img src="../../../assets/images/icons/check.svg" alt="Hope Bank" class="modal_congrats_img" />
            <h1 class="pin-info text-center">Congratulations</h1>
            <div class="line4"></div>
            <p class="modal_congrats">
              Dear {{ CORPORATE.COMPANY_NAME }}, Your
              <b>{{ ACCOUNT_TYPE.name }}</b> number is
              <b>{{ responseData.account }}</b>.
              <span>Signatory(s) have been notified via email to enrol on Digital
                Banking to activate the account.</span>
            </p>
            <!-- <router-link to="/corporate/register"> -->
            <b-button @click="success" class="ok-btn">OK</b-button>
            <!-- </router-link> -->
          </b-form-group>
        </form>
      </b-modal>
      <b-modal id="modal-center2" v-model="add" centered hide-footer hide-header-close>
        <form ref="form">
          <b-form-group label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <span v-if="!limitAmount && !unlimitedAmount">Please enter a limit Amount or select Unlimited</span>
            <!-- <span v-else>Mandate Added Successfully</span> -->
            <div v-else class="mandates_info">
              <img src="../../../assets/images/icons/check.svg" alt="Hope Bank" class="modal_congrats_img" />
              <p>- Mandate Added Successfully</p>
              <p>- To add more mandate(s), Click ‘+Set Mandate’</p>
              <p>
                - Remember that to allow the account owner transact as much as
                desired, Unlimited Mandate/Amount MUST be completed. Please do
                so if not already done.
              </p>
            </div>

            <div class="mt-3">
              <div @click="
                add = false;
              limitAmount = 0;
              unlimitedAmount = false;
              " class="btn" style="background: #fecb32">
                OK
              </div>
            </div>
          </b-form-group>
        </form>
      </b-modal>
      <!-- Modal to  show if same limit amount is chosen twice -->
      <b-modal id="modal-center2" v-model="duplicate_limit_amount" centered hide-footer hide-header-close>
        <form ref="form">
          <b-form-group label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <span>Limit Amount already set.</span>
            <br />

            <div class="mt-3">
              <div @click="
                duplicate_limit_amount = false;
              limitAmount = 0;
              unlimitedAmount = false;
              " class="btn" style="background: #fecb32">
                OK
              </div>
            </div>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import HeaderNavRegister from "../../../components/HeaderNav/HeaderNavRegister";
import VueNumeric from "vue-numeric";
import Keyboard from "../../../components/Keyboard/Keyboard.vue";
// import vuebase64 from "../../../components/vue-base64/vuebase64.vue";
import { CorporateAccountService } from "../../../services/CorporateAccount/corporateAccount.services.js";
import Loader from "../../../components/Loader/Loader.vue";
import { mapState } from "vuex";
import terms from "../../../components/Terms/terms";
import Footer2 from "../../../components/Footer/Footer2";
import Multiselect from "vue-multiselect";

export default {
  name: "mandate",
  components: {
    HeaderNavRegister,
    VueNumeric,
    Keyboard,
    Loader,
    // vuebase64,
    terms,
    Footer2,
    Multiselect,
  },
  data() {
    return {
      duplicate_limit_amount: "false",
      firstname: [],
      lastname: [],
      email: [],
      phone: [],
      CompName: [],
      categoryType: "",
      boardRes: "",
      licence: null,
      directorIds: [],
      signatoryIds: [],
      utility: "",
      amount: 0,
      scuml: "",
      idData: {},
      mandate: {},
      debitAuth: false,
      add: false,
      terms: false,
      otp1: "",
      otp2: "",
      otp1_modal: false,
      success_modal: false,
      otp2_modal: false,
      selected: [],
      Categories: ["A", "B", "C", "D", "E", "F"],
      limitAmount: 0,
      isLimitAmount: false,
      unlimitedAmount: "",
      isUnlimitedAmount: false,
      loading: false,
      responseData: {},
      validationCompleteId: "",
      counter: 0,
      // directorNo:3,
      // signatoryNo:3
      dataSingle: null,
      dataArray: [],
      dataArrayTaggable: [],
      dataGroups: [],
      options: [],
    };
  },
  watch: {
    limitAmount(newval) {
      if (newval) return (this.isUnlimitedAmount = true);
      this.isUnlimitedAmount = false;
    },
    unlimitedAmount(newval) {
      if (newval) return (this.isLimitAmount = true);
      this.isLimitAmount = false;
    },
    utility() {
      this.idCards();
    },
  },
  computed: {
    ...mapState({
      MANDATE: (state) => state.CreateAccount.CORPORATE_ACCOUNT.MANDATES,
      BVN_DETAILS: (state) => state.CreateAccount.BVN_DETAILS,
      CORPORATE: (state) => state.CreateAccount.CORPORATE_ACCOUNT,
      SIGNATORIES: (state) => state.CreateAccount.CORPORATE_ACCOUNT.SIGNATORIES,
      SIGNATORIES_IDS: (state) =>
        state.CreateAccount.CORPORATE_ACCOUNT.SIGNATORIES_ID,
      ACCOUNT_TYPE: (state) => state.CreateAccount.ACCOUNT_TYPE,
    }),
    signatoryNo() {
      return this.CORPORATE.SIGNATORIES_NO;
    },
    directors() {
      let directors = this.CORPORATE.DIRECTORS;
      // let isCompanySec = this.CORPORATE.IS_COMPANY_SEC;
      // if (isCompanySec) directors.splice(1, 1);
      return directors;
    },
  },
  methods: {
    formatAmount(value) {
      let val = (value / 1).toFixed(2).replace(".", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    // Signatory Class Amount Limit Dropdown
    toggleDropdown() {
      this.$refs.dropdown.classList.toggle("is-active");
    },
    onFile(event) {
      window.console.log(event);
    },

    onSizeExceeded() {
      this.$toastr.error(`Maximum file size exceeded`, "Error");
    },
    idCards() {
      let idData = {};
      for (let i = 0; i < this.directors.length; i++) {
        idData[this.directors[i]] = this.directorIds[i];
      }
      for (let i = 0; i < this.signatoryNo; i++) {
        idData[this.CORPORATE.SIGNATORIES[i]] = this.signatoryIds[i];
      }

      return (this.idData = idData);
    },
    //add mandate
    addMandate() {
      // if (!this.limitAmount && !this.unlimitedAmount) return (this.add = true);
      // this.duplicate_limit_amount = false;

      if (
        this.categoryType === "" ||
        (this.categoryType === "Amount" && this.limitAmount === 0)
      ) {
        return (this.add = true);
      }

      let keys = Object.keys(this.mandate);

      keys.forEach((item) => {
        if (item == this.limitAmount) {
          return (this.duplicate_limit_amount = true);
        }
      });

      window.console.log(this.duplicate_limit_amount);

      if (this.duplicate_limit_amount === true) {
        return;
      }

      this.$store.commit("SET_CORPORATE_MANDATE", {
        categoryType: this.categoryType,
        amount: this.limitAmount,
        combination: this.dataArray,
      });

      let selected = [];

      this.dataArray.forEach((cat) => {
        selected.push(cat.label);
      });

      this.mandate[this.limitAmount ? this.limitAmount : 0] =
        selected.toString();

      window.console.log(this.mandate);

      this.dataArray = [];

      // let values = Object.values(this.selected);
      // values.forEach(item => {
      //   let index = this.Categories.indexOf(item);
      //   if (index != -1) this.Categories.splice(index, 1);
      // });
      selected = [];
      this.toggleDropdown();
    },

    //Director/CEO Label
    directorLabel() {
      const code = this.ACCOUNT_TYPE.code;
      let message;
      switch (code) {
        case "109":
          message = "Exco Members";
          break;
        case "108":
          message = "Trustees";
          break;
        case "106":
          message = "Business Owner/Proprietor";
          break;
        case "107":
          message = "Partner";
          break;
        case "111":
          message = "Party";
          break;
        case "103":
          message = "Director";
          break;
        default:
          message = "Director";
      }

      return message;
    },

    // Reset Mandate
    ResetMandate() {
      this.mandate = {};
      this.Categories = ["A", "B", "C", "D", "E", "F"];
      this.selected = [];
    },
    //complete validation
    async validationComplete() {
      this.loading = true;
      await CorporateAccountService.validationComplete({
        company: this.CORPORATE.COMPANY_ID,
        ceo: this.directors[0],
        compSec: this.directors[1],
        directors: this.directors,
        signatories: this.SIGNATORIES_IDS,
        creators: this.CORPORATE.CREATORS,
        mandate: this.mandate,
        referee: [
          {
            firstname: this.firstname[0],
            lastname: this.lastname[0],
            email: this.email[0],
            phone: this.phone[0],
            CompName: this.CompName[0],
          },
          {
            firstname: this.firstname[1],
            lastname: this.lastname[1],
            email: this.email[1],
            phone: this.phone[1],
            CompName: this.CompName[1],
          },
        ],
        idData: this.idData,
        boardRes: this.boardRes,
        licence: this.licence,
        utility: this.utility,
        scuml: this.scuml,
        debitAuth: this.debitAuth,
      })
        .then((res) => {
          this.validationCompleteId = res.id;

          this.otp1_modal = true;

          this.$store.commit("CLEAR_CORPORATE_MANDATE");
        })
        .catch((err) => this.$toastr.error(err.status, "Failed"))
        .finally(() => {
          this.loading = false;
        });
    },
    //complete process
    async completeProcess() {
      this.loading = true;
      await CorporateAccountService.completeProcess({
        company: this.CORPORATE.COMPANY_ID,
        id: this.validationCompleteId,
        otp1: this.otp1,
        otp2: this.otp2,
      })
        .then((res) => {
          this.responseData = res;
          this.otp2_modal = false;
          this.success_modal = true;
        })
        .catch((err) => this.$toastr.error(err.status, "Failed"))
        .finally(() => {
          this.loading = false;
        });
    },
    success() {
      this.$router.push("/corporate/register");
      this.$store.dispatch("CLEAR_CORPORATE_DATA");
    },

    // Increase and Decrease Mandate
    increase: function () {
      this.counter++;
    },
    decrease: function () {
      this.counter--;
    },
    reset: function () {
      this.counter = 0;
    },
  },
  mounted() {
    let data = [];
    this.SIGNATORIES.forEach((sign) => {
      data.push({
        value: sign.signatoryClass.toLowerCase(),
        label: sign.signatoryClass,
      });
    });
    //  this.$store.commit("CLEAR_CORPORATE_MANDATE");

    this.options = data;
  },
  beforeDestroy() {
    this.$store.commit("CLEAR_CORPORATE_MANDATE");
    this.$store.commit("CLEAR_CORPORATE_SIGNATORIES_ID");
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
  color: #000000;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}

.navbar {
  padding: 0;
}

.quick-transfer {
  padding: 0 2.2rem;
}

.form-control {
  padding: 10px 20px;
  font-size: 18px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1rem 1rem;
  width: 90%;
  text-align: center;
}

.modal-header {
  padding: 1rem 1rem 0 !important;
}

.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}

.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;

  align-items: center;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}

.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
}

.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
}

.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}

.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}

.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}

.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}

.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #ffffff !important;
}

.yellow_bg>.navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}

.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}

.about-title-sm {
  font-family: Montserrat-SemiBold;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.dropdown {
  &__header {
    cursor: pointer;

    i.fa {
      position: absolute;
      width: 50px;

      transition: opacity 0.3s;

      &.fa-angle-up {
        opacity: 0;
      }
    }

    &.is-active {
      i.fa {
        &.fa-angle-up {
          opacity: 1;
        }

        &.fa-angle-down {
          opacity: 0;
        }
      }

      +.dropdown__content {
        height: auto;
        opacity: 1;
        visibility: visible;
        position: absolute;
        z-index: 5;
        background: #ffffff;
        width: 200px;
      }
    }
  }

  &__content {
    height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 1px 10px !important;
    transition: opacity 0.3s;
    visibility: hidden;
  }
}

.main_dashboard {
  width: 90%;
  margin: 0 auto;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .main_dashboard {
    width: 100% !important;
  }
}
</style>
