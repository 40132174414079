import { BVNValidationService } from "../../../services/bvnValidation.service";

const actions = {
    CREATEACCOUNT: async (context, {
        Code,
        Bvn,
        nin,
        Phone,
        DateOfBirth,
    }) => {
        return await BVNValidationService.validationService({
            Code,
            Bvn,
            nin,
            Phone,
            DateOfBirth
        }).then(async (res) => {
            // Store the User Token
            //context.commit('SET_CREATE_ACCOUNT', res);
            return Promise.resolve(res);
        }).catch((error) => {
            return Promise.reject(error);
        });
    },

    CLEAR_CORPORATE_DATA: ({ commit }) => {
        commit("SET_CORPORATE_COMPANY_ID", ""),
            commit("SET_CORPORATE_COMPANY_NAME", ""),
            commit("SET_CORPORATE_DIRECTORS_NO", ""),
            commit("SET_CORPORATE_PARTIES_NO", ""),
            commit("SET_CORPORATE_SIGNATORIES_NO", ""),
            commit("RESET_CORPORATE_DIRECTORS", []),
            commit("RESET_CORPORATE_SIGNATORIES", []),
            commit("SET_CORPORATE_IS_COMPANY_SEC", false),
            commit("RESET_CORPORATE_SIGNATORIES_CREATORS", [])
    },

}
export {
    actions
}