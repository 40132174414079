<template>
  <div class>
    <div class="poster"></div>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />

    <div class="history_page">
      <div>
        <HeaderNavBiz />
        <div class="container">
          <BusinessNavBar />
          <div class="main_dashboard">
            <div class="row">
              <div class="col-md-12">
                <div class="main_dashboard_inner3">
                  <div class="transaction_history">
                    <div class="row">
                      <div class="col-md-8">
                        <p class="frequently-used">Transaction History</p>
                      </div>
                      <div class="col-md-3">
                        <!-- <button class="download-btn">
                          <font-awesome-icon icon="envelope" class="download-icon" />Send to email
                        </button> -->
                      </div>
                      <!-- <div class="col-md-2">
                        <button class="download-btn">
                          <font-awesome-icon icon="download" class="download-icon" />Download
                        </button>
                      </div> -->
                      <div class="col-md-1">
                        <div class="search-bar">
                          <b-button v-b-modal.modal-center class="continue-btn">
                            <font-awesome-icon
                              icon="search"
                              class="angle-icon"
                            />
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="recent-transactions">
                    <div class="label-title">
                      <label>Select Account to view History</label>
                      <div class="account_select">
                        <select
                          v-model="transferAccountTo"
                          name
                          id
                          class="account_type"
                        >
                          <option value="all">All</option>
                          <option
                            v-for="item in user.corporateAccounts"
                            :key="item + 'a'"
                            :value="item.accountNumber"
                          >
                            {{ item.accountNumber }} - {{ item.accountName }} -
                            {{ item.accountType }}
                          </option>
                        </select>
                        <font-awesome-icon icon="caret-down" class />
                      </div>
                    </div>
                    <table>
                      <tr>
                        <th>Transaction Type</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Description</th>
                        <th>Fees</th>
                        <th>Receipt</th>
                      </tr>

                      <tr v-for="(trans, index) in transHistory" :key="index">
                        <!-- <td>{{ trans.data["reference Number"] }}</td> -->

                        <td
                          @click="transactionDetails(index)"
                          style="cursor: pointer"
                        >
                          {{ trans["transactionType"] }}
                        </td>
                        <td>{{ trans.date }}</td>
                        <td>&#8358; {{ formatAmount(trans.amount) }}</td>
                        <td>{{ trans.description }}</td>
                        <td>{{ trans.fee ? trans.fee : 0 }}</td>
                        <td>
                          <div
                            @click="transactionDetails(index)"
                            style="cursor: pointer; text-decoration: none"
                            class="preview_btn"
                          >
                            View
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <b-modal
            id="modal-center"
            centered
            title="Transaction History"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            hide-footer
            ref="myModal"
            v-model="dateModal"
          >
            <form ref="form">
              <b-form-group
                :state="nameState"
                label-for="name-input"
                class="pin-info"
              >
                <!--<h1 class="pin-info text-center">Transaction History</h1>-->
                <div class="current_tab_title">
                  <font-awesome-icon icon="play" class="play-icon" />
                  <p class>See all your Transaction History</p>
                </div>
                <div class="kindly_enter_details">
                  <font-awesome-icon
                    icon="exclamation-circle"
                    class="exclamation-icon"
                  />
                  <p>Select the Date you want to fetch details from</p>
                </div>
                <div class="label-title">
                  <label class="text-left">Select Account</label>
                  <div class="account_select">
                    <select v-model="account" name id class="account_type">
                      <option
                        v-for="item in user.corporateAccounts"
                        :key="item + 'a'"
                        :value="item.accountNumber"
                      >
                        {{ item.accountNumber }} - {{ item.accountName }} -
                        {{ item.accountType }}
                      </option>
                    </select>
                    <font-awesome-icon icon="caret-down" class />
                  </div>
                </div>
                <div class="label-title2">
                  <label>Start Date</label>
                  <DatePicker v-if="oneoff" v-model="start_date" valueType="format" format="MM-DD-YYYY" placeholder="MM-DD-YYYY"></DatePicker>
                </div>
                <div class="label-title2">
                  <label>End Date</label>
                    <DatePicker v-if="oneoff" v-model="end_date" valueType="format" format="MM-DD-YYYY" placeholder="MM-DD-YYYY"></DatePicker>
                </div>
                <div class="send-or-download2">
                  <div @click="hideModal" class="download-btn2">
                    <font-awesome-icon
                      icon="angle-left"
                      class="download-icon"
                    />EXIT
                  </div>
                  <button
                    class="download-btn2"
                    @click.prevent="transactionHistory"
                  >
                    SEARCH
                    <font-awesome-icon
                      icon="angle-right"
                      class="download-icon2"
                    />
                  </button>
                </div>
              </b-form-group>
            </form>
          </b-modal>
        </div>
      </div>
      <Footer3 />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import HeaderNavBiz from "../../../../components/HeaderNav/HeaderNavBiz";
import BusinessNavBar from "../../../../components/HeaderNav/BusinessNavBar";
// import { Datetime } from "vue-datetime";
import { CorporateAccountService } from "../../../../services/CorporateAccount/corporateAccountTransaction.services.js";
//import { TransactionHistoryService } from "../../../../services/TransactionHistory.services.js";
import Loader from "../../../../components/Loader/Loader.vue";
import Footer3 from "../../../../components/Footer/Footer3";
import { mapGetters, mapState } from "vuex";
// import {mapGetters} from 'vuex';
export default {
  name: "history_.vue",
  components: {
    // datetime: Datetime,
    HeaderNavBiz,
    BusinessNavBar,
    Loader,
    Footer3,
  },

  data() {
    return {
      oneoff: true,
      start_date: null,
      end_date: null,
      transHistory: [],
      loading: false,
      transferAccountTo: "",
      transHistories: [],
      dateModal: false,
      accounts: [],
      account: "",
    };
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },
    formatDate(value) {
      let date = value.slice(0, -8);

      const newDate = moment(date).format("DD-MM-YYYY");

      return newDate;
    },
    getTransactionHistory() {
      CorporateAccountService.transHistory()
        .then((res) => {
          this.loading = false;
          this.transHistories = res;
          this.transHistory = res;
        })
        .catch((err) => {
          window.console.log(err);
          this.$toastr.error(err.status, "Could not fetch Transactions", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async transactionHistory() {
      this.loading = true;
      let history = [];

      const data = {
        start: this.start_date,
        end: this.end_date,
        account: this.account,
      };
      await CorporateAccountService.SearchtransHistory(data)
        .then((res) => {
          // window.console.log("this is the response", res);
          this.loading = false;
          this.transHistories = res;
          this.transHistory = res;
          if (this.account !== "" && this.account !== "all") {
            history = this.transHistories.filter(
              (trans) =>
                this.account == trans.account &&
                this.formatDate(trans.date) >=
                  moment(this.start_date).format("DD-MM-YYYY") &&
                this.formatDate(trans.date) <=
                  moment(this.end_date).format("DD-MM-YYY")
            );
          } else {
            history = this.transHistories.filter((trans) => {
              return (
                this.account == trans.account &&
                this.formatDate(trans.date) >=
                  moment(this.start_date).format("DD-MM-YYYY") &&
                this.formatDate(trans.date) <=
                  moment(this.end_date).format("DD-MM-YYY")
              );
            });
          }
        })
        .catch((err) => {
          window.console.log(err);
          this.$toastr.error(err.status, "Could not fetch Transactions", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
          // this.start_date = null;
          // this.end_date = null;
          // this.email = "";
          // this.account = "";
        });

      this.transHistory = history;
      this.dateModal = false;
      this.account = null;
      this.start_date = null;
      this.end_date = null;
    },
    // transactionHistory(){
    //    function formatDate (value) {
    //      let date = value.slice(0,-8)
    //      const newDate =  moment(date).format("DD-MM-YYYY")
    //      return newDate
    //    }

    //  let history =[]
    //  if(this.transferAccountTo !== "" && this.transferAccountTo !== "all"){
    //   history = this.transHistories.filter(trans => formatDate(trans.date) >= moment(this.start_date).format("DD-MM-YYYY")  && formatDate(trans.date) <= moment(this.end_date).format("DD-MM-YYY"))
    //  }else{
    //    history= this.transHistories.filter((trans) =>{
    //      return formatDate(trans.date) >= moment(this.start_date).format("DD-MM-YYYY")  &&  formatDate(trans.date) <= moment(this.end_date).format("DD-MM-YYY")
    //    })

    //  }

    //  this.transHistory = history
    //  this.dateModal = false
    //  this.start_date = null
    //  this.end_date = null

    // },
    transactionDetails(n) {
      let transactions = this.transHistory[n];
      this.$store.commit("SET_TRANSACTION_DATA", transactions);
      this.$router.push("Receipt_");
    },
    formatAmount(value) {
      let val = (value / 1).toFixed(2).replace(".", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    hideModal() {
      this.$refs["myModal"].hide();
      // this.exitModal = false
    },
  },
  watch: {
    transferAccountTo: function () {
      if (this.transferAccountTo === "all" || this.transferAccountTo === "") {
        return (this.transHistory = this.transHistories);
      }
      let history = this.transHistories.filter(
        (trans) => trans.account == this.transferAccountTo
      );
      window.console.log(history);
      return (this.transHistory = history);
    },
  },
  computed: {
    ...mapGetters({
      user: "GET_USER",
      filteredUserAccount: "GET_FILTERED_USER",
    }),
    ...mapState({
      AUTHENTICATION_TOKEN: (state) => state.User.AUTHENTICATION_TOKEN,
      TRANSFER_ACCOUNT: (state) => state.Transfer.TRANSFER_ACCOUNT,
      TRANSFER_ACCOUNT_FROM: (state) => state.Transfer.TRANSFER_ACCOUNT_FROM,
      TRANSFER_ACCOUNT_TO: (state) => state.Transfer.TRANSFER_ACCOUNT_TO,
      INSTITUTIONS: (state) => state.Transfer.INSTITUTIONS,
    }),
  },
  mounted() {
    this.getTransactionHistory();
    this.getInstitution();
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}
/*.dashboard-page{*/
/*display: flex;*/
/*justify-content: space-between;*/
/*flex-direction: column;*/
/*height: 100vh;*/
/*}*/
.navbar {
  padding: 0;
}
.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}
.quick-transfer {
  padding: 0 0.7rem;
}
.main_dashboard_inner3 {
  padding: 1.5rem 2rem 3rem 2rem;
}
.recent-transactions {
  max-height: 400px;
  overflow-y: scroll;
}
.continue-cib-btn {
  height: 39px;
  padding: 0;
}
.account_select {
  width: 100%;
}
/* .search-bar {
  background-image: linear-gradient(
    180deg,
    #00c4ff 0%,
    #0091ff 100%
  ) !important;
  border: 1px solid #00c4ff;
  padding: 0 0.27rem;
  height: 39px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
} */
table {
  text-align: center;
}
th {
  text-align: left;
  font-family: Montserrat-Medium;
  font-size: 12px;
  color: #000000;
  letter-spacing: 0.17px;
  /* background-image: linear-gradient(
    180deg,
    #00c4ff 0%,
    #0091ff 100%
  ) !important; */
}
td {
  font-family: Montserrat-Medium;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0;
}
tr:nth-child(odd) {
  background-color: #e5e5e5;
}
tr:nth-child(even) {
  background-color: #ffffff;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
/* .poster {
  background-color: rgb(0, 94, 194, 0.1) !important;
}
.current_tab_title{
  background-image: linear-gradient(
    180deg,
    #00c4ff 0%,
    #0091ff 100%
  ) !important;
}
.download-btn {
  background-image: linear-gradient(
    180deg,
    #00c4ff 0%,
    #0091ff 100%
  ) !important;
  border: 1px solid #00c4ff !important;
} */
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .account_select {
    width: 100%;
  }
}
</style>
