<template>
  <div class="hope-login-page">
    <div class="poster"></div>
    <Loader v-if="loading" :show-full="true" loading-text="Loading..." />
    <Loader v-if="loading1" :show-full="true" loading-text="Please wait..." />

    <div class="upper-nav">
      <div class="hope-logo">
        <a href="/">
          <img src="../../assets/images/logo/hope_bank_logo1.svg" alt="Hope Bank Logo" class="hope_logo" />
        </a>
      </div>
      <div class="back-logout" @click="$router.go(-1)">
        <!-- <router-link to="/"> -->
        <!-- <button class="go-back-btn" @click="$router.go(-1)"> -->
        <div class="go-back-btn">
          <span>
            <font-awesome-icon icon="arrow-left" class="angle-left" />
          </span>
          Back
        </div>
        <!-- </router-link> -->
        <!-- <router-link to="LoginBusiness">
          <button class="logout-btn2">Business</button>
        </router-link>-->
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
          <div class="login-section">
            <div class="left-side">
              <!-- <router-link to="/">
                    <img
                        src="../../assets/images/logo/hope_bank_logo1.svg"
                        alt="Hope Bank Logo"
                        class="hope_logo2"
                    />
                    </router-link> -->
              <p class="login-info">
                Confirm my NIN
              </p>
              <form @submit.prevent="confirmNin" class="form-login">
                <div class="user-login">
                  <div class="text-left nin-text-label">
                    <label for="">Account to debit</label>
                  </div>
                  <div class="username">
                    <select v-model="account" class="username-input">
                      <option active>Account to debit</option>
                      <option v-for="(item) in user.accounts" :key="item + 'a'" :value="item.accountNumber">
                        {{ item.accountNumber }}</option>
                    </select>
                    <font-awesome-icon icon="chevron-down" class />
                  </div>
                  <div class="text-left nin-text-label">
                    <label for="">Phone Number</label>
                  </div>
                  <div class="username">
                    <input type="text" class="username-input" placeholder="08xxxxxxxxx" v-model="phone" disabled />
                    <font-awesome-icon icon="phone" class="login_icons" />
                  </div>

                  <!-- <div class="username">
                            <input
                                vmodel="account"
                                type="tel"
                                class="username-input"
                                placeholder="Account Number"
                                required
                            />
                            <font-awesome-icon icon="user-circle" class="login_icons" />
                        </div> -->
                </div>
                <div class="login-button">
                  <button class="login-btn" type="submit">OK</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"-->
    <b-modal id="modal-center-service-charge" centered v-model="amountChargeModal" @show="resetModal" @hidden="resetModal"
      @ok="handleOk" hide-footer ref="myModal" keyboard="false" backdrop="static">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group label-for="name-input" class="pin-info">
          <!-- <h1 class="pin-info text-center">Congratulations</h1>
            <div class="line4"></div> -->
          <p class="modal_congrats">
            You will be charged
            <strong>N100</strong> for using this service. Click OK to continue.
          </p>
          <button class="ok-btn" @click="hideModal">OK</button>
        </b-form-group>
      </form>
    </b-modal>
    <b-modal id="modal-center-pin" centered title="PIN" @show="resetModal" @hidden="resetModal" @ok="handleOk" hide-footer
      v-model="confirm_transaction">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
          <h1 class="pin-info text-center">Enter PIN</h1>
          <div class="dialpad">
            <div class="form-group">
              <the-mask type="password" class="form-control" v-model="pin" mask="########" maxlength="8" id="txtBox"
                placeholder="******" />
            </div>
            <Keyboard @pressed="pin = $event" :pinLength="pin" @completed="validateNIN" :selfValue="pin"></Keyboard>
          </div>
        </b-form-group>
      </form>
    </b-modal>
    <b-modal id="modal-center-nin" centered v-model="showNIN" @show="resetModal" @hidden="resetModal" @ok="handleOk"
      hide-footer data-keyboard="false" data-backdrop="static">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group label-for="name-input" class="pin-info">
          <!-- <h1 class="pin-info text-center">Congratulations</h1>
            <div class="line4"></div> -->
          <p class="modal_congrats">
            Your NIN is
            <strong>{{ nin }}</strong>
          </p>
          <p class="modal_congrats">
            Your Name is
            <strong>{{ name }}</strong>
          </p>
          <router-link to="/">
            <button class="ok-btn">OK</button>
          </router-link>
        </b-form-group>
      </form>
    </b-modal>
    <Footer2 />
    <!-- <Footer/> -->

  </div>
</template>

<script>
import Loader from "../../components/Loader/Loader.vue";
// import { TheMask } from "vue-the-mask";
import { mapGetters, mapState } from "vuex";
import { getInstitutionService } from "../../services/getInstitution.services.js";
import Keyboard from "../../components/Keyboard/Keyboard.vue";
import Footer2 from "../../components/Footer/Footer2";
import { NINValidation } from "../../services/ninValidation.service"

export default {
  components: {
    Loader,
    // TheMask,
    Keyboard,
    Footer2,
  },

  data() {
    return {
      // phone: "",
      pin: "",
      account: "",
      nin: "",
      name: "",
      loading: false,
      loading1: false,
      errors: [],
      showPasswordModal: false,
      confirm_transaction: false,
      showNIN: false,
      amountChargeModal: false
    };
  },

  computed: {
    ...mapGetters({
      user: "GET_USER",
      filteredUserAccount: "GET_FILTERED_USER",

    }),

    ...mapState({
      AUTHENTICATION_TOKEN: (state) => state.User.AUTHENTICATION_TOKEN,
      TRANSFER_ACCOUNT: (state) => state.Transfer.TRANSFER_ACCOUNT,
      TRANSFER_ACCOUNT_FROM: (state) => state.Transfer.TRANSFER_ACCOUNT_FROM,
      TRANSFER_ACCOUNT_TO: (state) => state.Transfer.TRANSFER_ACCOUNT_TO,
      INSTITUTIONS: (state) => state.Transfer.INSTITUTIONS,
    }),
    phone: {
      get() {
        return this.user.phone
      },
      set(value) {
        this.user.phone = value;
      },
    }
  },
  methods: {
    hideModal() {
      // this.$refs["myModal"].hide();
      this.amountChargeModal = false
    },
    myModal() {
      this.amountChargeModal = true
      setTimeout(() => {
        // this.infoModal()
      }, 1000);
    },
    confirmNin() {
      this.confirm_transaction = true
    },
    validateNIN() {
      this.loading = true
      this.confirm_transaction = false;
      let data = {
        token: this.AUTHENTICATION_TOKEN,
        pin: this.pin,
        account: this.account
      }

      NINValidation.confirmNINService(data).then((res) => {
        this.nin = res.nin
        this.name = res.name
        this.showNIN = true

      })
        .catch((err) => {
          this.$toastr.error(err.status, "Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });

    },
    async getInstitution() {
      await getInstitutionService
        .getInstitution()
        .then((res) => {
          this.$store.commit("SET_INSTITUTIONS", res);
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Could not fetch institutions", {
            timeOut: 5000,
          });
        });
    },
  },
  mounted() {
    this.myModal();
    this.getInstitution();

  },
  created() {
    this.getBeneficiary();
    if (this.transferAccountFrom) return;
    this.$store.commit(
      "SET_TRANSFER_ACCOUNT_FROM",
      this.user.accounts[0].accountNumber
    );
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.go-back-btn:hover {
  /* color: #000000;
        background: #ffffff; */
}

.logout-btn2:hover {
  /* color: #ffffff;
        background: #000000; */
}

.modal-body {
  padding: 1rem !important;
}

.btns-biz-individual {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.btn-for-individual {
  font-family: Montserrat-SemiBold !important;
  font-size: 13px !important;
  color: #000000 !important;
  background: #fecb32 !important;
  border: 1px solid #fecb32 !important;
  border-radius: 5px;
  padding: 10px 1.5rem;
  letter-spacing: 0.17px;
  margin: 5px;
}

.btn-for-individual:hover {
  color: #000000 !important;
  background: #ffffff !important;
  border: 1px solid #fecb32 !important;
}

.btn-for-biz {
  font-family: Montserrat-SemiBold !important;
  font-size: 13px !important;
  color: #000000 !important;
  background: #fecb32 !important;
  border: 1px solid #fecb32 !important;
  border-radius: 5px;
  padding: 10px 1.5rem;
  letter-spacing: 0.17px;
  margin: 5px;
}

.btn-for-biz:hover {
  color: #000000 !important;
  background: #ffffff !important;
  border: 1px solid #fecb32 !important;
}

.left-side {
  width: 100%;
}

.username-input {
  position: relative;
  z-index: 10;
}

.username svg {
  margin-left: -2.5rem;
  position: relative;
  z-index: 0;
}
</style>