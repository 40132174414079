

const getters = {
    GET_BILL_DATA: (state) => state.BILL_DATA,
    GET_AIRTIME_DATA: (state) => state.AIRTIME_DATA
}

export {
    getters
}

