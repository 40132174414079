<template>
  <div class="poster-bg-">
    <div class="poster"></div>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />

    <div class="dashboard-page registration-page">
      <div class>
        <HeaderNavIB />
        <div class="register_now">
          <div class="container">
            <div class="main_dashboard">
              <div class="row">
                <div class="col-md-12">
                  <div class="main_dashboard_inner">
                    <div class="transfer_money2 text-center pt-3">
                      <p class="frequently-used text-center">Digital Banking Registration</p>
                    </div>
                    <div class="kindly_enter_details mb-3">
                      <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                      <p>
                        To register for Internet Banking, we require your
                        Account Number
                      </p>
                    </div>
                    <form @submit.prevent="Signup">
                      <div class="form-table">
                        <div class="label-title2">
                          <label>Account Number *</label>
                          <div class="account_select2">
                            <the-mask
                              mask="##########"
                              type="tel"
                              class="input-amount"
                              required
                              placeholder
                              v-model="Account"
                            />
                          </div>
                        </div>

                        <div class="continue-button float-right">
                          <b-button
                            :disabled="Account.toString().length != 10"
                            type="submit"
                            class="continue-btn"
                          >
                            Verify
                            <font-awesome-icon icon="angle-right" class="angle-icon" />
                          </b-button>

                          <!--<router-link to="Verify"><button class="continue-btn">Verify<font-awesome-icon icon="angle-right" class="angle-icon"/></button></router-link>-->
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />

      <b-modal
        id="modal-center3"
        centered
        v-model="otp_modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <h1 class="pin-info text-center">
              Enter the OTP that was
              <br />sent to your phone/email
            </h1>
            <div class="dialpad">
              <div class="form-group">
                <the-mask
                  type="password"
                  class="form-control"
                  v-model="otp"
                  mask="########"
                  id="txtBox"
                  placeholder="******"
                />
              </div>
              <Keyboard
                @pressed="otp = $event"
                :pinLength="otp"
                @completed="save_otp"
                :selfValue="otp"
              ></Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import HeaderNavIB from "../../components/HeaderNav/HeaderNavIB";
import { TheMask } from "vue-the-mask";
import { userService } from "../../services/user.service.js";
import Loader from "../../components/Loader/Loader.vue";
import Keyboard from "../../components/Keyboard/Keyboard.vue";
import Footer2 from "../../components/Footer/Footer2";

export default {
  name: "Signup.vue",
  components: {
    HeaderNavIB,
    TheMask,
    Loader,
    Keyboard,
    Footer2
  },
  data() {
    return {
      date: null,
      image: "",
      Account: "",
      loading: false,
      otp_modal: false,
      otp: ""
    };
  },

  methods: {
    async Signup() {
      this.loading = true;
      let data = {
        Account: this.Account
      };
      await userService
        .verifyAccount(data)
        .then(res => {
          this.$toastr.success(res.status, { timeOut: 5000 });
          this.$store.commit("SET_VALIDATION_CREDENTIALS", res);
          this.otp_modal = true;
        })
        .catch(error => {
          this.$toastr.error(error.status, "Registration Failed!", {
            timeOut: 5000
          });
        })
        .finally(() => {
          this.loading = false;
        })
      
    },
    save_otp() {
      this.$store.commit("SET_VERIFICATION_CODE", this.otp);
      this.$store.commit("SET_ACCOUNT_NUMBER", this.Account)
      this.$router.push("SecurityQuestion");
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}
.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.quick-transfer {
  padding: 0 2.2rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
  /* letter-spacing: 0.17px; */
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}
.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}
.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}
/*.plus_icon{*/
/*margin-right: 1rem;*/
/*font-size: 20px;*/
/*}*/
.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #ffffff !important;
}
.yellow_bg > .navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}
.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}
.registration-page {
  height: 100vh !important;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.main_dashboard {
  width: 70%;
  margin: 0 auto;
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .poster-bg {
    height: 100vh;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .poster-bg {
    height: 100vh;
  }
  .main_dashboard {
    width: 100% !important;
  }
}
</style>
