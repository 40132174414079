<template>
  <div class="nav_header2">
    <b-navbar toggleable="lg" type="light">
      <b-navbar-brand href="/">
        <img src="../../assets/images/logo/hope_bank_logo1.svg" alt="Hope Bank Logo" class="hope_logo" />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto white_bg">
          <b-navbar-nav class="nav_header_inner">
            <b-nav-item href="/">Home</b-nav-item>
            <b-navbar-nav>
              <div class="dropdown">
                <button class="nav_menu_drop dropdown-toggle" data-toggle="dropdown">
                  NIN or BVN
                </button>
                <div class="dropdown-menu">
                  <router-link class="dropdown-item" to="nin">Registration</router-link>
                  <router-link class="dropdown-item" to="enrolmentcenter">Enrollment Centers</router-link>
                  <router-link class="dropdown-item" to="confirmmynin">Confirm My NIN</router-link>
                  <router-link class="dropdown-item" to="verifyid">Verify Identity</router-link>
                </div>
              </div>
            </b-navbar-nav>
            <b-navbar-nav>
              <div class="dropdown">
                <!-- <router-link to="about"> -->
                <button class="nav_menu_drop dropdown-toggle active" data-toggle="dropdown">
                  About Us
                </button>
                <!-- </router-link> -->
                <div class="dropdown-menu">
                  <router-link class="dropdown-item" to="about">Corporate Profile</router-link>
                  <router-link class="dropdown-item" to="about2">Our History</router-link>
                  <router-link class="dropdown-item" to="about3">Our Vision &amp; Mission</router-link>
                  <router-link class="dropdown-item" to="about4">Our Core Values</router-link>
                  <router-link class="dropdown-item" to="about5">Management</router-link>
                  <router-link class="dropdown-item" to="about6">Board of Directors</router-link>
                </div>
              </div>
            </b-navbar-nav>
            <b-navbar-nav>
              <div class="dropdown">
                <router-link to="Contact">
                  <button type="button" class="nav_menu_drop dropdown-toggle" data-toggle="dropdown">
                    Contact Us
                  </button>
                </router-link>
                <div class="dropdown-menu">
                  <router-link class="dropdown-item" to="Contact">Contact Us</router-link>
                  <router-link class="dropdown-item" to="LocateUs">Branch Locations</router-link>
                </div>
              </div>
            </b-navbar-nav>
            <b-navbar-nav>
              <div class="dropdown">
                <router-link to="Agency">
                  <button type="button" class="nav_menu_drop dropdown-toggle active" data-toggle="dropdown">
                    Agency
                  </button>
                </router-link>
                <div class="dropdown-menu">
                  <a :href="agentLink" target="_blank" class="dropdown-item">Agent Login</a>
                  <router-link class="dropdown-item" to="Agency" v-scroll-to="'#agent'">Become An Agent</router-link>
                  <router-link class="dropdown-item" to="/Agency" v-scroll-to="'#howto'">
                    Become A Branch Partner/ Agent Manager
                  </router-link>
                  <router-link class="dropdown-item" to="agentlocation" v-scroll-to="'#howto'">
                    Agent Locator
                  </router-link>
                </div>
              </div>
            </b-navbar-nav>
            <b-navbar-nav>
              <div class="dropdown">
                <!-- <router-link to="products"> -->
                <button type="button" class="nav_menu_drop dropdown-toggle active" data-toggle="dropdown">
                  Products
                </button>
                <!-- </router-link> -->
                <div class="dropdown-menu">
                  <!-- <router-link
                      class="dropdown-item"
                      to="/howtotransact"
                      v-scroll-to="'#howtotransact'"
                    >How to Transact</router-link> -->
                  <router-link class="dropdown-item" to="products" v-scroll-to="'#pro'">Products &amp;
                    Services</router-link>
                  <router-link class="dropdown-item" to="/products" v-scroll-to="'#ineed'">
                    Individual Needs
                  </router-link>
                  <router-link class="dropdown-item" to="/products" v-scroll-to="'#bizneed'">
                    Business Needs
                  </router-link>
                </div>
              </div>
            </b-navbar-nav>
            <b-nav-item>
              <router-link to="howtotransact" style="color: #ffffff">How to Transact</router-link>
            </b-nav-item>
            <b-navbar-nav>
              <div class="dropdown">
                <router-link to="Start">
                  <button type="button" class="nav_menu_drop dropdown-toggle" data-toggle="dropdown">
                    Open Account
                  </button>
                </router-link>
                <div class="dropdown-menu">
                  <!-- <router-link class="dropdown-item" to="Register"
                    >Complete Ongoing Account Process</router-link
                  > -->
                  <span style="cursor: pointer" @click="ongoingAccountProcessModal = true" class="dropdown-item"
                    to="Register">Complete Ongoing Account Process</span>
                  <router-link class="dropdown-item" to="Register">Personal Account</router-link>
                  <router-link class="dropdown-item" to="Business">Business Account</router-link>
                </div>
              </div>
            </b-navbar-nav>
          </b-navbar-nav>
          <b-navbar-nav class="yellow_bg edge--top--reverse">
            <b-nav-item class="logout-btn">
              <router-link to="digitalbanking">
                <font-awesome-icon icon="lock" class="plus_icon" />Digital
                Banking
              </router-link>
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
      <!-- <b-collapse  is-nav></b-collapse> -->
    </b-navbar>
    <b-modal id="modal-center-bvn-info" centered v-model="ongoingAccountProcessModal" @show="resetModal"
      @hidden="resetModal" @ok="handleOk" hide-footer>
      <form ref="form" @submit.stop.prevent="ongoingAccountProcessModal = true">
        <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
          <div class="dialpad">
            <div class="start_account_buttons">
              <router-link to="OngoingPersonalAccount">
                <button class="start_account_btn">Personal Account</button>
              </router-link>
            </div>
            <div class="start_account_buttons">
              <router-link to="OngoingBusinessAccount">
                <button class="start_account_btn">Business Account</button>
              </router-link>
            </div>

            <!-- <button class="ok-btn" type="submit">Submit</button> -->
          </div>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "HeaderNav.vue",
  data() {
    return {
      ongoingAccountProcessModal: false,
      str: 'https://agency.hopepsbank.com/login',
    };
  },
  computed: {
    agentLink() {
      return this.str.replace(/%20/g, " ")
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  /*color: #FECB32;*/
}

.slider {
  width: 100%;
  height: 105vh !important;
  position: absolute;
  z-index: 0;
}

.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
  /* letter-spacing: 0.17px; */
}

.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
  /*border-bottom: 3px solid #FECB32;*/
}

.navbar-light .navbar-nav .nav-link.active {
  color: #fecb32;
  /* border-bottom: 3px solid #FECB32; */
}

.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
  /*border-bottom: 3px solid #FECB32;*/
}

.white_bg {
  background: #000000;
  border-radius: 5px;
  height: 44px;
}

.nav_header_inner {
  padding: 0 1rem;
  background: #000000;
  border-radius: 5px 0 0 5px;
}

.nav_menu_drop {
  color: #ffffff !important;
}

.yellow_bg {
  background: #ffffff;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
  /* border-left: 25px solid transparent; */
}

.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}

.yellow_bg a:hover {
  color: #000000 !important;
}

.yellow_bg>.navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0.25px;
}

.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
  /* border-left: 25px solid transparent; */
}

.edge--top--reverse {
  position: relative;
  z-index: 1;
}

.edge--top--reverse:before {
  background: inherit;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}

.edge--top--reverse:before {
  top: 0;
  -webkit-transform: skewX(-23deg);
  transform: skewX(-23deg);
  -webkit-transform-origin: 45% 0;
  transform-origin: 45% 0;
}
</style>
