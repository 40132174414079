const mutations = {
    SET_CREATE_ACCOUNT: (state, account) => state.ACCOUNT = account,
    SET_ACCOUNT_OTP(state, payload) {
        state.ACCOUNT_OTP = payload
    },
    SET_ACCOUNT_TYPE(state, payload) {
        state.ACCOUNT_TYPE = payload
    },
    SET_ACCOUNT_CODE(state, payload) {
        state.ACCOUNT_CODE = payload
    },
    SET_ACCOUNT_ID(state, payload) {
        state.ACCOUNT_ID = payload
    },
    SET_ACCOUNT_PIN(state, payload) {
        state.ACCOUNT_PIN = payload
    },
    SET_ACCOUNT_NUMBER(state, payload) {
        state.ACCOUNT_NUMBER = payload
    },
    SET_BVN_DETAILS(state, payload) {
        state.BVN_DETAILS = payload
    },
    SET_REGISTRATION_DETAILS(state, payload) {
        state.REGISTRATION_DETAILS = payload
    },
    SET_COMPLETE_ONGOING_ACCOUNT_PROCESS(state, payload) {
        state.COMPLETE_ONGOINIG_ACCOUNT_PROCESS = payload
    },



    //MUTATIONS FOR CORPORATE Account creation
    //Creation
    SET_CORPORATE_COMPANY_ID(state, payload) {
        state.CORPORATE_ACCOUNT.COMPANY_ID = payload
    },
    SET_CORPORATE_COMPANY_NAME(state, payload) {
        state.CORPORATE_ACCOUNT.COMPANY_NAME = payload
    },
    SET_CORPORATE_DIRECTORS_NO(state, payload) {
        state.CORPORATE_ACCOUNT.DIRECTORS_NO = payload
    },
    SET_PARTIES_NO(state, payload) {
        state.CORPORATE_ACCOUNT.PARTIES_NO = payload
    },
    SET_CORPORATE_SIGNATORIES_NO(state, payload) {
        state.CORPORATE_ACCOUNT.SIGNATORIES_NO = payload
    },
    SET_CORPORATE_SIGNATORIES(state, payload) {
        state.CORPORATE_ACCOUNT.SIGNATORIES.push(payload)
    },
    SET_CORPORATE_SIGNATORIES_ID(state, payload) {
        state.CORPORATE_ACCOUNT.SIGNATORIES_ID.push(payload)
    },
    SET_CORPORATE_MANDATE(state, payload) {
        state.CORPORATE_ACCOUNT.MANDATES.push(payload)
    },
    CLEAR_CORPORATE_MANDATE(state) {
        state.CORPORATE_ACCOUNT.MANDATES = []
    },
    CLEAR_CORPORATE_SIGNATORIES_ID(state) {
        state.CORPORATE_ACCOUNT.SIGNATORIES_ID = []
    },
    SET_CORPORATE_SIGNATORIES_CREATORS(state, payload) {
        state.CORPORATE_ACCOUNT.CREATORS.push(payload)
    },
    RESET_CORPORATE_SIGNATORIES(state, payload) {
        state.CORPORATE_ACCOUNT.SIGNATORIES = payload
    },
    RESET_CORPORATE_SIGNATORIES_CREATORS(state, payload) {
        state.CORPORATE_ACCOUNT.CREATORS = payload
    },
    SET_CORPORATE_DIRECTORS(state, payload) {
        state.CORPORATE_ACCOUNT.DIRECTORS.push(payload);
    },
    RESET_CORPORATE_DIRECTORS(state, payload) {
        state.CORPORATE_ACCOUNT.DIRECTORS = payload;
    },
    SET_CORPORATE_IS_COMPANY_SEC(state, payload) {
        state.CORPORATE_ACCOUNT.IS_COMPANY_SEC = payload
    },

    //Authentication
    SET_CORPORATE_PHONE(state, payload) {
        state.CORPORATE_ACCOUNT.PHONE = payload
    },
    SET_CORPORATE_ACCOUNT(state, payload) {
        state.CORPORATE_ACCOUNT.ACCOUNT = payload
    },

}

export {
    mutations
}