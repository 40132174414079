<template>
  <div class="nav_header">
    <b-navbar toggleable="lg" type="light" variant="white">
      <b-navbar-brand href="/">
        <img
          src="../../assets/images/logo/hope_bank_logo1.svg"
          alt="Hope Bank Logo"
          class="logo-small"
        />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item>
            <router-link to="Dashboard">Dashboard</router-link>
          </b-nav-item>
          <!-- <b-navbar-nav>
            <div class="dropdown">
              <button
                class="nav_menu_drop dropdown-toggle"
                data-toggle="dropdown"
              >Accounts</button>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="register_">Open Additional Accounts</router-link>
                <router-link class="dropdown-item" to="bulkwallet">Open Bulk Wallets</router-link>
                <router-link class="dropdown-item" to="History">Account History</router-link>
                <router-link class="dropdown-item" to="StatementGenerate">Statement Generate</router-link>
              </div>
            </div>
          </b-navbar-nav> -->
          <b-navbar-nav>
            <div class="dropdown">
              <button
                type="button"
                class="nav_menu_drop dropdown-toggle"
                data-toggle="dropdown"
              >Accounts</button>
              <div class="dropdown-menu">
                <!-- <router-link class="dropdown-item" to="#" style="position:relative">
                  <div ref="dropdownToggle" style="position:relative">
                    <router-link to="#" style="position:relative">
                      <button
                        type="button"
                        class="nav_menu_drop dropdown-toggle ml-n2"
                        data-toggle="dropdown"
                        @click="toggleDropdown"
                      >Open Additional Accounts</button>
                    </router-link>
                    <div
                      class="dropdown-menu"
                      style="position:relative"
                      @mouseleave="toggleDropdown"
                    >
                      <router-link class="dropdown-item" to="register_">Hope Wallet</router-link>
                      <router-link class="dropdown-item" to="register_">Hope Wallet for 3rd Party</router-link>
                    </div>
                  </div>
                </router-link> -->
                <router-link class="dropdown-item" to="register_">Open Additional Accounts</router-link>
                <router-link class="dropdown-item" to="HopeWalletFor3rdParty">Hope Wallet for 3rd Party</router-link>
                <router-link class="dropdown-item" to="bulkwallet">Open Bulk Wallets for 3rd Party</router-link>
                <router-link class="dropdown-item" to="History">Account History</router-link>
                <router-link class="dropdown-item" to="StatementGenerate">Generate Statement</router-link>
              </div>
            </div>
          </b-navbar-nav>
          <!--<b-nav-item-dropdown text="Accounts" right class="nav_dashboard" >-->
          <!--<b-dropdown-item ><router-link to="History">Account History</router-link></b-dropdown-item>-->
          <!--<b-dropdown-item ><router-link to="StatementGenerate">Statement Generate</router-link></b-dropdown-item>-->
          <!--<b-dropdown-item ><router-link to="Cheque">Cheque</router-link></b-dropdown-item>-->
          <!--</b-nav-item-dropdown>-->
          <b-navbar-nav>
            <div class="dropdown">
              <router-link to="TransferMoney">
                <button
                  class="nav_menu_drop dropdown-toggle"
                  data-toggle="dropdown"
                >Transfer</button>
              </router-link>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="TransferMoney">Transfer Money</router-link>
                <router-link class="dropdown-item" to="ManageSchedule">Manage Schedule</router-link>
                <!--<router-link class="dropdown-item" to="TransferMoney">To Other Banks</router-link>-->
                <!--<router-link class="dropdown-item" to="TransferMoney">Int'l Transfer</router-link>-->
                <!--<router-link class="dropdown-item" to="TransferMoney">Schedule Transfer</router-link>-->
              </div>
            </div>
          </b-navbar-nav>
          <b-navbar-nav>
            <div class="dropdown">
              <button
                class="nav_menu_drop dropdown-toggle"
                data-toggle="dropdown"
              >Payments</button>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="airtimedata">Airtime/Data</router-link>
                <router-link class="dropdown-item" to="BillPayment">Bills Payment</router-link>
              </div>
            </div>
          </b-navbar-nav>
          <!--<b-nav-item-dropdown text="Payments" right class="nav_dashboard">-->
          <!--<b-dropdown-item ><router-link to="AirtimeData">Airtime/Data</router-link></b-dropdown-item>-->
          <!--<b-dropdown-item ><router-link to="BillPayment">Bill Payments</router-link></b-dropdown-item>-->
          <!--</b-nav-item-dropdown>-->
          <b-navbar-nav>
            <div class="dropdown">
              <button
                class="nav_menu_drop dropdown-toggle"
                data-toggle="dropdown"
              >Self Service</button>
              <div class="dropdown-menu">
                <!-- <router-link class="dropdown-item" to="TransferLimit">Transaction Limit</router-link> -->
                <router-link class="dropdown-item" to="AddBeneficiary">Add Beneficiary</router-link>
                <router-link class="dropdown-item" to="ManageBeneficiary">Manage Beneficiary</router-link>
                <router-link class="dropdown-item" to="nin">NIN or BVN</router-link>
                <router-link class="dropdown-item" to="enrolmentcenter">Enrollment Centers</router-link>
                <router-link class="dropdown-item" to="confirmmynin">Confirm My NIN</router-link>
                <router-link class="dropdown-item" to="verifyid">Verify Identity</router-link>
                <router-link class="dropdown-item" to="UpdateInfo">Update Info</router-link>

                <!-- <router-link class="dropdown-item" to="Card">Manage Card</router-link> -->
              </div>
            </div>
          </b-navbar-nav>
          <b-navbar-nav>
            <div class="dropdown">
              <button
                class="nav_menu_drop dropdown-toggle"
                data-toggle="dropdown"
              >Security</button>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="ChangePin">Change PIN</router-link>
                <router-link class="dropdown-item" to="ResetPin">Reset PIN</router-link>
                <router-link class="dropdown-item" to="ChangePassword">Change Password</router-link>
                <router-link class="dropdown-item" to="setpin">PIN Setup</router-link>
                <!-- <router-link class="dropdown-item" to="UpdateSecurityQuestion">Update Security Question</router-link> -->
              </div>
            </div>
          </b-navbar-nav>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item class="quick-transfer">
            <router-link to="Menu">
              <font-awesome-icon icon="bars" class="bars" />MENU
            </router-link>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "HeaderNav3.vue",
  methods: {
    toggleDropdown() {
      this.$refs.dropdownToggle.classList.toggle("dropdown");
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #4a4a4a;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.nav-link {
  font-family: Montserrat-Medium;
  font-size: 12px;
  color: #4a4a4a;
  /* font-weight: 400; */
  letter-spacing: 0.17px;
}
.navbar-nav {
  padding-left: 3.5rem !important;
}
.about-page-header .dropup,
.dropright,
.dropdown,
.dropleft {
  margin-top: 0 !important;
}
.quick-transfer {
  padding: 0 0.7rem;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .navbar-nav {
    padding-left: 0 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-nav {
    padding-left: 0 !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .navbar-nav {
    padding-left: 2rem !important;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1350px) {
  .navbar-nav {
    padding-left: 2rem !important;
  }
}
</style>