<template>
  <div class="hope-landing">
    <div class="hope-slider">
      <HeaderNav></HeaderNav>

      <div>
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          background="#ababab"
          img-width="1024"
          img-height="480"
          controls
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <b-carousel-slide>
            <template v-slot:img>
              <img
                class="slider-img"
                src="../../assets/images/slider/bg.svg"
                alt="Hope Bank"
              />
              <!-- <img
                class="slider-img"
                src="../../assets/images/hope-bank-sliders.jpg"
                alt="Hope Bank"
              /> -->
            </template>
            <div class="row ml-auto mr-auto">
              <div class="col-md-6">
                <div
                  class="slider-title2"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                >
                  <div></div>
                  <div></div>
                  <div class="slider-title">
                    <h1>
                      <span>*569*9# Hope Wallet</span>
                    </h1>
                    <div class="slider-info">
                      <img
                        src="../../assets/images/slider/wallet.svg"
                        alt="Hope Bank"
                        class="slide_icon"
                      />
                      <!-- <img
                        src="../../assets/images/icons/dial-ussd.svg"
                        alt="Hope Bank"
                        class="slide_icon"
                      />-->
                      <div style="color: #000">
                        <ul>
                          <li>Multipurpose Wallet for ATM, POS, Web & P2P</li>
                          <li>
                            No cards required but rather phone number at ATM,
                            POS, Web etc
                          </li>
                          <li>
                            Create Hope Wallets for Self, Staff, family &
                            friends
                          </li>
                          <li>Receive Transfer & Send Money to Bank Account</li>
                          <li>
                            No matter your existing bank, get Hope Wallet for
                            your regular payments at all channels
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="agents-buttons2">
                      <router-link to="digitalbanking">
                        <button class="agent-signin-btn2">
                          Digital Banking
                        </button>
                      </router-link>
                      <router-link to="Start">
                        <button class="agent-signup-btn2">Open Account</button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 hide_on_mobile">
                <div
                  class="slider-pictures"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                >
                  <img
                    src="../../assets/images/slider/hopepsbank-ussd.png"
                    alt="Hope Bank"
                    class
                  />
                </div>
              </div>
            </div>
          </b-carousel-slide>
          <b-carousel-slide>
            <template v-slot:img>
              <img
                class="slider-img"
                src="../../assets/images/slider/bg.svg"
                alt="Hope Bank"
              />
              <!-- <img
                class="slider-img"
                src="../../assets/images/hope-bank-slider2a.jpg"
                alt="Hope Bank"
              /> -->
            </template>
            <div class="row">
              <div class="col-md-6">
                <div
                  class="slider-title2"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                >
                  <div></div>
                  <div></div>
                  <div class="slider-title">
                    <h1>
                      <span>Personal Assistant</span>
                    </h1>
                    <div class="slider-info">
                      <img
                        src="../../assets/images/slider/assist.svg"
                        alt="Hope Bank"
                        class="slide_icon"
                      />
                      <div style="color: #000">
                        <ul>
                          <li>Let your Hope Account manage your calendar</li>
                          <li>Scheduled payments</li>
                          <li>Renewal of subscriptions</li>
                          <li>We help you build a good reputation.</li>
                        </ul>
                      </div>
                    </div>
                    <div class="agents-buttons2">
                      <router-link to="digitalbanking">
                        <button class="agent-signin-btn2">
                          Digital Banking
                        </button>
                      </router-link>
                      <router-link to="Start">
                        <button class="agent-signup-btn2">Open Account</button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 hide_on_mobile">
                <div
                  class="slider-pictures2"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                >
                  <img
                    src="../../assets/images/slider/hopebank-slide-img.png"
                    alt="Hope Bank"
                    class
                  />
                </div>
              </div>
            </div>
          </b-carousel-slide>
          <b-carousel-slide>
            <template v-slot:img>
              <img
                class="slider-img"
                src="../../assets/images/slider/bg.svg"
                alt="Hope Bank"
              />
            </template>
            <div class="row">
              <div class="col-md-6">
                <div class="slider-title2">
                  <div></div>
                  <div></div>
                  <div class="slider-title">
                    <h1>
                      <span>Telephone POS</span>
                      <!-- <span>Pay with Ease at your comfort 24/7 Digitally.</span> -->
                    </h1>
                    <div class="slider-info">
                      <img
                        src="../../assets/images/slider/pos.svg"
                        alt="Hope Bank"
                        class="slide_icon"
                      />
                      <div class>
                        <ul style="color: #000">
                          <li>Your telephone handset is now a POS device.</li>
                          <li>
                            Enter payee or customer’s number on your handset and
                            they instantly approve payment on their own phone
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="agents-buttons2">
                      <router-link to="digitalbanking">
                        <button class="agent-signin-btn2">
                          Digital Banking
                        </button>
                      </router-link>
                      <router-link to="Start">
                        <button class="agent-signup-btn2">Open Account</button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 hide_on_mobile">
                <div class="slider-pictures">
                  <img
                    src="../../assets/images/slider/slider-telephone.png"
                    alt="Hope Bank"
                    class
                  />
                </div>
              </div>
            </div>
          </b-carousel-slide>
          <b-carousel-slide>
            <template v-slot:img>
              <img
                class="slider-img"
                src="../../assets/images/slider/bg.svg"
                alt="Hope Bank"
              />
            </template>
            <div class="row">
              <div class="col-md-6">
                <div class="slider-title2">
                  <div></div>
                  <div></div>
                  <div class="slider-title">
                    <h1>
                      <span>Digital Collections</span>
                    </h1>
                    <!-- <h1>
                  Airtime, Data?
                  <span>we’ve got you covered</span>
                    </h1>-->
                    <div class="slider-info">
                      <img
                        src="../../assets/images/slider/digital.svg"
                        alt="Hope Bank"
                        class="slide_icon"
                      />
                      <div class>
                        <ul style="color: #000">
                          <li>
                            Send payment invoice to multiple phone numbers
                          </li>
                          <li>
                            Instant collection of bills, offerings, tithes, dues
                          </li>
                        </ul>
                        <!-- <p>Top up Airtime/Date “On the Go” fast and easy via any of our channels:</p>
                    <ul style="color: #000;">
                      <li>USSD: *569*9#</li>
                      <li>Mobile</li>
                      <li>Internet Banking</li>
                      <li>Agent Outlets</li>
                        </ul>-->
                      </div>
                    </div>
                    <div class="agents-buttons2">
                      <router-link to="digitalbanking">
                        <button class="agent-signin-btn2">
                          Digital Banking
                        </button>
                      </router-link>
                      <router-link to="Start">
                        <button class="agent-signup-btn2">Open Account</button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 hide_on_mobile">
                <div class="slider-pictures3">
                  <img
                    src="../../assets/images/slider/slider-digital-collection.png"
                    alt="Hope Bank"
                    class
                  />
                </div>
              </div>
            </div>
          </b-carousel-slide>
          <b-carousel-slide>
            <template v-slot:img>
              <img
                class="slider-img"
                src="../../assets/images/slider/bg.svg"
                alt="Hope Bank"
              />
            </template>
            <div class="row">
              <div class="col-md-6">
                <div class="slider-title2">
                  <div></div>
                  <div></div>
                  <div class="slider-title">
                    <h1>
                      <span>Risk Management</span>
                      <!-- <span>Pay with Ease at your comfort 24/7 Digitally.</span> -->
                    </h1>
                    <div class="slider-info">
                      <img
                        src="../../assets/images/slider/risk.svg"
                        alt="Hope Bank"
                        class="slide_icon"
                      />
                      <div class>
                        <ul style="color: #000">
                          <li>We have raised the bar</li>
                          <li>6-8 digit PIN</li>
                          <li>
                            Enter PIN on only your own handset or personal
                            device
                          </li>
                          <li>
                            No card or account details at points of transaction
                            – only phone number
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="agents-buttons2">
                      <router-link to="digitalbanking">
                        <button class="agent-signin-btn2">
                          Digital Banking
                        </button>
                      </router-link>
                      <router-link to="Start">
                        <button class="agent-signup-btn2">Open Account</button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 hide_on_mobile">
                <div class="slider-pictures3">
                  <img
                    src="../../assets/images/slider/slider-risk.png"
                    alt="Hope Bank"
                    class
                  />
                </div>
              </div>
            </div>
          </b-carousel-slide>
        </b-carousel>
      </div>

      <div class="angle_down__">
        <div class="angle_down_">
          <a href="#" v-scroll-to="'#easy_banking'">
            <img
              src="../../assets/images/icons/angle-down.svg"
              alt="Move Down"
              class="bounce-1 angledown"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="container" id="easy_banking">
      <transition name="fade">
        <div
          class="landing_page_section"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <div class="row">
            <div class="col-md-6">
              <div class="landing_page_left_section">
                <div class="image_moving1">
                  <div class="image_moving1_inner">
                    <img
                      src="../../assets/images/hopebank-app.png"
                      alt="Hope Bank"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
              <div class="landing_page_right_section">
                <h2>Banking has never been so awesome.</h2>
                <p>
                  Hope Payment Service Bank Limited (Hope PSBank) is Nigeria’s
                  premier Digital-first Bank. Incorporated in September, 2019,
                  Hope PSBank leverages digital technologies to deliver
                  inclusive financial services.
                </p>
                <p>
                  Our belief is that everyone irrespective of education, social
                  or economic status and location should have access to
                  financial and payment services with ease and convenience. We
                  seek to provide the 4As - Accessibility, Affordability,
                  Availability and Awareness of Digital Financial Services.
                </p>
                <h6>Ok, Get started</h6>
                <div class="open_account">
                  <router-link to="Start">
                    <button class="open_account_btn">Open Account</button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <div
        class="great_features mt-5 mb-5"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <div class>
          <div class="title_banking_category">
            <h2>Products Features</h2>
          </div>
          <b-tabs>
            <b-tab active>
              <template v-slot:title>
                <img
                  src="../../assets/images/icons/transfer2.svg"
                  alt="Hope Bank"
                />
                <br />Transfer Money
              </template>
              <div class="row">
                <div class="col-md-12">
                  <div class="great_features_info">
                    <p>
                      Easy, Fast and Safe way to send and receive money. You can
                      receive money into your accounts by transfer to just your
                      phone number. You can also transfer money from your
                      account with the use of just phone number
                    </p>
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab>
              <template v-slot:title>
                <img
                  src="../../assets/images/icons/buy-airtime2.svg"
                  alt="Hope Bank"
                />
                <br />Buy Airtime/Data
              </template>
              <div class="row">
                <div class="col-md-12">
                  <div class="great_features_info">
                    <p>
                      <b>Online Mobile Recharges with Hope PSBank.</b>
                    </p>
                    <p>
                      Online Mobile recharges are quicker and easier with Hope
                      PSBank. Recharge using our website or via The Hope Digital
                      App or USSD *569*9# to enjoy convenient mobile recharge at
                      your fingertips.
                    </p>
                    <p>
                      <b>Recharge whenever anywhere</b>
                    </p>
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab>
              <template v-slot:title>
                <img
                  src="../../assets/images/icons/bill-payment2.svg"
                  alt="Hope Bank"
                />
                <br />Bill Payment
              </template>
              <div class="row">
                <div class="col-md-12">
                  <div class="great_features_info">
                    <p>
                      <b>Online Bills Payment with Hope PSBank</b>
                    </p>
                    <!-- <p>
                      Key benefits of making Online Bill Payments with Hope PS
                      Bank:
                    </p>-->
                    <p>
                      <b>Convenience + Options</b>
                    </p>
                    <p>
                      With the number of billers we have on our platform, bills
                      payment has been made convenient at Hope PSBank. Pay your
                      bills online, faster, and at your comfort.
                    </p>
                    <!-- <p>
                      <b>Safety:</b>
                    </p>
                    <p>
                      Our PIN authentication is 6 – 8 Digits thus making it more
                      secure and less prone to hacking than 4 digits.
                    </p>-->
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab>
              <template v-slot:title>
                <img
                  src="../../assets/images/icons/self-service2.svg"
                  alt="Hope Bank"
                />
                <br />Account Opening
              </template>
              <div class="row">
                <div class="col-md-12">
                  <div class="great_features_info">
                    <p>
                      <b
                        >Our account opening process is digital and
                        self-service</b
                      >
                    </p>
                    <p>
                      Whether you are an Individual or a Business concern
                      seeking the New Normal in Banking, We at Hope PSBank will
                      handhold you to a world of Ease, Fast and Safe digital
                      experience.
                    </p>
                    <p>
                      <b>Safety</b>
                    </p>
                    <p>
                      Our PIN authentication is 6 – 8 Digits and entered on your
                      own handset or device to offer you our unique safety and
                      protection.
                    </p>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <div
        class="owl_carousel"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <carousel :autoplay="true" :nav="false">
          <div class="carousel_item">
            <img src="../../assets/images/icons/schedule.svg" />
            <h4>Transfer</h4>
            <p>
              Easy, Fast and Safe way to send and receive money. You can receive
              money directly into your bank account in minutes with just a phone
              number. You can also transfer money from your account with the use
              of just your phone number.
            </p>
          </div>

          <div class="carousel_item">
            <img src="../../assets/images/icons/buy-airtime3.svg" />
            <h4>Online Mobile Recharge</h4>
            <p>
              Online Mobile recharges are quicker and easier with Hope PS Bank.
              Recharge using our website or via The Hope Mobile App and USSD
              *569*9* to enjoy convenient mobile recharge at your fingertips.
            </p>
          </div>

          <div class="carousel_item">
            <img src="../../assets/images/icons/buy-airtime3.svg" />
            <h4>Buy Airtime & Data</h4>
            <p>
              Recharge your mobile on-the-go with Hope PS Bank. Our airtime
              recharge is easy and convenient achieving top up your mobile in
              within minutes, saving you the time and effort needed to buy a
              recharge from the store.
            </p>
          </div>

          <div class="carousel_item">
            <img src="../../assets/images/icons/schedule.svg" />
            <h4>Bill Payments</h4>
            <p>
              With the number of billers we have on our platform, bills payment
              has been made convenient at Hope PS Bank. Pay your bills online,
              faster, and at your comfort.
            </p>
          </div>
        </carousel>
      </div>

      <div
        class="become_agent"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <div class="row">
          <div class="col-md-6">
            <div class="agent_img">
              <img src="../../assets/images/agent.png" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="agent_how_to">
              <h1>
                How to
                <br />
                <span>Become an Agent</span>
              </h1>
              <p>
                Our Agents are highly motivated and empowered to carry out basic
                financial services. Our Agent commissions are very competitive.
                The following can become agents:
              </p>
              <ul>
                <li>Unregistered business – Individuals.</li>
                <li>
                  Registered businesses – Limited liability companies, Sole
                  proprietorships, Partnerships, Cooperative societies.
                </li>
                <li>
                  Corporates with retail chains / large distribution network.
                </li>
              </ul>
              <div class="open_account">
                <router-link to="Agency">
                  <button class="open_account_btn">Learn More</button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="download_section"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="app_downloads">
              <h1>
                Do
                <span>quick</span> and <span>exciting</span> transactions to
                <span>family, friends, colleagues</span> and
                <span>everyone else.</span>
              </h1>
              <p>
                We seek to provide Accessibility, Affordability, Availability
                and Awareness of Digital Financial Services.
              </p>
              <p>
                With Hope PS Bank, every mobile telephone user can easily enjoy
                instant digital banking services with his or her phone number as
                account number and also a means of payment across all channels –
                person-to-person on mobile devices, web, merchants’ points of
                sale, automated teller machines and agency outlets.
              </p>
              <h5>Download Hope PSB App</h5>
              <div class="store_icons">
                <router-link to target="_blank">
                  <img src="../../assets/images/icons/appstore-button.png" />
                </router-link>
                <router-link to target="_blank">
                  <img src="../../assets/images/icons/playstore-button.png" />
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="agent_img">
              <img src="../../assets/images/hopebank-app2.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <back-to-top bottom="50px" right="50px">
      <button type="button" class="btn backtotop btn-to-top">
        <font-awesome-icon icon="chevron-up" class />
      </button>
    </back-to-top>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "../../components/HeaderNav/HeaderNav";
import BackToTop from "vue-backtotop";

// import { Slider, SliderItem } from 'vue-easy-slider'
import carousel from "vue-owl-carousel2";
import Footer from "../../components/Footer/Footer";

export default {
  name: "Landing.vue",
  components: {
    // Slider,
    // SliderItem,
    carousel,
    HeaderNav,
    BackToTop,
    Footer,
  },

  data() {
    return {
      list: [],
      sliderValue: 2,
      slide: 0,
      sliding: null,
    };
  },
  methods: {
    el: "#tabs",
    data: { activetab: 1 },
    changeIndex(index) {
      this.sliderValue = index;
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  /*color: #FECB32;*/
}
.slider {
  width: 100%;
  height: 100vh !important;
  position: absolute;
  z-index: 0;
}
.slider-pictures {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
}
.slider-pictures img {
  width: 75%;
}
.slider-pictures2 {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
}
.slider-pictures2 img {
  width: 100%;
}
.slider-pictures3 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.slider-pictures3 img {
  width: 100%;
}
.slide_icon {
  object-fit: contain;
  width: 100px;
}
.slider-title2 {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  height: 85vh;
}
.slider-title {
  width: 100%;
  text-align: left;
  /*padding: 5%;*/
}
.slider-title h1 {
  font-family: Montserrat-Regular;
  font-size: 40px;
  color: #000000;
  letter-spacing: 0.7px;
  padding-bottom: 1rem;
}
.slider-title h1 span {
  font-family: Montserrat-SemiBold;
  color: #000000;
  letter-spacing: 0.7px;
  font-weight: 700;
}
.slider-info {
  display: flex;
  align-items: center;
}
/* .slider-info img {
  margin-right: 0;
} */
.slider-info p {
  font-family: Montserrat-Regular;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0.25px;
  margin-bottom: 0;
}
.slider-info p span {
  font-family: Montserrat-Medium;
  color: #000000;
  letter-spacing: 0.25px;
}
.slider-info ul {
  padding-left: 1.5rem;
}
.slider-info ul li {
  font-family: Montserrat-Regular;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0.25px;
  /* margin-bottom: 0; */
}
.landing_page_left_section {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  /*position: absolute;*/
  /*width: 40%;*/
  /*left: 10%;*/
  /*top: 110px;*/
  /*height: calc(100vh - 220px);*/
  z-index: 10;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #eff7f9;
  padding: 3rem 2rem 0 2rem;
}
.image_moving1 {
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.image_moving1_inner {
  width: 100%;
  height: 100%;
  background: 0 0 no-repeat;
  background-size: contain;
  animation: image_moving1_inner 2.25s 0.75s infinite alternate
    cubic-bezier(0.43, 0.05, 0.6, 0.99);
}
@keyframes image_moving1_inner {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(-20%);
  }
}
.image_moving1_inner img {
  width: 60%;
  object-fit: contain;
}
.image_moving2 {
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin: 20% 0 0 10%;
}
.image_moving2_inner {
  width: 100%;
  height: 100%;
  background: 0 0 no-repeat;
  background-size: contain;
  animation: image_moving2_inner 3s infinite alternate
    cubic-bezier(0.43, 0.05, 0.6, 0.99);
  margin-right: 20%;
  padding-top: 5rem;
}
@keyframes image_moving2_inner {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
.image_moving2_inner img {
  width: 90%;
  object-fit: contain;
  transform: rotate(40deg);
}
.landing_page_right_section {
  padding-top: 2rem;
  text-align: left;
  opacity: 1;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.landing_page_right_section h2 {
  font-family: Montserrat-Medium;
  font-weight: 600;
  font-size: 29px;
  color: #000000;
  letter-spacing: 0.4px;
  padding-bottom: 1rem;
}
.landing_page_right_section p {
  font-family: Montserrat-Regular;
  font-size: 14px;
  color: #595959;
  line-height: 1.5rem;
  letter-spacing: 0.28px;
  padding-bottom: 1rem;
}
.landing_page_right_section h6 {
  font-family: Montserrat-Medium;
  font-size: 18px;
  color: #636868;
  letter-spacing: 0.25px;
  font-weight: 600;
  padding-bottom: 1rem;
}
.open_account_btn {
  font-family: Montserrat-SemiBold;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.17px;
  background: #fecb32;
  border-radius: 5px;
  padding: 10px 2rem;
}
/* @media only screen and (min-width: 280px) and (max-width: 767px) {
  .slider-info img {
    margin-right: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .nav_header2 {
  }
} */
</style>
