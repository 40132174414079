var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"transaction-receipt-page"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-7 ml-auto mr-auto"},[_c('div',{ref:"html"},[_c('div',{staticClass:"hope-logo mt-5 text-center"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../../../../assets/images/logo/hope-bank-payment-service-logo.svg"),"alt":"Hope Bank Logo","width":"110px"}})])],1),_vm._m(0),_c('div',{staticClass:"line5"}),_c('div',{staticClass:"receipt-info"},[_c('table',[_c('tr',[_c('td',[_vm._v("Account Name:")]),_c('td',[_vm._v(_vm._s(_vm.transferedAccountName()))])]),_c('tr',[_c('td',[_vm._v("Account Number:")]),(_vm.TRANSACTION_DETAILS.from)?_c('td',[_vm._v(" "+_vm._s(_vm.hashAccount(_vm.TRANSACTION_DETAILS.from))+" ")]):_vm._e(),(_vm.TRANSACTION_DETAILS.account)?_c('td',[_vm._v(" "+_vm._s(_vm.TRANSACTION_DETAILS.account)+" ")]):_vm._e()]),_c('tr',[_c('td',[_vm._v("Description:")]),(_vm.TRANSACTION_DETAILS.description)?_c('td',[_vm._v(" "+_vm._s(_vm.hashAccount(_vm.TRANSACTION_DETAILS.description))+" ")]):_vm._e()]),(_vm.TRANSACTION_DETAILS['beneficiary Name'])?_c('tr',[_c('td',[_vm._v("Beneficiary Name:")]),(_vm.TRANSACTION_DETAILS['beneficiary Name'])?_c('td',[_vm._v(" "+_vm._s(_vm.TRANSACTION_DETAILS["beneficiary Name"])+" ")]):_vm._e()]):_vm._e(),_c('tr',[(
                    _vm.TRANSACTION_DETAILS.beneficiary || _vm.TRANSACTION_DETAILS.to
                  )?_c('td',[_vm._v(" Beneficiary Details: ")]):_vm._e(),(_vm.TRANSACTION_DETAILS.to)?_c('td',[_vm._v(" "+_vm._s(_vm.USER.accounts[0].accountName)+" - "+_vm._s(_vm.TRANSACTION_DETAILS.account)+" - Hope Bank ")]):_vm._e(),(
                    _vm.TRANSACTION_DETAILS.beneficiary &&
                    !_vm.TRANSACTION_DETAILS['destination Bank']
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.TRANSACTION_DETAILS.beneficiary)+" - Hope Bank ")]):_vm._e(),(
                    _vm.TRANSACTION_DETAILS.beneficiary &&
                    _vm.TRANSACTION_DETAILS['destination Bank']
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.TRANSACTION_DETAILS.beneficiary)+" - "+_vm._s(_vm.TRANSACTION_DETAILS["destination Bank"])+" ")]):_vm._e()]),_c('tr',[_c('td',[_vm._v("Transaction Amount:")]),_c('td',[_vm._v(" ₦ "+_vm._s(_vm.formatAmount(_vm.TRANSACTION_DETAILS.amount))+" ")])]),_c('tr',[_c('td',[_vm._v("Transaction Type:")]),_c('td',[_vm._v(_vm._s(_vm.TRANSACTION_DETAILS["transactionType"]))])]),_c('tr',[_c('td',[_vm._v("Transaction Ref:")]),_c('td',[_vm._v(" "+_vm._s(_vm.TRANSACTION_DETAILS.data["reference Number"])+" ")])]),_c('tr',[_c('td',[_vm._v("Beneficiary Account:")]),_c('td',[_vm._v(" "+_vm._s(_vm.TRANSACTION_DETAILS.data["beneficiary"])+" ")])]),_c('tr',[_c('td',[_vm._v("Beneficiary Name:")]),_c('td',[_vm._v(" "+_vm._s(_vm.TRANSACTION_DETAILS.data["beneficiary Name"])+" ")])]),_c('tr',[_c('td',[_vm._v("Transaction Date:")]),_c('td',[_vm._v(_vm._s(_vm.TRANSACTION_DETAILS.date))])])])])]),_c('div',{staticClass:"send-or-download"},[_c('button',{staticClass:"download-btn2"},[_c('font-awesome-icon',{staticClass:"download-icon",attrs:{"icon":"envelope"}}),_vm._v("Send to Email ")],1),_c('button',{staticClass:"download-btn2",on:{"click":_vm.exportToPDF}},[_c('font-awesome-icon',{staticClass:"download-icon",attrs:{"icon":"download"}}),_vm._v("Download ")],1)]),(_vm.post.name == 'Dashboard_')?_c('div',{staticClass:"send-or-download"},[_c('router-link',{attrs:{"to":"Dashboard_"}},[_c('button',{staticClass:"download-btn2"},[_vm._v("Go Back")])])],1):_vm._e(),(_vm.post.name == 'history_')?_c('div',{staticClass:"send-or-download"},[_c('router-link',{attrs:{"to":"history_"}},[_c('button',{staticClass:"download-btn2"},[_vm._v("Go Back")])])],1):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d text-center"},[_c('h1',[_vm._v("Transaction Receipt")]),_c('h2',[_vm._v("Successful Transaction")])])
}]

export { render, staticRenderFns }