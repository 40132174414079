<template>
  <div class="nav_header3">
    <b-navbar toggleable="lg" type="light">
      <b-navbar-brand href="/">
        <img
          src="../../assets/images/logo/hope_bank_logo1.svg"
          alt="Hope Bank Logo"
          class="hope_logo"
        />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto white_bg">
          <b-navbar-nav class="nav_header_inner">
            <b-nav-item href="/">Home</b-nav-item>
            <b-navbar-nav>
              <div class="dropdown">
                <button
                  class="nav_menu_drop dropdown-toggle"
                  data-toggle="dropdown"
                >
                  NIN or BVN
                </button>
                <div class="dropdown-menu">
                  <router-link class="dropdown-item" to="nin"
                    >Registration</router-link
                  >
                  <router-link class="dropdown-item" to="enrolmentcenter"
                    >Enrollment Centers</router-link
                  >
                  <router-link class="dropdown-item" to="confirmmynin"
                    >Confirm My NIN</router-link
                  >
                  <router-link class="dropdown-item" to="verifyid"
                    >Verify Identity</router-link
                  >
                </div>
              </div>
            </b-navbar-nav>
            <b-navbar-nav>
              <div class="dropdown">
                <!-- <router-link to="about"> -->
                <button
                  class="nav_menu_drop dropdown-toggle active"
                  data-toggle="dropdown"
                >
                  About Us
                </button>
                <!-- </router-link> -->
                <div class="dropdown-menu">
                  <router-link class="dropdown-item" to="about"
                    >Corporate Profile</router-link
                  >
                  <router-link class="dropdown-item" to="about2"
                    >Our History</router-link
                  >
                  <router-link class="dropdown-item" to="about3"
                    >Our Vision &amp; Mission</router-link
                  >
                  <router-link class="dropdown-item" to="about4"
                    >Our Core Values</router-link
                  >
                  <router-link class="dropdown-item" to="about5"
                    >Management</router-link
                  >
                  <router-link class="dropdown-item" to="about6"
                    >Board of Directors</router-link
                  >
                </div>
              </div>
            </b-navbar-nav>
            <b-navbar-nav>
              <div class="dropdown">
                <router-link to="Contact">
                  <button
                    class="nav_menu_drop dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Contact Us
                  </button>
                </router-link>
                <div class="dropdown-menu">
                  <router-link class="dropdown-item" to="Contact"
                    >Contact Us</router-link
                  >
                  <router-link class="dropdown-item" to="LocateUs"
                    >Branch Locations</router-link
                  >
                </div>
              </div>
            </b-navbar-nav>
            <b-navbar-nav>
              <div class="dropdown">
                <router-link to="Agency">
                  <button
                    class="nav_menu_drop dropdown-toggle active"
                    data-toggle="dropdown"
                  >
                    Agency
                  </button>
                </router-link>
                <div class="dropdown-menu">
                  <router-link
                    class="dropdown-item"
                    to="Agency"
                    v-scroll-to="'#agent'"
                    >Become An Agent</router-link
                  >
                  <router-link
                    class="dropdown-item"
                    to="/Agency"
                    v-scroll-to="'#howto'"
                  >
                    Become A Branch Partner/ Agent Manager
                  </router-link>
                </div>
              </div>
            </b-navbar-nav>
            <b-navbar-nav>
              <div class="dropdown">
                <!-- <router-link to="products"> -->
                <button
                  class="nav_menu_drop dropdown-toggle active"
                  data-toggle="dropdown"
                >
                  Products
                </button>
                <!-- </router-link> -->
                <div class="dropdown-menu">
                  <router-link
                    class="dropdown-item"
                    to="products"
                    v-scroll-to="'#pro'"
                    >Products &amp; Services</router-link
                  >
                  <router-link
                    class="dropdown-item"
                    to="/products"
                    v-scroll-to="'#ineed'"
                  >
                    Individual Needs
                  </router-link>
                  <router-link
                    class="dropdown-item"
                    to="/products"
                    v-scroll-to="'#bizneed'"
                  >
                    Business Needs
                  </router-link>
                </div>
              </div>
            </b-navbar-nav>
            <b-navbar-nav>
              <div class="dropdown">
                <router-link to="Start">
                  <button
                    class="nav_menu_drop dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Open Account
                  </button>
                </router-link>
                <div class="dropdown-menu">
                  <router-link class="dropdown-item" to="Start"
                    >Complete Ongoing Account Process</router-link
                  >
                  <router-link class="dropdown-item" to="Register"
                    >Personal Account</router-link
                  >
                  <router-link class="dropdown-item" to="Business"
                    >Business Account</router-link
                  >
                </div>
              </div>
            </b-navbar-nav>
          </b-navbar-nav>
          <b-navbar-nav class="yellow_bg">
            <b-nav-item class="logout-btn">
              <router-link to="digitalbanking">
                <font-awesome-icon icon="lock" class="plus_icon" />Digital
                Banking
              </router-link>
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "HeaderNavIB.vue",
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.quick-transfer {
  padding: 0 2.2rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
  /* letter-spacing: 0.17px; */
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link a.active {
  color: #fecb32 !important;
}
.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}
.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}
.nav_header_inner {
  padding: 0 1rem;
  background: #000000;
  border-radius: 5px 0 0 5px;
}
.nav_menu_drop {
  color: #ffffff !important;
}
/*.plus_icon{*/
/*margin-right: 1rem;*/
/*font-size: 20px;*/
/*}*/
.yellow_bg {
  background: #ffffff;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #ffffff !important;
}
.yellow_bg > .navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}
.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}
</style>
