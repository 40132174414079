<template>
  <div class>
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNav2></HeaderNav2>
      <div class="container">
        <HeaderNav3></HeaderNav3>
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <p class="frequently-used">Update Information</p>
                </div>
                <div class="current_tab_title">
                  <font-awesome-icon icon="dot-circle" class="play-icon" />
                  <p class>You can change or correct your personal information</p>
                </div>
                <div class="kindly_enter_details">
                  <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                  <p>Be sure to fill the information below accurately, ( * ) are compulsory</p>
                </div>
                <form action>
                  <div class="form-table2">
                    <div class="upload-picture">
                      <div class="upload_image">
                        <img
                          src="../../assets/images/upload.svg"
                          alt="Upload Profile"
                          style="width: 100px;"
                        />
                        <!-- <vue-image-chooser name="image-chooser" @change="uploadFile" :progress="progress" :error="error"/> -->
                      </div>
                      <!-- <div class="upload_image2">
                                            <h5>Profile Picture</h5>
                                            <div class="continue-button" v-if="!image">
                                                <input type="file" @change="onFileChange"/>
                                                <div><image-picker></image-picker></div>
                                                <button class="continue-btn" >UPLOAD <font-awesome-icon icon="upload" class="angle-icon"/></button>
                                            </div>
                                            <div v-else>
                                                <img :src="image" />
                                                <button @click="removeImage">Remove image</button>
                                            </div>
                      </div>-->
                    </div>
                    <div class="label-title2">
                      <label>First Name</label>
                      <div class="account_select2">
                        <input type="text" class="input-amount" placeholder />
                      </div>
                    </div>
                    <div class="label-title2">
                      <label>Other Name</label>
                      <div class="account_select2">
                        <input type="text" class="input-amount" placeholder />
                      </div>
                    </div>
                    <div class="label-title2">
                      <label>Surname</label>
                      <div class="account_select2">
                        <input type="text" class="input-amount" placeholder />
                      </div>
                    </div>
                    <div class="label-title">
                      <label>Marital Status</label>
                      <div class="account_select">
                        <select name id class="account_type">
                          <option value class="choose_account">Single</option>
                          <option value class="choose_account">Married</option>
                          <option value class="choose_account">Divorced</option>
                        </select>
                        <font-awesome-icon icon="caret-down" class />
                      </div>
                    </div>
                    <div class="label-title">
                      <label>Gender</label>
                      <div class="account_select">
                        <select name id class="account_type">
                          <option value class="choose_account">Male</option>
                          <option value class="choose_account">Female</option>
                          <option value class="choose_account">Others</option>
                        </select>
                        <font-awesome-icon icon="caret-down" class />
                      </div>
                    </div>
                    <div class="label-title2">
                      <label>Date of Birth</label>
                      <div class="account_select">
                        <datetime v-model="date" placeholder="-DD-MM-YY-" class="input-amount"></datetime>
                        <font-awesome-icon icon="calendar-check" class />
                      </div>
                    </div>
                    <div class="label-title2">
                      <label>Religion</label>
                      <div class="account_select2">
                        <input type="text" class="input-amount" placeholder />
                      </div>
                    </div>
                    <div class="label-title">
                      <label>State of Origin</label>
                      <div class="account_select">
                        <select name id class="account_type">
                          <option value class="choose_account">Lagos</option>
                          <option value class="choose_account">Abia</option>
                          <option value class="choose_account">Anambra</option>
                          <option value class="choose_account">Enugu</option>
                        </select>
                        <font-awesome-icon icon="caret-down" class />
                      </div>
                    </div>
                    <div class="label-title2">
                      <label>Home Address</label>
                      <div class="account_select2">
                        <input type="text" class="input-amount" placeholder />
                      </div>
                    </div>
                  </div>

                  <div class="send-or-download2">
                    <div class="continue-button">
                      <a @click="$router.go(-1)">
                        <div class="continue-btn btn">
                          <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                        </div>
                      </a>
                    </div>
                    <router-link to="#">
                      <div class="continue-button pb-2">
                        <button class="continue-btn">
                          SAVE
                          <font-awesome-icon icon="angle-right" class="angle-icon" />
                        </button>
                      </div>
                    </router-link>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar :accountNumber="Account" :accountBalance="Balance"></SideBar>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
    </div>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
import HeaderNav2 from "../../components/HeaderNav/HeaderNav2";
import HeaderNav3 from "../../components/HeaderNav/HeaderNav3";
import SideBar from "../../components/SideBar/SideBar";
import { mapGetters, mapState } from "vuex";
import Footer2 from "../../components/Footer/Footer2";

export default {
  name: "UpdateInfo.vue",
  components: {
    datetime: Datetime,
    HeaderNav2,
    HeaderNav3,
    SideBar,
    Footer2
    // 'image-picker': ImagePicker
  },
  data() {
    return {
      date: null,
      image: "",
      progress: null,
      error: null,
      Account: this.$store.getters.GET_USER.accounts[0].accountNumber
    };
  },
  computed: {
    ...mapGetters({ user: "GET_USER" }),

    ...mapState({
      AUTHENTICATION_TOKEN: state => state.User.AUTHENTICATION_TOKEN
    }),
    Balance() {
      return this.user.accounts[
        this.user.accounts.findIndex(a => a.accountNumber == this.Account)
      ].balance;
    }
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    }
  }
  // methods: {
  //     onFileChange(e) {
  //         var files = e.target.files || e.dataTransfer.files;
  //         if (!files.length)
  //             return;
  //         this.createImage(files[0]);
  //     },
  //     createImage(file) {
  //         var image = new Image();
  //         var reader = new FileReader();
  //         var vm = this;
  //
  //         reader.onload = (e) => {
  //             vm.image = e.target.result;
  //         };
  //         reader.readAsDataURL(file);
  //     },
  //     removeImage: function (e) {
  //         this.image = '';
  //     }
  // }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}
.quick-transfer {
  padding: 0 0.7rem;
}
.upload-picture {
  display: flex;
  padding: 1rem 0;
}
.upload_image {
  margin-right: 1.5rem;
  object-fit: contain;
  width: 100px;
}
.upload_image img {
  object-fit: contain;
  width: 100px;
  height: 100px;
}
.image-uploader {
  height: 100px !important;
}
.upload_image2 h5 {
  font-family: Montserrat-SemiBold;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0.22px;
  text-align: left;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
