<template>
  <div class="hope-landing">
    <div class="hope-slider2" id="agent">
      <HeaderNavA />

      <div class="container">
        <div class="slider-title3">
          <div class="row">
            <div class="col-md-7">
              <div class="agent-slide-text">
                <h2>“When you’re our agent, we make money for you”</h2>
              </div>
              <div class="agents-buttons">
                <a href="https://agency.hopepsbank.com/apply" target="_blank">
                  <button class="agent-signup-btn">Become an Agent</button>
                </a>
                <a :href="agentLink" target="_blank">
                  <button class="agent-signin-btn">Agent Login</button>
                </a>
              </div>
            </div>
            <div class="col-md-5"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" id="branches">
      <div class="become_agent">
        <div class="row">
          <div class="col-md-6">
            <div class="agent_img">
              <img src="../../assets/images/agent2.jpg" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="agent_benefit2">
              <h1>
                Branches and
                <span>Agents</span>
              </h1>
              <p>
                Our customers can be served at our Branches and Agents’ outlets.
                You no longer need to go far to bank, as our Branches and Agents
                are right within your neighborhood. Enjoy access to a full range
                of basic financial and payment services including:
              </p>
              <ul>
                <li>Account Opening.</li>
                <li>Balance Enquiry</li>
                <li>Cash Deposits</li>
                <li>Cash Withdrawals</li>
                <li>Funds Transfer</li>
                <li>Bill Payments</li>
                <li>Airtime Purchase</li>
                <li>BVN Registration</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agency-benefits grey_bg" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="agent_benefit">
              <h1>
                BENEFITS -
                <br />
                <span>Customers:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-8">
            <div class="agent_benefit2">
              <ul>
                <li>
                  Access to funds anytime even after banking hours (inclusive of
                  weekends and public holidays).
                </li>
                <li>Access to neighborhood agents.</li>
                <li>Banking services right at your doorstep.</li>
                <li>No queues.</li>
                <li>
                  Transactions are 6-8 digits PIN protected and are thus
                  secured.
                </li>
                <li>Save cost of going to the branch or ATM.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agency-benefits white_bg2" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="agent_benefit">
              <h1>
                BENEFITS -
                <br />
                <span>Branch Partners, Agent Managers and Agents:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-8">
            <div class="agent_benefit2">
              <ul>
                <li>
                  Cash Management – opportunity to turn idle cash into revenue
                  and deposit into Bank Account.
                </li>
                <li>
                  Increase in business opportunities as a result of increase in
                  walk-in customers.
                </li>
                <li>Additional income from transaction fees and commission.</li>
                <li>
                  Opportunity to cross-sell products/services to walk-in
                  customers.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agency-benefits grey_bg" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out"
      id="howto">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="agent_benefit">
              <h1>
                How to become
                <br />
                <span>Branch Partner, Agent Manager or Agent:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-8">
            <div class="agent_benefit2">
              <!--    <p>
                Visit
                <a href="https://www.hopepsbank.com">www.hopepsbank.com</a> or
                download the Hope Digital App to become Agent Manager or Agent
                instantly. To become a Branch Partner, simply contact us via
                email to
                <a href="mailto:branchpartner@hopepsbank.com">branchpartner@hopepsbank.com</a>
              </p> -->
              <p>THE HOPE AGENT
                An individual or business may be registered as a Hope Agent by self or through an Agent Manager. Where an
                Agent is registered by an Agent Manager, the Manager is paid a commission on every transaction by the
                Agent. The commission payable to the Manager is not deducted from the commission due to the Agent who is
                paid full commission.
                Upon successful registration, the Agent is assigned a unique code, an account is also created for the
                agent at the point of onboarding. All transactions by the agent will be credited or debited into the
                agent’s account which can be funded through transfer from any other bank. It is important that the account
                is always funded by the agent for transactions.
              </p>
              <h6>Terms and Conditions</h6>
              <ul>
                <li>
                  To register by yourself as an agent, you require a Hope PSBank BVN account. The account can be opened
                  through any of our channels - USSD *569*9#, Mobile app or HOPE PSBank Website
                </li>
                <li>
                  Agent will be provided with a POS terminal, free of charge after a non-refundable registration fee of
                  N12,900 is paid.
                </li>
                <li>
                  A daily service charge of N100 applies.
                </li>
                <li>
                  Every Hope Agent is expected to act with integrity and character.
                </li>
                <li>
                  Any Hope Agent that defrauds or engages in any irregularities while dealing with members of the public
                  will be blacklisted and reported to regulators and law enforcement agencies for prosecution.
                </li>
                <li>
                  Every Hope Agent must display Signages at their respective locations. These Signages will be available
                  at Hope PSBank Regional Offices.
                </li>
                <li>
                  The Agent is paid applicable commission for transactions done.
                </li>
                <li>
                  Hope PSBank reserves the right to terminate the relationship with any Agent.
                </li>
                <li>
                  Every Hope Agent is expected to act with integrity and character
                </li>
                <li>
                  Any Hope Agent that defrauds or engages in any irregularities while dealing with members of the public
                  will be blacklisted and reported to regulators and law enforcement agencies for prosecution.
                </li>
                <li>
                  Every Hope Agent must display Signages at their respective locations. These Signages will be available
                  at Hope PSBank Regional Offices.
                </li>
                <li>
                  The Agent is paid applicable commission for transactions done by the Agent.
                </li>
                <li>
                  Hope PSBank reserves the right to terminate the relationship with any Agent.
                </li>


              </ul>
              <!-- <p>Requirements for becoming an agent includes:</p>
              <ul>
                <li>Must maintain a banking relationship with HOPE PS Bank</li>
                <li>
                  Must have a visible business location which is easily
                  accessible
                </li>
                <li>
                  Must have been engaged in commercial activities for a minimum
                  of 12 months.
                </li>
                <li>No sign-up fee is required.</li>
                <li>
                  Duly completed registration form with the following documents
                  attached.
                </li>
              </ul>-->

              <h6>Quick Steps to Register</h6>
              <ul>
                <li>Visit HOPE PSBank or download the Hope Digital App to become an Agent.</li>
                <li>Select Agency</li>
                <li>Select Become an Agent</li>
                <li>Complete registration form online.</li>

              </ul>
              <h6>3 Easy Steps to Transact</h6>
              <ul>
                <li>Fund your Agency account through transfer from your Hope PSBank account, or transfer from another Bank
                  to your agency account number in Hope PSBank.</li>
                <li>Visit HOPE PSBank or Hope Digital App and Login on the Agency Platform</li>
                <li>Carry out your desired transactions.</li>
              </ul>

              <h6>Refund Policy</h6>
              <ul>
                <p>No refund of registration fee will be given if for any reason an Agent Manager chooses to quit after
                  completing a successful registration.</p>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agency-benefits white_bg2" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="agent_benefit">
              <h1>
                Contact
                <br />
                <span>Details:</span>
              </h1>
            </div>
          </div>
          <div class="col-md-8">
            <div class="agent_benefit2">
              <ul>
                <li>
                  Address: Commerce House,
                  <br />1, Idowu Taylor Street, <br />Victoria Island, Lagos
                </li>
                <li>
                  Website:
                  <a href="https://www.hopepsbank.com">www.hopepsbank.com</a>
                </li>
                <li>
                  Email:
                  <a href="mailto:branchpartner@hopepsbank.com">branchpartner@hopepsbank.com</a>
                </li>
                <li>Contact center lines (Helpdesk Line):</li>
                <li>Twitter handle:</li>
                <li>Facebook Page:</li>
                <li>WhatsApp:</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="download_section2 grey_bg" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="app_downloads">
              <h1>
                <span>Manage Transactions</span> and <span>Remit</span> in
                realtime and it follows you
                <span>everywhere.</span>
              </h1>
              <p>
                We seek to provide Accessibility, Affordability, Availability
                and Awareness of Digital Financial Services.
              </p>
              <p>
                With Hope PS Bank, every mobile telephone user can easily enjoy
                instant digital banking services with his or her phone number as
                account number and also a means of payment across all channels –
                person-to-person on mobile devices, web, merchants’ points of
                sale, automated teller machines and agency outlets.
              </p>
              <h5>Download Hope PSB App</h5>
              <div class="store_icons">
                <router-link to target="_blank">
                  <img src="../../assets/images/icons/appstore-button.png" />
                </router-link>
                <router-link to target="_blank">
                  <img src="../../assets/images/icons/playstore-button.png" />
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="agent_img">
              <img src="../../assets/images/hopebank-app2.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <back-to-top bottom="50px" right="50px">
      <button type="button" class="btn backtotop btn-to-top">
        <font-awesome-icon icon="chevron-up" class />
      </button>
    </back-to-top>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNavA from "../../components/HeaderNav/HeaderNavA";
import BackToTop from "vue-backtotop";
import Footer from "../../components/Footer/Footer";

export default {
  name: "Agency.vue",
  components: {
    HeaderNavA,
    BackToTop,
    Footer,
  },

  data() {
    return {
      toggle: false,
      str: 'https://agency.hopepsbank.com/login',
      //   toggle2: false,
    };
  },
  computed: {
    agentLink() {
      return this.str.replace(/%20/g, " ")
    }
  },
  methods: {},
};
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  /*color: #FECB32;*/
}

.slider {
  width: 100%;
  height: 544px !important;
  position: absolute;
  z-index: 0;
}

#__BVID__17__BV_tab_controls_ {
  background: #eff7f9 !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
  /* letter-spacing: 0.17px; */
}

.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}

.navbar-light .navbar-nav .nav-link a:hover {
  color: #fecb32;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
  /*border-bottom: 3px solid #FECB32;*/
}

.navbar-light .navbar-nav .nav-link.active {
  color: #fecb32 !important;
  /* border-bottom: 3px solid #FECB32; */
}

.navbar-light .navbar-nav .nav-link a.active {
  color: #fecb32 !important;
}

.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
  /* border-bottom: 3px solid #FECB32; */
}

.nav-link.active {
  color: #fecb32;
}

.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}

.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}

/*.plus_icon{*/
/*margin-right: 1rem;*/
/*font-size: 20px;*/
/*}*/
.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}

.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}

.yellow_bg a:hover {
  color: #000000 !important;
}

.yellow_bg>.navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}

.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}

.slider-title3 {
  /*position: absolute;*/
  width: 100%;
  text-align: left;
  /*padding: 0 5%;*/
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 544px;
}

.slider-title3 h1 {
  font-family: Montserrat-Regular;
  font-size: 51px;
  color: #000000;
  letter-spacing: 0.7px;
  padding-bottom: 2rem;
}

.slider-title3 h1 span {
  font-family: Montserrat-SemiBold;
  color: #000000;
  letter-spacing: 0.7px;
  font-weight: 700;
}

.slider-info {
  display: flex;
  align-items: center;
}

.slider-info img {
  padding-right: 2rem;
}

.slider-info p {
  font-family: Montserrat-Regular;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
  margin-bottom: 0;
}

.slider-info p span {
  font-family: Montserrat-Medium;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}

@keyframes image_moving1_inner {
  0% {
    transform: translateY(0);
  }

  to {
    transform: translateY(-20%);
  }
}

.image_moving1_inner img {
  width: 100%;
  object-fit: contain;
}

@keyframes image_moving2_inner {
  0% {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

.image_moving2_inner img {
  width: 90%;
  object-fit: contain;
  transform: rotate(40deg);
}

.landing_page_right_section h2 {
  font-family: Montserrat-Medium;
  font-weight: 600;
  font-size: 29px;
  color: #000000;
  letter-spacing: 0.4px;
  padding-bottom: 1rem;
}

.landing_page_right_section p {
  font-family: Montserrat-Regular;
  font-size: 18px;
  color: #595959;
  letter-spacing: 0.28px;
  padding-bottom: 1rem;
}

.landing_page_right_section h6 {
  font-family: Montserrat-Medium;
  font-size: 18px;
  color: #636868;
  letter-spacing: 0.25px;
  font-weight: 600;
  padding-bottom: 1rem;
}

.open_account_btn {
  font-family: Montserrat-SemiBold;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.17px;
  background: #fecb32;
  border-radius: 5px;
  padding: 10px 2rem;
}

.agent_benefit2 a {
  text-decoration: none;
  color: #636868;
  font-weight: 800;
}

.agent_benefit2 a:hover {
  text-decoration: none;
  color: #fecb32;
}
</style>
