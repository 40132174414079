<template>
  <div class>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNavBiz />
      <div class="container">
        <BusinessNavBar />
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <img src="../../../../assets/images/icons/transfer-sm.svg" alt="Hope Bank" />
                  <p class="frequently-used">Schedule Transaction</p>
                </div>
                <div>
                  <b-tabs no-body>
                    <!-- Schedule Transaction to Own Account -->
                    <!-- <b-tab :active="one" @click="ownTab" id="OwnTransfer">
                      <template v-slot:title>
                        To
                        <br />
                        <strong>Own Account</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Schedule Funds Transfer to Own Account</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Kindly enter Transfer Details</p>
                        </div>
                        <form action>
                          <div class="form-table2">
                            <div class="label-title">
                              <label>Transfer From</label>
                              <div class="account_select">
                                <select v-model="transferAccountFrom" name id class="account_type">
                                  <option
                                    v-for="(item) in user.corporateAccounts"
                                    :key="item + 'a'"
                                    :value="item.accountNumber"
                                  >{{item.accountName}} - {{item.accountNumber}}</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="label-title">
                              <label>Transfer To</label>
                              <div class="account_select">
                                <select v-model="transferAccountTo" name id class="account_type">
                                  <option
                                    v-for="(item) in filteredUserAccount"
                                    :key="item + 'a'"
                                    :value="item.accountNumber"
                                  >{{item.accountName}} - {{item.accountNumber}}</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select2">
                                <vue-numeric
                                  class="input-amount"
                                  currency="₦"
                                  separator=","
                                  v-model="transferAmount"
                                ></vue-numeric>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Note</label>
                              <div class="account_select2">
                                <input
                                  type="text"
                                  v-model="transferNote"
                                  class="input-amount"
                                  placeholder="Say something about the transaction"
                                />
                              </div>
                            </div>
                            <div class="kindly_enter_details">
                              <font-awesome-icon
                                icon="exclamation-circle"
                                class="exclamation-icon"
                              />
                              <p>Schedule Payment</p>
                            </div>
                            <div class="label-title2">
                              <label>Schedule Name</label>
                              <div class="account_select2">
                                <input
                                  v-model="transferScheduleName"
                                  type="text"
                                  class="input-amount"
                                  placeholder="Enter schedule name"
                                />
                              </div>
                            </div>
                            <div class="label-title">
                              <label>Frequency</label>
                              <div class="account_select">
                                <select v-model="transferFrequency" name id class="account_type">
                                  <option value="Once" class="choose_account">Once</option>
                                  <option value="Daily" class="choose_account">Daily</option>
                                  <option value="Weekly" class="choose_account">Weekly</option>
                                  <option value="Monthly" class="choose_account">Monthly</option>
                                  <option value="Yearly" class="choose_account">Yearly</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Start Transfer</label>
                              <div v-if="oneoff" class="account_select">
                                <datetime
                                  type="datetime"
                                  use12-hour
                                  v-model="transferStart"
                                  placeholder="-DD-MM-YY-"
                                  class="input-amount"
                                ></datetime>
                                <font-awesome-icon icon="calendar-check" class />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>End Transfer</label>
                              <div v-if="oneoff" class="account_select">
                                <datetime
                                  type="datetime"
                                  use12-hour
                                  v-model="transferEnd"
                                  placeholder="-DD-MM-YY-"
                                  class="input-amount"
                                ></datetime>
                                <font-awesome-icon icon="calendar-check" class />
                              </div>
                            </div>
                          </div>

                          <div class="send-or-download2">
                            <div class="continue-button">
                              <a @click="$router.go(-1)">
                                <div class="continue-cib-btn">
                                  <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                                </div>
                              </a>
                            </div>
                            <router-link to="confirmSchedule?p=1">
                              <div class="continue-button">
                                <button class="continue-cib-btn">
                                  CONTINUE
                                  <font-awesome-icon icon="angle-right" class="angle-icon" />
                                </button>
                              </div>
                            </router-link>
                          </div>
                        </form>
                      </div>
                    </b-tab>-->

                    <!-- Schedule Transaction to Hope Bank -->
                    <b-tab :active="two" id="HopeTransfer" @click="hopeTab">
                      <template v-slot:title class="left">
                        To
                        <br />
                        <strong>Hope Bank</strong>
                        <!--<b-spinner type="grow" small></b-spinner> Tab <strong>2</strong>-->
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Schedule Funds Transfer to Hope Bank Account</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Kindly enter Transfer Details</p>
                        </div>
                        <form action>
                          <div class="form-table2">
                            <div class="label-title">
                              <label>Transfer From</label>
                              <div class="account_select">
                                <select v-model="transferAccount" name id class="account_type">
                                  <option
                                    v-for="(item) in user.corporateAccounts"
                                    :key="item + 'a'"
                                    :value="item.accountNumber"
                                  >{{item.accountName}} - {{item.accountNumber}}</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <!-- <div class="label-title">
                                                        <label >Saved Beneficiaries</label>
                                                        <div class="account_select">
                                                            <select name="" id="" class="account_type">
                                                                <option value="" class="choose_account">— Beneficiary’s Account —</option>
                                                                <option value="" class="choose_account">Chukwudi Okoro  - 01234567890</option>
                                                            </select>
                                                            <font-awesome-icon icon="caret-down" class=""/>
                                                        </div>
                            </div>-->
                            <div class="label-title2">
                              <label>Beneficiary Account Number</label>
                              <div class="account_select2">
                                <the-mask
                                  mask="##########"
                                  v-model="beneficiaryAccount"
                                  class="input-amount"
                                  placeholder
                                />
                                <!--<input type="number" class="input-amount" placeholder="-"/>-->
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Beneficiary Name</label>
                              <div class="account_select2">
                                <input
                                  class="input-amount"
                                  disabled
                                  style="background:whitesmoke"
                                  v-model="beneficiaryName"
                                />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select2">
                                <vue-numeric
                                  class="input-amount"
                                  currency="₦"
                                  separator=","
                                  v-model="transferAmount"
                                ></vue-numeric>
                              </div>
                            </div>

                            <div class="label-title2">
                              <label>Note</label>
                              <div class="account_select2">
                                <input
                                  type="text"
                                  class="input-amount"
                                  v-model="transferNote"
                                  placeholder="Say something about the transaction"
                                />
                              </div>
                            </div>
                            <div class="kindly_enter_details">
                              <font-awesome-icon
                                icon="exclamation-circle"
                                class="exclamation-icon"
                              />
                              <p>Schedule Payment</p>
                            </div>
                            <div class="label-title2">
                              <label>Schedule Name</label>
                              <div class="account_select2">
                                <input
                                  v-model="transferScheduleName"
                                  type="text"
                                  class="input-amount"
                                  placeholder="Enter schedule name"
                                />
                              </div>
                            </div>
                            <div class="label-title">
                              <!-- <v-select v-model="selected" :options="['Once','Daily','Weekly','Monthly','Yearly']"></v-select> -->
                              <label>Frequency</label>
                              <div class="account_select">
                                <select v-model="transferFrequency" name id class="account_type">
                                  <option value="Once" class="choose_account">Once</option>
                                  <option value="Daily" class="choose_account">Daily</option>
                                  <option value="Weekly" class="choose_account">Weekly</option>
                                  <option value="Monthly" class="choose_account">Monthly</option>
                                  <option value="Yearly" class="choose_account">Yearly</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Start Transfer</label>
                              <div v-if="oneoff" class="account_select">
                                <datetime
                                  type="datetime"
                                  use12-hour
                                  v-model="transferStart"
                                  placeholder="-DD-MM-YY-"
                                  class="input-amount"
                                ></datetime>
                                <font-awesome-icon icon="calendar-check" class />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>End Transfer</label>
                              <div v-if="oneoff" class="account_select">
                                <datetime
                                  type="datetime"
                                  use12-hour
                                  v-model="transferEnd"
                                  placeholder="-DD-MM-YY-"
                                  class="input-amount"
                                ></datetime>
                                <font-awesome-icon icon="calendar-check" class />
                              </div>
                            </div>
                          </div>

                          <div class="send-or-download2">
                            <div class="continue-button">
                              <a @click="$router.go(-1)">
                                <div class="continue-cib-btn">
                                  <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                                </div>
                              </a>
                            </div>
                            <router-link to="confirmSchedule?p=2">
                              <div class="continue-button">
                                <button class="continue-cib-btn">
                                  CONTINUE
                                  <font-awesome-icon icon="angle-right" class="angle-icon" />
                                </button>
                              </div>
                            </router-link>
                          </div>
                        </form>
                      </div>
                    </b-tab>
                    <!-- Schedule Transaction to Other Bank -->
                    <b-tab :active="three" id="OtherBank" @click="hopeTab">
                      <template v-slot:title>
                        To
                        <br />
                        <strong>Other Bank</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Schedule Funds Transfer to Other Bank</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Kindly enter Transfer Details</p>
                        </div>
                        <form action>
                          <div class="form-table2">
                            <div class="label-title">
                              <label>Transfer From</label>
                              <div class="account_select">
                                <select v-model="transferAccount" name id class="account_type">
                                  <option
                                    v-for="(item) in user.corporateAccounts"
                                    :key="item + 'a'"
                                    :value="item.accountNumber"
                                  >{{item.accountName}} - {{item.accountNumber}}</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <!-- <div class="label-title">
                                                        <label >Saved Beneficiaries</label>
                                                        <div class="account_select">
                                                            <select name="" id="" class="account_type">
                                                                <option value="" class="choose_account">— Beneficiary’s Account —</option>
                                                                <option value="" class="choose_account">Chukwudi Okoro  - 01234567890</option>
                                                            </select>
                                                            <font-awesome-icon icon="caret-down" class=""/>
                                                        </div>
                            </div>-->
                            <div class="label-title">
                              <label>Bank</label>
                              <div class="account_select">
                                <select name id v-model="beneficiaryBank" class="account_type">
                                  <option value class="choose_account">- Select Bank -</option>
                                  <option
                                    v-for="item in INSTITUTIONS"
                                    :key="item"
                                    :value="item"
                                    class="choose_account"
                                  >{{item.label}}</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Beneficiary Account Number</label>
                              <div class="account_select2">
                                <the-mask
                                  mask="##########"
                                  v-model="beneficiaryAccount"
                                  class="input-amount"
                                  placeholder="-"
                                />
                                <!--<input type="number" class="input-amount" placeholder="-"/>-->
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Beneficiary Name</label>
                              <div class="account_select2">
                                <input
                                  class="input-amount"
                                  disabled
                                  style="background:whitesmoke"
                                  v-model="beneficiaryName"
                                />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select2">
                                <vue-numeric
                                  class="input-amount"
                                  currency="₦"
                                  separator=","
                                  v-model="transferAmount"
                                ></vue-numeric>
                              </div>
                            </div>
                            <!-- <div class="label-title2">
                                                        <label class="label" >Save Beneficiary?</label>
                                                        <div></div>
                                                        <div></div>
                                                        <b-form-checkbox size="lg"  v-model="saveBeneficiary"  name="check-button" switch>
                                                        </b-form-checkbox>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                            </div>-->
                            <div class="label-title2">
                              <label>Note</label>
                              <div class="account_select2">
                                <input
                                  type="text"
                                  v-model="transferNote"
                                  class="input-amount"
                                  placeholder="Say something about the transaction"
                                />
                              </div>
                            </div>
                            <div class="kindly_enter_details">
                              <font-awesome-icon
                                icon="exclamation-circle"
                                class="exclamation-icon"
                              />
                              <p>Schedule Payment</p>
                            </div>
                            <div class="label-title2">
                              <label>Schedule Name</label>
                              <div class="account_select2">
                                <input
                                  v-model="transferScheduleName"
                                  type="text"
                                  class="input-amount"
                                  placeholder="Enter schedule name"
                                />
                              </div>
                            </div>
                            <div class="label-title">
                              <!-- <v-select v-model="selected" :options="['Once','Daily','Weekly','Monthly','Yearly']"></v-select> -->
                              <label>Frequency</label>
                              <div class="account_select">
                                <select v-model="transferFrequency" name id class="account_type">
                                  <option value="Once" class="choose_account">Once</option>
                                  <option value="Daily" class="choose_account">Daily</option>
                                  <option value="Weekly" class="choose_account">Weekly</option>
                                  <option value="Monthly" class="choose_account">Monthly</option>
                                  <option value="Yearly" class="choose_account">Yearly</option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Start Transfer</label>
                              <div v-if="oneoff" class="account_select">
                                <datetime
                                  type="datetime"
                                  use12-hour
                                  v-model="transferStart"
                                  placeholder="-DD-MM-YY-"
                                  class="input-amount"
                                ></datetime>
                                <font-awesome-icon icon="calendar-check" class />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>End Transfer</label>
                              <div v-if="oneoff" class="account_select">
                                <datetime
                                  type="datetime"
                                  use12-hour
                                  v-model="transferEnd"
                                  placeholder="-DD-MM-YY-"
                                  class="input-amount"
                                ></datetime>
                                <font-awesome-icon icon="calendar-check" class />
                              </div>
                            </div>
                          </div>

                          <div class="send-or-download2">
                            <div class="continue-button">
                              <a @click="$router.go(-1)">
                                <div class="continue-cib-btn btn">
                                  <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                                </div>
                              </a>
                            </div>
                            <router-link to="confirmSchedule?p=3">
                              <div class="continue-button">
                                <button class="continue-cib-btn">
                                  CONTINUE
                                  <font-awesome-icon icon="angle-right" class="angle-icon" />
                                </button>
                              </div>
                            </router-link>
                          </div>
                        </form>
                      </div>
                    </b-tab>

                    <!-- List o all Schedules -->
                    <b-tab title="tab6" :active="four">
                      <template v-slot:title>
                        Transaction
                        <br />
                        <strong>Schedules</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Transaction Schedules</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>All Schedules</p>
                        </div>
                        <div style="max-height:60%" class="recent-transactions scheduled_table">
                          <table class>
                            <tr>
                              <th>S/NO</th>
                              <th>Name</th>
                              <th>Amount</th>
                              <th>Description</th>
                              <th>Frequency</th>
                              <th>Action</th>
                              <th>Delete</th>
                            </tr>
                            <tr v-for="(schedule,index) in ScheduleList" :key="index">
                              <td>{{index+1}}</td>
                              <td>{{schedule.name}}</td>
                              <td>
                                <span>&#8358;</span>
                                {{schedule.amount}}
                              </td>
                              <td>{{schedule.beneficiary}}</td>
                              <td>{{schedule.frequency}}</td>
                              <td class="view-delete-" @click="currentSchedule(index)">
                                <div class="view-delete">
                                  <button class="view-action-btn">View</button>
                                  <!-- <button class="view-action-btn"><font-awesome-icon icon="trash-alt" class="delete-icon"/></button> -->
                                </div>
                              </td>
                              <td>
                                <button
                                  @click="deleteSchedule(schedule.id)"
                                  class="view-action-btn"
                                >
                                  <font-awesome-icon icon="trash-alt" class="delete-icon" />
                                </button>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar
                v-if="transferAccountFrom"
                :accountNumber="transferAccountFrom"
                :accountBalance="Balance"
              ></SideBar>
              <SideBar
                v-if="transferAccount"
                :accountNumber="transferAccount"
                :accountBalance="Balance"
              ></SideBar>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>
    </div>
  </div>
</template>

<script>
// import { Datetime } from 'vue-datetime';
import { TheMask } from "vue-the-mask";
import VueNumeric from "vue-numeric";
import HeaderNavBiz from "../../../../components/HeaderNav/HeaderNavBiz";
import BusinessNavBar from "../../../../components/HeaderNav/BusinessNavBar";
import SideBar from "../../../../components/SideBar/SideBar_";
import { mapGetters, mapState } from "vuex";
import Loader from "../../../../components/Loader/Loader.vue";
import { transferService } from "../../../../services/transfer.services.js";
import { getInstitutionService } from "../../../../services/getInstitution.services.js";
import Footer3 from "../../../../components/Footer/Footer3";

export default {
  name: "Transfer.vue",
  components: {
    TheMask,
    VueNumeric,
    HeaderNavBiz,
    BusinessNavBar,
    SideBar,
    Loader,
    Footer3,
  },
  data() {
    return {
      oneoff: true,
      start_date: null,
      end_date: null,
      loading: false,
      one: false,
      two: false,
      three: false,
      four: false,
      ScheduleList: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "GET_USER",
      filteredUserAccount: "GET_FILTERED_USER",
    }),

    ...mapState({
      AUTHENTICATION_TOKEN: (state) => state.User.AUTHENTICATION_TOKEN,
      TRANSFER_ACCOUNT: (state) => state.Transfer.TRANSFER_ACCOUNT,
      TRANSFER_ACCOUNT_FROM: (state) => state.Transfer.TRANSFER_ACCOUNT_FROM,
      TRANSFER_ACCOUNT_TO: (state) => state.Transfer.TRANSFER_ACCOUNT_TO,
      BENEFICIARY_ACCOUNT: (state) => state.Transfer.BENEFICIARY_ACCOUNT,
      BENEFICIARY_NAME: (state) => state.Transfer.BENEFICIARY_NAME,
      BENEFICIARY_BANK: (state) => state.Transfer.BENEFICIARY_BANK,
      TRANSFER_AMOUNT: (state) => state.Transfer.TRANSFER_AMOUNT,
      SAVE_BENEFICIARY: (state) => state.Transfer.SAVE_BENEFICIARY,
      TRANSFER_NOTE: (state) => state.Transfer.TRANSFER_NOTE,
      INSTITUTIONS: (state) => state.Transfer.INSTITUTIONS,
      SCHEDULE_NAME: (state) => state.Transfer.SCHEDULE_NAME,
      FREQUENCY: (state) => state.Transfer.FREQUENCY,
      START: (state) => state.Transfer.START,
      END: (state) => state.Transfer.END,
    }),

    Balance() {
      if (this.transferAccountFrom)
        return this.user.corporateAccounts[
          this.user.corporateAccounts.findIndex(
            (a) => a.accountNumber == this.transferAccountFrom
          )
        ].balance;
      return this.user.corporateAccounts[
        this.user.corporateAccounts.findIndex(
          (a) => a.accountNumber == this.transferAccount
        )
      ].balance;
    },

    transferAccount: {
      get() {
        return this.TRANSFER_ACCOUNT;
      },
      set(value) {
        this.$store.commit("SET_TRANSFER_ACCOUNT", value);
      },
    },

    transferAccountFrom: {
      get() {
        return this.TRANSFER_ACCOUNT_FROM;
      },
      set(value) {
        this.$store.commit("SET_TRANSFER_ACCOUNT_FROM", value);
      },
    },

    transferAccountTo: {
      get() {
        return this.TRANSFER_ACCOUNT_TO;
      },
      set(value) {
        this.$store.commit("SET_TRANSFER_ACCOUNT_TO", value);
      },
    },

    beneficiaryAccount: {
      get() {
        return this.BENEFICIARY_ACCOUNT;
      },
      set(value) {
        this.$store.commit("SET_BENEFICIARY_ACCOUNT", value);
      },
    },
    beneficiaryName: {
      get() {
        return this.BENEFICIARY_NAME;
      },
      set(value) {
        this.$store.commit("SET_BENEFICIARY_NAME", value);
      },
    },
    beneficiaryBank: {
      get() {
        return this.BENEFICIARY_BANK;
      },
      set(value) {
        this.$store.commit("SET_BENEFICIARY_BANK", value);
      },
    },
    transferAmount: {
      get() {
        return this.TRANSFER_AMOUNT;
      },
      set(value) {
        this.$store.commit("SET_TRANSFER_AMOUNT", value);
      },
    },
    saveBeneficiary: {
      get() {
        return this.SAVE_BENEFICIARY;
      },
      set(value) {
        this.$store.commit("SET_SAVE_BENEFICIARY", value);
      },
    },
    transferNote: {
      get() {
        return this.TRANSFER_NOTE;
      },
      set(value) {
        this.$store.commit("SET_TRANSFER_NOTE", value);
      },
    },
    transferScheduleName: {
      get() {
        return this.SCHEDULE_NAME;
      },
      set(value) {
        this.$store.commit("SET_SCHEDULE_NAME", value);
      },
    },
    transferFrequency: {
      get() {
        return this.FREQUENCY;
      },
      set(value) {
        this.$store.commit("SET_FREQUENCY", value);
      },
    },
    transferStart: {
      get() {
        return this.START;
      },
      set(value) {
        this.$store.commit("SET_START", value);
      },
    },
    transferEnd: {
      get() {
        return this.END;
      },
      set(value) {
        this.$store.commit("SET_END", value);
      },
    },

    routeQuery() {
      return this.$route.query.p;
    },
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },

    ownTab() {
      this.transferAccount = "";
      this.$store.dispatch("CLEAR_INPUTS");
      this.$store.commit(
        "SET_TRANSFER_ACCOUNT_FROM",
        this.user.corporateAccounts[0].accountNumber
      );
    },

    hopeTab() {
      this.transferAccountFrom = "";
      this.$store.dispatch("CLEAR_INPUTS");
      this.$store.commit(
        "SET_TRANSFER_ACCOUNT",
        this.user.corporateAccounts[0].accountNumber
      );
    },

    async validateBeneficiaryAccount() {
      this.loading = true;
      const data = {
        Account: this.BENEFICIARY_ACCOUNT,
        Code: this.BENEFICIARY_BANK.value,
        token: this.AUTHENTICATION_TOKEN,
      };
      await transferService
        .validateBeneficiaryAccount(data)
        .then((res) => {
          this.$store.commit("SET_BENEFICIARY_NAME", res.status);
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Validation Failed!", {
            timeOut: 5000,
          });
          this.$store.commit("SET_BENEFICIARY_NAME", "");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getInstitution() {
      this.loading = true;
      await getInstitutionService
        .getInstitution()
        .then((res) => {
          this.$store.commit("SET_INSTITUTIONS", res);
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Could not fetch institutions", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    //get all schedules
    async getSchedule() {
      this.loading = true;
      await transferService
        .getSchedule()
        .then((res) => {
          this.ScheduleList = res;
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Could not fetch institutions", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    //get Current active schedule
    currentSchedule(index) {
      let schedule = this.ScheduleList[index];
      this.$store.commit("SET_CURRENT_SCHEDULE", schedule);
      this.$router.push("confirmSchedule?p=4");
    },

    //delete Schedule
    async deleteSchedule(id) {
      this.loading = true;
      await transferService
        .deleteSchedule(id, this.AUTHENTICATION_TOKEN)
        .then((res) => {
          this.$toastr.success(res.status, "Schedule deleted", {
            timeOut: 5000,
          });
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Failed to delete", { timeOut: 5000 });
        })
        .finally(() => {
          this.loading = false;
          this.$router.push("ManageSchedule?p=4");
        });
    },
  },
  watch: {
    beneficiaryAccount(newval) {
      if (newval.toString().length == 10) this.validateBeneficiaryAccount();
    },
    transferAccountFrom(newval) {
      let userAccounts = this.$store.getters.GET_USER.corporateAccounts;
      let filtered = userAccounts.filter((a) => a.accountNumber != newval);
      this.$store.commit("SET_FILTERED_USER", filtered);
    },
  },
  mounted() {
    this.transferAccount = "";
    this.getSchedule();
    this.getInstitution();

    if (this.routeQuery == 1) {
      this.transferAccount = "";
      this.one = true;
      return;
    }

    if (this.routeQuery == 2) {
      this.transferAccountFrom = "";
      this.$store.commit(
        "SET_TRANSFER_ACCOUNT",
        this.user.corporateAccounts[0].accountNumber
      );
      this.two = true;
      return;
    }

    if (this.routeQuery == 3) {
      this.transferAccountFrom = "";
      this.$store.commit(
        "SET_TRANSFER_ACCOUNT",
        this.user.corporateAccounts[0].accountNumber
      );
      this.three = true;
      return;
    }

    if (this.routeQuery == 4) {
      this.four = true;
    }
  },
  created() {
    if (this.transferAccountFrom) return;

    this.$store.commit(
      "SET_TRANSFER_ACCOUNT_FROM",
      this.user.corporateAccounts[0].accountNumber
    );
  },
};
</script>

<style scoped>
body {
  background: #000000;
}
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}
.quick-transfer {
  padding: 0 0.7rem;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
/* .poster {
  background-color: rgb(0, 94, 194, 0.1) !important;
} */

@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
