<template>
  <div class>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNavBiz />
      <div class="container">
        <BusinessNavBar />
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <p class="frequently-used">Change Password</p>
                </div>
                <div class="current_tab_title mb-3">
                  <font-awesome-icon icon="play" class="play-icon" />
                  <p class>Change Password to keep your account protected</p>
                </div>
                <div class="kindly_enter_details mb-3">
                  <font-awesome-icon
                    icon="exclamation-circle"
                    class="exclamation-icon"
                  />
                  <p>
                    To change Password you must input the old Password, an OTP
                    will be sent to you for verification
                  </p>
                </div>
                <ValidationObserver v-slot="{ passes }">
                  <form action @submit.prevent="passes(changePassword)">
                    <div class="form-table">
                      <div class="label-title2">
                        <label>Old Password</label>
                        <ValidationProvider
                          name="oldPassword"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="account_select2">
                            <input
                              type="password"
                              v-model="oldPassword"
                              class="input-amount"
                              placeholder="******"
                            />
                            <!--<input type="number" class="input-amount" placeholder="-"/>-->
                          </div>
                          <span style="font-size: 13px; color: red" class>
                            <span v-if="errors[0]">
                              <font-awesome-icon
                                icon="ban"
                                size="1x"
                                class="ban-icon text-danger"
                              />
                            </span>
                            {{ errors[0] }}
                          </span>
                        </ValidationProvider>
                      </div>
                      <div class="label-title2">
                        <label>New Password</label>
                        <ValidationProvider
                          name="password"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="account_select2" id="tooltip-variant">
                            <input
                              type="password"
                              v-model="password1"
                              ref="pin"
                              class="input-amount"
                              placeholder="******"
                            />
                          </div>
                          <b-tooltip
                            target="tooltip-variant"
                            variant="info"
                            custom-class="my-tooltip-class"
                            placement="left"
                            >Password must be atleast 8 characters long and must
                            contain an uppercase, lowercase, a number and a
                            symbol (e.g %, @, $, #).</b-tooltip
                          >
                          <span style="font-size: 13px; color: red" class>
                            <span v-if="errors[0]">
                              <font-awesome-icon
                                icon="ban"
                                size="1x"
                                class="ban-icon text-danger"
                              />
                            </span>
                            {{ errors[0] }}
                          </span>
                        </ValidationProvider>
                      </div>
                      <div class="label-title2">
                        <label>Confirm Password</label>
                        <ValidationProvider
                          name="passwords"
                          rules="required|confirmed:password"
                          v-slot="{ errors }"
                        >
                          <div class="account_select2">
                            <input
                              type="password"
                              v-model="newPassword"
                              data-vv-name="password"
                              class="input-amount"
                              placeholder="******"
                            />
                          </div>
                          <span style="font-size: 13px; color: red" class>
                            <span v-if="errors[0]">
                              <font-awesome-icon
                                icon="ban"
                                size="1x"
                                class="ban-icon text-danger"
                              />
                            </span>
                            {{ errors[0] }}
                          </span>
                        </ValidationProvider>
                      </div>
                      <!-- <div class="label-title2">
                                            <label >Token/OTP</label>
                                            <div class="account_select2">
                                                <the-mask mask="######" type="number" class="input-amount" placeholder="000000"/>
                                            </div>
                                        </div>
                      <div class="resend-otp">Resend OTP</div>-->

                      <div class="send-or-download2">
                        <div class="continue-button">
                          <a @click="$router.go(-1)">
                            <div class="continue-cib-btn">
                              <font-awesome-icon
                                icon="angle-left"
                                class="download-icon"
                              />BACK
                            </div>
                          </a>
                        </div>
                        <div class="continue-button">
                          <b-button type="submit" class="continue-cib-btn">
                            SUBMIT
                            <font-awesome-icon
                              icon="angle-right"
                              class="angle-icon"
                            />
                          </b-button>
                          <!--<button class="continue-cib-btn">CONTINUE <font-awesome-icon icon="angle-right" class="angle-icon"/></button>-->
                        </div>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar
                :accountNumber="user.corporateAccounts[0].accountNumber"
                :accountBalance="user.corporateAccounts[0].balance"
              ></SideBar>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>
      <b-modal
        id="modal-center"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <h1 class="pin-info text-center">
              Insert PIN to finish
              <br />transaction
            </h1>
            <div class="dialpad">
              <div class="form-group">
                <the-mask
                  type="password"
                  class="form-control"
                  v-model="pin"
                  mask="######"
                  id="txtBox"
                  placeholder="******"
                />
              </div>
              <Keyboard
                @pressed="pin = $event"
                :pinLength="pin"
                @completed="changePassword"
                :selfValue="pin"
              ></Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import HeaderNavBiz from "../../../../components/HeaderNav/HeaderNavBiz";
import BusinessNavBar from "../../../../components/HeaderNav/BusinessNavBar";
import { TheMask } from "vue-the-mask";
import SideBar from "../../../../components/SideBar/SideBar_";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { securityService } from "../../../../services/security.services.js";
import Loader from "../../../../components/Loader/Loader.vue";
import { mapGetters } from "vuex";
import Keyboard from "../../../../components/Keyboard/Keyboard.vue";
import Footer3 from "../../../../components/Footer/Footer3";

export default {
  name: "ChangePassword.vue",
  components: {
    TheMask,
    HeaderNavBiz,
    BusinessNavBar,
    SideBar,
    ValidationObserver,
    ValidationProvider,
    Loader,
    Keyboard,
    Footer3,
  },
  data() {
    return {
      loading: false,
      oldPassword: "",
      newPassword: "",
      password1: "",
      pin: "",
    };
  },
  computed: {
    ...mapGetters({ user: "GET_USER" }),
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },

    //Change password
    async changePassword() {
      this.loading = true;
      await securityService
        .changePassword({
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        })
        .then((res) => {
          this.loading = false;
          this.$router.push("/dashboard_");
          this.$toastr.success(res.status || "Password Change Successfull", {
            timeOut: 5000,
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toastr.error(error.status, "Password Change Failed", {
            timeOut: 5000,
          });
        });
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}
.quick-transfer {
  padding: 0 2.2rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
/* .poster {
  background-color: rgb(0, 94, 194, 0.1) !important;
}
.current_tab_title {
  background-image: linear-gradient(
    180deg,
    #00c4ff 0%,
    #0091ff 100%
  ) !important;
} */
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
