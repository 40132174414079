<template>
    <div>
      <div class="main_body" @click="closeModal()">
        <div class="receipt_page" @click="(event) => event.stopPropagation()">
          <div ref="html" class="receipt_page_download">
            <div  class="receipt_body">
              <div class="receipt_body_heading">
                  <div>Transaction Receipt</div>
                  <img
                  src="../../assets/images/banner.svg"
                  alt="Hope Bank Logo" width="100%"
                  />
              </div>

              <div class="receipt_tick">
                <img src="../../assets/images/icons/transaction_tick.svg" alt="">
                <h5><b>Successful Transaction</b></h5>
                <p>{{ TRANSACTION_DETAILS.date }}</p>
              </div>

              <div class="receipt_body_details">
                <div class="receipt_body_detail">
                  <div>Account Name</div>
                  <span><b>{{ USER.accounts[0].accountName }}</b></span>
                </div>

                <div class="receipt_body_detail">
                  <div>Account Number</div>
                  <!-- <span v-if="TRANSACTION_DETAILS.data.from"><b>{{hashAccount(TRANSACTION_DETAILS.data.from)}}</b></span> -->
                  <span v-if="TRANSACTION_DETAILS.data.account"><b>{{hashAccount(TRANSACTION_DETAILS.data.account)}}</b></span>
                </div>

                <div class="receipt_body_detail" v-if="TRANSACTION_DETAILS.data['beneficiary Name']">
                  <div>Beneficiary Name</div>
                  <span><b>{{TRANSACTION_DETAILS.data['beneficiary Name']}}</b></span>
                </div>

                <div class="receipt_body_detail" v-if="TRANSACTION_DETAILS.data.beneficiary">
                  <div>Beneficiary Account Number</div>
                  <span><b>{{ TRANSACTION_DETAILS.data.beneficiary }}</b></span>
                </div>

                <div class="receipt_body_detail" v-if="TRANSACTION_DETAILS.data['destination Bank']">
                  <div>Beneficiary Bank</div>
                  <span><b>{{TRANSACTION_DETAILS.data['destination Bank']}}</b></span>
                </div>

                <div class="receipt_body_detail">
                  <div>Transaction Amount</div>
                  <span><b>{{ TRANSACTION_DETAILS.data.amount }}</b></span>
                </div>

                <div v-if="TRANSACTION_DETAILS.data.fee" class="receipt_body_detail">
                  <div>Transaction Fee</div>
                  <span><b>{{ TRANSACTION_DETAILS.data.fee }}</b></span>
                </div>

                <div class="receipt_body_detail">
                  <div>Transaction Type</div>
                  <span><b>{{ TRANSACTION_DETAILS.data["transaction Type"] }}</b></span>
                </div>

                <div v-if="TRANSACTION_DETAILS.data.narration" class="receipt_body_detail">
                  <div>Transaction Note</div>
                  <span><b>{{ TRANSACTION_DETAILS.data.narration }}</b></span>
                </div>

                <div v-if="TRANSACTION_DETAILS.data.transactionRef" class="receipt_body_detail">
                  <div>Transaction Ref</div>
                  <div><b>{{TRANSACTION_DETAILS.data.transactionRef}}</b></div>
                </div>

                <div v-if="TRANSACTION_DETAILS.data['reference Number']" class="receipt_body_detail">
                  <div>Reference No.</div>
                  <div><b>{{TRANSACTION_DETAILS.data['reference Number']}}</b></div>
                </div>

                <div v-if="TRANSACTION_DETAILS.data.status" class="receipt_body_detail">
                  <div>Transaction Status</div>
                  <span><b :class="{approved : TRANSACTION_DETAILS.data.status = 'Approved'}">{{ TRANSACTION_DETAILS.data.status }}</b></span>
                </div>

              </div>
            </div>
          </div>
          <div class="receipt_cta">
            <button> 
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.1875 13.5084C15.0142 13.5084 13.9803 14.0855 13.3309 14.9633L7.71627 12.0883C7.80949 11.7705 7.875 11.4412 7.875 11.0938C7.875 10.6225 7.77838 10.1745 7.61145 9.76236L13.4874 6.22646C14.1413 6.9939 15.1025 7.4917 16.1875 7.4917C18.152 7.4917 19.75 5.89368 19.75 3.9292C19.75 1.96472 18.152 0.366699 16.1875 0.366699C14.223 0.366699 12.625 1.96472 12.625 3.9292C12.625 4.38191 12.7182 4.81135 12.8729 5.21004L6.97943 8.75631C6.32606 8.01167 5.37876 7.53128 4.3125 7.53128C2.34802 7.53128 0.75 9.1293 0.75 11.0938C0.75 13.0583 2.34802 14.6563 4.3125 14.6563C5.50515 14.6563 6.55652 14.0622 7.20347 13.1598L12.7996 16.0254C12.6965 16.3582 12.625 16.7047 12.625 17.0709C12.625 19.0353 14.223 20.6334 16.1875 20.6334C18.152 20.6334 19.75 19.0353 19.75 17.0709C19.75 15.1064 18.152 13.5084 16.1875 13.5084Z" fill="#E6AA02"/>
              </svg>

              Email
            </button>
            <button @click="exportToPDF">
              <svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.5303 19.3517H17.8639V10.7518C17.8639 10.5957 17.7344 10.4679 17.5761 10.4679H15.4171C15.2588 10.4679 15.1292 10.5957 15.1292 10.7518V19.3517H12.4701C12.229 19.3517 12.0959 19.625 12.2434 19.8095L16.2735 24.8388C16.3004 24.8727 16.3348 24.9002 16.3741 24.9191C16.4134 24.9379 16.4565 24.9477 16.5002 24.9477C16.5439 24.9477 16.587 24.9379 16.6263 24.9191C16.6655 24.9002 16.7 24.8727 16.7269 24.8388L20.757 19.8095C20.9045 19.625 20.7714 19.3517 20.5303 19.3517Z" fill="white"/>
              <path d="M27.0258 7.40355C25.4156 3.10736 21.3129 0.0523682 16.507 0.0523682C11.7012 0.0523682 7.59844 3.1038 5.98828 7.4C2.97539 8.2002 0.75 10.9778 0.75 14.2782C0.75 18.2081 3.89648 21.3911 7.77773 21.3911H9.1875C9.34219 21.3911 9.46875 21.263 9.46875 21.1066V18.9727C9.46875 18.8162 9.34219 18.6882 9.1875 18.6882H7.77773C6.59297 18.6882 5.47852 18.2116 4.64883 17.3474C3.82266 16.4867 3.3832 15.3273 3.42188 14.1252C3.45352 13.1863 3.76992 12.3043 4.34297 11.561C4.93008 10.8035 5.75273 10.2523 6.6668 10.0069L7.99922 9.65479L8.48789 8.35313C8.79023 7.54225 9.21211 6.78473 9.74297 6.09834C10.267 5.41801 10.8878 4.81997 11.5852 4.32367C13.0301 3.29585 14.7316 2.75171 16.507 2.75171C18.2824 2.75171 19.984 3.29585 21.4289 4.32367C22.1285 4.82157 22.7473 5.41905 23.2711 6.09834C23.802 6.78473 24.2238 7.54581 24.5262 8.35313L25.0113 9.65123L26.3402 10.0069C28.2457 10.5261 29.5781 12.2794 29.5781 14.2782C29.5781 15.4554 29.1246 16.565 28.302 17.3972C27.8985 17.8077 27.4186 18.1332 26.8899 18.3548C26.3613 18.5764 25.7945 18.6897 25.2223 18.6882H23.8125C23.6578 18.6882 23.5312 18.8162 23.5312 18.9727V21.1066C23.5312 21.263 23.6578 21.3911 23.8125 21.3911H25.2223C29.1035 21.3911 32.25 18.2081 32.25 14.2782C32.25 10.9813 30.0316 8.20731 27.0258 7.40355Z" fill="white"/>
              </svg>
              Download
            </button>
          </div>
        </div>

      </div>
    </div>
  </template>
  
<script>
import { mapGetters } from "vuex";
import html2pdf from "html2pdf.js";

export default {
  name: "TransactionReceipt",
  data() {
    return {
      post: null,
    };
  },
  computed: {
    ...mapGetters({
      TRANSACTION_DETAILS: "GET_TRANSACTION",
      USER: "GET_USER",
    }),
  },

  methods: {
    closeModal(){
      this.$emit('closeModal')
    },
    hashAccount(string) {
      let slicedFront = string.slice(0, 3);
      let slicedBack = string.slice(7);
      return `${slicedFront}****${slicedBack}`;
    },

    //download html as pdf
    exportToPDF() {
      html2pdf(this.$refs.html, {
        margin: 0,
        compress: true,
        filename: `HOPEPSBANK RECEIPT.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 392, letterRendering: true },
        jsPDF: { unit: "in", format: "a5", orientation: "portrait" },
      });
    },
  },
};
</script>
  
<style scoped>
.approved{
  color: rgb(0, 203, 0)
}
p{
  margin: 0
}
.close{
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
.main_body{
  padding: 40px 0;
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: scroll;
  display: grid;
  place-items: center;
  z-index: 2001;
  backdrop-filter: blur(10px);
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.381);
}
.receipt_page{
  margin:40px 0;
  background: white;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 500px;
  width: 90%;
  border-radius: 10px;
  z-index: 2002;
}
.receipt_page_download{
  padding: 30px;
  padding-bottom: 20px;

}
.receipt_logo img{
  border-radius: 20px;
  width: 100px;
}
.receipt_body_heading{
  margin: 30px 0;
  margin-top: 0px;
  text-align: center;
  position: relative;
  background: #E6AA02;
  padding: 12px;
  border-radius: 10px;
  color: white

}
.receipt_body_heading div{
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px
}
.receipt_body_heading img{
  position: absolute;
  width: 70px;
  top: 50%;
  right: 10px;
  transform: translateY(-50%)
}
.receipt_body_heading p:first-child{
  margin-bottom: 10px;
}
.receipt_tick{
  text-align: center
}
.receipt_tick img{
  width: 100px;
  margin-bottom: 20px
}
.receipt_body_details{
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #0053C5;
  border-bottom: 1px solid #0053C5;

}
.receipt_body_detail{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  font-size: 14px;
}
.receipt_body_detail div:first-child{
  color: #E6AA02
}
.receipt_body_detail:last-child{
  margin-bottom: 0
}
.receipt_body_detail div{
  font-weight: 700;

}
.receipt_body_detail span:first-child{
  font-weight: 100
}
.receipt_cta{
  display: flex;
  justify-content: space-between; 
  margin-top: 20px;
  padding: 40px;
  padding-top: 0px 
}
.receipt_cta button{
  padding: 10px;
  width: 100%;
  color: #FFF;
  font-weight: 700;
  border-radius: 10px;
  background: #E6AA02;
}
.receipt_cta button:first-child{
  margin-right: 20px;
  background: none;
  color: #E6AA02;
  border: 1px solid #E6AA02;
}
.receipt_cta svg{
  margin-right: 10px
}
@media screen and (max-width: 474px){
  .receipt_page_download{
    padding: 14px
  }
  .receipt_body_heading div{
    font-size: 14px
  }
.receipt_body_detail{
  font-size: 11px
}
  .receipt_cta{
    padding: 20px;
    padding-top: 0px
  }
  .receipt_cta button{
    font-size: 14px
    padding
  }
  .receipt_cta button svg{
    width: 20px;
  }
}
</style>