<template>
  <div class="hope-login-page">
    <div class="poster"></div>
    <Loader v-if="loading" :show-full="true" loading-text="Logging in..." />
    <Loader v-if="loading1" :show-full="true" loading-text="Please wait..." />

    <div class="upper-nav">
      <div class="hope-logo">
        <a href="/">
          <img
            src="../../assets/images/logo/hope_bank_logo1.svg"
            alt="Hope Bank Logo"
            class="hope_logo"
          />
        </a>
      </div>
      <div class="back-logout">
        <router-link to="digitalbanking">
          <!-- <button class="go-back-btn" @click="$router.go(-1)"> -->
          <div class="go-back-btn">
            <span>
              <font-awesome-icon icon="arrow-left" class="angle-left" />
            </span>
            Back
          </div>
        </router-link>
        <!-- <router-link to="LoginBusiness">
          <button class="logout-btn2">Business</button>
        </router-link>-->
      </div>
    </div>
    <div class="container">
      <div class="login_section">
        <div class="login-section">
          <div class="left-side">
            <router-link to="/">
              <img
                src="../../assets/images/logo/hope_bank_logo1.svg"
                alt="Hope Bank Logo"
                class="hope_logo2"
              />
            </router-link>
            <p class="login-info">Login to your Account</p>
            <form @submit.prevent="login" class="form-login">
              <div class="user-login">
                <div class="username">
                  <input
                    type="text"
                    class="username-input"
                    placeholder="Phone Number"
                    v-model="Phone"
                    required
                  />
                  <font-awesome-icon icon="phone" class="login_icons" />
                </div>
                <div class="username">
                  <input
                    type="password"
                    class="username-input"
                    placeholder="Password"
                    v-model="Password"
                    required
                  />
                  <font-awesome-icon icon="lock" class="login_icons" />
                </div>
                <!-- <label class="container-checkbox remember_me text-left">Remember Me
                                    <input type="checkbox" checked="checked">
                                    <span class="checkmark"></span>
                </label>-->
                <div class="forgot-password">
                  <span
                    style="cursor: pointer"
                    @click="showPasswordModal = true"
                  >
                    Forgot Password?
                  </span>
                </div>
              </div>
              <!-- <b-button
                v-b-modal.modal-center2
                class="open_account_btn hvr-icon-wobble-horizontal"
              >Login as</b-button>-->
              <!-- <b-modal id="modal-center2" scrollable size="md" centered hide-footer> -->
              <!-- <div class="modal_scroll_texts">
                <div class="biz-individual text-center">
                  <h5 v-if="dataModal">
                    Are you a trying to Login as an Individual or Business
                  </h5>
                  <div class="btns-biz-individual">
                    <button class="btn-for-individual">Individual</button>
                    <button class="btn-for-biz">Business</button>
                  </div>
                </div>
              </div> -->
              <!-- </b-modal> -->
              <!-- <div class="have-an-account">
                <p>Are you new here?</p>
                <router-link to="Signup" class="register_now_btn">Register</router-link>
              </div>-->
              <div class="login-button">
                <button class="login-btn" type="submit">LOGIN</button>
              </div>
            </form>
          </div>

          <div class="right-side">
            <div>
              <b-carousel
                id="carousel-fade"
                fade
                img-width="100%"
                img-height="480"
              >
                <b-carousel-slide>
                  <template #img>
                    <img
                      class="d-block"
                      width="100%"
                      height="300px"
                      src="../../assets/images/lady-smile.jpg"
                      alt="Hope PSBank"
                    />
                  </template>
                </b-carousel-slide>
                <b-carousel-slide>
                  <template #img>
                    <img
                      class="d-block"
                      width="100%"
                      height="300px"
                      src="../../assets/images/user1.jpg"
                      alt="Hope PSBank"
                    />
                  </template>
                </b-carousel-slide>
                <b-carousel-slide>
                  <template #img>
                    <img
                      class="d-block"
                      width="100%"
                      height="300px"
                      src="../../assets/images/user2.jpg"
                      alt="Hope PSBank"
                    />
                  </template>
                </b-carousel-slide>
                <b-carousel-slide>
                  <template #img>
                    <img
                      class="d-block"
                      width="100%"
                      height="300px"
                      src="../../assets/images/user3.jpg"
                      alt="Hope PSBank"
                    />
                  </template>
                </b-carousel-slide>
                <b-carousel-slide>
                  <template #img>
                    <img
                      class="d-block"
                      width="100%"
                      height="300px"
                      src="../../assets/images/user4.jpg"
                      alt="Hope PSBank"
                    />
                  </template>
                </b-carousel-slide>
              </b-carousel>
            </div>

            <div class="register-info">
              <!--<img src="../../assets/images/max_bg2.jpg" alt="Hope Bank">-->
              <h1>
                Become Elegant and
                <br />Hopeful for the future of <br />Banking.
              </h1>
              <div class="get-benefits pb-2">
                <p>Get benefits for the Future.</p>
                <!-- <router-link to="Signup">
                  <div class="register-button">
                    <button class="register-btn">Register</button>
                    <div class="angle-right2">
                      <font-awesome-icon icon="angle-right" class="angleright" />
                    </div>
                  </div>
                </router-link>-->
              </div>
              <!-- <div class="get-benefits">
                <p>Digital Banking at its finest!</p>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"-->
    <b-modal id="modal-center" centered hide-footer v-model="showPasswordModal">
      <ValidationObserver v-slot="{ passes }">
        <form ref="form" @submit.prevent="passes(resetPassword)">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <h6 class="text-center">
              Enter your Account Number to Reset your password
            </h6>
            <div class="label-title2">
              <label>Account Number*</label>
              <ValidationProvider
                name="password"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="account_select3">
                  <the-mask
                    mask="##########"
                    v-model="Account"
                    ref="password"
                    type="password"
                    class="input-amount"
                    placeholder="**********"
                  />
                </div>
                <span style="font-size: 13px; color: red" class>
                  <span v-if="errors[0]">
                    <font-awesome-icon
                      icon="ban"
                      size="1x"
                      class="ban-icon text-danger"
                    />
                  </span>
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="continue-button float-right">
              <b-button
                :disabled="Account.toString().length != 10"
                type="submit"
                class="continue-btn"
              >
                SUBMIT
                <font-awesome-icon icon="angle-right" class="angle-icon" />
              </b-button>
              <!--<button class="continue-btn">CONTINUE <font-awesome-icon icon="angle-right" class="angle-icon"/></button>-->
            </div>
          </b-form-group>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal
      id="modal-center-bvn-info"
      centered
      v-model="forgotModal"
      @show="forgotModal"
      @hidden="forgotModal = false"
      @ok="handleOk"
      hide-footer
    >
      <form ref="form" @submit.stop.prevent="forgotModal = true">
        <b-form-group
          :state="nameState"
          label-for="name-input"
          invalid-feedback="PIN is required"
          class="pin-info"
        >
          <div class="dialpad">
            <div class="start_account_buttons">
              <!-- <router-link to="signup"> -->
              <button
                @click="showPasswordModal = true"
                class="start_account_btn"
              >
                Personal Account
              </button>
              <!-- </router-link> -->
            </div>
            <div class="start_account_buttons">
              <!-- <router-link to="/corporate/register"> -->
              <button class="start_account_btn">Business Account</button>
              <!-- </router-link> -->
            </div>
          </div>
        </b-form-group>
      </form>
    </b-modal>
    <div
      class="context-menu"
      v-show="isVisible"
      ref="context"
      tabindex="0"
      @blur="close"
    >
      <!-- <div class="popContainer"> -->
      <form ref="form">
        <b-form-group
          :state="nameState"
          label-for="name-input"
          invalid-feedback="PIN is required"
          class="pin-info"
        >
          <div class="dialpad">
            <div class="start_account_buttons">
              <span
                style="cursor: pointer"
                @click="showPasswordModal = true"
                class="start_account_btn"
              >
                Personal Account
              </span>
            </div>
            <div class="start_account_buttons">
              <!-- <router-link to="/corporate/register"> -->
              <span style="cursor: pointer" class="start_account_btn"
                >Business Account</span
              >
              <!-- </router-link> -->
            </div>
          </div>
        </b-form-group>
      </form>
      <!-- </div> -->
    </div>
    <Footer2 />
    <!-- <Footer/> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import router from "../../router.js";
import Loader from "../../components/Loader/Loader.vue";
import { TheMask } from "vue-the-mask";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { securityService } from "../../services/security.services.js";
import { mapGetters } from "vuex";
import Footer2 from "../../components/Footer/Footer2";

export default {
  name: "Login.vue",
  components: {
    Loader,
    ValidationObserver,
    ValidationProvider,
    TheMask,
    Footer2,
  },

  data() {
    return {
      list: [],
      sliderValue: 2,
      Phone: null,
      Password: null,
      Account: "",
      loading: false,
      loading1: false,
      errors: [],
      showPasswordModal: false,
      forgotModal: false,
      dataModal: false,
      isVisible: false,
    };
  },

  computed: {
    ...mapGetters({
      user: "GET_USER",
    }),
    routeQuery() {
      return this.$route.query.path;
    },
    style() {
      return {
        top: this.top + "px",
        left: this.left + "px",
      };
    },
  },

  methods: {
    changeIndex(index) {
      this.sliderValue = index;
    },
    // toggleVisibility(evt) {
    //   this.left = evt.pageX || evt.clientX;
    //   this.top = (evt.pageY || evt.clientY) - window.pageYOffset;
    //   this.isVisible = !this.isVisible;
    // },
    close() {
      // this.show = false;
      this.isVisible = false;
      this.left = 0;
      this.top = 0;
    },

    hideDropdown() {
      this.isVisible = false;
      this.focusedIndex = 0;
    },

    mask() {
      let word = this.Password;
      if (word.length <= 0) {
        return word;
      } else {
        var masked =
          word.substring(0, word.length - 0).replace(/\d/g, "*") +
          word.substring(word.length - 0, word.length);
        return masked;
      }
    },

    //get Security questions
    async resetPassword() {
      this.loading1 = true;
      await securityService
        .resetPassword(this.Account)
        .then((res) => {
          window.console.log(res);
          this.loading1 = false;
          this.$store.commit("SET_SECURITY_QUESTIONS", res.questions);
          this.$store.commit("SET_USER_DATA", this.Account);
          this.$router.push("/ResetPassword");
        })
        .catch((error) => {
          this.loading1 = false;
          this.$toastr.error(error.status, "Reset Pin Failed!", {
            timeOut: 5000,
          });
        });
    },
    ...mapActions({ loginUser: "LOGIN" }),
    async login() {
      this.loading = true;
      await this.loginUser({
        Phone: this.Phone.trim(),
        Password: this.Password,
      })
        .then(() => {
          window.console.log("router query", this.routeQuery);
          if (this.routeQuery == "register") {
            router.push("/Dashboard?path=register");
            window.location.reload();
            return;
          }
          // Route to business page
          if (this.routeQuery == "business") {
            router.push("/Dashboard_?path=business");
            window.location.reload();
            return;
          }
          // Route to confirmmynin
          if (this.routeQuery == "confirmmynin") {
            router.push("/confirmmynin");
            window.location.reload();
            return;
          }

          //route to verifyid
          if (this.routeQuery == "verifyid") {
            router.push("/verifyid");
            window.location.reload();
            return;
          }

          //if user has both PIB and CIB
          if (this.user.hasCorporate && this.user.accounts[0]) {
            router.push("/ibanking");
            window.location.reload();
            return;
          }
          //if user has only CIB
          if (this.user.hasCorporate && !this.user.accounts[0]) {
            router.push("/Dashboard_");
            window.location.reload();
            return;
          }
          // if user has only PIB
          router.push("/Dashboard");
          window.location.reload();
        })
        .catch((error) => {
          this.$toastr.error(error.status, "Login Failed!", { timeOut: 5000 });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    setTimeout(() => (this.list = []), 500);
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}
.go-back-btn:hover {
  /* color: #000000;
        background: #ffffff; */
}
.logout-btn2:hover {
  /* color: #ffffff;
        background: #000000; */
}
.modal-body {
  padding: 1rem !important;
}
.btns-biz-individual {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}
.btn-for-individual {
  font-family: Montserrat-SemiBold !important;
  font-size: 13px !important;
  color: #000000 !important;
  background: #fecb32 !important;
  border: 1px solid #fecb32 !important;
  border-radius: 5px;
  padding: 10px 1.5rem;
  letter-spacing: 0.17px;
  margin: 5px;
}
.btn-for-individual:hover {
  color: #000000 !important;
  background: #ffffff !important;
  border: 1px solid #fecb32 !important;
}
.btn-for-biz {
  font-family: Montserrat-SemiBold !important;
  font-size: 13px !important;
  color: #000000 !important;
  background: #fecb32 !important;
  border: 1px solid #fecb32 !important;
  border-radius: 5px;
  padding: 10px 1.5rem;
  letter-spacing: 0.17px;
  margin: 5px;
}
.btn-for-biz:hover {
  color: #000000 !important;
  background: #ffffff !important;
  border: 1px solid #fecb32 !important;
}
.context-menu {
  background-color: #fff;
  display: block;
  position: absolute;
  left: 653px;
  top: 455px;
  z-index: 999;
  padding: 2rem 1.5rem;
  padding-bottom: 1rem;
  box-shadow: 3px 3px 7px 0 rgb(0 0 0 / 15%);
  border: 1px solid #e0e1e2;
  text-align: center;
  width: 252px;
  border-radius: 5px;
}
@media (max-width: 768px) {
  .context-menu {
    left: 241px;
    top: 563px;
  }
}
@media (max-width: 640px) {
  .context-menu {
    left: 59px;
    top: 376px;
  }
}
</style>