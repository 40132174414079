import { CardService } from "../../../services/card.service";


const actions = {
    //Transfer to Hope account
   async REQUEST_CARD ({state}, data) {
   return await CardService.requestForCard(data)
    .then (res => {
        window.console.log(state)
       return Promise.resolve(res);
       // window.console.log('success', res)
    })
    .catch (err => {
       return Promise.reject(err);
       // window.console.log('failed', err)
    })
    }
    
   


   
}

export {
    actions
};