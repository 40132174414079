<template>
  <div class="poster-bg">
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNavBiz />
      <div class="container">
        <BusinessNavBar />
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-12">
              <div class="main_dashboard_inner3">
                <div class="transfer_money">
                  <p class="frequently-used">Manage Beneficiary</p>
                </div>

                <div class="recent-transactions">
                  <table>
                    <tr>
                      <th>S/N</th>
                      <th>Name</th>
                      <th>Account Number</th>
                      <th>Bank</th>
                      <th>Date</th>
                      <th>
                        Select All
                        <input type="checkbox" />
                      </th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Chioma Chukwuma</td>
                      <td>0987654321</td>
                      <td>First Bank</td>
                      <td>12-05-2019</td>
                      <td>
                        <input type="checkbox" />
                        <span class="delete_one">
                          <font-awesome-icon icon="trash-alt" class />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Chioma Chukwuma</td>
                      <td>0987654321</td>
                      <td>First Bank</td>
                      <td>12-05-2019</td>
                      <td>
                        <input type="checkbox" />
                        <span class="delete_one">
                          <font-awesome-icon icon="trash-alt" class />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Chioma Chukwuma</td>
                      <td>0987654321</td>
                      <td>First Bank</td>
                      <td>12-05-2019</td>
                      <td>
                        <input type="checkbox" />
                        <span class="delete_one">
                          <font-awesome-icon icon="trash-alt" class />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Chioma Chukwuma</td>
                      <td>0987654321</td>
                      <td>First Bank</td>
                      <td>12-05-2019</td>
                      <td>
                        <input type="checkbox" />
                        <span class="delete_one">
                          <font-awesome-icon icon="trash-alt" class />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Chioma Chukwuma</td>
                      <td>0987654321</td>
                      <td>First Bank</td>
                      <td>12-05-2019</td>
                      <td>
                        <input type="checkbox" />
                        <span class="delete_one">
                          <font-awesome-icon icon="trash-alt" class />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Chioma Chukwuma</td>
                      <td>0987654321</td>
                      <td>First Bank</td>
                      <td>12-05-2019</td>
                      <td>
                        <input type="checkbox" />
                        <span class="delete_one">
                          <font-awesome-icon icon="trash-alt" class />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Chioma Chukwuma</td>
                      <td>0987654321</td>
                      <td>First Bank</td>
                      <td>12-05-2019</td>
                      <td>
                        <input type="checkbox" />
                        <span class="delete_one">
                          <font-awesome-icon icon="trash-alt" class />
                        </span>
                      </td>
                    </tr>
                    <!--<tr>-->
                    <!--<td>8</td>-->
                    <!--<td>Chioma Chukwuma</td>-->
                    <!--<td>0987654321</td>-->
                    <!--<td>First Bank</td>-->
                    <!--<td>12-05-2019</td>-->
                    <!--<td><input type="checkbox"/><span class="delete_one"><font-awesome-icon icon="trash-alt" class=""/></span></td>-->
                    <!--</tr>-->
                  </table>
                </div>

                <div class="send-or-download2">
                  <div class="continue-button">
                    <a @click="$router.go(-1)">
                      <div class="continue-cib-btn btn">
                        <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                      </div>
                    </a>
                  </div>
                  <div class="continue-button">
                    <button class="continue-cib-btn" disabled>
                      DELETE
                      <font-awesome-icon icon="angle-right" class />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer3 />
    </div>
  </div>
</template>

<script>
import HeaderNavBiz from "../../../../components/HeaderNav/HeaderNavBiz";
import BusinessNavBar from "../../../../components/HeaderNav/BusinessNavBar";
import { getBeneficiaryService } from "../../../../services/beneficiary.service.js";
import Footer3 from "../../../../components/Footer/Footer3";

export default {
  name: "ManageBeneficiary.vue",
  components: {
    HeaderNavBiz,
    BusinessNavBar,
    Footer3,
  },
  mounted() {
    this.getBeneficiary();
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },
    async getBeneficiary() {
      this.loading = true;
      await getBeneficiaryService
        .getBeneficiary()
        .then((res) => {
          this.Beneficiary = res;
          window.console.log(res);
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Could not fetch vas Category", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async DeleteBeneficiaryById(id) {
      this.loading = true;
      await getBeneficiaryService
        .DeleteBeneficiaryById(id)
        .then((res) => {
          this.$toastr.success(res.status, "Successfully fetched", {
            timeOut: 5000,
          });
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Could not fetch vas Category", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}
.recent-transactions {
  max-height: 360px;
  overflow-y: scroll;
}

.navbar {
  padding: 0;
}
.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}
.quick-transfer {
  padding: 0 0.7rem;
}
.main_dashboard_inner3 {
  padding: 1.5rem 2rem 1rem 2rem;
}
tr {
}
th {
  text-align: left;
  font-family: Montserrat-Medium;
  font-size: 12px;
  color: #000000;
  letter-spacing: 0.17px;
  background: #fecb32;
}
td {
  font-family: Montserrat-Medium;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0;
}
tr:nth-child(odd) {
  background-color: #e5e5e5;
}
tr:nth-child(even) {
  background-color: #ffffff;
}
td input {
  margin-left: 2rem;
  width: 20px;
  height: 20px;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
/* .poster {
  background-color: rgb(0, 94, 194, 0.1) !important;
} */
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
