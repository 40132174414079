import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserSecret,
  faSearch,
  faAngleRight,
  faArrowRight,
  faArrowLeft,
  faLock,
  faBars,
  faAngleLeft,
  faPlay,
  faBan,
  faExclamationCircle,
  faCaretDown,
  faBackspace,
  faUserLock,
  faLockOpen,
  faCalendarCheck,
  faDotCircle,
  faUpload,
  faPlus,
  faExclamation,
  faBriefcase,
  faPhone,
  faMapMarkerAlt,
  faPhoneSquareAlt,
  faPaperPlane,
  faDownload,
  faEnvelope,
  faTrashAlt,
  faUser,
  faBell,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faUsers,
  faCaretRight,
  faPowerOff,
  faChevronRight,
  faMoneyBill,
  faClock,
  faChartBar,
  faHistory,
  faGlasses,
  faSitemap,
  faTasks,
  faTrash,
  faPlusCircle,
  faMinusCircle,
  faEdit,
  faCheckDouble,
  faChevronCircleLeft,
  faChevronCircleRight,
  faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faFacebookSquare,
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
  faLinkedinIn,
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
// import { faEdit } from "@fortawesome/free-regular-svg-icons";
library.add(
  faFacebookSquare,
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
  faLinkedinIn,
  faWhatsapp,
  faUserSecret,
  faExclamation,
  faSearch,
  faAngleRight,
  faBan,
  faArrowRight,
  faArrowLeft,
  faLock,
  faBars,
  faAngleLeft,
  faPlay,
  faExclamationCircle,
  faCaretDown,
  faBackspace,
  faUserLock,
  faLockOpen,
  faCalendarCheck,
  faDotCircle,
  faUpload,
  faPlus,
  faBriefcase,
  faPhone,
  faMapMarkerAlt,
  faPhoneSquareAlt,
  faPaperPlane,
  faDownload,
  faEnvelope,
  faTrashAlt,
  faUser,
  faBell,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faUsers,
  faCaretRight,
  faPowerOff,
  faChevronRight,
  faMoneyBill,
  faClock,
  faChartBar,
  faHistory,
  faGlasses,
  faSitemap,
  faTasks,
  faTrash,
  faPlusCircle,
  faMinusCircle,
  faEdit,
  faCheckDouble,
  faChevronCircleLeft,
  faChevronCircleRight,
  faEnvelope,
  faUserCircle
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
