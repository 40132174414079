<template>
  <div class="nav_header2 mt-3">
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />
    <b-navbar toggleable="lg" type="light">
      <b-navbar-brand href="/">
        <img
          src="../../assets/images/logo/hope_bank_logo1.svg"
          alt="Hope Bank Logo"
          class="hope_logo"
        />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav class="yellow_bg">
            <b-nav-item class="logout-btn">
              <router-link to="iBanking" style="color: #FECB32;">Digital Banking</router-link>
            </b-nav-item>
            <b-nav-item class="logout-btn">
              <a @click="logout" style="color: #FECB32;">Logout</a>
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { userService } from "../../services/user.service.js";
import Loader from "../../components/Loader/Loader.vue";
let timeout;

export default {
  name: "HeaderNavRegistered.vue",
  components: {
    Loader
  },
  data() {
    return {
      loading: false
    };
  },

  methods: {
    async logout() {
      this.loading = true;
      const data = {};
      await userService
        .logout(data)
        .then(res => {
          this.$store.commit("SET_AUTH_TOKEN", "");
          this.removeListeners();
          this.$toastr.success(res.status, { timeOut: 3000 });
          this.$router.push("/Login");
          window.location.reload();
        })
        .catch(err => {
          this.$toastr.error(err.status, "Failed!", { timeOut: 5000 });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async logout1() {
      this.loading = true;
      const data = {};
      await userService
        .logout(data)
        .then(res => {
          this.$store.commit("SET_AUTH_TOKEN", "");
          this.removeListeners();
          this.$toastr.success(res.status, { timeOut: 3000 });
          this.$router.push("/Login");
          window.location.reload();
          this.$toastr.info("Session timed out", { timeOut: 5000 });
        })
        .catch(err => {
          this.$toastr.error(err.status, "Failed!", { timeOut: 5000 });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetTimeout() {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.logout1();
      }, 300000);
    },
    activateActivityTracker() {
      window.addEventListener("click", this.resetTimeout);
      window.addEventListener("scroll", this.resetTimeout);
      window.addEventListener("mousemove", this.resetTimeout);
      window.addEventListener("keydown", this.resetTimeout);
    },
    removeListeners() {
      clearTimeout(timeout);
      window.removeEventListener("click", this.resetTimeout);
      window.removeEventListener("scroll", this.resetTimeout);
      window.removeEventListener("mousemove", this.resetTimeout);
      window.removeEventListener("keydown", this.resetTimeout);
    }
  },
  mounted() {
    this.activateActivityTracker();
    this.resetTimeout();
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.quick-transfer {
  padding: 0 2.2rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
  /* letter-spacing: 0.17px; */
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #000000 !important;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link.active:hover {
  color: #000000;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link a.active {
  color: #fecb32 !important;
}
.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}
.white_bg {
  /* background: #ffffff; */
  border-radius: 5px;
  height: 44px;
}
.nav_header_inner {
  padding: 0 1rem;
  background: #000000;
  border-radius: 5px 0 0 5px;
}
.nav_menu_drop {
  color: #ffffff !important;
}
/*.plus_icon{*/
/*margin-right: 1rem;*/
/*font-size: 20px;*/
/*}*/
.yellow_bg {
  background: #000000;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #ffffff !important;
}
.yellow_bg > .navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}
.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
  border-left: 2px solid #ffffff;
}
</style>