<template>
  <div class>
    <div class="poster"></div>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />

    <div class="history_page">
      <div>
        <HeaderNavBiz />
        <div class="container">
          <BusinessNavBar />
          <div class="main_dashboard">
            <div class="row">
              <div class="col-md-12">
                <div class="main_dashboard_inner3">
                  <div class="transaction_history">
                    <div class="row">
                      <div class="col-md-12">
                        <p class="frequently-used">Pending Transactions</p>
                      </div>
                    </div>
                  </div>

                  <div class="recent-transactions">
                    <table>
                      <tr>
                        <th>ID</th>
                        <th>Account</th>
                        <th>Initiator's Name</th>
                        <!-- <th>Beneficiary Name</th> -->
                        <th>Amount</th>
                        <th>Description</th>
                        <th>Time and Date</th>
                        <th>Action</th>
                      </tr>

                      <tr v-for="(transaction, index) in PendingAuthorizations" :key="index">
                        <td>{{transaction.id}}</td>
                        <td>{{transaction.account}}</td>
                        <td>Maker - {{transaction.requester}}</td>
                        <!-- <td v-if="transaction.data">{{transaction.data.beneficiaryName}}</td>
                        <td v-else></td>-->
                        <td>{{transaction.amount}}</td>
                        <td>{{transaction.description}}</td>
                        <td>{{transaction.requestTime}}</td>
                        <td ><div style="cursor:pointer" @click="transactionDetails(index)" class="preview_btn">Preview</div></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <b-modal
            id="modal-center"
            centered
            title="Transaction History"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            hide-footer
          >
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <b-form-group :state="nameState" label-for="name-input" class="pin-info">
                <!--<h1 class="pin-info text-center">Transaction History</h1>-->
                <div class="current_tab_title">
                  <font-awesome-icon icon="play" class="play-icon" />
                  <p class>See all your Transaction History</p>
                </div>
                <div class="kindly_enter_details">
                  <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                  <p>Select the Date you want to fetch details from</p>
                </div>
                <div class="label-title2">
                  <label>Start Transfer</label>
                  <div v-if="oneoff" class="account_select">
                    <datetime v-model="start_date" placeholder="-DD-MM-YY-" class="input-amount"></datetime>
                    <font-awesome-icon icon="calendar-check" class />
                  </div>
                </div>
                <div class="label-title2">
                  <label>End Transfer</label>
                  <div v-if="oneoff" class="account_select">
                    <datetime v-model="end_date" placeholder="-DD-MM-YY-" class="input-amount"></datetime>
                    <font-awesome-icon icon="calendar-check" class />
                  </div>
                </div>
                <div class="send-or-download2">
                  <router-link to="History">
                    <button class="download-btn2">
                      <font-awesome-icon icon="angle-left" class="download-icon" />EXIT
                    </button>
                  </router-link>
                  <button class="download-btn2">
                    SEARCH
                    <font-awesome-icon icon="angle-right" class="download-icon2" />
                  </button>
                </div>
              </b-form-group>
            </form>
          </b-modal>
        </div>
      </div>
      <Footer3 />
    </div>
  </div>
</template>

<script>
import HeaderNavBiz from "../../../../components/HeaderNav/HeaderNavBiz";
import BusinessNavBar from "../../../../components/HeaderNav/BusinessNavBar";
import { Datetime } from "vue-datetime";
import { CorporateAccountService } from "../../../../services/CorporateAccount/corporateAccountTransaction.services.js";
import Loader from "../../../../components/Loader/Loader.vue";
import { mapState } from "vuex";
import Footer3 from "../../../../components/Footer/Footer3";

export default {
  name: "Pending",
  components: {
    datetime: Datetime,
    HeaderNavBiz,
    BusinessNavBar,
    Loader,
    Footer3,
  },

  data() {
    return {
      oneoff: true,
      start_date: null,
      end_date: null,
      PendingAuthorizations: [],
      loading: false,
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.User.AUTHENTICATION_TOKEN,
    }),
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },
    getPendingAuthorizations() {
      this.loading = true;
      CorporateAccountService.pendingAuthorizations({ token: this.token })
        .then((res) => {
          this.PendingAuthorizations = res;
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Failed", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    transactionDetails(n) {
      let transactions = this.PendingAuthorizations[n];
      this.$store.commit("SET_TRANSACTION_DATA", transactions);
      if (transactions.action == "CRA1")
        return this.$router.push("confirm?p=cra");
      this.$router.push("confirm");
    },
  },
  mounted() {
    this.getPendingAuthorizations();
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}
/*.dashboard-page{*/
/*display: flex;*/
/*justify-content: space-between;*/
/*flex-direction: column;*/
/*height: 100vh;*/
/*}*/
.navbar {
  padding: 0;
}
.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}
.quick-transfer {
  padding: 0 0.7rem;
}
.main_dashboard_inner3 {
  padding: 1.5rem 2rem 3rem 2rem;
}
.recent-transactions {
  max-height: 400px;
  overflow-y: scroll;
}
.continue-cib-btn {
  height: 39px;
  padding: 0;
}
.account_select {
  width: 100%;
}
.search-bar {
  background: #fecb32;
  border: 1px solid #fecb32;
  padding: 0 0.27rem;
  height: 39px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
table {
  text-align: center;
}
th {
  text-align: left;
  font-family: Montserrat-Medium;
  font-size: 12px;
  color: #000000;
  letter-spacing: 0.17px;
  /* background-image: linear-gradient(180deg, #00c4ff 0%, #0091ff 100%); */
  background: #fecb32;
}
td {
  font-family: Montserrat-Medium;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0;
}
tr:nth-child(odd) {
  background-color: #e5e5e5;
}
tr:nth-child(even) {
  background-color: #ffffff;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
/* .poster {
  background-color: rgb(0, 94, 194, 0.1) !important;
} */
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .account_select {
    width: 100%;
  }
}
</style>
