<template>
    <div>
            <ValidationProvider name="password" rules="required" v-slot="{ errors }">
                <div class="account_select2">
                    <input type="password" class="input-amount" placeholder=" "/>
                </div>
                <span style="font-size:13px; color:red" class=""> <span v-if="errors[0]"> <font-awesome-icon icon="ban" size="1x" class="ban-icon text-danger"/></span> {{ errors[0] }}</span> 
            </ValidationProvider> 
    </div>
</template>

<script>
    export default {
        name: "Validate.vue"
    }
</script>

<style scoped>

</style>