<template>
  <div class="hope-login-page">
    <div class="poster"></div>
    <Loader v-if="loading" :show-full="true" loading-text="Loading..." />
    <Loader v-if="loading1" :show-full="true" loading-text="Please wait..." />

    <div class="upper-nav">
      <div class="hope-logo">
        <a href="/">
          <img
            src="../../assets/images/logo/hope_bank_logo1.svg"
            alt="Hope Bank Logo"
            class="hope_logo"
          />
        </a>
      </div>
      <div class="back-logout" @click="$router.go(-1)">
        <!-- <button class="go-back-btn" @click="$router.go(-1)"> -->
        <div class="go-back-btn">
          <span>
            <font-awesome-icon icon="arrow-left" class="angle-left" />
          </span>
          Back
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-7 ml-auto mr-auto">
          <div class="enrollment-section">
            <p class="login-info">Agent Location</p>
            <!-- <div class="search-bar">
              <b-form-input
                @input="agentLocator()"
                v-model="BizState"
                type="text"
                placeholder="Search by Name"
              ></b-form-input>
              <span class="search-icon">
                <i class="fas fa-search"></i>
              </span>
            </div> -->
            <div class="label-title">
              <label class="text-left">State</label>
              <div class="account_select">
                <select v-model="BizState" name id class="account_type">
                  <option value="all">All</option>
                  <option
                    v-for="(items, i) in states"
                    :key="i"
                    required
                    :value="items"
                    class="choose_account"
                  >
                    {{ items.label }}
                  </option>
                </select>
                <font-awesome-icon icon="caret-down" class />
              </div>
            </div>
            <div class="label-title2">
              <label>Local Government Area</label>

              <div class="account_select">
                <select v-model="BizTown" name id class="account_type">
                  <option class="choose_account" value="all">All</option>
                  <option
                    :value="lga.label"
                    v-for="(lga, index) in BizState.lgas"
                    :key="index"
                  >
                    {{ lga.label }}
                  </option>
                </select>
                <font-awesome-icon icon="caret-down" class />
              </div>
            </div>

            <table>
              <tr>
                <th>Address</th>
                <th>State</th>
                <th>LGA</th>
                <th>Name</th>
              </tr>
              <tr v-for="(item, index) in paginated" :key="index">
                <td>{{ item.address }}</td>
                <td>{{ item.state }}</td>
                <td>{{ item.lga }}</td>
                <td>{{ item.name }}</td>
              </tr>
            </table>
            <div class="agentLocator">
              <button
                :disabled="current == 1"
                @click="prev"
                class="agent-location-btn-prev"
              >
                <font-awesome-icon
                  icon="angle-left"
                  class="download-icon"
                />Prev
              </button>
              <span class="download-btn2" aria-label="Goto page 1">{{
                current
              }}</span>
              <button
                :disabled="current >= pageCount - 1"
                @click="next()"
                class="agent-location-btn-next"
              >
                Next
                <font-awesome-icon icon="angle-right" class="download-icon2" />
              </button>
            </div>

            <!-- <ul>
                <li>Address: <span >Victoria Island</span></li>
                <li>State: <span>Victoria Island</span></li>
                <li>LGA: <span>Victoria Island</span></li>
                <li>Name: <span>Victoria Island</span></li>
              </ul> -->
          </div>
          <!-- <b-modal
            id="modal-center"
            centered
            title="ions "
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            hide-footer
            v-model="dateModal"
            ref="myModal"
          >
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <b-form-group
                :state="nameState"
                label-for="name-input"
                class="pin-info"
              >
                <h1 class="pin-info text-center">Transaction History</h1>
                <div class="current_tab_title">
                  <font-awesome-icon icon="play" class="play-icon" />
                  <p class>See all your Agent Locations</p>
                </div>
                <div class="kindly_enter_details">
                  <font-awesome-icon
                    icon="exclamation-circle"
                    class="exclamation-icon"
                  />
                  <p>Select the Date you want to fetch details from</p>
                </div>
                <div class="label-title">
                  <label class="text-left">Select Account</label>
                  <div class="account_select">
                    <select 
                    v-model="BizState"
                    name
                    id
                     class="account_type">
                      <option value="all">All</option>
                      <option
                        v-for="items in states"
                        :key="items + 'a'"
                        required
                        :value="items.label"
                        class="choose_account"
                      >
                        {{ items.label }}
                      </option>
                    </select>
                    <font-awesome-icon icon="caret-down" class />
                  </div>
                </div>
                <div class="send-or-download2">
                  <div @click="hideModal" class="download-btn2">
                    <font-awesome-icon
                      icon="angle-left"
                      class="download-icon"
                    />EXIT
                  </div>
                  <button @click.prevent="agentLocator" class="download-btn2">
                    SEARCH
                    <font-awesome-icon
                      icon="angle-right"
                      class="download-icon2"
                    />
                  </button>
                </div>
              </b-form-group>
            </form>
          </b-modal> -->
        </div>
      </div>
    </div>

    <Footer2 />
    <!-- <Footer/> -->
  </div>
</template>

<script>
import Loader from "../../components/Loader/Loader.vue";
import Footer2 from "../../components/Footer/Footer2";
import { mapState } from "vuex";
import { AgentLocatorService } from "../../services/agentLocator.services";
import { getStateService } from "../../services/getState.services.js";

export default {
  components: {
    Loader,
    Footer2,
  },

  data() {
    return {
      address: "",
      state: "",
      stateName: "",
      localGovt: "",
      localGovtName: "",
      name: "",
      loading: false,
      loading1: false,
      errors: [],
      search_data: [],
      showPasswordModal: false,
      outlets: [],
      states: [],
      BizState: "",
      BizTown: "",
      locateAgent: [],
      current: 1,
      pageSize: 50,
    };
  },

  computed: {
    ...mapState({
      AUTHENTICATION_TOKEN: (state) => state.User.AUTHENTICATION_TOKEN,
      TRANSFER_ACCOUNT: (state) => state.Transfer.TRANSFER_ACCOUNT,
      TRANSFER_ACCOUNT_FROM: (state) => state.Transfer.TRANSFER_ACCOUNT_FROM,
      TRANSFER_ACCOUNT_TO: (state) => state.Transfer.TRANSFER_ACCOUNT_TO,
      INSTITUTIONS: (state) => state.Transfer.INSTITUTIONS,
    }),
    indexStart() {
      return (this.current - 1) * this.pageSize;
    },
    indexEnd() {
      return this.indexStart + this.pageSize;
    },
    paginated() {
      return this.outlets.slice(this.indexStart, this.indexEnd);
    },
    // itemsPaginate() {
    //   let result = [];
    //   let init = (this.current - 1) * this.pageSize;
    //   for (let i = init; i < this.outlets.length; i++) {
    //     result.push(this.outlets[i]);
    //     if (result.length >= this.pageSize) {
    //       break;
    //     }
    //   }
    //   return result;
    // },
    pageCount() {
      let l = this.outlets.length,
        s = this.pageSize;
      return Math.ceil(l / s);
    },
  },
  watch: {
    BizTown: function () {
      if (this.BizTown === "all" || this.BizTown === "") {
        return (this.outlets = this.locateAgent);
      }
      let history = this.locateAgent.filter((trans) =>
        this.checkString(trans.lga, this.BizTown)
      );

      return (this.outlets = history);
    },
  },
  methods: {
    getAgentLocations() {
      AgentLocatorService.agentLocatorCenter()
        .then((res) => {
          this.outlets = res;
          this.locateAgent = res;
          window.console.log("this is the length response", res.length);
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    checkString(sentence, word) {
      var check = sentence.split(" ");
      return check.includes(word);
    },
    getState() {
      getStateService
        .getState()
        .then((res) => {
          this.loading = false;
          this.states = res;
        })
        .catch((err) => window.console.log(err));
    },
    hideModal() {
      this.$refs["myModal"].hide();
      // this.exitModal = false
    },
    prev() {
      this.current--;
    },
    next() {
      this.current++;
    },
  },
  mounted() {
    this.getAgentLocations();
    this.getState();
  },
  created() {},
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}
.go-back-btn:hover {
  /* color: #000000;
        background: #ffffff; */
}
.logout-btn2:hover {
  /* color: #ffffff;
        background: #000000; */
}
.modal-body {
  padding: 1rem !important;
}
.btns-biz-individual {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}
.btn-for-individual {
  font-family: Montserrat-SemiBold !important;
  font-size: 13px !important;
  color: #000000 !important;
  background: #fecb32 !important;
  border: 1px solid #fecb32 !important;
  border-radius: 5px;
  padding: 10px 1.5rem;
  letter-spacing: 0.17px;
  margin: 5px;
}
.btn-for-individual:hover {
  color: #000000 !important;
  background: #ffffff !important;
  border: 1px solid #fecb32 !important;
}
.btn-for-biz {
  font-family: Montserrat-SemiBold !important;
  font-size: 13px !important;
  color: #000000 !important;
  background: #fecb32 !important;
  border: 1px solid #fecb32 !important;
  border-radius: 5px;
  padding: 10px 1.5rem;
  letter-spacing: 0.17px;
  margin: 5px;
}
.btn-for-biz:hover {
  color: #000000 !important;
  background: #ffffff !important;
  border: 1px solid #fecb32 !important;
}
.enrollment-section {
  width: 100%;
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.22);
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.22);
  padding: 1.5rem;
}
.enrollment-section ul li {
  font-family: Montserrat-Regular;
  font-size: 14px;
  color: #000000;
  text-align: left;
  line-height: 1.7rem;
}
.agentLocator {
  display: flex;
  justify-content: center;
  margin: 15px;
  align-items: center;
}
</style>