<template>
  <div class="poster-bg-">
    <div class="poster"></div>
    <div class="dashboard-page">
      <Loader v-if="loading" :show-full="true" loading-text="Processing" />

      <div class>
        <HeaderNavRegistered />
        <div class="register_now">
          <div class="container">
            <div class="text-left">
              <h6 class="welcome_username">
                Hi, {{ user.accounts[0].accountName }}
              </h6>
            </div>
            <div class="hope_account_opening">
              <h1 class>Hope PSBank Account Opening</h1>
              <p>Our account opening process is digital.</p>
              <p>
                Whether you are an Individual or a Business concern seeking the
                New Normal in Banking,
                <br />We at Hope PS Bank will handhold you to a world of Ease,
                Fast and Safe digital experience.
              </p>
            </div>
            <div class="main_dashboard">
              <div class="row">
                <div class="col-md-12">
                  <div class="steps_to_acc_open">
                    <h2>Account Opening</h2>
                  </div>
                  <div class="main_dashboard_inner">
                    <div class="kindly_enter_details mb-3">
                      <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                      <p>
                        Open an account instantly by filling the form below.
                      </p>
                    </div>
                    <form @submit.prevent="BvnValidation">
                      <div class="form-table">
                        <div>
                          <!-- Select account type/category start -->
                          <div class="category">
                            <!-- <div class="steps_to_acc_open">
                              <h2>Select Category</h2>
                            </div>-->

                            <div class="label-title">
                              <label>Select Account Type</label>
                              <div class="account_select">
                                <select v-model="Code" name id class="account_type">
                                  <option value>Select</option>
                                  <option v-for="(
                                      account, key
                                    ) in PersonalAccountDataType.filter(
        (fund) => fund.code !== '105'
      )" :value="account" v-bind:key="key" class="choose_account">
                                    {{ account.name }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                          </div>

                          <!-- main input fields for NON BVN Accounts start except hopw wallet and hope 3rd party wallet (203 and 210) -->
                          <div class="no-bvn" v-if="Code">
                            <div class="input_fields" v-if="Code.code == '207'">
                              <!-- Non bvn accounts except third party & kool teen-->
                              <div v-if="Code.code == '203'">
                                <div class="label-title2">
                                  <label>
                                    First Name
                                    <span class="red-star">*</span>
                                  </label>
                                  <div class="account_select2">
                                    <input disabled v-model="FirstName" type="text" class="input-amount"
                                      placeholder=" " />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label>Other Names</label>
                                  <div class="account_select2">
                                    <input disabled v-model="MiddleName" type="text" class="input-amount"
                                      placeholder=" " />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label>
                                    Last Name
                                    <span class="red-star">*</span>
                                  </label>
                                  <div class="account_select2">
                                    <input disabled v-model="LastName" type="text" class="input-amount"
                                      placeholder=" " />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label>
                                    Phone Number
                                    <span class="red-star">*</span>
                                  </label>
                                  <div class="account_select2">
                                    <the-mask disabled mask="###########" type="tel" class="input-amount"
                                      v-model="Phone" placeholder=" " />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label> NIN </label>
                                  <div class="account_select2">
                                    <input v-model="nin" type="text" class="input-amount" placeholder=" " />
                                  </div>
                                </div>
                              </div>

                              <!-- Hello prepaid third party & kool teen only -->
                              <div v-if="Code.code == '210' || Code.code == '207'">
                                <div class="label-title2">
                                  <label>
                                    First Name
                                    <span class="red-star">*</span>
                                  </label>
                                  <div class="account_select2">
                                    <input v-model="Firstname" type="text" class="input-amount" placeholder=" " />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label>Other Names</label>
                                  <div class="account_select2">
                                    <input v-model="Middlename" type="text" class="input-amount" placeholder=" " />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label>
                                    Last Name
                                    <span class="red-star">*</span>
                                  </label>
                                  <div class="account_select2">
                                    <input v-model="Lastname" type="text" class="input-amount" placeholder=" " />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label>
                                    Phone Number
                                    <span class="red-star">*</span>
                                  </label>
                                  <div class="account_select2">
                                    <the-mask mask="###########" type="tel" class="input-amount" v-model="Phone_"
                                      placeholder=" " />
                                  </div>
                                </div>
                              </div>

                              <div class="label-title2">
                                <label> Email Address </label>
                                <div class="account_select2">
                                  <input v-model="Email" type="email" class="input-amount" placeholder=" " />
                                </div>
                              </div>
                              <!-- For Kool teens alone -->
                              <div class="label-title2" v-if="Code.code !== '207'">
                                <label>
                                  Date of Birth
                                  <span class="red-star">*</span>
                                </label>
                                <DatePicker v-model="DateOfBirth" :disabled-date="disabledVer" valueType="format"
                                  format="MM-DD-YYYY" placeholder="MM-DD-YYYY"></DatePicker>
                              </div>

                              <div class="label-title2" v-else>
                                <label>
                                  Date of Birth
                                  <span class="red-star">*</span>
                                </label>
                                <DatePicker v-model="DateOfBirth" :disabled-date="disabledVer" valueType="format"
                                  format="MM-DD-YYYY" placeholder="MM-DD-YYYY"></DatePicker>
                              </div>

                              <div class="label-title">
                                <label>
                                  Gender
                                  <span class="red-star">*</span>
                                </label>
                                <div class="account_select">
                                  <select v-model="Gender" class="account_type" required>
                                    <!-- <option class="choose_account">
                                      Select Gender
                                    </option> -->
                                    <option value="Male" class="choose_account">
                                      Male
                                    </option>
                                    <option value="Female" class="choose_account">
                                      Female
                                    </option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                                <!-- added Referer for koolTeen -->
                              </div>
                              <div class="non_nigerian_form">
                                <div class="label-title2">
                                  <div class="">
                                    <label>
                                      Referral Code or Phone No.
                                      <span class="optional_">[Optional]</span>
                                    </label>
                                    <p>Who introduced you to Hope?</p>
                                  </div>
                                  <div class="account_select2">
                                    <input type="text" maxlength="11" v-model="Referrer" class="input-amount"
                                      placeholder=" " />
                                  </div>
                                </div>
                              </div>
                              <!-- <b-button v-b-modal.modal-center-koolteens
                                >Launch centered modal</b-button
                              > -->

                              <!-- For Kool teens & 3rd party alone -->
                              <div v-if="Code.code !== '207' && Code.code !== '210'
        ">
                                <div class="label-title2">
                                  <label> NIN </label>
                                  <div class="account_select2">
                                    <input v-model="nin" type="text" class="input-amount" placeholder=" " />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <div>
                                    <small class="pl-3">
                                      You are about to create a
                                      <b>{{ Code.name }}</b>
                                    </small>
                                    <br />
                                    <small class="pl-3">with Opening Balance.</small>
                                    <label>
                                      Amount to Fund [Min Transfer ₦1000]
                                      <span class="red-star">*</span>
                                    </label>
                                  </div>

                                  <div class="account_select2">
                                    <!-- <vue-numeric
                                      class="input-amount"
                                      currency="₦"
                                      separator=","
                                      precision="2"
                                      v-model="Amount"
                                      required
                                    ></vue-numeric> -->
                                    <currency-input v-model="Amount" class="input-amount" currency="NGN" precision="2"
                                      required />
                                  </div>
                                </div>

                                <div class="label-title">
                                  <label>Account to debit</label>
                                  <div class="account_select">
                                    <select required v-model="transferAccount" name id class="account_type">
                                      <option v-for="item in user.accounts" :key="item + 'a'"
                                        :value="item.accountNumber">
                                        {{ item.accountName }} -
                                        {{ item.accountNumber }}
                                      </option>
                                    </select>
                                    <font-awesome-icon icon="caret-down" class />
                                  </div>
                                </div>
                                <!-- Who introduced you to Hope? (Optional) -->
                                <div class="non_nigerian_form">
                                  <div class="label-title2">
                                    <div class="">
                                      <label>
                                        Referral Code or Phone No.
                                        <span class="optional_">[Optional]</span>
                                      </label>
                                      <p>Who introduced you to Hope?</p>
                                    </div>
                                    <div class="account_select2">
                                      <input type="text" maxlength="11" v-model="Referrer" class="input-amount"
                                        placeholder=" " />
                                    </div>
                                  </div>
                                </div>
                                <!-- Who introduced you to Hope? (Optional) -->
                                <!-- <div class="label-title">
                                  <label>
                                    State
                                    <span class="red-star">*</span>
                                  </label>
                                  <div class="account_select">
                                    <select class="account_type" v-model="ResState">
                                      <option class="choose_account" value>Select State</option>
                                      <option
                                        :value="state"
                                        v-for="(state, index) in states"
                                        :key="index"
                                      >{{ state.label }}</option>
                                    </select>
                                    <font-awesome-icon icon="caret-down" class />
                                  </div>
                                </div>
                                <div class="label-title">
                                  <label>
                                    LGA
                                    <span class="red-star">*</span>
                                  </label>
                                  <div class="account_select">
                                    <select required class="account_type" v-model="ResTown">
                                      <option class="choose_account" value>Select Lga</option>
                                      <option
                                        :value="lga.value"
                                        v-for="(lga, index) in ResState.lgas"
                                        :key="index"
                                      >{{ lga.label }}</option>
                                    </select>
                                    <font-awesome-icon icon="caret-down" class />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label>
                                    Address
                                    <span class="red-star">*</span>
                                  </label>
                                  <div class="account_select2">
                                    <input
                                      type="text"
                                      class="input-amount"
                                      v-model="Address"
                                      placeholder=" "
                                      required
                                    />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label>
                                    Next of Kin Name
                                    <span class="red-star">*</span>
                                  </label>
                                  <div class="account_select2">
                                    <input
                                      type="text"
                                      class="input-amount"
                                      v-model="NextOfKin"
                                      placeholder=" "
                                      required
                                    />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label>
                                    Next of Kin Phone
                                    <span class="red-star">*</span>
                                  </label>
                                  <div class="account_select2">
                                    <input
                                      type="tel"
                                      class="input-amount"
                                      v-model="NextOfKinPhone"
                                      placeholder=" "
                                      maxlength="11"
                                      required
                                    />
                                  </div>
                                </div> -->
                              </div>

                              <!-- for Hope Wallet/prepaid, kool teen and third party -->
                              <div v-if="Code.code == 203 || Code.code == 210">
                                <div class="label-title2">
                                  <div>
                                    <small class="pl-3">
                                      You are about to create a
                                      <b>{{ Code.name }}</b>
                                    </small>
                                    <br />
                                    <small class="pl-3">with Opening Balance.</small>
                                    <label>
                                      Amount to Fund [Min Transfer ₦1000]
                                      <span class="red-star">*</span>
                                    </label>
                                  </div>

                                  <div class="account_select2">
                                    <currency-input v-model="Amount" class="input-amount" currency="NGN" precision="2"
                                      required />
                                    <!-- <vue-numeric
                                      class="input-amount"
                                      currency="₦"
                                      separator=","
                                      precision="2"
                                      v-model="Amount"
                                      required
                                    ></vue-numeric> -->
                                  </div>
                                </div>

                                <div class="label-title">
                                  <label>Account to debit</label>
                                  <div class="account_select">
                                    <select required v-model="transferAccount" name id class="account_type">
                                      <option v-for="item in user.accounts" :key="item + 'a'"
                                        :value="item.accountNumber">
                                        {{ item.accountName }} -
                                        {{ item.accountNumber }}
                                      </option>
                                    </select>
                                    <font-awesome-icon icon="caret-down" class />
                                  </div>
                                </div>
                                <!-- Who introduced you to Hope? (Optional) -->
                                <div class="non_nigerian_form">
                                  <div class="label-title2">
                                    <div class="">
                                      <label>
                                        Referral Code or Phone No.
                                        <span class="optional_">[Optional]</span>
                                      </label>
                                      <p>Who introduced you to Hope?</p>
                                    </div>
                                    <div class="account_select2">
                                      <input type="text" maxlength="11" v-model="Referrer" class="input-amount"
                                        placeholder=" " />
                                    </div>
                                  </div>
                                </div>
                                <!-- Who introduced you to Hope? (Optional) -->
                              </div>
                              <div>
                                <br />
                              </div>
                              <!--  <div class="term_condition_check">
                                <input
                                  type="checkbox"
                                  id="debit"
                                  v-model="debitCard"
                                  name="debit"
                                  value="Debit"
                                />
                                <label for="debit" class="terms-label-text">&nbsp;request for debit card </label>
                              </div> -->
                              <div class="term_condition_check">
                                <input type="checkbox" id="terms" v-model="terms" name="terms" value="Terms" />
                                <label for="terms">
                                  I accept the
                                  <b-button v-b-modal.modal-scrollable-terms class="terms_button">Terms and
                                    Conditions</b-button>
                                </label>
                              </div>
                              <terms />
                              <!-- submit -->
                              <div class="send-or-download2">
                                <div class="continue-button">
                                  <a @click="$router.go(-1)">
                                    <div class="continue-btn">
                                      <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                                    </div>
                                  </a>
                                </div>
                                <div class="continue-button">
                                  <!-- <b-button @click="next_page" v-if='Type == 109' class="continue-btn">Next<font-awesome-icon icon="angle-right" class="angle-icon"/></b-button> -->
                                  <b-button type="submit" :disabled="isDisabled" class="continue-btn">
                                    Submit
                                    <font-awesome-icon icon="angle-right" class="angle-icon" />
                                  </b-button>
                                  <!-- <router-link to="Verify">VERIFY</router-link> -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Main input field for non BVN ACCOUNTS end -->

                          <!-- Input field for Hope  Wallet (203)  start -->
                          <div class="no-bvn" v-if="Code">
                            <div class="input_fields" v-if="Code.code == '203'">
                              <div class="label-title2">
                                <label>
                                  First Name
                                  <span class="red-star">*</span>
                                </label>
                                <div class="account_select2">
                                  <input v-model="FirstName" type="text" class="input-amount" placeholder=" "
                                    required />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>Other Names</label>
                                <div class="account_select2">
                                  <input v-model="MiddleName" type="text" class="input-amount" placeholder=" " />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>
                                  Last Name
                                  <span class="red-star">*</span>
                                </label>
                                <div class="account_select2">
                                  <input v-model="LastName" type="text" class="input-amount" placeholder=" " required />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>
                                  Phone Number
                                  <span class="red-star">*</span>
                                </label>
                                <div class="account_select2">
                                  <the-mask mask="###########" type="tel" class="input-amount" v-model="Phone"
                                    placeholder=" " required />
                                </div>
                              </div>
                              <div class="label-title">
                                <label>
                                  Gender
                                  <span class="red-star">*</span>
                                </label>
                                <div class="account_select">
                                  <select v-model="Gender" class="account_type" required>
                                    <option class="choose_account">
                                      Select Gender
                                    </option>
                                    <option value="Male" class="choose_account">
                                      Male
                                    </option>
                                    <option value="Female" class="choose_account">
                                      Female
                                    </option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>
                              <!-- Who introduced you to Hope? (Optional) -->
                              <div class="non_nigerian_form">
                                <div class="label-title2">
                                  <div class="">
                                    <label>
                                      Referral Code or Phone No.
                                      <span class="optional_">[Optional]</span>
                                    </label>
                                    <p>Who introduced you to Hope?</p>
                                  </div>
                                  <div class="account_select2">
                                    <input type="text" maxlength="11" v-model="Referrer" class="input-amount"
                                      placeholder=" " />
                                  </div>
                                </div>
                              </div>
                              <!-- Who introduced you to Hope? (Optional) -->

                              <div>
                                <br />
                              </div>
                              <!--  <div class="term_condition_check">
                                <input
                                  type="checkbox"
                                  id="debit"
                                  v-model="debitCard"
                                  name="debit"
                                  value="Debit"
                                />
                                <label for="debit" class="terms-label-text">&nbsp;request for debit card </label>
                              </div> -->
                              <div class="term_condition_check">
                                <input type="checkbox" id="terms" v-model="terms" name="terms" value="Terms" />
                                <label for="terms" class="terms-label-text">
                                  I accept the
                                  <b-button v-b-modal.modal-scrollable-terms class="terms_button">Terms and
                                    Conditions</b-button>
                                </label>
                              </div>
                              <terms />

                              <!-- submit -->
                              <div class="send-or-download2">
                                <div class="continue-button">
                                  <a @click="$router.go(-1)">
                                    <div class="continue-btn">
                                      <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                                    </div>
                                  </a>
                                </div>
                                <div class="continue-button">
                                  <!-- <b-button @click="next_page" v-if='Type == 109' class="continue-btn">Next<font-awesome-icon icon="angle-right" class="angle-icon"/></b-button> -->
                                  <b-button type="submit" :disabled="isDisabled2" class="continue-btn ml-3">
                                    Submit
                                    <font-awesome-icon icon="angle-right" class="angle-icon" />
                                  </b-button>
                                  <!-- <router-link to="Verify">VERIFY</router-link> -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Input field for Hope Wallet (203) end -->

                          <!-- Input field for Hope Wallet 3rd party (210)  start -->
                          <div class="no-bvn" v-if="Code">
                            <div class="input_fields" v-if="Code.code == '210'">
                              <div class="label-title2">
                                <label>
                                  First Name
                                  <span class="red-star">*</span>
                                </label>
                                <div class="account_select2">
                                  <input v-model="FirstName" type="text" class="input-amount" placeholder=" "
                                    required />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>Other Names</label>
                                <div class="account_select2">
                                  <input v-model="MiddleName" type="text" class="input-amount" placeholder=" " />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>
                                  Last Name
                                  <span class="red-star">*</span>
                                </label>
                                <div class="account_select2">
                                  <input v-model="LastName" type="text" class="input-amount" placeholder=" " required />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>
                                  Phone Number
                                  <span class="red-star">*</span>
                                </label>
                                <div class="account_select2">
                                  <the-mask mask="###########" type="tel" class="input-amount" v-model="Phone"
                                    placeholder=" " required />
                                </div>
                              </div>

                              <div class="label-title2">
                                <div>
                                  <small class="pl-3">
                                    You are about to create a
                                    <b>{{ Code.name }}</b>
                                  </small>
                                  <br />
                                  <small class="pl-3">with Opening Balance.</small>
                                  <label>
                                    Amount to Fund [Min Transfer ₦1000]
                                    <span class="red-star">*</span>
                                  </label>
                                </div>

                                <div class="account_select2">
                                  <currency-input v-model="Amount" class="input-amount" currency="NGN" precision="2"
                                    required />
                                  <!-- <vue-numeric
                                    class="input-amount"
                                    currency="₦"
                                    separator=","
                                    precision="2"
                                    v-model="Amount"
                                    required
                                  ></vue-numeric> -->
                                </div>
                              </div>

                              <div class="label-title">
                                <label>Account to debit</label>
                                <div class="account_select">
                                  <select required v-model="transferAccount" name id class="account_type">
                                    <option v-for="item in user.accounts" :key="item + 'a'" :value="item.accountNumber">
                                      {{ item.accountName }} -
                                      {{ item.accountNumber }}
                                    </option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>
                              <!-- Who introduced you to Hope? (Optional) -->
                              <div class="non_nigerian_form">
                                <div class="label-title2">
                                  <div class="">
                                    <label>
                                      Referral Code or Phone No.
                                      <span class="optional_">[Optional]</span>
                                    </label>
                                    <p>Who introduced you to Hope?</p>
                                  </div>
                                  <div class="account_select2">
                                    <input type="text" maxlength="11" v-model="Referrer" class="input-amount"
                                      placeholder=" " />
                                  </div>
                                </div>
                              </div>
                              <!-- Who introduced you to Hope? (Optional) -->
                              <div>
                                <br />
                              </div>
                              <!--  <div class="term_condition_check">
                                <input
                                  type="checkbox"
                                  id="debit"
                                  v-model="debitCard"
                                  name="debit"
                                  value="Debit"
                                />
                                <label for="debit" class="terms-label-text">&nbsp;request for debit card </label>
                              </div> -->

                              <div class="term_condition_check">
                                <input type="checkbox" id="terms" v-model="terms" name="terms" value="Terms" />
                                <label for="terms" class="terms-label-text">
                                  I accept the
                                  <b-button v-b-modal.modal-scrollable-terms class="terms_button">
                                    Terms and Condition
                                  </b-button>
                                </label>
                              </div>
                              <terms />



                              <!-- submit -->
                              <div class="send-or-download2">
                                <div class="continue-button">
                                  <a @click="$router.go(-1)">
                                    <div class="continue-btn">
                                      <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                                    </div>
                                  </a>
                                </div>
                                <div class="continue-button">
                                  <!-- <b-button @click="next_page" v-if='Type == 109' class="continue-btn">Next<font-awesome-icon icon="angle-right" class="angle-icon"/></b-button> -->
                                  <b-button type="submit" :disabled="isDisabled2" class="continue-btn ml-3">
                                    Submit
                                    <font-awesome-icon icon="angle-right" class="angle-icon" />
                                  </b-button>
                                  <!-- <router-link to="Verify">VERIFY</router-link> -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Input field for Hope Wallet 3rd party (210) end -->

                          <!-- Input fields for BVN ACCOUNTS  start-->
                          <div class="bvn-accounts" v-if="Code">
                            <div class="input_fields" v-if="Code.code != '203' &&
        Code.code != '207' &&
        Code.code != '210' &&
        Code.code != '111'
        ">
                              <div class="label-title2">
                                <label>
                                  BVN
                                  <span class="red-star">*</span>
                                </label>
                                <div class="account_select2">
                                  <the-mask mask="###########" type="tel" class="input-amount" v-model="Bvn"
                                    placeholder="BVN" required />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>
                                  NIN (National Identification Number)
                                  <span class="red-star">*</span>
                                </label>
                                <div class="account_select2">
                                  <the-mask mask="###########" type="tel" class="input-amount" v-model="nin"
                                    placeholder="NIN" required />
                                </div>
                              </div>

                              <div class="label-title2">
                                <label>
                                  Phone Number
                                  <span class="red-star">*</span>
                                </label>
                                <div class="account_select2">
                                  <the-mask mask="###########" type="tel" class="input-amount" v-model="Phone"
                                    placeholder=" " required />
                                </div>
                              </div>

                              <div class="label-title2">
                                <label>
                                  Date of Birth
                                  <span class="red-star">*</span>
                                </label>
                                <DatePicker v-model="DateOfBirth" :disabled-date="disabledVer" valueType="format"
                                  format="MM-DD-YYYY" placeholder="MM-DD-YYYY"></DatePicker>
                              </div>

                              <div class="label-title2" v-if="Code.code == '103'">
                                <label>
                                  Date of Incorporation
                                  <span class="red-star">*</span>
                                </label>
                                <DatePicker v-model="DateOfInc" valueType="format" format="MM-DD-YYYY"
                                  placeholder="MM-DD-YYYY"></DatePicker>
                              </div>

                              <!-- <div class="term_condition_check">
                                <input
                                  type="checkbox"
                                  id="terms"
                                  name="terms"
                                  value="Terms"
                                  checked
                                />
                                <label for="terms">
                                  I accept the
                                  <router-link to="terms" class>Terms and Conditions</router-link>
                                </label>
                              </div>-->

                              <!-- submit -->
                              <div class="send-or-download2">
                                <div class="continue-button">
                                  <a @click="$router.go(-1)">
                                    <div class="continue-btn">
                                      <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                                    </div>
                                  </a>
                                </div>
                                <div class="continue-button">
                                  <b-button type="submit" :disabled="isDisabled1" class="continue-btn">
                                    Submit
                                    <font-awesome-icon icon="angle-right" class="angle-icon" />
                                  </b-button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Input fields for BVN ACCOUNTS  end-->
                          <!-- Input field for Hope Joint Account (111) alone -->

                          <div class="bvn-accounts" v-if="Code">
                            <div v-if="Code.code == '111'">
                              <div class="label-title">
                                <label> Select Number of Parties </label>
                                <div class="account_select">
                                  <select class="account_type" v-model="noOfParties">
                                    <option class="choose_account">
                                      Select
                                    </option>
                                    <option value="2" class="choose_account">
                                      Min. 2
                                    </option>
                                    <option value="3" class="choose_account">
                                      Max. 3
                                    </option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>

                              <div class="label-title2">
                                <label>
                                  Nominated Phone Number
                                  <span class="red-star">*</span>
                                </label>
                                <div class="account_select2">
                                  <the-mask mask="###########" type="tel" class="input-amount" v-model="Phone"
                                    placeholder=" " required />
                                </div>
                              </div>

                              <div class="label-title2">
                                <label>Account Name
                                  <span class="red-star">*</span></label>
                                <div class="account_select2">
                                  <input type="text" class="input-amount" v-model="BizName" placeholder=" " required />
                                </div>
                              </div>

                              <div class="label-title2">
                                <label>Referral Code</label>
                                <div class="account_select2">
                                  <input type="text" maxlength="11" v-model="Referrer" class="input-amount"
                                    placeholder=" " />
                                </div>
                              </div>

                              <div class="send-or-download2">
                                <div class="continue-button">
                                  <a @click="$router.go(-1)">
                                    <div class="continue-btn">
                                      <font-awesome-icon icon="angle-left" class="download-icon" />Back
                                    </div>
                                  </a>
                                </div>
                                <div class="continue-button">
                                  <button class="continue-btn" @click.prevent="continueJointAccount">
                                    Continue
                                    <font-awesome-icon icon="angle-right" class="angle-icon" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- submit dummy/hidden when real button shows -->
                      <div class="send-or-download2" v-if="!Code">
                        <div class="continue-button">
                          <a @click="$router.go(-1)">
                            <div class="continue-btn">
                              <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                            </div>
                          </a>
                        </div>
                        <div class="continue-button"></div>
                      </div>
                      <!-- submit dummy ends -->
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />

      <!-- otp modal for no bvn account -->
      <b-modal id="modal-center3" centered v-model="otp_modal" @show="resetModal" @hidden="resetModal" @ok="handleOk"
        hide-footer>
        <form ref="form" @submit.prevent="handleSubmit">
          <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <h1 class="pin-info text-center">Enter OTP sent to your Phone</h1>
            <div class="dialpad">
              <div class="form-group">
                <input type="password" class="form-control" v-model="otp" mask="######" maxlength="6" minlength="6"
                  placeholder="otp" />
              </div>
              <!-- keyboard for no bvn accounts -->
              <Keyboard v-if="Code.code == '203' ||
        Code.code == '204' ||
        Code.code == '207' ||
        Code.code == '210'
        " @pressed="otp = $event" :pinLength="otp" @completed="showPinModal" :selfValue="otp"></Keyboard>
              <!-- keyboard for bvn accounts -->
              <Keyboard v-else @pressed="otp = $event" :pinLength="otp" @completed="next_page" :selfValue="otp">
              </Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>

      <b-modal id="modal-center4" centered v-model="pin_modal" @show="resetModal" @hidden="resetModal" @ok="handleOk"
        hide-footer>
        <form ref="form" @submit.stop.prevent="CreateAccount">
          <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <h1 class="pin-info text-center">Enter Pin</h1>
            <div class="dialpad">
              <div class="form-group">
                <input @mousedown="showPinKeyboard1" type="password" class="form-control" v-model="pin" mask="########"
                  maxlength="8" minlength="6" placeholder required />
              </div>
              <!-- <div class="form-group">
                <input
                  @mousedown="showPinKeyboard"
                  type="password"
                  class="form-control"
                  v-model="pin"
                  mask="######"
                  maxlength="8"
                  minlength="6"
                  id="txtBox"
                  placeholder="Confirm PIN (6-8 Digits)"
                  required
                />
              </div>-->
              <!-- <div class="h6 mt-n3 mb-3 text-danger" v-if="isPinMatch">Pin does not match</div> -->
              <button class="ok-btn" type="submit">Create Account</button>
            </div>
          </b-form-group>
        </form>
      </b-modal>
      <b-modal id="modal-center3" centered title="Confirm Transactions" v-model="wallet_modal" @show="resetModal"
        @hidden="resetModal" @ok="handleOk" hide-footer>
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <p class="confirm-amount">
              You are about to open <b>{{ Code.name }}</b>
            </p>
            <!-- <div @click="transferOwn" class="btn btn-warning">Confirm</div> -->
            <!-- </p> -->
            <h1 class="pin-info text-center">Enter PIN</h1>
            <div class="dialpad">
              <div class="form-group">
                <the-mask type="password" class="form-control" v-model="pin" mask="########" maxlength="8" id="txtBox"
                  placeholder="******" />
              </div>
              <Keyboard @pressed="pin = $event" :pinLength="pin" @completed="CreateAccount" :selfValue="pin"></Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>

      <b-modal id="modal-center-createpin" centered v-model="pin_modal" @show="resetModal" @hidden="resetModal"
        @ok="handleOk" hide-footer>
        <form ref="form" @submit.stop.prevent="CreateAccount">
          <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <h1 class="pin-info text-center">
              Create PIN for Your New Account
            </h1>
            <div class="dialpad">
              <div class="form-group">
                <input @mousedown="showPinKeyboard1" type="password" class="form-control" v-model="pin1" mask="######"
                  maxlength="8" minlength="6" id="txtBox" placeholder="Enter PIN (6-8 Digits)" required />
              </div>
              <div class="form-group">
                <input @mousedown="showPinKeyboard" type="password" class="form-control" v-model="pin" mask="######"
                  maxlength="8" minlength="6" id="txtBox" placeholder="Confirm PIN (6-8 Digits)" required />
              </div>
              <div class="h6 mt-n3 mb-3 text-danger" v-if="isPinMatch">
                Pin does not match
              </div>
              <button class="ok-btn" :disabled="isDisabled_" type="submit">
                Create Account
              </button>
            </div>
          </b-form-group>
        </form>
      </b-modal>

      <b-modal id="modal-center-koolteens" centered v-model="koolTeenCreateModal" @show="resetModal"
        @hidden="resetModal" @ok="handleOk" hide-footer>
        <form ref="form" @submit.stop.prevent="CreateAccount">
          <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <h1 class="pin-info text-center">
              You are about to open <b>{{ Code.name }}</b> with a sum of:
            </h1>
            <div class="dialpad">
              <div class="label-title2">
                <!-- <div>
                  <small class="pl-3">
                    You are about to create a 
                    <b>{{ Code.name }}</b>
                  </small>
                </div> -->
                <label>Enter Amount</label>
                <div class="account_select2">
                  <!-- <vue-numeric
                    class="input-amount"
                    currency="₦"
                    separator=","
                    precision="2"
                    v-model="Amount"
                    required
                  ></vue-numeric> -->
                  <currency-input v-model="Amount" class="input-amount" currency="NGN" precision="2" required />
                </div>
              </div>

              <div class="label-title">
                <label>Account to debit</label>
                <div class="account_select">
                  <select required v-model="transferAccount" name id class="account_type">
                    <option v-for="item in user.accounts" :key="item + 'a'" :value="item.accountNumber">
                      {{ item.accountName }} -
                      {{ item.accountNumber }}
                    </option>
                  </select>
                  <font-awesome-icon icon="caret-down" class />
                </div>
              </div>
              <div class="label-title2">
                <label>Enter PIN</label>
                <div class="account_select2">
                  <input @mousedown="showPinKeyboard1" type="password" class="input-amount" v-model="pin"
                    mask="########" maxlength="8" minlength="6" placeholder required />
                </div>
              </div>

              <button class="ok-btn" type="submit">Create Account</button>
            </div>
          </b-form-group>
        </form>
      </b-modal>

      <b-modal id="modal-center-koolteens-success" centered @show="resetModal" @hidden="resetModal" @ok="handleOk"
        hide-footer>
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <img src="../../assets/images/icons/check.svg" alt="Hope Bank" class="modal_congrats_img" />
            <h1 class="pin-info text-center">Successful Transaction</h1>
            <div class="line4"></div>
            <p class="modal_congrats">
              <!-- {{responseData.status}}
              <br />-->
              You've successully opened {{ Code.name }} with the opening balance
              of
              <strong>{{ responseData.balance }}</strong>
            </p>
            <router-link to="Signup">
              <button class="ok-btn">OK</button>
            </router-link>
          </b-form-group>
        </form>
      </b-modal>
      <b-modal id="modal-center" centered v-model="has3rdParty_modal" @show="resetModal" @hidden="resetModal"
        @ok="handleOk" hide-footer ref="myModal">
        <form ref="form" @submit.stop.prevent="wallet_modal = true">
          <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <p class="modal_congrats2">
              This Phone Number ({{ this.Phone }}) already has a Hope Wallet. Do
              you wish to create another Hope Wallet for this number?
            </p>

            <div class="cancel_submit_buttons">
              <botton class="continue-btn mt-2" @click="hideModal">Cancel</botton>
              <button class="continue-btn mt-2">Proceed</button>
            </div>
          </b-form-group>
        </form>
      </b-modal>

      <b-modal id="modal-center" centered v-model="success_modal" @show="resetModal" @hidden="resetModal" @ok="handleOk"
        hide-footer>
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <img src="../../assets/images/icons/check.svg" alt="Hope Bank" class="modal_congrats_img" />
            <h1 class="pin-info text-center">Congratulations</h1>
            <div class="line4"></div>
            <p class="modal_congrats">
              <!-- {{responseData.status}}
              <br />-->
              Your {{ Code.name }} Number is
              <strong>{{ responseData.account }}</strong>
            </p>
            <p class="modal_congrats2">
              Transact with your Phone Number on ATM, POS and Web; access your
              account at Hope PS Bank Mobile App or Dial
              <b>*569*9#</b>
            </p>
            <router-link to="Signup">
              <button class="ok-btn">OK</button>
            </router-link>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import HeaderNavRegistered from "../../components/HeaderNav/HeaderNavRegistered";
import { TheMask } from "vue-the-mask";
import { getTitlesService } from "../../services/getTitles.services.js";
import Loader from "../../components/Loader/Loader.vue";
import { AccountTypeService } from "../../services/accountType.services.js";
import { CreateAccountService } from "../../services/createAccount.service.js";
import { BVNValidationService } from "../../services/bvnValidation.service.js";
import { CorporateAccountService } from "../../services/CorporateAccount/corporateAccount.services";
import Keyboard from "../../components/Keyboard/Keyboard.vue";
import { mapState, mapGetters } from "vuex";
import terms from "../../components/Terms/terms";
import Footer2 from "../../components/Footer/Footer2";
import { getStateService } from "../../services/getState.services.js";
import functions from "../../utils/functions";
import Axios from "axios";

// import VueNumeric from "vue-numeric";

export default {
  name: "Register_",
  components: {
    HeaderNavRegistered,
    TheMask,
    Loader: Loader,
    Keyboard,
    terms,
    // VueNumeric,
    Footer2,
  },
  data() {
    return {
      image: "",
      koolTeenCreateModal: false,
      sDisabled: true,
      isDisabled_: true,
      isDisabled1: false,
      isDisabled2: true,
      loading: false,
      Code: "",
      codeValue: "",
      pin: "",
      pin1: "",
      isPinMatch: false,
      pin_keyboard1: true,
      pin_keyboard: false,
      DateOfBirth: "",
      DateOfInc: "",
      AccountDataType: [],
      Amount_: "",
      otp: "",
      otp_modal: false,
      transferAccount: "",
      pin_modal: false,
      pin_modal2: false,
      has3rdParty_modal: false,
      submittedNames: [],
      nameState: null,
      category: "",
      country: null,
      titles: [],
      countries: [],
      states: [],
      Nationality: "",
      Address: "",
      Email: "",
      nin: "",
      Gender: "",
      ResState: "",
      ResTown: "",
      NextOfKin: "",
      NextOfKinPhone: "",
      Firstname: "",
      Lastname: "",
      Middlename: "",
      Phone_: "",
      wallet_modal: false,
      success_modal: false,
      responseData: {},
      terms: false,
      debitCard: false,
      ReferalCode: "",
      BizPhone: "",
      BizName: "",
      noOfParties: "",
      Referrer: "",
    };
  },
  computed: {
    NewAccountType() {
      return this.$store.getters.ACCOUNT;
    },
    ...mapGetters({ user: "GET_USER" }),
    ...mapState({
      ID: (state) => state.Transfer.ID,
      ACCOUNT_OTP: (state) => state.CreateAccount.ACCOUNT_OTP,
      ACCOUNT_TYPE: (state) => state.CreateAccount.ACCOUNT_TYPE,
      ACCOUNT_ID: (state) => state.CreateAccount.ACCOUNT_ID,
    }),

    PersonalAccountDataType() {
      // let account = this.AccountDataType;
      // account = account.filter((item) => item.group == 1);
      // return account.filter((item) => {
      //   return item.code != "210" && item.code != "204" && item.code != "207";
      // });
      let account = this.AccountDataType;
      return account.filter((item) => item.group == 1);
    },
    fullName() {
      let name = this.user.accounts[0].accountName;
      name = name.split(" ");
      if (name.length == 2) {
        name.splice(1, 0, "");
      }
      return name;
    },
    FirstName: {
      get() {
        if (this.Code.code == 210 || this.Code.code == 207) {
          return this.Firstname;
        }
        return this.fullName[0];
      },
      set(value) {
        if (this.Code.code == 210 || this.Code.code == 207) {
          return (this.Firstname = value);
        }
      },
    },
    MiddleName: {
      get() {
        if (this.Code.code == 210 || this.Code.code == 207) {
          return this.Middlename;
        }
        return this.fullName[1];
      },
      set(value) {
        if (this.Code.code == 210 || this.Code.code == 207) {
          return (this.Middlename = value);
        }
      },
    },
    LastName: {
      get() {
        if (this.Code.code == 210 || this.Code.code == 207) {
          return this.Lastname;
        }
        return this.fullName[2];
      },
      set(value) {
        if (this.Code.code == 210 || this.Code.code == 207) {
          return (this.Lastname = value);
        }
      },
    },
    Phone: {
      get() {
        if (this.Code.code == 210 || this.Code.code == 207) {
          return this.Phone_;
        }
        return this.user.phone;
      },
      set(value) {
        if (this.Code.code == 210 || this.Code.code == 207) {
          return (this.Phone_ = value);
        }
        return (this.user.phone = value);
      },
    },
    Bvn: {
      get() {
        return this.$store.state.User.BVN;
      },
      set(value) {
        this.$store.state.User.BVN = value;
      },
    },
    Amount: {
      get() {
        return this.Amount_;
      },
      set(value) {
        this.Amount_ = value;
        // this.Amount_ = this.AmountToFixed(value);
      },
    },
  },
  watch: {
    terms(newval) {
      if (
        newval &&
        this.DateOfBirth &&
        !this.checkVowel(this.FirstName) &&
        !this.checkConsonants(this.FirstName) &&
        !this.checkVowel(this.MiddleName) &&
        !this.checkConsonants(this.MiddleName) &&
        !this.checkVowel(this.LastName) &&
        !this.checkConsonants(this.LastName)
      ) {
        // this.$toastr.error("Invalid Name");
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
      if (
        newval &&
        !this.checkVowel(this.FirstName) &&
        !this.checkConsonants(this.FirstName) &&
        !this.checkVowel(this.MiddleName) &&
        !this.checkConsonants(this.MiddleName) &&
        !this.checkVowel(this.LastName) &&
        !this.checkConsonants(this.LastName)
      ) {
        // this.$toastr.error("Invalid Name");
        this.isDisabled2 = false;
      } else {
        this.isDisabled2 = true;
      }
    },
    category(newval) {
      if (newval == "existing") {
        this.$router.push("/Login?path=register");
      }
    },
    pin(newval) {
      if (newval.length >= 6) {
        if (newval == this.pin1) {
          this.isDisabled_ = false;
          this.isPinMatch = false;
          return;
        }
        this.isDisabled_ = true;
        this.isPinMatch = true;
      }
    },
    DateOfBirth(newval) {
      this.checkDateValidity(newval);
      if (newval && this.terms) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
      if (newval && this.Bvn && this.Phone && this.nin) {
        this.isDisabled1 = false;
      } else {
        this.isDisabled1 = true;
      }
    },
  },

  methods: {
    hideModal() {
      this.$refs["myModal"].hide();
    },
    sendEmail(x) {
      Axios.post(`https://hopemail.tm-dev.xyz/service/send-mail/`, {
        name: `${this.FirstName} ${this.LastName}`,
        phone_number: this.Phone,
        account_no: x,
        email_from: this.user.email
      })
        .then((res) => {
          window.console.log(res)
        })
        .catch((err) => {
          window.console.log(err)
        })
    },
    disabledVer(date) {
      let tdate = new Date();
      let year = tdate.getFullYear();
      let month = tdate.getMonth();
      let day = tdate.getDate();
      const today = new Date(year - 18, month, day);
      today.setHours(0, 0, 0, 0);

      return date > today
    },
    //Check Date Validity
    checkDateValidity(date) {
      if (this.Code.code != 207) {
        let tdate = new Date();
        let year = tdate.getFullYear();
        let month = tdate.getMonth();
        let day = tdate.getDate();
        let newYear = new Date(year - 18, month, day);
        let dob = new Date(date);

        if (dob > newYear) {
          this.DateOfBirth = "";
          return this.$toastr.error("Must be 18 years or older", "Error");
        }
      } else {
        let tdate = new Date();
        let year = tdate.getFullYear();
        let month = tdate.getMonth();
        let day = tdate.getDate();
        let newYear = new Date(year - 18, month, day);
        let newYear_ = new Date(year - 7, month, day);
        let dob = new Date(date);

        if (dob < newYear || dob > newYear_) {
          this.DateOfBirth = "";
          return this.$toastr.error(
            "Must be between 7 and 17 years old",
            "Error"
          );
        }
      }
    },
    async continueJointAccount() {
      this.loading = true;
      await CorporateAccountService.validateJoinAccount({
        code: this.Code.code,
        BizPhone: this.Phone,
        BizName: this.BizName,
        ReferalCode: this.ReferalCode,
      })
        .then((res) => {
          this.loading = false;
          this.$store.commit("SET_CORPORATE_COMPANY_ID", res.id);
          this.$store.commit("SET_PARTIES_NO", parseInt(this.noOfParties));
          this.$store.commit("SET_ACCOUNT_TYPE", this.Code);
          //uncomment when going to production
          this.$router.push("/jointaccount");
        })
        .catch((error) => {
          this.loading = false;
          this.$toastr.error(error.status, "Validation Failed!", {
            timeOut: 5000,
          });
        });
    },
    //For Bvn
    async BvnValidation() {
      this.loading = true;
      // window.console.log("====", this.Type);
      if (this.Code.code === "207") {
        //uncomment when going to production
        this.koolTeenCreateModal = true;
      }
      let payload;
      if (this.Code.code === "210" || this.Code.code === "207") {
        payload = {
          Code: this.Code.code,
          Phone: this.Phone,
          DateOfBirth: this.DateOfBirth,
          DateOfInc: this.DateOfInc,
        };
      } else {
        payload = {
          Code: this.Code.code,
          Bvn: this.$store.state.User.BVN,
          nin: this.nin,
          Phone: this.Phone,
          DateOfBirth: this.DateOfBirth,
          DateOfInc: this.DateOfInc,
        };
      }
      await BVNValidationService.validationServiceWhenLoggedIn(payload)
        .then((res) => {
          // window.console.log("hasThirdParty", res);
          if (this.Code.code == "210" && res.has3rdParty == true) {
            this.has3rdParty_modal = true;
            this.$store.commit("SET_ACCOUNT_ID", res.id);
          } else if (this.Code.code == "210" && res.has3rdParty == false) {
            this.wallet_modal = true;
            this.$store.commit("SET_ACCOUNT_ID", res.id);
          } else if (this.Code.code == "203") {
            this.pin_modal = true;
            this.$store.commit("SET_ACCOUNT_ID", res.id);
          } else if (this.Code.code == "207") {
            this.$store.commit("SET_ACCOUNT_ID", res.id);
            this.$store.commit("SET_BVN_DETAILS", res);
            //Added 204 implementation
          } else {
            this.$store.commit("SET_ACCOUNT_ID", res.id);
            this.$store.commit("SET_BVN_DETAILS", res);
            this.$store.commit("SET_ACCOUNT_CATEGORY", this.Code);
            this.codeValue = this.Code.code;
            this.$store.commit("SET_REGISTRATION_DETAILS", {
              DateOfBirth: this.DateOfBirth,
              phoneNumber: this.Phone,
            });
            //this.$router.push("/verify_");
            this.$router.push({
              name: "verify_",
              params: { data: this.codeValue },
            });
          }
        })
        .catch((error) => {
          this.$toastr.error(error.status, "Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAccountType(e) {
      this.$store.dispatch("LOAD_ACCOUNT_TYPE", e.target.value);
      window.console.log(e.target.value);
    },
    //get States
    GetStates() {
      getStateService
        .getStateWhenLoggedIn()
        .then((res) => {
          this.states = res;
          //window.console.log("=====", res);
        })
        .catch((err) => window.console.log(err));
    },
    getTitle() {
      getTitlesService
        .getTitle()
        .then((res) => {
          this.loading = false;
          this.titles = res;
        })
        .catch((err) => window.console.log(err));
    },
    AmountToFixed(x) {
      return functions.AmountToFixed(x);
    },
    checkVowel(x) {
      return functions.checkVowel(x);
    },
    checkConsonants(x) {
      return functions.checkConsonants(x);
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid ? "valid" : "invalid";
      return valid;
    },
    handleOk(bvModalEvt) {
      window.console.log(bvModalEvt);
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs.modal.hide();
      });
    },
    next_page() {
      this.$store.commit("SET_ACCOUNT_OTP", this.otp);
      this.$store.commit("SET_ACCOUNT_TYPE", this.Code);
      this.$router.push("/Verify_");
    },
    showPinKeyboard() {
      this.pin_keyboard1 = false;
      this.pin_keyboard = true;
    },
    showPinKeyboard1() {
      this.pin_keyboard1 = true;
      this.pin_keyboard = false;
    },
    showPinModal() {
      this.otp_modal = false;
      this.pin_modal = true;
    },

    //get Account types
    async GetAccountTypes() {
      await AccountTypeService.accountTypeServiceWhenLoggedIn()
        .then((res) => {
          this.loading = false;
          this.AccountDataType = res;
          // window.console.log("=====", res);
        })
        .catch((err) => window.console.log(err));
    },

    //create account
    async CreateAccount() {
      this.loading = true;
      await CreateAccountService.createServiceWhenLoggedIn({
        Id: this.ACCOUNT_ID,
        OTP: this.otp,
        Address: this.Address,
        Nationality: this.Nationality,
        Email: this.Email,
        Code: this.Code.code,
        Product: this.Code.code,
        DateOfBirth: this.DateOfBirth,
        Account: this.transferAccount,
        Amount: parseFloat(this.Amount),
        nin: this.nin,
        FirstName: this.FirstName,
        LastName: this.LastName,
        MiddleName: this.MiddleName,
        NkName: this.NextOfKin,
        NkPhone: this.NextOfKinPhone,
        ResState: this.ResState.value,
        ResTown: this.ResTown,
        Gender: this.Gender,
        Pin: this.pin,
        Referrer: this.Referrer,
      })
        .then((res) => {
          window.console.log(res);
          this.responseData = res;
          this.pin_modal = false;
          this.success_modal = true;
          this.wallet_modal = false;
          this.has3rdParty_modal = false;
          if (this.responseData) {
            if (this.debitCard) {
              this.sendEmail(this.responseData.account)
              window.console.log(this.responseData.account)
            }
          } else {
            return
          }
          //this.$toastr.success(res.status, "Account Successfully created", {timeOut: 5000});
        })
        .catch((error) => {
          this.$toastr.error(error.status, "Account Creation Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.GetAccountTypes();
    this.GetStates();
    this.$store.dispatch("CLEAR_CORPORATE_DATA");
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}

.navbar {
  padding: 0;
}

.quick-transfer {
  padding: 0 2.2rem;
}

.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}

.modal-header {
  padding: 1rem 1rem 0 !important;
}

.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}

.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}

.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
}

.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
}

.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}

.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}

.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}

.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}

.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #ffffff !important;
}

.yellow_bg>.navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}

.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}

.cancel_submit_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_dashboard {
  width: 75%;
  margin: 0 auto;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .main_dashboard {
    width: 100% !important;
  }
}
</style>
