
const mutations = {
    SET_TRANSFER_ACCOUNT (state, payload) {
        state.TRANSFER_ACCOUNT = payload
    },
    SET_TRANSFER_ACCOUNT_FROM (state, payload) {
        state.TRANSFER_ACCOUNT_FROM = payload
    },
    SET_TRANSFER_ACCOUNT_TO (state, payload) {
        state.TRANSFER_ACCOUNT_TO = payload
    },
    SET_BENEFICIARY_ACCOUNT (state, payload) {
        state.BENEFICIARY_ACCOUNT = payload
    },
    SET_BENEFICIARY_NAME (state, payload) {
        state.BENEFICIARY_NAME = payload
    },
    SET_BENEFICIARY_BANK (state, payload) {
        state.BENEFICIARY_BANK = payload
    },
    SET_TRANSFER_AMOUNT (state, payload) {
        state.TRANSFER_AMOUNT = payload
    },
    SET_TRANSFER_TYPE (state, payload) {
        state.TRANSFER_TYPE = payload
    },
    SET_SAVE_BENEFICIARY (state, payload) {
        state.SAVE_BENEFICIARY = payload
    },
    SET_TRANSFER_NOTE (state, payload) {
        state.TRANSFER_NOTE = payload
    },
    SET_INSTITUTIONS (state, payload) {
        state.INSTITUTIONS = payload
    },
    SET_SCHEDULE_NAME (state, payload){
        state.SCHEDULE_NAME = payload
    },
    SET_FREQUENCY (state,payload){
        state.FREQUENCY = payload
    },
    SET_START (state, payload){
        state.START = payload
    },

    SET_END (state, payload){
        state.END = payload
    },
    SET_CURRENT_SCHEDULE (state, payload){
        state.CURRENT_SCHEDULE = payload
    }

}

export {
    mutations
};