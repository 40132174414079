<template>
  <div class>
    <div class="poster"></div>
    <div class="dashboard-page">
      <Loader v-if="loading" :show-full="true" loading-text="Processing" />

      <HeaderNavBiz />
      <div class="container">
        <BusinessNavBar />
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <img
                    src="../../../../assets/images/icons/bill-sm.svg"
                    alt="Hope Bank"
                  />
                  <p class="frequently-used">Bills Payment</p>
                </div>
                <div class="current_tab_title mb-3">
                  <font-awesome-icon icon="play" class="play-icon" />
                  <p class>Bills Payment</p>
                </div>
                <div class="kindly_enter_details mb-3">
                  <font-awesome-icon
                    icon="exclamation-circle"
                    class="exclamation-icon"
                  />
                  <p>Select details to complete this process</p>
                </div>

                <form @submit.prevent="checkForm">
                  <div class="form-table">
                    <div v-if="errors.length">
                      <b class="text-danger"
                        >Please correct the following error(s):</b
                      >
                      <ul>
                        <p
                          class="text-danger"
                          v-for="(error, key) in errors"
                          :key="key"
                        >
                          {{ error }}
                        </p>
                      </ul>
                    </div>
                    <div class="label-title">
                      <label>Account to Debit</label>
                      <div class="account_select">
                        <select v-model="Account" name id class="account_type">
                          <option
                            v-for="(account, key) in user.corporateAccounts"
                            :key="key"
                            :value="account.accountNumber"
                            class="choose_account"
                          >
                            {{ account.accountType }}
                            {{ account.accountNumber }}
                          </option>
                        </select>
                        <font-awesome-icon icon="caret-down" class />
                      </div>
                    </div>
                    <div class="label-title">
                      <label>Select Category</label>
                      <div class="account_select">
                        <select
                          v-model="CategoryId"
                          name
                          id
                          class="account_type"
                        >
                          <option value class="choose_account">
                            -- Select Category --
                          </option>
                          <option
                            v-for="(category, key) in Category"
                            :value="category.id"
                            :key="key"
                            class="choose_account"
                          >
                            {{ category.name }}
                          </option>
                        </select>
                        <font-awesome-icon icon="caret-down" class />
                      </div>
                    </div>
                    <div class="label-title2">
                      <label>Select Biller/Merchant</label>
                      <div class="account_select">
                        <select
                          v-model="MerchantId"
                          name="MerchantId"
                          id
                          class="account_type"
                        >
                          <option value class="choose_account">
                            -- Select Merchant --
                          </option>
                          <option
                            v-for="(merchant, key) in Merchants.merchants"
                            :value="merchant.name"
                            :key="key"
                            class="choose_account"
                          >
                            {{ merchant.name }}
                          </option>
                        </select>
                        <font-awesome-icon icon="caret-down" class />
                      </div>
                    </div>

                    <div class="label-title2">
                      <label>Select Product</label>
                      <div class="account_select">
                        <select
                          v-model="ProductId"
                          name="ProductId"
                          id
                          class="account_type"
                        >
                          <option value class="choose_account">
                            -- Select Product --
                          </option>
                          <option
                            v-for="(product, key) in Products"
                            :value="product.id"
                            :key="key"
                            class="choose_account"
                          >
                            {{ product.name }}
                          </option>
                        </select>
                        <font-awesome-icon icon="caret-down" class />
                      </div>
                    </div>

                    <div
                      class="label-title2"
                      v-for="(parameter, index) in Parameters"
                      :key="index"
                    >
                      <label>{{ parameter.label }}</label>
                      <div class="account_select2">
                        <input
                          class="input-amount"
                          name="label"
                          v-model="paramValue.value[index]"
                          id="label"
                        />
                      </div>
                    </div>

                    <div class="label-title2" v-if="ParameterLabel">
                      <label>{{ ParameterLabel }}</label>
                      <div class="account_select2">
                        <input
                          class="input-amount"
                          name="label"
                          v-model="Beneficiary"
                          id="label"
                        />
                      </div>
                    </div>

                    <div class="label-title2">
                      <label>Amount</label>
                      <div class="account_select2">
                        <!-- <vue-numeric
                          class="input-amount"
                          name="Amount"
                          id="Amount"
                          currency="N"
                          separator=","
                          v-model="Amount" -->
                        <currency-input
                          v-model="Amount"
                          class="input-amount"
                          currency="NGN"
                          precision="2"
                          required
                        />

                        <!-- </vue-numeric> -->
                      </div>
                    </div>

                    <div class="send-or-download2">
                      <div class="continue-button">
                        <a @click="$router.go(-1)">
                          <div class="continue-cib-btn">
                            <font-awesome-icon
                              icon="angle-left"
                              class="download-icon"
                            />BACK
                          </div>
                        </a>
                      </div>
                      <div class="continue-button">
                        <button type="submit" class="continue-cib-btn">
                          NEXT
                          <font-awesome-icon
                            icon="angle-right"
                            class="angle-icon"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar
                :accountNumber="Account"
                :accountBalance="Balance"
                :accountName="Name"
              ></SideBar>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>
      <b-modal
        id="modal-center"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
        v-model="otp_modal"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <h1 class="pin-info text-center">
              Insert PIN to complete
              <br />transaction
            </h1>
            <div class="dialpad">
              <div class="form-group">
                <the-mask
                  type="password"
                  class="form-control"
                  v-model="pin"
                  mask="########"
                  id="txtBox"
                  placeholder="******"
                />
              </div>
              <Keyboard
                @pressed="pin = $event"
                :pinLength="pin"
                @completed="save_otp"
                :selfValue="pin"
              ></Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import HeaderNavBiz from "../../../../components/HeaderNav/HeaderNavBiz";
import BusinessNavBar from "../../../../components/HeaderNav/BusinessNavBar";
import SideBar from "../../../../components/SideBar/SideBar_";
import { mapGetters, mapState } from "vuex";
import { vasBillPaymentService } from "../../../../services/vasBillPayment.services.js";
import { VasPaymentService } from "../../../../services/VasPayment.services.js";
import { CorporateAccountService } from "../../../../services/CorporateAccount/corporateAccountTransaction.services.js";
// import VueNumeric from "vue-numeric";
import Loader from "../../../../components/Loader/Loader.vue";
import Keyboard from "../../../../components/Keyboard/Keyboard.vue";
import Footer3 from "../../../../components/Footer/Footer3";

export default {
  name: "BillPayment.vue",
  components: {
    HeaderNavBiz,
    BusinessNavBar,
    SideBar,
    // VueNumeric,
    Loader,
    Keyboard,
    Footer3,
  },
  data() {
    return {
      // active: false,
      Account: this.$store.getters.GET_USER.corporateAccounts[0].accountNumber,
      Amount: "",
      Beneficiary: "",
      pin: "",
      loading: false,
      errors: [],
      Category: [],
      CategoryId: "",
      MerchantId: "",
      ProductId: "",
      Merchants: {},
      Products: [],
      Parameters: [],
      ParameterLabel: "",
      ParameterLabelValue: "",
      paramValue: {
        value: [],
      },
      otp_modal: false,
    };
  },

  watch: {
    CategoryId(newval) {
      this.getVasCategoryId(newval);
      this.Parameters = "";
      this.ParameterLabel = "";
      this.Products = "";
      this.Amount = "";
      this.Beneficiary = "";
    },
    MerchantId(newval) {
      let merchant = this.Merchants.merchants.filter((o) => o.name == newval);
      this.Products = merchant[0].products;
    },
    ProductId(newval) {
      let product = this.Products.filter((o) => o.id == newval);
      this.Parameters = product[0].parameters;
      this.ParameterLabel = product[0].parameterLabel;
      this.Amount = product[0].price;
    },
  },
  computed: {
    ...mapGetters({ user: "GET_USER" }),

    ...mapState({
      AUTHENTICATION_TOKEN: (state) => state.User.AUTHENTICATION_TOKEN,
    }),
    Balance() {
      return this.user.corporateAccounts[
        this.user.corporateAccounts.findIndex(
          (a) => a.accountNumber == this.Account
        )
      ].balance;
    },
    Name() {
      return this.user.corporateAccounts[
        this.user.corporateAccounts.findIndex(
          (a) => a.accountNumber == this.Account
        )
      ].accountName;
    },
  },

  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },
    checkForm: function (e) {
      e.preventDefault();
      this.errors = [];
      if (this.Account === "") {
        this.errors.push("Select Account Number");
      } else if (this.ProductId === "") {
        this.errors.push("Select biller");
      } else if (this.Amount === "") {
        this.errors.push("Amount  is required");
      } else {
        this.ValidateBillVasPayment();
      }
    },

    async ValidateBillVasPayment() {
      this.loading = true;
      const data = {
        Beneficiary: this.Beneficiary,
        ProductId: this.ProductId,
        Amount: this.Amount,
        token: this.AUTHENTICATION_TOKEN,
      };
      await VasPaymentService.validateVaspaymentService(data)
        .then((res) => {
          window.console.log(res);
          // this.$toastr.success(res.status || "Validation Successful", {timeOut: 5000});
          this.otp_modal = true;
        })
        .catch((error) => {
          this.$toastr.error(error.status, "Validation Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async save_otp() {
      this.loading = true;
      const data = {
        Account: this.Account,
        ProductId: this.ProductId,
        Amount: this.Amount,
        Pin: this.pin,
        token: this.AUTHENTICATION_TOKEN,
        Beneficiary: this.Beneficiary,
        //Labels :
      };
      await CorporateAccountService.paymentService(data)
        .then((res) => {
          window.console.log(res);
          this.pin = "";
          this.Amount = "";
          this.Account = "";
          this.ProductId = "";
          this.Beneficiary = "";
          this.$toastr.success(res.status, "Payment Successful", {
            timeOut: 5000,
          });
        })
        .catch((error) => {
          this.$toastr.error(error.status, "Payment Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.otp_modal = false;
          this.loading = false;
          this.pin = "";
        });
    },

    async getVasCategory() {
      this.loading = true;
      await vasBillPaymentService
        .vasCategoryMain()
        .then((res) => {
          this.Category = res;
          // this.$toastr.success(res.status, "Successfully fetched", {timeOut: 5000});
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Could not fetch vas Category", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getVasCategoryId(id) {
      this.loading = true;
      await vasBillPaymentService
        .vasCategory(id)
        .then((res) => {
          this.Merchants = res;
          // this.$toastr.success(res.status, "Successfully fetched", {timeOut: 5000});
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Could not fetch vas Category", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getVasCategory();
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000 !important;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.navbar {
  padding: 0;
}
.quick-transfer {
  padding: 0 0.7rem;
}
.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}
.footer-info {
  padding-top: 2.5rem;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
/* .poster {
  background-color: rgb(0, 94, 194, 0.1) !important;
}
.current_tab_title.current_tab_title {
  background-image: linear-gradient(
    180deg,
    #00c4ff 0%,
    #0091ff 100%
  ) !important;
} */
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
