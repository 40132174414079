<template>
  <div class="poster-bg-">
    <div class="poster"></div>
    <div class="dashboard-page">
      <Loader v-if="loading" :show-full="true" loading-text="Processing" />

      <div class>
        <HeaderNavRegistered />
        <div class="register_now">
          <div class="container">
            <div class="text-left">
              <h6 class="welcome_username" v-if="user.accounts[0]">
                Hi, {{ user.accounts[0].accountName }}
              </h6>
              <h6 v-else>Hi, User</h6>
              <!-- <h6 class="welcome_username">Hi, {{ user.accounts[0].accountName }}</h6> -->
            </div>
            <div class="hope_account_opening">
              <h1 class>Hope PSBank Account Opening</h1>
              <p>Our account opening process is digital.</p>
              <p>
                Whether you are an Individual or a Business concern seeking the
                New Normal in Banking,
                <br />We at Hope PS Bank will handhold you to a world of Ease,
                Fast and Safe digital experience.
              </p>
            </div>
            <div class="main_dashboard">
              <div class="row">
                <div class="col-md-12">
                  <div class="steps_to_acc_open">
                    <h2>Business Account Opening</h2>
                  </div>
                  <div class="main_dashboard_inner">
                    <div class="kindly_enter_details mb-3">
                      <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                      <p>
                        Open an account instantly by filling the form below.
                      </p>
                    </div>
                    <form @submit.prevent="ValidateCorporate">
                      <div class="form-table">
                        <!-- Select Customer Category -->
                        <!-- <div class="label-title">
                          <label>Customer Category</label>
                          <div class="account_select">
                            <select class="account_type" v-model="category">
                              <option value class="choose_account">--select--</option>
                              <option value="new" class="choose_account">New Business Customer</option>
                              <option value="existing" class="choose_account">
                                <router-link to="Login" target="_blank">Existing Business Customer</router-link>
                              </option>
                            </select>
                            <font-awesome-icon icon="caret-down" class />
                          </div>
                        </div>-->

                        <!-- Select Product Catrgory -->
                        <div>
                          <div class="label-title">
                            <label>Select Product</label>
                            <div class="account_select">
                              <select v-model="Code" required class="account_type">
                                <option value>Select Account Product</option>
                                <option v-for="(
                                    account, key
                                  ) in CorporateAccountDataType" :value="account" v-bind:key="key"
                                  class="choose_account">
                                  {{ account.name }}
                                </option>
                              </select>
                              <font-awesome-icon icon="caret-down" class />
                            </div>
                          </div>
                        </div>

                        <!-- Select Number of Directors -->
                        <div class="category">
                          <div class="label-title">
                            <label>Enter Signatories</label>
                            <div class="account_select">
                              <select v-model="existing" required class="account_type">
                                <option value class="choose_account">
                                  --select--
                                </option>
                                <option value="existing" class="choose_account">
                                  Existing Signatories
                                </option>
                                <option value="new" class="choose_account">
                                  <router-link to="/signatories" target="_blank">New Signatories</router-link>
                                </option>
                              </select>
                              <font-awesome-icon icon="caret-down" class />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="label-title2" v-if="existing == 'existing'">
                            <label>Business Name
                              <span class="red-star">*</span></label>
                            <div class="account_select2">
                              <input v-model="BizName" type="text" required class="input-amount" placeholder=" " />
                            </div>
                          </div>
                        </div>
                        <div class="label-title" v-if="existing == 'existing'">
                          <label class="text-left">Select Account</label>
                          <div class="account_select">
                            <select @change="onChange($event)" v-model="corporate" name id class="account_type">
                              <option v-for="item in user.corporateAccounts" :key="item + 'a'" :value="item">
                                {{ item.accountNumber }} -
                                {{ item.accountName }} -
                                {{ item.accountType }}
                              </option>
                            </select>
                            <font-awesome-icon icon="caret-down" class />
                          </div>
                        </div>

                        <!-- I commented this part -->
                        <!-- Select Number of Partnership -->
                        <!-- <div
                          class="category"
                          v-if="Code && Code.code === '107'"
                        >
                          <div class="label-title">
                            <label>Enter Number of Partners</label>
                            <div class="account_select">
                              <select
                                v-model="DirectorNumber"
                                required
                                class="account_type"
                              >
                                <option value>Select Number of Partners</option>
                                <option
                                  v-for="(one, index) in 29"
                                  :key="one"
                                  :value="index"
                                  class="choose_account"
                                >
                                  {{ index + 2 }}
                                </option>
                                >
                              </select>
                              <font-awesome-icon icon="caret-down" class />
                            </div>
                          </div>
                        </div> -->
                        <!-- Select Number of Partnership -->

                        <!-- Select Number of Directors -->
                        <!-- <div
                          class="category"
                          v-if="
                            Code &&
                            Code.code !== '107' &&
                            Code.code !== '106' &&
                            Code.code !== '108' &&
                            Code.code !== '109'
                          "
                        >
                          <div class="label-title">
                            <label>Enter Number of Directors</label>
                            <div class="account_select">
                              <select
                                v-model="DirectorNumber"
                                required
                                class="account_type"
                              >
                                <option value>
                                  Select Number of Directors
                                </option>
                                <option
                                  v-for="(one, index) in 29"
                                  :key="one"
                                  :value="index"
                                  class="choose_account"
                                >
                                  {{ index + 2 }}
                                </option>
                                >
                              </select>
                              <font-awesome-icon icon="caret-down" class />
                            </div>
                          </div>
                        </div> -->
                        <!-- Select Number of Partnership -->

                        <!-- Select Number of Trustees/Registered Club -->
                        <!-- <div
                          class="category"
                          v-if="Code && Code.code === '108'"
                        >
                          <div class="label-title">
                            <label>Enter Number of Trustees</label>
                            <div class="account_select">
                              <select
                                v-model="DirectorNumber"
                                required
                                class="account_type"
                              >
                                <option value>Select Number of Trustees</option>
                                <option
                                  v-for="(one, index) in 29"
                                  :key="one"
                                  :value="index"
                                  class="choose_account"
                                >
                                  {{ index + 2 }}
                                </option>
                                >
                              </select>
                              <font-awesome-icon icon="caret-down" class />
                            </div>
                          </div>
                        </div> -->
                        <!-- Select Number of Trustees/Registered Club -->

                        <!-- Select Number of Unregistered Club -->
                        <!-- <div
                          class="category"
                          v-if="Code && Code.code === '109'"
                        >
                          <div class="label-title">
                            <label>Enter Number of Exco Members</label>
                            <div class="account_select">
                              <select
                                v-model="DirectorNumber"
                                required
                                class="account_type"
                              >
                                <option value>
                                  Select Number of Exco Members
                                </option>
                                <option
                                  v-for="(one, index) in 29"
                                  :key="one"
                                  :value="index"
                                  class="choose_account"
                                >
                                  {{ index + 2 }}
                                </option>
                                >
                              </select>
                              <font-awesome-icon icon="caret-down" class />
                            </div>
                          </div>
                        </div> -->
                        <!-- Select Number of Unregistered Club -->

                        <!-- Select Number of Registered Business/Enterprise
 -->
                        <!-- <div
                          class="category"
                          v-if="Code && Code.code === '106'"
                        >
                          <div class="label-title">
                            <label
                              >Enter Number of Owner(s)/Proprietor(s)</label
                            >
                            <div class="account_select">
                              <select
                                v-model="DirectorNumber"
                                required
                                class="account_type"
                              >
                                <option value>
                                  Select Number of Owner(s)/Proprietor(s)
                                </option>
                                <option
                                  v-for="(one, index) in 1"
                                  :key="one"
                                  :value="index"
                                  class="choose_account"
                                >
                                  {{ index + 1 }}
                                </option>
                                >
                              </select>
                              <font-awesome-icon icon="caret-down" class />
                            </div>
                          </div>
                        </div> -->
                        <!-- Select Number of Registered Business/Enterprise
 -->

                        <div v-if="Directors">
                          <!-- Select account type/category start -->

                          <!-- main input fields for NON BVN Accounts start -->
                          <div class="no-bvn">
                            <div class="non_nigerian_form">
                              <h3 v-if="Code &&
                                Code.code !== '109' &&
                                Code.code !== '108'
                                ">
                                Enter Details of the Business/Company
                              </h3>
                              <h3 v-if="(Code && Code.code === '109') ||
                                Code.code === '108'
                                ">
                                Enter Details of Clubs, Trustees And
                                Associations
                              </h3>
                              <div class="label-title2" v-if="Code.code !== '109' && Code.code !== '106'
                                ">
                                <label>Registration Number
                                  <span class="red-star">*</span></label>
                                <div class="account_select2">
                                  <input v-model="rcNo" type="tel" required class="input-amount" placeholder=" " />
                                </div>
                              </div>
                              <!-- Registered Business/Enterprise Account Name -->
                              <div v-if="Code && Code.code === '106'">
                                <div class="label-title2">
                                  <label>Business Reg. Number
                                    <span class="red-star">*</span></label>
                                  <div class="account_select2">
                                    <input v-model="PartnershipName" type="text" required class="input-amount"
                                      placeholder=" " />
                                  </div>
                                </div>
                              </div>

                              <!-- Limited Liability/Corporate Account Name -->
                              <div v-if="existing" class="label-title2">
                                <label>Business Name
                                  <span class="red-star">*</span></label>
                                <div class="account_select2">
                                  <input v-model="BizName" type="text" required class="input-amount" placeholder=" " />
                                </div>
                              </div>
                              <!-- Limited Liability/Corporate Account Name -->

                              <!-- Partnership Account Name -->
                              <div v-if="Code && Code.code === '107'">
                                <div class="label-title2">
                                  <label>Partnership Name
                                    <span class="red-star">*</span></label>
                                  <div class="account_select2">
                                    <input v-model="PartnershipName" type="text" required class="input-amount"
                                      placeholder=" " />
                                  </div>
                                </div>
                              </div>
                              <!-- Partnership Account Name -->

                              <!-- REGISTERED CLUBS, TRUSTEES AND ASSOCIATIONS AMENDED ON CORPORATE ACCOUNT Account -->
                              <div v-if="Code && Code.code === '108'">
                                <div class="label-title2">
                                  <label>Name <span class="red-star">*</span></label>
                                  <div class="account_select2">
                                    <input v-model="BizName" type="text" required class="input-amount" placeholder=" " />
                                  </div>
                                </div>

                                <div class="label-title2">
                                  <label>Address
                                    <span class="red-star">*</span>
                                  </label>
                                  <div class="account_select2">
                                    <input v-model="BizAddress" type="text" required class="input-amount"
                                      placeholder=" " />
                                  </div>
                                </div>

                                <div class="label-title2">
                                  <label>Objectives
                                    <span class="red-star">*</span></label>
                                  <div class="account_select2">
                                    <input type="text" v-model="BizObj" required class="input-amount" placeholder=" " />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label>Nominated Phone Number
                                    <span class="red-star">*</span></label>
                                  <div class="account_select2">
                                    <the-mask mask="#############" type="tel" class="input-amount" required
                                      v-model="BizPhone" placeholder=" " />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label>Tax Identification Number
                                    <!-- <span class="red-star">*</span> -->
                                  </label>
                                  <div class="account_select2">
                                    <input type="text" v-model="TIN" class="input-amount" placeholder=" " />
                                  </div>
                                </div>
                              </div>
                              <!-- REGISTERED CLUBS, TRUSTEES AND ASSOCIATIONS AMENDED ON CORPORATE ACCOUNT Account -->

                              <!-- UNREGISTERED CLUBS, TRUSTEES AND ASSOCIATIONS AMENDED ON CORPORATE ACCOUNT Account -->
                              <div v-if="Code && Code.code === '109'">
                                <div class="label-title2">
                                  <label>Name <span class="red-star">*</span></label>
                                  <div class="account_select2">
                                    <input v-model="BizName" type="text" required class="input-amount" placeholder=" " />
                                  </div>
                                </div>

                                <div class="label-title2">
                                  <label>Address
                                    <span class="red-star">*</span></label>
                                  <div class="account_select2">
                                    <input v-model="BizAddress" type="text" required class="input-amount"
                                      placeholder=" " />
                                  </div>
                                </div>

                                <div class="label-title2">
                                  <label>Objectives
                                    <span class="red-star">*</span></label>
                                  <div class="account_select2">
                                    <input type="text" v-model="BizObj" required class="input-amount" placeholder=" " />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label>Nominated Phone Number
                                    <span class="red-star">*</span></label>
                                  <div class="account_select2">
                                    <the-mask mask="#############" type="tel" class="input-amount" required
                                      v-model="BizPhone" placeholder=" " />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label>Tax Identification Number
                                    <!-- <span class="red-star">*</span> -->
                                  </label>
                                  <div class="account_select2">
                                    <input type="text" v-model="TIN" class="input-amount" placeholder=" " />
                                  </div>
                                </div>
                              </div>
                              <!-- UNREGISTERED CLUBS, TRUSTEES AND ASSOCIATIONS AMENDED ON CORPORATE ACCOUNT Account -->

                              <!-- Registered Business/Enterprise Account Name -->

                              <div v-if="Code && Code.code !== '109'">
                                <div class="label-title2">
                                  <label>Date of Reg.
                                    <span class="red-star">*</span></label>
                                  <DatePicker v-model="BizReg" valueType="format" format="DD-MM-YYYY"
                                    placeholder="DD-MM-YYYY"></DatePicker>
                                </div>
                              </div>
                              <div v-if="Code &&
                                Code.code !== '108' &&
                                Code.code !== '109'
                                " class="label-title2">
                                <label>Business Address
                                  <span class="red-star">*</span></label>
                                <div class="account_select2">
                                  <input v-model="BizAddress" type="text" required class="input-amount" placeholder=" " />
                                </div>
                              </div>

                              <div class="label-title2">
                                <label>Email Address
                                  <span class="red-star">*</span></label>
                                <div class="account_select2">
                                  <input v-model="BizEmail" type="email" required class="input-amount" placeholder=" " />
                                </div>
                              </div>

                              <div class="label-title2">
                                <label>Country
                                  <span class="red-star">*</span></label>
                                <div class="account_select">
                                  <select v-model="BizCountry" class="account_type">
                                    <option v-for="(items, key) in countries" :key="key" required :value="items.label"
                                      class="choose_account">
                                      {{ items.label }}
                                    </option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>State <span class="red-star">*</span></label>
                                <div class="account_select">
                                  <select v-model="BizState" class="account_type">
                                    <option value>--Select State--</option>
                                    <option v-for="(items, key) in states" :key="key" required :value="items"
                                      class="choose_account">
                                      {{ items.label }}
                                    </option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>LGA <span class="red-star">*</span></label>
                                <div class="account_select">
                                  <select v-model="BizLga" class="account_type">
                                    <option value>--Select Lga--</option>
                                    <option v-for="(items, key) in BizState.lgas" :key="key" required :value="items.value"
                                      class="choose_account">
                                      {{ items.label }}
                                    </option>
                                  </select>
                                  <font-awesome-icon icon="caret-down" class />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>City <span class="red-star">*</span></label>
                                <div class="account_select2">
                                  <input type="text" v-model="BizCity" required class="input-amount" placeholder=" " />
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>ZIP Code
                                  <!-- <span class="red-star">*</span> -->
                                </label>
                                <div class="account_select2">
                                  <input type="tel" v-model="ZipCode" class="input-amount" placeholder=" " />
                                </div>
                              </div>
                              <div v-if="Code.code !== '108' && Code.code !== '109'
                                ">
                                <div class="label-title2">
                                  <label>Tax Identification Number </label>
                                  <div class="account_select2">
                                    <input type="text" v-model="TIN" class="input-amount" placeholder=" " />
                                  </div>
                                </div>
                                <div class="label-title2" v-if="Code.code !== '106'">
                                  <label>Authorized Share Capital
                                    <span class="red-star">*</span></label>
                                  <div class="account_select2">
                                    <input type="number" v-model="ShareCap" class="input-amount" required
                                      placeholder=" " />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label>Objective of the Company
                                    <span class="red-star">*</span></label>
                                  <div class="account_select2">
                                    <input type="text" v-model="BizObj" required class="input-amount" placeholder=" " />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label>Office Phone Number
                                    <span class="red-star">*</span></label>
                                  <div class="account_select2">
                                    <the-mask mask="#############" type="tel" class="input-amount" required
                                      v-model="BizPhone" placeholder=" " />
                                  </div>
                                </div>
                                <div class="label-title2" v-if="Code.code !== '106'">
                                  <label>Company Secretary Name
                                    <span class="red-star">*</span></label>
                                  <div class="account_select2">
                                    <input type="text" v-model="CompSecName" required class="input-amount"
                                      placeholder=" " />
                                  </div>
                                </div>
                                <div class="label-title2" v-if="Code.code !== '106'">
                                  <label>Company Secretary Phone No.
                                    <span class="red-star">*</span></label>
                                  <div class="account_select2">
                                    <the-mask mask="#############" type="tel" class="input-amount" required
                                      v-model="CompSecPhone" placeholder=" " />
                                  </div>
                                </div>
                                <div class="label-title2" v-if="Code.code !== '106'">
                                  <label>Company Secretary Address
                                    <span class="red-star">*</span></label>
                                  <div class="account_select2">
                                    <input type="text" v-model="CompSecAddress" required class="input-amount"
                                      placeholder=" " />
                                  </div>
                                </div>

                                <div class="label-title2">
                                  <label>SCUML (where applicable)
                                    <!-- <span class="red-star">*</span> -->
                                  </label>
                                  <div class="account_select2">
                                    <input type="text" v-model="SCUML" class="input-amount" placeholder=" " />
                                  </div>
                                </div>
                              </div>
                              <div class="label-title2">
                                <label>Expected Turnover
                                  <span class="red-star">*</span></label>
                                <div class="account_select2">
                                  <vue-numeric class="input-amount" currency="₦" separator="," v-model="TurnOver"
                                    v-bind:precision="2"></vue-numeric>
                                  <!-- <input
                                    type="number"
                                    v-model="TurnOver"
                                    required
                                    class="input-amount"
                                    placeholder=" "
                                  /> -->
                                </div>
                              </div>

                              <!-- Registered Business/Enterprise Account Name -->
                              <!-- <div v-if="Code && Code.code === '106'" >
                                <h3>Details of Next of Kin</h3>
                                <div class="label-title2">
                                  <label
                                    >Name
                                    <span class="red-star">*</span></label
                                  >
                                  <div class="account_select2">
                                    <input
                                      type="text"
                                      required
                                      class="input-amount"
                                      placeholder=" "
                                    />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label
                                    >Phone
                                    <span class="red-star">*</span></label
                                  >
                                  <div class="account_select2">
                                    <input
                                      type="tel"
                                      required
                                      class="input-amount"
                                      placeholder=" "
                                    />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label
                                    >Address
                                    <span class="red-star">*</span></label
                                  >
                                  <div class="account_select2">
                                    <input
                                      type="text"
                                      required
                                      class="input-amount"
                                      placeholder=" "
                                    />
                                  </div>
                                </div>
                                <div class="label-title2">
                                  <label
                                    >Relationship
                                    <span class="red-star">*</span></label
                                  >
                                  <div class="account_select2">
                                    <input
                                      type="text"
                                      required
                                      class="input-amount"
                                      placeholder=" "
                                    />
                                  </div>
                                </div>
                              </div> -->
                              <!-- Registered Business/Enterprise Account Name -->

                              <!-- <div class="term_condition_check">
                                <input type="checkbox" id="terms" name="terms" value="Terms" />
                                <label for="terms">
                                  I accept the
                                  <router-link to="terms" class>Terms and Conditions</router-link>
                                </label>
                              </div>-->
                            </div>
                          </div>
                          <!-- Who introduced you to Hope? (Optional) -->
                          <div class="non_nigerian_form">
                            <div class="label-title2">
                              <div class="">
                                <label>
                                  Referral Code or Phone No.
                                  <span class="optional_">[Optional]</span>
                                </label>
                                <p>Who introduced you to Hope?</p>
                              </div>
                              <div class="account_select2">
                                <input type="text" maxlength="11" class="input-amount" placeholder=" " />
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Limited Liability/Corporate Registration ends -->
                        <!-- submit -->
                        <div class="send-or-download2">
                          <div class="continue-button">
                            <a @click="$router.go(-1)">
                              <div class="continue-btn">
                                <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                              </div>
                            </a>
                          </div>
                          <div class="continue-button">
                            <!-- <b-button @click="next_page" v-if='Type == 109' class="continue-btn">Next<font-awesome-icon icon="angle-right" class="angle-icon"/></b-button> -->
                            <b-button type="submit" class="continue-btn">
                              SUBMIT
                              <font-awesome-icon icon="angle-right" class="angle-icon" />
                            </b-button>
                            <!-- <router-link to="proceed">PROCEED</router-link> -->
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-modal id="modal-center" centered hide-footer v-model="success_modal" hide-header-close>
            <form ref="form">
              <b-form-group label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
                <img src="../../../assets/images/icons/check.svg" alt="Hope Bank" class="modal_congrats_img" />
                <h1 class="pin-info text-center">Congratulations</h1>
                <div class="line4"></div>
                <p class="modal_congrats">
                  Dear {{ BizName }}, Your <b>{{ ACCOUNT_TYPE.name }}</b> number
                  is <b>{{ responseData.account }}</b>.
                  <span>Signatory(s) have been notified via email to enrol on
                    Digital Banking to activate the account.</span>
                  <br />
                  <span>Legal Search Fee is ₦10,000</span>
                </p>
                <b-button @click="success" class="ok-btn">OK</b-button>
              </b-form-group>
            </form>
          </b-modal>
        </div>
      </div>
      <Footer2 />

      <!-- otp modal for no bvn account -->
    </div>
  </div>
</template>

<script>
import HeaderNavRegistered from "../../../components/HeaderNav/HeaderNavRegistered";
import { TheMask } from "vue-the-mask";
//import { getTitlesService } from "../../services/getTitles.services.js";
import Loader from "../../../components/Loader/Loader.vue";
import { AccountTypeService } from "../../../services/accountType.services.js";
import { getCountryService } from "../../../services/getCountries.services.js";
import { getStateService } from "../../../services/getState.services.js";
import { CorporateAccountService } from "../../../services/CorporateAccount/corporateAccount.services.js";
//import Keyboard from "../../components/Keyboard/Keyboard.vue";
import { mapState, mapGetters } from "vuex";
import VueNumeric from "vue-numeric";
import Footer2 from "../../../components/Footer/Footer2";

export default {
  name: "business.vue",
  components: {
    HeaderNavRegistered,
    TheMask,
    Loader: Loader,
    VueNumeric,
    Footer2,
    //Keyboard
  },
  data() {
    return {
      image: "",
      DirectorNumber: "",
      isDisabled: true,
      isDisabled_: true,
      isDisabled1: true,
      loading: false,
      Code: "",
      Bvn: "",
      pin: "",
      pin1: "",
      isPinMatch: false,
      pin_keyboard1: true,
      pin_keyboard: false,
      Phone: "",
      DateOfBirth: "",
      AccountDataType: [],
      otp: "",
      otp_modal: false,
      pin_modal: false,
      submittedNames: [],
      nameState: null,
      category: "",
      country: null,
      titles: [],
      countries: [],
      states: [],
      Nationality: "",
      Address: "",
      Email: "",
      Gender: "",
      FirstName: "",
      MiddleName: "",
      LastName: "",
      NextOfKin: "",
      NextOfKinPhone: "",
      success_modal: false,
      responseData: {},
      //new for business account 1
      rcNo: "",
      BizName: "",
      BizAddress: "",
      BizReg: "",
      BizEmail: "",
      BizCountry: "",
      BizState: "",
      BizLga: "",
      BizCity: "",
      ZipCode: "",
      TIN: "",
      ShareCap: "",
      BizObj: "",
      BizPhone: "",
      CompSecName: "",
      CompSecPhone: "",
      CompSecAddress: "",
      TurnOver: "",
      SCUML: "",
      existing: true,
      corporate: "",
      account: "",
    };
  },
  computed: {
    NewAccountType() {
      return this.$store.getters.ACCOUNT;
    },
    ...mapGetters({ user: "GET_USER" }),
    ...mapState({
      ACCOUNT_TYPE: (state) => state.CreateAccount.ACCOUNT_TYPE,
      ACCOUNT_ID: (state) => state.CreateAccount.ACCOUNT_ID,
    }),
    Directors() {
      if (this.DirectorNumber === "") return 0;
      return this.DirectorNumber + 2;
    },
    CorporateAccountDataType() {
      let account = this.AccountDataType;
      return account.filter((item) => item.group == 2);
    },
  },
  watch: {
    existing(newval) {
      if (newval == "new") {
        this.$router.push("/signatories");
      }
    },
  },

  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid ? "valid" : "invalid";
      return valid;
    },
    onChange() {
      window.console.log("The new value is: ", this.corporate);
    },
    handleOk(bvModalEvt) {
      window.console.log(bvModalEvt);
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs.modal.hide();
      });
    },

    //Get countries and state
    getCountries() {
      getCountryService
        .getCountryWhenLoggedIn()
        .then((res) => {
          this.loading = false;
          this.countries = res;
        })
        .catch((err) => window.console.log(err));
    },

    getState() {
      getStateService
        .getStateWhenLoggedIn()
        .then((res) => {
          this.loading = false;
          this.states = res;
        })
        .catch((err) => window.console.log(err));
    },

    //get Account types
    async GetAccountTypes() {
      await AccountTypeService.accountTypeServiceWhenLoggedIn()
        .then((res) => {
          this.loading = false;
          this.AccountDataType = res;
          //window.console.log("=====", res);
        })
        .catch((err) => window.console.log(err));
    },

    //Validate Corporate
    async ValidateCorporate() {
      this.loading = true;
      let payload = {
        code: this.Code.code,
        existing: true,
        BizName: this.BizName,
        corporate: this.corporate.customerId,
        account: this.corporate.accountNumber,
        // account:
        // rcNo: this.rcNo,
        // BizAddress: this.BizAddress,
        // BizReg: this.BizReg,
        // BizEmail: this.BizEmail,
        // BizCountry: this.BizAddress,
        // BizState: this.BizState.value,
        // BizLga: this.BizLga,
        // BizCity: this.BizCity,
        // ZipCode: this.ZipCode,
        // TIN: this.TIN,
        // ShareCap: this.ShareCap,
        // BizObj: this.BizObj,
        // BizPhone: this.BizPhone,
        // CompSecName: this.CompSecName,
        // CompSecPhone: this.CompSecPhone,
        // CompSecAddress: this.CompSecAddress,
        // TurnOver: this.TurnOver,
        // SCUML: this.SCUML,
      };

      // if (this.Code.code === "107") {
      //   payload.BizName = this.PartnershipName;
      // } else {
      //   payload.BizName = this.BizName;
      // }
      await CorporateAccountService.validationCorporateWhenLoggedIn(payload)
        .then((res) => {
          window.console.log(
            "this is the response ",
            JSON.stringify(res.status)
          );
          this.$store.commit("SET_CORPORATE_COMPANY_ID", res.id);
          this.$store.commit("SET_CORPORATE_DIRECTORS_NO", this.Directors);
          this.$store.commit("SET_CORPORATE_COMPANY_NAME", payload.BizName);
          this.responseData = res;
          this.success_modal = true;
          // this.$router.push("/Proceed_");
        })
        .catch((err) => this.$toastr.error(err.status, "Failed"))
        .finally(() => {
          this.loading = false;
        });
    },
    success() {
      this.$router.push("/Dashboard_");
      this.$store.dispatch("CLEAR_CORPORATE_DATA");
    },
  },

  created() {
    this.GetAccountTypes();
    this.getCountries();
    this.getState();
    this.$store.dispatch("CLEAR_CORPORATE_DATA");
    this.$store.commit("SET_BVN_DETAILS", {});
    this.$store.commit("CLEAR_CORPORATE_MANDATE");
    this.$store.commit("CLEAR_CORPORATE_SIGNATORIES_ID");
  },
  beforeDestroy() {
    this.$store.commit("SET_ACCOUNT_TYPE", this.Code);
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}

.navbar {
  padding: 0;
}

.quick-transfer {
  padding: 0 2.2rem;
}

.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}

.modal-header {
  padding: 1rem 1rem 0 !important;
}

.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}

.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}

.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
}

.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
}

.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}

.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}

.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}

.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}

.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #ffffff !important;
}

.yellow_bg>.navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}

.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}

.main_dashboard {
  width: 75%;
  margin: 0 auto;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .main_dashboard {
    width: 100% !important;
  }
}
</style>
