import { ApiService } from "../api.service";

const CorporateAccountService = {
    //Validate Signatory/maker
    validate: async ({ Phone, Account }) => {
        return new Promise(function (resolve, reject) {
            ApiService.post('/api/web/corporate/auth/validate', {
                Phone,
                Account,
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //Complete Validation Signatoty/maker
    complete: async ({ CustomerId, CorporateId, PhoneX, Code, Account, Password, Pin, question1, question2, question3, answer1, answer2, answer3 }) => {
        const Questions = {}
        Questions[question1] = answer1;
        Questions[question2] = answer2;
        Questions[question3] = answer3;

        return new Promise(function (resolve, reject) {
            ApiService.post('/api/web/corporate/auth/complete', {
                CustomerId,
                CorporateId,
                PhoneX,
                Code,
                Account,
                Password,
                Pin,
                Questions
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //Create Maker
    createMaker: async ({ Bvn, Phone, DateOfBirth,account, corporate, email }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'POST',
                url: ApiService.getBaseUrl() + "/api/web/corporate/create/maker",
                data: {
                    Bvn,
                    DateOfBirth,
                    Phone,
                    corporate,
                    account,
                    email,
                },
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/corporate/create/maker', localStorage.getItem('licence'))
                },
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

     //Create Checker
     createChecker: async ({ corporate, Bvn, Phone, DateOfBirth, account,email }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'POST',
                url: ApiService.getBaseUrl() + "/api/web/corporate/create/checker",
                data: {
                    Bvn,
                    DateOfBirth,
                    Phone,
                    email,
                    account,
                    corporate

                },
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/corporate/create/checker', localStorage.getItem('licence'))
                },
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //Set pin Signatory/maker
    setPin9: async ({ Pin, Account }) => {
        return new Promise(function (resolve, reject) {
            ApiService.post('/api/web/corporate/auth/set-pin', {
                Pin,
                Account,
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

   //Set pin Signatory/maker
    setPin: async ({Pin, Account}) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'POST',
                url: ApiService.getBaseUrl() + "/api/web/corporate/auth/set-pin",
                data: {
                    Pin,
                    Account,

                },
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/corporate/auth/set-pin', localStorage.getItem('licence'))
                },
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //Set pin PIB
    setPin_: async ({ Pin, Account }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'POST',
                url: ApiService.getBaseUrl() + "/api/web/auth/set-pin",
                data: {
                    Pin,
                    Account,

                },
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/auth/set-pin', localStorage.getItem('licence'))
                },
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //Validate Referee
    validateReferee: async ({ Phone, Account, Bvn, DatOfBirth, Referee, Bank }) => {
        return new Promise(function (resolve, reject) {
            ApiService.post('/api/web/validate/referee', {
                Phone,
                Account,
                Bvn,
                DatOfBirth,
                Referee,
                Bank
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },


};



export {
    CorporateAccountService
};
