<template>
  <div class="upper-nav">
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />
    <div class="hope-logo">
      <router-link to="/">
        <img
          src="../../assets/images/logo/hope_bank_logo1.svg"
          alt="Hope Bank Logo"
          class="hope_logo"
        />
      </router-link>
    </div>
    <div class="back-logout">
      <div class="dropdown">
        <div class="dropdown__header" @click="toggleDropdown($event)">
          <div class="notify-btn">
            <font-awesome-icon icon="bell" class="bell-icon" />
          </div>
          <i class="fa fa-angle-down" aria-hidden="true"></i>
          <i class="fa fa-angle-up" aria-hidden="true"></i>
        </div>
        <div class="dropdown__content">
          <div>
            <badger-accordion>
              <badger-accordion-item>
                <template slot="header">
                  <div class="notify_view">
                    <div>1.</div>
                    <div class>New Credit Alert</div>
                  </div>
                </template>
                <template slot="content">
                  <div class="notify_view2">
                    <p class>N5000 from Chioma Chukwuma</p>
                  </div>
                </template>
              </badger-accordion-item>
              <badger-accordion-item>
                <template slot="header">
                  <div class="notify_view">
                    <div>2.</div>
                    <div class>New Debit Alert</div>
                  </div>
                </template>
                <template slot="content">
                  <div class="notify_view2">
                    <p class>N5000 from Chioma Chukwuma</p>
                  </div>
                </template>
              </badger-accordion-item>
              <badger-accordion-item>
                <template slot="header">
                  <div class="notify_view">
                    <div>3.</div>
                    <div class>New Credit Alert</div>
                  </div>
                </template>
                <template slot="content">
                  <div class="notify_view2">
                    <p class>N5000 from Chioma Chukwuma</p>
                  </div>
                </template>
              </badger-accordion-item>
              <badger-accordion-item>
                <template slot="header">
                  <div class="notify_view">
                    <div>4.</div>
                    <div class>New Debit Alert</div>
                  </div>
                </template>
                <template slot="content">
                  <div class="notify_view2">
                    <p class>N5000 from Chioma Chukwuma</p>
                  </div>
                </template>
              </badger-accordion-item>
            </badger-accordion>
          </div>
        </div>
      </div>
      <router-link to="/Dashboard">
        <button class="go-back-btn">
          <!--<button class="go-back-btn" @click="$router.go(-1)">-->
          <span>
            <font-awesome-icon icon="arrow-left" class="angle-left" />
          </span> Back
        </button>
      </router-link>
      <router-link to>
        <button @click="logout" class="logout-btn2">
          <span>
            <font-awesome-icon icon="power-off" class="angle-left" />
          </span> Logout
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { BadgerAccordion, BadgerAccordionItem } from "vue-badger-accordion";
import { userService } from "../../services/user.service.js";
import Loader from "../../components/Loader/Loader.vue";
let timeout;

export default {
  name: "HeaderNav2.vue",
  components: {
    BadgerAccordion,
    BadgerAccordionItem,
    Loader
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },
    async logout() {
      this.loading = true;
      const data = {};
      await userService
        .logout(data)
        .then(res => {
          this.$store.commit("SET_AUTH_TOKEN", "");
          this.removeListeners();
          this.$toastr.success(res.status, { timeOut: 3000 });
          this.$router.push("/Login");
          window.location.reload();
        })
        .catch(err => {
          this.$toastr.error(err.status, "Failed!", { timeOut: 5000 });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async logout1() {
      this.loading = true;
      const data = {};
      await userService
        .logout(data)
        .then(res => {
          this.$store.commit("SET_AUTH_TOKEN", "");
          this.removeListeners();
          this.$toastr.success(res.status, { timeOut: 3000 });
          this.$router.push("/Login");
          window.location.reload();
          this.$toastr.info("Session timed out", { timeOut: 5000 });
        })
        .catch(err => {
          this.$toastr.error(err.status, "Failed!", { timeOut: 5000 });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetTimeout() {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.logout1();
      }, 300000);
    },
    activateActivityTracker() {
      window.addEventListener("click", this.resetTimeout);
      window.addEventListener("scroll", this.resetTimeout);
      window.addEventListener("mousemove", this.resetTimeout);
      window.addEventListener("keydown", this.resetTimeout);
    },
    removeListeners() {
      clearTimeout(timeout);
      window.removeEventListener("click", this.resetTimeout);
      window.removeEventListener("scroll", this.resetTimeout);
      window.removeEventListener("mousemove", this.resetTimeout);
      window.removeEventListener("keydown", this.resetTimeout);
    }
  },
  mounted() {
    this.activateActivityTracker();
    this.resetTimeout();
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}
.quick-transfer {
  padding: 0 0.7rem;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
</style>

<style lang="scss">
.dropdown {
  &__header {
    cursor: pointer;
    /*line-height: 50px;*/
    /*padding-left: 10px;*/
    /*padding-right: 50px;*/
    /*position: relative;*/
    /*text-overflow: ellipsis;*/
    i.fa {
      position: absolute;
      /*right: 10px;*/
      top: 50%;
      width: 50px;

      transition: opacity 0.3s;
      &.fa-angle-up {
        opacity: 0;
      }
    }
    &.is-active {
      i.fa {
        &.fa-angle-up {
          opacity: 1;
        }
        &.fa-angle-down {
          opacity: 0;
        }
      }
      + .dropdown__content {
        height: auto;
        opacity: 1;
        visibility: visible;
        position: absolute;
        z-index: 5;
        background: #ffffff;
        width: 230px;
      }
    }
  }
  &__content {
    height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 15px 10px;
    transition: opacity 0.3s;
    visibility: hidden;
  }
}
</style>