<template>
  <div class>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNavBiz />

      <div class="container">
        <BusinessNavBar />

        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <img src="../../../../assets/images/icons/transfer-sm.svg" alt="Hope Bank" />
                  <p class="frequently-used">Confirm Transaction</p>
                </div>
                <div>
                  <b-tabs no-body v-model="tabIndex" class="generictab" ref="wizard">
                    <!-- <b-tab :active="one" :title-link-class="disabledTab">
                      <template v-slot:title>
                        To
                        <br />
                        <strong>Own Account</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Transfer Funds to Own Account</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Please confirm your Transactions Details</p>
                        </div>
                        <form action>
                          <div class="form-table2">
                            <div class="label-title2">
                              <label>Debit From</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{TRANSFER_ACCOUNT_FROM}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Bank</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">Hope Bank</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Number</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{TRANSFER_ACCOUNT_TO}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Names</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{user.accounts[0].accountName}}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  <span>&#8358;</span>
                                  {{TRANSFER_AMOUNT}}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction Note</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{TRANSFER_NOTE}}</div>
                              </div>
                            </div>
                          </div>
                          <div class="send-or-download2">
                            <div class="continue-button">
                              <button
                                class="continue-cib-btn"
                                @click="$router.push('transfermoney_?p=1')"
                              >
                                BACK
                                <font-awesome-icon icon="angle-left" class="angle-icon" />
                              </button>
                            </div>
                            <div class="continue-button">
                              <b-button
                                @click="confirm_transaction_own = true"
                                class="continue-cib-btn"
                              >
                                SUBMIT
                                <font-awesome-icon icon="angle-right" class="angle-icon" />
                              </b-button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </b-tab>-->

                    <b-tab :active="two" title="tab2" :title-link-class="disabledTab">
                      <template v-slot:title class="left">
                        To
                        <br />
                        <strong>Hope PS Bank</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Transfer Funds to Hope PS Bank Account</p>
                        </div>
                        <p class="frequently-used pt-2">Business Name</p>
                        <div class="kindly_enter_details">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Please confirm your Transactions Details before your Approve</p>
                        </div>
                        <form action>
                          <div class="form-table2">
                            <div class="label-title2">
                              <label>Debit From</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{ TRANSFER_ACCOUNT }}</div>
                              </div>
                            </div>
                            <div class="beneficiary_details">
                              <p>Beneficiary Details</p>
                            </div>
                            <div class="label-title2">
                              <label>Bank</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">HOPE Bank</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Number</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{ BENEFICIARY_ACCOUNT }}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Names</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{ BENEFICIARY_NAME }}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  <span>&#8358;</span>
                                  {{(TRANSFER_AMOUNT)}}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Description</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{ TRANSFER_NOTE }}</div>
                              </div>
                            </div>
                          </div>
                          <div class="send-or-download2">
                            <div class="continue-button">
                              <!--<b-button v-b-modal.modal-center class="continue-cib-btn">BACK <font-awesome-icon icon="angle-right" class="angle-icon"/></b-button>-->
                              <button
                                class="continue-cib-btn"
                                @click="$router.push('transfer?p=1')"
                              >BACK</button>
                            </div>
                            <div class="d-flex">
                              <!-- <div class="continue-button mr-2">
                                <b-button
                                  @click="confirm_transaction = false"
                                  class="reject-btn"
                                >
                                  REJECT
                                </b-button>
                              </div>-->
                              <div class="continue-button">
                                <b-button
                                  @click="confirm_transaction = true"
                                  class="continue-cib-btn"
                                >APPROVE</b-button>
                                <!--<button class="continue-cib-btn">CONTINUE <font-awesome-icon icon="angle-right" class="angle-icon"/></button>-->
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </b-tab>
                    <b-tab :title-link-class="disabledTab" title="tab3" :active="three">
                      <template v-slot:title>
                        To
                        <br />
                        <strong>Other Bank</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Transfer Funds to Other Account</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Please confirm your Transactions Details</p>
                        </div>
                        <form action>
                          <div class="form-table2">
                            <div class="label-title2">
                              <label>Debit From</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{ TRANSFER_ACCOUNT }}</div>
                              </div>
                            </div>
                            <div class="beneficiary_details">
                              <p>Beneficiary Details</p>
                            </div>
                            <div class="label-title2">
                              <label>Bank</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{ BENEFICIARY_BANK.label }}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Number</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{ BENEFICIARY_ACCOUNT }}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Names</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{ BENEFICIARY_NAME }}</div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  <span>&#8358;</span>
                                  {{ TRANSFER_AMOUNT }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Description</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">{{ TRANSFER_NOTE }}</div>
                              </div>
                            </div>
                          </div>
                          <div class="send-or-download2">
                            <div class="continue-button">
                              <!--<b-button v-b-modal.modal-center class="continue-cib-btn">BACK <font-awesome-icon icon="angle-right" class="angle-icon"/></b-button>-->
                              <button
                                class="continue-cib-btn"
                                @click="$router.push('transfer?p=3')"
                              >BACK</button>
                            </div>
                            <div class="d-flex">
                              <!-- <div class="continue-button mr-2">
                                <b-button
                                  @click="confirm_transaction = false"
                                  class="reject-btn"
                                >
                                  REJECT
                                </b-button>
                              </div>-->
                              <div class="continue-button">
                                <b-button
                                  v-b-modal.modal-center
                                  class="continue-cib-btn"
                                  @click="confirm_transaction = true"
                                >APPROVE</b-button>
                                <!--<button class="continue-cib-btn">CONTINUE <font-awesome-icon icon="angle-right" class="angle-icon"/></button>-->
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </b-tab>
                    <!-- <b-tab :title-link-class="disabledTab" title="tab4" :active="four">
                                        <template v-slot:title>
                                            International<br><strong>Fund Transfer</strong>
                                        </template>
                                        <div class="tabs-contents">
                                            <div class="current_tab_title">
                                                <font-awesome-icon icon="play" class="play-icon"/>
                                                <p class="">Transfer Funds to Other Account</p>
                                            </div>
                                            <div class="kindly_enter_details">
                                                <font-awesome-icon icon="exclamation-circle" class="exclamation-icon"/>
                                                <p>Please confirm your Transactions Details</p>
                                            </div>
                                            <form action="">
                                                <div class="form-table2">
                                                    <div class="label-title">
                                                        <label >Debit From</label>
                                                        <div class="account_select3">
                                                            <div class="confirm-account-info">CURRENT ACCOUNT - 1234567890</div>
                                                        </div>
                                                    </div>
                                                    <div class="label-title">
                                                        <label >Bank</label>
                                                        <div class="account_select3">
                                                            <div class="confirm-account-info">Bank of America</div>
                                                        </div>
                                                    </div>
                                                    <div class="label-title2">
                                                        <label >Bank ID</label>
                                                        <div class="account_select3">
                                                            <div class="confirm-account-info">1234567890</div>
                                                        </div>
                                                    </div>
                                                    <div class="label-title2">
                                                        <label >Account Names</label>
                                                        <div class="account_select3">
                                                            <div class="confirm-account-info">Michael Daniel</div>
                                                        </div>
                                                    </div>
                                                    <div class="label-title2">
                                                        <label >Amount</label>
                                                        <div class="account_select3">
                                                            <div class="confirm-account-info"><span>$</span>10,000</div>
                                                        </div>
                                                    </div>
                                                    <div class="label-title2">
                                                        <label >Transaction Note</label>
                                                        <div class="account_select3">
                                                            <div class="confirm-account-info">--</div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="send-or-download2">
                                                    <div class="continue-button">
                                                        <button class="continue-cib-btn" @click="$router.push('transfermoney_?p=4')">BACK <font-awesome-icon icon="angle-left" class="angle-icon"/></button>
                                                    </div>
                                                    <div class="continue-button">
                                                        <b-button v-b-modal.modal-center class="continue-cib-btn">SUBMIT <font-awesome-icon icon="angle-right" class="angle-icon"/></b-button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                    </b-tab>-->
                    <!-- Schedule Transaction to Hope Bank -->
                    <!-- <b-tab
                      :title-link-class="disabledTab"
                      title="tab5"
                      :active="five"
                    >
                      <template v-slot:title>
                        Schedule
                        <br />
                        <strong>Transaction</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Transaction Schedue</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon
                            icon="exclamation-circle"
                            class="exclamation-icon"
                          />
                          <p>Please confirm your Transactions Details</p>
                        </div>
                        <form action>
                          <div class="form-table2">
                            <div class="label-title">
                              <label>Debit From</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ TRANSFER_ACCOUNT }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title">
                              <label>Bank</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  Hope Bank
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Number</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ BENEFICIARY_ACCOUNT }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Names</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ BENEFICIARY_NAME }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  <span>&#8358;</span>
                                  {{ TRANSFER_AMOUNT }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction Note</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ TRANSFER_NOTE }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction Schedule Name</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ SCHEDULE_NAME }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction Frequency</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ FREQUENCY }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction Start Date</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ START }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction End Date</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ END }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="send-or-download2">
                            <div class="continue-button">
                              <button
                                class="continue-cib-btn"
                                @click="$router.push('transfermoney_?p=5')"
                              >
                                BACK
                                <font-awesome-icon
                                  icon="angle-left"
                                  class="angle-icon"
                                />
                              </button>
                            </div>
                            <div class="continue-button">
                              <b-button
                                v-b-modal.modal-center
                                class="continue-cib-btn"
                              >
                                SUBMIT
                                <font-awesome-icon
                                  icon="angle-right"
                                  class="angle-icon"
                                />
                              </b-button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </b-tab>-->
                    <!-- <b-tab :title-link-class="disabledTab" title="tab6" :active="five">
                                        <template v-slot:title>
                                            Schedule<br><strong>Transaction</strong>
                                        </template>
                                        <div class="tabs-contents">
                                            <div class="current_tab_title">
                                                <font-awesome-icon icon="play" class="play-icon"/>
                                                <p class="">Schedule Transaction</p>
                                            </div>
                                            <div class="kindly_enter_details">
                                                <font-awesome-icon icon="exclamation-circle" class="exclamation-icon"/>
                                                <p>Please confirm your Transactions Details</p>
                                            </div>
                                            <div class="recent-transactions scheduled_table">
                                                <table>
                                                    <tr>
                                                        <th>S/NO</th>
                                                        <th>Amount</th>
                                                        <th>Description</th>
                                                        <th>Schedue Nma</th>
                                                        <th>Frequency</th>
                                                       
                                                    </tr>
                                                    <tr>
                                                        <td>1</td>
                                                        <td><span>&#8358;</span> {{TRANSFER_AMOUNT}}</td>
                                                        <td>Musa Emeka Akpan - 01234567890</td>
                                                        <td>Monthly</td>
                                                        <td class="view-delete-"><div class="view-delete">
                                                            <router-link to="TransferMoney"><button class="view-action-btn">View</button></router-link>
                                                            <button class="view-action-btn"><font-awesome-icon icon="trash-alt" class="delete-icon"/></button>
                                                        </div> </td>

                                                    </tr>  
                                                    <tr>
                                                        <td>4</td>
                                                        <td><span>&#8358;</span>105,213,840</td>
                                                        <td>HopeMobile/Transfer to Ahmed Oluwaseyi Babinus</td>
                                                        <td>Monthly</td>
                                                        <td class="view-delete-"><div class="view-delete">
                                                            <router-link to="TransferMoney"><button class="view-action-btn">View</button></router-link>
                                                            <button class="view-action-btn"><font-awesome-icon icon="trash-alt" class="delete-icon"/></button>
                                                        </div> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                    </b-tab>-->
                  </b-tabs>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar
                v-if="TRANSFER_ACCOUNT_FROM"
                :accountNumber="TRANSFER_ACCOUNT_FROM"
                :accountBalance="Balance"
                :accountName="Name"
              ></SideBar>
              <SideBar
                v-if="TRANSFER_ACCOUNT"
                :accountNumber="TRANSFER_ACCOUNT"
                :accountBalance="Balance"
                :accountName="Name"
              ></SideBar>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>

      <!-- Confirmation Modal for Transfer to Hope, OtherAccount and Schedule transfer to hope account -->
      <b-modal
        id="modal-center"
        centered
        title="Confirm Transactions"
        v-model="confirm_transaction"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <p class="confirm-amount">
              You are about to transfer
              <span>
                <span>&#8358;</span>
                {{ TRANSFER_AMOUNT }}
              </span>
              from {{ TRANSFER_ACCOUNT }} to
              <span>{{ BENEFICIARY_NAME }}</span>
              - {{ BENEFICIARY_ACCOUNT }}
            </p>
            <h1 class="pin-info text-center">Enter PIN</h1>
            <div class="dialpad">
              <div class="form-group">
                <the-mask
                  type="password"
                  class="form-control"
                  v-model="pin"
                  mask="########"
                  id="txtBox"
                  placeholder="******"
                />
              </div>
              <Keyboard
                @pressed="pin = $event"
                v-if="routeQuery == 2"
                :pinLength="pin"
                @completed="transferHope"
                :selfValue="pin"
              ></Keyboard>
              <Keyboard
                @pressed="pin = $event"
                v-if="routeQuery == 3"
                :pinLength="pin"
                @completed="transferOther"
                :selfValue="pin"
              ></Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>
      <!-- Confirmation modal for transfer to Own account -->
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import HeaderNavBiz from "../../../../components/HeaderNav/HeaderNavBiz";
import BusinessNavBar from "../../../../components/HeaderNav/BusinessNavBar";
import SideBar from "../../../../components/SideBar/SideBar_";
import { mapGetters, mapState } from "vuex";
import Loader from "../../../../components/Loader/Loader.vue";
import Keyboard from "../../../../components/Keyboard/Keyboard.vue";
//import { transferService } from "../../../../services/transfer.services.js";
import { CorporateAccountService } from "../../../../services/CorporateAccount/corporateAccountTransaction.services.js";
import Footer3 from "../../../../components/Footer/Footer3";

export default {
  name: "Confirmation.vue",
  components: {
    TheMask,
    HeaderNavBiz,
    BusinessNavBar,
    SideBar,
    Loader,
    Keyboard,
    Footer3,
  },
  data() {
    return {
      tabIndex: 0,
      password: "",
      nameState: null,
      submittedNames: [],
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      pin: "",
      loading: false,
      transaction_successful: false,
      confirm_transaction: false,
      confirm_transaction_own: false,
      Account: null,
      Beneficiary: null,
      BeneficiaryName: null,
      Narration: null,
      Amount: null,
      Type: null,
      // Pin:null,
      ScheduleName: null,
      Frequency: null,
      Start: null,
      End: null,
      schedule: [],
    };
  },

  computed: {
    ...mapGetters({ user: "GET_USER" }),

    ...mapState({
      AUTHENTICATION_TOKEN: (state) => state.User.AUTHENTICATION_TOKEN,
      TRANSFER_ACCOUNT: (state) => state.Transfer.TRANSFER_ACCOUNT,
      TRANSFER_ACCOUNT_FROM: (state) => state.Transfer.TRANSFER_ACCOUNT_FROM,
      TRANSFER_ACCOUNT_TO: (state) => state.Transfer.TRANSFER_ACCOUNT_TO,
      BENEFICIARY_ACCOUNT: (state) => state.Transfer.BENEFICIARY_ACCOUNT,
      BENEFICIARY_NAME: (state) => state.Transfer.BENEFICIARY_NAME,
      BENEFICIARY_BANK: (state) => state.Transfer.BENEFICIARY_BANK,
      TRANSFER_AMOUNT: (state) => state.Transfer.TRANSFER_AMOUNT,
      TRANSFER_TYPE: (state) => state.Transfer.TRANSFER_TYPE,
      TRANSFER_NOTE: (state) => state.Transfer.TRANSFER_NOTE,
      SCHEDULE_NAME: (state) => state.Transfer.SCHEDULE_NAME,
      FREQUENCY: (state) => state.Transfer.FREQUENCY,
      START: (state) => state.Transfer.START,
      END: (state) => state.Transfer.END,
      TYPE: (state) => state.Transfer.TYPE,
    }),

    routeQuery() {
      return this.$route.query.p;
    },

    disabledTab() {
      return ["disabled"];
    },

    Balance() {
      if (this.TRANSFER_ACCOUNT_FROM)
        return this.user.corporateAccounts[
          this.user.corporateAccounts.findIndex(
            (a) => a.accountNumber == this.TRANSFER_ACCOUNT_FROM
          )
        ].balance;
      return this.user.corporateAccounts[
        this.user.corporateAccounts.findIndex(
          (a) => a.accountNumber == this.TRANSFER_ACCOUNT
        )
      ].balance;
    },
    Name() {
      if (this.TRANSFER_ACCOUNT_FROM)
        return this.user.corporateAccounts[
          this.user.corporateAccounts.findIndex(
            (a) => a.accountNumber == this.TRANSFER_ACCOUNT_FROM
          )
        ].accountName;
      return this.user.corporateAccounts[
        this.user.corporateAccounts.findIndex(
          (a) => a.accountNumber == this.TRANSFER_ACCOUNT
        )
      ].accountName;
    },
  },

  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid ? "valid" : "invalid";
      return valid;
    },
    resetModal() {
      this.password = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs.modal.hide();
      });
    },
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },

    //Single Transfer to Hope Account
    transferHope() {
      this.loading = true;
      let data = {
        Account: this.TRANSFER_ACCOUNT,
        Beneficiary: this.BENEFICIARY_ACCOUNT,
        BeneficiaryName: this.BENEFICIARY_NAME,
        Narration: this.TRANSFER_NOTE,
        Amount: this.TRANSFER_AMOUNT,
        Save: this.SAVE_BENEFICIARY,
        Pin: this.pin,
        token: this.AUTHENTICATION_TOKEN,
      };
      CorporateAccountService.transferHope(data)
        .then((res) => {
          //window.console.log(res);
          this.$toastr.success(res.status, "Payment Successful", {
            timeOut: 5000,
          });
          this.transaction_successful = true;
          this.confirm_transaction = false;
          this.$store.dispatch(
            "AUTHORISE_USER",
            this.$store.getters.GET_AUTH_TOKEN
          );
          this.$router.push("/Dashboard_");
        })
        .catch((err) => {
          this.confirm_transaction = false;
          this.$toastr.error(err.status, "Transaction Failed!", {
            timeOut: 5000,
          });
          this.$router.push("/transfer?p=1");
        })
        .finally(() => {
          this.loading = false;
          this.pin = "";
          this.confirm_transaction = false;
          //this.$store.dispatch("CLEAR_INPUTS");
        });
    },

    //Single Transfer to Other Account
    transferOther() {
      this.loading = true;
      let data = {
        Account: this.TRANSFER_ACCOUNT,
        Beneficiary: this.BENEFICIARY_ACCOUNT,
        BeneficiaryName: this.BENEFICIARY_NAME,
        BeneficiaryBank: this.BENEFICIARY_BANK.value,
        Narration: this.TRANSFER_NOTE,
        Amount: this.TRANSFER_AMOUNT,
        Save: this.SAVE_BENEFICIARY,
        Pin: this.pin,
        token: this.AUTHENTICATION_TOKEN,
      };
      CorporateAccountService.transferOther(data)
        .then((res) => {
          //window.console.log(res);
          this.$toastr.success(res.statusDescription, "Payment Successful", {
            timeOut: 5000,
          });
          this.transaction_successful = true;
          this.confirm_transaction = false;
          this.$toastr.success(res.status, { timeOut: 5000 });
          this.$store.dispatch(
            "AUTHORISE_USER",
            this.$store.getters.GET_AUTH_TOKEN
          );
          this.$router.push("/Dashboard_");
        })
        .catch((err) => {
          this.confirm_transaction = false;
          this.$toastr.error(err.status, "Transaction Failed!", {
            timeOut: 5000,
          });
          this.$router.push("/transfer?p=1");
        })
        .finally(() => {
          this.loading = false;
          this.pin = "";
          this.confirm_transaction = false;
          this.$store.dispatch("CLEAR_INPUTS");
        });
    },
  },
  mounted() {
    if (this.routeQuery == 1) return (this.one = true);
    if (this.routeQuery == 2) return (this.two = true);
    if (this.routeQuery == 3) return (this.three = true);
    if (this.routeQuery == 4) return (this.four = true);
    if (this.routeQuery == 5) return (this.five = true);
  },
};
</script>

<style scoped>
/*body{*/
/*width: 100%;*/
/*height: 100vh;*/
/*background-image: url("../../assets/images/hope-bg3.jpg");*/
/*background-size: cover;*/
/*background-repeat: no-repeat;*/
/*}*/
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}
.quick-transfer {
  padding: 0 0.7rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: center;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #fecb32;
  border: 2px solid #fecb32;
}
.tabs-contents th {
  text-align: left;
  font-family: Montserrat-Medium;
  font-size: 12px;
  color: #000000;
  letter-spacing: 0.17px;
  background: #fecb32;
}
.confirm-amount {
  font-family: Montserrat-Medium;
  font-size: 15px;
  color: #4a4a4a;
  letter-spacing: 0;
  border-bottom: 1px solid #d0d0d0;
}
.confirm-amount span {
  font-family: Montserrat-Bold;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}

.disabledTab {
  pointer-events: none !important;
  background: red !important;
}

.nav-tabs {
  border: none !important;
  -ms-flex-pack: distribute;
  justify-content: flex-start !important;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
  margin-right: 1rem !important;
}
/* .poster {
  background-color: rgb(0, 94, 194, 0.1) !important;
}
.current_tab_title{
  background-image: linear-gradient(
    180deg,
    #00c4ff 0%,
    #0091ff 100%
  ) !important;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-image: linear-gradient(
    180deg,
    #00c4ff 0%,
    #0091ff 100%
  ) !important;
} */

@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
