import {
    ApiService
} from "./api.service";

const getTitlesService = {
    getTitle: async () => {
         return new Promise(function (resolve, reject) {
             ApiService.get('/api/web/catalog/titles').then((res) => {
                 let data = res.data;
                 resolve(data);
             }).catch((error) => {
                 reject(error.response.data);
             });
         });
       
    },

};



export {
    getTitlesService
};
