import { ApiService } from "./api.service"

const AgentLocatorService = {
    agentLocatorCenter: async () => {
        const licence = localStorage.getItem('licence') ? localStorage.getItem('licence') : '124114982AA640178097CEBDDFA0E050'
        return new Promise((resolve, reject) => {
            ApiService.customRequest({
                method: 'GET',
                url: ApiService.getBaseUrl() + "/api/web/catalog/agency-outlets",
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/catalog/agency-outlets', licence)
                },
            })
                .then((res) => {
                    let data = res.data;
                    resolve(data);
                })
                .catch(err => {
                    reject(err.response.data)
                })
        })

    },
    BranchLocator: async () => {
        const licence = localStorage.getItem('licence') ? localStorage.getItem('licence') : '124114982AA640178097CEBDDFA0E050'
        return new Promise((resolve, reject) => {
            ApiService.customRequest({
                method: 'GET',
                url: ApiService.getBaseUrl() + "/api/web/catalog/branch",
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/catalog/branch', licence)
                },
            })
                .then((res) => {
                    let data = res.data;
                    resolve(data);
                })
                .catch(err => {
                    reject(err.response.data)
                })
        })

    },
}
export {
    AgentLocatorService
};
