<template>
  <div class="vuertual-numeric-keyboard bg-light rounded border p-3">
    <button
      v-for="key in keys"
      :key="key"
      @click="press(key)"
      class="btn btn-lg shadow-none"
      :class="keyTheme"
    >{{ key }}</button>
    <button class="btn btn-lg shadow-none" :class="buttonTheme" @click="clear">&larr;</button>
    <button class="btn btn-lg shadow-none pin-input" @click="complete" :disabled="pinLength.toString().length < 6">OK</button>
    <!-- <button class="btn btn-lg shadow-none" :class="buttonTheme" @click="clear('all')">C</button> -->
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css';
//import _ from 'lodash';

export default {
  props: ['selfValue', 'pinLength'],
  data() {
    return {
      pin: '',
      keys: [...Array(10).keys()],
      keyTheme: 'btn-keyboard',
      buttonTheme: 'btn-danger',
    };
  },
  methods: {
    // shuffle() {
    //   this.keys = _.shuffle(this.keys);
    // },
    press(key) {
      if(this.pin.toString().length >= 8) return
      this.pin = `${this.pin}${key}`;
      //this.shuffle();
    },
    clear(type) {
      if (type === 'all') this.pin = '';
      else this.pin = this.pin.substring(0, this.pin.length - 1);
    },

    complete(){
      this.$emit('completed')
    }
  },
  watch: {
    pin() {
      this.$emit('pressed', this.pin);
    },
    selfValue() {
      this.pin = this.selfValue;
    },
  },
  created() {
    this.shuffle();
  },
};
</script>

<style scoped>
.vuertual-numeric-keyboard {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
}
.vuertual-numeric-keyboard .btn {
  font-weight: bold;
}
.btn-keyboard {
  background-color: #FECB32;
  color: #000000;
}
.pin-input{
    background: #FECB32;
    border-radius: 5px;
    font-family: Montserrat-Regular;
    /* font-size: 22px; */
    color: #000000;
    font-weight: 900;
    letter-spacing: 0;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .4s ease-in-out;
}
.pin-input:hover, .pin-input:focus .pin-input:active{
    opacity: 0.8;
}
</style>
