import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

const state = {
    ACCOUNT: {},
    ACCOUNT_OTP: "",
    ACCOUNT_TYPE: {},
    ACCOUNT_NUMBER: "",
    ACCOUNT_ID: "",
    ACCOUNT_PIN: "",
    ACCOUNT_CODE: {},
    BVN_DETAILS: {},
    COMPLETE_ONGOINIG_ACCOUNT_PROCESS: {},
    REGISTRATION_DETAILS: {},
    CORPORATE_ACCOUNT: {
        //Creation
        COMPANY_ID: "",
        COMPANY_NAME: "",
        DIRECTORS_NO: "",
        SIGNATORIES_NO: "",
        PARTIES_NO: "",
        DIRECTORS: [],
        CREATORS: [],
        IS_COMPANY_SEC: false,
        SIGNATORIES: [],
        MANDATES: [],
        SIGNATORIES_ID: [],

        //Authentication
        PHONE: "",
        ACCOUNT: ""
    }
}


export default { state, getters, mutations, actions }