import {
    ApiService
} from "./api.service";

const AccountTypeService = {
    accountTypeService: async () => {
        return new Promise(function (resolve, reject) {
            ApiService.get('/api/web/catalog/account-types').then((res) => {
                let data = res.data;
                window.console.log(data)
                resolve(data);
            }).catch((error) => {
                reject(error.response.data);
            });
        });

    },

    //Get account types when logged in
    accountTypeServiceWhenLoggedIn: async () => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'GET',
                url: ApiService.getBaseUrl() + "/api/web/catalog/account-types",
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/catalog/account-types', localStorage.getItem('licence'))
                },
            })
                .then((res) => {
                    let data = res.data
                    resolve(data);
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    }
}



export {AccountTypeService}
