<template>
  <div class="poster-bg">
    <div class="poster"></div>
    <div class="dashboard-page registration-page">
      <div class>
        <HeaderNavRegister />
        <div class="register_now">
          <div class="container">
            <div class="main_dashboard">
              <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                  <div class="main_dashboard_inner">
                    <div class="transfer_money2 text-center">
                      <p class="frequently-used text-center">Complete Account Creation</p>
                    </div>

                    <div class="current_tab_title mb-3">
                      <font-awesome-icon icon="play" class="play-icon" />
                      <p class>Complete your registration by uploading the your credentials</p>
                    </div>
                    <div class="kindly_enter_details mb-3">
                      <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                      <p>Password created would be used to login to your internet banking</p>
                    </div>

                    <div class="steps_to_acc_open">
                      <h2>Other Details</h2>
                    </div>

                    <form action>
                      <div class="form-table2">
                        <div class="label-title2">
                          <label>ID Card *</label>
                          <div class="account_select2">
                            <input type="file" class="input-amount" id="idCard" />
                          </div>
                        </div>
                        <div class="line3"></div>
                        <div class="label-title2">
                          <label>Utility Bill *</label>
                          <div class="account_select2">
                            <input type="file" class="input-amount" id="utilityBill" />
                          </div>
                        </div>
                        <div class="line3"></div>
                        <div class="label-title2">
                          <label>Signature *</label>
                          <div class="account_select2">
                            <input type="file" class="input-amount" id="signature" />
                          </div>
                        </div>
                        <div class="line3"></div>
                        <div class="label-title2">
                          <label>Passport Photo *</label>
                          <div class="account_select2">
                            <input type="file" class="input-amount" id="passport" />
                          </div>
                        </div>
                      </div>
                      <div class="send-or-download2">
                        <div class="continue-button">
                          <b-button v-b-modal.modal-center class="continue-btn">
                            <font-awesome-icon icon="angle-left" class="download-icon" />SKIP
                          </b-button>
                        </div>
                        <div class="continue-button">
                          <b-button v-b-modal.modal-center class="continue-btn">
                            Upload Documents
                            <font-awesome-icon icon="angle-right" class="angle-icon" />
                          </b-button>
                          <!-- <router-link to="Login"><button class="continue-btn">Upload Documents <font-awesome-icon icon="angle-right" class="angle-icon"/></button></router-link> -->
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
      <b-modal
        id="modal-center"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <img
              src="../../assets/images/icons/check.svg"
              alt="Hope Bank"
              class="modal_congrats_img"
            />
            <h1 class="pin-info text-center">Congratulations</h1>
            <div class="line4"></div>
            <!--<p class="modal_congrats">You have successfully opened an account. Click OK to Login</p>-->
            <p class="modal_congrats">Your upload was successful.</p>
            <b-button v-b-modal.modal-center2 class="ok-btn">OK</b-button>
            <!-- <router-link to="Login"><button class="ok-btn">OK</button></router-link> -->
          </b-form-group>
        </form>
      </b-modal>
      <b-modal
        id="modal-center2"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <router-link to="Signup">
              <button class="ok-btn">Proceed to Internet Banking Registration</button>
            </router-link>

            <router-link to="Login">
              <button class="modal_congrats2">Not Now</button>
            </router-link>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import HeaderNavRegister from "../../components/HeaderNav/HeaderNavRegister";
import Footer2 from "../../components/Footer/Footer2";
// import {TheMask} from 'vue-the-mask';

export default {
  name: "Complete.vue",
  components: {
    HeaderNavRegister,
    Footer2
    // TheMask,
  },
  data() {
    return {
      image: ""
    };
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.quick-transfer {
  padding: 0 2.2rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;

  align-items: center;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
  /* letter-spacing: 0.17px; */
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}
.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}
.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}

/*.plus_icon{*/
/*margin-right: 1rem;*/
/*font-size: 20px;*/
/*}*/
.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #ffffff !important;
}
.yellow_bg > .navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}
.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>