import Vue from "vue";
import Router from "vue-router";
import Store from "./store/store";
import Landing from "./views/Landing/Landing.vue";
import Agency from "./views/Agency/Agency.vue";
import about from "./views/About/About.vue";
import about2 from "./views/About/about2.vue";
import about3 from "./views/About/about3.vue";
import about4 from "./views/About/about4.vue";
import about5 from "./views/About/about5.vue";
import about6 from "./views/About/about6.vue";
import Contact from "./views/Contact/Contact.vue";
import LocateUs from "./views/Contact/LocateUs.vue";
import products from "./views/products/products.vue";
import howtotransact from "./views/products/howtotransact.vue";
//import nin from "./views/NIN/nin.vue";
import confirmnin from "./views/NIN/confirmnin.vue"
import confirmmynin from "./views/NIN/confirmmynin.vue"
import verifyid from "./views/NIN/verifyid.vue"
import enrolmentcenter from "./views/NIN/enrolmentcenter.vue"
import AgentLocator from "./views/Agency/AgentLocator.vue"
// import enrolmentcenters from "./views/NIN/enrolmentcenters.vue"
// Register/Login Account Section
import Login from "./views/Login/Login.vue";
import Start from "./views/Register/Start.vue";
//import Processing from "./views/Register/Processing.vue";
import Register from "./views/Register/Register.vue";
import Register_ from "./views/Register/Register_.vue";
import salarysavings from "./views/Register/salarysavings.vue";
import SalarysavingsComplete from "./views/Register/SalarySavingsComplete.vue";
import Verify from "./views/Register/Verify.vue";
import Verify_ from "./views/Register/Verify_.vue";
import Complete from "./views/Register/Complete.vue";
import digitalbanking from "./views/DigitalBanking/digitalbanking.vue";
import iBanking from "./views/DigitalBanking/iBanking.vue";
import Signup from "./views/DigitalBanking/Signup.vue";

import Validate from "./views/DigitalBanking/Validate.vue";
import SecurityQuestion from "./views/DigitalBanking/SecurityQuestion.vue";

// Personal/Individual Account Section
import Menu from "./views/Menu/Menu.vue";
import Dashboard from "./views/Accounts/Dashboard.vue";
import bulkwallet from "./views/Accounts/bulkwallet.vue";
import History from "./views/Accounts/History.vue";
import StatementGenerate from "./views/Accounts/StatementGenerate.vue";
import StatementGen2 from "./views/Accounts/StatementGen2.vue";
import StatementGen3 from "./views/Accounts/StatementGen3.vue";
import Receipt from "./views/Accounts/Receipt.vue";
import Receipt1 from "./views/Accounts/Receipt1.vue";
import HopeWallet3rdParty from './views/Accounts/HopeWallet3rdParty.vue'
import TransferMoney from "./views/Transfer/TransferMoney.vue";
import TransferLimit from "./views/Transfer/TransferLimit.vue";
import Confirmation from "./views/Transfer/Confirmation.vue";
import ManageSchedule from "./views/Transfer/ManageSchedule.vue";
import ManageSchedule2 from "./views/Transfer/ManageSchedule2.vue";
import AddBeneficiary from "./views/SelfService/AddBeneficiary.vue";
import ManageBeneficiary from "./views/SelfService/ManageBeneficiary.vue";
import UpdateInfo from "./views/SelfService/UpdateInfo.vue";
import Cheque from "./views/SelfService/Cheque.vue";
import Card from "./views/SelfService/Card.vue";
import BillPayment from "./views/Payments/BillPayment/BillPayment.vue";
import confirmbills from "./views/Payments/BillPayment/confirmbills.vue";
import receiptvas from "./views/Payments/BillPayment/receiptvas.vue";
import receiptvasAirtime from "./views/Payments/AirtimeData/receiptvas.vue";
import ResetPin from "./views/Security/ResetPin.vue";
import ChangePin from "./views/Security/ChangePin.vue";
import ChangePassword from "./views/Security/ChangePassword.vue";
import UpdateSecurityQuestion from "./views/Security/UpdateSecurityQuestion.vue";
import ResetPassword from "./views/Security/ResetPassword.vue";
import ValidateAirtimeData from "./views/Payments/AirtimeData/ValidateAirtimeData.vue";
import confirmairtime from "./views/Payments/AirtimeData/confirmairtime.vue";
import terms from "./views/Terms/terms.vue";
import terms1 from "./views/Terms/terms2.vue";
import PrivacyPolicy from './views/Terms/privacyPolicy.vue'
import setpin from "./views/Security/setpin.vue";
import pinsetup_ from "./views/Security/pinsetup_.vue";
import OngoingPersonalAccountProcess from './views/Register/OngoingPersonalAccountProcess.vue';
import VerifyOngoingAccountProcess from './views/Register/VerifyOngoingAccountProcess.vue';
import OngoingBusinessAccountProcess from './views/Business/Registration/completeBusinessAccountProcess/ongoingBusinessAccountProcess.vue';


// Business/Corporate Account Section
import LoginBusiness from "./views/Login/LoginBusiness.vue";
import business from "./views/Business/Registration/business.vue";
import business_ from "./views/Business/Registration/business_.vue";
import proceed from "./views/Business/Registration/proceed.vue";
import partnerinfo from "./views/Business/Registration/partnerinfo.vue";
import trusteeinfo from "./views/Business/Registration/trusteeinfo.vue";
import excoinfo from "./views/Business/Registration/excoinfo.vue";
import proprietorinfo from "./views/Business/Registration/proprietorinfo.vue";
import mandate from "./views/Business/Registration/mandate.vue";
import signatories from "./views/Business/Registration/signatories.vue";
import makers from "./views/Business/Registration/makers.vue";
import checkers from "./views/Business/Registration/checkers.vue";
import jointaccount from "./views/Business/Registration/jointaccount.vue";
import signupib from "./views/Business/IBanking/signupIb.vue";
import signupcib from "./views/Business/IBanking/signupcib.vue";
import pinsetup from "./views/Business/IBanking/pinsetup.vue";
import SecurityQuestions from "./views/Business/IBanking/SecurityQuestions.vue";
import SecurityQuestionMakerChecker from "./views/Business/IBanking/SecurityQuestionMakerChecker.vue";
import referee from "./views/Business/referee/referee.vue";
// import setup from "./views/InternetBanking/setup.vue";

// Business/Corporate Account Dashboard/Logged In Section
import menu_ from "./views/Business/dashboards/accounts/menu_.vue";
import dashboard_ from "./views/Business/dashboards/accounts/dashboard_.vue";
import createbulkaccounts from "./views/Business/dashboards/accounts/createbulkaccounts.vue";
import bulkwallets from "./views/Business/dashboards/accounts/bulkwallets.vue";
import pending from "./views/Business/dashboards/accounts/pending.vue";
import confirm from "./views/Business/dashboards/accounts/confirm.vue";
import receipt_ from "./views/Business/dashboards/accounts/receipt_.vue";
import transfer from "./views/Business/dashboards/transfer/transfer.vue";
import transferBulk from "./views/Business/dashboards/transfer/transferBulk.vue";
import transferlimit_ from "./views/Business/dashboards/transfer/transferlimit_.vue";
import confirmation_ from "./views/Business/dashboards/transfer/confirmation_.vue";
import manageschedule_ from "./views/Business/dashboards/transfer/manageschedule_.vue";
import manageschedule2_ from "./views/Business/dashboards/transfer/manageschedule2_.vue";
import managebeneficiary_ from "./views/Business/dashboards/transfer/managebeneficiary_.vue";
import bulkpayment from "./views/Business/dashboards/payments/bulkpayment.vue";
import billpayment_ from "./views/Business/dashboards/payments/billpayment_.vue";
import validateAirtimeData_ from "./views/Business/dashboards/payments/validateAirtimeData_.vue";
import updateinfo_ from "./views/Business/dashboards/settings/updateinfo_.vue";
import resetpin_ from "./views/Business/dashboards/settings/resetpin_.vue";
import setpin_ from "./views/Business/dashboards/settings/setpin_.vue";
import changepin_ from "./views/Business/dashboards/settings/changepin_.vue";
import changepassword_ from "./views/Business/dashboards/settings/changepassword_.vue";
import updateSecurityQuestion_ from "./views/Business/dashboards/settings/updateSecurityQuestion_.vue";

import history_ from "./views/Business/dashboards/services/history_.vue";
import statementgenerate_ from "./views/Business/dashboards/services/statementgenerate_.vue";
import statementgen2_ from "./views/Business/dashboards/services/statementgen2_.vue";
import statementgen3_ from "./views/Business/dashboards/services/statementgen3_.vue";


Vue.use(Router);

const router = new Router({
    routes: [{
            path: "/",
            name: "landing",
            component: Landing,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
                // transition: "fade-in-up",
            },
        },
        {
            path: "/Agency",
            name: "agency",
            component: Agency,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        // {
        //   path: "/Agency/:id",
        //   name: "agency",
        //   component: Agency,
        //   meta: {
        //     public: true,
        //     onlyWhenLoggedOut: true,
        //   },
        // },
        {
            path: "/about",
            name: "about",
            component: about,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
                title: "About Us",
            },
        },
        {
            path: "/about2",
            component: about2,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
                title: "About Us",
            },
        },
        {
            path: "/about3",
            component: about3,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
                title: "About Us",
            },
        },
        {
            path: "/about4",
            component: about4,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
                title: "About Us",
            },
        },
        {
            path: "/about5",
            component: about5,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
                title: "About Us",
            },
        },
        {
            path: "/about6",
            component: about6,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
                title: "About Us",
            },
        },
        {
            path: "/Contact",
            name: "contact",
            component: Contact,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/LocateUs",
            name: "LocateUs",
            component: LocateUs,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },

        {
            path: "/products",
            name: "Products",
            component: products,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/howtotransact",
            name: "howtotransact",
            component: howtotransact,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },

     /*    {
            path: "/nin",
            name: "nin",
            component: nin,
            meta: {
                public: true, 
                onlyWhenLoggedOut: true,
            },
        }, */
        {
            path: "/confirmnin",
            name: "confirmnin",
            component: confirmnin,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/confirmmynin",
            name: "confirmmynin",
            component: confirmmynin,
            // meta: {
            //   public: true, 
            //   onlyWhenLoggedOut: true,
            // },
        },
        {
            path: "/verifyid",
            name: "verifyid",
            component: verifyid,
            // meta: {
            //   public: true, 
            //   onlyWhenLoggedOut: true,
            // },
        },
        {
            path: "/enrolmentcenter",
            name: "enrolmentcenter",
            component: enrolmentcenter,
            meta: {
                public: true,
                //  onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/agentlocation",
            name: "agentlocation",
            component: AgentLocator,
            meta: {
                public: true,
                //  onlyWhenLoggedOut: true,
            },
        },
        // {
        //   path: "/enrolmentcenters",
        //   name: "enrolmentcenters",
        //   component: enrolmentcenters,
        //   meta: {
        //     public: true,
        //     onlyWhenLoggedOut: true,
        //   },
        // },
        // {
        //   path: "/products/:id",
        //   name: "Products",
        //   component: products,
        //   meta: {
        //     public: true, 
        //     onlyWhenLoggedOut: true,
        //   },
        // },

        {
            path: "/Login",
            name: "login",
            component: Login,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },

        {
            path: "/Start",
            name: "Start",
            component: Start,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        /* {
            path: "/Processing",
            name: "processing",
            component: Processing,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        }, */
        {
            path: "/Register",
            name: "register",
            component: Register,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/Register_",
            name: "register_",
            component: Register_,
        },
        {
            path: "/OngoingPersonalAccount",
            name: "OngoingPersonalAccount",
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
            component: OngoingPersonalAccountProcess,
        },
        {
            path: "/OngoingBusinessAccount",
            name: "OngoingBusinessAccount",
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
            component: OngoingBusinessAccountProcess,
        },


        {
            path: "/VerifyOngoingAccount",
            name: "VerifyOngoingAccount",
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
            component: VerifyOngoingAccountProcess,
        },
        {
            path: "/salarysavings",
            name: "salarysavings",
            component: salarysavings,
        },
        {
            path: "/salarysavings/complete",
            name: "salarysavings/complete",
            component: SalarysavingsComplete,
        },

        {
            path: "/Verify",
            name: "verify",
            component: Verify,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/Verify_",
            name: "verify_",
            component: Verify_,
        },
        {
            path: "/Complete",
            name: "complete",
            component: Complete,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },

        {
            path: "/digitalbanking",
            name: "digitalbanking",
            component: digitalbanking,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/iBanking",
            name: "ibanking",
            component: iBanking,
        },
        {
            path: "/Signup",
            name: "signup",
            component: Signup,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },

        {
            path: "/Validate",
            name: "validate",
            component: Validate,
        },
        {
            path: "/SecurityQuestion",
            name: "securityquestion",
            component: SecurityQuestion,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },

        {
            path: "/Menu",
            name: "menu",
            component: Menu,
        },
        {
            path: "/Dashboard",
            name: "Dashboard",
            component: Dashboard,
        },
        {
            path: "/History",
            name: "History",
            component: History,
        },
        {
            path: "/StatementGenerate",
            name: "StatementGenerate",
            component: StatementGenerate,
        },
        {
            path: "/StatementGen2",
            name: "StatementGen2",
            component: StatementGen2,
        },
        {
            path: "/StatementGen3",
            name: "StatementGen3",
            component: StatementGen3,
        },
        {
            path: "/Receipt",
            name: "Receipt",
            component: Receipt,
        },
        {
            path: "/Transaction_Receipt",
            name: "Transaction_Receipt",
            component: Receipt1,
        },
        {
            path: "/TransferMoney",
            name: "transfermoney",
            component: TransferMoney,
        },
        {
            path: "/TransferLimit",
            name: "transferlimit",
            component: TransferLimit,
        },
        {
            path: "/Confirmation",
            name: "confirmation",
            component: Confirmation,
        },
        {
            path: "/ManageSchedule",
            name: "manageschedule",
            component: ManageSchedule,
        },
        {
            path: "/confirmSchedule",
            name: "confirmschedule",
            component: ManageSchedule2,
        },
        {
            path: "/ManageBeneficiary",
            name: "managebeneficiary",
            component: ManageBeneficiary,
        },
        {
            path: "/AddBeneficiary",
            name: "addbeneficiary",
            component: AddBeneficiary,
        },
        {
            path: "/UpdateInfo",
            name: "updateinfo",
            component: UpdateInfo,
        },
        {
            path: "/Cheque",
            name: "cheque",
            component: Cheque,
        },
        {
            path: "/Card",
            name: "card",
            component: Card,
        },
        {
            path: "/HopeWalletFor3rdParty",
            name: "hopewallet3rdparty",
            component: HopeWallet3rdParty,
        },
        {
            path: "/AirtimeData",
            name: "airtimedata",
            component: ValidateAirtimeData,
        },
        {
            path: "/confirmairtime",
            name: "confirmairtime",
            component: confirmairtime,
        },
        {
            path: "/BillPayment",
            name: "billpayment",
            component: BillPayment,
        },
        {
            path: "/confirmbills",
            name: "confirmbills",
            component: confirmbills,
        },
        {
            path: "/receiptvas",
            name: "receiptvas",
            component: receiptvas,
        },
        {
            path: "/receiptvasairtime",
            name: "receiptvasAirtime",
            component: receiptvasAirtime,
        },

        {
            path: "/ResetPin",
            name: "ResetPin",
            component: ResetPin,
        },
        {
            path: "/ChangePin",
            name: "ChangePin",
            component: ChangePin,
        },
        {
            path: "/ChangePassword",
            name: "ChangePassword",
            component: ChangePassword,
        },
        {
            path: "/UpdateSecurityQuestion",
            name: "/UpdateSecurityQuestion",
            component: UpdateSecurityQuestion,
        },
        {
            path: "/ResetPassword",
            name: "/ResetPassword",
            component: ResetPassword,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/Terms",
            name: "/terms",
            component: terms,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/PrivacyPolicy",
            name: "/PrivacyPolicy",
            component: PrivacyPolicy,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/Terms_",
            name: "/terms_",
            component: terms1,
        },
        {
            path: "/setpin",
            name: "/setpin",
            component: setpin,
        },
        {
            path: "/pinsetup_",
            name: "/pinsetup_",
            component: pinsetup_,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        // Business/Corporate Account Section
        {
            path: "/LoginBusiness",
            name: "LoginBusiness",
            component: LoginBusiness,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/Business",
            name: "business",
            component: business,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/business_",
            name: "business_",
            component: business_,
        },
        {
            path: "/Proceed",
            name: "proceed",
            component: proceed,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },

        {
            path: "/partnerinfo",
            name: "partnerinfo",
            component: partnerinfo,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/trusteeinfo",
            name: "trusteeinfo",
            component: trusteeinfo,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/excoinfo",
            name: "excoinfo",
            component: excoinfo,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/proprietorinfo",
            name: "proprietorinfo",
            component: proprietorinfo,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },

        {
            path: "/mandate",
            name: "mandate",
            component: mandate,
            meta: {
                public: true, // Allow access to even if not logged in
                // onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/signatories",
            name: "signatories",
            component: signatories,
            meta: {
                public: true, // Allow access to even if not logged in
                // onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/makers",
            name: "makers",
            component: makers,
            // meta: {
            //   public: true, // Allow access to even if not logged in
            //   onlyWhenLoggedOut: true,
            // },
        },
        {
            path: "/checkers",
            name: "checkers",
            component: checkers,
            // meta: {
            //   public: true, // Allow access to even if not logged in
            //   onlyWhenLoggedOut: true,
            // },
        },
        {
            path: "/jointaccount",
            name: "jointaccount",
            component: jointaccount,
            meta: {
                public: true, // Allow access to even if not logged in
                // onlyWhenLoggedOut: true,
            },
        },

        {
            path: "/corporate/referee",
            name: "corporate/referee",
            component: referee,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/corporate/register",
            name: "corporate/register",
            component: signupib,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/corporate/signup",
            name: "corporate/signup",
            component: signupcib,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/corporate/pinsetup",
            name: "corporate/pinsetup",
            component: pinsetup,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/SecurityQuestions",
            name: "securityquestions",
            component: SecurityQuestions,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        {
            path: "/SecurityQuestionMakerChecker",
            name: "SecurityQuestionMakerChecker",
            component: SecurityQuestionMakerChecker,
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
            },
        },
        // {
        //   path: "/setup",
        //   name: "setup",
        //   component: setup,
        //   meta: {
        //     public: true,
        //     onlyWhenLoggedOut: true,
        //   },
        // },
        // Business Dashboard loggedIn
        {
            path: "/menu_",
            name: "menu_",
            component: menu_,
        },
        {
            path: "/Dashboard_",
            name: "Dashboard_",
            component: dashboard_,
        },
        {
            path: "/createbulkaccounts",
            name: "createbulkaccounts",
            component: createbulkaccounts,
        },
        {
            path: "/bulkwallet",
            name: "bulkwallet",
            component: bulkwallet,
        },
        {
            path: "/bulkwallets",
            name: "bulkwallets",
            component: bulkwallets,
        },
        {
            path: "/history_",
            name: "history_",
            component: history_,
        },
        {
            path: "/pending",
            name: "pending",
            component: pending,
        },
        {
            path: "/confirm",
            name: "confirm",
            component: confirm,
        },
        {
            path: "/statementgenerate_",
            name: "statementgenerate_",
            component: statementgenerate_,
        },
        {
            path: "/statementgen2_",
            name: "statementgen2_",
            component: statementgen2_,
        },
        {
            path: "/statementgen3_",
            name: "statementgen3_",
            component: statementgen3_,
        },
        {
            path: "/receipt_",
            name: "receipt_",
            component: receipt_,
        },
        {
            path: "/transfer",
            name: "transfer",
            component: transfer,
        },
        {
            path: "/transferBulk",
            name: "transferBulk",
            component: transferBulk,
        },
        {
            path: "/transferlimit_",
            name: "transferlimit_",
            component: transferlimit_,
        },
        {
            path: "/confirmation_",
            name: "confirmation_",
            component: confirmation_,
        },
        {
            path: "/manageschedule_",
            name: "manageschedule_",
            component: manageschedule_,
        },
        {
            path: "/manageschedule2_",
            name: "manageschedule2_",
            component: manageschedule2_,
        },
        {
            path: "/managebeneficiary_",
            name: "managebeneficiary_",
            component: managebeneficiary_,
        },
        {
            path: "/updateinfo_",
            name: "updateinfo_",
            component: updateinfo_,
        },
        {
            path: "/airtimedata_",
            name: "airtimedata_",
            component: validateAirtimeData_,
        },
        {
            path: "/bulkpayment",
            name: "bulkpayment",
            component: bulkpayment,
        },
        {
            path: "/billpayment_",
            name: "billpayment_",
            component: billpayment_,
        },
        {
            path: "/setpin_",
            name: "setpin_",
            component: setpin_,
        },
        {
            path: "/resetpin_",
            name: "resetpin_",
            component: resetpin_,
        },
        {
            path: "/changepin_",
            name: "changepin_",
            component: changepin_,
        },
        {
            path: "/changepassword_",
            name: "changepassword_",
            component: changepassword_,
        },
        {
            path: "/UpdateSecurityQuestion_",
            name: "/UpdateSecurityQuestion",
            component: updateSecurityQuestion_,
        },

        // Unregistered Business Account

    ],
    // Build smooth transition logic with $
    // scrollBehavior: function(to, from, savedPosition) {
    //   if (to.hash) {
    //     return { selector: to.hash };
    //   } else {
    //     return { x: 0, y: 0 };
    //   }
    // },
    mode: "history",
});

router.beforeEach((to, from, next) => {
    window.console.log("FroM Router", from)
    const isPublic = to.matched.some((record) => record.meta.public);
    const onlyWhenLoggedOut = to.matched.some(
        (record) => record.meta.onlyWhenLoggedOut
    );
    const loggedIn = Store.getters.IS_AUTHENTICATED;
    const loggedInBusiness = Store.state.User.LOGGED_IN_BUSINESS;

    if (!isPublic && !loggedIn) {
        if (to.name === "confirmmynin") {
            return next({
                path: "/login?path=confirmmynin",
            });
        }
        if (to.name === "verifyid") {
            return next({
                path: "/login?path=verifyid",
            });
        }
        return next({
            name: "login",
        });
    }

    // Do not allow user to visit login page or register page if they are logged in
    if (loggedIn && onlyWhenLoggedOut) {
        if (loggedInBusiness) {
            return next({
                name: "Dashboard_",
            });
        }
        return next({
            name: "Dashboard",
        });
    }

    next();
});

export default router;