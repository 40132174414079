<template>
  <div class>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNavBiz />
      <div class="container">
        <BusinessNavBar />
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <img src="../../../../assets/images/icons/transfer-sm.svg" alt="Hope Bank" />
                  <p class="frequently-used">Bulk Transfer</p>
                </div>
                <div class="cib-color-change">
                  <b-tabs no-body>
                    <b-tab :active="two" id="HopeTransfer" @click="hopeTab">
                      <template v-slot:title class="left">
                        To
                        <br />
                        <strong>Hope PS Bank</strong>
                        <!--<b-spinner type="grow" small></b-spinner> Tab <strong>2</strong>-->
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Transfer Funds to Hope PS Bank Account</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>
                            Ensure that file conforms to standard template and
                            all information are provided
                          </p>
                        </div>
                        <form action>
                          <div class="form-table">
                            <div class="label-title">
                              <label>Transfer From</label>
                              <div class="account_select">
                                <select v-model="transferAccount" name id class="account_type">
                                  <option v-for="item in user.corporateAccounts" :key="item + 'a'"
                                    :value="item.accountNumber">
                                    {{ item.accountName }} -
                                    {{ item.accountNumber }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>

                            <div class="label-title2">
                              <label>Click to Upload File (.xls, .xlsx)</label>
                              <div class="account_select5">
                                <vuebase64 accept=".xls, .xlsx" @size-exceeded="onSizeExceeded" @file="data = $event" />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Description</label>
                              <div class="account_select2">
                                <input type="text" v-model="transferNote" class="input-amount"
                                  placeholder="Say something about the transaction" />
                              </div>
                            </div>

                            <div class="kindly_enter_details2 text-left">
                              Click
                              <!-- <a
                                class="sample-download"
                                href="../../../../assets/sample/sample_bulk_transfer_transactions.xls"
                                download
                              > -->
                              <a class="sample-download"
                                href="https://hopepsbank.com/xls/sample_bulk_transfer_transactions.xls" download>
                                <span style="color: blue; font-weight: 800" class="ml-1 mr-1">here</span>
                              </a>
                              to download sample template
                            </div>

                            <div class="send-or-download2">
                              <div class="continue-button">
                                <a @click="$router.go(-1)">
                                  <div class="continue-cib-btn">
                                    <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                                  </div>
                                </a>
                              </div>
                              <router-link to="#">
                                <div class="continue-button">
                                  <button class="continue-cib-btn" @click="validateTransfer">
                                    SUBMIT
                                    <font-awesome-icon icon="angle-right" class="angle-icon" />
                                  </button>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </b-tab>
                    <b-tab :active="three" id="OtherBank" @click="hopeTab">
                      <template v-slot:title>
                        To
                        <br />
                        <strong>Other Bank</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Transfer Funds to Other Account</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Kindly enter Transfer Details</p>
                        </div>
                        <form action>
                          <div class="form-table2">
                            <div class="label-title">
                              <label>Transfer From</label>
                              <div class="account_select">
                                <select v-model="transferAccount" name id class="account_type">
                                  <option v-for="item in user.corporateAccounts" :key="item + 'a'"
                                    :value="item.accountNumber">
                                    {{ item.accountName }} -
                                    {{ item.accountNumber }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>

                            <div class="label-title2">
                              <label>Click to Upload File (.xls, .xlsx)</label>
                              <div class="account_select5">
                                <vuebase64 accept=".xls, .xlsx" @size-exceeded="onSizeExceeded" @file="data = $event" />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Description</label>
                              <div class="account_select2">
                                <input type="text" v-model="transferNote" class="input-amount"
                                  placeholder="Say something about the transaction" />
                              </div>
                            </div>
                            <div class="kindly_enter_details2 text-left">
                              Click
                              <!-- <a
                                class="sample-download"
                                href="../../../../assets/sample/sample_bulk_transfer_transactions.xls"
                                download
                              > -->
                              <a class="sample-download"
                                href="https://hopepsbank.com/xls/sample_bulk_transfer_transactions.xls" download>
                                <span style="color: blue; font-weight: 800" class="ml-1 mr-1">here</span>
                              </a>
                              to download sample template
                            </div>
                          </div>

                          <div class="send-or-download2">
                            <div class="continue-button">
                              <a @click="$router.go(-1)">
                                <div class="continue-cib-btn">
                                  <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                                </div>
                              </a>
                            </div>
                            <!-- <router-link to="#"> -->
                            <div class="continue-button">
                              <button class="continue-cib-btn" @click="validateTransfer">
                                SUBMIT
                                <font-awesome-icon icon="angle-right" class="angle-icon" />
                              </button>
                            </div>
                            <!-- </router-link> -->
                          </div>
                        </form>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
                <!-- <b-button v-b-modal.modal-center-validated>Launch centered modal</b-button> -->
              </div>
            </div>
            <div class="col-md-4">
              <SideBar v-if="transferAccount" :accountNumber="transferAccount" :accountBalance="Balance"
                :accountName="Name">
              </SideBar>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>
    </div>
    <!-- Confirmation Modal for Transfer to Hope, OtherAccount and Schedule transfer to hope account -->
    <b-modal id="modal-center" centered title="Confirm Transactions" v-model="confirm_transaction" @show="resetModal"
      @hidden="resetModal" @ok="handleOk" hide-footer>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
          <!-- <p class="confirm-amount">
            You are about to transfer
            <span>
              <span>&#8358;</span>
              {{ TRANSFER_AMOUNT }}
            </span>
            from {{ TRANSFER_ACCOUNT }} to
            <span>{{ BENEFICIARY_NAME }}</span>
            - {{ BENEFICIARY_ACCOUNT }}
          </p>-->
          <h1 class="pin-info text-center">Enter PIN</h1>
          <div class="dialpad">
            <div class="form-group">
              <the-mask type="password" class="form-control" v-model="pin" mask="########" id="txtBox"
                placeholder="******" />
            </div>
            <Keyboard @pressed="pin = $event" :pinLength="pin" @completed="initiateTransfer" :selfValue="pin">
            </Keyboard>
          </div>
        </b-form-group>
      </form>
    </b-modal>
    <!-- Bulk Validation Page starts here -->
    <BulkValidate :onClick="downloadFile" :onSubmit="submitValidatedFile" />
    <!-- Bulk Validation Page ends here -->
  </div>
</template>

<script>
import { ApiService } from "../../../../services/api.service";
import { TheMask } from "vue-the-mask";
import HeaderNavBiz from "../../../../components/HeaderNav/HeaderNavBiz";
import BusinessNavBar from "../../../../components/HeaderNav/BusinessNavBar";
import SideBar from "../../../../components/SideBar/SideBar_";
import { mapGetters, mapState } from "vuex";
import Loader from "../../../../components/Loader/Loader.vue";
import { CorporateAccountService } from "../../../../services/CorporateAccount/corporateAccountTransaction.services.js";
import Keyboard from "../../../../components/Keyboard/Keyboard.vue";
import Footer3 from "../../../../components/Footer/Footer3";
import vuebase64 from "../../../../components/vue-base64/vuebase64.vue";
import BulkValidate from "../../../../components/BulkValidate/BulkValidate";
import axios from "axios";
//import { getInstitutionService } from "../../../../services/getInstitution.services.js";

export default {
  name: "Transfer",
  components: {
    TheMask,
    HeaderNavBiz,
    BusinessNavBar,
    SideBar,
    Loader,
    Keyboard,
    Footer3,
    vuebase64,
    BulkValidate,
  },
  data() {
    return {
      oneoff: true,
      start_date: null,
      end_date: null,
      loading: false,
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      Type: "",
      data: "",
      pin: "",
      id: "",
      confirm_transaction: false,
      location: "",
    };
  },
  computed: {
    ...mapGetters({
      TRANSACTION_DETAILS: "GET_TRANSACTION",
      user: "GET_USER",
      filteredUserAccount: "GET_FILTERED_USER",
    }),

    ...mapState({
      token: (state) => state.User.AUTHENTICATION_TOKEN,
      TRANSFER_ACCOUNT: (state) => state.Transfer.TRANSFER_ACCOUNT,
      TRANSFER_ACCOUNT_FROM: (state) => state.Transfer.TRANSFER_ACCOUNT_FROM,
    }),

    Balance() {
      if (this.transferAccountFrom)
        return this.user.corporateAccounts[
          this.user.corporateAccounts.findIndex(
            (a) => a.accountNumber == this.transferAccountFrom
          )
        ].balance;
      return this.user.corporateAccounts[
        this.user.corporateAccounts.findIndex(
          (a) => a.accountNumber == this.transferAccount
        )
      ].balance;
    },
    Name() {
      if (this.transferAccountFrom)
        return this.user.corporateAccounts[
          this.user.corporateAccounts.findIndex(
            (a) => a.accountNumber == this.transferAccountFrom
          )
        ].accountName;
      return this.user.corporateAccounts[
        this.user.corporateAccounts.findIndex(
          (a) => a.accountNumber == this.transferAccount
        )
      ].accountName;
    },
    transferAccount: {
      get() {
        return this.TRANSFER_ACCOUNT;
      },
      set(value) {
        this.$store.commit("SET_TRANSFER_ACCOUNT", value);
      },
    },
    transferAccountFrom: {
      get() {
        return this.TRANSFER_ACCOUNT_FROM;
      },
      set(value) {
        this.$store.commit("SET_TRANSFER_ACCOUNT_FROM", value);
      },
    },
    // Dynamic Corporate id
    Corporate() {
      let account = this.user.corporateAccounts;
      return account[
        account.findIndex((a) => a.accountNumber == this.transferAccount)
      ].customerId;
    },
    routeQuery() {
      return this.$route.query.p;
    },
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },
    onSizeExceeded() {
      this.$toastr.error(`Maximum file size exceeded`, "Error");
    },

    ownTab() {
      this.transferAccount = "";
      this.$store.dispatch("CLEAR_INPUTS");
      this.$store.commit(
        "SET_TRANSFER_ACCOUNT_FROM",
        this.user.corporateAccounts[0].accountNumber
      );
    },

    hopeTab() {
      this.$store.commit(
        "SET_TRANSFER_ACCOUNT",
        this.user.corporateAccounts[0].accountNumber
      );
    },

    downloadFile() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Hash: ApiService.computeHash(
            "/api/web/corporate/docs/pending",
            localStorage.getItem("licence")
          ),
        },
        responseType: "blob",
      };

      axios
        .get(this.location, config)
        .then((result) => {
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.$toastr.success("success", "Successful", {
            timeOut: 5000,
          });
        })
        .catch((err) => {
          window.console.log(err);
          this.$toastr.error(err.status, "Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    submitValidatedFile() {
      this.confirm_transaction = true;
      this.$bvModal.hide("modal-center-validated");
    },

    async validateTransfer() {
      this.loading = true;
      const data = {
        Account: this.transferAccount,
        Corporate: this.Corporate,
        data: this.data,
        token: this.token,
      };
      CorporateAccountService.bulkTransferValidate(data)
        .then((res) => {
          // this.confirm_transaction = true;
          this.$bvModal.show("modal-center-validated");
          this.location = res.location;
          this.id = res.id;
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async initiateTransfer() {
      this.loading = true;
      this.confirm_transaction = false;
      const data = {
        Account: this.transferAccount,
        Corporate: this.Corporate,
        Id: this.id,
        Pin: this.pin,
        token: this.token,
      };
      CorporateAccountService.bulkTransferInitiate(data)
        .then((res) => {
          this.$toastr.success(res.status, "Successful", {
            timeOut: 5000,
          });
          this.$router.push("/Dashboard_");
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.hopeTab();
    if (this.routeQuery == 1) {
      this.transferAccount = "";
      this.one = true;
      // this.$store.commit('SET_TRANSFER_ACCOUNT_FROM', this.user.accounts[0].accountNumber)
      return;
    }
    if (this.routeQuery == 2) {
      this.transferAccountFrom = "";
      this.two = true;
      // this.$store.commit('SET_TRANSFER_ACCOUNT', this.user.accounts[0].accountNumber)
      return;
    }
    if (this.routeQuery == 3) {
      this.transferAccountFrom = "";
      this.three = true;
      // this.$store.commit('SET_TRANSFER_ACCOUNT', this.user.accounts[0].accountNumber)
      return;
    }
  },
  // created() {
  //   if (this.transferAccountFrom) return;
  //   this.$store.commit(
  //     "SET_TRANSFER_ACCOUNT_FROM",
  //     this.user.corporateAccounts[0].accountNumber
  //   );
  // }
};
</script>

<style scoped>
body {
  background: #000000;
}

a {
  text-decoration: none;
  color: #000000;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}

.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}

.quick-transfer {
  padding: 0 0.7rem;
}

.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}

/* .poster {
  background-color: rgb(0, 94, 194, 0.1) !important;
}
.current_tab_title.current_tab_title {
  background-image: linear-gradient(
    180deg,
    #00c4ff 0%,
    #0091ff 100%
  ) !important;
} */

@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
