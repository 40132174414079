import {
    ApiService
} from "./api.service";

const VasPaymentService = {
    validateVaspaymentService: async ({ ProductId, Beneficiary, Amount, token
    }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/transaction/vas-validate', localStorage.getItem("licence"))


                },
                data: {
                    ProductId,
                    Beneficiary,
                    Amount,

                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/transaction/vas-validate"


            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    },
    paymentService: async ({ Account, ProductId, Beneficiary, Amount, Pin, Fee, token }) => {
        window.console.log(ApiService.computeHash('/api/web/transaction/vas-process', localStorage.getItem("licence")))
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/transaction/vas-process', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    ProductId,
                    Beneficiary,
                    Amount,
                    Pin,
                    Fee

                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/transaction/vas-process"

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    },

};



export {
    VasPaymentService
};
