<template>
  <div class="main_dashboard_inner2">
    <Loader v-if="loading" :show-full="true" loading-text="please wait.." />

    <div class="account-balance">
      <p>Account Balance:</p>
      <!-- <h3 ><span>&#8358;</span>{{user.accounts[0].balance}}</h3> -->
      <h3 v-if="accountBalance  != undefined">
        <span>&#8358;</span>
        {{formatAmount(accountBalance)}}
      </h3>
      <h4 v-else>NOT AUTHORIZED</h4>
    </div>
    <div class="profile-account">
      <div class="row">
        <div class="col-md-4">
          <div class="profile-image">
            <img src="../../assets/images/avatar-icon.svg" alt="Profile Picture" />
          </div>
        </div>
        <div class="col-md-8">
          <div class="profile-info">
            <h4>{{user.fullName}}</h4>
            <p>Account Number:</p>
            <!-- <p><b>{{user.accounts[0].accountNumber}}</b></p> -->
            <p>
              <b>{{accountNumber}}</b>
            </p>
            <p>BVN:</p>
            <p>
              <b>{{BVN}}</b>
            </p>
          </div>
        </div>
      </div>
      <div class="last-login">
        <div>
          <img src="../../assets/images/icons/time.svg" alt="Hope Bank" />
        </div>
        <div class="last-login-time">
          <p>Last Login</p>
          <p>
            <b>{{user.lastLogin}}</b>
          </p>
        </div>
      </div>
    </div>
    <div class="recent-transactions">
      <h4>Recent Transactions</h4>
      <table>
        <tr>
          <th>Date</th>
          <th>Description</th>
          <th>Amount</th>
        </tr>
        <tr v-for="(trans, key) in transHistory.slice(0, 5)" :key="key">
          <td>{{trans.date}}</td>
          <td>{{trans['transactionType']}}</td>
          <td> 			&#8358; {{formatAmount(trans.amount)}}</td>
        </tr>
      </table>
      <!-- <router-link to="history_">
        <div class="history-cib-button">
          <button class="history-btn">All History</button>
          <div class="angle-right-cib">
            <font-awesome-icon icon="angle-right" class="angleright" />
          </div>
        </div>
      </router-link> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { CorporateAccountService } from "../../services/CorporateAccount/corporateAccountTransaction.services.js";

import Loader from "../../components/Loader/Loader.vue";

export default {
  data() {
    return {
      transHistory: [],
      loading: true,
    };
  },
  components: {
    Loader: Loader,
  },

  props: {
    accountNumber: String,
    accountName: {
      type: String,
    },
    accountBalance: String,
  },

  computed: {
    ...mapGetters({ user: "GET_USER" }),
    ...mapState({
      BVN: (state) => state.User.BVN,
    }),
  },
  mounted() {
    this.getTransactionHistory();
  },

  methods: {
    async getTransactionHistory() {
      await CorporateAccountService.transHistory()
        .then((res) => {
          this.loading = false;
          this.transHistory = res;
        })
        .catch((err) => window.console.log(err));
    },
    // formatAmount(value) {
    //   if (value == undefined) return "Not Authorized";
    //   return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    // },
    formatAmount(value) {
        let val = (value/1).toFixed(2).replace('.', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}
/* .profile-image {
  border-radius: 50%;
  text-align: center;
  padding: 1rem;
}
.profile-image img {
  border-radius: 50%;
  width: 93px;
  height: 93px;
  border: 6px solid rgba(50, 138, 254, 0.48);
} */
</style>