<template>
    <div>
        <b-modal id="modal-center-bulkwallet-success" v-model="modalState" centered size="md" title="Upload Validation"
            hide-footer ref="myModal">
            <div class="bulk-validated-page text-center">
                <div class="d-flex justify-content-center mb-3">
                    <button class="continue-btn" @click.prevent="onClick">Download Validated Filme</button>
                </div>
                <div class="d-flex justify-content-center">
                    <p>Checker have been notified to authorize Bulk Wallet Creation.</p>
                </div>
                <div class="cancel_submit_buttons">
                    <botton class="continue-btn mt-2" @click="hideModal">Cancel</botton>
                    <button @click.prevent="onSubmit" class="continue-btn mt-2">Submit</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
// import vuebase64 from "../../components/vue-base64/vuebase64.vue";

export default {

    props: {
        onClick: Function,
        onSubmit: Function,
        number: Number
    },
    components: {
        // vuebase64,
    },
    methods: {
        hideModal() {
            this.$refs["myModal"].hide();
        },
        hideModal2() {
            this.$refs["myModal2"].hide();
        }
    }

}
</script>

<style scoped>
.cancel_submit_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>