import { ApiService } from "./api.service";

const transferService = {
    //validate beneficiary account Number
    validateBeneficiaryAccount: async ({ Account, Code, token }) => {
        return new Promise((resolve, reject) => {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/transaction/account-validation', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    Code
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/transaction/account-validation"
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);
                    reject(res.data)
                })
                .catch((error) => {
                    return reject(error.response.data);
                });
        })
    },

    //Transfer funds to Hope account
    transferHope: async ({ Account, Beneficiary, BeneficiaryName, Narration, Amount, Save, Pin, token }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/transaction/process', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    Beneficiary,
                    BeneficiaryName,
                    Narration,
                    Amount,
                    Pin,
                    Type: "TRM4",
                    Save
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/transaction/process"

            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });
    },

    //Transfer funds to Other account
    transferOther: async ({ Account, Beneficiary, BeneficiaryName, BeneficiaryBank, Narration, Amount, Save, Pin, token }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/transaction/process', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    Beneficiary,
                    BeneficiaryName,
                    BeneficiaryBank: BeneficiaryBank.code ? BeneficiaryBank.code : BeneficiaryBank.value,
                    Narration,
                    Amount,
                    Pin,
                    Type: "TRM1",
                    Save
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/transaction/process"

            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });
    },

    //Transfer funds to Own account
    transferOwn: async ({ Account, Beneficiary, Narration, Amount, Save, Pin, token }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/transaction/process', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    Beneficiary,
                    Narration,
                    Amount,
                    Pin,
                    Type: "TRM6",
                    Save
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/transaction/process"

            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });
    },

    //Schedule Transaction to hope account
    scheduleService_Hope: async ({ Account, Beneficiary, BeneficiaryName, token, Narration, Amount, Pin, ScheduleName, Frequency, Start, End }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/schedule/setup/transfer', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    Beneficiary,
                    BeneficiaryName,
                    Narration,
                    Amount: Amount,
                    Type: "TRM4",
                    Pin,
                    ScheduleName,
                    Frequency,
                    Start,
                    End
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/schedule/setup/transfer"

            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data)

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //Schedule Transaction to Own account
    scheduleService_Own: async ({ Account, Beneficiary, BeneficiaryName, token, Narration, Amount, Pin, ScheduleName, Frequency, Start, End }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/schedule/setup/transfer', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    Beneficiary,
                    BeneficiaryName,
                    Narration,
                    Amount: Amount,
                    Type: "TRM6",
                    Pin,
                    ScheduleName,
                    Frequency,
                    Start,
                    End
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/schedule/setup/transfer"

            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data)

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //Schedule Transaction to Other account
    scheduleService_Other: async ({ Account, Beneficiary, BeneficiaryName, BeneficiaryBank, token, Narration, Amount, Pin, ScheduleName, Frequency, Start, End }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/schedule/setup/transfer', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    Beneficiary,
                    BeneficiaryName,
                    BeneficiaryBank: BeneficiaryBank.value,
                    Narration,
                    Amount: Amount,
                    Type: "TRM1",
                    Pin,
                    ScheduleName,
                    Frequency,
                    Start,
                    End
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/schedule/setup/transfer"

            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data)

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //Get All Schedule
    getSchedule: async () => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'GET',
                url: ApiService.getBaseUrl() + "/api/web/schedule",
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/schedule', localStorage.getItem('licence'))
                },
                redirect: 'follow'
            })
                .then((res) => {
                    let data = res.data
                    resolve(data);
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });
    },

    //Get All Schedule
    deleteSchedule: async (id) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'PUT',
                url: ApiService.getBaseUrl() + `/api/web/schedule/delete/${id}`,
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash(`/api/web/schedule/delete/${id}`, localStorage.getItem('licence'))
                },
                redirect: 'follow'
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);
                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });
    },

    //Save/Add Beneficiary
    AddBeneficiary: async ({ TrxId, RRN }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'POST',
                data: {
                    TrxId,
                    RRN
                },
                url: ApiService.getBaseUrl() + `/api/web/self/favourite`,
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash(`/api/web/self/favourite`, localStorage.getItem('licence'))
                },
                redirect: 'follow'
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);
                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });
    },


}


export {
    transferService
};