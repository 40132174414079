<template>
  <div class>
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNav2></HeaderNav2>
      <div class="container">
        <HeaderNav3></HeaderNav3>
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <p class="frequently-used">Menu</p>
                <!-- <div class="row ">
                                <div class="col-md-3">
                                    <router-link to="TransferMoney"><div class="column_1">
                                        <img src="../../assets/images/icons/transfer.svg" alt="Hope Bank">
                                        <p>Transfer Money</p>
                                    </div></router-link>
                                </div>
                                <div class="col-md-3">
                                    <router-link to="BillPayment"><div class="column_1">
                                        <img src="../../assets/images/icons/bill-payment.svg" alt="Hope Bank">
                                        <p>Bill Payment</p>
                                    </div></router-link>
                                </div>
                                <div class="col-md-3">
                                    <router-link to="UpdateInfo"><div class="column_1">
                                        <img src="../../assets/images/icons/self-service.svg" alt="Hope Bank">
                                        <p>Self Service</p>
                                    </div></router-link>
                                </div>
                                <div class="col-md-3">
                                    <router-link to="airtimedata"><div class="column_1">
                                        <img src="../../assets/images/icons/buy-airtime.svg" alt="Hope Bank">
                                        <p>Buy Airtime/Data</p>
                                    </div></router-link>
                                </div>
                            </div>
                <div class="line"></div>-->
                <div class="row">
                  <div class="col-md-3">
                    <router-link to="TransferMoney">
                      <div class="column_1">
                        <img src="../../assets/images/icons/transfer.svg" alt="Hope Bank" />
                        <p>Transfer Money</p>
                      </div>
                    </router-link>
                  </div>

                  <div class="col-md-3">
                    <router-link to="AirtimeData">
                      <div class="column_1">
                        <img src="../../assets/images/icons/buy-airtime.svg" alt="Hope Bank" />
                        <p>Buy Airtime/Data</p>
                      </div>
                    </router-link>
                  </div>
                  <div class="col-md-3">
                    <router-link to="BillPayment">
                      <div class="column_1">
                        <img src="../../assets/images/icons/bill-payment.svg" alt="Hope Bank" />
                        <p>Bill Payment</p>
                      </div>
                    </router-link>
                  </div>
                  <!-- <div class="col-md-4">
                                    <router-link to="Card"><div class="column_1">
                                        <img src="../../assets/images/icons/card-management.svg" alt="Hope Bank">
                                        <p>Card Management</p>
                                    </div></router-link>
                  </div>-->
                  <div class="col-md-3">
                    <router-link to>
                      <div class="column_1">
                        <img src="../../assets/images/icons/self-service.svg" alt="Hope Bank" />
                        <p>Self Service</p>
                      </div>
                    </router-link>
                  </div>

                  <!-- <div class="col-md-4">
                                    <router-link to="Cheque"><div class="column_1">
                                        <img src="../../assets/images/icons/cheque.svg" alt="Hope Bank">
                                        <p>Cheque</p>
                                    </div></router-link>
                  </div>-->
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar
                :accountNumber="user.accounts[0].accountNumber"
                :accountBalance="user.accounts[0].balance"
              ></SideBar>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderNav2 from "../../components/HeaderNav/HeaderNav2";
import HeaderNav3 from "../../components/HeaderNav/HeaderNav3";
import SideBar from "../../components/SideBar/SideBar";
import { mapGetters } from "vuex";
import Footer2 from "../../components/Footer/Footer2";

export default {
  name: "Dashboard.vue",
  components: {
    HeaderNav2,
    HeaderNav3,
    SideBar,
    Footer2
  },

  computed: {
    ...mapGetters({ user: "GET_USER" })
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000 !important;
  display: flex;
  align-items: center;
  margin: 0 1rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
  letter-spacing: 0.17px;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>

