import { ApiService } from "./api.service";

const BVNValidationService = {
    validationService: async ({ Code, Bvn, nin, Phone, DateOfBirth }) => {
        return new Promise(function (resolve, reject) {
            ApiService.post('/api/web/setup/validation', {
                Code,
                Bvn,
                nin,
                Phone,
                DateOfBirth,
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //Validate BVN when logged in
    validationServiceWhenLoggedIn: async ({ Code, Bvn, nin, Phone, DateOfBirth, DateOfInc }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'POST',
                url: ApiService.getBaseUrl() + "/api/web/setup/validation",
                data: {
                    Code,
                    Bvn,
                    nin,
                    Phone,
                    DateOfBirth,
                    DateOfInc

                },
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/setup/validation', localStorage.getItem('licence'))
                },
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    }

};



export {
    BVNValidationService
};
