import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

const state = {
    BILL_DATA: {},
    AIRTIME_DATA:{}



}

export default {state, getters, mutations, actions}