<template>
  <div class="about-page">
    <div class="about-page-header">
      <HeaderNavA />

      <div class="about-title">
        <h1>
          About Us:
          <br />
          <span>Hope PSBank</span>
        </h1>
      </div>
    </div>

    <div class="great_features mt-4">
      <div class="line3"></div>
      <div
        class="container"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <b-tabs>
          <b-tab active id="1">
            <template v-slot:title>
              <font-awesome-icon icon="briefcase" class="about_icon" />
              <br />Corporate Profile
            </template>
            <div class="line3"></div>
            <div
              class="title_banking_category"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <div class="container">
                <div class="row">
                  <div
                    class="col-md-5"
                    data-aos="fade-right"
                    data-aos-duration="2000"
                    data-aos-easing="ease-in-out"
                  >
                    <div class="brand_logo pt-3">
                      <!-- <img
                          src="../../assets/images/logo/hope-bank-payment-service-logo.svg"
                          alt="Hope Bank Logo"
                          class="w-100"
                      />-->
                      <img
                        src="../../assets/images/happy-african-american.jpg"
                        alt="Hope Bank Logo"
                        class="w-100"
                      />
                    </div>
                  </div>
                  <div
                    class="col-md-7"
                    data-aos="fade-left"
                    data-aos-duration="2000"
                    data-aos-easing="ease-in-out"
                  >
                    <h2 class="text-left">Hope PSBank</h2>
                    <p>
                      Hope Payment Service Bank Limited (Hope PSBank) is
                      Nigeria’s premier Digital-first Bank. Incorporated in
                      September, 2019, Hope PSBank leverages digital
                      technologies to deliver inclusive financial services.
                    </p>
                    <p>
                      Our belief is that everyone irrespective of education,
                      social or economic status and location should have access
                      to financial and payment services with ease and
                      convenience. We seek to provide the 4As - Accessibility,
                      Affordability, Availability and Awareness of Digital
                      Financial Services.
                      <span
                        v-if="readMore"
                      ></span>
                      <span v-else>...</span>
                    </p>

                    <div class v-show="readMore">
                      <p>
                        With Hope PSBank, every mobile telephone user can easily
                        enjoy instant digital financial and payment services
                        with his or her phone number as account number and also
                        a means of payment across all channels –
                        person-to-person on personal devices, web, merchants’
                        points of sale, automated teller machines and agency
                        outlets.
                      </p>
                      <p>
                        At Hope PSBank, we redefine the concept of open banking
                        and the boundaries of competition by offering our unique
                        financial and payment services and solutions to
                        customers of other banks who are not required to open or
                        maintain account with our bank. All transactions on our
                        platforms are gladly settled into accounts of our
                        community of users and customers with any bank of their
                        choice. These are borne out of our philosophy that
                        competition is about service to customers and not about
                        alternative service providers who we view as partners in
                        the service of customers and not as competitors.
                      </p>
                    </div>
                    <button
                      class="open_account_btn hvr-icon-wobble-horizontal"
                      @click="readMore = !readMore"
                    >
                      <span v-if="readMore">
                        Read Less
                        <font-awesome-icon icon="chevron-left" class="hvr-icon ml-1" />
                      </span>
                      <span v-else>
                        Read More
                        <font-awesome-icon icon="chevron-right" class="hvr-icon ml-1" />
                      </span>
                    </button>
                    <!-- <button
                        @click="toggle2 = !toggle2"
                        class="open_account_btn hvr-icon-wobble-horizontal"
                      >
                        Read More
                        <font-awesome-icon icon="chevron-right" class="hvr-icon ml-1" />
                    </button>-->
                  </div>
                </div>
              </div>
            </div>
            <div class="line3"></div>
          </b-tab>
          <b-tab id="2">
            <template v-slot:title>
              <font-awesome-icon icon="history" class="about_icon" />
              <br />Our History
            </template>
            <div class="line3"></div>
            <div class="row pt-5 pb-4">
              <div class="col-md-3">
                <div class="agent_benefit">
                  <h1 class="text-left">
                    <span>Our History:</span>
                  </h1>
                </div>
              </div>
              <div class="col-md-9">
                <div class="agent_benefit">
                  <p>Hope PS Bank (Hope Payment Service Bank Limited) is Nigeria’s premier Digital Bank. Incorporated in September, 2019, Hope PSBank leverages digital technologies to deliver inclusive financial services.</p>
                </div>
              </div>
            </div>
            <!-- <div class="about-title">
              <h2>Our History</h2>
              <p>
                Hope PS Bank (Hope Payment Service Bank Limited) is Nigeria’s
                premier Digital Bank. Incorporated in September, 2019, Hope PS
                Bank leverages digital technologies to deliver inclusive
                financial services.
              </p>
            </div> -->
            <div class="line3"></div>
          </b-tab>
          <b-tab id="3">
            <template v-slot:title>
              <font-awesome-icon icon="glasses" class="about_icon" />
              <br />Our Vision &amp; Mission
            </template>
            <div class="line3"></div>
            <div
              class="title_banking_category"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <div class="container">
                <div class="row">
                  <div class="col-md-3">
                    <div class="agent_benefit">
                      <h1 class="text-left">
                        <span>Vision:</span>
                      </h1>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <p>To become the leading provider of inclusive digital financial and payment services in Africa.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="line3"></div>
            <div
              class="title_banking_category blue_bg"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <div class="container">
                <div class="row">
                  <div class="col-md-3">
                    <div class="agent_benefit">
                      <h1 class="text-left">
                        <span>Mission:</span>
                      </h1>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <p>
                      To bring financial and payment services closer to all by providing tailored financial services and solutions for them across different channels, irrespective of their economic or social status and location.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="line3"></div>
          </b-tab>
          <b-tab id="4">
            <template v-slot:title>
              <font-awesome-icon icon="tasks" class="about_icon" />
              <br />Our Core Values
            </template>
            <div class="line3"></div>
            <div
              class="title_banking_category blue_bg"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <div class="container">
                <div class="row">
                  <div class="col-md-3">
                    <div class="agent_benefit">
                      <h1 class="text-left">
                        Core
                        <br />
                        <span>Values:</span>
                      </h1>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <ul>
                      <li>
                        <b>D</b>ependability
                      </li>
                      <li>
                        <b>I</b>nclusion
                      </li>
                      <li>
                        <b>G</b>overnance
                      </li>
                      <li>
                        <b>I</b>nnovation
                      </li>
                      <li>
                        <b>T</b>rust
                      </li>
                      <li>
                        <b>A</b>ccessibility
                      </li>
                      <li>
                        <b>L</b>eadership
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="line3"></div>
          </b-tab>
          <b-tab id="5">
            <template v-slot:title>
              <font-awesome-icon icon="users" class="about_icon" />
              <br />Management
            </template>
            <div class="line3"></div>
            <div class="about-title">
              <h2>Management</h2>

              <div class="row">
                <div class="col-md-3">
                  <div class="hope_management">
                    <div class="img">
                      <img src="../../assets/images/management/director1.jpg" alt="User" />
                    </div>
                    <div class="hope_management_name">
                      <h5>Ayotunde Kuponiyi</h5>
                      <h6>Managing Director & CEO</h6>
                      <b-button
                        v-b-modal.modal-scrollable-ayotunde2
                        class="open_account_btn hvr-icon-wobble-horizontal"
                      >
                        View Profile
                        <font-awesome-icon icon="chevron-right" class="hvr-icon ml-1" />
                      </b-button>
                    </div>
                  </div>
                </div>
<!--                <div class="col-md-3">-->
<!--                  <div class="hope_management">-->
<!--                    <div class="img">-->
<!--                      <img src="../../assets/images/management/kathleen-erhimu.jpg" alt="User" />-->
<!--                    </div>-->
<!--                    <div class="hope_management_name">-->
<!--                      <h5>Kathleen O. Erhimu</h5>-->
<!--                      <h6>Chief Marketing Officer</h6>-->
<!--                      <b-button-->
<!--                        v-b-modal.modal-scrollable-kathleen2-->
<!--                        class="open_account_btn hvr-icon-wobble-horizontal"-->
<!--                      >-->
<!--                        View Profile-->
<!--                        <font-awesome-icon icon="chevron-right" class="hvr-icon ml-1" />-->
<!--                      </b-button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
                <div class="col-md-3">
                  <div class="hope_management">
                    <div class="img">
                      <img src="../../assets/images/management/director2.jpg" alt="Users" />
                    </div>
                    <div class="hope_management_name">
                      <h5>Cletus Omale Igah</h5>
                      <h6>Group Head Corp. Services</h6>
                      <b-button
                        v-b-modal.modal-scrollable-cletus
                        class="open_account_btn hvr-icon-wobble-horizontal"
                      >
                        View Profile
                        <font-awesome-icon icon="chevron-right" class="hvr-icon ml-1" />
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <b-modal id="modal-scrollable-ayotunde2" scrollable size="xl" hide-footer>
                <div class="modal_scroll_texts">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="img">
                        <img src="../../assets/images/management/director1.jpg" alt="Users" />
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="hope_management_name2">
                        <h5>Ayotunde Kuponiyi</h5>
                        <h6>Managing Director & CEO</h6>
                        <p>
                          Ayotunde Kuponiyi is the pioneer Managing Director of
                          Hope PSBank. A graduate of the prestigious Obafemi
                          Awolowo University, Ile-Ife, he obtained an MBA from
                          the University of Liverpool in the United Kingdom. He
                          is a seasoned Banker with passion and skills for
                          extending the frontiers of electronic banking and
                          payments in Nigeria. He currently serves as the
                          Chairman of the Mobile Payments Scheme Board, having
                          been appointed by the Central Bank of Nigeria in
                          recognition of his role in advancing and strengthening
                          alternative payment platforms in the country. By
                          extension, he is also a Member of the Payment Systems
                          Strategy Board and the Payment Infrastructure
                          Coordinating Committee in Nigeria.
                        </p>
                        <p>
                          He is currently a member of the Governing Board of the
                          Committee of e-Banking Industry Heads (CeBIH).
                        </p>
                        <p>
                          An honorary member of the Chartered Institute of
                          Bankers of Nigeria (HCIB) with a wealth of experience
                          which spans over 29 years in Banking and Telecoms. He
                          is a Certified Cards and Payment Professional (CCPP).
                        </p>
                        <p>
                          Prior to joining Hope PSBank, he held various leadership
                          roles in the Banking and Telecoms Industry. He was the
                          Head of the Consumer Distribution Department in
                          Ecobank Nigeria, responsible for bank-wide Card
                          Business, POS Acquiring, Digital Banking Channels,
                          Agency Banking and Remittance Business.
                        </p>
                        <p>
                          He was the Consumer Banking Lead on Project Falcon, an
                          Ecobank project that implemented the upgrade of the
                          bank's core banking application across 33 countries in
                          Africa which included the implementation of Oracle
                          Digital Banking experience across the 33 Affiliates.
                        </p>
                        <p>
                          He held the position of Director, Telebanking in
                          Globacom Nigeria, responsible for launching the first
                          Mobile Money Service, Glo TxtCash, in Nigeria, in
                          partnership with UBA Plc and Stanbic IBTC Bank. He was
                          also the Regional Manager, Lagos & South West, Retail
                          Banking in Ecobank Nigeria between 2008 and 2010.
                        </p>
                        <p>
                          Ayotunde started his Banking career in 1991 with
                          GTBank and worked in several departments, in front
                          office and back-office roles. He also worked in the
                          former Equitorial Trust Bank (now Sterling Bank) where
                          he held several positions including Treasurer and Head
                          of Consumer Banking.
                        </p>
                        <p>
                          He has attended numerous courses in Nigeria and
                          abroad.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="line3"></div>
              </b-modal>
<!--              <b-modal id="modal-scrollable-kathleen2" scrollable size="xl" hide-footer>-->
<!--                <div class="modal_scroll_texts">-->
<!--                  <div class="row">-->
<!--                    <div class="col-md-3">-->
<!--                      <div class="img">-->
<!--                        <img src="../../assets/images/management/kathleen-erhimu.jpg" alt="User" />-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="col-md-9">-->
<!--                      <div class="hope_management_name2">-->
<!--                        <h5>Kathleen O. Erhimu</h5>-->
<!--                        <h6>Director</h6>-->
<!--                        <p>-->
<!--                          Kathleen is the Chief Marketing Officer of Hope-->
<!--                          PSBank. With 26 years of formal work experience under-->
<!--                          her belt prior to this appointment, she is a-->
<!--                          well-rounded banker as well as a real estate-->
<!--                          practitioner. Kathleen has 22 contiguous years of-->
<!--                          experience in banking, with the most recent as a-->
<!--                          Deputy General Manager with Keystone Bank Nigeria-->
<!--                          Limited. Her rich banking career also includes 15-->
<!--                          years in the employment of Access Bank Plc. where she-->
<!--                          rose to the level of Assistant General Manager after-->
<!--                          working in several core banking functions. Amongst her-->
<!--                          string of successful trailblazing assignments,-->
<!--                          Kathleen set up and ran Keystone Bank’s-->
<!--                          Retail/SME/Value Chain/ Product & Marketing Division-->
<!--                          as the Pioneer Head.-->
<!--                        </p>-->

<!--                        <p>-->
<!--                          Standard Trust Bank (now United Bank for Africa),-->
<!--                          Oceanic Bank (now Ecobank), and Knight Frank proved to-->
<!--                          be excellent formative work experiences in her-->
<!--                          maturation as a seasoned banker.-->
<!--                        </p>-->

<!--                        <p>-->
<!--                          Kathleen holds a Chartered Banker MBA degree from-->
<!--                          University of Bangor UK, a master’s degree in Business-->
<!--                          Administration from the University of Lagos, and a-->
<!--                          bachelor’s degree in Geography & Regional planning-->
<!--                          from Edo State University. She was the best graduating-->
<!--                          student academics and games from Federal Government-->
<!--                          College, Warri (1988), and an Alumnus of Emeritus-->
<!--                          Institute on Management and University of Bangor, UK.-->
<!--                        </p>-->

<!--                        <p>-->
<!--                          A Fellow of both the Institute of Management-->
<!--                          Consultants Nigeria (IMC- Nigeria) and the-->
<!--                          Microfinance Association (FMA), Kathleen is both an-->
<!--                          Honorary (HCIB) and Associate (ACIB) Member of the-->
<!--                          Chartered Institute of Banking Nigeria. Her other-->
<!--                          professional memberships include the Chartered Banker-->
<!--                          Institute (MCIBS), and the International Council of-->
<!--                          Management Consulting Institutes (ICMCI).-->
<!--                        </p>-->

<!--                        <p>-->
<!--                          In her quest for professional and personal excellence,-->
<!--                          Kathleen attended several developmental programs in-->
<!--                          top notch institutions across the globe, including-->
<!--                          Harvard Business School, Wharton Business School,-->
<!--                          INSEAD Business School, IMD, and Lagos Business School-->
<!--                          amongst others.-->
<!--                        </p>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="line3"></div>-->
<!--              </b-modal>-->
              <b-modal id="modal-scrollable-cletus" scrollable size="xl" hide-footer>
                <div class="modal_scroll_texts">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="img">
                        <img src="../../assets/images/management/director2.jpg" alt="Users" />
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="hope_management_name2">
                        <h5>Cletus Omale Igah</h5>
                        <h6>Group Head Corporate Services</h6>
                        <p>
                          Cletus is the Group Head, Corporate Services of Hope
                          PS Bank. He brings to the Bank a rich wealth of
                          experience spanning over 21 years which cut across
                          Capital Markets Infrastructure, Banking and
                          Hospitality services. Cletus started his career from
                          Ess-ay Holdings Nigeria Limited. He then joined the
                          world-renowned American hospitality company, The
                          Ritz-Carlton Hotel Doha, Qatar where he led a team of
                          HR Professionals engaging people across the globe. He
                          has many years of banking experience, where he served
                          as a HR and Administration Professional in FSB
                          International Bank, United Bank for Africa and Oceanic
                          Bank International Plc. Prior to joining Hope Payment
                          Service Bank, he was the Head of Human Resources of
                          Central Securities Clearing System.
                        </p>
                        <p>
                          A graduate of University of Ilorin, Cletus possessed a
                          Bachelor's degree in Political Science and Masters of
                          Public Administration from the University of Ilorin.
                          He is a member of many Local and International
                          professional bodies. Notable amongst them are:
                          Chartered Institute of Personnel Management of Nigeria
                          (MCIPM), International Academy of Business and
                          Financial Management (CHRM) and American Society of
                          Human Resources (SHRM).
                        </p>
                        <p>
                          Cletus is an Alumni of the prestigious Lagos Business
                          School and has undertaken several professional and
                          leadership development programs in Singapore, United
                          States, UAE, United Kingdom and Corporate Governance
                          training in Wharton Business School.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="line3"></div>
              </b-modal>
            </div>
            <div class="line3"></div>
          </b-tab>
          <b-tab id="6">
            <template v-slot:title>
              <font-awesome-icon icon="sitemap" class="about_icon" />
              <br />Board of Directors
            </template>
            <div class="line3"></div>
            <div class="about-title">
              <h2>Board of Directors</h2>
              <div class="row">
                <div class="col-md-3">
                  <div class="hope_management">
                    <div class="img">
                      <img src="../../assets/images/management/shehu-abubakar.jpg" alt="Users" />
                    </div>
                    <div class="hope_management_name">
                      <h5>Shehu Abubakar</h5>
                      <h6>Chairman</h6>
                      <b-button
                        v-b-modal.modal-scrollable-shehu
                        class="open_account_btn hvr-icon-wobble-horizontal"
                      >
                        View Profile
                        <font-awesome-icon icon="chevron-right" class="hvr-icon ml-1" />
                      </b-button>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="hope_management">
                    <div class="img">
                      <img src="../../assets/images/management/director1.jpg" alt="User" />
                    </div>
                    <div class="hope_management_name">
                      <h5>Ayotunde Kuponiyi</h5>
                      <h6>Managing Director & CEO</h6>
                      <b-button
                        v-b-modal.modal-scrollable-ayotunde
                        class="open_account_btn hvr-icon-wobble-horizontal"
                      >
                        View Profile
                        <font-awesome-icon icon="chevron-right" class="hvr-icon ml-1" />
                      </b-button>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="hope_management">
                    <div class="img">
                      <img src="../../assets/images/management/agada-apochi.jpg" alt="User" />
                    </div>
                    <div class="hope_management_name">
                      <h5>Agada B. Apochi</h5>
                      <h6>Founder & Director</h6>
                      <b-button
                        v-b-modal.modal-scrollable-agada
                        class="open_account_btn hvr-icon-wobble-horizontal"
                      >
                        View Profile
                        <font-awesome-icon icon="chevron-right" class="hvr-icon ml-1" />
                      </b-button>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="hope_management">
                    <div class="img">
                      <img src="../../assets/images/management/ano-anyanwu.jpg" alt="User" />
                    </div>
                    <div class="hope_management_name">
                      <h5>Ano Anyanwu</h5>
                      <h6>Independent Director</h6>
                      <b-button
                        v-b-modal.modal-scrollable-ano
                        class="open_account_btn hvr-icon-wobble-horizontal"
                      >
                        View Profile
                        <font-awesome-icon icon="chevron-right" class="hvr-icon ml-1" />
                      </b-button>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="hope_management">
                    <div class="img">
                      <img src="../../assets/images/management/nana-fatima.jpg" alt="User" />
                    </div>
                    <div class="hope_management_name">
                      <h5>Nana Fatima Mede</h5>
                      <h6>Independent Director</h6>
                      <b-button
                        v-b-modal.modal-scrollable-nana
                        class="open_account_btn hvr-icon-wobble-horizontal"
                      >
                        View Profile
                        <font-awesome-icon icon="chevron-right" class="hvr-icon ml-1" />
                      </b-button>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="hope_management">
                    <div class="img">
                      <img src="../../assets/images/management/festus.jpg" alt="User" />
                    </div>
                    <div class="hope_management_name">
                      <h5>Festus Eze Ikediasor</h5>
                      <h6>Director</h6>
                      <b-button
                        v-b-modal.modal-scrollable-festus
                        class="open_account_btn hvr-icon-wobble-horizontal"
                      >
                        View Profile
                        <font-awesome-icon icon="chevron-right" class="hvr-icon ml-1" />
                      </b-button>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="hope_management">
                    <div class="img">
                      <img src="../../assets/images/management/ochanya-dan.jpg" alt="User" />
                    </div>
                    <div class="hope_management_name">
                      <h5>Ochanya Dan-Ugo</h5>
                      <h6>Director</h6>
                      <b-button
                        v-b-modal.modal-scrollable-ochanya
                        class="open_account_btn hvr-icon-wobble-horizontal"
                      >
                        View Profile
                        <font-awesome-icon icon="chevron-right" class="hvr-icon ml-1" />
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <b-modal id="modal-scrollable-shehu" scrollable size="xl" hide-footer>
                <div class="modal_scroll_texts">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="img">
                        <img src="../../assets/images/management/shehu-abubakar.jpg" alt="Users" />
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="hope_management_name2">
                        <h5>Shehu Abubakar</h5>
                        <h6>Chairman</h6>
                        <p>
                          Mr. Abubakar is the Chairman of the Board of Directors
                          of Hope PSBank.
                        </p>
                        <p>
                          He has over 30 years of experience in the banking
                          industry during which time he held strategic positions
                          in different Banks. He began his career in
                          Co-operative & Commerce Bank Plc where he served as an
                          intern under the National Youth Service Corps scheme
                          and worked at different times in International
                          Merchant Bank, FSB International Bank, Fidelity Bank
                          and Keystone Bank where he retired as an Executive
                          Director. His banking experience covers areas such as
                          corporate banking, public sector, corporate finance,
                          leasing and treasury. His board experience includes
                          being Director of a number of companies including
                          Global Bank of Liberia, KBL Health Insurance and
                          Cement Company of Northern Nigeria.
                        </p>
                        <p>
                          In the course of his career, Mr. Abubakar has attended
                          many courses within and outside Nigeria including the
                          Lagos Business School, Harvard Business School,
                          Columbia Business School and Wharton Business School
                          among others and has a wide range of experience in
                          Strategy, Leadership & Executive Management, Customer
                          Relations and Management and Corporate Finance, among
                          others.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </b-modal>
              <b-modal id="modal-scrollable-ayotunde" scrollable size="xl" hide-footer>
                <div class="modal_scroll_texts">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="img">
                        <img src="../../assets/images/management/director1.jpg" alt="Users" />
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="hope_management_name2">
                        <h5>Ayotunde Kuponiyi</h5>
                        <h6>Managing Director &amp; CEO</h6>
                        <p>
                          Ayotunde Kuponiyi is the pioneer Managing Director of
                          Hope PSBank. A graduate of the prestigious Obafemi
                          Awolowo University, Ile-Ife, he obtained an MBA from
                          the University of Liverpool in the United Kingdom. He
                          is a seasoned Banker with passion and skills for
                          extending the frontiers of electronic banking and
                          payments in Nigeria. He currently serves as the
                          Chairman of the Mobile Payments Scheme Board, having
                          been appointed by the Central Bank of Nigeria in
                          recognition of his role in advancing and strengthening
                          alternative payment platforms in the country. By
                          extension, he is also a Member of the Payment Systems
                          Strategy Board and the Payment Infrastructure
                          Coordinating Committee in Nigeria.
                        </p>
                        <p>
                          He is currently a member of the Governing Board of the
                          Committee of e-Banking Industry Heads (CeBIH).
                        </p>
                        <p>
                          An honorary member of the Chartered Institute of
                          Bankers of Nigeria (HCIB) with a wealth of experience
                          which spans over 29 years in Banking and Telecoms. He
                          is a Certified Cards and Payment Professional (CCPP).
                        </p>
                        <p>
                          Prior to joining Hope PSBank, he held various leadership
                          roles in the Banking and Telecoms Industry. He was the
                          Head of the Consumer Distribution Department in
                          Ecobank Nigeria, responsible for bank-wide Card
                          Business, POS Acquiring, Digital Banking Channels,
                          Agency Banking and Remittance Business.
                        </p>
                        <p>
                          He was the Consumer Banking Lead on Project Falcon, an
                          Ecobank project that implemented the upgrade of the
                          bank's core banking application across 33 countries in
                          Africa which included the implementation of Oracle
                          Digital Banking experience across the 33 Affiliates.
                        </p>
                        <p>
                          He held the position of Director, Telebanking in
                          Globacom Nigeria, responsible for launching the first
                          Mobile Money Service, Glo TxtCash, in Nigeria, in
                          partnership with UBA Plc and Stanbic IBTC Bank. He was
                          also the Regional Manager, Lagos & South West, Retail
                          Banking in Ecobank Nigeria between 2008 and 2010.
                        </p>
                        <p>
                          Ayotunde started his Banking career in 1991 with
                          GTBank and worked in several departments, in front
                          office and back-office roles. He also worked in the
                          former Equitorial Trust Bank (now Sterling Bank) where
                          he held several positions including Treasurer and Head
                          of Consumer Banking.
                        </p>
                        <p>
                          He has attended numerous courses in Nigeria and
                          abroad.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </b-modal>

              <b-modal id="modal-scrollable-agada" scrollable size="xl" hide-footer>
                <div class="modal_scroll_texts">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="img">
                        <img src="../../assets/images/management/agada-apochi.jpg" alt="User" />
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="hope_management_name2">
                        <h5>Agada B. Apochi</h5>
                        <h6>Founder & Director</h6>
                        <p>Agada Apochi is an Organizational Psychologist & Change Leadership Specialist and also a Practitioner-Scholar in diffusion of innovation and digital financial services. He has over 30 years of professional experience in banking and financial technology. Agada is the Founder/Director of Hope PSBank. </p>
<p>Agada is the Managing Director & CEO of Nigeria’s premier financial technology company, UP (Unified Payments), which is a group of companies with services including Banking, Payment Scheme, Financial Technology, Digital Commerce, Value Added Service, Payment Solutions, Software Solutions, etc. He is also the Founder/Director of the payment scheme and financial technology company, Payattitude Global Limited as well as the telecom VAS provider, UP Digital Limited. He is also the Chairman of the Board of Directors of TM30, a software company.  </p>
<p>Agada attained Doctor of Business Administration (DBA) with specialization in diffusion of innovation and digital financial services as well as Master Degree in Applied Business Research from SBS Swiss Business School in Switzerland, Master Degree (MA) in Organizational Psychology with specialization in Change Leadership from Columbia University in New York, Master Degree in Law (LL.M.) from Rivers State University, Barrister at Law (BL) from the Nigerian Law School and Bachelor Degree in Law (LL.B.) from the University of Jos. He is also is an Alumnus of Lagos Business School and Cornell University. He has attended different executive learning programs including programs at Harvard Business School, Insead Business School and Bank Card Business School in Cambridge. Agada holds several academic honours and awards including best graduating student. He was called to the Nigerian Bar in 1992 and has since worked and gained experience in company law & practice, banking and financial services, electronic transactions, retail business, administration, strategy, execution and change leadership.</p>
<p>Agada is currently the President of Lagos Business School Alumni Association and a Life Member of the Governing Council of the association. Agada is an Organizational Psychologist, Innovator and Entrepreneur. He is a Fellow, Chartered Institute of Bankers of Nigeria, Fellow, International Academy of Cards & Payments, Fellow, Institute of Credit Administration, Certified International Cards & Payments Professional (CICPP), Certified Associate of Capability Maturity Model Institute, Chartered Mediator & Conciliator, Barrister & Solicitor. He has to his credit, different professional and technical papers. Agada is married with children. </p>
                        </div>
                    </div>
                  </div>
                </div>
              </b-modal>
              <b-modal id="modal-scrollable-ano" scrollable size="xl" hide-footer>
                <div class="modal_scroll_texts">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="img">
                        <img src="../../assets/images/management/ano-anyanwu.jpg" alt="User" />
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="hope_management_name2">
                        <h5>Ano Anyanwu</h5>
                        <h6>Independent Director</h6>
                        <p>
                          Mr. Anogwi Anyanwu serves as an Independent Director
                          on the Board of Hope PSBank. He is a 1984 graduate of
                          Statistics of the University of Nigeria Nsukka. He was
                          a Federal Government Scholar and the Best Graduating
                          Student in his Class. He is a Fellow of the Institute
                          of Chartered Accountants of Nigeria (FCA), and an
                          Honorary Senior Member, Chartered Institute of Bankers
                          of Nigeria (HCIB). He was a Former Executive Director
                          in charge of South East/South South Branches of Spring
                          Bank Plc from 2008 to 2009, and Executive Director in
                          charge of Operations and Information Technology of
                          Mainstreet Bank Limited from 2011 to 2014.
                        </p>
                        <p>
                          He is member of Lagos Business School Advanced
                          Management Programme 19 and an Alumnus of LBS.
                          <br />He earned the Certificate in Business Excellence
                          from Columbia Business School New York and is an
                          Alumnus of Columbia Business School.
                        </p>
                        <p>
                          Anogwi is an Accomplished Administrator, Banker and
                          Chartered Accountant. He currently runs the consulting
                          outfit, Minds Hrewd Limited as Chief Executive
                          Officer.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </b-modal>
              <b-modal id="modal-scrollable-nana" scrollable size="xl" hide-footer>
                <div class="modal_scroll_texts">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="img">
                        <img src="../../assets/images/management/nana-fatima.jpg" alt="Users" />
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="hope_management_name2">
                        <h5>Nana Fatima Mede</h5>
                        <h6>Independent Director</h6>
                        <p>
                          Mrs. Nana Fatima Mede serves as an Independent
                          Director on the Board of Hope PSBank. She is also a
                          senior Managing Partner in Carter Consulting limited
                          where she oversees portfolio capital planning &
                          analysis amongst other responsibilities. She is also
                          the founder of Baba Rabi Foundation, a charity
                          organisation that is committed to enhancing the
                          quality of life for the less privileged, orphans and
                          widows across Nigerian rural communities.
                        </p>
                        <p>
                          An accountant by profession with a Bachelor of Science
                          degree in accounting and a Master’s in Business
                          Administration (MBA) from Alabama A&M University, Mrs
                          Mede has a vast amount of experience spanning over
                          three and half decades working in the Nigerian public
                          sector in various capacities. She also possesses a
                          certificate in public financial management obtained
                          from the prestigious John F Kennedy School of
                          Government, Harvard University.
                        </p>
                        <p>
                          Upon graduation in 1981, Mrs Mede completed her youth
                          service as a graduate trainee accountant at the
                          Ajaokuta Steel Company, after which she was
                          immediately employed and made Head of the Accounting
                          Department at the National Television Authority (NTA)
                          located in Abuja. She quickly rose through the ranks
                          to become a Principal Accountant before transferring
                          her service to Benue State Government.
                        </p>
                        <p>
                          Mrs. Mede has, over the years, deployed her expertise
                          in several high ranking positions throughout her
                          professional career, including as Permanent Secretary
                          / Accountant General of Benue State, Director of
                          Finance in the Office of the Accountant General of the
                          Federation (OAGF) and as a Federal Permanent Secretary
                          presiding over the affairs of the Ministries of
                          Environment as well as Budget & National Planning,
                          before gracefully retiring. In her quest to continue
                          serving the people, she maintains her position in the
                          board of many organisations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </b-modal>
              <b-modal id="modal-scrollable-festus" scrollable size="xl" hide-footer>
                <div class="modal_scroll_texts">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="img">
                        <img src="../../assets/images/management/festus.jpg" alt="Users" />
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="hope_management_name2">
                        <h5>Festus Ezechukwu Ikediasor</h5>
                        <h6>Director</h6>
                        <p>
                          Festus is a Director of Hope PSBank. He retired from
                          the services of UP (Unified Payments) as Director of
                          Finance & Corporate Assets. He joined UP in 2001 and
                          worked at different times in the company as Internal
                          Auditor, Head of Group—Administration, HoD—Accounts;
                          and Group Head—Finance and Accounts. He is an
                          Accounting (B.Sc. & M. Sc.) graduate of the University
                          of Lagos and Certified Information Systems Auditor
                          (CISA). He was admitted as an Associate (ACA) member
                          of the Institute of Chartered Accountants of Nigeria
                          in January 1991 and he is currently a Fellow of the
                          Institute. He has held various positions in Finance,
                          Accounting and Auditing including Finance Manager with
                          Nigeria Tobacco Company Limited (BAT) and Accountant
                          with Elf oil Nigeria Limited. Festus is an alumnus of
                          the famous Lagos Business School where he concluded an
                          Advanced Management Programme in 2010.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </b-modal>
              <b-modal id="modal-scrollable-ochanya" scrollable size="xl" hide-footer>
                <div class="modal_scroll_texts">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="img">
                        <img src="../../assets/images/management/ochanya-dan.jpg" alt="User" />
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="hope_management_name2">
                        <h5>Ochanya Dan-Ugo</h5>
                        <h6>Director</h6>
                        <p>
                          Ochanya is currently the Director/Group Chief Risk
                          Officer (GCRO) of Unified Payments®. A Visa
                          International trained manager in Risk Management. She
                          has attended several trainings on payment card and
                          Cyber security including American Bankers Association,
                          School of Bank Card Management, Emory University,
                          Atlanta USA and Global Cyber Security Financial
                          Summit, Dallas, Texas, USA. Prior to this period,
                          Ochanya was a Chief Superintendent of Narcotics and a
                          Principal Staff Officer, Records Management
                          (Intelligence), National Drug Law Enforcement Agency.
                          A place she worked for fourteen years where she
                          garnered her investigation and Intelligence
                          experiences.
                        </p>
                        <p>
                          She is a Certified Risk and Compliance Management
                          Professional (CRCMP); certified in Management of Risk
                          ( M_O_R) (Foundation and Practioner levels) and
                          certified in ISO 31000 (Lead Risk Manger). A Certified
                          Risk and Compliance Management Professional (CRCMP), a
                          certified Information Systems Risk and Compliance
                          Professional (CISRCP), a certified Cyber (Governance
                          Risk and Compliance) Professional (CC(GRC)P), a
                          Certified Protection Officer (CPO), a certification
                          awarded by the International Foundation for Protection
                          Officers (IFPO).
                        </p>
                        <p>
                          A fellow of the Institute of Disaster Management and
                          Safety Science (FDMSS).
                        </p>
                        <p>
                          Ochanya holds a Bachelor of Arts Degree from the
                          University of Jos; Post Graduate Diploma, Education, a
                          Post Graduate Degree in Humanitarian and Refugee
                          Studies from the University of Lagos; She is an
                          alumnus of the Lagos Business School, the Metropolitan
                          School of Business and Management, United Kingdom and
                          Cornell University, USA. She has been serving as a
                          member of Visa Risk Executive Council for the
                          Sub-Saharan Africa since 2012 till date. She joined
                          the services of Unified Payment Services Limited in
                          November, 2005.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </b-modal>
            </div>
            <div class="line3"></div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-md-12">
        <div class="about-right-side mt-5">
          <div class>
            <div class="great_features mb-5">
              <!-- <div
                class="title_banking_category"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <div class="container">
                  <div class="row">
                    <div class="col-md-6 mb-3">
                      <div class="vision-mission">
                        <h5 class="about-title-sm text-left">Vision:</h5>
                        <p>To become the leading provider of inclusive digital financial and payment service in Africa.</p>
                      </div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <div class="vision-mission">
                        <h5 class="about-title-sm text-left">Mission:</h5>
                        <p>
                          To bring financial and payment services closer to all
                          by providing tailored financial services and solutions
                          for them across different channels, irrespective of
                          their economic or social status and location.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="line3"></div>
              <div
                class="title_banking_category"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <div class="container">
                  <div class="row">
                    <!-- <div class="col-md-6 mb-3">
                      <div class="vision-mission">
                        <h5 class="about-title-sm text-left">Core Values:</h5>
                        <ul>
                          <li>
                            <b>D</b>ependability
                          </li>
                          <li>
                            <b>I</b>nclusive Innovations
                          </li>
                          <li>
                            <b>G</b>overnance
                          </li>
                          <li>
                            <b>I</b>ntegrity
                          </li>
                          <li>
                            <b>T</b>rust
                          </li>
                          <li>
                            <b>A</b>ccessibility
                          </li>
                          <li>
                            <b>L</b>eadership
                          </li>
                        </ul>
                      </div>
                    </div> -->
                    <div class="col-md-12 mb-3">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="agent_benefit">
                            <h1 class="text-left">
                              <span>Tagline:</span>
                            </h1>
                          </div>
                        </div>
                        <div class="col-md-9">
                          <p>Hope. Succeed. Hope Bank Always.</p>
                        </div>
                      </div>
                      <div class="line3"></div>
                      <!-- <div class="vision-mission">
                        <h5 class="about-title-sm text-left">Tagline:</h5>
                        <p>Hope. Succeed. Hope Bank Always.</p>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <back-to-top bottom="50px" right="50px">
        <button type="button" class="btn backtotop btn-to-top">
          <font-awesome-icon icon="chevron-up" class />
        </button>
      </back-to-top>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNavA from "../../components/HeaderNav/HeaderNavA";
import BackToTop from "vue-backtotop";
import Footer from "../../components/Footer/Footer";
export default {
  name: "About.vue",
  components: {
    HeaderNavA,
    BackToTop,
    Footer,
  },
  data() {
    return {
      toggle: false,
      toggle2: false,
      toggle3: false,
      toggle4: false,
      toggle5: false,
      toggle6: false,
      toggle7: false,
      readMore: false,
    };
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000 !important;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  /*color: #FECB32;*/
}
.slider {
  width: 100%;
  height: 105vh !important;
  position: absolute;
  z-index: 0;
}
/*.nav_header2{*/
/*margin-bottom: -10.1rem;*/
/*}*/

#__BVID__17__BV_tab_controls_ {
  background: #eff7f9 !important;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
  /* letter-spacing: 0.17px; */
}
.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
  /*border-bottom: 3px solid #FECB32;*/
}
.navbar-light .navbar-nav .nav-link.active {
  color: #fecb32 !important;
  /* border-bottom: 3px solid #FECB32; */
}
.navbar-light .navbar-nav .nav-link a.active {
  color: #fecb32 !important;
}
.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
  /*border-bottom: 3px solid #FECB32;*/
}
.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}
.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}

.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg a:hover {
  color: #000000 !important;
}
.yellow_bg > .navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}
.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}
.nav_menu_drop {
  color: #fecb32;
}

.about_icon {
  font-size: 40px;
  /* color: linear-gradient(180deg, #00c4ff 0%, #0091ff 100%) !important; */
  color: #000000;
}
.brand_logo {
  width: 100%;
  height: 100%;
  background: 0 0 no-repeat;
  background-size: contain;
  animation: brand_logo 3s infinite alternate
    cubic-bezier(0.43, 0.05, 0.6, 0.99);
}
@keyframes brand_logo {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(-5%);
  }
}
.about-title-sm {
  font-family: Montserrat-SemiBold;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  margin-bottom: 0.5rem;
}
.hope_management {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 1rem;
  /* height: 100%; */
  margin-bottom: 1.5rem;
  overflow: hidden;
}
.hope_management:hover {
  background: #ffffff;
  box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.2);
}
.img {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 50%;
  overflow: hidden;
}
.img:hover {
  background: #ffffff;
  /* box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.2); */
}
.img img {
  width: 100%;
}
.hope_management img {
  width: 100%;
  border-radius: 50%;
  text-align: center;
  transition: transform 0.5s ease;
  object-fit: contain;
  position: relative;
  z-index: 0;
}
.hope_management img:hover {
  transform: scale(1.2);
}
.hope_management_name {
  padding: 14px 10px;
  background: #ffffff;
  position: relative;
  z-index: 2;
  text-align: center;
  /* margin-bottom: 1rem; */
}
.hope_management_name h5 {
  font-family: Montserrat-SemiBold;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 170.5%;
  color: #000000;
  margin-bottom: 0;
}
.hope_management_name h6 {
  font-family: Montserrat-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 170.5%;
  color: #333333;
}
.hope_management_name p {
  font-family: Montserrat-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 170.5%;
  color: #333333;
  margin-bottom: 0;
}
.hope_management_name2 {
  text-align: left;
  padding-bottom: 2rem;
}
.hope_management_name2 h5 {
  font-family: Montserrat-SemiBold;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 170.5%;
  color: #000000;
  margin-bottom: 0;
}
.hope_management_name2 h6 {
  font-family: Montserrat-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 170.5%;
  color: #333333;
}
.hope_management_name2 p {
  font-family: Montserrat-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 170.5%;
  color: #333333;
  /* margin-bottom: 0; */
}
.open_account_btn {
  font-family: Montserrat-SemiBold !important;
  font-size: 13px !important;
  color: #000000 !important;
  background: #ffffff !important;
  border: 1px solid #fecb32 !important;
  /* margin-top: 1rem; */
  border-radius: 5px;
  padding: 10px 1.5rem;
  letter-spacing: 0.17px;
}
.blue_bg {
  background: #eff7f9;
}
/* Icon Wobble Horizontal starts*/
@-webkit-keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.hvr-icon-wobble-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-wobble-horizontal .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-wobble-horizontal:hover .hvr-icon,
.hvr-icon-wobble-horizontal:focus .hvr-icon,
.hvr-icon-wobble-horizontal:active .hvr-icon {
  -webkit-animation-name: hvr-icon-wobble-horizontal;
  animation-name: hvr-icon-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
/* Icon Wobble Horizontal ends*/
</style>
