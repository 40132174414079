<template>
  <div class="">
    <div class="container">
      <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
          <div class="footer-info">
            <!-- <img src="../../assets/images/icons/lock2.svg" alt="Hope Bank" /> -->
            <p>
              Your transactions are secure, always confirm Account Numbers, Phone Numbers
              and Bill Payment Numbers before making transactions
            </p>
          </div>
        </div>
      </div>
    </div>
    
    <!-- <div class="fixed-bottom">
      <div class="marquee">
        <div>
          <span>THIS WEBSITE IS FOR TESTING PURPOSE ONLY.</span>
          <span>THIS WEBSITE IS FOR TESTING PURPOSE ONLY.</span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Footer2.vue",
};
</script>

<style scoped>
.marquee {
  height: 35px;
  width: 100%;
  background: #fecb32;
  overflow: hidden;
  position: relative;
  padding: 0.5rem;
}

.marquee div {
  display: block;
  width: 200%;
  height: 30px;

  position: absolute;
  overflow: hidden;

  animation: marquee 5s linear infinite;
}

.marquee span {
  font-family: Montserrat-Medium;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  float: left;
  width: 50%;
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
</style>