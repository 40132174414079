<template>
  <div class>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNav2></HeaderNav2>

      <div class="container">
        <HeaderNav3></HeaderNav3>

        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <img src="../../../assets/images/icons/transfer-sm.svg" alt="Hope Bank" />
                  <p class="frequently-used">Confirm Transaction</p>
                </div>
                <div>
                  <b-tabs no-body v-model="tabIndex" class="generictab" ref="wizard">
                    <b-tab :active="one" :title-link-class="disabledTab">
                      <template v-slot:title>
                        <strong>Bills Payment</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Bills Payment</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                          <p>Please confirm your h Transactions Details</p>
                        </div>
                        <form action>
                          <div class="form-table2">
                            <!--  <div class="label-title2">
                              <label>Name</label>
                              <div
                                class="account_select3"
                                v-if="ProductId == 369"
                              >
                                <div class="confirm-account-info">
                                  {{ Name }}
                                </div>
                              </div>
                              <div
                                class="account_select3"
                                v-if="ProductId == 373"
                              >
                                <div class="confirm-account-info">
                                  {{ FirstName }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2" v-if="ProductId == 373">
                              <label>Middle Name</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ MiddleName }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2" v-if="ProductId == 373">
                              <label>Surname</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ Surname }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2" v-if="ProductId == 369">
                              <label>Email</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ Email }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Phone</label>
                              <div
                                class="account_select3"
                                v-if="ProductId == 369"
                              >
                                <div class="confirm-account-info">
                                  {{ Phone }}
                                </div>
                              </div>
                              <div
                                class="account_select3"
                                v-if="ProductId == 373"
                              >
                                <div class="confirm-account-info">
                                  {{ GSMNo }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Transaction Date</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ TransactionDate }}
                                </div>
                              </div>
                            </div> -->
                            <div class="label-title2">
                              <label>Account to Debit</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ account }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Beneficiary Details</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ beneficiary }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Category</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ Category }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Biller/Merchant</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ Merchant }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Description</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ Description }}
                                </div>
                              </div>
                            </div>
                            <!-- <div class="label-title2">
                              <label>Currency</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ Currency }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>ServiceName</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ ServiceName }}
                                </div>
                              </div>
                            </div> -->
                            <div class="label-title2">
                              <label>Product</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ Product }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  <!-- <span>&#8358;</span> -->
                                  {{ Amount }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Fee</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ Fee }}
                                </div>
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Total</label>
                              <div class="account_select3">
                                <div class="confirm-account-info">
                                  {{ Total }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="send-or-download2">
                            <div class="continue-button">
                              <button class="continue-btn" @click="$router.push('BillPayment')">
                                BACK
                                <font-awesome-icon icon="angle-left" class="angle-icon" />
                              </button>
                            </div>
                            <div class="continue-button">
                              <b-button @click="complete" class="continue-btn">
                                SUBMIT
                                <font-awesome-icon icon="angle-right" class="angle-icon" />
                              </b-button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar v-if="TRANSFER_ACCOUNT_FROM" :accountNumber="TRANSFER_ACCOUNT_FROM" :accountBalance="Balance">
              </SideBar>
              <SideBar v-if="TRANSFER_ACCOUNT" :accountNumber="TRANSFER_ACCOUNT" :accountBalance="Balance"></SideBar>
            </div>
          </div>
        </div>
        <Footer2 />
      </div>

      <!-- Confirmation Modal for Transfer to Hope, OtherAccount and Schedule transfer to hope account -->
      <b-modal id="modal-center" centered title="Confirm Transactions" v-model="confirm_transaction" @show="resetModal"
        @hidden="resetModal" @ok="handleOk" hide-footer>
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <p class="confirm-amount">
              You are about to pay for bills payment
              <span>
                <span>&#8358;</span>
                {{ Total }}
              </span>
              from {{ account }} to -
              <span> {{ Product }}</span>
            </p>
            <h1 class="pin-info text-center">Enter PIN</h1>
            <div class="dialpad">
              <div class="form-group">
                <the-mask type="password" class="form-control" v-model="pin" mask="########" id="txtBox"
                  placeholder="******" />
              </div>
              <Keyboard @pressed="pin = $event" v-if="routeQuery == 2" :pinLength="pin" @completed="transferHope"
                :selfValue="pin"></Keyboard>
              <Keyboard @pressed="pin = $event" v-if="routeQuery == 3" :pinLength="pin" @completed="transferOther"
                :selfValue="pin"></Keyboard>
              <Keyboard @pressed="pin = $event" v-if="routeQuery == 5" :pinLength="pin" @completed="scheduletransferHope"
                :selfValue="pin"></Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>
      <!-- Confirmation modal for transfer to Own account -->
      <b-modal id="modal-center3" centered title="Confirm Transactions" v-model="confirm_transaction_own"
        @show="resetModal" @hidden="resetModal" @ok="handleOk" hide-footer>
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
            <p class="confirm-amount">
              You are about to pay for bills payment
              <span>
                <span>&#8358;</span>
                {{ Total }}
              </span>
              from {{ account }} to -
              <span> {{ Product }}</span>
            </p>
            <!-- <div @click="transferOwn" class="btn btn-warning">Confirm</div> -->
            <h1 class="pin-info text-center">Enter PIN</h1>
            <div class="dialpad">
              <div class="form-group">
                <the-mask type="password" class="form-control" v-model="pin" mask="########" maxlength="8" id="txtBox"
                  placeholder="******" />
              </div>
              <Keyboard @pressed="pin = $event" :pinLength="pin" @completed="payBillVasPayment" :selfValue="pin">
              </Keyboard>
            </div>
          </b-form-group>
        </form>
      </b-modal>
      <!-- Transaction successful modal -->
      <b-modal id="modal-center2" centered v-model="transaction_successful" @show="resetModal" @hidden="resetModal"
        @ok="handleOk" hide-footer>
        <!-- <form ref="form" @submit.stop.prevent="handleSubmit"> -->
        <b-form-group :state="nameState" label-for="name-input" invalid-feedback="PIN is required" class="pin-info">
          <img src="../../../assets/images/icons/check.svg" alt="Hope Bank" class="modal_congrats_img" />
          <h1 class="pin-info text-center">
            Your bills payment was successful
          </h1>
          <div class="line4"></div>
          <p class="modal_congrats">
            Some transaction takes a few minutes to reflect
          </p>
          <!--<router-link to="Complete"><button class="ok-btn">OK</button></router-link>-->
          <div class="send-or-download2">
            <div>
              <button class="download-btn2" @click.prevent="ViewReceipt(ResponseData.data)">
                View Receipt
                <font-awesome-icon icon="angle-right" class="download-icon2" />
              </button>
            </div>
            <!-- <div>
              <button class="download-btn2" @click.prevent="AddBeneficiary">
                Save Beneficiary
                <font-awesome-icon icon="angle-right" class="download-icon2" />
              </button>
            </div> -->
          </div>
        </b-form-group>
        <!-- </form> -->
      </b-modal>
      <AppReceiptVue data-type="bill" @closeModal="receipt_modal = false" v-if="receipt_modal" />

    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import HeaderNav2 from "../../../components/HeaderNav/HeaderNav2";
import HeaderNav3 from "../../../components/HeaderNav/HeaderNav3";
import SideBar from "../../../components/SideBar/SideBar";
import { mapGetters, mapState } from "vuex";
import Loader from "../../../components/Loader/Loader.vue";
import Keyboard from "../../../components/Keyboard/Keyboard.vue";
import { transferService } from "../../../services/transfer.services.js";
import { VasPaymentService } from "../../../services/VasPayment.services.js";
import Footer2 from "../../../components/Footer/Footer2";
import AppReceiptVue from "../../../components/Receipt/AppReceipt.vue";


export default {
  name: "Confirmation.vue",
  components: {
    AppReceiptVue,
    TheMask,
    HeaderNav2,
    HeaderNav3,
    SideBar,
    Loader,
    Keyboard,
    Footer2,
  },
  data() {
    return {
      tabIndex: 0,
      password: "",
      nameState: null,
      submittedNames: [],
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      pin: "",
      loading: false,
      receipt_modal: false,
      transaction_successful: false,
      confirm_transaction: false,
      confirm_transaction_own: false,
      Account: null,
      Beneficiary: null,
      BeneficiaryName: null,
      Narration: null,
      Type: null,
      // Pin:null,
      ScheduleName: null,
      Frequency: null,
      Start: null,
      End: null,
      schedule: [],
      ResponseData: {},
    };
  },

  computed: {
    ...mapGetters({ user: "GET_USER", billData: "GET_BILL_DATA" }),

    ...mapState({
      AUTHENTICATION_TOKEN: (state) => state.User.AUTHENTICATION_TOKEN,
      TRANSFER_ACCOUNT: (state) => state.Transfer.TRANSFER_ACCOUNT,
      TRANSFER_ACCOUNT_FROM: (state) => state.Transfer.TRANSFER_ACCOUNT_FROM,
      TRANSFER_ACCOUNT_TO: (state) => state.Transfer.TRANSFER_ACCOUNT_TO,
      BENEFICIARY_ACCOUNT: (state) => state.Transfer.BENEFICIARY_ACCOUNT,
      BENEFICIARY_NAME: (state) => state.Transfer.BENEFICIARY_NAME,
      BENEFICIARY_BANK: (state) => state.Transfer.BENEFICIARY_BANK,
      TRANSFER_AMOUNT: (state) => state.Transfer.TRANSFER_AMOUNT,
      TRANSFER_TYPE: (state) => state.Transfer.TRANSFER_TYPE,
      TRANSFER_NOTE: (state) => state.Transfer.TRANSFER_NOTE,
      SCHEDULE_NAME: (state) => state.Transfer.SCHEDULE_NAME,
      FREQUENCY: (state) => state.Transfer.FREQUENCY,
      START: (state) => state.Transfer.START,
      END: (state) => state.Transfer.END,
      TYPE: (state) => state.Transfer.TYPE,
    }),

    routeQuery() {
      return this.$route.query.p;
    },

    disabledTab() {
      return ["disabled"];
    },

    Category: {
      get() {
        return this.billData.form.Category;
      },
      set(value) {
        if (this.billData.form.Category) {
          return (this.billData.form.Category = value);
        }
        return "";
      },
    },
    account: {
      get() {
        return this.billData.form.Account;
      },
      set(value) {
        if (this.billData.form.Account) {
          return (this.billData.form.Account = value);
        }
        return "";
      },
    },
    Merchant: {
      get() {
        return this.billData.form.Merchant;
      },
      set(value) {
        if (this.billData.form.Merchant) {
          return (this.billData.form.Merchant = value);
        }
        return "";
      },
    },

    Product: {
      get() {
        return this.billData.form.Product;
      },
      set(value) {
        if (this.billData.form.Product) {
          return (this.billData.form.Product = value);
        }
        return "";
      },
    },
    ProductId: {
      get() {
        return this.billData.form.ProductId;
      },
      set(value) {
        if (this.billData.form.ProductId) {
          return (this.billData.form.ProductId = value);
        }
        return "";
      },
    },
    beneficiary: {
      get() {
        return this.billData.form.Beneficiary;
      },
      set(value) {
        if (this.billData.form.Beneficiary) {
          return (this.billData.form.Beneficiary = value);
        }
        return "";
      },
    },
    AmountEntered: {
      get() {
        return this.billData.form.Amount;
      },
      set(value) {
        if (this.billData.form.Amount) {
          return (this.billData.form.Amount = value);
        }
        return "";
      },
    },
    Trans_Amt: {
      get() {
        return this.billData.response["trans_Amt"];
      },
      set(value) {
        if (this.billData.response["trans_Amt"]) {
          return (this.billData.response["trans_Amt"] = value);
        }
        return "";
      },
    },
    Amount: {
      get() {
        return this.billData.response["amount"];
      },
      set(value) {
        if (this.billData.response["amount"]) {
          return (this.billData.response["amount"] = value);
        }
        return "";
      },
    },

    Phone: {
      get() {
        return this.billData.response["phone"];
      },
      set(value) {
        if (this.billData.response["phone"]) {
          return (this.billData.response["phone"] = value);
        }
        return "";
      },
    },

    GSMNo: {
      get() {
        return this.billData.response["gsmNo"];
      },
      set(value) {
        if (this.billData.response["gsmNo"]) {
          return (this.billData.response["gsmNo"] = value);
        }
        return "";
      },
    },
    Surname: {
      get() {
        return this.billData.response["surname"];
      },
      set(value) {
        if (this.billData.response["surname"]) {
          return (this.billData.response["surname"] = value);
        }
        return "";
      },
    },
    MiddleName: {
      get() {
        return this.billData.response["middleName"];
      },
      set(value) {
        if (this.billData.response["middleName"]) {
          return (this.billData.response["middleName"] = value);
        }
        return "";
      },
    },
    FirstName: {
      get() {
        return this.billData.response["firstName"];
      },
      set(value) {
        if (this.billData.response["firstName"]) {
          return (this.billData.response["firstName"] = value);
        }
        return "";
      },
    },
    Name: {
      get() {
        return this.billData.response["name"];
      },
      set(value) {
        if (this.billData.response["name"]) {
          return (this.billData.response["name"] = value);
        }
        return "";
      },
    },

    TransactionDate: {
      get() {
        return this.billData.response["trans_Date"];
      },
      set(value) {
        if (this.billData.response["trans_Date"]) {
          return (this.billData.response["trans_Date"] = value);
        }
        return "";
      },
    },
    Email: {
      get() {
        return this.billData.response["email"];
      },
      set(value) {
        if (this.billData.response["email"]) {
          return (this.billData.response["email"] = value);
        }
        return "";
      },
    },
    Total: {
      get() {
        const total = Number(this.billData.response["fee"].slice(1)) + Number(this.billData.response["amount"].replace(/[^\d.-]/g, ""))
        return `₦${total?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
      },
      set(value) {
        const total = Number(this.billData.response["fee"].slice(1)) + Number(this.billData.response["amount"].replace(/[^\d.-]/g, ""))
        if (total) {
          return (this.billData.response["total"] = value);
        }
        return "";
      },
    },
    Fee: {
      get() {
        return this.billData.response["fee"];
      },
      set(value) {
        if (this.billData.response["fee"]) {
          return (this.billData.response["fee"] = value);
        }
        return "";
      },
    },

    ServiceName: {
      get() {
        return this.billData.response["serviceName"];
      },
      set(value) {
        if (this.billData.response["serviceName"]) {
          return (this.billData.response["serviceName"] = value);
        }
        return "";
      },
    },

    Currency: {
      get() {
        return this.billData.response["currency"];
      },
      set(value) {
        if (this.billData.response["currency"]) {
          return (this.billData.response["currency"] = value);
        }
        return "";
      },
    },

    Description: {
      get() {
        return this.billData.response["description"];
      },
      set(value) {
        if (this.billData.response["description"]) {
          return (this.billData.response["description"] = value);
        }
        return "";
      },
    },

    Balance() {
      if (this.TRANSFER_ACCOUNT_FROM)
        return this.user.accounts[
          this.user.accounts.findIndex(
            (a) => a.accountNumber == this.TRANSFER_ACCOUNT_FROM
          )
        ].balance;
      return this.user.accounts[
        this.user.accounts.findIndex(
          (a) => a.accountNumber == this.TRANSFER_ACCOUNT
        )
      ].balance;
    },
  },

  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid ? "valid" : "invalid";
      return valid;
    },

    complete() {
      this.confirm_transaction_own = true;
    },
    resetModal() {
      this.password = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs.modal.hide();
      });
    },
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },

    async payBillVasPayment() {
      this.loading = true;

      const data = {
        Account: this.account,
        Beneficiary: this.beneficiary,
        ProductId: this.ProductId,
        Amount:
          this.ProductId == "369"
            ? this.Amount.replace(/[^\d.-]/g, "")
            : this.ProductId == "373"
              ? this.Amount.replace(/[^\d.-]/g, "")
              : this.AmountEntered,
        token: this.AUTHENTICATION_TOKEN,
        Pin: this.pin,
        Fee: this.Fee.slice(1),
      };

      window.console.log(data);
      await VasPaymentService.paymentService(data)
        .then((res) => {
          window.console.log(res, 'res');
          this.$toastr.success(res.status || "Transaction Successful", {
            timeOut: 5000,
          });
          this.confirm_transaction_own = false;
          this.receipt_modal = true
          //this.$router.push("/receiptvas");

          if (res.data.token) {
            this.$store.commit("SET_BILL_DATA", {
              // response: res.data,
              new: 'new',
              response: res.data,
              form: this.billData.form,
            });
          } else {
            this.$store.commit("SET_BILL_DATA", {
              // response: res.data,
              response: res.data,
              new: 'new',
              form: this.billData.form,
            });
          }
        })
        .catch((error) => {
          this.$toastr.error(error.status, "Transaction Failed!", {
            timeOut: 5000,
          });
          this.$store.commit("SET_BILL_DATA", {})
        })
        .finally(() => {
          this.loading = false;
        });
    },

    //Transfer to Hope Account
    transferHope() {
      this.loading = true;
      this.$store
        .dispatch("TRANSFER_HOPE", {
          Pin: this.pin,
          token: this.AUTHENTICATION_TOKEN,
        })
        .then((res) => {
          this.loading = false;
          this.ResponseData = res;
          this.transaction_successful = true;
          this.confirm_transaction = false;
          this.$toastr.success(res.status, { timeOut: 5000 });
          this.$store.dispatch(
            "AUTHORISE_USER",
            this.$store.getters.GET_AUTH_TOKEN
          );
          //this.$router.push("/Dashboard");
          this.$store.dispatch("CLEAR_INPUTS");
        })
        .catch((err) => {
          this.loading = false;
          this.confirm_transaction = false;
          this.$toastr.error(err.status, "Transaction Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.pin = "";
          this.confirm_transaction = false;
          //this.$store.dispatch("CLEAR_INPUTS");
          //this.$router.go(-1)
        });
    },

    //Schedule transaction to Hope account
    scheduletransferHope() {
      this.loading = true;
      this.$store
        .dispatch("SCHEDULE_TRANSFER_HOPE", {
          Pin: this.pin,
          token: this.AUTHENTICATION_TOKEN,
        })
        .then((res) => {
          this.transaction_successful = true;
          this.$store.dispatch("CLEAR_INPUTS");
          this.$toastr.success(res.status, { timeOut: 5000 });
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Transaction Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
          this.pin = "";
          this.confirm_transaction = false;
          // this.$router.go(-1)
        });
    },

    //Transfer to Other Account
    transferOther() {
      this.loading = true;
      this.$store
        .dispatch("TRANSFER_OTHER", {
          Pin: this.pin,
          token: this.AUTHENTICATION_TOKEN,
        })
        .then((res) => {
          this.loading = false;
          this.ResponseData = res;
          this.transaction_successful = true;
          this.confirm_transaction = false;
          this.$toastr.success(res.status, { timeOut: 5000 });
          this.$store.dispatch(
            "AUTHORISE_USER",
            this.$store.getters.GET_AUTH_TOKEN
          );
          //this.$router.push("/Dashboard");
          this.$store.dispatch("CLEAR_INPUTS");
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Transaction Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
          this.pin = "";
          this.confirm_transaction = false;
          // this.$router.go(-1)
        });
    },

    //Transfer to Own Account
    transferOwn() {
      this.loading = true;
      this.$store
        .dispatch("TRANSFER_OWN", {
          Pin: this.pin,
          token: this.AUTHENTICATION_TOKEN,
        })
        .then((res) => {
          window.console.log("response", res);
          this.ResponseData = res;
          this.transaction_successful = true;
          this.$toastr.success(res.status, { timeOut: 5000 });
          this.$store.dispatch("CLEAR_INPUTS");
          this.$store.dispatch(
            "AUTHORISE_USER",
            this.$store.getters.GET_AUTH_TOKEN
          );
          // this.$router.push("/Dashboard");
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Transaction Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.pin = "";
          this.loading = false;
          this.confirm_transaction_own = false;
        });
    },

    //Add/Save Beneficiary
    AddBeneficiary() {
      this.loading = true;
      transferService
        .AddBeneficiary({
          TrxId: this.ResponseData.responseId,
          RRN: this.ResponseData.rrn,
        })
        .then(() => {
          this.$toastr.success("Beneficiary Added Successfully", {
            timeOut: 5000,
          });
          this.$router.push("/Dashboard");
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Failed!", {
            timeOut: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    ViewReceipt(receipt) {
      this.$store.commit("SET_TRANSACTION_DATA", receipt);
      this.$router.push("Transaction_Receipt");
    },
  },
  async getSchedule() {
    await transferService
      .getSchedule()
      .then((res) => {
        this.schedule = res;
        window.console.log("this is schedule", res);
      })
      .catch((err) => {
        this.$toastr.error(err.status, "Could not fetch vas Category", {
          timeOut: 5000,
        });
      });
  },
  mounted() {
    if (this.routeQuery == 1) return (this.one = true);
    if (this.routeQuery == 2) return (this.two = true);
    if (this.routeQuery == 3) return (this.three = true);
    if (this.routeQuery == 4) return (this.four = true);
    if (this.routeQuery == 5) return (this.five = true);
  },
};
</script>

<style scoped>
/*body{*/
/*width: 100%;*/
/*height: 100vh;*/
/*background-image: url("../../assets/images/hope-bg3.jpg");*/
/*background-size: cover;*/
/*background-repeat: no-repeat;*/
/*}*/
a {
  text-decoration: none;
  color: #000000;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}

.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}

.quick-transfer {
  padding: 0 0.7rem;
}

.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}

.modal-header {
  padding: 1rem 1rem 0 !important;
}

.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}

.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: center;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}

.btn-secondary:hover {
  color: #000000;
  background: #fecb32;
  border: 2px solid #fecb32;
}

.tabs-contents th {
  text-align: left;
  font-family: Montserrat-Medium;
  font-size: 12px;
  color: #000000;
  letter-spacing: 0.17px;
  background: #fecb32;
}

.confirm-amount {
  font-family: Montserrat-Medium;
  font-size: 15px;
  color: #4a4a4a;
  letter-spacing: 0;
  border-bottom: 1px solid #d0d0d0;
}

.confirm-amount span {
  font-family: Montserrat-Bold;
}

.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}

.disabledTab {
  pointer-events: none !important;
  background: red !important;
}

@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
