// eslint-disable vue/no-use-v-if-with-v-for
<template>
  <div class="poster-bg">
    <div class="poster"></div>
    <div class="dashboard-page registration-page">
      <Loader v-if="loading" :show-full="true" loading-text="Processing" />

      <div class>
        <HeaderNavRegister />
        <div class="register_now">
          <div class="container">
            <div class="main_dashboard">
              <div class="row">
                <div class="col-md-12">
                  <div class="main_dashboard_inner">
                    <div class="transfer_money2 text-center">
                      <p class="frequently-used text-center">Open Account</p>
                    </div>

                    <div class="current_tab_title mb-3">
                      <font-awesome-icon icon="play" class="play-icon" />
                      <p class>
                        All the information below are mandatory to create your
                        account
                      </p>
                    </div>

                    <div class="steps_to_acc_open">
                      <h2>
                        Enter Details -
                        <span class="h5">{{ ACCOUNT_TYPE.name }}</span>
                      </h2>
                    </div>

                    <form @submit.prevent="ValidateDirectors">
                      <div class="form-table2">
                        <div class="upload_profile_photo">
                          <div class="profile_photo">
                            <img
                              src="../../../assets/images/upload.svg"
                              alt="Hope Bank"
                            />
                          </div>
                          <!-- <label class="btn ml-n1 btn-info continue-btn">
                            Browse
                            <input
                              type="file"
                              class="mt-3 mt-md-0 mb-2 mb-md-1 small-screen-id"
                              style="display:none"
                              @change="onFileChange($event); validate($event)"
                              name="idCard"
                              id="id"
                            />
                          </label>-->

                          <!-- <button class="continue-btn">Browse</button> -->
                        </div>
                      </div>
                      <div
                        class="non_nigerian_form mb-5 mt-3"
                        v-for="(director, index) in CORPORATE.DIRECTORS_NO"
                        :key="director"
                      >
                        <div v-if="index == index_">
                          <h3>
                            <span v-if="!indexChange && index ===0">Enter Details of Chairman/President</span>
                            <span v-if="index == 1 && !indexChange"
                              >Enter Details of other Secretary/Another Trustees selected</span>
                          </h3>
                          <div
                            class="label-title2"
                            v-if="index == 1 && !indexChange"
                          >
                            <label>Do you have a Secretary?</label>
                            <div class="account_select">
                              <select
                                v-model="isCompanySec"
                                class="account_type"
                              >
                                <option value>--Select--</option>
                                <option value="cs" class="choose_account"
                                  >Yes - Secretary</option
                                >
                                <option value="d2" class="choose_account"
                                  >No - Another Trustee</option
                                >
                              </select>
                              <font-awesome-icon icon="caret-down" class />
                            </div>
                          </div>

                          <div class="label-title2">
                            <label>BVN <span class="red-star">*</span></label>
                            <div class="account_select2">
                              <the-mask
                                mask="###########"
                                type="tel"
                                class="input-amount"
                                v-model="Bvn"
                                placeholder="BVN"
                              />
                            </div>
                          </div>

                          <div class="label-title2">
                            <label
                              >Phone Number
                              <span class="red-star">*</span></label
                            >
                            <div class="account_select2">
                              <the-mask
                                mask="###########"
                                type="tel"
                                class="input-amount"
                                v-model="Phone_"
                                placeholder=" "
                              />
                            </div>
                          </div>

                          <div class="label-title2">
                            <label
                              >Date of Birth 
                              <span class="red-star">*</span></label
                            >
                            <DatePicker v-model="DateOfBirth_" valueType="format" format="MM-DD-YYYY" placeholder="MM-DD-YYYY"></DatePicker>
                          </div>
                        </div>
                      </div>

                      <!-- For Company secretary -->

                      <!-- v-if="BVN_DETAILS.data" -->
                      <div
                        class="non_nigerian_form mt-5"
                        v-if="BVN_DETAILS.data"
                      >
                        <h3>Personal Details</h3>
                        <div class="label-title2">
                          <label
                            >First Name <span class="red-star">*</span></label
                          >
                          <div class="account_select2">
                            <input
                              v-model="FirstName"
                              type="text"
                              :disabled="isDisabled"
                              class="input-amount"
                              placeholder=" "
                            />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Middle Name</label>
                          <div class="account_select2">
                            <input
                              v-model="MiddleName"
                              type="text"
                              :disabled="isDisabled"
                              class="input-amount"
                              placeholder=" "
                            />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label
                            >Last Name <span class="red-star">*</span></label
                          >
                          <div class="account_select2">
                            <input
                              v-model="LastName"
                              type="text"
                              :disabled="isDisabled"
                              class="input-amount"
                              placeholder=" "
                            />
                          </div>
                        </div>

                        <div class="label-title2">
                          <label>Phone Number</label>
                          <div class="account_select2">
                            <input
                              type="text"
                              class="input-amount"
                              v-model="Phone"
                              :disabled="isDisabled"
                              placeholder=" "
                            />
                          </div>
                        </div>
                        <div class="label-title2">
                          <label
                            >Date of Birth 
                            <span class="red-star">*</span></label
                          >
                          <div class="account_select">
                            <input type="text" 
                              placeholder="-DD-MM-YY-"
                              v-model="DateOfBirth"
                              :disabled="isDisabled"
                              class="input-amount"
                            >
                            <!-- <datetime
                              placeholder="-DD-MM-YY-"
                              v-model="DateOfBirth"
                              :disabled="isDisabled"
                              class="input-amount"
                            ></datetime>
                            <font-awesome-icon icon="calendar-check" /> -->
                          </div>
                        </div>

                        <!-- <div class="label-title2">
                          <label>Address</label>
                          <div class="account_select2">
                            <input
                              type="text"
                              class="input-amount"
                              v-model="NkAddress"
                              :disabled="isDisabled"
                              placeholder=" "
                            />
                          </div>
                        </div>-->

                        <div class="label-title">
                          <label>Gender</label>
                          <div class="account_select">
                            <select
                              v-model="Gender"
                              :disabled="isDisabled"
                              class="account_type"
                            >
                              <option value="Male" class="choose_account"
                                >Male</option
                              >
                              <option value="Female" class="choose_account"
                                >Female</option
                              >
                            </select>
                            <font-awesome-icon icon="caret-down" class />
                          </div>
                        </div>

                        <!-- <div class="label-title2">
                          <label>State of Origin</label>
                          <div class="account_select2">
                            <input
                              type="text"
                              class="input-amount"
                              :disabled="isDisabled"
                              placeholder=" "
                            />
                          </div>
                        </div>-->
                        <!-- <div class="label-title2">
                          <label>LGA of Origin</label>
                          <div class="account_select2">
                            <input
                              type="text"
                              class="input-amount"
                              :disabled="isDisabled"
                              placeholder=" "
                            />
                          </div>
                        </div>-->
                      </div>

                      <div
                        class="send-or-download2"
                        v-if="index_ < CORPORATE.DIRECTORS_NO"
                      >
                        <div class="continue-button">
                          <a @click="$router.go(-1)">
                            <div class="continue-btn ">
                              <font-awesome-icon
                                icon="angle-left"
                                class="download-icon"
                              />BACK
                            </div>
                          </a>
                        </div>
                        <div class="continue-button">
                          <!-- <b-button type="submit" :disabled="isDisabled" class="continue-btn">
                            Submit
                            <font-awesome-icon icon="angle-right" class="angle-icon" />
                          </b-button>-->
                          <b-button
                            type="submit"
                            v-if="isSubmit"
                            class="continue-btn"
                          >
                            Submit
                            <font-awesome-icon
                              icon="angle-right"
                              class="angle-icon"
                            />
                          </b-button>
                          <div
                            @click="Next"
                            v-if="isNext"
                            class="continue-btn "
                          >
                            Next
                            <font-awesome-icon
                              icon="angle-right"
                              class="angle-icon"
                            />
                          </div>
                          <!-- <router-link to="Complete"><button class="continue-btn">Create Account <font-awesome-icon icon="angle-right" class="angle-icon"/></button></router-link> -->
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </div>
  </div>
</template>

<script>
import HeaderNavRegister from "../../../components/HeaderNav/HeaderNavRegister";
// import {TheMask} from 'vue-the-mask';
import { CorporateAccountService } from "../../../services/CorporateAccount/corporateAccount.services.js";
import Loader from "../../../components/Loader/Loader.vue";
import { mapState } from "vuex";
import Footer2 from "../../../components/Footer/Footer2";

export default {
  name: "proceed.vue",
  components: {
    HeaderNavRegister,
    Loader,
    Footer2,
    // TheMask,
  },
  data() {
    return {
      image: "",
      Bvn: "",
      Phone_: "",
      DateOfBirth_: "",
      indexChange: false,
      index_: 0,
      details: null,
      isSubmit: true,
      isNext: false,
      isCompanySec: "",
      loading: false,
      isDisabled: true,
      Phone:"",
      DateOfBirth:""
    };
  },

  computed: {
    ...mapState({
      ACCOUNT_TYPE: (state) => state.CreateAccount.ACCOUNT_TYPE,
      BVN_DETAILS: (state) => state.CreateAccount.BVN_DETAILS,
      CORPORATE: (state) => state.CreateAccount.CORPORATE_ACCOUNT,
    }),
    FirstName: {
      get() {
        return this.BVN_DETAILS.data.firstName;
      },
      set(value) {
        this.BVN_DETAILS.data.firstName = value;
      },
    },
    LastName: {
      get() {
        return this.BVN_DETAILS.data.lastName;
      },
      set(value) {
        this.BVN_DETAILS.data.lastName = value;
      },
    },
    MiddleName: {
      get() {
        return this.BVN_DETAILS.data.middleName;
      },
      set(value) {
        this.BVN_DETAILS.data.middleName = value;
      },
    },
    phone: {
      get() {
        return this.BVN_DETAILS.data.phone;
      },
      set(value) {
        this.BVN_DETAILS.data.phone = value;
      },
    },
    dateOfBirth: {
      get() {
        return this.BVN_DETAILS.data.dateOfBirth;
      },
      set(value) {
        this.BVN_DETAILS.data.dateOfBirth = value;
      },
    },
    Gender: {
      get() {
        return this.BVN_DETAILS.data.gender;
      },
      set(value) {
        this.BVN_DETAILS.data.gender = value;
      },
    },
  },
  methods: {
    //Validate Directors
    async ValidateDirectors() {
      this.loading = true;
      await CorporateAccountService.validationDirector({
        company: this.CORPORATE.COMPANY_ID,
        Bvn: this.Bvn,
        Phone: this.Phone_,
        DateOfBirth: this.DateOfBirth_,
      })
        .then((res) => {
          window.console.log(res.data.phoneNumber)
          this.Phone = res.data.phoneNumber
          this.DateOfBirth =res.data.dateOfBirth
          this.isSubmit = false;
          this.isNext = true;
          this.$store.commit("SET_BVN_DETAILS", res);
          this.$store.commit("SET_CORPORATE_DIRECTORS", res.id);
        })
        .catch((err) => this.$toastr.error(err.status, "Failed"))
        .finally(() => {
          this.loading = false;
        });
    },
    //change
    // change() {
    //   this.details = {
    //     one: "one"
    //   };
    //   this.isSubmit = false;
    //   this.isNext = true;
    //   this.$store.commit("SET_CORPORATE_DIRECTORS", this.index_);
    // },
    Next() {

      if (this.isCompanySec == "cs") {
        this.$store.commit(
          "SET_CORPORATE_DIRECTORS_NO",
          this.CORPORATE.DIRECTORS_NO + 1
        );
        this.indexChange = true;
      }

      if (this.index_ >= this.CORPORATE.DIRECTORS_NO - 1)
        return this.$router.push("/signatories");

      this.index_++;
      this.isSubmit = true;
      this.isNext = false;
      this.Bvn = "";
      this.Phone_ = "";
      this.DateOfBirth_ = "";
      this.$store.commit("SET_BVN_DETAILS", {});
      this.isCompanySec = "";
    },
  },
  // beforeDestroy() {
  //   if (this.indexChange)
  //     this.$store.commit("SET_CORPORATE_IS_COMPANY_SEC", true);
  // },
};
</script>

<style scoped>
.img-fluid {
  height: 100px !important;
  width: 100px !important;
}
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.quick-transfer {
  padding: 0 2.2rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.3rem 0.73rem;
  margin: 0 0.6rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
}
.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
}
.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}
.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 44px;
}
.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}

.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #ffffff !important;
}
.yellow_bg > .navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}
.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}
.poster-bg {
  height: auto !important;
  background: none !important;
}
.main_dashboard {
  width: 75%;
  margin: 0 auto;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .main_dashboard {
    width: 100% !important;
  }
}
</style>
