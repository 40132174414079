import {
    ApiService
} from "./api.service";

const vasBillPaymentService = {
    //get the 4 major categories : Airtime/Data,  Bill Payment, Colections&Levies and Religion
    vasCategoryMain: async () => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                    method: 'GET',
                    url: ApiService.getBaseUrl() + `/api/web/catalog/vas/categories`,
                    headers: {
                        "Content-Type": "application/json",
                        "Hash": ApiService.computeHash('/api/web/catalog/vas/categories', localStorage.getItem('licence'))
                    },
                    redirect: 'follow'
                })
                .then((res) => {
                     resolve(res.data);
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });
    },
    vasCategory: async (id) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                    method: 'GET',
                    url: ApiService.getBaseUrl() + `/api/web/catalog/vas/category/${id}`,
                    headers: {
                        "Content-Type": "application/json",
                        "Hash": ApiService.computeHash(`/api/web/catalog/vas/category/${id}`, localStorage.getItem('licence'))
                    },
                    redirect: 'follow'
                })
                .then((res) => {
                     resolve(res.data);
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });
       

    },

};



export {
    vasBillPaymentService
};
