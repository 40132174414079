import { ApiService } from "./api.service";

const CreateAccountService = {
    createService: async ({
        Id,
        Address,
        Email,
        Nationality,
        FirstName,
        MiddleName,
        LastName,
        Gender,
        DateOfBirth,
        NkName,
        NkRelationship,
        NkPhone,
        NkAddress,
        ResState,
        ResTown,
        ResAddress,
        ResCountry,
        BizAddress,
        BizCountry,
        BizState,
        BizLGA,
        TIN,
        nin,
        Occupation,
        MaidenName,
        ZipCode,
        IdType,
        IdNo,
        IdIssueDate,
        IdExpiryDate,
        IdData,
        UtilityBill,
        Ref1Name,
        Ref1Email,
        Ref1Phone,
        Ref2Name,
        Ref2Email,
        Ref2Phone,
        CompanyIntro,
        CompanyId,
        OTP,
        Code,
        Product,
        Pin,
        Referrer,
        TurnOver,
    }) => {
        return new Promise(function (resolve, reject) {
            ApiService.post('/api/web/setup/create-account', {
                Id,
                Address,
                Email,
                Nationality,
                FirstName,
                MiddleName,
                LastName,
                Gender,
                DateOfBirth,
                NkName,
                NkRelationship,
                NkPhone,
                NkAddress,
                ResState,
                ResTown,
                ResAddress,
                ResCountry,
                BizAddress,
                BizCountry,
                BizState,
                BizLGA,
                TIN,
                nin,
                Occupation,
                MaidenName,
                ZipCode,
                IdType,
                IdNo,
                IdIssueDate,
                IdExpiryDate,
                IdData,
                UtilityBill,
                Ref1Name,
                Ref1Email,
                Ref1Phone,
                Ref2Name,
                Ref2Email,
                Ref2Phone,
                CompanyIntro,
                CompanyId,
                OTP,
                Code,
                Product,
                Pin,
                Referrer,
                TurnOver,
                Channel: "web",
            }).then((res) => {
                if (res.data.success) return resolve(res.data);
                reject(res.data)
            })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    },

    //Create account when logged in
    createServiceWhenLoggedIn: async ({ Id,
        Address,
        Email,
        Nationality,
        FirstName,
        MiddleName,
        LastName,
        Gender,
        DateOfBirth,
        NkName,
        NkRelationship,
        NkPhone,
        NkAddress,
        ResState,
        ResTown,
        ResAddress,
        ResCountry,
        BizAddress,
        BizCountry,
        BizState,
        BizLGA,
        TIN,
        nin,
        Occupation,
        MaidenName,
        ZipCode,
        IdType,
        IdNo,
        IdIssueDate,
        IdExpiryDate,
        IdData,
        UtilityBill,
        Ref1Name,
        Ref1Email,
        Ref1Phone,
        Ref2Name,
        Ref2Email,
        Ref2Phone,
        CompanyIntro,
        CompanyId,
        OTP,
        Code,
        Product,
        Pin,
        Account,
        Amount,
        Referrer,
        TurnOver
    }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                method: 'POST',
                url: ApiService.getBaseUrl() + "/api/web/setup/create-account",
                data: {
                    Id,
                    Address,
                    Email,
                    Nationality,
                    FirstName,
                    MiddleName,
                    LastName,
                    Gender,
                    DateOfBirth,
                    NkName,
                    NkRelationship,
                    NkPhone,
                    NkAddress,
                    ResState,
                    ResTown,
                    ResAddress,
                    ResCountry,
                    BizAddress,
                    BizCountry,
                    BizState,
                    BizLGA,
                    TIN,
                    nin,
                    Occupation,
                    MaidenName,
                    ZipCode,
                    IdType,
                    IdNo,
                    IdIssueDate,
                    IdExpiryDate,
                    IdData,
                    UtilityBill,
                    Ref1Name,
                    Ref1Email,
                    Ref1Phone,
                    Ref2Name,
                    Ref2Email,
                    Ref2Phone,
                    CompanyIntro,
                    CompanyId,
                    OTP,
                    Code,
                    Product,
                    Pin,
                    Account,
                    Amount,
                    Referrer,
                    TurnOver,
                    Channel: "web",


                },
                headers: {
                    "Content-Type": "application/json",
                    "Hash": ApiService.computeHash('/api/web/setup/create-account', localStorage.getItem('licence'))
                },
            })
                .then((res) => {
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    },
    //Validate Bulk wallet
    bulkWalletValidate: async ({ Account, Corporate, data, token }) => {
        const formData = new FormData();
        formData.set("Account", Account);
        formData.set("Corporate", Corporate);
        formData.set("data", data);

        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/transaction/wallets/validate', localStorage.getItem("licence"))
                },
                data: formData,
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/transaction/wallets/validate"

            })
                .then((res) => {
                    if (res.data.success) {
                        return resolve(res.data);
                    }
                    else {
                        reject(res.data)
                    }
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });
    },

    //Initiate bulk wallet
    bulkWalletInitiate: async ({ Account, Corporate, Id, Pin, token }) => {

        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Hash': ApiService.computeHash('/api/web/transaction/wallets/initiate', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    Id,
                    Corporate,
                    Pin
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/transaction/wallets/initiate"

            })
                .then((res) => {

                    if (res.data.success) {
                        return resolve(res.data);
                    }
                    else {
                        reject(res.data)
                    }
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });
    },

    //complete ongoing account process
    completeOngoingPersonalAccount: ({ Phone }) => {
        return new Promise(function (resolve, reject) {
            ApiService.post(`/api/web/setup/retrieve/${Phone}`)
                .then((res) => {
                    window.console.log("this is the response you know", res)
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)

                })
        });

    },
    //complete ongoing account process
    completeOngoingBusinessAccount: ({ Phone }) => {
        return new Promise(function (resolve, reject) {
            ApiService.post(`/api/web/corporate/setup/retrieve/${Phone}`)
                .then((res) => {
                    window.console.log("this is the response you know", res)
                    if (res.data.success) return resolve(res.data);

                    reject(res.data)

                })
        });

    },

    //save create account details
    saveAccountDetails: async ({
        Id,
        Address,
        Email,
        Nationality,
        FirstName,
        MiddleName,
        LastName,
        Gender,
        DateOfBirth,
        NkName,
        NkRelationship,
        NkPhone,
        NkAddress,
        ResState,
        ResTown,
        ResAddress,
        ResCountry,
        BizAddress,
        BizCountry,
        BizState,
        BizLGA,
        TIN,
        NIN,
        Occupation,
        MaidenName,
        ZipCode,
        IdType,
        IdNo,
        IdIssueDate,
        IdExpiryDate,
        IdData,
        UtilityBill,
        Ref1Name,
        Ref1Email,
        Ref1Phone,
        Ref2Name,
        Ref2Email,
        Ref2Phone,
        CompanyIntro,
        CompanyId,
        OTP,
        Code,
        Product,
        Pin
    }) => {
        return new Promise(function (resolve, reject) {
            ApiService.post('/api/web/setup/save', {
                Id,
                Address,
                Email,
                Nationality,
                FirstName,
                MiddleName,
                LastName,
                Gender,
                DateOfBirth,
                NkName,
                NkRelationship,
                NkPhone,
                NkAddress,
                ResState,
                ResTown,
                ResAddress,
                ResCountry,
                BizAddress,
                BizCountry,
                BizState,
                BizLGA,
                TIN,
                NIN,
                Occupation,
                MaidenName,
                ZipCode,
                IdType,
                IdNo,
                IdIssueDate,
                IdExpiryDate,
                IdData,
                UtilityBill,
                Ref1Name,
                Ref1Email,
                Ref1Phone,
                Ref2Name,
                Ref2Email,
                Ref2Phone,
                CompanyIntro,
                CompanyId,
                OTP,
                Code,
                Product,
                Pin,
                Channel: "web"
            })
                .then((res) => {
                    window.console.log("this is save data response", res)
                    if (res.data.success) return resolve(res.data);
                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        });

    },



};
// /api/web/setup/retrieve / 08164582517



export {
    CreateAccountService
};
