<template>
  <div class>
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNavBiz />
      <div class="container">
        <BusinessNavBar />
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <p class="frequently-used">Update Security Questions</p>
                </div>
                <div class="current_tab_title mb-3">
                  <font-awesome-icon icon="play" class="play-icon" />
                  <p class>You can change your security question and answer</p>
                </div>
                <div class="kindly_enter_details mb-3">
                  <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                  <p>To reset your security question and answer, kindly fill the followings</p>
                </div>
                <form action>
                  <div class="form-table">
                    <div class="label-title">
                      <label>Old Security Question *</label>
                      <div class="account_select">
                        <select name id class="account_type">
                          <option value class="choose_account">- Select -</option>
                          <option value class="choose_account">What is my name?</option>
                          <option value class="choose_account">Where did you meet your spouse?</option>
                          <option value class="choose_account">What is the name of your best friend?</option>
                        </select>
                        <font-awesome-icon icon="caret-down" class />
                      </div>
                    </div>
                    <div class="label-title2">
                      <label>Old Secret Answer *</label>
                      <div class="account_select2">
                        <input type="text" class="input-amount" placeholder=" " />
                      </div>
                    </div>
                    <div class="line3"></div>
                    <div class="label-title2">
                      <label>New Secret Question *</label>
                      <div class="account_select">
                        <select name id class="account_type">
                          <option value class="choose_account">- Select -</option>
                          <option value class="choose_account">What is my name?</option>
                          <option value class="choose_account">Where did you meet your spouse?</option>
                          <option value class="choose_account">What is the name of your best friend?</option>
                        </select>
                        <font-awesome-icon icon="caret-down" class />
                      </div>
                    </div>
                    <div class="label-title2">
                      <label>New Secret Answer *</label>
                      <div class="account_select2">
                        <input type="text" class="input-amount" placeholder=" " />
                      </div>
                    </div>
                    <div class="line3"></div>
                    <div class="label-title2">
                      <label>OTP/Token *</label>
                      <div class="account_select2">
                        <the-mask mask="######" class="input-amount" placeholder="0" />
                      </div>
                    </div>

                    <div class="send-or-download2">
                      <div class="continue-button">
                        <a @click="$router.go(-1)">
                          <div class="continue-cib-btn ">
                            <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                          </div>
                        </a>
                      </div>
                      <div class="continue-button">
                        <!--<b-button v-b-modal.modal-center class="continue-cib-btn">Create Account <font-awesome-icon icon="angle-right" class="angle-icon"/></b-button>-->
                        <router-link to="Dashboard">
                          <button class="continue-cib-btn">
                            Create Account
                            <font-awesome-icon icon="angle-right" class="angle-icon" />
                          </button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-4">
              <SideBar></SideBar>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>
      <b-modal
        id="modal-center"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <h1 class="pin-info text-center">
              Insert PIN to finish
              <br />transaction
            </h1>
            <the-mask mask="####" type="password" class="input-pin" placeholder="****" required />
            <div class="pin_input">
              <div class="pin_input2">
                <div class="pin-input">1</div>
                <div class="pin-input">2</div>
                <div class="pin-input">3</div>
                <div class="pin-input">4</div>
              </div>
              <div class="pin_input2">
                <div class="pin-input">5</div>
                <div class="pin-input">6</div>
                <div class="pin-input">7</div>
                <div class="pin-input">8</div>
              </div>
              <div class="pin_input2">
                <div class="pin-input">9</div>
                <div class="pin-input">0</div>
                <div class="pin-input">
                  <font-awesome-icon icon="backspace" class />
                </div>
                <button class="pin-input">OK</button>
              </div>
            </div>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import HeaderNavBiz from "../../../../components/HeaderNav/HeaderNavBiz";
import BusinessNavBar from "../../../../components/HeaderNav/BusinessNavBar";
import SideBar from "../../../../components/SideBar/SideBar_";
import Footer3 from "../../../../components/Footer/Footer3";

export default {
  name: "UpdateSecurityQuestion.vue",
  components: {
    TheMask,
    HeaderNavBiz,
    BusinessNavBar,
    SideBar,
    Footer3,
  },
  data() {
    return {
      price: "",
    };
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}
.quick-transfer {
  padding: 0 2.2rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
/* .poster {
  background-color: rgb(0, 94, 194, 0.1) !important;
} */
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
