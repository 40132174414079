import {
    ApiService
} from "./api.service";

const securityService = {
    //retrieve security questions
    resetPin: async (Account) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Hash': ApiService.computeHash('/api/web/auth/forgot-pin', localStorage.getItem("licence"))
                },
                data: {
                    Account
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/auth/forgot-pin"

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },
    resetCorporatePin: async (Account) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Hash': ApiService.computeHash('/api/web/corporate/auth/forgot-pin', localStorage.getItem("licence"))
                },
                data: {
                    Account
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/auth/forgot-pin"

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //answer security questions
    answerSecurityQuestions: async ({ answer1, answer2, answer3, Account, keys }) => {
        return new Promise(function (resolve, reject) {
            let Answers = {}
            Answers[keys[0]] = answer1
            Answers[keys[1]] = answer2
            Answers[keys[2]] = answer3

            ApiService.customRequest({
                headers: {
                    'Hash': ApiService.computeHash('/api/web/auth/forgot-pin/quest', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    Answers
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/auth/forgot-pin/quest"

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //answer security questions corporate
    answerCorporateSecurityQuestions: async ({ answer1, answer2, answer3, Account, keys }) => {
        return new Promise(function (resolve, reject) {
            let Answers = {}
            Answers[keys[0]] = answer1
            Answers[keys[1]] = answer2
            Answers[keys[2]] = answer3

            ApiService.customRequest({
                headers: {
                    'Hash': ApiService.computeHash('/api/web/corporate/auth/forgot-pin/quest', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    Answers
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/auth/forgot-pin/quest"

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //Update/Reset pin
    updatePin: async ({ Account, Code, Pin }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Hash': ApiService.computeHash('/api/web/auth/forgot-pin/code', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    Code,
                    Pin
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/auth/forgot-pin/code"

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //update corporate pin
    updateCorporatePin: async ({ Account, Code, Pin }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Hash': ApiService.computeHash('/api/web/corporate/auth/forgot-pin/code', localStorage.getItem("licence"))
                },
                data: {
                    Account,
                    Code,
                    Pin
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/auth/forgot-pin/code"

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //Change pin to new pin
    changePin: async ({ account, oldPin, newPin, }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Hash': ApiService.computeHash('/api/web/auth/change-pin', localStorage.getItem("licence"))
                },
                data: {
                    account,
                    oldPin,
                    newPin
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/auth/change-pin"
            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },
    //Change pin to new pin for corporate account
    CorporateChangePin: async ({ account, oldPin, newPin, }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Hash': ApiService.computeHash('/api/web/corporate/auth/change-pin', localStorage.getItem("licence"))
                },
                data: {
                    account,
                    oldPin,
                    newPin
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/corporate/auth/change-pin"
            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //Change password to new password
    changePassword: async ({ oldPassword, newPassword }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Hash': ApiService.computeHash('/api/web/auth/change-password', localStorage.getItem("licence"))
                },
                data: {
                    oldPassword,
                    newPassword
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/auth/change-password"
            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //retrieve security questions
    resetPassword: async (Account) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    // "Content-Type": "application/json"
                    'Hash': ApiService.computeHash('/api/web/auth/forgot-password', "124114982AA640178097CEBDDFA0E050")
                },
                data: {
                    Account
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/auth/forgot-password"

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //answer security questions
    answerSecurityQuestions2: async ({ answer1, answer2, answer3, Account, keys }) => {
        return new Promise(function (resolve, reject) {
            let Answers = {}
            Answers[keys[0]] = answer1
            Answers[keys[1]] = answer2
            Answers[keys[2]] = answer3

            ApiService.customRequest({
                headers: {
                    'Hash': ApiService.computeHash('/api/web/auth/forgot-password/quest', "124114982AA640178097CEBDDFA0E050")
                },
                data: {
                    Account,
                    Answers
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/auth/forgot-password/quest"

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //Update/Reset pin
    updatePassword: async ({ Account, Code, Password }) => {
        return new Promise(function (resolve, reject) {
            ApiService.customRequest({
                headers: {
                    'Hash': ApiService.computeHash('/api/web/auth/forgot-password/code', "124114982AA640178097CEBDDFA0E050")
                },
                data: {
                    Account,
                    Code,
                    Password
                },
                method: "POST",
                url: ApiService.getBaseUrl() + "/api/web/auth/forgot-password/code"

            })
                .then((res) => {
                    if (res.data.success)
                        return resolve(res.data);

                    reject(res.data)
                })
                .catch(err => {
                    reject(err.response.data)
                })

        });
    },

    //reset pin
    resendOtp: async ({ id, code }) => {
        return new Promise((resolve, reject) => {
            ApiService.customRequest({
                headers: {
                    'Hash': ApiService.computeHash('/api/web/setup/resend-code')
                },
                data: {
                    id,
                    code
                },
                method: "POST",
                url: ApiService.getBaseUrl() + '/api/web/setup/resend-code'

            }).then((res) => {
                if (res.data.success)
                    return resolve(res.data)
                reject(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })

    }

};



export {
    securityService
};