<template>
    <div>
      <div class="main_body" @click="closeModal()">
        <div class="receipt_page" @click="(event) => event.stopPropagation()">
          <div ref="html" class="receipt_page_download">
            <div  class="receipt_body">
              <div class="receipt_body_heading">
                  <div>Transaction Receipt</div>
                  <img
                  src="../../assets/images/banner.svg"
                  alt="Hope Bank Logo" width="100%"
                  />
              </div>

              <div class="receipt_tick">
                <img src="../../assets/images/icons/transaction_tick.svg" alt="">
                <h5><b>Successful Transaction</b></h5>
                <p>{{ TRANSACTION_DETAILS.date }}</p>
              </div>

              <div class="receipt_body_details">
                <div class="receipt_body_detail">
                  <div>Account Name</div>
                  <span><b>{{ USER.accounts[0].accountName }}</b></span>
                </div>

                <div class="receipt_body_detail">
                  <div>Account Number</div>
                  <span v-if="TRANSACTION_DETAILS.account"><b>{{hashAccount(TRANSACTION_DETAILS.account)}}</b></span>
                </div>

                <div class="receipt_body_detail" v-if='TRANSACTION_DETAILS["beneficiary Name"]'>
                  <div>Beneficiary Name</div>
                  <span><b>{{TRANSACTION_DETAILS["beneficiary Name"]}}</b></span>
                </div>

                <div class="receipt_body_detail" v-if="TRANSACTION_DETAILS.beneficiary">
                  <div>Beneficiary Account Number</div>
                  <span><b>{{ TRANSACTION_DETAILS.beneficiary }}</b></span>
                </div>

                <div class="receipt_body_detail" v-if="TRANSACTION_DETAILS['destination Bank']">
                  <div>Beneficiary Bank</div>
                  <span><b>{{TRANSACTION_DETAILS['destination Bank']}}</b></span>
                </div>

                <div class="receipt_body_detail">
                  <div>Transaction Amount</div>
                  <span><b>{{ TRANSACTION_DETAILS.amount }}</b></span>
                </div>
                <div v-if="TRANSACTION_DETAILS.description" class="receipt_body_detail">
                  <div>Transaction Note</div>
                  <span><b>{{ TRANSACTION_DETAILS.description }}</b></span>
                </div>

                <div v-if="TRANSACTION_DETAILS['reference Number']" class="receipt_body_detail">
                  <div>Reference No.</div>
                  <div><b>{{TRANSACTION_DETAILS["reference Number"]}}</b></div>
                </div>

                <div v-if="TRANSACTION_DETAILS.status" class="receipt_body_detail">
                  <div>Transaction Status</div>
                  <span><b :class="{approved : TRANSACTION_DETAILS.status = 'Approved'}">{{ TRANSACTION_DETAILS.status }}</b></span>
                </div>
                
              </div>
            </div>
          </div>
          <div class="receipt_cta">
            <button><font-awesome-icon icon="envelope" class="download-icon" /> Send to Email</button>
            <button @click="exportToPDF"><font-awesome-icon icon="download" class="download-icon" />Download</button>
          </div>
        </div>

      </div>
    </div>
  </template>
  
<script>
import { mapGetters } from "vuex";
import html2pdf from "html2pdf.js";

export default {
  name: "TransactionReceipt",
  data() {
    return {
      post: null,
    };
  },
  computed: {
    ...mapGetters({
      TRANSACTION_DETAILS: "GET_TRANSACTION",
      USER: "GET_USER",
    }),
  },

  methods: {
    closeModal(){
      this.$emit('closeModal')
    },
    hashAccount(string) {
      let slicedFront = string.slice(0, 3);
      let slicedBack = string.slice(7);
      return `${slicedFront}****${slicedBack}`;
    },

    //download html as pdf
    exportToPDF() {
      html2pdf(this.$refs.html, {
        margin: 0,
        compress: true,
        filename: `HOPEPSBANK RECEIPT.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 392, letterRendering: true },
        jsPDF: { unit: "in", format: "a5", orientation: "portrait" },
      });
    },
  },
};
</script>
  
<style scoped>
.approved{
  color: rgb(0, 203, 0)
}
p{
  margin: 0
}
.close{
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
.main_body{
  padding: 40px 0;
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: scroll;
  display: grid;
  place-items: center;
  z-index: 2001;
  backdrop-filter: blur(10px);
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.381);
}
.receipt_page{
  margin:40px 0;
  background: white;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 500px;
  width: 90%;
  border-radius: 10px;
  z-index: 2002;
}
.receipt_page_download{
  padding: 30px;
  padding-bottom: 20px;

}
.receipt_logo img{
  border-radius: 20px;
  width: 100px;
}
.receipt_body_heading{
  margin: 30px 0;
  margin-top: 0px;
  text-align: center;
  position: relative;
  background: #E6AA02;
  padding: 12px;
  border-radius: 10px;
  color: white

}
.receipt_body_heading div{
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px
}
.receipt_body_heading img{
  position: absolute;
  width: 70px;
  top: 50%;
  right: 10px;
  transform: translateY(-50%)
}
.receipt_body_heading p:first-child{
  margin-bottom: 10px;
}
.receipt_tick{
  text-align: center
}
.receipt_tick img{
  width: 100px;
  margin-bottom: 20px
}
.receipt_body_details{
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #0053C5;
  border-bottom: 1px solid #0053C5;
  height: 30vh;
  overflow: hidden;
  overflow-y: scroll;

}
.receipt_body_detail{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  font-size: 14px;
}
.receipt_body_detail div:first-child{
  color: #E6AA02
}
.receipt_body_detail:last-child{
  margin-bottom: 0
}
.receipt_body_detail div{
  font-weight: 700;

}
.receipt_body_detail span:first-child{
  font-weight: 100
}
.receipt_cta{
  display: flex;
  justify-content: space-between; 
  margin-top: 20px;
  padding: 40px;
  padding-top: 0px 
}
.receipt_cta button{
  padding: 10px;
  width: 100%;
  color: #FFF;
  font-weight: 700;
  border-radius: 10px;
  background: #E6AA02;
}
.receipt_cta button:first-child{
  margin-right: 20px;
  background: none;
  color: #E6AA02;
  border: 1px solid #E6AA02;
}
.receipt_cta svg{
  margin-right: 10px
}
@media screen and (max-width: 474px){
  .receipt_page_download{
    padding: 14px
  }
  .receipt_body_heading div{
    font-size: 14px
  }
  .receipt_body_detail{
  font-size: 11px
}
  .receipt_cta{
    padding: 20px;
    padding-top: 0px
  }
  .receipt_cta button{
    font-size: 14px
    padding
  }
  .receipt_cta button svg{
    width: 20px;
  }
}
</style>