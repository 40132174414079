
const mutations = {
    SET_USER_DATA: (state, user) => state.USER = user,
    SET_BVN: (state, payload) => state.BVN = payload,
    SET_AUTH_TOKEN: (state, authentication) => {
        state.AUTHENTICATION_TOKEN = authentication;
    },
    REMOVE_AUTH: (state) => {
        state.AUTHENTICATION_TOKEN = '';
        state.USER = '';
    },
    SET_VALIDATION_CREDENTIALS (state, payload)  {
        state.VALIDATION_CREDENTIALS = payload
    },
    SET_SECURITY_QUESTIONS (state, payload)  {
        state.SECURITY_QUESTIONS = payload
    },
    SET_VERIFICATION_CODE (state, payload)  {
        state.VERIFICATION_CODE = payload
    },
     SET_FILTERED_USER (state, payload)  {
        state.FILTERED_USER = payload
    },
    SET_LOGGED_IN_BUSINESS(state, payload) {
        state.LOGGED_IN_BUSINESS = payload
    },
    SET_ACCOUNT_NUMBER(state, payload) {
        state.ACCOUNT_NUMBER = payload
    },
    
};

export {
    mutations
};