<template>
  <div class>
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />
    <div class="poster"></div>
    <div class="dashboard-page">
      <HeaderNavBiz />
      <div class="container">
        <BusinessNavBar />
        <div class="main_dashboard">
          <div class="row">
            <div class="col-md-8">
              <div class="main_dashboard_inner">
                <div class="transfer_money">
                  <img
                    src="../../../../assets/images/icons/transfer-sm.svg"
                    alt="Hope Bank"
                  />
                  <p class="frequently-used">Single Transfer</p>
                </div>
                <div class="cib-color-change">
                  <b-tabs no-body>
                    <b-tab :active="two" id="HopeTransfer" @click="hopeTab">
                      <template v-slot:title class="left">
                        To
                        <br />
                        <strong>Hope PS Bank</strong>
                        <!--<b-spinner type="grow" small></b-spinner> Tab <strong>2</strong>-->
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Transfer Funds to Hope PS Bank Account</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon
                            icon="exclamation-circle"
                            class="exclamation-icon"
                          />
                          <p>Kindly enter Transfer Details</p>
                        </div>
                        <form action>
                          <div class="form-table">
                            <div class="label-title">
                              <label>Transfer From</label>
                              <div class="account_select">
                                <select
                                  v-model="transferAccount"
                                  name
                                  id
                                  class="account_type"
                                >
                                  <option
                                    v-for="item in user.corporateAccounts"
                                    :key="item + 'a'"
                                    :value="item.accountNumber"
                                  >
                                    {{ item.accountName }} -
                                    {{ item.accountNumber }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="beneficiary_details">
                              <p>Beneficiary Details</p>
                            </div>
                            <div class="label-title2">
                              <label>Account Number</label>
                              <div class="account_select2">
                                <!-- <the-mask
                                  mask="###########"
                                  v-model="beneficiaryAccount"
                                  class="input-amount"
                                  placeholder
                                /> -->
                                <input
                                  maxlength="11"
                                  v-model="beneficiaryAccount"
                                  class="input-amount"
                                  placeholder
                                  required
                                  @blur="validateBeneficiary"
                                />
                                <!--<input type="number" class="input-amount" placeholder="-"/>-->
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Name</label>
                              <div class="account_select2">
                                <input
                                  class="input-amount"
                                  disabled
                                  style="background: white; height: 35px"
                                  v-model="beneficiaryName"
                                />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select2">
                                <currency-input
                                  v-model="transferAmount"
                                  class="input-amount"
                                  currency="NGN"
                                  precision="2"
                                  required
                                />
                                <!-- <vue-numeric
                                  class="input-amount"
                                  currency="₦"
                                  separator=","
                                  v-bind:precision="2"
                                  v-model="transferAmount"
                                ></vue-numeric> -->
                              </div>
                            </div>
                            <!-- <div class="label-title2">
                              <label class="label">Save Beneficiary?</label>
                              <div></div>
                              <div></div>
                              <b-form-checkbox
                                size="lg"
                                v-model="saveBeneficiary"
                                name="check-button"
                                switch
                              ></b-form-checkbox>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>-->
                            <div class="label-title2">
                              <label>Description</label>
                              <div class="account_select2">
                                <input
                                  type="text"
                                  class="input-amount"
                                  v-model="transferNote"
                                  placeholder="Say something about the transaction"
                                />
                              </div>
                            </div>

                            <div class="send-or-download2">
                              <div class="continue-button">
                                <a @click="$router.go(-1)">
                                  <div class="continue-cib-btn">
                                    <font-awesome-icon
                                      icon="angle-left"
                                      class="download-icon"
                                    />BACK
                                  </div>
                                </a>
                              </div>
                              <router-link to="Confirmation_?p=2">
                                <div class="continue-button">
                                  <button class="continue-cib-btn">
                                    CONTINUE
                                    <font-awesome-icon
                                      icon="angle-right"
                                      class="angle-icon"
                                    />
                                  </button>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </b-tab>
                    <b-tab :active="three" id="OtherBank" @click="hopeTab">
                      <template v-slot:title>
                        To
                        <br />
                        <strong>Other Bank</strong>
                      </template>
                      <div class="tabs-contents">
                        <div class="current_tab_title">
                          <font-awesome-icon icon="play" class="play-icon" />
                          <p class>Transfer Funds to Other Account</p>
                        </div>
                        <div class="kindly_enter_details">
                          <font-awesome-icon
                            icon="exclamation-circle"
                            class="exclamation-icon"
                          />
                          <p>Kindly enter Transfer Details</p>
                        </div>
                        <form action>
                          <div class="form-table2">
                            <div class="label-title">
                              <label>Transfer From</label>
                              <div class="account_select">
                                <select
                                  v-model="transferAccount"
                                  name
                                  id
                                  class="account_type"
                                >
                                  <option
                                    v-for="item in user.corporateAccounts"
                                    :key="item + 'a'"
                                    :value="item.accountNumber"
                                  >
                                    {{ item.accountName }} -
                                    {{ item.accountNumber }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="beneficiary_details">
                              <p>Beneficiary Details</p>
                            </div>
                            <!-- <div class="label-title">
                                                        <label >Saved Beneficiaries</label>
                                                        <div class="account_select">
                                                            <select name="" id="" class="account_type">
                                                                <option value="" class="choose_account">— Beneficiary’s Account —</option>
                                                                <option value="" class="choose_account">Chukwudi Okoro  - 01234567890</option>
                                                            </select>
                                                            <font-awesome-icon icon="caret-down" class=""/>
                                                        </div>
                            </div>-->
                            <div class="label-title">
                              <label>Bank</label>
                              <div class="account_select">
                                <select
                                  name
                                  id
                                  v-model="beneficiaryBank"
                                  class="account_type"
                                >
                                  <option value class="choose_account">
                                    - Select Bank -
                                  </option>
                                  <option
                                    v-for="item in INSTITUTIONS"
                                    :key="item"
                                    :value="item"
                                    class="choose_account"
                                  >
                                    {{ item.label }}
                                  </option>
                                </select>
                                <font-awesome-icon icon="caret-down" class />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Number</label>
                              <div class="account_select2">
                                <input
                                  maxlength="11"
                                  v-model="beneficiaryAccount"
                                  class="input-amount"
                                  placeholder
                                  required
                                  @blur="validateBeneficiary"
                                />
                                <!-- <the-mask
                                  mask="###########"
                                  v-model="beneficiaryAccount"
                                  class="input-amount"
                                  placeholder="-"
                                  required
                                /> -->
                                <!--<input type="number" class="input-amount" placeholder="-"/>-->
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Account Name</label>
                              <div class="account_select2">
                                <input
                                  class="input-amount"
                                  disabled
                                  style="background: white; height: 35px"
                                  v-model="beneficiaryName"
                                />
                              </div>
                            </div>
                            <div class="label-title2">
                              <label>Amount</label>
                              <div class="account_select2">
                                <currency-input
                                  v-model="transferAmount"
                                  class="input-amount"
                                  currency="NGN"
                                  precision="2"
                                  required
                                />
                                <!-- <vue-numeric
                                  class="input-amount"
                                  currency="₦"
                                  separator=","
                                  v-bind:precision="2"
                                  v-model="transferAmount"
                                  required
                                ></vue-numeric> -->
                              </div>
                            </div>
                            <!-- <div class="label-title2">
                              <label class="label">Save Beneficiary?</label>
                              <div></div>
                              <div></div>
                              <b-form-checkbox
                                size="lg"
                                v-model="saveBeneficiary"
                                name="check-button"
                                switch
                              ></b-form-checkbox>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>-->
                            <div class="label-title2">
                              <label>Description</label>
                              <div class="account_select2">
                                <input
                                  type="text"
                                  v-model="transferNote"
                                  class="input-amount"
                                  placeholder="Say something about the transaction"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="send-or-download2">
                            <div class="continue-button">
                              <a @click="$router.go(-1)">
                                <div class="continue-cib-btn">
                                  <font-awesome-icon
                                    icon="angle-left"
                                    class="download-icon"
                                  />BACK
                                </div>
                              </a>
                            </div>
                            <router-link to="Confirmation_?p=3">
                              <div class="continue-button">
                                <button class="continue-cib-btn">
                                  CONTINUE
                                  <font-awesome-icon
                                    icon="angle-right"
                                    class="angle-icon"
                                  />
                                </button>
                              </div>
                            </router-link>
                          </div>
                        </form>
                      </div>
                    </b-tab>

                    <!-- Schedule Transfer to Hope Account -->
                    <!-- <b-tab :active="five" @click="$router.push('ManageSchedule_')">
                      <template v-slot:title>
                        Schedule
                        <br />
                        <strong>Transaction</strong>
                      </template>
                    </b-tab>-->
                  </b-tabs>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <!-- <SideBar
                v-if="transferAccountFrom"
                :accountNumber="transferAccountFrom"
                :accountBalance="Balance"
              ></SideBar>-->
              <SideBar
                v-if="transferAccount"
                :accountNumber="transferAccount"
                :accountBalance="Balance"
                :accountName="Name"
              ></SideBar>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>
    </div>
  </div>
</template>

<script>
// import { TheMask } from "vue-the-mask";
// import VueNumeric from "vue-numeric";
import HeaderNavBiz from "../../../../components/HeaderNav/HeaderNavBiz";
import BusinessNavBar from "../../../../components/HeaderNav/BusinessNavBar";
import SideBar from "../../../../components/SideBar/SideBar_";
import { mapGetters, mapState } from "vuex";
import Loader from "../../../../components/Loader/Loader.vue";
import { transferService } from "../../../../services/transfer.services.js";
import { getInstitutionService } from "../../../../services/getInstitution.services.js";
import Footer3 from "../../../../components/Footer/Footer3";
import functions from "../../../../utils/functions";

export default {
  name: "Transfer.vue",
  components: {
    // TheMask,
    // VueNumeric,
    HeaderNavBiz,
    BusinessNavBar,
    SideBar,
    Loader,
    Footer3,
  },
  data() {
    return {
      oneoff: true,
      start_date: null,
      end_date: null,
      loading: false,
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      Type: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "GET_USER",
      filteredUserAccount: "GET_FILTERED_USER",
    }),

    ...mapState({
      AUTHENTICATION_TOKEN: (state) => state.User.AUTHENTICATION_TOKEN,
      TRANSFER_ACCOUNT: (state) => state.Transfer.TRANSFER_ACCOUNT,
      TRANSFER_ACCOUNT_FROM: (state) => state.Transfer.TRANSFER_ACCOUNT_FROM,
      TRANSFER_ACCOUNT_TO: (state) => state.Transfer.TRANSFER_ACCOUNT_TO,
      BENEFICIARY_ACCOUNT: (state) => state.Transfer.BENEFICIARY_ACCOUNT,
      BENEFICIARY_NAME: (state) => state.Transfer.BENEFICIARY_NAME,
      BENEFICIARY_BANK: (state) => state.Transfer.BENEFICIARY_BANK,
      TRANSFER_AMOUNT: (state) => state.Transfer.TRANSFER_AMOUNT,
      SAVE_BENEFICIARY: (state) => state.Transfer.SAVE_BENEFICIARY,
      TRANSFER_NOTE: (state) => state.Transfer.TRANSFER_NOTE,
      INSTITUTIONS: (state) => state.Transfer.INSTITUTIONS,
    }),

    Balance() {
      if (this.transferAccountFrom)
        return this.user.corporateAccounts[
          this.user.corporateAccounts.findIndex(
            (a) => a.accountNumber == this.transferAccountFrom
          )
        ].balance;
      return this.user.corporateAccounts[
        this.user.corporateAccounts.findIndex(
          (a) => a.accountNumber == this.transferAccount
        )
      ].balance;
    },
    Name() {
      if (this.transferAccountFrom)
        return this.user.corporateAccounts[
          this.user.corporateAccounts.findIndex(
            (a) => a.accountNumber == this.transferAccountFrom
          )
        ].accountName;
      return this.user.corporateAccounts[
        this.user.corporateAccounts.findIndex(
          (a) => a.accountNumber == this.transferAccount
        )
      ].accountName;
    },

    transferAccount: {
      get() {
        return this.TRANSFER_ACCOUNT;
      },
      set(value) {
        this.$store.commit("SET_TRANSFER_ACCOUNT", value);
      },
    },

    transferAccountFrom: {
      get() {
        return this.TRANSFER_ACCOUNT_FROM;
      },
      set(value) {
        this.$store.commit("SET_TRANSFER_ACCOUNT_FROM", value);
      },
    },

    transferAccountTo: {
      get() {
        return this.TRANSFER_ACCOUNT_TO;
      },
      set(value) {
        this.$store.commit("SET_TRANSFER_ACCOUNT_TO", value);
      },
    },

    beneficiaryAccount: {
      get() {
        return this.BENEFICIARY_ACCOUNT;
      },
      set(value) {
        this.$store.commit("SET_BENEFICIARY_ACCOUNT", value);
      },
    },
    beneficiaryName: {
      get() {
        return this.BENEFICIARY_NAME;
      },
      set(value) {
        this.$store.commit("SET_BENEFICIARY_NAME", value);
      },
    },
    beneficiaryBank: {
      get() {
        return this.BENEFICIARY_BANK;
      },
      set(value) {
        this.$store.commit("SET_BENEFICIARY_BANK", value);
      },
    },
    transferAmount: {
      get() {
        return this.TRANSFER_AMOUNT;
      },
      set(value) {
        this.$store.commit("SET_TRANSFER_AMOUNT", value);
      },
    },
    saveBeneficiary: {
      get() {
        return this.SAVE_BENEFICIARY;
      },
      set(value) {
        this.$store.commit("SET_SAVE_BENEFICIARY", value);
      },
    },
    transferNote: {
      get() {
        return this.TRANSFER_NOTE;
      },
      set(value) {
        this.$store.commit("SET_TRANSFER_NOTE", value);
      },
    },
    transferScheduleName: {
      get() {
        return this.SCHEDULE_NAME;
      },
      set(value) {
        this.$store.commit("SET_SCHEDULE_NAME", value);
      },
    },
    transferFrequency: {
      get() {
        return this.FREQUENCY;
      },
      set(value) {
        this.$store.commit("SET_FREQUENCY", value);
      },
    },
    transferStart: {
      get() {
        return this.START;
      },
      set(value) {
        this.$store.commit("SET_START", value);
      },
    },
    transferEnd: {
      get() {
        return this.END;
      },
      set(value) {
        this.$store.commit("SET_END", value);
      },
    },

    routeQuery() {
      return this.$route.query.p;
    },
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },

    ownTab() {
      this.transferAccount = "";
      this.$store.dispatch("CLEAR_INPUTS");
      this.$store.commit(
        "SET_TRANSFER_ACCOUNT_FROM",
        this.user.corporateAccounts[0].accountNumber
      );
    },

    hopeTab() {
      this.transferAccountFrom = "";
      this.$store.dispatch("CLEAR_INPUTS");
      this.$store.commit(
        "SET_TRANSFER_ACCOUNT",
        this.user.corporateAccounts[0].accountNumber
      );
    },

    async validateBeneficiaryAccount() {
      this.loading = true;
      const data = {
        Account: this.BENEFICIARY_ACCOUNT,
        Code: this.BENEFICIARY_BANK.value,
        token: this.AUTHENTICATION_TOKEN,
      };
      await transferService
        .validateBeneficiaryAccount(data)
        .then((res) => {
          this.loading = false;
          this.$store.commit("SET_BENEFICIARY_NAME", res.status);
        })
        .catch((err) => {
          this.loading = false;
          this.$toastr.error(err.status, "Validation Failed!", {
            timeOut: 5000,
          });
          this.$store.commit("SET_BENEFICIARY_NAME", "");
        });
    },
    async getInstitution() {
      await getInstitutionService
        .getInstitution()
        .then((res) => {
          this.$store.commit("SET_INSTITUTIONS", res);
        })
        .catch((err) => {
          this.$toastr.error(err.status, "Could not fetch institutions", {
            timeOut: 5000,
          });
        });
    },
    AmountToFixed(x) {
      return functions.AmountToFixed(x);
    },
    validateBeneficiary() {
      //window.alert("yes");
      if (this.beneficiary) return;
      if (
        this.beneficiaryAccount.toString().length == 11 ||
        this.beneficiaryAccount.toString().length == 10
      ) {
        setTimeout(function () {
          this.hovered = true;
        }, 1000);
        this.validateBeneficiaryAccount();
      }
    },
  },
  watch: {
    // beneficiaryAccount(newval) {
    //   if (newval.toString().length == 10) this.validateBeneficiaryAccount();
    // },
    transferAccountFrom(newval) {
      let userAccounts = this.$store.getters.GET_USER.corporateAccounts;
      let filtered = userAccounts.filter((a) => a.accountNumber != newval);
      this.$store.commit("SET_FILTERED_USER", filtered);
    },
  },
  mounted() {
    this.getInstitution();
    this.hopeTab();
    if (this.routeQuery == 1) {
      this.transferAccount = "";
      this.one = true;
      this.hopeTab();
      return;
    }
    if (this.routeQuery == 2) {
      this.transferAccountFrom = "";
      this.two = true;
      return;
    }
    if (this.routeQuery == 3) {
      this.transferAccountFrom = "";
      this.three = true;
      return;
    }
  },
  // created() {
  //   this.hopeTab()
  // },
};
</script>

<style scoped>
body {
  background: #000000;
}
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.nav-link {
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.17px;
}
.quick-transfer {
  padding: 0 0.7rem;
}
.back-logout .dropdown {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}

.nav-tabs {
  border: none !important;
  -ms-flex-pack: distribute;
  justify-content: flex-start;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
  margin-right: 1rem;
}
/* .current_tab_title {
  background-image: linear-gradient(
    180deg,
    #00c4ff 0%,
    #0091ff 100%
  ) !important;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-image: linear-gradient(
    180deg,
    #00c4ff 0%,
    #0091ff 100%
  ) !important;
}
.poster {
  background-color: rgb(0, 94, 194, 0.1) !important;
} */

@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>
