<template>
    <div>
        <b-modal id="modal-center-bulkwallet-completed" v-model="modalState" centered size="md" title="Completed" hide-footer ref="myModal2">
            <div class="bulk-validated-page text-center">
                
                <!-- <div class="d-flex justify-content-center mb-3">
                    <button class="continue-btn"  @click.prevent="onClick">Download Validated File</button>
                </div> -->
                <div class="d-flex justify-content-center">
                    <p>You've successfully opened <span>{{number}}</span> Hope Wallet for Third Party.</p>
                </div>
                <div class="d-flex justify-content-center">
                    <p>Beneficiaries have been notified to complete Wallet Activation</p>
                </div>
                <!-- <div class="d-flex justify-content-center">
                    <div class="account_select5">
                    <vuebase64
                        accept=".xls, .csv"
                        @size-exceeded="onSizeExceeded"
                        @file="data = $event"
                    />
                    </div>
                </div> -->
                <div class="cancel_submit_buttons">
                    <botton class="continue-btn mt-2" @click="hideModal2">Cancel</botton>
                    <button @click.prevent="onSubmit" class="continue-btn mt-2">OK</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
// import vuebase64 from "../../components/vue-base64/vuebase64.vue";

export default {

    props:{
        onClick:Function,
        onSubmit:Function,
        number:String
    },
    components: {
        // vuebase64,
    },
    methods: {
    hideModal() {
      this.$refs["myModal"].hide();
    },
    hideModal2() {
      this.$refs["myModal2"].hide();
    }
  }
    
}
</script>

<style scoped>
.cancel_submit_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>