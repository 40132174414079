<template>
  <div class="poster-bg">
    <Loader v-if="loading" :show-full="true" loading-text="please wait..." />
    <div class="poster"></div>
    <div class="dashboard-page registration-page">
      <div>
        <HeaderNavRegister />
        <div class="register_now">
          <div class="container">
            <div class="main_dashboard">
              <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                  <div class="main_dashboard_inner">
                    <div class="transfer_money2 text-center">
                      <p class="frequently-used text-center">Reset Password</p>
                    </div>
                    <div class="current_tab_title mb-3">
                      <font-awesome-icon icon="play" class="play-icon" />
                      <p class>Create password to secure account for the first time</p>
                    </div>
                    <div class="kindly_enter_details mb-3">
                      <font-awesome-icon icon="exclamation-circle" class="exclamation-icon" />
                      <p>Password created would be used to login to your internet banking</p>
                    </div>
                    <form action @submit.prevent="answerSecurityQuestions">
                      <div class="form-table">
                        <div class="label-title">
                          <label>Security Question 1*</label>
                          <div class="account_select">
                            <!-- <select name="" id="" class="account_type">
                            <option value="" class="choose_account">- Select -</option>-->
                            <div
                              value
                              v-if="SECURITY_QUESTIONS[this.obJectKeys[0]]"
                              class="account_type"
                            >{{SECURITY_QUESTIONS[this.obJectKeys[0]]}}</div>

                            <!-- </select> -->
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Security Answer 1*</label>
                          <div class="account_select2">
                            <input
                              type="text"
                              v-model="answer1"
                              class="input-amount"
                              placeholder=" "
                            />
                          </div>
                        </div>
                        <div class="label-title">
                          <label>Security Question 2*</label>
                          <div class="account_select">
                            <!-- <select name="" id="" class="account_type">
                            <option value="" class="choose_account">- Select -</option>-->
                            <div
                              value
                              v-if="SECURITY_QUESTIONS[this.obJectKeys[1]]"
                              class="account_type"
                            >{{SECURITY_QUESTIONS[this.obJectKeys[1]]}}</div>

                            <!-- </select> -->
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Security Answer 2*</label>
                          <div class="account_select2">
                            <input
                              type="text"
                              v-model="answer2"
                              class="input-amount"
                              placeholder=" "
                            />
                          </div>
                        </div>
                        <div class="label-title">
                          <label>Security Question 3*</label>
                          <div class="account_select">
                            <!-- <select name="" id="" class="account_type">
                            <option value="" class="choose_account">- Select -</option>-->
                            <div
                              value
                              v-if="SECURITY_QUESTIONS[this.obJectKeys[2]]"
                              class="account_type"
                            >{{SECURITY_QUESTIONS[this.obJectKeys[2]]}}</div>

                            <!-- </select> -->
                          </div>
                        </div>
                        <div class="label-title2">
                          <label>Security Answer 3*</label>
                          <div class="account_select2">
                            <input
                              type="text"
                              v-model="answer3"
                              class="input-amount"
                              placeholder=" "
                            />
                          </div>
                        </div>

                        <div class="send-or-download2">
                          <div class="continue-button">
                            <a @click="$router.go(-1)">
                              <div class="continue-btn">
                                <font-awesome-icon icon="angle-left" class="download-icon" />BACK
                              </div>
                            </a>
                          </div>
                          <div class="continue-button">
                            <b-button type="submit" class="continue-btn">
                              SUBMIT
                              <font-awesome-icon icon="angle-right" class="angle-icon" />
                            </b-button>
                            <!--<button class="continue-btn">CONTINUE <font-awesome-icon icon="angle-right" class="angle-icon"/></button>-->
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />

      <b-modal
        id="modal-center"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
        v-model="showPasswordModal"
      >
        <ValidationObserver v-slot="{ passes }">
          <form ref="form" @submit.prevent="passes(updatePassword)">
            <b-form-group
              :state="nameState"
              label-for="name-input"
              invalid-feedback="PIN is required"
              class="pin-info"
            >
              <h1 class="pin-info text-center">Reset Password</h1>
              <div class="label-title2">
                <label>New Password</label>
                <ValidationProvider name="password" rules="required" v-slot="{ errors }">
                  <div class="account_select2" id="tooltip-variant">
                    <input
                      v-model="password1"
                      ref="password"
                      type="password"
                      class="input-amount"
                      placeholder="******"
                    />
                  </div>
                  <b-tooltip
                    target="tooltip-variant"
                    variant="info"
                    custom-class="my-tooltip-class"
                    placement="left"
                  >Password must be atleast 8 characters long and must contain an uppercase, lowercase, a number and a symbol (e.g %, @, $, #).</b-tooltip>
                  <span style="font-size:13px; color:red" class>
                    <span v-if="errors[0]">
                      <font-awesome-icon icon="ban" size="1x" class="ban-icon text-danger" />
                    </span>
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="label-title2">
                <label>Confirm Password</label>
                <ValidationProvider
                  name="passwords"
                  rules="required|confirmed:password"
                  v-slot="{ errors }"
                >
                  <div class="account_select2">
                    <input
                      v-model="password"
                      type="password"
                      data-vv-name="password"
                      class="input-amount"
                      placeholder="******"
                    />
                  </div>
                  <span style="font-size:13px; color:red" class>
                    <span v-if="errors[0]">
                      <font-awesome-icon icon="ban" size="1x" class="ban-icon text-danger" />
                    </span>
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="label-title2">
                <label>Token/OTP</label>
                <ValidationProvider name="otp" rules="required" v-slot="{ errors }">
                  <div class="account_select2">
                    <the-mask
                      mask="######"
                      v-model="otp"
                      type="password"
                      class="input-amount"
                      placeholder="000000"
                    />
                  </div>
                  <span style="font-size:13px; color:red" class>
                    <span v-if="errors[0]">
                      <font-awesome-icon icon="ban" size="1x" class="ban-icon text-danger" />
                    </span>
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div @click="resendOtp" class="resend-otp">Resend OTP</div>
              <div class="continue-button float-right">
                <b-button type="submit" class="continue-btn">
                  SUBMIT
                  <font-awesome-icon icon="angle-right" class="angle-icon" />
                </b-button>
                <!--<button class="continue-btn">CONTINUE <font-awesome-icon icon="angle-right" class="angle-icon"/></button>-->
              </div>
            </b-form-group>
          </form>
        </ValidationObserver>
      </b-modal>

      <b-modal
        id="modal-center"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-footer
        v-model="resetSuccessfulModal"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            label-for="name-input"
            invalid-feedback="PIN is required"
            class="pin-info"
          >
            <img
              src="../../assets/images/icons/check.svg"
              alt="Hope Bank"
              class="modal_congrats_img"
            />
            <h1 class="pin-info text-center">Congratulations</h1>
            <div class="line4"></div>
            <p class="modal_congrats">You have successfully reset your password. Click OK to Login</p>
            <router-link to="Login">
              <button class="ok-btn">OK</button>
            </router-link>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import { securityService } from "../../services/security.services.js";
import { mapGetters, mapState } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Loader from "../../components/Loader/Loader.vue";
import HeaderNavRegister from "../../components/HeaderNav/HeaderNavRegister";
import Footer2 from "../../components/Footer/Footer2";

export default {
  name: "ResetPassword.vue",
  components: {
    TheMask,
    Loader,
    ValidationObserver,
    ValidationProvider,
    HeaderNavRegister,
    Footer2
  },
  data() {
    return {
      image: "",
      answer1: "",
      answer2: "",
      answer3: "",
      showPasswordModal: false,
      resetSuccessfulModal: false,
      loading: false,
      otp: "",
      password1: "",
      password: ""
    };
  },
  computed: {
    ...mapGetters({ user: "GET_USER" }),

    ...mapState({
      SECURITY_QUESTIONS: state => state.User.SECURITY_QUESTIONS
    }),
    Account() {
      return this.user.accounts[0].accountNumber;
    },
    obJectKeys() {
      return Object.keys(this.SECURITY_QUESTIONS);
    }
  },
  methods: {
    toggleDropdown(event) {
      event.currentTarget.classList.toggle("is-active");
    },
    async resendOtp() {
      // this.showPasswordModal = false;
      await securityService.resendOtp({}).then(() => {

      })
    },
    //get Security questions
    // async resetPassword() {
    //   await securityService
    //     .resetPassword(this.Account)
    //     .then(res => {
    //       window.console.log(res);
    //       this.$store.commit("SET_SECURITY_QUESTIONS", res.questions);
    //     })
    //     .catch(error => {
    //       this.$toastr.error(error.status, "Reset Pin Failed!", {
    //         timeOut: 5000
    //       });
    //     });
    // },
    //answer security questions
    async answerSecurityQuestions() {
      this.loading = true;
      await securityService
        .answerSecurityQuestions2({
          answer1: this.answer1,
          answer2: this.answer2,
          answer3: this.answer3,
          Account: this.user,
          keys: this.obJectKeys
        })
        .then(res => {
          this.loading = false;
          this.showPasswordModal = true;
          this.$toastr.success(res.status || "Answer Successful", {
            timeOut: 5000
          });
        })
        .catch(error => {
          this.loading = false;
          this.$toastr.error(error.status, "Answer Failed", { timeOut: 5000 });
        });
    },
    //Reset password to new password
    async updatePassword() {
      this.loading = true;
      await securityService
        .updatePassword({
          Account: this.user,
          Code: this.otp,
          Password: this.password
        })
        .then(res => {
          this.loading = false;
          this.resetSuccessfulModal = true;
          this.$store.commit("SET_SECURITY_QUESTIONS", "");
          this.$store.commit("SET_USER_DATA", "");
          this.$toastr.success(res.status || "Updated Successfully", {
            timeOut: 5000
          });
        })
        .catch(error => {
          this.loading = false;
          this.$toastr.error(error.status, "Update Failed", { timeOut: 5000 });
        });
    },
    mounted() {
      //this.resetPassword();
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000000;
}

.navbar {
  padding: 0;
}
.navbar {
  padding: 0;
}
.quick-transfer {
  padding: 0 2.2rem;
}
.form-control {
  padding: 10px 20px;
  font-size: 22px;
  border: 1px solid #dadada;
  border-radius: 28.1px;
  background: #e5e5e5;
  margin: 1.5rem 1rem;
  width: 90%;
  text-align: center;
}
.modal-header {
  padding: 1rem 1rem 0 !important;
}
.modal-header .close {
  padding: 1rem 1rem 0 !important;
  font-size: 40px !important;
}
.btn-secondary {
  background: #fecb32;
  font-family: Montserrat-Medium;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.22px;
  padding: 0.5rem 1rem;
  text-align: left;
  border: 2px solid #fecb32;
  transition: all 0.4s ease-in-out;
}
.btn-secondary:hover {
  color: #000000;
  background: #ffffff;
  border: 2px solid #fecb32;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  padding: 1.1rem 0.3rem 0.73rem;
  margin: 0 1rem;
  font-family: Montserrat-Regular;
  font-size: 12px;
  letter-spacing: 0.17px;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fecb32;
  border-bottom: 3px solid #fecb32;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
  border-bottom: 3px solid #fecb32;
}
.navbar-light .navbar-nav .nav-link.active:hover {
  color: #fecb32;
  border-bottom: 3px solid #fecb32;
}
.navbar-light .navbar-nav .nav-link a {
  color: #000000;
  display: flex;
  align-items: center;
}
.white_bg {
  background: #ffffff;
  border-radius: 5px;
  height: 52px;
}
.nav_header_inner {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 5px 0 0 5px;
}

/*.plus_icon{*/
/*margin-right: 1rem;*/
/*font-size: 20px;*/
/*}*/
.yellow_bg {
  background: #fecb32;
  border-radius: 0 5px 5px 0;
  color: #000000 !important;
}
.yellow_bg:hover {
  border-radius: 0 5px 5px 0;
  color: #ffffff !important;
}
.yellow_bg > .navbar-light .navbar-nav .nav-link {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.25px;
}
.logout-btn {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .dashboard-page {
    width: 100%;
    height: 100%;
  }
}
</style>